import { Dimensions } from "react-native";

import { ScreenSize } from "../interfaces/screen";

const screenSizeMap: Record<ScreenSize, number> = {
    [ScreenSize.Small]: 0,
    [ScreenSize.Normal]: 300,
    [ScreenSize.Medium]: 600,
    [ScreenSize.Large]: 900,
};

export const getScreenSize = (deviceWidth?: number): ScreenSize => {
    deviceWidth = deviceWidth == null ? Dimensions.get("window").width : deviceWidth;

    if (deviceWidth > screenSizeMap[ScreenSize.Large]) {
        return ScreenSize.Large;
    }

    if (deviceWidth > screenSizeMap[ScreenSize.Medium]) {
        return ScreenSize.Medium;
    }

    if (deviceWidth > screenSizeMap[ScreenSize.Normal]) {
        return ScreenSize.Normal;
    }

    return ScreenSize.Small;
};

export const compareScreenSizes = (size1: ScreenSize, size2: ScreenSize): boolean => {
    return screenSizeMap[size1] >= screenSizeMap[size2];
};
