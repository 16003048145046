import React, { memo } from "react";
import { StyleSheet, View } from "react-native";

import { Input, useTheme, SpacingValue } from "@swiggy-private/rn-dls";
import { useDebounceFn } from "@swiggy-private/react-hooks";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useChatInstrumentation } from "../../hooks/use-chat-instrumentation";
import { IChatSearchInputStyles } from "../../interfaces";

export const CHAT_SEARCH_INPUT_PLACEHOLDER = "Search in your chats...";
export const CHAT_SEARCH_DEBOUNE_TIME = 100;

export type ChatSearchProps = {
    onChangeText: (text: string) => void;
    searchVal?: string;
    testID?: string;
    searchWidgetStyles?: IChatSearchInputStyles;
    iconSize?: number;
};

const DEFAULT_ICON_SIZE = 24;
export const ChatSearchInput: React.FC<ChatSearchProps> = memo(
    ({ onChangeText, searchWidgetStyles, iconSize = DEFAULT_ICON_SIZE, testID }) => {
        const { value: theme } = useTheme();

        const instrumentationCb = useChatInstrumentation()?.chatConversationListCb;

        const inputStyle = {
            fontFamily: theme["text-body-1-regular-font-family"],
            color: theme["color-basic-75"],
        };

        const search = useDebounceFn(onChangeText, { wait: CHAT_SEARCH_DEBOUNE_TIME });
        const searchIcon = (
            <SvgIcon icon="Magnifier" height={iconSize} width={iconSize} color={"color-basic-60"} />
        );

        const onFocus = (): void => {
            instrumentationCb?.searchInputAnalyticClick?.();
        };

        return (
            <View
                style={[
                    styles.container,
                    searchWidgetStyles?.containerStyle,
                    { backgroundColor: theme["color-background-primary"] },
                ]}
                testID={testID}>
                <Input
                    placeholder={CHAT_SEARCH_INPUT_PLACEHOLDER}
                    accessoryLeft={searchIcon}
                    textStyle={[inputStyle, searchWidgetStyles?.textStyle]}
                    onChangeText={search}
                    placeholderTextColor={theme["color-basic-45"]}
                    selectionColor={theme["color-basic-45"]}
                    style={searchWidgetStyles?.inputStyle}
                    onFocus={onFocus}
                />
            </View>
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    ChatSearchInput.displayName = "ChatSearchInput";
}

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: 0,
        marginBottom: SpacingValue["space-large"],
    },
    textAlignment: { textAlign: "center" },
});
