import { ProviderStatus } from "../../interfaces/provider";

export class ProviderError extends Error {
    private _status: ProviderStatus;

    constructor(message: string, status = ProviderStatus.Unknown) {
        super(message);

        this.name = "ProviderError";
        this._status = status;
    }

    get status(): ProviderStatus {
        return this._status;
    }
}
