import React from "react";
import {
    StyleSheet,
    Animated,
    StyleProp,
    Platform,
    TextStyle,
    InteractionManager,
} from "react-native";

import { useFocusEffect } from "@react-navigation/native";

import { useMount } from "@swiggy-private/react-hooks";
import { Text } from "@swiggy-private/rn-dls";

type IProps = {
    texts: string[];

    height?: number;
    slideDelay?: number;
    textStyle?: StyleProp<TextStyle>;
    stopAnimation?: boolean;
};

const OPACITY_ANIM_DURATION = 200;
const SLIDE_ANIM_DURATION = 500;
let animationTimeout: number | NodeJS.Timeout | undefined;

const IS_NATIVE = Platform.OS !== "web";

export const VerticalTextSlider: React.FC<IProps> = ({
    texts,
    height = 1,
    slideDelay,
    textStyle,
    stopAnimation,
}) => {
    const [textList, setTextList] = React.useState(texts);
    const [shouldAnimate, setShouldAnimate] = React.useState(textList.length >= 2);

    const translateValueYRef = React.useRef(new Animated.Value(0)).current;
    const opacityValueRef = React.useRef(new Animated.Value(1)).current;

    const taskRef = React.useRef<ReturnType<typeof InteractionManager.runAfterInteractions> | null>(
        null,
    );

    const resetAnimation = React.useCallback((): void => {
        clearTimeout(animationTimeout);
        taskRef.current?.cancel();
        setTextList(texts);
        setShouldAnimate(false);
    }, [texts]);

    useMount(() => {
        return () => {
            resetAnimation();
        };
    });

    useFocusEffect(
        React.useCallback(() => {
            return () => {
                resetAnimation();
            };
        }, [resetAnimation]),
    );

    const startAnimations = React.useCallback(() => {
        taskRef.current = InteractionManager.runAfterInteractions(() => {
            Animated.parallel([
                Animated.timing(translateValueYRef, {
                    toValue: -height,
                    duration: SLIDE_ANIM_DURATION,
                    useNativeDriver: IS_NATIVE,
                }),
                Animated.timing(opacityValueRef, {
                    toValue: 0,
                    duration: OPACITY_ANIM_DURATION,
                    useNativeDriver: IS_NATIVE,
                }),
                Animated.timing(translateValueYRef, {
                    toValue: -height,
                    duration: SLIDE_ANIM_DURATION,
                    delay: 0,
                    useNativeDriver: IS_NATIVE,
                }),
            ]).start(({ finished }) => {
                if (finished) {
                    const copyTexts = [...textList];
                    const firstElem = copyTexts.splice(1);
                    translateValueYRef.setValue(0);
                    opacityValueRef.setValue(1);
                    translateValueYRef.setValue(0);
                    setTextList([...firstElem.concat(copyTexts)]);
                }
            });
        });
    }, [opacityValueRef, translateValueYRef, textList, height]);

    React.useEffect(() => {
        if (!shouldAnimate || stopAnimation) {
            resetAnimation();
            return;
        }
        animationTimeout = setTimeout(() => {
            startAnimations();
        }, slideDelay);
    }, [startAnimations, shouldAnimate, slideDelay, resetAnimation, stopAnimation]);

    const currTextStyles = {
        transform: [{ translateY: translateValueYRef }],
        opacity: opacityValueRef,
        height,
    };

    const nextTextStyles = {
        transform: [{ translateY: translateValueYRef }],
        height,
    };

    return (
        <>
            <Animated.View style={[styles.slide, currTextStyles]}>
                <Text color="color-basic-45" category="b1" style={textStyle}>
                    {textList[0]}
                </Text>
            </Animated.View>

            <Animated.View style={[styles.slide, nextTextStyles]}>
                <Text color="color-basic-45" category="b1" style={textStyle}>
                    {textList[1]}
                </Text>
            </Animated.View>
        </>
    );
};

const styles = StyleSheet.create({
    slide: {
        justifyContent: "center",
    },
});
