import * as React from "react";
import { Modal, StatusBar, View } from "react-native";
import { useTheme } from "@swiggy-private/rn-dls";

import { ImageGalleryHeader } from "./header";
import { ImageGalleryProps } from "./types";
import { ImageDetail } from "./image-detail";

export const ImageGallery: React.FC<ImageGalleryProps> = (props) => {
    const { value: theme } = useTheme();

    const {
        onClose,
        onShow,
        visible,
        hardwareAccelerated,
        overlayBackgroundColor = theme["color-background-primary"],
        statusBarTranslucent,
        onDismiss,
        HeaderComponent = ImageGalleryHeader,
        FooterComponent,
        images,
        resizeMode = "cover",
        renderToHardwareTextureAndroid,
        imageBackgroundColor = theme["color-background-secondary"],
        imageStyle,
    } = props;

    const imageArr = Array.isArray(images) ? images : [images];
    if (!imageArr.length) {
        return null;
    }

    const containerStyle = {
        backgroundColor: overlayBackgroundColor,
        flex: 1,
        height: "100%",
    };

    return (
        <Modal
            animationType="fade"
            onRequestClose={onClose}
            onDismiss={onDismiss}
            onShow={onShow}
            hardwareAccelerated={hardwareAccelerated}
            visible={visible}
            presentationStyle="overFullScreen"
            transparent
            statusBarTranslucent={statusBarTranslucent}>
            <StatusBar
                translucent={statusBarTranslucent}
                backgroundColor={overlayBackgroundColor}
                barStyle="dark-content"
            />
            <View style={containerStyle}>
                <HeaderComponent onClose={onClose} />
                <ImageDetail
                    resizeMode={resizeMode}
                    source={imageArr[0]}
                    imageBackgroundColor={imageBackgroundColor}
                    imageStyle={imageStyle}
                    renderToHardwareTextureAndroid={renderToHardwareTextureAndroid}
                />
                {FooterComponent ? <FooterComponent /> : null}
            </View>
        </Modal>
    );
};
