export const SHARE = "Share";
export const APPLY_COUPON = "Apply Coupon";
export const DESCRIPTION_EMPTY_STRING = "-";
export const COUPON_VISIBILITY_COPY = {
    PUBLIC: "Visible on your mini store.",
    SHARED_CODE: "Hidden, accessible with a coupon code.",
};

export const DEFAULT_VALUES = {
    COUPON_ROUND_RADIUS: 16,
};
