import { useEffect, useMemo } from "react";

import type { DebounceOptions } from "../interfaces/debounce-options";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fn = (...args: any) => any;
type VoidFunction = () => void;

interface DebounceFunc {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (...args: any): void;
    cancel: VoidFunction;
    pending: () => boolean;
}

/**
 * Creates a debounced function that delays invoking `fn` until after `wait`
 * milliseconds have elapsed since the last time the debounced function was
 * invoked, or until the next browser frame is drawn. The debounced function
 * comes with a `cancel` method to cancel delayed `fn`.
 *
 * @param fn the function to debounce
 * @param wait The wait time
 *
 * @returns {@link DebounceFunc}
 */
function debounce(fn: Fn, wait: number): DebounceFunc {
    if (typeof fn !== "function") {
        throw new TypeError("Expected a function");
    }

    const useRAF = wait !== 0 && typeof requestAnimationFrame === "function";
    let timerId: number | undefined;
    let lastCallTime: number | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let lastArgs: any;

    function shouldInvoke(): boolean {
        const curTime = Date.now();
        return curTime >= (lastCallTime ?? 0) + wait;
    }

    function invokeFunc(): void {
        if (shouldInvoke()) {
            fn(...lastArgs);
        } else {
            timerId = startTimer();
        }
    }

    function startTimer(): number {
        if (useRAF) {
            timerId && cancelAnimationFrame(timerId);
            return requestAnimationFrame(invokeFunc);
        }

        timerId && clearTimeout(timerId);
        return setTimeout(invokeFunc, wait) as unknown as number;
    }

    function cancelTimer(id: number): void {
        if (useRAF) {
            return cancelAnimationFrame(id);
        }

        return clearTimeout(id);
    }

    const debounceFn: DebounceFunc = (...args) => {
        lastArgs = args;
        lastCallTime = Date.now();
        timerId = startTimer();
    };

    debounceFn.cancel = () => {
        timerId && cancelTimer(timerId);
        lastCallTime = lastArgs = timerId = undefined;
    };

    debounceFn.pending = () => !!timerId;

    return debounceFn;
}

/**
 * A hook that handle the debounce function.
 *
 * @param fn The function to debounce
 * @param options Config the debounce behavior
 */
export const useDebounceFn = <T extends Fn>(fn: T, options?: DebounceOptions): DebounceFunc => {
    const wait = options?.wait ?? 500;
    const debounced = useMemo(() => debounce(fn, wait), [fn, wait]);
    useEffect(() => () => debounced.cancel(), [debounced]);
    return debounced;
};
