import React from "react";

import { ISellerBadge } from "@swiggy-private/connect-business-commons";

export const useStarSeller = (badges?: ISellerBadge[]): boolean => {
    const starSellerBadge = React.useMemo(
        () => badges?.find((badge) => badge.badgeType === "STAR_SELLER"),
        [badges],
    );

    return Boolean(starSellerBadge);
};
