import React, { memo } from "react";

import { View, Animated, StyleSheet } from "react-native";

import AnimatedDot from "./components/animated-dot";

const HEIGHT = 35;

interface IIndicators {
    scrollWidth: number;
    scrollAnimatedValue: Animated.AnimatedValue;
    numberOfCards?: number;

    activeColor?: string;
    inActiveColor?: string;
}

const AnimatedScrollIndicators: React.FC<IIndicators> = ({
    scrollAnimatedValue,
    numberOfCards = 0,
    scrollWidth,
    activeColor,
    inActiveColor,
}) => {
    return (
        <View style={styles.container}>
            <View style={styles.animatedDotsContainer}>
                <Animated.View style={[styles.animatedDotsView]}>
                    {[...Array(numberOfCards)].map((e, index) => (
                        <AnimatedDot
                            i={index}
                            key={index}
                            activeColor={activeColor}
                            scrollWidth={scrollWidth}
                            inActiveColor={inActiveColor}
                            numberOfCards={numberOfCards}
                            scrollAnimatedValue={scrollAnimatedValue}
                        />
                    ))}
                </Animated.View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
    },
    animatedDotsContainer: {
        height: HEIGHT,
        overflow: "hidden",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    animatedDotsView: {
        height: HEIGHT,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
});

export const AnimatedDotIndicators = memo(AnimatedScrollIndicators);
