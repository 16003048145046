import React from "react";

import { CdnImage, CdnImageProps } from "../cdn-image";

import { EmojiTypes } from "./types";

export interface EmojiProps extends Omit<CdnImageProps, "id"> {
    id: keyof EmojiTypes;
}

export const Emoji: React.FC<EmojiProps> = ({ id, ...props }) => {
    return <CdnImage id={`minis/${id}`} {...props} />;
};
