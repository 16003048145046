import React, { useCallback } from "react";
import { SafeAreaView, StyleProp, StyleSheet, ViewStyle } from "react-native";

import {
    GenericErrorView,
    getErrorDescription,
    getErrorTitle,
} from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { useFocusEffect } from "@react-navigation/core";

import { useGetConversation } from "../../hooks/use-get-conversation";
import { useGetMessages } from "../../hooks/use-get-messages";

import { ChatConversationView } from "../../components/conversation-view";
import { ScreenLoader } from "../../components/screen-loader";
import { ChatConversationStateProvider } from "../../contexts/chat-conversation-context";

import { useSendMessage } from "../../hooks/use-send-message";
import { useSubscribeConversation } from "../../hooks/use-subscribe-conversation";
import { useChatRenderer } from "../../hooks/use-chatrender";
import { useGetUserOnlineStatus } from "../../hooks/use-get-user-online-status";

import type { Navigation, Conversation } from "../../interfaces";
import { logError } from "../../helpers/log";

interface ChatConversationProps {
    conversation: Conversation;
    testID?: string;
    style?: StyleProp<ViewStyle>;
    contentStyle?: StyleProp<ViewStyle>;
    navigation?: Navigation;
    showPopup?: boolean;
    messageTemplate?: string;

    ChatInfoComponent?: React.ComponentType<{ showPopup?: boolean; closeInfoPopup?: VoidFunction }>;
    onConversationLoad?: VoidFunction;
    closeInfoPopup?: VoidFunction;
    shouldShowAdvanceFooterByDefault?: boolean;
}

const ChatConversation: React.FC<ChatConversationProps> = ({ conversation, ...props }) => {
    useSubscribeConversation(conversation.id);

    const sendMessage = useSendMessage(conversation.id);
    const getUserOnlineStatus = useGetUserOnlineStatus();

    const [messageSections, { loading, fetch: fetchMessages, error }] = useGetMessages(
        conversation.id,
    );

    const onContentScrollEndReached = useCallback(
        (info: { distanceFromEnd: number }) => {
            if (!loading && info.distanceFromEnd >= 0) {
                fetchMessages(true);
            }
        },
        [fetchMessages, loading],
    );

    const ConversationView = useChatRenderer()?.renderConversation ?? ChatConversationView;

    useMount(() => {
        props.onConversationLoad?.();
    });

    useFocusEffect(
        useCallback(() => {
            getUserOnlineStatus(conversation.id).catch(logError);
        }, [conversation.id, getUserOnlineStatus]),
    );

    if (!messageSections.length && error) {
        return (
            <GenericErrorView
                title={getErrorTitle(error)}
                message={getErrorDescription(error)}
                onRetry={() => fetchMessages()}
            />
        );
    }

    return (
        <ConversationView
            {...props}
            sendMessage={sendMessage}
            onContentScrollEndReached={onContentScrollEndReached}
            messageSections={messageSections}
            conversation={conversation}
            loading={loading}
        />
    );
};

interface ChatConversationScreenProps extends Omit<ChatConversationProps, "conversation"> {
    conversationId: string;
    showPopup?: boolean;
    shouldShowAdvanceFooterByDefault?: boolean;

    ChatInfoComponent?: React.ComponentType<{ showPopup?: boolean; closeInfoPopup?: VoidFunction }>;
    closeInfoPopup?: VoidFunction;
}

export const ChatConversationScreen: React.FC<ChatConversationScreenProps> = ({
    conversationId,
    ...props
}) => {
    const [conversation, { loading, error, fetch }] = useGetConversation(conversationId);

    if (!conversation && loading) {
        return (
            <SafeAreaView style={styles.container}>
                <ScreenLoader />
            </SafeAreaView>
        );
    }

    if (!conversation) {
        return (
            <GenericErrorView
                title={error ? getErrorTitle(error) : null}
                message={error ? getErrorDescription(error) : null}
                onRetry={fetch}
            />
        );
    }

    return (
        <ChatConversationStateProvider initialState={{ conversation }} key={conversation.id}>
            <ChatConversation {...props} conversation={conversation} />
        </ChatConversationStateProvider>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
