import * as React from "react";
import {
    Animated,
    Easing,
    LayoutChangeEvent,
    Platform,
    StyleSheet,
    View,
    ViewProps,
    ViewStyle,
} from "react-native";
import { ColorPalette, SpacingValue } from "@swiggy-private/rn-dls-theme";

import { Tab, TabProps } from "./tab";
import { TextProps } from "../text";
import { useTheme } from "../../styles/theme-service";
import { useAnimatedValue } from "../../hooks/use-animated-value";

type Children = React.ReactElement<TabProps, typeof Tab>;

export interface TabsProps extends ViewProps {
    /** Callback fired when the value changes. */
    onChange?: (index: number) => void;
    /** Determines the color of the indicator. */
    indicatorColor?: keyof ColorPalette;
    /** The value of the currently selected Tab. If you don't want any selected Tab, you can set this prop to false. */
    value?: number | false;
    /** Determines the color of the inactive Tab. */
    textColor?: TextProps["color"];
    /** Determines the color of the active Tab. */
    activeTextColor?: TextProps["color"];
    /** fullWidth will make the tabs grow to use all the available space */
    variant?: "fullWidth" | "standard";

    children?: Children | Children[];
    tabStyle?: ViewStyle;
    indicatorStyle?: ViewStyle;
}

type TabMeta = {
    width: number;
    height: number;
};

/**
 * ## Usage
 * ```ts
 * import * as React from "react";
 * import { Tabs, Tab } from "@swiggy-private/rn-dls";
 *
 * const MyComponent: React.FC = () => {
 *     const [value, setValue] = React.useState(0);
 *     return (
 *         <Tabs value={value} onChange={setValue}>
 *             <Tab>Item 1</Tab>
 *             <Tab>Item 2</Tab>
 *             <Tab>Item 3</Tab>
 *         </Tabs>
 *     );
 * };
 *```
 */
export const Tabs: React.FC<TabsProps> = ({
    children,
    indicatorColor, // "color-primary"
    value = 0,
    textColor = "high",
    activeTextColor = "high",
    variant = "fullWidth",
    onChange,
    accessibilityRole = "tablist",
    accessible = true,
    style,
    tabStyle,
    indicatorStyle,
    ...props
}) => {
    const { value: theme } = useTheme();
    const tabs = React.Children.toArray(children).filter(
        (c) => React.isValidElement(c) && c.type === Tab,
    ) as Children[];

    const [tabsMeta, setTabsMeta] = React.useState<Record<number, TabMeta>>({});
    const indicatorAnimation = useAnimatedValue(0);

    const lastValueRef = React.useRef(value);
    const indicatorXRef = React.useRef(0);

    React.useEffect(() => {
        const indicatorX =
            value === false
                ? 0
                : Object.entries(tabsMeta).reduce((sum, cur) => {
                      return Number(cur[0]) < value ? sum + cur[1].width : sum;
                  }, 0);

        if (lastValueRef.current === value && indicatorXRef.current === indicatorX) {
            return;
        }

        lastValueRef.current = value;
        indicatorXRef.current = indicatorX;

        Animated.timing(indicatorAnimation, {
            duration: 150,
            easing: Easing.ease,
            toValue: indicatorX,
            useNativeDriver: Platform.OS !== "web",
        }).start();
    }, [indicatorAnimation, tabsMeta, value]);

    const tabIndicatorStyle = {
        backgroundColor: theme["color-primary"],
        width: value === false ? 0 : tabsMeta[value]?.width ?? 0,
        transform: [{ translateX: indicatorAnimation }],
    };

    return (
        <View
            accessibilityRole={accessibilityRole}
            accessible={accessible}
            style={[styles.container, style]}
            {...props}>
            {tabs.map((tab, i) => {
                const selected = value === i;
                const textProps: TextProps = {
                    color: selected ? activeTextColor : textColor,
                    category: "b2",
                    weight: selected ? "bold" : "regular",
                };

                const _tabStyle = [
                    styles.tab,
                    variant === "fullWidth" ? styles.tabFullWidth : null,
                    tabStyle,
                    tab.props.style,
                ];

                return React.cloneElement(tab, {
                    selected,
                    textProps,
                    style: _tabStyle,
                    onPress: () => onChange?.(i),
                    onLayout: (e: LayoutChangeEvent) => {
                        const { height, width } = e.nativeEvent.layout;
                        setTabsMeta((state) => {
                            state[i] = { width, height };
                            return { ...state };
                        });
                    },
                });
            })}
            <Animated.View style={[styles.tabIndicator, tabIndicatorStyle, indicatorStyle]} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        overflow: "hidden",
        minHeight: 48,
    },
    tab: {
        padding: SpacingValue["space-small"],
        alignItems: "center",
    },
    tabFullWidth: {
        flex: 1,
    },
    tabIndicator: {
        height: 4,
        position: "absolute",
        bottom: 0,
        left: 0,
        width: 100,
    },
});
