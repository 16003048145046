import { useCallback, useRef } from "react";
import { BackHandler, Dimensions } from "react-native";

export type DismissFunction = (reason: "hardwareback" | "dimensionchange" | "keyboardesc") => void;

type Event = {
    onHardwareBack?: boolean;
    onDimensionChange?: boolean;
    onKeyboardEsc?: boolean;
};

type VoidFunction = () => void;

export const useDimissListeners = (
    handleDismiss: DismissFunction,
    { onHardwareBack, onDimensionChange }: Event = { onHardwareBack: true, onKeyboardEsc: true },
): [VoidFunction, VoidFunction] => {
    const subscriptions: VoidFunction[] = useRef([]).current;

    const removeListeners = useCallback(() => {
        while (subscriptions.length > 0) {
            const s = subscriptions.pop();
            s?.();
        }
    }, [subscriptions]);

    const attachListeners = useCallback(() => {
        removeListeners();

        if (onHardwareBack) {
            const fn = (): boolean => {
                handleDismiss("hardwareback");
                return true;
            };

            const subscription = BackHandler.addEventListener("hardwareBackPress", fn);

            subscriptions.push(() => {
                if (subscription == null) {
                    BackHandler.removeEventListener("hardwareBackPress", fn);
                } else {
                    subscription.remove();
                }
            });
        }

        if (onDimensionChange) {
            const fn = (): void => {
                handleDismiss("dimensionchange");
            };

            const subscription = Dimensions.addEventListener("change", fn);

            subscriptions.push(() => {
                if (subscription == null) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-expect-error
                    Dimensions.removeEventListener("change", fn);
                } else {
                    subscription.remove();
                }
            });
        }
    }, [handleDismiss, onDimensionChange, onHardwareBack, removeListeners, subscriptions]);

    return [attachListeners, removeListeners];
};
