import React, { useContext } from "react";
import { Pressable, StyleSheet, ColorValue } from "react-native";
import { SvgProps } from "react-native-svg";
import { ColorPalette, ThemeContext } from "@swiggy-private/rn-dls-theme";

import * as AllIcons from "./icons";

/**
 * Done to provide Intellisense.
 * See issue here - https://github.com/Microsoft/TypeScript/issues/29729
 */
type ValidColors<T extends U, U = string> = T | (U & Record<never, never>);

export type Icon = keyof typeof AllIcons;

export type SvgIconType = Partial<SvgProps> & {
    icon: Icon;
    color?: ValidColors<keyof ColorPalette, ColorValue>;
    pressable?: boolean;
};

export const SvgIcon: React.FC<SvgIconType> = ({
    style,
    icon,
    height = 24,
    width = 24,
    onPress,
    onLongPress,
    testID,
    accessibilityLabel,
    accessibilityRole,
    accessible,
    hitSlop,
    disabled,
    color = "color-basic-100",
    pressable = !!(onPress || onLongPress),
    onPressIn,
    onPressOut,
    collapsable = false,
    ...props
}) => {
    const themeCtx = useContext(ThemeContext);
    const Icon = React.useMemo(() => AllIcons[icon as unknown as Icon], [icon]);

    const colorValue = color
        ? themeCtx.value[color as keyof ColorPalette]?.toString() ?? color
        : undefined;

    const children = (
        <Icon
            preserveAspectRatio="xMidYMid meet"
            {...props}
            color={colorValue}
            style={StyleSheet.flatten(style)}
            height={height}
            width={width}
        />
    );

    if (!pressable) {
        return children;
    }

    return (
        <Pressable
            accessible={accessible}
            accessibilityLabel={accessibilityLabel}
            accessibilityRole={accessible ? accessibilityRole ?? "button" : accessibilityRole}
            testID={testID}
            onPress={onPress}
            hitSlop={hitSlop}
            disabled={disabled}
            onLongPress={onLongPress}
            onPressIn={onPressIn}
            collapsable={collapsable}
            onPressOut={onPressOut}>
            {children}
        </Pressable>
    );
};
