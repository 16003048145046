import { useMemo } from "react";
import { useChatState } from "./use-chatstate";

interface Indicators {
    typing?: boolean;
    online?: boolean;
}

export const useConversationIndicators = (conversationId: string): Indicators => {
    const state = useChatState();

    const meta = useMemo(
        () => state.conversationsMeta[conversationId] ?? { indicators: {} },
        [conversationId, state],
    );

    return meta.indicators;
};
