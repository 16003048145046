import Pubnub from "pubnub";
import { HttpError } from "@swiggy-private/http-client";

import {
    ChatSdk as IChatSdk,
    ChatSdkConfig,
    SdkFetchMessagesParams,
    SdkFetchMessagesResponse,
    SdkSendMessageParams,
    SdkSubscribeParams,
    SdkUnsubscribeParams,
    SdkConversation,
    SdkCreateConversationsParams,
    SdkListConversationsParams,
    SdkListConversationsResponse,
    SdkAddListenerParams,
    SdkInitializeParams,
    SdkInitializeResponse,
    SdkMessage,
    SdkUser,
    SdkUserDetails,
    SdkPrepareMessageParams,
    SdkSendSignalParams,
    SdkRegisterDeviceForPNParams,
    SdkUnregisterDeviceForPNParams,
    SdkGetOnlineUsersParams,
    SdkGetOnlineUsersResponse,
    SdkAddMessageActionParams,
    SdkAddMessageActionResponse,
    SdkBlockConversationRequest,
    SdkBlockConversationResponse,
} from "./interfaces";
import { ChatServer } from "./network/server";
import { getProvider } from "./network/provider";
import { transformConversation } from "./helpers/conversation";
import { ChatSdkError } from "./chat-sdk-error";
import { getMessagesFromProviderResponse, isValidMessage } from "./helpers/message";
import { Provider, ProviderAddListenerParams, ProviderStatus } from "./interfaces/provider";
import { ProviderError } from "./network/provider/error";
import { waitFor } from "./helpers/util";
import { ServerLaunchResponseMetaData } from "./interfaces/server";

export class ChatSdk implements IChatSdk {
    private provider: Provider;
    private chatServer: ChatServer;

    private logVerbosity = false;

    private fetchMessagesInProgress = false;

    private tokenRefreshTimer: number | null = null;
    private messageFlushTimer: number | null = null;
    private onlineStatusTimer: number | null = null;

    private messageQueue: SdkSendMessageParams[] = [];
    private retryMessageQueue: Array<{ message: SdkSendMessageParams; retryCount: number }> = [];
    private sendingMessage = false;

    private config: ChatSdkConfig;
    private launchMetaData: ServerLaunchResponseMetaData = {};

    constructor(config: ChatSdkConfig) {
        const { serverUrl, userMode, ...providerConfig } = config;

        this.config = config;
        this.chatServer = new ChatServer(serverUrl, userMode);
        this.provider = getProvider(providerConfig, "pubnub");
        this.logVerbosity = !!config.logVerbosity;

        this.listenForProviderStatus();
    }

    static generateUUID(): string {
        return Pubnub.generateUUID();
    }

    getUUID(): string {
        return this.provider.getUUID();
    }

    registerDeviceForPushNotifications(params: SdkRegisterDeviceForPNParams): Promise<void> {
        return this.provider.registerDeviceForPushNotifications(params);
    }

    unregisterDeviceForPushNotifications(params: SdkUnregisterDeviceForPNParams): Promise<void> {
        return this.provider.unregisterDeviceForPushNotifications(params);
    }

    getUser(id?: string): Promise<SdkUser> {
        return this.chatServer.getUser(id);
    }

    async setUserDetails(details: SdkUserDetails): Promise<void> {
        await this.chatServer.setUserDetails(details);
    }

    getOnlineUsers(params: SdkGetOnlineUsersParams): Promise<SdkGetOnlineUsersResponse> {
        return this.provider.getOnlineUsers(params);
    }

    async initialize(params: SdkInitializeParams): Promise<SdkInitializeResponse> {
        const { token, ttl, user, metaData } = await this.chatServer.launch(params);

        this.provider.setAuthToken(token);
        this.provider.setUUID(user.id);
        this.launchMetaData = metaData ?? {};

        this.startTokenRefreshTimer(ttl);
        this.startOnlineStatusTimer();

        return { uuid: user.id };
    }

    subscribe({ conversationIds, ...params }: SdkSubscribeParams): void {
        const subscribedConversations = this.getSubscribedConversations();
        const conversationsToSubscribe = conversationIds.filter(
            (c) => subscribedConversations.indexOf(c) === -1,
        );

        if (!conversationsToSubscribe.length) {
            return;
        }

        return this.provider.subscribe({
            conversationIds: conversationsToSubscribe,
            ...params,
        });
    }

    unsubscribe(params: SdkUnsubscribeParams): void {
        return this.provider.unsubscribe(params);
    }

    unsubscribeAll(): void {
        return this.provider.unsubscribeAll();
    }

    getSubscribedConversations(): string[] {
        return this.provider.getSubscribedConversations();
    }

    stop(): void {
        this.log("stopped");
        this.clearTimers();
        this.provider.stop();
    }

    disconnect(): void {
        this.provider.sendUserOnlineStatus({ online: false }).catch((err) => {
            this.log("error while sending user online status", err);
        });

        this.provider.disconnect();
        this.clearTimers();

        this.log("disconnected");
    }

    reconnect(): void {
        this.log("reconnected");

        this.fetchMessagesInProgress = false;
        this.sendingMessage = false;
        this.provider.reconnect();

        this.clearTimers();
        this.startTokenRefreshTimer(0, () => {
            this.provider.reconnect();
            this.flushMessages();
            this.startOnlineStatusTimer();
        });
    }

    addListener(params: SdkAddListenerParams): () => void {
        const listenerParams: ProviderAddListenerParams = {
            ...params,
            signal: (event) => {
                if (event.publisher === this.getUUID()) {
                    return;
                }

                params.signal?.({
                    ...event,
                    message: event.message as SdkMessage,
                });
            },
            message: ({ message, ...event }) => {
                if (!isValidMessage(message as SdkMessage)) {
                    return;
                }

                params.message?.({
                    ...event,
                    message: message as SdkMessage,
                });
            },
            presence: (event) => {
                if (event.uuid === this.getUUID()) {
                    return;
                }

                params.presence?.(event);
            },
            messageAction: (event) => {
                params.messageAction?.(event);
            },
        };

        return this.provider.addListener(listenerParams);
    }

    prepareMessage(params: SdkPrepareMessageParams): SdkMessage {
        return {
            type: params.type,
            payload: params.payload,
            id: ChatSdk.generateUUID(),
            timestamp: Date.now(),
        };
    }

    sendMessage(params: SdkSendMessageParams): void {
        this.messageQueue.push(params);
        this.flushMessages();
    }

    async addMessageAction(
        params: SdkAddMessageActionParams,
    ): Promise<SdkAddMessageActionResponse> {
        const response = await this.provider.addMessageAction(params);
        return response as SdkAddMessageActionResponse;
    }

    async sendSignal(params: SdkSendSignalParams): Promise<void> {
        await this.provider.sendSignal(params);
    }

    async fetchMessages(params: SdkFetchMessagesParams): Promise<SdkFetchMessagesResponse> {
        if (this.fetchMessagesInProgress) {
            return new Promise((resolve, reject) => {
                setTimeout(() => this.fetchMessages(params).then(resolve).catch(reject), 100);
            });
        }

        return this.doFetchMessages(params);
    }

    async getConversation(conversationId: string): Promise<SdkConversation> {
        const conversation = await this.chatServer.getConversation({ id: conversationId });
        return transformConversation(conversation, this.getUUID());
    }

    async createConversation(params: SdkCreateConversationsParams): Promise<SdkConversation> {
        const conversation = await this.chatServer.startConversation({ uuid: params.uuid });
        return transformConversation(conversation, this.getUUID());
    }

    setConversationLastReadTime(conversationId: string): Promise<void> {
        const useServerForLastReadTime =
            this.launchMetaData?.saveLastReadTimeEndpoint === "MINIS_SERVICE";

        return useServerForLastReadTime
            ? this.chatServer.saveLastReadTime({ conversationId })
            : this.provider.setConversationLastReadTime(conversationId);
    }

    async listConversations(
        params: SdkListConversationsParams = {},
    ): Promise<SdkListConversationsResponse> {
        this.log("fetching conversations ", params);

        const userId = this.getUUID();
        const response = await this.chatServer.fetchAllConversations(params);
        const conversations = response.conversations
            .filter((c) => c.lastMessageAt)
            .map((c) => transformConversation(c, userId));

        return { ...response, conversations };
    }

    async fetchUnreadMessagesCount(
        conversationIds: string[],
        conversationTimetokens: Record<string, string>,
    ): Promise<Record<string, number>> {
        const useServerForUnreadCount =
            this.launchMetaData?.unreadChatCountEndpoint === "MINIS_SERVICE";

        // ConversationIds are channelIds only but since the name is not changed in the SDK, we are using it as it is.
        if (useServerForUnreadCount) {
            return await this.chatServer.getUnreadMessageCount({ channelIds: conversationIds });
        }

        return await this.provider
            .getConversationsUnreadCount(conversationIds, conversationTimetokens)
            .catch((err) => {
                this.log("error while fetchUnreadMessagesCount ", err);
                return {} as Record<string, number>;
            });
    }

    async blockConversation(
        params: SdkBlockConversationRequest,
    ): Promise<SdkBlockConversationResponse> {
        return this.chatServer.blockConversation({
            id: params.id,
            blocked: params.blocked,
        });
    }

    private listenForProviderStatus(): void {
        this.provider.addListener({
            status: (event) => {
                if (event.status === ProviderStatus.AccessDeniedCategory) {
                    this.startTokenRefreshTimer();
                } else if (event.status === ProviderStatus.ReconnectedCategory) {
                    this.log("reconnected");
                    this.flushMessages();
                }
            },
        });
    }

    private startOnlineStatusTimer(timeout = 0): void {
        this.onlineStatusTimer && clearTimeout(this.onlineStatusTimer);

        if (!this.getUUID()) {
            return;
        }

        this.onlineStatusTimer = setTimeout(async () => {
            this.log("sending online status");

            try {
                await this.provider.sendUserOnlineStatus({ online: true });
                this.log("sent online status");
                this.startOnlineStatusTimer(60 * 1_000);
            } catch (err) {
                this.log("error while sending online status ", err);

                if (err instanceof HttpError) {
                    await waitFor(5_000);
                } else {
                    await waitFor(1_000);
                }

                this.startOnlineStatusTimer();
            }
        }, timeout) as unknown as number;
    }

    private startTokenRefreshTimer(ttl = 0, cb?: () => void): void {
        this.tokenRefreshTimer && clearTimeout(this.tokenRefreshTimer);

        if (!this.getUUID()) {
            return;
        }

        const timeout = (ttl / 2) * 60 * 1_000;

        this.tokenRefreshTimer = setTimeout(async () => {
            this.log("refreshing token");

            try {
                const newTtl = await this.refreshToken();
                this.log("token refreshed");
                this.startTokenRefreshTimer(newTtl);
                cb?.();
            } catch (err) {
                this.log("error while refreshing token ", err);

                if (err instanceof HttpError) {
                    await waitFor(5_000);
                } else {
                    await waitFor(1_000);
                }

                this.startTokenRefreshTimer(ttl);
            }
        }, timeout) as unknown as number;
    }

    private async refreshToken(): Promise<number> {
        if (!this.getUUID()) {
            return Promise.resolve(1);
        }

        const { token, ttl } = await this.chatServer.grantToken();
        this.provider.setAuthToken(token);

        return ttl;
    }

    private flushMessages(timeout = 0): void {
        if (this.sendingMessage) {
            return;
        }

        this.messageFlushTimer && clearTimeout(this.messageFlushTimer);
        this.messageFlushTimer = setTimeout(
            () => this.doFlushMessages(),
            timeout,
        ) as unknown as number;
    }

    private async doFetchMessages(
        params: SdkFetchMessagesParams,
    ): Promise<SdkFetchMessagesResponse> {
        this.log("fetching messages for conversations ", params.conversationIds.join(","));
        this.fetchMessagesInProgress = true;

        try {
            const response = await this.provider.fetchMessages(params);
            this.fetchMessagesInProgress = false;
            return getMessagesFromProviderResponse(response);
        } catch (err) {
            this.fetchMessagesInProgress = false;
            throw new ChatSdkError((err as Error).message, err);
        }
    }

    private doFlushMessages(): void {
        const messageToSend = this.getNextMessageToSendFromQueue();
        if (!messageToSend) {
            this.sendingMessage = false;
            return;
        }

        this.sendingMessage = true;
        const { message, retryCount } = messageToSend;

        this.provider
            .sendMessage(message)
            .then(() => {
                this.sendingMessage = false;
                this.flushMessages();
            })
            .catch((err) => {
                this.sendingMessage = false;
                this.log("publish error", err);

                let flushTimeout = retryCount * 100;
                let shouldRetry = false;

                // https://www.pubnub.com/docs/sdks/javascript/status-events
                if (err instanceof ProviderError) {
                    if (err.status === ProviderStatus.AccessDeniedCategory) {
                        flushTimeout = 3_000;
                        shouldRetry = true;
                    } else if (err.status === ProviderStatus.NetworkIssuesCategory) {
                        flushTimeout = 1_000;
                        shouldRetry = true;
                    } else if (err.status === ProviderStatus.BadRequestCategory) {
                        this.log("bad request", err);
                        return this.flushMessages(100);
                    }
                }

                if (shouldRetry || retryCount < 3) {
                    this.retryMessageQueue.push({
                        message,
                        retryCount: retryCount + 1,
                    });
                }

                this.flushMessages(flushTimeout);
            });
    }

    private getNextMessageToSendFromQueue(): {
        message: SdkSendMessageParams;
        retryCount: number;
    } | null {
        if (this.retryMessageQueue.length > 0) {
            const messageToSend = this.retryMessageQueue.pop();
            if (messageToSend) {
                return messageToSend;
            }
        }

        if (this.messageQueue.length > 0) {
            const messageToSend = this.messageQueue.shift();
            return messageToSend
                ? {
                      message: messageToSend,
                      retryCount: 0,
                  }
                : null;
        }

        return null;
    }

    private clearTimers(): void {
        this.messageFlushTimer && clearTimeout(this.messageFlushTimer);
        this.tokenRefreshTimer && clearTimeout(this.tokenRefreshTimer);
        this.onlineStatusTimer && clearTimeout(this.onlineStatusTimer);
    }

    private log(...args: unknown[]): void {
        __DEV__ &&
            this.logVerbosity &&
            typeof console !== "undefined" &&
            console.log &&
            console.log("[chat]", ...args);
    }
}
