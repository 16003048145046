/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Determines if two objects are equal by comparing their properties.
 *
 * This function performs a shallow equality check on the two objects' own properties.
 * It first checks if the number of properties in both objects are the same. If not,
 * the objects are considered not equal. If the counts are the same, it then compares
 * the values of each property in the first object to the corresponding property's value
 * in the second object. If all properties are equal, the function returns true, indicating
 * the objects are equal. Otherwise, it returns false.
 *
 * Note: This function does not handle deep object comparisons or property comparisons in
 * objects that are not plain objects (e.g., Arrays, Dates, Functions).
 *
 * @param {Record<string, any>} obj1 - The first object to compare.
 * @param {Record<string, any>} obj2 - The second object to compare.
 * @returns {boolean} - True if the objects are equal, false otherwise.
 */
export const areObjectsEqual = (obj1: Record<string, any>, obj2: Record<string, any>): boolean => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
};
