import React, { memo, useCallback, useMemo, useState } from "react";
import {
    ImageStyle,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    ViewProps,
    useWindowDimensions,
} from "react-native";

import { CdnImage, ReadMoreText } from "@swiggy-private/react-native-ui";
import { Box, ScreenSize, Stack, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, TextProps, useTheme } from "@swiggy-private/rn-dls";
import { ImageGallery } from "@swiggy-private/rn-image-gallery";

import { StarRating } from "../star-ratings";
import { getImageDetailForSubmittedReviewMsg } from "./helpers";
import { CONSTANTS, DEFAULT_VALUES } from "./constants";

export interface SubmittedReviewMessageProps {
    rating: number;

    style?: ViewProps["style"];
    imageId?: string;
    text?: string;
    imageHeight?: number;
    handleReadMoreClick?: (b: boolean) => void;
    onImageFullScreenView?: VoidFunction;
}

const toogleTextProps: TextProps = {
    category: "b2",
    weight: "bold",
    color: "color-primary",
};

const textProps: TextProps = {
    category: "b2",
    weight: "regular",
    /** commenting for consumer, to be fixed properly for both apps */
    // color: "color-basic-0",
};

const IMAGE_SIZE_SHIFT = 6;

export const SubmittedReviewMessage: React.FC<SubmittedReviewMessageProps> = memo(
    ({
        imageId,
        style,
        text,
        rating,
        imageHeight = DEFAULT_VALUES.IMAGE_HEIGHT,
        handleReadMoreClick,
        onImageFullScreenView,
    }) => {
        const screenSize = useScreenSize();
        const isDWeb = Platform.OS === "web" && screenSize === ScreenSize.Large;

        const { width: windowWidth } = useWindowDimensions();
        const { value: theme } = useTheme();

        const imageUrlOrId = getImageDetailForSubmittedReviewMsg({ rating, imageId, text });
        const isShowingBanner = !imageId && !text;

        const [hasError, setHasError] = useState(false);
        const [fullView, setFullView] = useState(false);

        const galleryViewEnabled = windowWidth <= DEFAULT_VALUES.MAX_WINDOW_SIZE_FOR_GALLERY_VIEW;
        const msgWidth = isDWeb ? DEFAULT_VALUES.MIN_MSG_WIDTH : windowWidth * 0.7;

        const isFullImageEnabled = useMemo(() => {
            if (hasError) {
                return false;
            }

            return fullView && galleryViewEnabled && imageUrlOrId.length && imageId;
        }, [fullView, hasError, imageId, imageUrlOrId.length, galleryViewEnabled]);

        const openFullView = useCallback(() => {
            const isFullViewEnabled = galleryViewEnabled && imageUrlOrId.length && imageId;

            if (isFullViewEnabled) {
                setFullView(true);
                onImageFullScreenView?.();
            }
        }, [galleryViewEnabled, imageId, imageUrlOrId.length, onImageFullScreenView]);
        const closeFullView = useCallback(() => setFullView(false), []);
        const onError = useCallback(() => setHasError(true), []);

        const imageStyle: StyleProp<ImageStyle> = [
            styles.image,
            {
                width: msgWidth + 2 * IMAGE_SIZE_SHIFT,
                height: imageHeight + IMAGE_SIZE_SHIFT,
                backgroundColor: theme["color-basic-0"],
                marginLeft: -1 * IMAGE_SIZE_SHIFT,
                marginRight: -1 * IMAGE_SIZE_SHIFT,
                marginTop: -1 * IMAGE_SIZE_SHIFT,
            },
        ];

        return (
            <Stack style={[style, { width: msgWidth }]}>
                {imageUrlOrId ? (
                    <>
                        <Pressable onPress={openFullView}>
                            <CdnImage
                                resizeMode={isShowingBanner ? "contain" : "cover"}
                                id={imageUrlOrId}
                                showLoader
                                style={imageStyle}
                                onError={onError}
                                testID={imageUrlOrId}
                            />
                        </Pressable>
                        {isFullImageEnabled ? (
                            <ImageGallery images={imageUrlOrId} onClose={closeFullView} visible />
                        ) : null}
                    </>
                ) : null}

                <StarRating
                    iconSize={DEFAULT_VALUES.STAR_ICON_SIZE}
                    ratingCount={rating}
                    showFilled
                    isDisabled
                    starColor="color-basic-30"
                    /** commenting for consumer, to be fixed properly for both apps */
                    // starFillColor="color-basic-0"
                    style={styles.starRating}
                    unselectedStarIcon="StarFilled"
                />

                {text ? (
                    <Box testID={CONSTANTS.MESSAGE_TEST_ID}>
                        <ReadMoreText
                            text={text}
                            maxLengthCount={DEFAULT_VALUES.MAX_TEXT_LENGTH_COUNT}
                            textProps={textProps}
                            expandedTextProps={toogleTextProps}
                            collapsedTextProps={toogleTextProps}
                            handleToggle={handleReadMoreClick}
                        />
                    </Box>
                ) : null}
            </Stack>
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    SubmittedReviewMessage.displayName = "SubmittedReviewMessage";
}

const styles = StyleSheet.create({
    image: {
        borderRadius: 16,
        marginBottom: SpacingValue["space-small"],
    },
    starRating: {
        justifyContent: "flex-start",
        marginBottom: SpacingValue["space-small"],
    },
});
