import React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { useTheme, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";

import { getIndicatorDotCount, isCurrentIndexActive } from "../../helpers/indicator";
import { DEFAULT_VALUES } from "./constants";

interface IndicatorProps {
    count: number;
    currentSlideIndex: number;

    style?: StyleProp<ViewStyle>;
    indicatorSize?: number;
}

interface IndicatorItemProps {
    currentSlideIndex: number;
    size: number;
    index: number;
    count: number;
}

const { ACTIVE_INDICATOR_HEIGHT_INCREASE, ACTIVE_INDICATOR_WIDTH_INCREASE } = DEFAULT_VALUES;

const IndicatorItem: React.FC<IndicatorItemProps> = React.memo(
    ({ currentSlideIndex, size, index, count }) => {
        const { value: theme } = useTheme();
        const indicatorDotCount = getIndicatorDotCount(count);
        const isActive = isCurrentIndexActive({
            index,
            currentSlideIndex,
            count,
            indicatorDotCount,
        });

        const indicatorStyle: StyleProp<ViewStyle> = {
            height: isActive ? size + ACTIVE_INDICATOR_HEIGHT_INCREASE : size,
            width: isActive ? size + ACTIVE_INDICATOR_WIDTH_INCREASE : size,
            borderRadius: size * 2,
            marginHorizontal: size / 2,
            backgroundColor: isActive ? theme["color-basic-0"] : theme["color-basic-30"],
        };

        return (
            <Box alignItems="center" justifyContent="center" style={indicatorStyle}>
                {isActive && (
                    <Text category="b3" weight="bold" color="color-basic-100">
                        {`${currentSlideIndex + 1}/${count}`}
                    </Text>
                )}
            </Box>
        );
    },
);

if (__DEV__) {
    IndicatorItem.displayName = "IndicatorItem";
}

const SliderIndicatorComponent: React.FC<IndicatorProps> = ({
    count,
    currentSlideIndex,
    indicatorSize = 8,
    style,
}) => {
    const indicatorDotCount = getIndicatorDotCount(count);

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" style={style}>
            {[...Array(indicatorDotCount)].map((_, index) => (
                <IndicatorItem
                    key={index}
                    index={index}
                    currentSlideIndex={currentSlideIndex}
                    size={indicatorSize}
                    count={count}
                />
            ))}
        </Stack>
    );
};

export const SliderIndicator = React.memo(SliderIndicatorComponent);
