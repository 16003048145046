import { ApiClient, ApiResponse } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { APIService } from "./base";
import type { User } from "../interfaces";

const API_V1_PATH = "/api/v1/auth";

export interface MobileNumberInfo {
    verified: boolean;
    registered: boolean;
    passwordEnabled: boolean;
    active: boolean;
}

export interface SignupParams {
    mobile: string | number;
    name: string;
    email: string;
    password?: string;
}

export class AuthAPIService extends APIService {
    private static create(endpoint: string, client: ApiClient): AuthAPIService {
        return new AuthAPIService(endpoint, client);
    }

    static createV1(host: string, client: ApiClient): AuthAPIService {
        return AuthAPIService.create(host + API_V1_PATH, client);
    }

    fetchMobileNumberInfo = async (mobile: string | number): Promise<MobileNumberInfo> => {
        const endpoint = Endpoint.from(`${this.endpoint}/login-check`);

        const response = await this.client.get<MobileNumberInfo>({
            endpoint,
            queryParams: { mobile: String(mobile) },
        });

        return this.handleResponse(response, "Invalid mobile number");
    };

    smsOtp = async (mobile: string | number): Promise<void> => {
        const endpoint = Endpoint.from(`${this.endpoint}/sms-otp`);

        await this.client.get({
            endpoint,
            queryParams: { mobile: String(mobile) },
        });
    };

    verifyOtp = async (otp: string | number): Promise<User> => {
        const endpoint = Endpoint.from(`${this.endpoint}/verify-otp`);

        const response = await this.client.post<User>({
            endpoint,
            queryParams: { otp: String(otp) },
        });

        return this.handleResponse(response);
    };

    signup = async ({
        mobile,
        email,
        name,
        password = "",
    }: SignupParams): Promise<ApiResponse<[]>> => {
        const endpoint = Endpoint.from(`${this.endpoint}/signup`);

        const response = await this.client.post<ApiResponse<[]>>({
            endpoint,
            body: { mobile, email, name, password },
        });

        return this.handleResponse(response);
    };
}
