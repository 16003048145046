import { useCallback, useEffect, useRef } from "react";
import type {
    ChatServiceUpdateMessageActionParams,
    SdkMessageActionEvent,
    SdkMessageEvent,
    SdkPresenceEvent,
    SdkSignalEvent,
} from "@swiggy-private/connect-chat-sdk";
import { AppState } from "react-native";

import { useChatDispatch } from "./use-chatdispatch";
import { useChatListener } from "./use-chatlistener";
import { useChatMessageAction } from "./use-chat-message-action";
import { useChatService } from "./use-chat-service";
import { logError } from "../helpers/log";
import { useChatUserId } from "./use-chatuserid";
import { ChatEvent, ChatEventMap, ChatEventTypes } from "../chat-event";
import { useChatState } from "./use-chatstate";
import { useGetConversationFromNetwork } from "./use-get-conversation";
import { AddMessageAction, ChatAction } from "../reducers/chat";
import { useChatSdk } from "./use-chatsdk";

const useOnMessage = (): ((event: SdkMessageEvent) => void) => {
    const chatDispatch = useChatDispatch();
    const addMessageAction = useChatMessageAction();
    const chatService = useChatService();
    const chatUuid = useChatUserId();

    return useCallback(
        (event: SdkMessageEvent) => {
            const message = {
                ...event.message,
                publisher: event.publisher,
                timetoken: event.timetoken,
            };

            const actions: ChatAction[] = [];

            if (event.publisher !== chatUuid) {
                actions.push({
                    type: "INCREMENT_UNREAD_COUNT_ACTION",
                    payload: {
                        conversationId: event.conversationId,
                        messageId: message.id,
                    },
                });
            } else {
                chatService
                    ?.saveConversationLastReadTime(event.conversationId, message.timestamp)
                    .catch(logError);
            }

            actions.push({
                type: "ADD_CONVERSATION_MESSAGE_ACTION",
                payload: {
                    conversationId: event.conversationId,
                    messages: [message],
                },
            });

            chatDispatch({
                type: "MULTI_DISPATCH",
                payload: actions,
            });

            addMessageAction({
                conversationId: event.conversationId,
                message: message,
                type: "ack",
            });

            chatService
                ?.putMessage({
                    message,
                    conversationId: event.conversationId,
                    unread: chatUuid === event.publisher ? false : true,
                    publisher: event.publisher,
                    timetoken: event.timetoken,
                })
                .catch(logError);

            if (event.publisher !== chatUuid) {
                ChatEvent.dispatch({
                    type: "newMessage" as ChatEventTypes,
                    handled: false,
                    value: {
                        message,
                        conversationId: event.conversationId,
                        publisher: event.publisher,
                    },
                });
            }
        },
        [addMessageAction, chatDispatch, chatService, chatUuid],
    );
};

export const useOnMessageReply = (): void => {
    const sdk = useChatSdk();
    const chatService = useChatService();
    const chatDispatch = useChatDispatch();

    useEffect(() => {
        const unsubscribe = ChatEvent.listen("reply", async (event) => {
            if (event.handled || AppState.currentState !== "active" || !chatService || !sdk) {
                return;
            }

            event.handled = true;

            const conversationId = event.value.conversationId;

            if (event.value.messageId) {
                chatService.updateMessageReadState(event.value.messageId, false).catch(logError);
            }

            if (event.value.messageTimestamp) {
                const lastReadTime = await chatService
                    .getConversationLastReadTime(event.value.conversationId)
                    .catch((_) => null);

                if (
                    lastReadTime &&
                    (lastReadTime[conversationId] || 0) < (event.value.messageTimestamp || 0)
                ) {
                    await chatService
                        .saveConversationLastReadTime(conversationId, event.value.messageTimestamp)
                        .catch(logError);

                    await sdk.setConversationLastReadTime(conversationId).catch(logError);
                }
            }

            chatDispatch({
                type: "SET_UNREAD_COUNT_ACTION",
                payload: {
                    conversationId,
                    count: 0,
                },
            });
        });

        return () => {
            unsubscribe();
        };
    });
};

export const useOnMessageNotification = (): void => {
    const chatDispatch = useChatDispatch();
    const { messages, conversations } = useChatState();

    const onMessage = useOnMessage();
    const getConversationFromNetwork = useGetConversationFromNetwork();
    const messageQueue = useRef<ChatEventMap["messageNotification"][]>([]);
    const sdk = useChatSdk();

    const processMessage = useCallback(
        (event: ChatEventMap["messageNotification"]) => {
            const { conversationId, message, publisher, timetoken } = event;

            if (message.timestamp) {
                const sdkMessageEvent: SdkMessageEvent = {
                    conversationId: conversationId,
                    message: message,
                    publisher: publisher.uuid,
                    timetoken: timetoken || String(message.timestamp * 10_000),
                };

                onMessage(sdkMessageEvent);
            }

            if (!conversations[conversationId]) {
                getConversationFromNetwork(conversationId, true).catch(logError);
            }
        },
        [conversations, getConversationFromNetwork, onMessage],
    );

    useEffect(() => {
        const unsubscribe = ChatEvent.listen("messageNotification", async (event) => {
            if (event.handled) {
                return;
            }

            event.handled = true;

            const { conversationId, message } = event.value;
            if (messages[conversationId] != null && messages[conversationId][message.id] != null) {
                return;
            }

            if (AppState.currentState !== "active") {
                const timetoken = String(message.timestamp * 10_000);
                const latestMessages = await sdk
                    ?.fetchMessages({
                        conversationIds: [conversationId],
                        count: 2,
                        endTimetoken: timetoken,
                    })
                    .catch(logError);

                if (
                    latestMessages?.conversations &&
                    Array.isArray(latestMessages.conversations[conversationId])
                ) {
                    const _message = latestMessages.conversations[conversationId].find(
                        (m) => m.message.id === message.id,
                    );

                    if (_message) {
                        messageQueue.current.push({
                            ...event.value,
                            timetoken: String(_message.timetoken),
                        });

                        await sdk
                            ?.addMessageAction({
                                messageTimetoken: String(_message.timetoken),
                                conversationId,
                                action: {
                                    type: "ack",
                                    value: String(Date.now()),
                                },
                            })
                            .catch(logError);

                        return;
                    }
                }
            }

            messageQueue.current.push(event.value);
        });

        return () => {
            unsubscribe();
        };
    }, [chatDispatch, conversations, getConversationFromNetwork, messages, onMessage, sdk]);

    useEffect(() => {
        const fn = (): void => {
            while (true) {
                if (AppState.currentState !== "active" || !messageQueue.current.length) {
                    return;
                }

                const event = messageQueue.current.shift();
                if (event) {
                    processMessage(event);
                }
            }
        };

        const timer = setInterval(fn, 1_000);
        return () => clearInterval(timer);
    }, [processMessage]);
};

const useOnSignal = (): ((event: SdkSignalEvent) => void) => {
    const chatDispatch = useChatDispatch();
    const typingTimerRef = useRef<number>();

    const onSignal = useCallback(
        ({ message, conversationId }: SdkSignalEvent) => {
            if (message === "typing_on" || message === "typing_off") {
                typingTimerRef.current && clearTimeout(typingTimerRef.current);

                const actions: ChatAction[] = [];

                actions.push({
                    type: "SET_INDICATOR_ACTION",
                    payload: {
                        conversationId: conversationId,
                        indicators: {
                            typing: message === "typing_on",
                        },
                    },
                });

                if (message === "typing_on") {
                    typingTimerRef.current = setTimeout(() => {
                        chatDispatch({
                            type: "SET_INDICATOR_ACTION",
                            payload: {
                                conversationId: conversationId,
                                indicators: {
                                    typing: false,
                                },
                            },
                        });
                    }, 3_000) as unknown as number;
                }

                if (actions.length > 0) {
                    chatDispatch({
                        type: "MULTI_DISPATCH",
                        payload: actions,
                    });
                }
            }
        },
        [chatDispatch],
    );

    useEffect(() => {
        return () => {
            typingTimerRef.current && clearTimeout(typingTimerRef.current);
        };
    }, []);

    return onSignal;
};

const useOnPresence = (): ((event: SdkPresenceEvent) => void) => {
    const chatDispatch = useChatDispatch();

    return useCallback(
        ({ action, conversationId }: SdkPresenceEvent) => {
            chatDispatch({
                type: "MULTI_DISPATCH",
                payload: [
                    {
                        type: "SET_INDICATOR_ACTION",
                        payload: {
                            conversationId: conversationId,
                            indicators: {
                                online: action === "join",
                            },
                        },
                    },
                ],
            });
        },
        [chatDispatch],
    );
};

export const useOnMessageAction = (): ((event: SdkMessageActionEvent) => void) => {
    const chatDispatch = useChatDispatch();
    const chatService = useChatService();
    const messageActionQueues = useRef<AddMessageAction["payload"][]>([]);

    useEffect(() => {
        const timer = setInterval(async () => {
            if (messageActionQueues.current.length > 0 && AppState.currentState === "active") {
                const actions = messageActionQueues.current;
                messageActionQueues.current = [];

                chatDispatch({
                    type: "MULTI_DISPATCH",
                    payload: actions.map((payload) => ({
                        type: "ADD_MESSAGE_ACTION",
                        payload,
                    })),
                });

                for await (const payload of actions) {
                    await chatService
                        ?.updateMessageAction(payload as ChatServiceUpdateMessageActionParams)
                        .catch(logError);
                }
            }
        }, 1_000);

        return () => clearInterval(timer);
    });

    return useCallback((event) => {
        messageActionQueues.current.push({
            messageTimeToken: event.action.messageTimetoken,
            conversationId: event.conversationId,
            type: event.action.type,
            actionTimetoken: event.action.actionTimetoken,
            uuid: event.action.uuid,
            value: event.action.value,
        });
    }, []);
};

export const useChatStateHandler = (): void => {
    const onSignal = useOnSignal();
    const onMessage = useOnMessage();
    const onPresence = useOnPresence();
    const onMessageAction = useOnMessageAction();

    useChatListener({
        signal: onSignal,
        message: onMessage,
        presence: onPresence,
        messageAction: onMessageAction,
    });
};
