/* eslint-disable react-native/no-inline-styles */
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { Platform, View } from "react-native";

import { IPortalContext, PortalContext } from "./portal-context";

let nextKey = 1;

/**
 * Portal host renders all of its children `Portal` elements.
 * For example, you can wrap a screen in `Portal.Host` to render items above the screen.
 *
 * ## Usage
 * ```ts
 * import * as React from 'react';
 * import { Portal } from '@swiggy-private/rn-dls';
 *
 * export const MyComponent: React.FC = () => (
 *   <Portal.Host>
 *     <App />
 *   </Portal.Host>
 * );
 * ```
 *
 * Here any `Portal` elements under `<App />` are rendered alongside `<App />`
 * and will appear above `<App />` like a `Modal`.
 */
export const PortalHost: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [elements, setElements] = useState<Array<[string, React.ReactElement]>>([]);

    const addElement: IPortalContext["addElement"] = useCallback((element, key?: string) => {
        const newElement: [string, React.ReactElement] = [key ? key : String(nextKey++), element];
        setElements((state) => [...state, newElement]);

        const update = (e: React.ReactElement): void => {
            setElements((state) => {
                const index = state.findIndex((a) => a === newElement);
                if (index !== -1) {
                    state[index][1] = e;
                    return [...state];
                }

                return state;
            });
        };

        const remove = (): void => setElements((state) => state.filter((s) => s !== newElement));

        return [update, remove];
    }, []);

    const value = useMemo(() => ({ addElement }), [addElement]);

    return (
        <PortalContext.Provider value={value}>
            <Wrapper>
                <Fragment>{children}</Fragment>
                {elements.map((element) => (
                    <Fragment key={element[0]}>{element[1]}</Fragment>
                ))}
            </Wrapper>
        </PortalContext.Provider>
    );
};

const Wrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    if (Platform.OS === "web") {
        return <Fragment>{children}</Fragment>;
    }

    return (
        <View style={{ flex: 1 }} collapsable={false}>
            {children}
        </View>
    );
};
