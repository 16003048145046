import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { useCallback, useMemo } from "react";

import { useChatUserId } from "../hooks/use-chatuserid";
import { getMessageShortText } from "../helpers/message";
import { Message } from "../interfaces/types";
import { useChatState } from "./use-chatstate";

const useGetLastMessageInState = (): ((conversationId: string) => Message | null) => {
    const { messageBlocks, messages } = useChatState();

    return useCallback(
        (conversationId) => {
            const { messageSectionSortedList, messageSections } =
                messageBlocks[conversationId] ?? {};
            if (!messageSections || !messageSectionSortedList) {
                return null;
            }

            const key = messageSectionSortedList[0];
            if (
                !messageSections[key] ||
                !messageSections[key].messages?.length ||
                !messages[conversationId]
            ) {
                return null;
            }

            const id = messageSections[key].messages[0];

            return messages[conversationId][id] || null;
        },
        [messages, messageBlocks],
    );
};

export const usePrepareConversation = (): ((conversation: SdkConversation) => SdkConversation) => {
    const getLastMessage = useGetLastMessageInState();

    const returnVal = useCallback(
        (c: SdkConversation) => {
            const lastMessage = getLastMessage(c.id);
            if (!lastMessage) {
                return c;
            }

            return {
                ...c,
                lastMessageText: getMessageShortText(lastMessage),
                lastMessageTimestamp: lastMessage.timestamp,
            };
        },
        [getLastMessage],
    );

    return returnVal;
};

export const useSortConversationsByLastMessageTime = (
    conversations: SdkConversation[],
): SdkConversation[] => {
    const prepareConversation = usePrepareConversation();
    const state = useChatState();
    const chatUserId = useChatUserId();

    return useMemo(
        () =>
            conversations
                .map(prepareConversation)
                .filter((c) => {
                    if (!c.lastMessageTimestamp) {
                        return false;
                    }

                    const conversationUser =
                        state.users[
                            c.participants.filter((p) => p.id !== chatUserId)[0]?.id ?? ""
                        ] ?? null;

                    if (!conversationUser || conversationUser.name === "unknown") {
                        return false;
                    }

                    return true;
                })
                .sort(
                    (a, b) =>
                        Number(b.lastMessageTimestamp ?? 0) - Number(a.lastMessageTimestamp ?? 0),
                ),
        [chatUserId, conversations, prepareConversation, state.users],
    );
};
