import React from "react";
import { View, FlatList, StyleSheet, FlatListProps, ViewabilityConfig } from "react-native";

import { SpacingValue } from "@swiggy-private/rn-dls";

import { StoryCircleListItem } from "./StoryCircleListItem";
import { IUserStory, StoryCircleListViewProps } from "./interfaces";

const viewabilityConfig: ViewabilityConfig = {
    waitForInteraction: false,
    itemVisiblePercentThreshold: 100,
    minimumViewTime: 300,
};

const StoryCircleListViewComponent: React.FC<StoryCircleListViewProps> = ({
    data,
    handleStoryItemPress,
    unPressedBorderColor,
    pressedBorderColor,
    unPressedAvatarTextColor,
    pressedAvatarTextColor,
    avatarSize,
    showText,
    avatarTextStyle,
    avatarImageStyle,
    avatarWrapperStyle,
    avatarFlatListProps,
    analytics,
}: StoryCircleListViewProps) => {
    const renderItem = React.useCallback(
        ({ item, index }: { item: IUserStory; index: number }) => (
            <StoryCircleListItem
                avatarSize={avatarSize}
                handleStoryItemPress={() =>
                    handleStoryItemPress && handleStoryItemPress(item, index)
                }
                unPressedBorderColor={unPressedBorderColor}
                pressedBorderColor={pressedBorderColor}
                unPressedAvatarTextColor={unPressedAvatarTextColor}
                pressedAvatarTextColor={pressedAvatarTextColor}
                item={item}
                showText={showText}
                avatarTextStyle={avatarTextStyle}
                avatarImageStyle={avatarImageStyle}
                avatarWrapperStyle={avatarWrapperStyle}
                index={index}
            />
        ),
        [
            avatarImageStyle,
            avatarSize,
            avatarTextStyle,
            avatarWrapperStyle,
            handleStoryItemPress,
            pressedAvatarTextColor,
            pressedBorderColor,
            showText,
            unPressedAvatarTextColor,
            unPressedBorderColor,
        ],
    );

    const onViewableItemsChanged: NonNullable<
        FlatListProps<IUserStory[]>["onViewableItemsChanged"]
    > = React.useCallback(
        ({ changed }) => {
            changed
                .filter((i) => i.isViewable)
                .map((item) => {
                    analytics.sendHighlightImpression(item.item.user_id);
                });
        },
        [analytics],
    );

    return (
        <FlatList
            keyExtractor={(_item, index) => index.toString()}
            data={data}
            horizontal
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
            ListFooterComponent={<View style={styles.footer} />}
            renderItem={renderItem}
            viewabilityConfig={viewabilityConfig}
            onViewableItemsChanged={onViewableItemsChanged}
            {...avatarFlatListProps}
        />
    );
};

const styles = StyleSheet.create({
    paddingLeft: {
        paddingLeft: SpacingValue["space-small"],
    },
    footer: {
        flex: 1,
        width: 8,
    },
});

export const StoryCircleListView = React.memo(StoryCircleListViewComponent);
