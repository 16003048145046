import { ForwardRefExoticComponent } from "react";
import { SectionList } from "react-native";

import withIO from "./withIO";

import type { IOSectionListPropsFinal } from "./typings/IOSectionList.d";
import withIOProps from "./typings/withIO.d";

const IOSectionList: ForwardRefExoticComponent<IOSectionListPropsFinal> = (
    withIO as unknown as typeof withIOProps
)(SectionList, [
    "flashScrollIndicators",
    "getNativeScrollRef",
    "getScrollResponder",
    "getScrollableNode",
    "scrollToEnd",
    "scrollToIndex",
    "scrollToItem",
    "scrollToOffset",
]);
export default IOSectionList;
