import React, { memo } from "react";

import { Text, TextProps } from "@swiggy-private/rn-dls";

const RupeeTextComponent: React.FC<TextProps> = (props) => (
    <Text color="color-basic-60" {...props}>
        ₹
    </Text>
);

export const RupeeText = memo(RupeeTextComponent);
