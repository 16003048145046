import type { ClipboardAPI } from "../interfaces/clipboard";

type ClipboardPackage = Omit<ClipboardAPI, "isAvailable">;

let ClipboardPackage: ClipboardPackage | null = null;

if (ClipboardPackage == null) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        ClipboardPackage = require("react-native-web").Clipboard;
    } catch (error) {}
}

export default ClipboardPackage;
