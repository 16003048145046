import { useCallback, useState } from "react";

export const useOnOff = (initialState = false): [boolean, () => void, () => void] => {
    const [show, setShow] = useState(initialState);

    const on = useCallback(() => setShow(true), []);
    const off = useCallback(() => setShow(false), []);

    return [show, on, off];
};
