export class AssertionError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "AssertionError";
    }
}

export function assertIsString(val: unknown): asserts val is string {
    if (typeof val !== "string") {
        throw new AssertionError("Not a string!");
    }
}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
    if (val === undefined || val === null) {
        throw new AssertionError(`Expected 'val' to be defined, but received ${val}`);
    }
}

export function assert(condition: unknown, msg?: string): asserts condition {
    if (!condition) {
        throw new AssertionError(msg ?? "");
    }
}
