import React, { memo, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import formatRelative from "date-fns/formatRelative";
import { Text, SpacingValue } from "@swiggy-private/rn-dls";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { Navigation } from "../../../../interfaces";

export type ChatConversationHeaderProps = {
    title?: string;
    navigation?: Navigation;
    active?: boolean;
    typing?: boolean;
    conversation?: SdkConversation;
    lastSeenAt?: number;
    userId?: string;
    callIconClickHandler?: VoidFunction;
};

const HeaderTitle: React.FC<ChatConversationHeaderProps> = memo(
    ({ title, active, typing, lastSeenAt }) => {
        return (
            <View style={styles.container}>
                <Text category="b1" color="color-basic-100" weight="bold" style={styles.title}>
                    {title ?? "Unknown"}
                </Text>
                <View style={styles.activeContainer}>
                    {active ? <View style={styles.activeDot} /> : null}
                    <Text category="b3" color="color-basic-45">
                        {typing
                            ? "Typing..."
                            : active
                            ? "Online now"
                            : lastSeenAt
                            ? "Last seen " + formatRelative(lastSeenAt, new Date())
                            : "Offline"}
                    </Text>
                </View>
            </View>
        );
    },
);

if (__DEV__) {
    HeaderTitle.displayName = "ChatConversationHeaderTitle";
}

export const ChatConversationHeader: React.FC<ChatConversationHeaderProps> = memo((props) => {
    useEffect(() => {
        props.navigation?.setOptions({
            headerTitle: () => <HeaderTitle {...props} />,
        });
    }, [props]);

    return <></>;
});

if (__DEV__) {
    ChatConversationHeader.displayName = "ChatConversationHeader";
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
    },
    title: {
        textTransform: "capitalize",
    },
    activeContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    activeDot: {
        backgroundColor: "#1BA672",
        width: 8,
        height: 8,
        borderRadius: 4,
        marginRight: SpacingValue["space-xx-small"],
        marginTop: SpacingValue["space-xxx-small"],
    },
});
