import React from "react";
import { StyleSheet } from "react-native";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";

import { EMPTY_RESULT_TEXT } from "../constants";

export const EmptySearchResultScreen: React.FC = () => {
    return (
        <Text
            color="color-basic-60"
            category="s3"
            weight="regular"
            style={[styles.txt]}
            numberOfLines={2}>
            {EMPTY_RESULT_TEXT}
        </Text>
    );
};

const styles = StyleSheet.create({
    txt: {
        marginTop: "50%",
        marginHorizontal: SpacingValue["space-medium"],
        textAlign: "center",
    },
});
