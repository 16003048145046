import { IMAGEKIT_CLOUDNAME, IMAGEKIT_MEDIA_BASE_URL } from "@env";

import { getImageKitImageUrl } from "@swiggy-private/react-native-ui";

import { SubmittedReviewMessageProps } from ".";

const getImageUrl = (imageId?: string): string | null => {
    return imageId
        ? getImageKitImageUrl({
              id: imageId,
              transformations: [{ format: "auto" }],
              cloudBaseUrl: IMAGEKIT_MEDIA_BASE_URL,
              cloudName: IMAGEKIT_CLOUDNAME,
          })
        : null;
};

export const getImageDetailForSubmittedReviewMsg = ({
    rating,
    imageId,
    text,
}: Partial<SubmittedReviewMessageProps>): string => {
    // Image should be visible when either imageId is present or only rating.
    const shouldShowImage = imageId || (!text && !imageId);

    if (!shouldShowImage) {
        return "";
    }

    // Need image url for image full view.
    const imageUrl = getImageUrl(imageId);
    const bannerId = `static-assets/${rating}-star-rating`;

    return imageUrl || bannerId;
};
