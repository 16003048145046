import { AsyncStorageService } from "@swiggy-private/rn-services";

export const getValue = async (key: string): Promise<string | null> => {
    try {
        return await AsyncStorageService.getItem(key);
    } catch (e) {
        __DEV__ && console.error(e);
    }

    return null;
};

export const getObjectValue = async <T>(key: string): Promise<T | null> => {
    try {
        const jsonValue = await AsyncStorageService.getItem(key);
        return jsonValue !== null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        __DEV__ && console.error(e);
    }

    return null;
};

export const setValue = async <T>(key: string, value: T): Promise<void> => {
    try {
        await AsyncStorageService.setItem(
            key,
            typeof value === "string" ? value : JSON.stringify(value),
        );
    } catch (e) {
        __DEV__ && console.error(e);
    }
};

export const removeValue = async (key: string): Promise<void> => {
    try {
        await AsyncStorageService.removeItem(key);
    } catch (e) {
        __DEV__ && console.error(e);
    }
};

export const multiSet = async (pairs: [string, string][]): Promise<void> => {
    try {
        await AsyncStorageService.multiSet(pairs);
    } catch (e) {
        __DEV__ && console.error(e);
    }
};

export const multiRemove = async (keys: string[]): Promise<void> => {
    try {
        await AsyncStorageService.multiRemove(keys);
    } catch (e) {
        __DEV__ && console.error(e);
    }
};

export const getMultiple = async (keys: string[]): Promise<Record<string, string | null>> => {
    let values: Record<string, string | null> = {};

    try {
        values = Object.fromEntries(await AsyncStorageService.multiGet(keys));
    } catch (e) {
        __DEV__ && console.error(e);
    }

    return values;
};

export const getAllKeys = async (): Promise<string[]> => {
    let keys: string[] = [];

    try {
        keys = await AsyncStorageService.getAllKeys();
    } catch (e) {
        __DEV__ && console.error(e);
    }

    return keys;
};

export const isWindowSessionStorageAvailable = (): boolean => {
    try {
        return typeof window !== "undefined" && typeof window.sessionStorage !== "undefined";
    } catch (e) {
        return false;
    }
};

export const setSessionValue = async <T>(key: string, value: T): Promise<void> => {
    try {
        window.sessionStorage.setItem(
            key,
            typeof value === "string" ? value : JSON.stringify(value),
        );
    } catch (e) {
        __DEV__ && console.error(e);
    }
};
