import { ActivityIndicator, useLayout } from "@swiggy-private/rn-dls";
import { useMountedRef } from "@swiggy-private/react-hooks";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    HandlerStateChangeEvent,
    PinchGestureHandler,
    PinchGestureHandlerEventPayload,
    State,
    PanGestureHandler,
    PanGestureHandlerEventPayload,
    GestureHandlerRootView,
} from "react-native-gesture-handler";
import {
    Animated,
    Image,
    LayoutChangeEvent,
    Platform,
    SafeAreaView,
    StyleSheet,
    View,
    ViewStyle,
} from "react-native";

import { ImageDetailProps } from "./types";

const IMAGE_SIZE_CACHE: Record<string, { width: number; height: number; ratio: number }> = {};
const USE_NATIVE_DRIVER = Platform.OS !== "web";

export const ImageDetail: React.FC<ImageDetailProps> = ({
    resizeMode = "contain",
    source,
    imageBackgroundColor,
    imageStyle,
}) => {
    const [containerLayout, onLayout] = useLayout();
    const [loading, setLoading] = useState(true);
    const [imageRatio, setImageRatio] = useState(IMAGE_SIZE_CACHE[source]?.ratio || 0);

    const opacity = useRef(new Animated.Value(0)).current;
    const pinchScale = useRef(new Animated.Value(1)).current;
    const translateX = useRef(new Animated.Value(0)).current;
    const translateY = useRef(new Animated.Value(0)).current;

    const containerRef = useRef<SafeAreaView>(null);
    const pinchRef = useRef();
    const panRef = useRef();

    const origin = useRef({ x: 0, y: 0 });
    const initialFocal = useRef({ x: 0, y: 0 });
    const focal = useRef(new Animated.ValueXY({ x: 0, y: 0 })).current;

    const mounted = useMountedRef();

    const onLoad = useCallback(() => {
        setLoading(false);
        Animated.timing(opacity, {
            toValue: 1,
            duration: 100,
            delay: 0,
            useNativeDriver: USE_NATIVE_DRIVER,
        }).start();
    }, [opacity]);

    const onLoadStart = useCallback(() => setLoading(true), []);

    const onPinchGestureEvent = Animated.event([{ nativeEvent: { scale: pinchScale } }], {
        useNativeDriver: USE_NATIVE_DRIVER,
    });

    const onPanEvent = Animated.event(
        [
            {
                nativeEvent: {
                    translationX: translateX,
                    translationY: translateY,
                },
            },
        ],
        { useNativeDriver: USE_NATIVE_DRIVER },
    );

    const onPanHandlerStateChange = useCallback(
        (event: HandlerStateChangeEvent<PanGestureHandlerEventPayload>) => {
            if (event.nativeEvent.oldState === State.ACTIVE) {
                Animated.parallel([
                    Animated.spring(translateX, {
                        toValue: 0,
                        useNativeDriver: USE_NATIVE_DRIVER,
                    }),
                    Animated.spring(translateY, {
                        toValue: 0,
                        useNativeDriver: USE_NATIVE_DRIVER,
                    }),
                ]).start();
            }
        },
        [translateX, translateY],
    );

    const onPinchHandlerStateChange = useCallback(
        (event: HandlerStateChangeEvent<PinchGestureHandlerEventPayload>) => {
            const eventFocal = {
                x: event.nativeEvent.focalX,
                y: event.nativeEvent.focalY,
            };

            if (event.nativeEvent.state === State.BEGAN) {
                initialFocal.current = eventFocal;
            }

            if (event.nativeEvent.state === State.ACTIVE) {
                // onStart: focalX & focalY result both to 0 on Android
                if (initialFocal.current.x === 0 && initialFocal.current.y === 0) {
                    initialFocal.current = eventFocal;
                }

                focal.setValue({
                    x: (origin.current.x - initialFocal.current.x) * (event.nativeEvent.scale - 1),
                    y: (origin.current.y - initialFocal.current.y) * (event.nativeEvent.scale - 1),
                });
            }

            if (event.nativeEvent.oldState === State.ACTIVE) {
                initialFocal.current = { x: 0, y: 0 };
                Animated.parallel([
                    Animated.spring(pinchScale, {
                        toValue: 1,
                        useNativeDriver: USE_NATIVE_DRIVER,
                    }),
                    Animated.spring(focal, {
                        toValue: { x: 0, y: 0 },
                        useNativeDriver: USE_NATIVE_DRIVER,
                    }),
                ]).start();
            }
        },
        [focal, pinchScale],
    );

    useEffect(() => {
        // eslint-disable-next-line max-params
        containerRef.current?.measure((x, y, w, h) => {
            onLayout({
                nativeEvent: { layout: { x, y, width: w, height: h } },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any);
        });

        if (!imageRatio) {
            Image.getSize(
                source,
                (width, height) => {
                    if (!width || !height) {
                        return;
                    }

                    const ratio = height / width;
                    IMAGE_SIZE_CACHE[source] = { width, height, ratio };

                    if (mounted.current) {
                        setImageRatio(ratio);
                    }
                },
                (err) => {
                    if (mounted.current) {
                        __DEV__ && console.log(err);
                        setImageRatio(1);
                    }
                },
            );
        }
    }, [imageRatio, mounted, onLayout, source]);

    const onImageContainerLayout = useCallback(({ nativeEvent: { layout } }: LayoutChangeEvent) => {
        origin.current = {
            x: layout.x + layout.width / 2,
            y: layout.y + layout.height / 2,
        };
    }, []);

    const width = containerLayout.width;
    const height = Math.min(containerLayout.height, containerLayout.width * imageRatio);

    const selectedImageStyle = {
        width,
        height,
        opacity,
        transform: [
            { translateX: focal.x },
            { translateY: focal.y },
            { translateX },
            { translateY },
            { scale: pinchScale },
        ],
    };

    const containerStyle: ViewStyle = useMemo(
        () => ({
            backgroundColor: imageBackgroundColor,
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
        }),
        [imageBackgroundColor],
    );

    return (
        <SafeAreaView
            ref={containerRef}
            style={containerStyle}
            onLayout={onLayout}
            collapsable={false}>
            {imageRatio > 0 && containerLayout.measured ? (
                <GestureHandlerRootView>
                    <PinchGestureHandler
                        onGestureEvent={onPinchGestureEvent}
                        ref={pinchRef}
                        simultaneousHandlers={[panRef]}
                        onHandlerStateChange={onPinchHandlerStateChange}>
                        <Animated.View style={{ width, height }} onLayout={onImageContainerLayout}>
                            <PanGestureHandler
                                onGestureEvent={onPanEvent}
                                ref={panRef}
                                onHandlerStateChange={onPanHandlerStateChange}
                                simultaneousHandlers={[pinchRef]}>
                                <Animated.View style={StyleSheet.absoluteFill}>
                                    <Animated.Image
                                        source={{ uri: source }}
                                        resizeMode={resizeMode}
                                        style={[selectedImageStyle, imageStyle]}
                                        resizeMethod="scale"
                                        onLoadStart={onLoadStart}
                                        onLoad={onLoad}
                                    />
                                </Animated.View>
                            </PanGestureHandler>
                        </Animated.View>
                    </PinchGestureHandler>
                </GestureHandlerRootView>
            ) : null}
            {loading ? (
                <View style={styles.activity}>
                    <ActivityIndicator size="large" />
                </View>
            ) : null}
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    image: {
        ...StyleSheet.absoluteFillObject,
        resizeMode: "cover",
    },
    activity: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: "center",
        alignItems: "center",
    },
});
