import memoizeOne from "memoize-one";
import { Theme } from "@swiggy-private/rn-dls-theme";

import {
    ToastSizeStyle,
    ToastStyle,
    ToastStyleMeta,
    ToastColorStyleMap,
} from "../interfaces/toast";

const colorStyleMap = memoizeOne(
    (theme: Theme): ToastColorStyleMap => ({
        primary: {
            backgroundColor: theme["color-basic-100"],
            textColor: theme["color-basic-0"],
        },
    }),
);

const sizeStyle = memoizeOne(
    (theme: Theme): ToastSizeStyle => ({
        paddingHorizontal: theme["space-medium"],
        paddingVertical: theme["space-small"],
        textMarginHorizontal: theme["space-x-small"],
        textFontSize: theme["text-body-3-regular-font-size"],
        textFontFamily: theme["text-body-3-regular-font-family"],
        textLineHeight: theme["text-body-3-regular-line-height"],
        letterSpacing: theme["text-body-3-regular-letter-spacing"],
        iconWidth: 20,
        iconHeight: 20,
    }),
);

export const getToastStyles = (meta: ToastStyleMeta, theme: Theme): ToastStyle => {
    const color = meta.color ?? "primary";

    const { ...colorStyle } = colorStyleMap(theme)[color];

    return {
        ...sizeStyle(theme),
        ...colorStyle,
    };
};
