import { IMAGEKIT_MEDIA_BASE_URL, IMAGEKIT_CLOUDNAME } from "@env";

import React, { memo, useCallback, useMemo, useState } from "react";
import { SdkImageMessagePayload } from "@swiggy-private/connect-chat-sdk";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Pressable, StyleSheet, useWindowDimensions, ViewProps } from "react-native";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { ImageGallery } from "@swiggy-private/rn-image-gallery";
import { getImageKitImageUrl } from "@swiggy-private/react-native-ui";

interface MessageViewImageMessageProps extends SdkImageMessagePayload {
    style?: ViewProps["style"];
    cloudName?: string;
    cloudFolderName?: string;
}

const MAX_IMAGE_SIZE = 240;
const MAX_WINDOW_SIZE_FOR_GALLERY_VIEW = 768;

export const MessageViewImageMessage: React.FC<MessageViewImageMessageProps> = memo(
    ({ full, thumbnail, style }) => {
        const { value: theme } = useTheme();
        const [hasError, setHasError] = React.useState(false);
        const { width: windowWidth } = useWindowDimensions();

        const imageId = thumbnail || full;
        const canShowImage = typeof imageId === "string" && imageId.length > 0 && !hasError;
        const imageSize = Math.min(windowWidth * 0.65, MAX_IMAGE_SIZE);

        const [fullView, setFullView] = useState(false);
        const galleryViewEnabled = windowWidth <= MAX_WINDOW_SIZE_FOR_GALLERY_VIEW;

        const thumbnailImageUrl = useMemo(
            () =>
                thumbnail
                    ? getImageKitImageUrl({
                          id: thumbnail,
                          transformations: [{ format: "auto" }],
                          cloudBaseUrl: IMAGEKIT_MEDIA_BASE_URL,
                          cloudName: IMAGEKIT_CLOUDNAME,
                      })
                    : null,
            [thumbnail],
        );

        const fullImageUrl = useMemo(
            () =>
                getImageKitImageUrl({
                    id: full,
                    transformations: [{ format: "auto" }],
                    cloudBaseUrl: IMAGEKIT_MEDIA_BASE_URL,
                    cloudName: IMAGEKIT_CLOUDNAME,
                }),
            [full],
        );

        const openFullView = useCallback(() => setFullView(true), []);
        const closeFullView = useCallback(() => setFullView(false), []);
        const onError = useCallback(() => setHasError(true), []);
        const imageStyle = useMemo(
            () => [
                styles.image,
                {
                    width: imageSize,
                    height: imageSize,
                    backgroundColor: theme["color-basic-5"],
                },
            ],
            [imageSize, theme],
        );

        if (!canShowImage || !imageSize || !fullImageUrl) {
            return null;
        }

        return (
            <>
                <Pressable style={style} onPress={openFullView}>
                    <CdnImage
                        resizeMode="cover"
                        id={thumbnailImageUrl || fullImageUrl}
                        showLoader
                        style={imageStyle}
                        onError={onError}
                    />
                </Pressable>
                {fullView && galleryViewEnabled ? (
                    <ImageGallery images={fullImageUrl} onClose={closeFullView} visible />
                ) : null}
            </>
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    MessageViewImageMessage.displayName = "MessageViewImageMessage";
}

const styles = StyleSheet.create({
    image: {
        borderRadius: 16,
    },
    text: {
        marginTop: SpacingValue["space-small"],
    },
});
