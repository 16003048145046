export const removeBrBeforeImg = (htmlString: string): string => {
    if (!htmlString?.trim()) {
        return "";
    }

    const brBeforeImgRegex = /<br\s*\/?>\s*<img/gi;
    return htmlString.replace(brBeforeImgRegex, "<img");
};

export const wrapImagesWithClass = (htmlString: string, divClass: string): string => {
    if (!htmlString?.trim() || !divClass?.trim()) {
        return "";
    }

    const imgRegex = /<img\s+.*?\/?>/g;

    const modifiedHTML = htmlString.replace(imgRegex, (match) => {
        return "<div class=" + divClass + ">" + match + "</div>";
    });

    return modifiedHTML;
};

export const getParsedDescriptionWithImageContainer = (
    htmlString: string,
    divClass: string,
): string => {
    if (!htmlString?.trim()) {
        return "";
    }

    let parsedDescription = "";

    try {
        parsedDescription = JSON.parse(htmlString);
    } catch (e) {
        parsedDescription = htmlString;
    }
    const withoutBrTags = removeBrBeforeImg(parsedDescription);
    const imageTagsWrapped = wrapImagesWithClass(withoutBrTags, divClass);

    return imageTagsWrapped;
};

const findAllImageTags = (htmlString: string): RegExpMatchArray | null => {
    const regex = /<img[^>]+>/g;
    const images = htmlString.match(regex);

    return images;
};

const addStyleToImageTags = (
    images: RegExpMatchArray,
    htmlString: string,
    style: string,
): string => {
    let htmlWithStyledImages = htmlString;

    images.forEach(function (image) {
        const styledImage = image.replace(">", ` style="${style}">`);
        htmlWithStyledImages = htmlWithStyledImages.replace(image, styledImage);
    });

    return htmlWithStyledImages;
};

export const styleImagesWithRegex = (htmlString: string, style: string): string => {
    if (!htmlString.trim()) {
        return "";
    }

    // Use regex to find all <img> tags
    const images = findAllImageTags(htmlString);

    // Apply the style to each <img> tag found
    if (images) {
        return addStyleToImageTags(images, htmlString, style);
    }

    return htmlString;
};

export const addBoldTag = (inputString: string): string => {
    if (!inputString.trim()) {
        return "";
    }

    const withBoldTags = inputString.replace(
        /<span([^>]*)style="[^"]*\bfont-weight:\s*bold\b[^"]*"[^>]*>(.*?)<\/span>/gi,
        "<b$1>$2</b>",
    );

    return withBoldTags;
};

export const removeStyleTags = (inputString: string): string => {
    if (!inputString.trim()) {
        return "";
    }

    return inputString.replace(/ style="[^"]*"/g, "");
};

export const hasImageTags = (htmlString: string): boolean => {
    if (!htmlString?.trim()) {
        return false;
    }

    const imgRegex = /<img\b[^>]*>/i;
    return imgRegex.test(htmlString);
};
