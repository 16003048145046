import React, { useContext, useEffect } from "react";
import { View } from "react-native";

import { assert } from "../../helpers/assert";
import { FormContext } from "./form-context";

type FormFieldProps<T, V> = T & {
    name: string;
    value: V;
    initialValue?: V;
    component?: React.FC<T>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export function FormField<V, T = {}>(props: FormFieldProps<T, V>): React.ReactElement | null {
    const { component = View, name, value, initialValue, ...propsToPass } = props;
    assert(name.length > 0, "form field name is missing");

    const { setField } = useContext(FormContext);
    useEffect(() => setField(name, value), [name, setField, value]);

    return React.createElement(
        component as React.FC<React.PropsWithChildren<T>>,
        propsToPass as unknown as React.PropsWithChildren<T>,
    );
}
