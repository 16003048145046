import React, { useEffect, useRef } from "react";
import type { VideoProperties } from "react-native-video";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import { createElement, StyleSheet } from "react-native";

let idCounter = 0;

export const Video: React.FC<VideoProperties> = ({
    onVideoEnd,
    onVideoError,
    onPlaybackStalled,
    onVideoSeek,
    onSeek,
    ...props
}) => {
    const idRef = useRef<string>();

    if (!idRef.current) {
        idRef.current = "rn_video_" + idCounter++;
    }

    const videoId = "video_" + idRef.current;
    const styles = StyleSheet.flatten(props.style);
    const width = (styles.width as number) ?? 0;
    const height = (styles.height as number) ?? 0;

    const attrs: React.DetailedHTMLProps<
        React.VideoHTMLAttributes<HTMLVideoElement>,
        HTMLVideoElement
    > = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        src: props.source?.uri || "",
        poster: props.poster,
        controls: props.controls,
        loop: props.repeat,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        style: props.style,
        muted: props.muted,
        playsInline: props.fullscreen !== true,
        controlsList: "noremoteplayback",
        crossOrigin: "anonymous",
        disableRemotePlayback: true,
        autoPlay: false,
        disablePictureInPicture: true,
        id: videoId,
        width,
        height,
    };

    useEffect(() => {
        const videoElement = document.getElementById(videoId) as HTMLVideoElement;
        if (videoElement) {
            if (props.paused) {
                videoElement.pause();
            } else {
                videoElement.play();
            }
        }
    }, [props.paused, videoId]);

    useEffect(() => {
        const videoElement = document.getElementById(videoId) as HTMLVideoElement;

        videoElement?.addEventListener("ended", (_) => onVideoEnd?.());
        videoElement?.addEventListener("error", (_) => onVideoError?.());
        videoElement?.addEventListener("stalled", (_) => onPlaybackStalled?.());
        videoElement?.addEventListener("seeking", (_) => onVideoSeek?.());
    }, [onPlaybackStalled, onVideoEnd, onVideoError, onVideoSeek, props, props.paused, videoId]);

    return createElement("video", attrs);
};
