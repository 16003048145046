import {
    getFacebookUser,
    removeFacebookUser,
    retrievePixelIDCollection,
    savePixelIDCollection,
    setFacebookUser,
} from "./cache-helpers";
import FB_GRAPH_API from "./fb-graph";
import { ERROR_FB_MESSAGE } from "./helpers";
import {
    ICustomData,
    IFBAdsData,
    TEventName,
    TInitialiseSDK,
    TPixelIDCollection,
    TUserDeviceData,
} from "./interface";

class META_SDK {
    static #accessToken: string;
    static errorMessage: string;
    static storeID = "";
    static metaPixelId = "";
    static adsAccountId = "";
    static enableEventCapture = false;
    static metaPixelIdCollection: TPixelIDCollection = [];
    static userDeviceData: TUserDeviceData = {
        name: "",
        mobile: "",
        email: "",
        platform: "",
        osVersion: "",
        osName: "",
        userAgent: "",
    };

    /**
     * * Invoked on Seller App to set accessToken
     *
     * @static
     * @param {string} val
     * @memberof META_SDK
     */
    static setAccessToken = (val: string): void => {
        const userObject = this.getUserData();
        this.#accessToken = val;
        setFacebookUser(this.storeID, { ...userObject, accessToken: val });
    };

    /**
     * * Sets users selected pixelID
     *
     * @static
     * @param {string} val
     * @memberof META_SDK
     */
    static setPixelID = (val: string): void => {
        const userObject = this.getUserData();
        this.metaPixelId = val;
        setFacebookUser(this.storeID, { ...userObject, metaPixelId: val });
    };

    /**
     * * Sets available pixelIDs for a Seller
     *
     * @static
     * @param {TPixelIDCollection} val
     * @memberof META_SDK
     */
    static setPixelIDCollection = (val: TPixelIDCollection): void => {
        this.metaPixelIdCollection = val;
        savePixelIDCollection(this.storeID, val);
    };

    /**
     * * Gets available pixelIDs for a Seller
     *
     * @static
     * @param {string} storeId
     * @memberof META_SDK
     */
    static getPixelIDCollection = (storeId?: string): TPixelIDCollection => {
        const pixelCollection = retrievePixelIDCollection(storeId || this.storeID);

        if (pixelCollection?.length) {
            this.metaPixelIdCollection = pixelCollection;
        }

        return pixelCollection ?? [];
    };

    // * Sets  storeID
    static setStoreID = (val: string): void => {
        this.storeID = val;
    };

    /**
     * * Invoked to save data coming from BE API
     *
     * @static
     * @param {IFBAdsData} userData
     * @memberof META_SDK
     */
    static setUserData = (userData: IFBAdsData): void => {
        const { accessToken, metaPixelId, adsAccountId, enableEventCapture } = userData;
        if (typeof accessToken === "string") {
            this.#accessToken = accessToken;
        }
        if (typeof adsAccountId === "string") {
            this.adsAccountId = adsAccountId;
        }
        if (typeof metaPixelId === "string") {
            this.metaPixelId = metaPixelId;
        }
        if (typeof enableEventCapture === "boolean") {
            this.enableEventCapture = enableEventCapture;
        }
        // * saves into storage
        setFacebookUser(this.storeID, {
            accessToken,
            metaPixelId,
            adsAccountId,
            enableEventCapture,
        });
    };

    /**
     * * retrieves Meta Data from storage
     *
     * @static
     * @memberof META_SDK
     */
    static getUserData = (): IFBAdsData => {
        const userObject = getFacebookUser(this.storeID);
        if (userObject?.accessToken) {
            return userObject;
        }
        return {
            accessToken: this.#accessToken,
            metaPixelId: this.metaPixelId,
            adsAccountId: this.adsAccountId,
            enableEventCapture: this.enableEventCapture,
        };
    };

    static deleteUserData = (): void => {
        this.#accessToken = "";
        this.metaPixelId = "";
        this.adsAccountId = "";
        this.enableEventCapture = false;
        removeFacebookUser(this.storeID);
    };

    /**
     * * Used on consumer side
     *
     * @static
     * @param {{
     *         storeInfo: Store;
     *         platform?: string;
     *         userInfo?: TAppUser;
     *         deviceInfo?: TDeviceInfo;
     *     }} {
     *         userInfo,
     *         platform,
     *         storeInfo,
     *         deviceInfo,
     *     }
     * @memberof META_SDK
     */
    static initialize = ({
        userInfo,
        platform,
        storeInfo,
        deviceInfo,
        userAgent,
    }: TInitialiseSDK): void => {
        const { metaPixelId } = storeInfo?.facebookAdsUserDetails || {};
        if (storeInfo?.storeId) {
            this.setStoreID(storeInfo?.storeId);
        }

        this.userDeviceData = {
            name: userInfo?.name ?? "",
            mobile: userInfo?.mobile ?? "",
            platform: platform ?? "web",
            osName: deviceInfo?.osName,
            osVersion: deviceInfo?.osVersion,
            userAgent: userAgent ?? "",
        };

        if (storeInfo?.facebookAdsUserDetails) {
            this.setUserData(storeInfo?.facebookAdsUserDetails);
        }

        // send the init event to pixel.
        if (typeof window !== "undefined" && typeof window.fbq === "function" && metaPixelId) {
            try {
                window.fbq("init", metaPixelId);
            } catch (err) {
                throw err;
            }
        }
    };

    /**
     * * returns selected pixel (its ID and Label )
     *
     * @static
     * @param {string} [pixel_id]
     * @memberof META_SDK
     */
    static getSelectedPixel = (pixel_id?: string): TPixelIDCollection[0] => {
        // * retrieve from cache
        const pixelCollection = this.getPixelIDCollection();

        const pixelID = this.getUserData()?.metaPixelId;
        const label = pixelCollection.find((val) => val?.id === (pixel_id ?? pixelID))?.label ?? "";
        return { id: pixel_id ?? pixelID, label: label };
    };

    /**
     * * retrieves Pixel-ID from FB API , based on access-token
     *
     * @static
     * @param {string} token
     * @memberof META_SDK
     */
    static retrievePixelIDs = async (token?: string): Promise<[TPixelIDCollection, string]> => {
        if (!this.#accessToken && !token) {
            return [[], ""];
        }
        if (token) {
            this.setAccessToken(token);
        }

        // * clear the errorMessage
        this.errorMessage = "";

        const [pixelIDs, errorCode] = await FB_GRAPH_API.fetchPixelID(this.#accessToken);

        if (errorCode) {
            this.errorMessage = ERROR_FB_MESSAGE[errorCode];
        }

        if (pixelIDs) {
            this.setPixelIDCollection(pixelIDs);
        }

        // * returns collections of pixelIDs to the FE compos
        return [pixelIDs, this.errorMessage];
    };

    /**
     * * Prepares attributes and sends FB pixel events
     *
     * @static
     * @param {TEventName} eventName
     * @param {ICustomData} customData
     * @param {string} [userAgent]
     * @memberof META_SDK
     */
    static fireEvents = (eventName: TEventName, customData: ICustomData): void => {
        if (!eventName) {
            return;
        }

        const FBMetaData = this.getUserData();

        FB_GRAPH_API.postMetaEvents({
            eventName,
            customData,
            FBMetaData,
            userDeviceData: this.userDeviceData,
        });
    };
}

export default META_SDK;

// export all interfaces
export * from "./interface";
