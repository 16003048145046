import { reverseGeocodeApi } from "../network";

export interface GetAddressDetailsResponse {
    locationTitle: string;
    streetAddress: string;
    postalCode: string;
    locality: string;
    city: string;
    stateValue: string;
    country: string;
}

interface GetAddressDetailsParams {
    latitude: number;
    longitude: number;
    baseUrl: string;
}

export const getAddressDetails = async ({
    latitude,
    longitude,
    baseUrl,
}: GetAddressDetailsParams): Promise<GetAddressDetailsResponse> => {
    try {
        const addressData = {
            locationTitle: "",
            streetAddress: "",
            locality: "",
            city: "",
            stateValue: "",
            country: "",
            postalCode: "",
        };

        const response = await reverseGeocodeApi(latitude, longitude, { baseUrl });

        const addressObject = response.data[0]?.address_components || [];

        addressData.streetAddress = response.data[0]?.formatted_address || "";

        let subLocality = "";

        addressObject.forEach((address) => {
            if (address.types[0] === "city") {
                addressData.city = address.long_name;
            } else if (address.types[0] === "state") {
                addressData.stateValue = address.long_name;
            } else if (address.types[0] === "locality") {
                addressData.locality = address.long_name;
            } else if (address.types[0] === "country") {
                addressData.country = address.long_name;
            } else if (address.types[0] === "postal_code") {
                addressData.postalCode = address.long_name;
            } else if (address.types[0] === "premise") {
                addressData.locationTitle = address.long_name;
            } else if (address.types[0] === "sublocality") {
                subLocality = address.long_name;
            }
        });

        /**
         * This is an edge case, where we might not get premise or sublocality. In Swiggy App, they show "Set Location" in that case
         */
        if (!addressData.locationTitle.length) {
            addressData.locationTitle = subLocality || "Set Location";
        }

        return addressData;
    } catch (err) {
        return Promise.reject(err as string);
    }
};

// eslint-disable-next-line no-void
export const EmptyFunction = (): void => void 0;

/**
 * Function for removing spaces from string.
 *
 * @param str - The string
 * @returns the string with no spaces.
 */
export const removeSpaces = (str: string): string => str.replace(/\s/g, "");
