import React from "react";
import { Animated, StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";

interface IAnimatedDot {
    i: number;
    scrollWidth: number;
    scrollAnimatedValue: Animated.AnimatedValue;
    numberOfCards?: number;

    activeColor?: string;
    inActiveColor?: string;
}

const ICON_SIZE = 8;
const SCALED_WIDTH = 32;
const SCALED_HEIGHT = 18;

const ICON_RADIUS = ICON_SIZE / 2;
const SCALED_ICON_RADIUS = SCALED_WIDTH / 2;

const AnimatedDot: React.FC<IAnimatedDot> = ({
    i = 0,
    scrollAnimatedValue,
    numberOfCards,
    scrollWidth,
    activeColor,
    inActiveColor,
}) => {
    const scaleAnimation = scrollAnimatedValue.interpolate({
        inputRange: [
            (i - 3) * scrollWidth,
            (i - 2) * scrollWidth,
            (i - 1) * scrollWidth,
            i * scrollWidth,
            (i + 1) * scrollWidth,
            (i + 2) * scrollWidth,
            (i + 3) * scrollWidth,
        ],
        outputRange: [0.7, 0.8, 1, 1, 1, 0.8, 0.7],
        extrapolate: "clamp",
    });

    const inactiveOpacityAnimation = scrollAnimatedValue.interpolate({
        inputRange: [
            (i - 2) * scrollWidth,
            (i - 1) * scrollWidth,
            i * scrollWidth,
            (i + 1) * scrollWidth,
            (i + 2) * scrollWidth,
        ],
        outputRange: [0.3, 0.5, 1, 0.5, 0.3],
        extrapolate: "clamp",
    });

    const widthAnimation = scrollAnimatedValue.interpolate({
        extrapolate: "clamp",
        inputRange: [(i - 1) * scrollWidth, i * scrollWidth, (i + 1) * scrollWidth],
        outputRange: [ICON_SIZE, SCALED_WIDTH, ICON_SIZE],
    });

    const heightAnimation = scrollAnimatedValue.interpolate({
        extrapolate: "clamp",
        inputRange: [(i - 1) * scrollWidth, i * scrollWidth, (i + 1) * scrollWidth],
        outputRange: [ICON_SIZE, SCALED_HEIGHT, ICON_SIZE],
    });

    const opacityAnimation = scrollAnimatedValue.interpolate({
        extrapolate: "clamp",
        inputRange: [(i - 1) * scrollWidth, i * scrollWidth, (i + 1) * scrollWidth],
        outputRange: [0, 1, 0],
    });

    const radiusAnimation = scrollAnimatedValue.interpolate({
        extrapolate: "clamp",
        inputRange: [(i - 1) * scrollWidth, i * scrollWidth, (i + 1) * scrollWidth],
        outputRange: [ICON_RADIUS, SCALED_ICON_RADIUS, ICON_RADIUS],
    });

    return (
        <Animated.View
            style={[
                styles.animatedDotContainer,
                { backgroundColor: inActiveColor },
                {
                    transform: [
                        {
                            scale: scaleAnimation,
                        },
                    ],
                    width: widthAnimation,
                    height: heightAnimation,
                    borderRadius: radiusAnimation,
                    opacity: inactiveOpacityAnimation,
                },
            ]}>
            <Animated.View
                style={[
                    styles.activeDot,
                    { backgroundColor: activeColor },
                    {
                        opacity: opacityAnimation,
                        width: widthAnimation,
                        height: heightAnimation,
                        borderRadius: radiusAnimation,
                    },
                ]}>
                <Text style={[styles.iconStyles]} color="color-basic-0">{`${
                    i + 1
                }/${numberOfCards}`}</Text>
            </Animated.View>
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    animatedDotContainer: {
        borderRadius: 4,
        width: ICON_SIZE + 2,
        height: ICON_SIZE + 2,
        marginRight: SpacingValue["space-x-small"],
    },
    activeDot: {
        flex: 1,
        borderRadius: ICON_RADIUS,
        alignItems: "center",
        justifyContent: "center",
    },
    animatedDotsView: {
        height: ICON_SIZE,
        flexDirection: "row",
    },
    iconStyles: {
        fontSize: 11,
        fontWeight: "900",
    },
});

export default AnimatedDot;
