import React from "react";
import { useErrorHandler } from "react-error-boundary";
import { useDebounceFn, useMountedRef } from "@swiggy-private/react-hooks";

import { ApiError } from "@swiggy-private/connect-api-core";

import { getAddressDetails, GetAddressDetailsResponse } from "../helpers";
import { useMapEndpointContext } from "../contexts/map-endpoint-context";

type AddressDataProps = { latitudeValue: number; longitudeValue: number };

type AddressDataResponse = GetAddressDetailsResponse;

export const useAddressData = ({
    latitudeValue,
    longitudeValue,
}: AddressDataProps): AddressDataResponse | null => {
    const { mapEndpoint } = useMapEndpointContext();
    const mountedRef = useMountedRef();
    const [error, setErr] = React.useState<ApiError | null>(null);

    const [data, setData] = React.useState<null | AddressDataResponse>(null);

    const getData = useDebounceFn(
        React.useCallback(async () => {
            try {
                const {
                    locationTitle,
                    streetAddress,
                    postalCode,
                    locality,
                    city,
                    stateValue,
                    country,
                } = await getAddressDetails({
                    latitude: latitudeValue,
                    longitude: longitudeValue,
                    baseUrl: mapEndpoint,
                });

                mountedRef.current &&
                    setData({
                        locationTitle,
                        streetAddress,
                        postalCode,
                        locality,
                        city,
                        stateValue,
                        country,
                    });
            } catch (err) {
                mountedRef.current && setErr(err as ApiError);
            }
        }, [latitudeValue, longitudeValue, mapEndpoint, mountedRef]),
    );

    useErrorHandler(error);

    React.useEffect(() => {
        if (latitudeValue > 0 && longitudeValue > 0) {
            getData();
        }
    }, [getData, latitudeValue, longitudeValue]);

    return data;
};
