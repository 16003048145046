import { ColorPalette, BaseColorPalette, ThemeType } from "../interfaces";
import { calculateShades, calculateTints } from "./color";

import { BaseTheme } from "@swiggy-private/dls-web-common/dist/components/themes/Base";

interface GenerateColorPaletteParams {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    criticalColor: string;
    highlightColor: string;
    warningColor: string;
    positiveColor: string;
}

export const generateColorPalette = (
    params: GenerateColorPaletteParams,
    type: ThemeType,
): ColorPalette => {
    const {
        primaryColor,
        secondaryColor,
        tertiaryColor,
        criticalColor,
        highlightColor,
        warningColor,
        positiveColor,
    } = params;

    const primaryColorShades = calculateShades(primaryColor);
    const primaryColorTints = calculateTints(primaryColor);

    const secondaryColorShades = calculateShades(secondaryColor);
    const secondaryColorTints = calculateTints(secondaryColor);

    const tertiaryColorShades = calculateShades(tertiaryColor);
    const tertiaryColorTints = calculateTints(tertiaryColor);

    const criticalColorShades = calculateShades(criticalColor);
    const criticalColorTints = calculateTints(criticalColor);

    const highlightColorShades = calculateShades(highlightColor);
    const highlightColorTints = calculateTints(highlightColor);

    const warningColorShades = calculateShades(warningColor);
    const warningColorTints = calculateTints(warningColor);

    const positiveColorShades = calculateShades(positiveColor);
    const positiveColorTints = calculateTints(positiveColor);

    const baseColorPalette: BaseColorPalette = {
        "color-primary": primaryColor,
        "color-primary-light": primaryColorTints[9],
        "color-primary-dark": primaryColorShades[9],
        "color-primary-25": primaryColorTints[9],
        "color-primary-50": primaryColorTints[6],
        "color-primary-100": primaryColorTints[4],
        "color-primary-200": primaryColorTints[2],
        "color-primary-300": primaryColorTints[1],
        "color-primary-400": primaryColor,
        "color-primary-500": primaryColorShades[1],
        "color-primary-600": primaryColorShades[2],
        "color-primary-700": primaryColorShades[4],
        "color-primary-800": primaryColorShades[6],
        "color-primary-900": primaryColorShades[9],

        "color-secondary": secondaryColor,
        "color-secondary-light": secondaryColorTints[9],
        "color-secondary-dark": secondaryColorShades[9],
        "color-secondary-25": secondaryColorTints[9],
        "color-secondary-50": secondaryColorTints[6],
        "color-secondary-100": secondaryColorTints[4],
        "color-secondary-200": secondaryColorTints[2],
        "color-secondary-300": secondaryColorTints[1],
        "color-secondary-400": secondaryColor,
        "color-secondary-500": secondaryColorShades[1],
        "color-secondary-600": secondaryColorShades[2],
        "color-secondary-700": secondaryColorShades[4],
        "color-secondary-800": secondaryColorShades[6],
        "color-secondary-900": secondaryColorShades[9],

        "color-tertiary": tertiaryColor,
        "color-tertiary-light": tertiaryColorTints[9],
        "color-tertiary-dark": tertiaryColorShades[9],
        "color-tertiary-25": tertiaryColorTints[9],
        "color-tertiary-50": tertiaryColorTints[6],
        "color-tertiary-100": tertiaryColorTints[4],
        "color-tertiary-200": tertiaryColorTints[2],
        "color-tertiary-300": tertiaryColorTints[1],
        "color-tertiary-400": tertiaryColor,
        "color-tertiary-500": tertiaryColorShades[1],
        "color-tertiary-600": tertiaryColorShades[2],
        "color-tertiary-700": tertiaryColorShades[4],
        "color-tertiary-800": tertiaryColorShades[6],
        "color-tertiary-900": tertiaryColorShades[9],

        "color-critical": criticalColor,
        "color-critical-light": criticalColorTints[9],
        "color-critical-dark": criticalColorShades[9],
        "color-critical-25": criticalColorTints[9],
        "color-critical-50": criticalColorTints[6],
        "color-critical-100": criticalColorTints[4],
        "color-critical-200": criticalColorTints[2],
        "color-critical-300": criticalColorTints[1],
        "color-critical-400": criticalColor,
        "color-critical-500": criticalColorShades[1],
        "color-critical-600": criticalColorShades[2],
        "color-critical-700": criticalColorShades[4],
        "color-critical-800": criticalColorShades[6],
        "color-critical-900": criticalColorShades[9],

        "color-highlight": highlightColor,
        "color-highlight-light": highlightColorTints[9],
        "color-highlight-dark": highlightColorShades[9],
        "color-highlight-25": highlightColorTints[9],
        "color-highlight-50": highlightColorTints[6],
        "color-highlight-100": highlightColorTints[4],
        "color-highlight-200": highlightColorTints[2],
        "color-highlight-300": highlightColorTints[1],
        "color-highlight-400": highlightColor,
        "color-highlight-500": highlightColorShades[1],
        "color-highlight-600": highlightColorShades[2],
        "color-highlight-700": highlightColorShades[4],
        "color-highlight-800": highlightColorShades[6],
        "color-highlight-900": highlightColorShades[9],

        "color-warning": warningColor,
        "color-warning-light": warningColorTints[9],
        "color-warning-dark": warningColorShades[9],
        "color-warning-25": warningColorTints[9],
        "color-warning-50": warningColorTints[6],
        "color-warning-100": warningColorTints[4],
        "color-warning-200": warningColorTints[2],
        "color-warning-300": warningColorTints[1],
        "color-warning-400": warningColor,
        "color-warning-500": warningColorShades[1],
        "color-warning-600": warningColorShades[2],
        "color-warning-700": warningColorShades[4],
        "color-warning-800": warningColorShades[6],
        "color-warning-900": warningColorShades[9],

        "color-positive": positiveColor,
        "color-positive-light": positiveColorTints[9],
        "color-positive-dark": positiveColorShades[9],
        "color-positive-25": positiveColorTints[9],
        "color-positive-50": positiveColorTints[6],
        "color-positive-100": positiveColorTints[4],
        "color-positive-200": positiveColorTints[2],
        "color-positive-300": positiveColorTints[1],
        "color-positive-400": positiveColor,
        "color-positive-500": positiveColorShades[1],
        "color-positive-600": positiveColorShades[2],
        "color-positive-700": positiveColorShades[4],
        "color-positive-800": positiveColorShades[6],
        "color-positive-900": positiveColorShades[9],

        "color-basic-100": "#02060C",
        "color-basic-75": "#36393E",
        "color-basic-60": "#66686E",
        "color-basic-45": "#909197",
        "color-basic-30": "#BABBC0",
        "color-basic-15": "#E2E2E7", // Greys/Slate 15
        "color-basic-5": "#F0F0F5",
        "color-basic-0": "#FFFFFF",

        "color-basic-alpha-92": "rgba(2, 6, 12, 0.92)",
        "color-basic-alpha-75": "rgba(2, 6, 12, 0.75)",
        "color-basic-alpha-60": "rgba(2, 6, 12, 0.60)",
        "color-basic-alpha-45": "rgba(2, 6, 12, 0.45)",
        "color-basic-alpha-30": "rgba(2, 6, 12, 0.30)",
        "color-basic-alpha-15": "rgba(2, 6, 12, 0.15)",
        "color-basic-alpha-5": "rgba(2, 6, 12, 0.05)",
    };

    const swiggyColors = BaseTheme.colors[type];
    return {
        ...baseColorPalette,
        ...swiggyColors,

        "color-positive": baseColorPalette["color-positive-400"],
        "color-warning": baseColorPalette["color-warning-400"],
        "color-negative": baseColorPalette["color-critical-400"],
        "color-highlight": baseColorPalette["color-highlight-400"],

        "color-background-primary": baseColorPalette["color-basic-0"],
        "color-background-secondary": baseColorPalette["color-basic-5"],

        "color-overlay-primary": baseColorPalette["color-basic-alpha-15"],
        "color-overlay-secondary": baseColorPalette["color-basic-alpha-60"],

        "color-surface-primary": baseColorPalette["color-basic-0"],
        "color-surface-secondary": baseColorPalette["color-basic-0"],
        "color-surface-warning": baseColorPalette["color-warning-light"],
        "color-surface-critical": baseColorPalette["color-critical-light"],
        "color-surface-success": baseColorPalette["color-primary-light"],
        "color-surface-highlight": baseColorPalette["color-highlight-light"],
    };
};
