import { Platform } from "react-native";

import { Endpoint, HttpClient } from "@swiggy-private/http-client";

import {
    LocationApiResponse,
    ReverseGeocodeApiResponse,
    FetchGeocodeApiResponse,
    MapApiOpts,
} from "../interfaces";

const webUrlPath = "/api/v1/locations";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const httpClient = new HttpClient<any>();

/**
 * The API to fetch locations based on keyword(s).
 *
 *
 * @returns {@link Promise} with value as {@link LocationApiResponse}
 */
export const fetchLocationsApi = async (
    input: string,
    apiOpts: MapApiOpts,
): Promise<LocationApiResponse> => {
    try {
        if (Platform.OS === "web") {
            return await httpClient.get({
                endpoint: Endpoint.from(`${apiOpts.baseUrl}${webUrlPath}/autocomplete`),
                queryParams: { input },
            });
        }

        return await httpClient.get({
            endpoint: Endpoint.from(`${apiOpts.baseUrl}/place/autocomplete`),
            queryParams: { input },
        });
    } catch (err) {
        return Promise.reject(
            "Sorry, unable to fetch the locations. Please try again in a few minutes",
        );
    }
};

/**
 * The API to fetch approximate locations based on the Geocode.
 *
 *
 * @returns {@link Promise} with value as {@link ReverseGeocodeApiResponse}
 */
export const reverseGeocodeApi = async (
    lat: number,
    lng: number,
    apiOpts: MapApiOpts,
): Promise<ReverseGeocodeApiResponse> => {
    try {
        if (Platform.OS === "web") {
            return await httpClient.get({
                endpoint: Endpoint.from(`${apiOpts.baseUrl}${webUrlPath}`),
                queryParams: {
                    lat: `${lat}`,
                    lng: `${lng}`,
                },
            });
        }

        return await httpClient.get({
            endpoint: Endpoint.from(`${apiOpts.baseUrl}/address/recommend`),
            queryParams: { latlng: `${lat},${lng}` },
        });
    } catch (err) {
        return Promise.reject(
            "Sorry, unable to fetch the details. Please try again in a few minutes",
        );
    }
};

/**
 * The API to fetch Geocode based on place id.
 *
 *
 * @returns {@link Promise} with value as {@link FetchGeocodeApiResponse}
 */
export const fetchGeocodeApi = async (
    placeId: string,
    apiOpts: MapApiOpts,
): Promise<FetchGeocodeApiResponse> => {
    try {
        if (Platform.OS === "web") {
            return await httpClient.get({
                endpoint: Endpoint.from(`${apiOpts.baseUrl}${webUrlPath}`),
                queryParams: {
                    placeId,
                },
            });
        }

        return await httpClient.get({
            endpoint: Endpoint.from(`${apiOpts.baseUrl}/address/recommend`),
            queryParams: { place_id: placeId },
        });
    } catch (err) {
        return Promise.reject(
            "Sorry, unable to fetch the details. Please try again in a few minutes",
        );
    }
};
