// useConstructor is a custom hook. It ensures that a given block of code is run once, and only once.
import { useRef } from "react";

export const useConstructor = (callBack: VoidFunction): void => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) {
        return;
    }

    callBack();

    hasBeenCalled.current = true;
};
