import React, { useEffect, useMemo } from "react";
import {
    ImageProps,
    ImageStyle,
    StyleSheet,
    TextStyle,
    ViewStyle,
    Animated,
    Easing,
    ViewProps,
    Platform,
    useWindowDimensions,
    StyleProp,
} from "react-native";
import { shadow, SpacingValue } from "@swiggy-private/rn-dls-theme";

import { useDLS } from "../styles/style-service";
import { FalsyFC, RenderProp } from "../support/falsy-fc";
import { FalsyText } from "../support/falsy-text";
import { TextProps } from "./text";
import { ToastColor, ToastStyle } from "../styles/interfaces/toast";
import { useAnimatedValue } from "../hooks/use-animated-value";
import { useLayout } from "../hooks/use-layout";

export interface ToastProps extends Omit<ViewProps, "children"> {
    visible?: boolean;
    color?: ToastColor;
    children?: RenderProp<TextProps> | React.ReactText;
    accessoryLeft?: RenderProp<Partial<ImageProps>>;
    accessoryRight?: RenderProp<Partial<ImageProps>>;
    style?: StyleProp<ViewStyle>;
}

export type ToastElement = React.ReactElement<ToastProps>;

const getComponentStyle = (
    source: ToastStyle,
): {
    text: TextStyle;
    container: ViewStyle;
    iconLeft: ImageStyle;
    iconRight: ImageStyle;
} => {
    const {
        textColor,
        textFontFamily,
        textFontSize,
        letterSpacing,
        textLineHeight,
        iconHeight,
        iconWidth,
        textMarginHorizontal,
        textFontSize: touchableTextFontSize,
        ...containerProps
    } = source;

    return {
        text: {
            letterSpacing,
            color: textColor,
            fontFamily: textFontFamily,
            fontSize: textFontSize,
            lineHeight: textLineHeight,
        },
        iconLeft: {
            width: iconWidth,
            height: iconHeight,
            marginRight: textMarginHorizontal,
        },
        iconRight: {
            width: iconWidth,
            height: iconHeight,
            marginLeft: textMarginHorizontal,
        },
        container: {
            ...containerProps,
        },
    };
};

const USE_NATIVE_DRIVER = Platform.OS !== "web";

/**
 * Toast shows user a message with Fade in animation for some time and, a touchable to take actions before closing the Toast.
 * Default Toast color is `primary`.
 *
 * @param props {@link ToastProps}
 */
export const Toast: React.FC<ToastProps> = (props) => {
    const {
        visible = false,
        children,
        style,
        accessoryLeft,
        accessoryRight,
        color,
        ...otherProps
    } = props;

    const animated = useAnimatedValue(0);
    const [layout, setLayout] = useLayout();

    const { width } = useWindowDimensions();
    const isWebAndNotMobile = Platform.OS === "web" && width > 1024;

    const dls = useDLS("toast", props);
    const componentStyle = useMemo(() => getComponentStyle(dls.style), [dls.style]);

    useEffect(() => {
        if (visible) {
            Animated.timing(animated, {
                toValue: 1,
                duration: 200,
                easing: Easing.linear,
                useNativeDriver: USE_NATIVE_DRIVER,
            }).start();
        } else {
            Animated.timing(animated, {
                toValue: 0,
                duration: 100,
                useNativeDriver: USE_NATIVE_DRIVER,
            }).start();
        }
    }, [animated, visible]);

    const animatedStyle = useMemo(
        () => ({
            opacity: animated.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
            }),
            transform: [
                {
                    translateY: animated.interpolate({
                        inputRange: [0, 1],
                        outputRange: [layout.height, 0],
                    }),
                },
            ],
        }),
        [animated, layout.height],
    );

    return (
        <Animated.View
            {...otherProps}
            style={[
                styles.container,
                componentStyle.container,
                shadow(1),
                animatedStyle,
                isWebAndNotMobile ? styles.webStyle : {},
                style,
            ]}
            pointerEvents={visible ? "none" : "box-none"}
            onLayout={setLayout}>
            <FalsyFC
                component={accessoryLeft}
                style={[styles.accessory, componentStyle.iconLeft]}
            />
            <FalsyText
                style={[styles.text, componentStyle.text]}
                component={children}
                // * Raising it to minimum of 3 lines
                numberOfLines={3}
            />
            <FalsyFC
                component={accessoryRight}
                style={[styles.accessory, componentStyle.iconLeft]}
            />
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        overflow: "hidden",
        zIndex: 1000,
    },
    webStyle: {
        left: "40%",
        right: "40%",
        width: "auto",
        textAlign: "center",
        marginBottom: SpacingValue["space-large"],
        borderRadius: SpacingValue["space-small"],
    },
    text: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    accessory: {
        justifyContent: "center",
        alignItems: "center",
    },
});
