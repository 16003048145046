import React, { Fragment, useRef, useState, useEffect, useCallback } from "react";
import { Dimensions, View, Platform, StyleSheet } from "react-native";

import AndroidCubeEffect from "./components/AndroidCubeEffect";
import CubeNavigationHorizontal from "./components/CubeNavigationHorizontal";
import Modal from "./components/Modal";

import { StoryListItem } from "./StoryListItem";
import { StoryCircleListView } from "./StoryCircleListView";
import { isNullOrWhitespace, fetchStoryImage } from "./helpers";
import { IUserStory, NextOrPrevious, StoryProps } from "./interfaces";

const { height, width } = Dimensions.get("window");

const DEFAULT_TEST_ID = "stories-container";

export const Story: React.FC<StoryProps> = ({
    data,
    unPressedBorderColor,
    pressedBorderColor,
    unPressedAvatarTextColor,
    pressedAvatarTextColor,
    style,
    onStart,
    onClose,
    duration,
    swipeText,
    avatarSize,
    showAvatarText,
    avatarTextStyle,
    onStorySeen,
    renderCloseComponent,
    renderSwipeUpComponent,
    renderStoryActions,
    renderHeaderComponent,
    renderStoryEndCover,
    renderFooter,
    loadedAnimationBarStyle,
    unloadedAnimationBarStyle,
    animationBarContainerStyle,
    storyUserContainerStyle,
    storyImageStyle,
    storyAvatarImageStyle,
    storyContainerStyle,
    avatarImageStyle,
    avatarWrapperStyle,
    avatarFlatListProps,
    selectedHighlightIndex,
    selectedStoryIndex,
    pauseAnimation,
    analytics,
    containerTestID = DEFAULT_TEST_ID,
}: StoryProps) => {
    const [dataState, setDataState] = useState<IUserStory[]>(data);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0); // current hihglight
    const [selectedData, setSelectedData] = useState<IUserStory[]>([]);
    const cube = useRef<CubeNavigationHorizontal | AndroidCubeEffect>();

    const [imageDataSet, setImageDataSet] = useState<{ [key: number]: string }>({});

    // Component Functions
    const _handleStoryItemPress = (item: IUserStory, index?: number): void => {
        analytics.setHighlightIndex(index ?? 0);
        analytics.sendHighlightClick();

        const newData = dataState.slice(index);
        if (onStart) {
            onStart(item);
        }

        setCurrentPage(0);
        setSelectedData(newData);
        setIsModalOpen(true);
    };

    useEffect(() => {
        dataState.forEach((story, index) => {
            fetchStoryImage(story.stories[0].story_image).then(async (response) => {
                response && setImageDataSet((prev) => ({ ...prev, [index]: response }));
            });
        });
    }, []);

    useEffect(() => {
        if (selectedHighlightIndex === undefined) {
            return;
        }
        const newData = dataState.slice(selectedHighlightIndex, selectedHighlightIndex + 1);
        setSelectedData(newData);
        setIsModalOpen(true);
    }, [dataState, selectedHighlightIndex]);

    const getHighlight = (storyId: string): IUserStory | undefined => {
        return dataState.find((x) => x.user_id === storyId);
    };

    const getHighlightIndex = (storyId: string): number => {
        return dataState.findIndex((x) => x.user_id === storyId);
    };

    const handleSeen = useCallback((): void => {
        const seen = selectedData[currentPage];
        const seenIndex = dataState.indexOf(seen);
        if (seenIndex >= 0) {
            if (!dataState[seenIndex]?.seen) {
                const tempData = dataState;
                dataState[seenIndex] = {
                    ...dataState[seenIndex],
                    seen: true,
                };
                setDataState(tempData);
            }
        }
    }, [currentPage, dataState, selectedData]);

    useEffect(() => {
        handleSeen();
    }, [handleSeen]);

    const onStoryFinish = (state: NextOrPrevious): void => {
        let highlightIndex = 0;
        if (!isNullOrWhitespace(state)) {
            if (state === "next") {
                const newPage = currentPage + 1;
                if (newPage < selectedData.length) {
                    setCurrentPage(newPage);
                    cube?.current?.scrollTo(newPage);
                    highlightIndex = newPage;
                } else {
                    setIsModalOpen(false);
                    setCurrentPage(0);
                    if (onClose) {
                        onClose(selectedData[selectedData.length - 1]);
                    }
                    highlightIndex = 0;
                }
            } else if (state === "previous") {
                const newPage = currentPage - 1;
                if (newPage < 0) {
                    setIsModalOpen(false);
                    setCurrentPage(0);
                    highlightIndex = 0;
                } else {
                    setCurrentPage(newPage);
                    cube?.current?.scrollTo(newPage);
                    highlightIndex = newPage;
                }
            }
            analytics.setHighlightIndex(getHighlightIndex(selectedData[highlightIndex].user_id));
        }
    };

    const onClosePress = (): void => {
        setIsModalOpen(false);
        analytics.sendCloseClickEvent();
        if (onClose) {
            onClose();
        }
    };

    const renderStoryList = (): React.ReactNode =>
        selectedData.map((x, i) => {
            return (
                <StoryListItem
                    duration={duration * 1000}
                    key={i}
                    userId={x.user_id}
                    profileName={x.user_name}
                    profileImage={x.user_image}
                    stories={x.stories}
                    seen={!!getHighlight(x.user_id)?.seen}
                    currentPage={currentPage}
                    isLastPage={i === selectedData.length - 1}
                    onFinish={onStoryFinish}
                    swipeText={swipeText}
                    renderSwipeUpComponent={renderSwipeUpComponent}
                    renderCloseComponent={renderCloseComponent}
                    renderHeaderComponent={(storyIndex: number) =>
                        renderHeaderComponent?.(getHighlightIndex(x.user_id), storyIndex)
                    }
                    renderStoryActions={renderStoryActions}
                    renderFooter={(storyIndex: number) =>
                        renderFooter(getHighlightIndex(x.user_id), storyIndex, onClosePress)
                    }
                    renderStoryEndCover={renderStoryEndCover}
                    onClosePress={onClosePress}
                    index={i}
                    firstStoryImageData={imageDataSet[getHighlightIndex(x.user_id)]}
                    onStorySeen={onStorySeen}
                    unloadedAnimationBarStyle={unloadedAnimationBarStyle}
                    animationBarContainerStyle={animationBarContainerStyle}
                    loadedAnimationBarStyle={loadedAnimationBarStyle}
                    storyUserContainerStyle={storyUserContainerStyle}
                    storyImageStyle={storyImageStyle}
                    storyAvatarImageStyle={storyAvatarImageStyle}
                    storyContainerStyle={storyContainerStyle}
                    selectedStoryIndex={selectedStoryIndex}
                    pauseAnimation={pauseAnimation}
                    analytics={analytics}
                />
            );
        });

    const renderCube = (): React.ReactElement => {
        if (Platform.OS === "ios") {
            return (
                <CubeNavigationHorizontal
                    ref={cube as React.LegacyRef<CubeNavigationHorizontal>}
                    callBackAfterSwipe={(x: number) => {
                        if (Number(x) !== currentPage) {
                            setCurrentPage(Number(x));
                            analytics.setHighlightIndex(getHighlightIndex(selectedData[x].user_id));
                        }
                    }}>
                    {renderStoryList()}
                </CubeNavigationHorizontal>
            );
        } else {
            return (
                <AndroidCubeEffect
                    ref={cube as React.LegacyRef<AndroidCubeEffect>}
                    callBackAfterSwipe={(x: number) => {
                        if (Number(x) !== currentPage) {
                            setCurrentPage(Number(x));
                            analytics.setHighlightIndex(getHighlightIndex(selectedData[x].user_id));
                        }
                    }}>
                    {renderStoryList()}
                </AndroidCubeEffect>
            );
        }
    };

    return (
        <Fragment>
            <View style={style} testID={containerTestID}>
                <StoryCircleListView
                    handleStoryItemPress={_handleStoryItemPress}
                    data={dataState}
                    avatarSize={avatarSize}
                    unPressedBorderColor={unPressedBorderColor}
                    pressedBorderColor={pressedBorderColor}
                    unPressedAvatarTextColor={unPressedAvatarTextColor}
                    pressedAvatarTextColor={pressedAvatarTextColor}
                    showText={showAvatarText}
                    avatarTextStyle={avatarTextStyle}
                    avatarWrapperStyle={avatarWrapperStyle}
                    avatarImageStyle={avatarImageStyle}
                    avatarFlatListProps={avatarFlatListProps}
                    analytics={analytics}
                />
            </View>
            {isModalOpen ? (
                <Modal
                    style={styles.modal}
                    isOpen={isModalOpen}
                    onClosed={() => setIsModalOpen(false)}
                    position="center"
                    swipeToClose
                    swipeArea={250}
                    backButtonClose
                    coverScreen>
                    {renderCube()}
                </Modal>
            ) : null}
        </Fragment>
    );
};

const styles = StyleSheet.create({
    modal: {
        flex: 1,
        height,
        width,
    },
});

export default Story;

Story.defaultProps = {
    showAvatarText: true,
};
