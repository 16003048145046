import React from "react";
import { View, StyleSheet } from "react-native";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Button, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { MapAddressPill } from "../../../map-address-pill";
import { MAP_VIEW_CONSTS } from "../../constants";

type Props = {
    handleBackNavigation: () => void;
    isActive: boolean;
    showNextScreen: () => void;
    title: string | null;
    streetAddress: string | null;
    onMount?: () => void;
    onMountMapAddressPill?: () => void;
};

export const ProceedAhead: React.FC<Props> = ({
    handleBackNavigation,
    isActive,
    title,
    streetAddress,
    showNextScreen,
    onMount,
    onMountMapAddressPill,
}) => {
    const [btnDisabled, setIsBtnDisabled] = React.useState<boolean>(true);

    useMount(() => {
        onMount?.();
    });

    React.useEffect(() => {
        if (isActive) {
            setIsBtnDisabled(false);
        } else {
            setIsBtnDisabled(true);
        }
    }, [isActive]);
    return (
        <View style={styles.mainContainer}>
            <MapAddressPill
                addressTitle={title}
                streetAddress={streetAddress}
                navigationLabel={isActive ? MAP_VIEW_CONSTS.NAVIGATION_LABEL : null}
                handleNavigation={handleBackNavigation}
                onMount={onMountMapAddressPill}
            />
            <Button
                size="large"
                disabled={btnDisabled}
                accessoryRight={
                    <SvgIcon
                        color="color-basic-0"
                        accessibilityLabel="Proceed"
                        accessible
                        icon="ArrowRight"
                        width={20}
                        height={20}
                    />
                }
                style={styles.cta}
                accessoryAlignment="end"
                onPress={showNextScreen}>
                <Text>{MAP_VIEW_CONSTS.CTA_TEXT}</Text>
            </Button>
        </View>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        width: "100%",
        justifyContent: "space-between",
        borderTopLeftRadius: SpacingValue["space-medium"],
        borderTopRightRadius: SpacingValue["space-medium"],
        paddingTop: SpacingValue["space-x-small"],
    },
    cta: {
        marginTop: SpacingValue["space-x-large"],
    },
});
