import { Theme } from "@swiggy-private/rn-dls-theme";
import memoizeOne from "memoize-one";

import {
    CheckBoxColor,
    CheckBoxColorStyle,
    CheckBoxSizeStyle,
    CheckBoxState,
    CheckBoxStyle,
    CheckBoxStyleMeta,
} from "../interfaces/check-box";

type CheckBoxColorWithStateStyle = Record<
    CheckBoxColor,
    {
        state: Partial<Record<CheckBoxState, Partial<CheckBoxColorStyle>>>;
    } & CheckBoxColorStyle
>;

const sizeStyle = memoizeOne(
    (theme: Theme): CheckBoxSizeStyle => ({
        width: 16,
        height: 16,
        borderRadius: 4,
        iconWidth: 10,
        iconHeight: 8,
        iconBorderRadius: 0,
        borderWidth: 1,
        textFontFamily: theme["text-body-1-medium-font-family"],
        textFontSize: theme["text-body-1-medium-font-size"],
        textLineHeight: theme["text-body-1-medium-line-height"],
        letterSpacing: theme["text-body-1-medium-letter-spacing"],
        textMarginHorizontal: 8,
    }),
);

const defaultColorStyle = (theme: Theme): CheckBoxColorStyle => ({
    borderColor: theme["color-basic-30"],
    backgroundColor: "transparent",
    iconTintColor: "transparent",
    textColor: theme["color-basic-100"],
});

const colorStyleMap = memoizeOne(
    (theme: Theme): CheckBoxColorWithStateStyle => ({
        primary: {
            ...defaultColorStyle(theme),
            state: {
                checked: {
                    iconTintColor: theme["color-basic-0"],
                    borderColor: theme["color-primary"],
                    backgroundColor: theme["color-primary"],
                },
            },
        },
        secondary: {
            ...defaultColorStyle(theme),
            state: {
                checked: {
                    iconTintColor: theme["color-primary"],
                    borderColor: theme["color-primary-light"],
                    backgroundColor: theme["color-primary-light"],
                },
            },
        },
    }),
);

export const getCheckBoxStyles = (meta: CheckBoxStyleMeta, theme: Theme): CheckBoxStyle => {
    const color = meta.color ?? "primary";
    const state = meta.checked ? "checked" : Array.isArray(meta.state) ? meta.state[0] : meta.state;
    const { state: stateStyle, ...colorStyle } = colorStyleMap(theme)[color];

    return {
        ...sizeStyle(theme),
        ...colorStyle,
        ...(state ? stateStyle[state] ?? {} : {}),
    };
};
