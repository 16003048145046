import * as React from "react";
import { Image, ImageProps } from "react-native";

import { IKImage } from "../imagekit-image";
import { ImagekitTransformation, ImagekitTransformationProps } from "../imagekit-transformation";
import { ImageKitTransformation } from "../../helpers/imageKit";

type Children = React.ReactElement<ImagekitTransformationProps, typeof ImagekitTransformation>;

export interface CdnImageProps extends Omit<ImageProps, "source"> {
    id: string;
    width?: number;
    height?: number;
    resourceType?: "upload" | "youtube";
    children?: Children | Children[];
    fallback?: boolean;
    showLoader?: boolean;
    format?: ImageKitTransformation["format"];
    startPerfMarker?: VoidFunction;
    stopPerfMarker?: VoidFunction;
    // TODO: remove towards the end of IK migration
    isImageKitEnabled?: boolean;
    optimizedDpr?: boolean;
}

export const CdnImage: React.FC<CdnImageProps> & {
    Component: React.FC<ImageProps> & typeof Image;
    ForceCache: boolean;
} = (props) => {
    return <IKImage {...props} />;
};

CdnImage.ForceCache = false;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-errors
CdnImage.Component = Image;
