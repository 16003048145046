import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import {
    Animated,
    Dimensions,
    PanResponder,
    ScrollView,
    StyleProp,
    StyleSheet,
    ViewStyle,
} from "react-native";

import { useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

const { height } = Dimensions.get("screen");

interface Props {
    children: React.ReactNode;
    // This prop represents the height of the modal when it is open.
    heightWhenOpened: number;
    // This prop determines where the drawer should snap when dragged. It's specified as a percentage of the screen height
    maxSnapToPercent: number;

    style?: StyleProp<ViewStyle>;
    lineStyle?: StyleProp<ViewStyle>;
    headerComponent?: React.ComponentType;

    onCloseCb?: VoidFunction;
    onOpenCb?: VoidFunction;
    onOpenMaxHeightCb?: VoidFunction;
    testID?: string;
}

export interface BottomSheetMethods {
    expand: VoidFunction;
    close: VoidFunction;
}

const DrawerBottomSheetComponent = forwardRef<BottomSheetMethods, Props>(
    (
        {
            children,
            headerComponent: Header,
            onCloseCb,
            onOpenCb,
            onOpenMaxHeightCb,
            heightWhenOpened,
            maxSnapToPercent,
            style,
            lineStyle,
            testID,
        },
        ref,
    ) => {
        const { value: theme } = useTheme();
        const animatedValue = useRef(new Animated.Value(0)).current;
        const initialTranslateY = useRef(0);
        const maxHeight = (maxSnapToPercent / 100) * height;
        const openHeightRef = useRef(heightWhenOpened);

        const animateSheet = useCallback(
            (toValue: number, callback?: VoidFunction) => {
                callback?.();
                Animated.timing(animatedValue, {
                    toValue: toValue,
                    duration: 200,
                    useNativeDriver: true,
                }).start();
            },
            [animatedValue],
        );

        const handleSheetChange = useCallback(
            (newValue: number, callback?: VoidFunction) => {
                initialTranslateY.current = newValue;
                animateSheet(initialTranslateY.current, callback);
            },
            [animateSheet],
        );

        const expand = useCallback(() => {
            handleSheetChange(-heightWhenOpened, onOpenCb);
        }, [handleSheetChange, onOpenCb, heightWhenOpened]);

        const close = useCallback(() => {
            handleSheetChange(0, onCloseCb);
        }, [handleSheetChange, onCloseCb]);

        const animateSpring = useCallback(
            (toValue: number, callback?: VoidFunction) => {
                initialTranslateY.current = toValue;
                callback?.();
                Animated.spring(animatedValue, {
                    toValue: toValue,
                    useNativeDriver: true,
                }).start();
            },
            [animatedValue],
        );

        const panResponder = useRef(
            PanResponder.create({
                onStartShouldSetPanResponder: () => true,
                onPanResponderMove: (_e, gesture) => {
                    animatedValue.setValue(initialTranslateY.current + gesture.dy);
                },
                onPanResponderRelease: (e, gesture) => {
                    const currentOpenHeight = openHeightRef.current;
                    initialTranslateY.current = initialTranslateY.current + gesture.dy;
                    const halfMaxHeight = (-1 * (maxHeight + currentOpenHeight)) / 2;
                    const snapTo =
                        initialTranslateY.current <= halfMaxHeight
                            ? -maxHeight
                            : -currentOpenHeight;
                    const callback = snapTo === -maxHeight ? onOpenMaxHeightCb : onOpenCb;

                    animateSpring(snapTo, callback);
                },
            }),
        ).current;

        const bottomSheetAnimation = {
            transform: [
                {
                    translateY: animatedValue,
                },
            ],
            height: maxHeight,
            bottom: -maxHeight,
            borderTopColor: theme["color-basic-15"],
            backgroundColor: theme["color-basic-0"],
        };

        useImperativeHandle(
            ref,
            () => ({
                expand,
                close,
            }),
            [expand, close],
        );

        useEffect(() => {
            openHeightRef.current = heightWhenOpened;
        }, [heightWhenOpened]);

        return (
            <Animated.View
                style={[styles.bottomSheet, bottomSheetAnimation, style]}
                testID={testID}>
                <Box
                    justifyContent="center"
                    alignItems="center"
                    style={[styles.draggableArea, lineStyle]}
                    {...panResponder.panHandlers}>
                    <Box
                        style={[styles.dragHandle, { backgroundColor: theme["color-basic-15"] }]}
                    />
                </Box>

                {Header ? <Header /> : null}

                <ScrollView>{children}</ScrollView>
            </Animated.View>
        );
    },
);

export const DrawerBottomSheet = React.memo(DrawerBottomSheetComponent);

const styles = StyleSheet.create({
    bottomSheet: {
        position: "absolute",
        width: "100%",
        borderTopWidth: 1,
    },
    draggableArea: {
        width: "100%",
        height: 32,
        alignSelf: "center",
    },
    dragHandle: {
        width: 60,
        height: 4,
        borderRadius: 10,
    },
});

if (__DEV__) {
    DrawerBottomSheetComponent.displayName = "DrawerBottomSheetComponent";
}
