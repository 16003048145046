import React, { ReactNode, useEffect, useRef } from "react";
import { View, ViewProps, Pressable } from "react-native";

import { throttleFn } from "@swiggy-private/common-helpers";

import type { InViewProps } from "./typings/InView.d";

const InView: <T extends ViewProps = ViewProps>(props: InViewProps<T>) => JSX.Element | null = <
    T extends ViewProps = ViewProps,
>({
    as: ViewComponent = View,
    children,
    triggerOnce,
    visiblityPercentage = 100,
    onChange,
    pressableProps,
    ...props
}: InViewProps<T>): JSX.Element | null => {
    const element = useRef<unknown>(null);
    const observer = useRef<IntersectionObserver>();
    const mounted = useRef(false);

    /*
    Unobserver or Disconnect the observer
    */
    const unObserve = React.useCallback(() => {
        if (observer.current && element.current) {
            observer.current?.unobserve(element.current as Element);
            observer.current.disconnect();
        }
    }, []);

    /*
    Checks if the element is visible 100% in the viewport
    */
    const handleIntersection: IntersectionObserverCallback = React.useCallback(
        (entries) => {
            if (!mounted.current) {
                return;
            }
            entries.forEach((entry) => {
                if (entry.target === element.current) {
                    // if need it to be triggered only once
                    if (triggerOnce && entry.isIntersecting) {
                        unObserve();
                    }
                    // Handle the intersection events here
                    if (onChange) {
                        onChange(entry.isIntersecting);
                    }
                }
            });
        },
        [onChange, triggerOnce, unObserve],
    );

    const throttledIntersectionHandler = throttleFn(handleIntersection, 500);

    /*
    The handleRef callback sets the view ref to the provided ref.
    */
    const handleRef = React.useCallback((ref: unknown): void => {
        element.current = ref;
    }, []);

    useEffect(() => {
        mounted.current = true;
        if (element.current) {
            observer.current = new IntersectionObserver(throttledIntersectionHandler, {
                threshold: visiblityPercentage / 100,
            });
            // Start observing the target element
            observer.current.observe(element.current as Element);
        }

        // Clean up the observer when the component unmounts
        return () => {
            mounted.current = false;
            unObserve();
        };
    }, [throttledIntersectionHandler, unObserve, visiblityPercentage]);

    const InViewComponent = React.createElement(
        ViewComponent,
        { ...props, ref: handleRef },
        children as ReactNode,
    );
    const InViewElement = pressableProps
        ? React.createElement(Pressable, { ...pressableProps }, InViewComponent)
        : InViewComponent;

    return InViewElement;
};

export default InView;
