export const checkIsApiBlacklistedForLogging = (
    apiEndpoint: string,
    blacklistedApiEndpoints: RegExp[],
): boolean => {
    for (let i = 0; i < blacklistedApiEndpoints.length; i++) {
        const blacklistedEndpointPattern = new RegExp(blacklistedApiEndpoints[i]);

        if (blacklistedEndpointPattern.test(apiEndpoint)) {
            return true;
        }
    }

    return false;
};
