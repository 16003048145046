import * as React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import {
    Button,
    ButtonProps,
    Dialog,
    DialogContent,
    SpacingValue,
    Text,
    TextProps,
    useTheme,
} from "@swiggy-private/rn-dls";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";
import { useMount } from "@swiggy-private/react-hooks";

import { DEFAULT_VALUES } from "./constants";

interface ConfirmDialogProps {
    onPressCancel: VoidFunction;
    onPressContinue: VoidFunction;

    open?: boolean;
    loading?: boolean;
    disableBackdropClose?: boolean;
    title?: string;
    description?: string;
    cancelBtnText?: string;
    continueBtnText?: string;
    titleTextProps?: TextProps;
    style?: StyleProp<ViewStyle>;
    btnTertiaryProps?: ButtonProps;
    btnCriticalProps?: ButtonProps;
    btnTertiaryExtraStyle?: StyleProp<ViewStyle>;
    btnCriticalExtraStyle?: StyleProp<ViewStyle>;
    overlayStyle?: StyleProp<ViewStyle>;
    onClose?: VoidFunction;
    onMount?: VoidFunction;
}

export type TextStyleProps = Pick<TextProps, "category" | "weight">;

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
    open = true,
    disableBackdropClose = true,
    overlayStyle,
    style,
    ...props
}) => {
    const modalMaxWidth = useSelectScreen({
        lg: 340 as ViewStyle["width"],
        default: "90%",
    });

    if (!open) {
        return null;
    }

    return (
        <Dialog
            overlayStyle={overlayStyle}
            disableBackdropClose={disableBackdropClose}
            open={open}
            onClose={props.onClose}
            style={[styles.dialog, { width: modalMaxWidth }, style]}>
            <DialogContent>
                <ConfirmDialogContent {...props} />
            </DialogContent>
        </Dialog>
    );
};

const ConfirmDialogContent: React.FC<Exclude<ConfirmDialogProps, "open">> = ({
    onPressCancel,
    title,
    description,
    onPressContinue,
    cancelBtnText,
    continueBtnText,
    loading,
    onMount,
    btnTertiaryProps,
    btnCriticalProps,
    btnCriticalExtraStyle,
    btnTertiaryExtraStyle,
    titleTextProps,
}) => {
    const { value: theme } = useTheme();

    const btnTertiary: ViewStyle = {
        backgroundColor: theme["color-background-secondary"],
    };

    const btnTertiaryText = {
        color: theme.text_Highest_Emphasis,
    };

    const titleText = useSelectScreen<TextStyleProps>({
        default: { category: "h5" },
        lg: { category: "h4" },
    });

    const subtitleText = useSelectScreen<TextStyleProps>({
        default: { category: "b2" },
        lg: { category: "b1" },
    });

    const marginStyle = useSelectScreen({
        default: {
            marginTop: SpacingValue["space-medium"],
        },
        md: {
            marginTop: SpacingValue["space-x-large"],
        },
        lg: {
            marginTop: SpacingValue["space-x-large"],
        },
    });

    useMount(() => {
        onMount?.();
    });

    return (
        <Box>
            {title ? (
                <Text category={titleText.category} weight="regular" {...titleTextProps}>
                    {title}
                </Text>
            ) : null}
            {description ? (
                <Text category={subtitleText.category} color="medium" style={styles.mt8}>
                    {description}
                </Text>
            ) : null}
            <Stack direction="row" spacing={SpacingValue["space-small"]} style={marginStyle}>
                <Button
                    color="rn_dls_tertiary"
                    elevation={1}
                    style={[btnTertiary, styles.btnTertiary, btnTertiaryExtraStyle]}
                    textStyle={btnTertiaryText}
                    onPress={onPressCancel}
                    {...btnTertiaryProps}>
                    {cancelBtnText || DEFAULT_VALUES.CANCEL_BTN_TEXT}
                </Button>
                <Button
                    color="rn_dls_critical"
                    elevation={1}
                    style={[styles.btnCritical, btnCriticalExtraStyle]}
                    onPress={onPressContinue}
                    {...btnCriticalProps}>
                    {loading ? (
                        <ThreeDotsLoader />
                    ) : (
                        continueBtnText || DEFAULT_VALUES.CONTINUE_BTN_TEXT
                    )}
                </Button>
            </Stack>
        </Box>
    );
};

const styles = StyleSheet.create({
    dialog: {
        borderRadius: 20,
    },
    mt8: {
        marginTop: SpacingValue["space-x-small"],
    },
    btnCritical: {
        flex: 1,
    },
    btnTertiary: {
        borderColor: "transparent",
        flex: 1,
    },
});
