import React, { memo, useContext, useEffect, useMemo, useRef } from "react";
import { ColorValue, Platform, StyleProp, StyleSheet, ViewProps, ViewStyle } from "react-native";

import format from "date-fns/format";

import {
    Text,
    SpacingValue,
    useTheme,
    Surface,
    TextColor,
    ElevationValue,
} from "@swiggy-private/rn-dls";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { Divider } from "@swiggy-private/react-native-ui";
import { CustomMessageTypes } from "@swiggy-private/connect-chat-commons";

import { IMessageViewContent, Message } from "../../interfaces/types";
import { MessageViewTextMessage } from "./components/text-message";
import {
    isImageMessage,
    isTextMessage,
    isCustomMessage,
    isVideoMessage,
} from "../../helpers/message";
import { MessageViewImageMessage } from "./components/image-message";
import { useChatRenderer } from "../../hooks/use-chatrender";
import {
    useChatMessageAction,
    useIsMessageRead,
    useIsMessageReceived,
    useIsMessageSent,
} from "../../hooks/use-chat-message-action";
import { useChatDispatch } from "../../hooks/use-chatdispatch";
import { useChatService } from "../../hooks/use-chat-service";
import { logError } from "../../helpers/log";
import { MessageViewVideoMessage } from "./components/video-message";
import { ChatConversationContentContext } from "../conversation-view/components/content/context";

export interface ChatConversationMessageViewProps extends ViewProps {
    conversationTitle: string;
    message: Message;
    isPublisher: boolean;
    isSystemMessage: boolean;
    conversationId: string;
    blocked?: SdkConversation["blocked"];
    deleted?: SdkConversation["deleted"];
    footerStyle?: ViewProps["style"];
    contentStyle?: ViewProps["style"];
    conversationMeta?: SdkConversation["meta"];
    onMessageRead?: (T: IMessageViewContent) => void;
    publisherBgColor?: ColorValue;
    subscriberBgColor?: ColorValue;
    subscriberTextColor?: TextColor;
    publisherTextColor?: TextColor;
    footerTextColor?: TextColor;
    previousMessage?: Message;
    nextMessage?: Message;
}

export const ChatConversationMessageView: React.FC<ChatConversationMessageViewProps> = memo(
    (props) => {
        const {
            conversationTitle,
            message,
            isPublisher,
            isSystemMessage,
            conversationId,
            blocked,
            deleted,
            style,
            renderToHardwareTextureAndroid = true,
            footerStyle,
            contentStyle,
            onMessageRead,
            publisherBgColor,
            subscriberBgColor,
            subscriberTextColor,
            publisherTextColor,
            footerTextColor,
            nextMessage,
            ...restProps
        } = props;

        useSendReadReceipt(props);

        const { value: theme } = useTheme();
        const time = useMemo(() => format(message.timestamp, "hh:mm a"), [message.timestamp]);

        const Footer = useChatRenderer()?.renderMessageFooter ?? ChatMessageViewFooter;

        const finalContainerStyle = useMemo(() => {
            const containerStyle = isSystemMessage
                ? [
                      styles.systemContainer,
                      {
                          backgroundColor: theme["color-basic-15"],
                      },
                  ]
                : isPublisher
                ? [
                      styles.publisherContainer,
                      {
                          backgroundColor: publisherBgColor ?? theme["color-surface-primary"],
                      },
                  ]
                : [
                      styles.subscriberContainer,
                      {
                          // pick from DLS
                          backgroundColor: subscriberBgColor ?? theme["color-primary-light"],
                      },
                  ];

            const containerPaddingStyle =
                isImageMessage(message) || isVideoMessage(message)
                    ? {
                          paddingHorizontal: SpacingValue["space-xx-small"],
                          paddingVertical: SpacingValue["space-xx-small"],
                      }
                    : {
                          paddingHorizontal: SpacingValue["space-small"],
                          paddingVertical: SpacingValue["space-small"],
                          paddingBottom: SpacingValue["space-xx-large"],
                      };

            const containerBorderRadiusStyle =
                nextMessage?.publisher === message.publisher && !message.payload.senderId
                    ? {
                          borderRadius: CONTAINER_BORDER_RADIUS,
                          borderBottomLeftRadius: CONTAINER_BORDER_RADIUS,
                          borderBottomRightRadius: CONTAINER_BORDER_RADIUS,
                      }
                    : null;

            const addToCartMsgStyles =
                message.type === "addedToCart"
                    ? {
                          maxWidth: "100%",
                          alignSelf: "center",
                          backgroundColor: theme["color-basic-15"],
                          paddingHorizontal: 8,
                          paddingVertical: 4,
                          paddingBottom: 4,
                          borderRadius: 0,
                      }
                    : null;

            return [
                styles.container,
                containerStyle,
                containerPaddingStyle,
                containerBorderRadiusStyle,
                style,
                addToCartMsgStyles,
            ];
        }, [
            isPublisher,
            isSystemMessage,
            message,
            nextMessage?.publisher,
            publisherBgColor,
            style,
            subscriberBgColor,
            theme,
        ]);

        return (
            <Surface
                style={finalContainerStyle}
                renderToHardwareTextureAndroid={renderToHardwareTextureAndroid}
                {...restProps}>
                <MessageViewContent
                    message={message}
                    isPublisher={isPublisher}
                    conversationId={conversationId}
                    style={contentStyle}
                    blocked={blocked}
                    deleted={deleted}
                    conversationMeta={props.conversationMeta}
                    subscriberTextColor={subscriberTextColor}
                    publisherTextColor={publisherTextColor}
                />
                <Footer
                    conversationTitle={conversationTitle}
                    message={message}
                    conversationId={conversationId}
                    time={time}
                    style={footerStyle}
                    isSystemMessage={isSystemMessage}
                    isPublisher={isPublisher}
                    textColor={footerTextColor}
                />
            </Surface>
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    ChatConversationMessageView.displayName = "ChatConversationMessageView";
}

const MessageViewContent: React.FC<{
    message: Message;
    isPublisher: boolean;
    conversationId: string;
    blocked?: SdkConversation["blocked"];
    deleted?: SdkConversation["deleted"];
    style?: ViewProps["style"];
    conversationMeta?: SdkConversation["meta"];
    subscriberTextColor?: TextColor;
    publisherTextColor?: TextColor;
}> = ({
    message,
    style,
    isPublisher,
    conversationId,
    blocked,
    deleted,
    conversationMeta,
    publisherTextColor,
    subscriberTextColor,
}) => {
    const CustomMessageRenderer = useChatRenderer()?.renderCustomMessage;

    if (isTextMessage(message)) {
        return (
            <MessageViewTextMessage
                {...message.payload}
                /** changed for consumer, to be fixed properly for both apps */
                color={isPublisher ? publisherTextColor : subscriberTextColor}
                style={style}
            />
        );
    } else if (isImageMessage(message)) {
        return <MessageViewImageMessage {...message.payload} style={style} />;
    } else if (isVideoMessage(message)) {
        return (
            <MessageViewVideoMessage {...message.payload} messageId={message.id} style={style} />
        );
    } else if (isCustomMessage(message)) {
        return CustomMessageRenderer ? (
            <CustomMessageRenderer
                message={message}
                style={style}
                isPublisher={isPublisher}
                conversationId={conversationId}
                blocked={blocked}
                deleted={deleted}
                conversationMeta={conversationMeta}
                textColor={isPublisher ? publisherTextColor : subscriberTextColor}
            />
        ) : null;
    }

    return null;
};

export interface ChatMessageViewFooterProps {
    conversationTitle: string;
    conversationId: string;
    message: Message;
    time: string;
    isSystemMessage?: boolean;
    isPublisher: boolean;
    style?: ViewProps["style"];
    textColor?: TextColor;
    showMessageReceivedOnSent?: boolean;
}

export const ChatMessageViewFooter: React.FC<ChatMessageViewFooterProps> = ({
    conversationTitle,
    conversationId,
    message,
    time,
    isSystemMessage,
    style,
    isPublisher,
    textColor,
    showMessageReceivedOnSent,
}) => {
    const isMessageSent = useIsMessageSent(message);
    const isMessageReceived =
        useIsMessageReceived(message) || (showMessageReceivedOnSent && isMessageSent);
    const isMessageRead = useIsMessageRead(message);

    const showTickIcon = isPublisher && (isMessageSent || isMessageReceived || isMessageRead);
    const [tickIcon, tickIconSize] =
        isMessageReceived || isMessageRead
            ? (["DoubleTick", 16] as const)
            : (["Tick", 12] as const);

    const CustomFooterCta = useChatRenderer()?.renderFooterCta ?? null;

    const CustomFooterCtaComponent = CustomFooterCta?.({
        message,
        conversationTitle,
        conversationId,
    });

    const mainContainerStyle: StyleProp<ViewStyle> = [
        CustomFooterCtaComponent ? styles.footerCtaContainer : styles.footer,
        style,
    ];
    const contentContainerStyle: StyleProp<ViewStyle> = CustomFooterCtaComponent
        ? styles.flexEnd
        : styles.flexCenter;

    if (message.type === CustomMessageTypes.ADDED_TO_CART) {
        return null;
    }

    return (
        <Stack style={mainContainerStyle}>
            <Stack
                direction="row"
                spacing={SpacingValue["space-xx-small"]}
                alignItems="center"
                style={contentContainerStyle}>
                <Text color={textColor ?? "low"} style={[styles.footerTime, styles.footerCtaTime]}>
                    {time}
                </Text>

                {showTickIcon ? (
                    <SvgIcon
                        icon={tickIcon}
                        width={tickIconSize}
                        height={tickIconSize}
                        color={isMessageRead ? "color-highlight-300" : "color-basic-45"}
                    />
                ) : null}
            </Stack>

            {isSystemMessage ? (
                <Text color="color-basic-60" category="b3" style={styles.flex}>
                    This is an automated message&nbsp;
                </Text>
            ) : null}

            {CustomFooterCtaComponent ? (
                <>
                    <Divider style={styles.divider} />
                    {CustomFooterCtaComponent}
                </>
            ) : null}
        </Stack>
    );
};

const useSendReadReceipt = ({
    message,
    onMessageRead,
    isPublisher,
    conversationId,
}: ChatConversationMessageViewProps): void => {
    const addMessageAction = useChatMessageAction();
    const chatDispatch = useChatDispatch();
    const chatService = useChatService();
    const onVisibilityChange = useContext(ChatConversationContentContext).onVisibilityChange;
    const readActionSentRef = useRef(false);

    useEffect(() => {
        if (readActionSentRef.current) {
            return () => {
                // do nothing..
            };
        }

        let animationTimer: number | null = null;

        const unsubscribe = onVisibilityChange(message.id, (_, visible) => {
            if (!visible) {
                return;
            }

            readActionSentRef.current = addMessageAction({
                type: "read",
                conversationId,
                message,
            });

            if (readActionSentRef.current) {
                animationTimer = requestAnimationFrame(() => {
                    chatDispatch({
                        type: "DECREMENT_UNREAD_COUNT_ACTION",
                        payload: {
                            conversationId,
                        },
                    });

                    onMessageRead?.({
                        conversationId,
                        message,
                        isPublisher,
                    });

                    chatService?.updateMessageReadState(message.id, true).catch(logError);
                });
            }
        });

        return () => {
            unsubscribe();
            if (animationTimer) {
                cancelAnimationFrame(animationTimer);
            }
        };
    }, [
        addMessageAction,
        chatDispatch,
        chatService,
        conversationId,
        isPublisher,
        message,
        onMessageRead,
        onVisibilityChange,
    ]);
};

const CONTAINER_BORDER_RADIUS = 20;

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-small"],
        paddingVertical: SpacingValue["space-small"],
        minWidth: 120,
        alignSelf: "flex-start",
        maxWidth: "100%",
        ...(Platform.OS === "android" ? { elevation: 1 } : ElevationValue[1]),
    },
    subscriberContainer: {
        borderTopLeftRadius: CONTAINER_BORDER_RADIUS,
        borderTopRightRadius: CONTAINER_BORDER_RADIUS,
        borderBottomRightRadius: CONTAINER_BORDER_RADIUS,
        borderBottomLeftRadius: Platform.OS === "android" ? CONTAINER_BORDER_RADIUS : 0,
    },
    publisherContainer: {
        borderTopLeftRadius: CONTAINER_BORDER_RADIUS,
        borderTopRightRadius: CONTAINER_BORDER_RADIUS,
        borderBottomLeftRadius: CONTAINER_BORDER_RADIUS,
        borderBottomRightRadius: Platform.OS === "android" ? CONTAINER_BORDER_RADIUS : 0,
        alignSelf: "flex-end",
    },
    systemContainer: {
        borderRadius: CONTAINER_BORDER_RADIUS,
        width: "100%",
        maxWidth: "100%",
    },
    footer: {
        flexDirection: "row-reverse",
        alignItems: "center",
        justifyContent: "space-between",
        position: "absolute",
        bottom: SpacingValue["space-x-small"],
        right: SpacingValue["space-x-small"],
        marginRight: SpacingValue["space-xxx-small"],
    },
    footerTime: {
        fontSize: 11,
    },
    footerCtaTime: {
        paddingTop: SpacingValue["space-xx-small"],
        alignSelf: "flex-end",
    },
    footerCtaContainer: {
        marginBottom: -SpacingValue["space-medium"],
    },
    divider: { marginTop: SpacingValue["space-xx-small"] },
    flexEnd: {
        justifyContent: "flex-end",
    },
    flexCenter: {
        justifyContent: "center",
    },
    flex: {
        flex: 1,
    },
});
