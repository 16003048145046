import * as React from "react";
import { useColorScheme } from "react-native";

import { Portal } from "./components/portal";
import { ThemeProvider } from "./styles/theme-service";

interface ProviderProps {
    theme?: string;
}

export const Provider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
    children,
    theme = "light",
}) => {
    const colorScheme = useColorScheme();
    const themeType = theme ? theme : colorScheme === "dark" ? "dark" : "light";

    return (
        <ThemeProvider theme={themeType}>
            <Portal.Host>{children}</Portal.Host>
        </ThemeProvider>
    );
};
