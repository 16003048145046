import React from "react";
import { StyleSheet } from "react-native";

import { useMount } from "@swiggy-private/react-hooks";
import { useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { CouponCardProps } from "./types";
import { CouponContent } from "./components/content";
import { CouponFooter } from "./components/footer";
import { CouponLoader } from "./components/loader";

/**
 * The coupon card component
 *
 * ## Usage
 * ```js
 * import * as React from "react";
 * import { CouponCard } from "@swiggy-private/react-native-ui";
 *
 * const MyComponent = () => {
 *   return <CouponCard
                    textContent={{
                        title: "10% OFF",
                        titleTextProps: { category: "h3", color: "color-basic-75", weight: "bold" },
                        description: "On orders above ₹199, for all customers till 10 Aug 2022",
                        descriptionProps: { category: "b3", color: "color-basic-100" },
                    }}
                    code={{
                        text: "NEW150",
                        prefix: "Coupon code",
                        prefixProps: { category: "b2", color: "color-basic-75", weight: "bold" },
                        props: { category: "b2", color: "color-basic-45", weight: "bold" },
                    }}
                    options={{ isActive: false }}
                />;
 * };
 *```
 */
export const CouponCard: React.FC<CouponCardProps> = (props) => {
    if (props?.options?.loading) {
        return <CouponLoader style={props.options.cardStyle} />;
    }

    return <CouponCardComponent {...props} />;
};

const CouponCardComponent: React.FC<CouponCardProps> = (props) => {
    const { value: theme } = useTheme();

    useMount(() => {
        props?.options?.onMount?.();
    });

    const containerStyle = StyleSheet.flatten([
        styles.card,

        {
            backgroundColor: theme["color-background-primary"],
        },
        props?.options?.cardStyle,
    ]);

    const { footerComponent: Footer } = props;

    return (
        <Box style={containerStyle}>
            <CouponContent {...props} />
            {Footer ? <Footer /> : <CouponFooter {...props} />}
        </Box>
    );
};

const styles = StyleSheet.create({
    card: {
        borderRadius: 16,
    },
});
