import React, { createContext } from "react";

import { ChatInstrumentation } from "../interfaces/chat-renderer";

export const ChatInstrumentationContext = createContext<ChatInstrumentation | null>(null);

export const ChatInstrumentationContextProvider: React.FC<{ value: ChatInstrumentation | null }> = (
    props,
) => {
    return <ChatInstrumentationContext.Provider {...props} />;
};
