import React from "react";
import { StyleSheet, TouchableWithoutFeedback, View, ViewProps } from "react-native";

import { useTheme } from "../styles/theme-service";

interface OverlayProps extends ViewProps {
    onPress?: () => void;
    backgroundColor?: "primary" | "secondary" | "transparent";
}

export const Overlay: React.FC<OverlayProps> = ({
    style,
    onPress,
    backgroundColor = "transparent",
    ...props
}) => {
    const { value: theme } = useTheme();
    const backdropStyle = {
        backgroundColor:
            backgroundColor === "transparent"
                ? "transparent"
                : backgroundColor === "primary"
                ? theme["color-overlay-primary"]
                : theme["color-overlay-secondary"],
    };

    return (
        <TouchableWithoutFeedback onPress={onPress}>
            <View style={[styles.backdrop, backdropStyle, style]} {...props} />
        </TouchableWithoutFeedback>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        ...StyleSheet.absoluteFillObject,
    },
});
