import React, { RefObject, memo, useCallback, useRef, useEffect } from "react";
import { StyleProp, StyleSheet, TextInput, View, ViewStyle } from "react-native";

import { Input, useTheme, InputProps, Text, SpacingValue } from "@swiggy-private/rn-dls";
import { SdkConversation, SdkMessage } from "@swiggy-private/connect-chat-sdk";

import { ISentMessageResponse } from "../../interfaces/types";

export interface ChatMessageInputViewProps extends Omit<InputProps, "accessoryRight"> {
    onSend: (text: string | SdkMessage) => ISentMessageResponse | undefined;
    conversation: SdkConversation;
    accessoryRight?: (params: { send: () => void }) => React.ReactElement | null;
    containerStyle?: StyleProp<ViewStyle>;
    onMessageSent?: (T: ISentMessageResponse) => void;
    inputRef?: RefObject<TextInput>;
}

export const ChatMessageInputView: React.FC<ChatMessageInputViewProps> = memo((props) => {
    const {
        onSend,
        conversation,
        defaultValue,
        onChangeText,
        style,
        textStyle,
        accessoryRight,
        containerStyle,
        onMessageSent,
        inputRef,
        ...restProps
    } = props;

    const { value: theme } = useTheme();

    const messageRef = useRef<string>(defaultValue ?? "");

    const onChangeTextCallback = useCallback(
        (text: string) => {
            messageRef.current = text;
            onChangeText?.(text);
        },
        [onChangeText],
    );

    const onSendCallback = useCallback(() => {
        const text = messageRef.current?.trim() ?? "";
        if (text.length > 0) {
            const sentMessageResponse = onSend(text);
            sentMessageResponse && onMessageSent?.(sentMessageResponse);

            inputRef?.current?.clear();
            messageRef.current = "";
        }
    }, [onSend, onMessageSent]);

    const accessoryRightElement = useCallback(
        () => accessoryRight?.({ send: onSendCallback }) ?? null,
        [accessoryRight, onSendCallback],
    );

    const inputContainerStyle = {
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-background-primary"],
    };

    const textInputStyle = {
        fontSize: theme["text-body-1-regular-font-size"],
        fontFamily: theme["text-body-1-regular-font-family"],
        letterSpacing: theme["text-body-1-regular-letter-spacing"],
    };

    useEffect(() => {
        messageRef.current = props?.value || "";
        onChangeText?.(props?.value || "");
    }, [onChangeText, props?.value]);

    return (
        <View style={[styles.container, containerStyle]}>
            {conversation.deleted ? (
                /** IMP: Get the design team to change the message and make it generic as deleted flag will be used for multiple use cases */
                <Text category="b2" color="color-basic-45" style={styles.textCenter}>
                    Seems like this user is not available anymore
                </Text>
            ) : (
                <Input
                    ref={inputRef}
                    placeholder="Start typing your message"
                    style={[styles.inputContainer, inputContainerStyle, style]}
                    textStyle={[styles.input, textInputStyle, textStyle]}
                    accessoryRight={accessoryRightElement}
                    defaultValue={defaultValue}
                    onChangeText={onChangeTextCallback}
                    enablesReturnKeyAutomatically={true}
                    spellCheck={false}
                    multiline
                    autoFocus={!!defaultValue}
                    {...restProps}
                />
            )}
        </View>
    );
});

if (process.env.NODE_ENV !== "production") {
    ChatMessageInputView.displayName = "ChatMessageInputView";
}

const styles = StyleSheet.create({
    container: {
        flexShrink: 1,
        flexGrow: 1,
        maxHeight: 350,
    },
    inputContainer: {
        borderRadius: 12,
        borderWidth: 1.5,
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: SpacingValue["space-small"],
        minHeight: 36,
    },
    input: {
        alignItems: "center",
        justifyContent: "center",
        minHeight: 0,
        margin: 0,
        padding: 0,
        paddingTop: 0,
        paddingVertical: 0,
        marginVertical: 0,
        textAlignVertical: "center",
    },
    textCenter: {
        textAlign: "center",
    },
});
