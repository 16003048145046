import { AsyncStorageAPI } from "../interfaces/async-storage";

type AsyncStoragePackage = AsyncStorageAPI;

let AsyncStoragePackage: AsyncStoragePackage | null = null;
try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    AsyncStoragePackage = require("@react-native-async-storage/async-storage").default;
} catch (error) {}

export default AsyncStoragePackage;
