import * as React from "react";
import { Modal, StatusBar, StyleSheet, useWindowDimensions } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { ImageGalleryHeader } from "./header";
import { ImageGalleryProps } from "./types";
import { PhotosCarousel } from "./images-carousel";

export const ImageCarouselModal: React.FC<ImageGalleryProps> = (props) => {
    const { value: theme } = useTheme();

    const {
        onClose,
        onShow,
        visible,
        hardwareAccelerated,
        overlayBackgroundColor = theme["color-overlay-secondary"],
        statusBarTranslucent,
        onDismiss,
        HeaderComponent = ImageGalleryHeader,
        FooterComponent,
        images,
        resizeMode = "cover",
        imageStyle,
        selectedIndex = 0,
        imageSize,
    } = props;

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    const imageArr = Array.isArray(images) ? images : [images];

    if (!imageArr.length) {
        return null;
    }

    const containerStyle = {
        backgroundColor: overlayBackgroundColor,
    };

    const defaultImageSize = Math.min(
        windowHeight - SpacingValue["space-medium"] * 2,
        windowWidth - SpacingValue["space-medium"] * 2,
    );
    const size = imageSize || defaultImageSize;

    return (
        <Modal
            transparent
            animationType="fade"
            onRequestClose={onClose}
            onDismiss={onDismiss}
            onShow={onShow}
            hardwareAccelerated={hardwareAccelerated}
            visible={visible}
            presentationStyle="overFullScreen"
            statusBarTranslucent={statusBarTranslucent}>
            <StatusBar
                translucent={statusBarTranslucent}
                backgroundColor={overlayBackgroundColor}
                barStyle="dark-content"
            />
            <Stack style={[styles.container, containerStyle]}>
                <HeaderComponent onClose={onClose} />
                <PhotosCarousel
                    resizeMode={resizeMode}
                    imageList={imageArr}
                    size={size}
                    imageStyle={imageStyle}
                    slideIndex={selectedIndex}
                />
                {FooterComponent ? <FooterComponent /> : null}
            </Stack>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
