import * as React from "react";
import { FlatListProps, ViewToken } from "react-native";

interface ViewableItemsContextInterface {
    onViewChange: (fn: (token: ViewToken) => void) => () => void;
    onViewableItemsChanged: FlatListProps<unknown>["onViewableItemsChanged"];
}

export const ViewableItemsContext = React.createContext<ViewableItemsContextInterface>({
    // eslint-disable-next-line no-void
    onViewChange: () => () => void 0,
    // eslint-disable-next-line no-void
    onViewableItemsChanged: () => void 0,
});

export const ViewableItemsContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const handlers = React.useRef<Array<(token: ViewToken) => void>>([]);

    const value: ViewableItemsContextInterface = React.useMemo(
        () => ({
            onViewChange: (fn) => {
                handlers.current = [...handlers.current.filter((h) => h !== fn), fn];
                return () => {
                    handlers.current = handlers.current.filter((h) => h !== fn);
                };
            },
            onViewableItemsChanged: (info) => {
                info.changed.forEach((token) =>
                    handlers.current.forEach((handler) => handler(token)),
                );

                info.viewableItems.forEach((token) =>
                    handlers.current.forEach((handler) => handler(token)),
                );
            },
        }),
        [],
    );

    return <ViewableItemsContext.Provider value={value}>{children}</ViewableItemsContext.Provider>;
};
