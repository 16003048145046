import React, { useCallback, useMemo, useState } from "react";
import {
    FlatList,
    Image,
    Modal,
    Pressable,
    SafeAreaView,
    StatusBar,
    StyleSheet,
    View,
    ViewProps,
} from "react-native";
import Video from "react-native-video";

import { useMountedRef } from "@swiggy-private/react-hooks";
import { Button, ColorPalette, SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { ThreeDotsLoader } from "@swiggy-private/react-native-ui";

import { MediaFile } from "./types";

interface MediaPreviewScreenModalProps {
    media: MediaFile[];
    onUpload: (media?: MediaFile[]) => Promise<void>;
    btnText?: string;
    onError?: (err?: Error) => void;
    HeaderComponent: React.FC<
        {
            onBack: () => void;
            backIconColor: keyof ColorPalette;
            title: () => React.ReactElement;
        } & ViewProps
    >;
    onDismiss?: VoidFunction;
}

const isVideo = (media: MediaFile): boolean => media.type === "video";

export const MediaPreviewScreenModal: React.FC<MediaPreviewScreenModalProps> = ({
    media,
    onUpload,
    btnText,
    onError,
    HeaderComponent,
    onDismiss,
}) => {
    const { value: theme } = useTheme();
    const [selectedMedia, updateSelectedMedia] = useState<MediaFile>(media[0]);
    const [uploading, setUploading] = useState(false);
    const mounted = useMountedRef();

    const onUploadBtnPress = useCallback(async () => {
        if (uploading || !media.length) {
            return;
        }

        setUploading(true);
        await onUpload(media).catch(onError);
        if (mounted.current) {
            setUploading(false);
        }
    }, [media, mounted, onError, onUpload, uploading]);

    const bgColor = theme["color-basic-100"].toString();
    const bgStyle = useMemo(() => ({ backgroundColor: bgColor }), [bgColor]);
    const containerStyle = {
        ...bgStyle,
    };

    const Title = useCallback((): React.ReactElement => {
        return (
            <Text
                color="color-basic-0"
                ellipsizeMode="tail"
                numberOfLines={1}
                category="h4"
                style={styles.title}>
                {media.length === 1 ? "Confirm Media" : `Preview ${media.length} Media`}
            </Text>
        );
    }, [media.length]);

    const renderItem: React.FC<{ item: MediaFile; index: number }> = useCallback(
        ({ item }) => {
            const selectedBorder =
                selectedMedia === item
                    ? { borderWidth: 1, borderColor: theme["color-primary"].toString() }
                    : {};

            return (
                <Pressable accessible onPress={() => updateSelectedMedia(item)}>
                    <Image source={{ uri: item.path }} style={[styles.thumb, selectedBorder]} />
                </Pressable>
            );
        },
        [selectedMedia, theme],
    );

    const SelectedMediaComponent: React.FC<{ item: MediaFile }> = useCallback(
        ({ item }) => {
            if (isVideo(item)) {
                return (
                    <View style={[styles.mediaContainter, bgStyle]}>
                        <Video
                            source={{ uri: item.path }}
                            resizeMode="contain"
                            posterResizeMode="contain"
                            controls={true}
                            style={styles.media}
                            muted
                        />
                    </View>
                );
            }

            return (
                <View style={[styles.mediaContainter, bgStyle]}>
                    <Image source={{ uri: item.path }} style={styles.media} resizeMode="contain" />
                </View>
            );
        },
        [bgStyle],
    );

    return (
        <Modal
            onRequestClose={() => onUpload()}
            onDismiss={onDismiss}
            animationType="slide"
            visible
            statusBarTranslucent={false}
            presentationStyle="overFullScreen"
            style={[styles.container, containerStyle]}>
            <View style={styles.container}>
                <HeaderComponent
                    onBack={onUpload}
                    style={bgStyle}
                    backIconColor="color-basic-0"
                    title={Title}
                />
                <SafeAreaView style={[styles.content, bgStyle]}>
                    <SelectedMediaComponent item={selectedMedia} />
                    {media.length > 1 ? (
                        <FlatList
                            horizontal={true}
                            bounces={false}
                            ItemSeparatorComponent={ItemSeparatorComponent}
                            contentContainerStyle={[styles.flatList]}
                            data={media}
                            renderItem={renderItem}
                            extraData={{ selectedMedia }}
                            keyExtractor={(_, index) => index.toString()}
                        />
                    ) : null}
                    <View style={[styles.uploadBtnContainer, bgStyle]}>
                        <Button
                            onPress={onUploadBtnPress}
                            color="primary"
                            style={[styles.uploadBtn]}>
                            {uploading ? <ThreeDotsLoader /> : btnText ?? "Upload"}
                        </Button>
                    </View>
                </SafeAreaView>
            </View>
            <StatusBar translucent={false} backgroundColor={bgColor} barStyle="light-content" />
        </Modal>
    );
};

const ItemSeparatorComponent: React.FC = () => <View style={styles.mr2} />;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    content: {
        flex: 1,
    },
    flatList: {
        marginBottom: SpacingValue["space-xx-large"] + SpacingValue["space-x-small"],
    },
    mediaContainter: {
        flexGrow: 1,
        flexShrink: 1,
        paddingVertical: SpacingValue["space-large"] * 3,
    },
    media: {
        width: "100%",
        height: "100%",
    },
    thumb: {
        height: 64,
        width: 64,
    },
    uploadBtnContainer: {
        justifyContent: "flex-end",
        paddingVertical: SpacingValue["space-large"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    uploadBtn: {
        width: "100%",
        textTransform: "uppercase",
    },
    uploadBtnForWeb: {
        width: "25%",
        alignSelf: "center",
    },
    mr2: { marginRight: SpacingValue["space-xxx-small"] },
    title: {
        lineHeight: 24,
        marginTop: SpacingValue["space-xxx-small"],
    },
});
