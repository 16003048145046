import memoizeOne from "memoize-one";

import type { Theme } from "@swiggy-private/rn-dls-theme";
import type { TooltipStyle, TooltipStyleMeta } from "../interfaces/tooltip";

const style = memoizeOne(
    (theme: Theme): TooltipStyle => ({
        backgroundColor: theme["color-basic-75"],
        paddingHorizontal: theme["space-x-small"],
        paddingVertical: theme["space-x-small"],
        pointerBackgroundColor: theme["color-basic-75"],
        pointerSize: 6,
        textColor: theme["color-basic-0"],
        borderRadius: 6,
        textFontFamily: theme["text-body-3-medium-font-family"],
        textFontSize: theme["text-body-3-medium-font-size"],
        textLetterSpacing: theme["text-body-3-medium-letter-spacing"],
        textLineHeight: theme["text-body-3-medium-line-height"],
    }),
);

export const getTooltipStyles = (_: TooltipStyleMeta, theme: Theme): TooltipStyle => {
    return style(theme);
};
