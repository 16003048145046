import React from "react";
import { StyleProp, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps } from "@swiggy-private/rn-dls";
import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";

export interface PerfMetricsWidgetProps {
    icon: SvgIconType["icon"];
    text: string;
    testID: string;

    title?: string;
    iconColor?: SvgIconType["color"];
    containerStyle?: StyleProp<ViewStyle>;
    containerStackSpacing?: number;
    titleStackSpacing?: number;
    iconProps?: SvgIconType;
    iconContainerStyle?: StyleProp<ViewStyle>;
    textProps?: TextProps;
    titleProps?: TextProps;
}

const DEFAULT_ICON_SIZE = 12;

const PerfMetricsWidgetComponent: React.FC<PerfMetricsWidgetProps> = ({
    icon,
    text,
    title,
    testID,
    containerStyle,
    containerStackSpacing,
    titleStackSpacing,
    iconProps = {},
    textProps = {},
    titleProps = {},
    iconContainerStyle = {},
    iconColor,
}) => {
    if (!icon && !text) {
        return null;
    }

    return (
        <Stack
            spacing={containerStackSpacing ?? 3 * SpacingValue["space-xxx-small"]}
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={containerStyle}
            testID={testID}>
            <Box style={iconContainerStyle}>
                <SvgIcon
                    height={DEFAULT_ICON_SIZE}
                    width={DEFAULT_ICON_SIZE}
                    {...iconProps}
                    icon={icon}
                    color={iconColor ?? "color-primary"}
                />
            </Box>

            <Stack
                alignItems="flex-start"
                justifyContent="center"
                spacing={titleStackSpacing ?? SpacingValue["space-xx-small"]}>
                {title ? (
                    <Text category="b3" weight="medium" color="high" {...titleProps}>
                        {title}
                    </Text>
                ) : null}

                <Text category="b3" weight="medium" color="low" {...textProps}>
                    {text}
                </Text>
            </Stack>
        </Stack>
    );
};

export const PerfMetricsWidget = React.memo(PerfMetricsWidgetComponent);
