import { LayoutRectangle, ScaledSize } from "react-native";

export enum TooltipPlacement {
    TOP,
    TOP_START,
    TOP_END,
    BOTTOM,
    BOTTOM_START,
    BOTTOM_END,
    LEFT,
    LEFT_START,
    LEFT_END,
    RIGHT,
    RIGHT_START,
    RIGHT_END,
}

export enum TooltipPointerDirection {
    TOP,
    DOWN,
    LEFT,
    RIGHT,
}

const TOP_PLACEMENT = [TooltipPlacement.TOP, TooltipPlacement.TOP_START, TooltipPlacement.TOP_END];
const BOTTOM_PLACEMENT = [
    TooltipPlacement.BOTTOM,
    TooltipPlacement.BOTTOM_END,
    TooltipPlacement.BOTTOM_START,
];

const LEFT_PLACEMENT = [
    TooltipPlacement.LEFT,
    TooltipPlacement.LEFT_END,
    TooltipPlacement.LEFT_START,
];

const VERTICAL_PLACEMENT = [...TOP_PLACEMENT, ...BOTTOM_PLACEMENT];

// Minimum padding between the edge of the screen and the tooltip
const SCREEN_INDENT = 8;
const DEFAULT_POINTER_OFFSET = 8;

type CalculateTooltipCoordinatesParams = {
    windowLayout: ScaledSize;
    anchorLayout: LayoutRectangle;
    tooltipLayout: LayoutRectangle;
    placement: TooltipPlacement;
    verticalOffset?: number;
    horizontalOffset?: number;
    pointerSize?: number;
    pointerOffset?: number;
    canAdjustPosition?: boolean;
};

type CalculateTooltipCoordinatesReturn = {
    x: number;
    y: number;
    pointerX: number;
    pointerY: number;
    pointerDirection: TooltipPointerDirection;
};

const isOutOfBounds = ({
    windowLayout,
    anchorLayout,
    tooltipLayout,
    horizontalOffset = 0,
    verticalOffset = 0,
    pointerSize = 0,
    placement,
}: CalculateTooltipCoordinatesParams): boolean => {
    if (BOTTOM_PLACEMENT.includes(placement)) {
        return (
            anchorLayout.y +
                anchorLayout.height +
                tooltipLayout.height +
                pointerSize +
                verticalOffset >
            windowLayout.height - SCREEN_INDENT
        );
    }

    if (TOP_PLACEMENT.includes(placement)) {
        return anchorLayout.y - tooltipLayout.height - pointerSize - verticalOffset < SCREEN_INDENT;
    }

    if (LEFT_PLACEMENT.includes(placement)) {
        return (
            anchorLayout.x - tooltipLayout.width - pointerSize - horizontalOffset < SCREEN_INDENT
        );
    }

    return (
        anchorLayout.x + anchorLayout.width + tooltipLayout.width + pointerSize + horizontalOffset >
        windowLayout.width - SCREEN_INDENT
    );
};

const reversePlacement = (placement: TooltipPlacement): TooltipPlacement => {
    switch (placement) {
        case TooltipPlacement.BOTTOM:
            return TooltipPlacement.TOP;
        case TooltipPlacement.BOTTOM_END:
            return TooltipPlacement.TOP_END;
        case TooltipPlacement.BOTTOM_START:
            return TooltipPlacement.TOP_START;

        case TooltipPlacement.TOP:
            return TooltipPlacement.BOTTOM;
        case TooltipPlacement.TOP_END:
            return TooltipPlacement.BOTTOM_END;
        case TooltipPlacement.TOP_START:
            return TooltipPlacement.BOTTOM_START;

        case TooltipPlacement.LEFT:
            return TooltipPlacement.RIGHT;
        case TooltipPlacement.LEFT_END:
            return TooltipPlacement.RIGHT_END;
        case TooltipPlacement.LEFT_START:
            return TooltipPlacement.RIGHT_START;

        case TooltipPlacement.RIGHT:
            return TooltipPlacement.LEFT;
        case TooltipPlacement.RIGHT_END:
            return TooltipPlacement.LEFT_END;
        case TooltipPlacement.RIGHT_START:
            return TooltipPlacement.LEFT_START;

        default:
            return placement;
    }
};

export const calculateTooltipCoordinates = ({
    canAdjustPosition = true,
    ...params
}: CalculateTooltipCoordinatesParams): CalculateTooltipCoordinatesReturn => {
    if (canAdjustPosition && isOutOfBounds(params)) {
        return calculateTooltipCoordinates({
            ...params,
            canAdjustPosition: false,
            placement: reversePlacement(params.placement),
        });
    }

    const {
        anchorLayout,
        tooltipLayout,
        verticalOffset = 0,
        horizontalOffset = 0,
        pointerSize = 0,
        pointerOffset = DEFAULT_POINTER_OFFSET,
        placement,
    } = params;

    const anchorHeight = anchorLayout.height;
    const anchorWidth = anchorLayout.width;
    const anchorTop = anchorLayout.y;
    const anchorBottom = anchorTop + anchorHeight;
    const anchorLeft = anchorLayout.x;
    const anchorRight = anchorLeft + anchorWidth;

    const tooltipWidth = tooltipLayout.width;
    const tooltipHeight = tooltipLayout.height;

    if (VERTICAL_PLACEMENT.includes(placement)) {
        const isTop = TOP_PLACEMENT.includes(placement);
        const isStart =
            placement === TooltipPlacement.BOTTOM_START || placement === TooltipPlacement.TOP_START;

        const isEnd =
            placement === TooltipPlacement.BOTTOM_END || placement === TooltipPlacement.TOP_END;

        const pointerWidth = pointerSize * 2;
        const pointerCenter = tooltipWidth / 2 - pointerWidth / 2;
        const pointerLeft = pointerOffset;
        const pointerRight = tooltipWidth - (pointerOffset + pointerWidth);
        const pointerTop = isTop ? tooltipHeight : -pointerSize;

        const xCenter = anchorLeft + anchorWidth / 2 - tooltipWidth / 2;
        const tooltipY = isTop
            ? anchorTop - tooltipHeight - verticalOffset - pointerSize
            : anchorBottom + verticalOffset + pointerSize;

        return {
            y: tooltipY,
            x: isStart ? anchorLeft : isEnd ? anchorRight - tooltipWidth : xCenter,
            pointerY: pointerTop,
            pointerX: isStart ? pointerLeft : isEnd ? pointerRight : pointerCenter,
            pointerDirection: isTop ? TooltipPointerDirection.DOWN : TooltipPointerDirection.TOP,
        };
    }

    const isLeft = LEFT_PLACEMENT.includes(placement);
    const isStart =
        placement === TooltipPlacement.LEFT_START || placement === TooltipPlacement.RIGHT_START;

    const isEnd =
        placement === TooltipPlacement.LEFT_END || placement === TooltipPlacement.RIGHT_END;

    const YCenter = anchorTop + anchorHeight / 2 - tooltipHeight / 2;
    const tooltipX = isLeft
        ? anchorLeft - tooltipWidth - horizontalOffset - pointerSize
        : anchorRight + horizontalOffset + pointerSize;

    const pointerWidth = pointerSize * 2;
    const pointerCenter = tooltipHeight / 2 - pointerWidth / 2;
    const pointerTop = pointerOffset;
    const pointerBottom = tooltipHeight - (pointerOffset + pointerWidth);
    const pointerLeft = isLeft ? tooltipWidth : -pointerSize;

    return {
        y: isStart ? anchorTop : isEnd ? anchorBottom - tooltipHeight : YCenter,
        x: tooltipX,
        pointerY: isStart ? pointerTop : isEnd ? pointerBottom : pointerCenter,
        pointerX: pointerLeft,
        pointerDirection: isLeft ? TooltipPointerDirection.RIGHT : TooltipPointerDirection.LEFT,
    };
};
