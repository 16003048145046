import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import Svg, { Path } from "react-native-svg";

import { getWrapperStyle, timeStyle } from "../helpers";
import { useCountdown } from "../hooks/use-countdown";
import { Props } from "../types";
import { Dashes } from "./dashes";

const TimerComponent: React.FC<Props> = (props) => {
    const { children, duration, strokeLinecap, trailColor, trailStrokeWidth } = props;

    const {
        path,
        pathLength,
        stroke,
        strokeDashoffset,
        remainingTime,
        elapsedTime,
        size,
        strokeWidth,
    } = useCountdown(props);

    return (
        <View style={getWrapperStyle(size) as StyleProp<ViewStyle>}>
            <Svg width={size} height={size}>
                <Path
                    d={path}
                    fill="none"
                    stroke={trailColor ?? "#d9d9d9"}
                    strokeWidth={trailStrokeWidth ?? strokeWidth}
                />
                <Dashes {...props} />
                {elapsedTime !== duration && (
                    <Path
                        d={path}
                        fill="none"
                        stroke={stroke}
                        strokeLinecap={strokeLinecap ?? "round"}
                        strokeWidth={strokeWidth}
                        strokeDasharray={pathLength}
                        strokeDashoffset={strokeDashoffset}
                    />
                )}
            </Svg>
            {typeof children === "function" && (
                <View style={timeStyle as StyleProp<ViewStyle>}>
                    {children({ remainingTime, elapsedTime, color: stroke })}
                </View>
            )}
        </View>
    );
};

export const Timer = React.memo(TimerComponent);
