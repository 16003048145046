import { Dimensions, useWindowDimensions } from "react-native";

import { getScreenSize } from "../utils/screen";
import { ScreenSize } from "../interfaces/screen";

const useScreenSizeOnly = (): ScreenSize => {
    const width = Dimensions.get("window").width;
    return getScreenSize(width);
};

const useScreenSizeWithResize = (): ScreenSize => {
    const { width } = useWindowDimensions();
    return getScreenSize(width);
};

let screenSizeFn;

if (typeof window !== "undefined" && !window.location.hostname.includes("desktop")) {
    screenSizeFn = useScreenSizeOnly;
} else {
    screenSizeFn = useScreenSizeWithResize;
}

export const useScreenSize = screenSizeFn;
