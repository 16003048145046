import React from "react";
import { StyleSheet } from "react-native";

import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Text, useTheme } from "@swiggy-private/rn-dls";

import { CouponCardProps } from "../types";

export const CouponCode: React.FC<CouponCardProps> = (props) => {
    const { value: theme } = useTheme();

    if (!props.code) {
        return null;
    }

    const { prefix, text, style, prefixProps, props: codeProps } = props.code;

    return (
        <Box direction="row" style={[styles.content, style]}>
            <Box>
                <Text {...prefixProps}>{prefix}</Text>
            </Box>
            <Box>
                <Text
                    {...codeProps}
                    style={{ color: props.options?.brandColor ?? theme["color-primary"] }}>
                    {text}
                </Text>
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    content: { alignSelf: "flex-start", flexWrap: "wrap", alignItems: "center" },
});
