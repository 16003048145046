/**
 * `isNumber` determines if the variable is a number.
 * For reference - {@link [link](https://github.com/jonschlinkert/is-number/blob/master/index.js)}
 *
 * @param num any variable to be tested for number
 * @returns boolean
 */
export const isNumber = (num: unknown): boolean => {
    if (typeof num === "number") {
        return num - num === 0;
    }
    if (typeof num === "string" && num.trim() !== "") {
        return Number.isFinite ? Number.isFinite(+num) : isFinite(+num);
    }
    return false;
};

/**
 * `getNumber` returns the number from the variable.
 * Uses {@link isNumber} to determine if the variable is a valid number
 *
 * @param text any variable to be converted to number
 * @returns number | false
 */
export const getNumber = (num: unknown): number | false => (isNumber(num) ? Number(num) : false);

/**
 * `getNumberInShorthandFormat` formats a given number into shorthand.
 *
 * @param num number to be formatted
 * @returns string
 */
export const getNumberInShorthandFormat = (n: unknown): string => {
    const num = getNumber(n);

    if (!num) {
        return "0";
    }

    if (num < 0) {
        return `-${getNumberInShorthandFormat(-1 * num)}`;
    }

    if (num < 1000) {
        return num.toString();
    }

    if (num < 1000000) {
        return `${(num / 1000).toFixed(1)}K`;
    }

    if (num < 1000000000) {
        return `${(num / 1000000).toFixed(1)}M`;
    }

    return `${(num / 1000000000).toFixed(1)}B`;
};
