import * as React from "react";
import { StyleSheet } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { AddedToCartMessage } from "@swiggy-private/connect-chat-commons";
import { Box } from "@swiggy-private/rn-adaptive-layout";

interface AddedToCartMessageProps {
    message: AddedToCartMessage;
}

const AddedToCartMessageComponent: React.FC<AddedToCartMessageProps> = ({
    message: { payload: data },
}) => {
    // TODO: need to do in v2.
    const { productName = "" } = data as never;

    if (!productName) {
        return null;
    }

    return (
        <Box direction="row" style={styles.container} justifyContent="center">
            <Text color="color-basic-60" category="b3" ellipsizeMode="middle">
                {`Item "${productName}" added to cart`}
            </Text>
        </Box>
    );
};

export const AddedToCartMsg = React.memo(AddedToCartMessageComponent);

const styles = StyleSheet.create({
    container: {
        paddingVertical: SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-x-small"],
    },
});
