import { useCallback } from "react";
import { MouseEvent, PressableProps } from "react-native";
import { UserInteraction } from "../styles/style-service";

type EventHandlers = Pick<PressableProps, "onPressIn" | "onPressOut" | "onBlur" | "onFocus"> & {
    onHoverIn?: (e: MouseEvent) => void;
    onHoverOut?: (e: MouseEvent) => void;
};

export const useEventHandlers = (
    { onHoverIn, onHoverOut, onBlur, onFocus, onPressIn, onPressOut }: EventHandlers,
    dispatch: (interaction: UserInteraction[]) => void,
    disabled?: boolean | null,
): EventHandlers => {
    const onHoverInCallback: NonNullable<EventHandlers["onHoverIn"]> = useCallback(
        (e) => {
            if (disabled) {
                return;
            }

            dispatch([UserInteraction.HOVER]);
            onHoverIn?.(e);
        },
        [onHoverIn, dispatch, disabled],
    );

    const onHoverOutCallback: NonNullable<EventHandlers["onHoverOut"]> = useCallback(
        (e) => {
            if (disabled) {
                return;
            }

            dispatch([]);
            onHoverOut?.(e);
        },
        [onHoverOut, dispatch, disabled],
    );

    const onFocusCallback: NonNullable<EventHandlers["onFocus"]> = useCallback(
        (e) => {
            if (disabled) {
                return;
            }

            dispatch([UserInteraction.FOCUSED]);
            onFocus?.(e);
        },
        [onFocus, dispatch, disabled],
    );

    const onBlurCallback: NonNullable<EventHandlers["onBlur"]> = useCallback(
        (e) => {
            if (disabled) {
                return;
            }

            dispatch([]);
            onBlur?.(e);
        },
        [onBlur, dispatch, disabled],
    );

    const onPressInCallback: NonNullable<EventHandlers["onPressIn"]> = useCallback(
        (e) => {
            dispatch([UserInteraction.ACTIVE]);
            onPressIn?.(e);
        },
        [onPressIn, dispatch],
    );

    const onPressOutCallback: NonNullable<EventHandlers["onPressOut"]> = useCallback(
        (e) => {
            dispatch([]);
            onPressOut?.(e);
        },
        [onPressOut, dispatch],
    );

    return {
        onHoverIn: onHoverInCallback,
        onHoverOut: onHoverOutCallback,
        onFocus: onFocusCallback,
        onBlur: onBlurCallback,
        onPressIn: onPressInCallback,
        onPressOut: onPressOutCallback,
    };
};
