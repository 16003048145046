import * as React from "react";
import {
    Animated,
    Easing,
    LayoutChangeEvent,
    Platform,
    View,
    ViewProps,
    ViewStyle,
} from "react-native";
import type { ColorPalette } from "@swiggy-private/rn-dls-theme";
import { useMount } from "@swiggy-private/react-hooks";

import { useTheme } from "../../styles/theme-service";
import { useLayout } from "../../hooks/use-layout";
import { useAnimatedValue } from "../../hooks/use-animated-value";

export interface LinearProgressProps extends ViewProps {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    progress?: number;
    backgroundColor?: keyof ColorPalette;
    fillColor?: keyof ColorPalette;
    height?: number;
    /**
     * The variant to use. Use indeterminate when there is no progress value.
     * Default value is `determinate`
     */
    variant?: "determinate" | "indeterminate";
}

/**
 * Progress bars express an unspecified wait time or display the length of a process.
 *
 * ## Usage
 * ```ts
 * import * as React from "react";
 * import { LinearProgress } from "@swiggy-private/rn-dls";
 *
 * const MyComponent: React.FC = () => {
 *   return <LinearProgress progress={20} />;
 * };
 *```
 */
export const LinearProgress: React.FC<LinearProgressProps> = (props) => {
    const {
        backgroundColor = "color-basic-15",
        fillColor = "color-highlight",
        height = 8,
        progress = 0,
        variant = "determinate",

        style: propStyle,
        onLayout: propOnLayout,

        accessible = true,
        accessibilityRole = "progressbar",
        accessibilityLabel = variant === "determinate"
            ? `progress bar. ${Math.round(progress)}%`
            : undefined,
        accessibilityValue = variant === "determinate"
            ? {
                  min: 0,
                  max: 100,
                  now: progress,
              }
            : undefined,

        ...restProps
    } = props;

    const progressValue = Math.min(progress, 100);

    const { value: theme } = useTheme();
    const [containerLayout, onLayout] = useLayout();

    const progressRef = React.useRef(0);
    const progressAnimation = useAnimatedValue(progressValue || 0);

    const onContainerLayout = React.useCallback(
        (event: LayoutChangeEvent) => {
            onLayout(event);
            propOnLayout?.(event);
        },
        [onLayout, propOnLayout],
    );

    React.useEffect(() => {
        if (variant === "determinate" && progressRef.current !== progressValue) {
            progressRef.current = progressValue;
            Animated.timing(progressAnimation, {
                duration: 200,
                easing: Easing.ease,
                toValue: progressValue,
                useNativeDriver: Platform.OS !== "web",
            }).start();
        }
    }, [progressAnimation, progressValue, variant]);

    useMount(() => {
        if (variant === "indeterminate") {
            progressAnimation.setValue(0);
            Animated.loop(
                Animated.timing(progressAnimation, {
                    duration: 1500,
                    easing: Easing.bezier(0.65, 0.815, 0.735, 0.395),
                    toValue: 100,
                    useNativeDriver: Platform.OS !== "web",
                }),
            ).start();
        }
    });

    const containerStyle: ViewStyle = {
        height,
        backgroundColor: theme[backgroundColor] ?? backgroundColor,
        borderRadius: 10,
        overflow: "hidden",
    };

    const progressStyle = {
        backgroundColor: theme[fillColor] ?? fillColor,
        transform: [
            {
                translateX: -containerLayout.width / 2 /* shifts origin to left */,
            },
            {
                scaleX: containerLayout.measured
                    ? variant === "indeterminate"
                        ? 0.25
                        : progressAnimation.interpolate({
                              inputRange: [0, 100],
                              outputRange: [0, 2],
                          })
                    : 0,
            },
            {
                translateX:
                    variant === "indeterminate"
                        ? progressAnimation.interpolate({
                              inputRange: [0, 100],
                              outputRange: [-containerLayout.width / 2, containerLayout.width * 4],
                          })
                        : 0,
            },
        ],
    };

    return (
        <View
            style={[containerStyle, propStyle]}
            onLayout={onContainerLayout}
            accessible={accessible}
            accessibilityRole={accessibilityRole}
            accessibilityLabel={accessibilityLabel}
            accessibilityValue={accessibilityValue}
            {...restProps}>
            <Animated.View style={[containerStyle, progressStyle, propStyle]} />
        </View>
    );
};
