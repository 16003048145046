import React from "react";
import {
    Insets,
    StyleProp,
    StyleSheet,
    ViewStyle,
    Pressable,
    PressableProps,
    View,
    MouseEvent,
} from "react-native";

export interface TouchableComponentProps extends PressableProps {
    useDefaultHitSlop?: boolean;
    style?: StyleProp<ViewStyle>;
    children?: React.ReactNode;
    /**
     * @platform web
     */
    onHoverIn?: (e: MouseEvent) => void;
    /**
     * @platform web
     */
    onHoverOut?: (e: MouseEvent) => void;
}

const createHitSlopInsets = (style?: TouchableComponentProps["style"]): Insets => {
    const flatStyle: ViewStyle = StyleSheet.flatten(style || {});
    const value: number = 40 - Number(flatStyle.height || 0);

    return {
        left: value > 0 ? value : 0,
        top: value > 0 ? value : 0,
        right: value > 0 ? value : 0,
        bottom: value > 0 ? value : 0,
    };
};

type TouchableComponent = React.ForwardRefExoticComponent<
    TouchableComponentProps & React.RefAttributes<View>
>;

/**
 * Helper component for the `Pressable` with recommended hitSlop.
 * @see https://reactnative.dev/docs/view#hitslop
 *
 * @param props {@link TouchableComponentProps}
 */
export const TouchableComponent: TouchableComponent = React.forwardRef(
    ({ useDefaultHitSlop, ...props }, ref) => {
        return (
            <Pressable
                hitSlop={useDefaultHitSlop ? createHitSlopInsets(props.style) : undefined}
                ref={ref as React.Ref<View>}
                {...props}
            />
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    TouchableComponent.displayName = "TouchableComponent";
}
