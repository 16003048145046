import React, { ForwardRefRenderFunction } from "react";
import { Animated, StyleProp, StyleSheet, View, ViewProps, ViewStyle } from "react-native";
import { ColorPalette, Elevation, shadow } from "@swiggy-private/rn-dls-theme";

import { useTheme } from "../../styles/theme-service";

export type SurfaceProps = ViewProps & {
    /**
     * Content of the `Surface`.
     */
    children: React.ReactNode;
    color?: keyof ColorPalette;
    style?: Animated.WithAnimatedValue<StyleProp<ViewStyle>>;
};

const SurfaceComponent: ForwardRefRenderFunction<View, SurfaceProps> = (
    { style, color = "color-surface-primary", ...rest },
    ref,
) => {
    const { elevation = 1 } = (StyleSheet.flatten(style) || {}) as ViewStyle;
    const { value: theme } = useTheme();

    return (
        <Animated.View
            {...rest}
            ref={ref}
            style={[
                {
                    backgroundColor: theme[color],
                },
                elevation ? shadow(elevation as Elevation) : null,
                style,
            ]}
        />
    );
};

/**
 * Surface is a basic container that can give depth to an element with elevation shadow.
 * Overlay and shadow can be applied by specifying the `elevation` property both on Android and iOS.
 *
 * ## Usage
 * ```js
 * import * as React from "react";
 * import { StyleSheet } from "react-native";
 * import { Surface, Text } from "@swiggy-private/rn-dls";
 *
 * export const MyComponent: React.FC = () => (
 *   <Surface style={styles.surface}>
 *      <Text>Surface</Text>
 *   </Surface>
 * );
 *
 * const styles = StyleSheet.create({
 *   surface: {
 *     padding: 8,
 *     height: 80,
 *     width: 80,
 *     alignItems: 'center',
 *     justifyContent: 'center',
 *     elevation: 4,
 *   },
 * });
 * ```
 *
 * @param param0 {@link SurfaceProps}
 */
export const Surface = React.forwardRef(SurfaceComponent);
