export class AssertionError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "AssertionError";
    }
}

export function assert(condition: unknown, msg?: string): asserts condition {
    if (!condition) {
        throw new AssertionError(msg ?? "");
    }
}
