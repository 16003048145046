import { IChatService } from "@swiggy-private/connect-chat-sdk";
import React, { createContext } from "react";

export const ChatServiceContext = createContext<IChatService | null>(null);

export const ChatServiceContextProvider: React.FC<
    React.PropsWithChildren<{ value: IChatService | null }>
> = (props) => {
    return <ChatServiceContext.Provider {...props} />;
};
