import { ActivityIndicator } from "@swiggy-private/rn-dls";
import { useConstructor } from "@swiggy-private/react-hooks";
import * as React from "react";
import { Image, ImageProps, PixelRatio, StyleSheet, View } from "react-native";

import { CloudinaryContext } from "../../contexts/cloudinary-context";
import {
    CloudinaryTransformation,
    getCloudinaryImageUrl,
    getCloudinaryTransformationsFromChildren,
    getImageFormatForPlatform,
} from "../../helpers/cloudinary";
import { isUri } from "../../helpers/uri";
import { CTransformation, CTransformationProps } from "../cloudinary-transformation";

type Children = React.ReactElement<CTransformationProps, typeof CTransformation>;

export interface CImageProps extends Omit<ImageProps, "source"> {
    id: string;
    width?: number;
    height?: number;
    resourceType?: "upload" | "youtube";
    children?: Children | Children[];
    fallback?: boolean;
    showLoader?: boolean;
    format?: CloudinaryTransformation["format"];
    startPerfMarker?: VoidFunction;
    stopPerfMarker?: VoidFunction;
}

type ImageUrlParams = {
    id: string;
    cloudName: string;
    cloudBaseUrl: string;
    folderName?: string;
    cloudResourceType?: "upload" | "youtube";
    width?: number;
    height?: number;
    format?: CloudinaryTransformation["format"];
    children?: Children | Children[];
};

export const getImageUrl = ({
    id,
    cloudBaseUrl,
    cloudName,
    cloudResourceType,
    folderName,
    width,
    height,
    format,
    children,
}: ImageUrlParams): string => {
    return isUri(id)
        ? id
        : getCloudinaryImageUrl({
              id,
              cloudName,
              cloudBaseUrl,
              folderName,
              cloudResourceType,
              transformations: [
                  ...getCloudinaryTransformationsFromChildren(CTransformation, children),
                  {
                      width: width ? PixelRatio.getPixelSizeForLayoutSize(width) : undefined,
                      height: height ? PixelRatio.getPixelSizeForLayoutSize(height) : undefined,
                      crop: "fit",
                  },
                  {
                      format: format ?? getImageFormatForPlatform(),
                  },
              ],
          });
};

export const CImage: React.FC<CImageProps> & {
    Component: React.FC<ImageProps> & typeof Image;
    ForceCache: boolean;
} = ({
    id,
    resizeMode = "cover",
    width,
    height,
    style,
    children,
    resourceType: cloudResourceType = "upload",
    showLoader = false,
    onLoadStart,
    onLoadEnd,
    startPerfMarker,
    stopPerfMarker,
    format,
    ...props
}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const { cloudName, baseUrl: cloudBaseUrl, folderName } = React.useContext(CloudinaryContext);

    const imageUrl = React.useMemo(
        () =>
            getImageUrl({
                id,
                cloudBaseUrl,
                cloudName,
                cloudResourceType,
                folderName,
                height,
                width,
                format,
                children,
            }),
        [
            children,
            cloudBaseUrl,
            cloudName,
            cloudResourceType,
            folderName,
            height,
            id,
            width,
            format,
        ],
    );

    const onLoadStartCallback: ImageProps["onLoadStart"] = React.useCallback(() => {
        showLoader && setIsLoading(true);

        onLoadStart?.();
    }, [onLoadStart, showLoader]);

    const onLoadEndCallback: ImageProps["onLoadEnd"] = React.useCallback(() => {
        showLoader && setIsLoading(false);

        onLoadEnd?.();
        stopPerfMarker?.();
    }, [onLoadEnd, stopPerfMarker, showLoader]);

    useConstructor(() => {
        startPerfMarker?.();
    });

    const image = (
        <CImage.Component
            {...props}
            style={[{ width, height }, style]}
            resizeMode={resizeMode}
            source={{ uri: imageUrl, cache: CImage.ForceCache ? "force-cache" : undefined }}
            onLoadStart={onLoadStartCallback}
            onLoadEnd={onLoadEndCallback}
        />
    );

    if (!showLoader) {
        return image;
    }

    return (
        <View>
            {image}
            {showLoader && isLoading ? <ActivityIndicator style={styles.loader} /> : null}
        </View>
    );
};

const styles = StyleSheet.create({
    loader: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: "center",
        alignItems: "center",
    },
});

CImage.ForceCache = false;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
CImage.Component = Image;
