import * as React from "react";
import { ColorValue, Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SvgIconType, SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme, TextProps } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

type PressableChipProps = {
    title: string;

    icon?: SvgIconType["icon"];
    onPress?: () => void;
    onMount?: () => void;
    isDisabled?: boolean;
    extraStyles?: StyleProp<ViewStyle>;
    iconColor?: ColorValue;
    titleLimit?: number;
    extraTextStyles?: StyleProp<ViewStyle>;
    extraIconStyle?: StyleProp<ViewStyle>;
    iconSize?: number;
    extraTextProps?: TextProps;
};

export const PressableChip: React.FC<PressableChipProps> = ({
    title,
    icon,
    onPress,
    onMount,
    isDisabled = false,
    iconColor,
    titleLimit,
    extraStyles,
    extraTextStyles,
    extraIconStyle,
    iconSize = 24,
    extraTextProps,
}) => {
    const { value: theme } = useTheme();

    const text = React.useMemo(
        () =>
            titleLimit && title.length > titleLimit ? title.slice(0, titleLimit) + "..." : title,
        [title, titleLimit],
    );

    useMount(() => onMount?.());

    return (
        <Pressable
            testID="pressable-icon"
            disabled={isDisabled}
            onPress={onPress}
            style={[styles.rootContainer, { borderColor: theme["color-basic-15"] }, extraStyles]}>
            <Stack
                direction="row"
                spacing={3 * SpacingValue["space-xxx-small"]}
                alignItems="center">
                {icon ? (
                    <SvgIcon
                        icon={icon}
                        height={iconSize}
                        width={iconSize}
                        color={iconColor || "color-basic-0"}
                        style={extraIconStyle}
                    />
                ) : null}
                <Text
                    category="b3"
                    weight="medium"
                    color="color-basic-60"
                    style={[styles.text, extraTextStyles]}
                    numberOfLines={1}
                    {...extraTextProps}>
                    {text}
                </Text>
            </Stack>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    rootContainer: {
        minHeight: 36,
        maxWidth: "100%",
        paddingVertical: 4 * SpacingValue["space-xxx-small"],
        paddingHorizontal: SpacingValue["space-x-small"],
        borderRadius: 24,
        borderWidth: 1,
        marginTop: SpacingValue["space-medium"],
    },
    text: {
        maxWidth: "80%",
    },
});
