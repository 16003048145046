import React, { useCallback, useMemo, useRef } from "react";
import { Message } from "../../../../interfaces/types";

type onVisibilityChangeCallback = (m: Message, visible: boolean) => void;

interface IChatConversationContentContext {
    onVisibilityChange: (messageId: string, fn: onVisibilityChangeCallback) => () => void;
}

export const ChatConversationContentContext = React.createContext<IChatConversationContentContext>({
    // eslint-disable-next-line no-void
    onVisibilityChange: () => () => void 0,
});

export type ChatConversationContentContextRefHandler = {
    setVisibleMessages: (messages: Message[]) => void;
};

export const ChatConversationContentContextProvider = React.forwardRef<
    ChatConversationContentContextRefHandler,
    { children: React.ReactElement }
>(({ children }, ref) => {
    const listeners: Record<string, onVisibilityChangeCallback[]> = useRef({}).current;
    const lastVisibleMessagesRef = useRef<Record<string, Message>>({});

    React.useImperativeHandle(
        ref,
        () => ({
            setVisibleMessages: (messages: Message[]) => {
                const visibleMessages = Object.fromEntries(messages.map((m) => [m.id, m]));

                Object.values(lastVisibleMessagesRef.current).forEach((message) => {
                    if (visibleMessages[message.id] == null && listeners[message.id]) {
                        listeners[message.id].forEach((fn) => fn(message, false));
                    }
                });

                messages.forEach((m) => {
                    (listeners[m.id] || []).forEach((fn) => fn(m, true));
                });

                lastVisibleMessagesRef.current = visibleMessages;
            },
        }),
        [listeners],
    );

    const onVisibilityChange = useCallback(
        (messageId: string, fn: (m: Message, visible: boolean) => void) => {
            if (!Array.isArray(listeners[messageId])) {
                listeners[messageId] = [];
            }

            listeners[messageId].push(fn);

            return () => {
                listeners[messageId] = listeners[messageId].filter((f) => f !== fn);
                if (!listeners[messageId].length) {
                    delete listeners[messageId];
                }
            };
        },
        [listeners],
    );

    const value = useMemo(
        () => ({
            onVisibilityChange,
        }),
        [onVisibilityChange],
    );

    return (
        <ChatConversationContentContext.Provider value={value}>
            {children}
        </ChatConversationContentContext.Provider>
    );
});

if (__DEV__) {
    ChatConversationContentContextProvider.displayName = "ChatConversationContentContextProvider";
}
