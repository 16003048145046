import React from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import { useDLS } from "../../styles/style-service";
import { AppBarAction } from "./action";
import { AppBarSearch } from "./search";

const DEFAULT_APPBAR_HEIGHT = 64;

export interface AppBarProps extends ViewProps {
    position?: "absolute" | "relative";
    height?: number;
}

type AppBar = React.ForwardRefExoticComponent<AppBarProps & React.RefAttributes<View>> & {
    Action: typeof AppBarAction;
    Search: typeof AppBarSearch;
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const AppBar: AppBar = React.forwardRef(
    ({ position, height = DEFAULT_APPBAR_HEIGHT, style: propStyle, ...props }, ref) => {
        const dls = useDLS("appbar");
        const containerStyle = {
            height,
            backgroundColor: dls.style.backgroundColor,
        };

        return <View {...props} style={[styles.container, containerStyle, propStyle]} ref={ref} />;
    },
);

if (process.env.NODE_ENV !== "production") {
    AppBar.displayName = "AppBar";
}

AppBar.Action = AppBarAction;
AppBar.Search = AppBarSearch;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        overflow: "hidden",
    },
});
