import React, { memo, useCallback } from "react";
import { StyleSheet, ViewStyle } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { useTheme } from "@swiggy-private/rn-dls";

import { IMG_SIZE, IMG_MULTIPLIER, PRODUCT_IMG_FALLBACK } from "./constants";

interface IItemImagesComponent {
    imageIds: string[];

    imageDisplayCount?: number;
}

const ItemImagesComponent: React.FC<IItemImagesComponent> = ({
    imageIds = [],
    imageDisplayCount = 2,
}) => {
    const { value: theme } = useTheme();

    const hasMultipleItems = imageIds.length > 1;

    const imageContainerStyles: ViewStyle = {
        width: hasMultipleItems ? IMG_SIZE + IMG_MULTIPLIER : IMG_SIZE,
        height: hasMultipleItems ? IMG_SIZE + IMG_MULTIPLIER : IMG_SIZE,
    };

    const renderItemImages = useCallback(() => {
        if (hasMultipleItems) {
            return [...imageIds].slice(0, imageDisplayCount).map((imageId, index) => {
                const imgId = imageId || PRODUCT_IMG_FALLBACK;
                const gutter = index * IMG_MULTIPLIER;
                return (
                    <Box
                        style={[
                            styles.imageContainer,
                            {
                                top: gutter,
                                left: gutter,
                                zIndex: gutter,
                                borderColor: theme["color-basic-0"],
                            },
                        ]}
                        key={`${imgId}-${index}`}>
                        <CdnImage
                            id={imgId}
                            style={styles.image}
                            width={IMG_SIZE}
                            height={IMG_SIZE}
                        />
                    </Box>
                );
            });
        }

        const imgId = imageIds?.[0] || PRODUCT_IMG_FALLBACK;

        return <CdnImage style={styles.image} id={imgId} width={IMG_SIZE} height={IMG_SIZE} />;
    }, [hasMultipleItems, imageDisplayCount, imageIds, theme]);

    return <Stack style={imageContainerStyles}>{renderItemImages()}</Stack>;
};

export const ItemImages = memo(ItemImagesComponent);

const styles = StyleSheet.create({
    imageContainer: {
        borderWidth: 1,
        borderRadius: 8,
        position: "absolute",
    },
    image: {
        borderRadius: 6,
    },
});
