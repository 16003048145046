export type Task = () => Promise<void>;

const __DEV__ = process.env.NODE_ENV !== "production";

export class Scheduler {
    private task: Task;
    private interval: number;

    private _timer?: number;

    private constructor(task: Task, interval: number) {
        this.task = task;
        this.interval = interval;
    }

    public static schedule(task: Task, interval: number): Scheduler {
        const scheduler = new Scheduler(task, interval);
        scheduler.startTimer();
        return scheduler;
    }

    public startTimer(): void {
        if (this._timer) {
            clearTimeout(this._timer);
        }

        this._timer = setTimeout(async () => {
            await this.task().catch((err) => {
                __DEV__ && console.debug(err);
            });

            this.startTimer();
        }, this.interval) as unknown as number;
    }

    destroy(): void {
        clearTimeout(this._timer);
    }
}
