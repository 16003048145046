import { Message, MessageSection } from "../../../../interfaces/types";
import { MessageSectionListData } from "../../interfaces";

export const buildMessageSectionListData = (
    messageSections: MessageSection[],
    messages: Record<string, Message> = {},
): MessageSectionListData[] => {
    return messageSections.map((section) => ({
        id: section.id,
        data: section.messages.map((id) => messages[id]).filter(Boolean),
        timestamp: section.timestamp,
    }));
};
