/* eslint-disable @typescript-eslint/no-explicit-any */

import { SdkMessage } from "@swiggy-private/connect-chat-sdk";
import { logError } from "./helpers/log";

export type IChatEvent<T extends ChatEventTypes> = {
    handled?: boolean;
    type: T;
    value: ChatEventMap[T];
};

export type ChatListener<T extends ChatEventTypes> = (e: IChatEvent<T>) => void;

let listeners: Array<[ChatEventTypes, ChatListener<any>]> = [];

export const ChatEvent = {
    dispatch: <T extends ChatEventTypes>(event: IChatEvent<T>) => {
        listeners.forEach((listener) => {
            try {
                if (event.type === listener[0]) {
                    listener[1](event);
                }
            } catch (err) {
                logError(err);
            }
        });
    },

    listen: <T extends ChatEventTypes>(type: T, listener: ChatListener<T>): (() => void) => {
        const value = [type, listener] as [ChatEventTypes, ChatListener<any>];
        listeners.push(value);

        return () => {
            listeners = listeners.filter((v) => v !== value);
        };
    },
};

export interface ChatEventMap {
    messageNotification: {
        conversationId: string;
        message: SdkMessage;
        publisher: {
            uuid: string;
            name?: string;
            avatarUrl?: string;
        };
        timetoken?: string;
    };
    reply: {
        conversationId: string;
        messageId?: string | null;
        messageTimestamp?: number | null;
    };
    newMessage: {
        conversationId: string;
        message: SdkMessage;
        publisher: string;
    };
}

export type ChatEventTypes = keyof ChatEventMap;
