import React, { useEffect, useCallback, useMemo, useRef } from "react";
import {
    ImageProps,
    ImageStyle,
    StyleSheet,
    TextStyle,
    View,
    ViewStyle,
    Animated,
    Easing,
    useWindowDimensions,
    ViewProps,
} from "react-native";
import { useMountedRef } from "@swiggy-private/react-hooks";

import { useDLS } from "../styles/style-service";
import { FalsyFC, RenderProp } from "../support/falsy-fc";
import { FalsyText } from "../support/falsy-text";
import { TouchableComponent } from "../support/touchable-component";
import { TextProps } from "./text";
import { SnackbarColor, SnackbarStyle } from "../styles/interfaces/snackbar";

export interface SnackbarProps extends Omit<ViewProps, "children"> {
    isActive: boolean;
    isAutoDismiss?: boolean;
    isDefaultTouchable?: boolean;
    onPress?: () => void;
    children?: RenderProp<TextProps> | React.ReactText;
    color?: SnackbarColor;
    accessoryLeft?: RenderProp<Partial<ImageProps>>;
    touchableRight?: RenderProp<TextProps> | React.ReactText;
    childrenStyle?: TextStyle;
    onSnackBarDismiss?: () => void;
}

export type SnackbarElement = React.ReactElement<SnackbarProps>;

const getComponentStyle = (
    source: SnackbarStyle,
): {
    text: TextStyle;
    container: ViewStyle;
    iconLeftContainer: ImageStyle;
    touchableRight: ImageStyle | TextStyle;
} => {
    const {
        textColor,
        textFontFamily,
        textFontSize,
        textLineHeight,
        letterSpacing,
        iconHeight,
        iconWidth,
        textMarginHorizontal,
        touchableTextColor,
        touchableTextFontSize,
        touchableTextFontFamily,
        ...containerProps
    } = source;

    return {
        text: {
            letterSpacing,
            color: textColor,
            fontFamily: textFontFamily,
            fontSize: textFontSize,
            lineHeight: textLineHeight,
            flex: 1,
        },
        iconLeftContainer: {
            width: iconWidth,
            height: iconHeight,
            marginRight: textMarginHorizontal,
        },
        touchableRight: {
            color: touchableTextColor,
            fontFamily: touchableTextFontFamily,
            fontSize: touchableTextFontSize,
            marginLeft: textMarginHorizontal,
            fontWeight: "bold",
        },
        container: {
            ...containerProps,
        },
    };
};

const DEFAULT_TIMEOUT = 4000;
const ANIMATION_IN_DURATION = 200;
const ANIMATION_OUT_DURATION = 400;
const ANIMATION_SHOW = 1;
const ANIMATION_HIDE = 0;
const DEFAULT_TOUCHABLE_TEXT = "CLOSE";

/**
 * Snackbar shows user a message with Slide in animation for some time and, a touchable to take actions before closing the Snackbar.
 * Default Snackbar color is `primary`.
 *
 * @param props {@link SnackbarProps}
 */
export const Snackbar: React.FC<SnackbarProps> = (props) => {
    const {
        isActive = false,
        isAutoDismiss = true,
        isDefaultTouchable = false,
        children,
        style,
        accessoryLeft,
        touchableRight,
        onPress,
        color,
        childrenStyle,
        onSnackBarDismiss,
        ...otherProps
    } = props;

    const { height } = useWindowDimensions();

    const mountedRef = useMountedRef();
    const animated = useRef(new Animated.Value(0)).current;
    const timeoutRef: { current: NodeJS.Timeout | null } = useRef(null);

    const dls = useDLS("snackbar", props);
    const componentStyle = useMemo(() => getComponentStyle(dls.style), [dls.style]);

    const slideInOutAnimatedStyle = useMemo(
        () => ({
            opacity: animated,
            transform: [
                {
                    translateY: animated.interpolate({
                        inputRange: [0, 1],
                        outputRange: [height, 1],
                    }),
                },
            ],
        }),
        [animated, height],
    );

    const hideSnackbar = useCallback(() => {
        mountedRef.current &&
            Animated.timing(animated, {
                toValue: ANIMATION_HIDE,
                duration: ANIMATION_IN_DURATION,
                useNativeDriver: true,
            }).start();
    }, [animated, mountedRef]);

    const hideSnackbarTimeout = useCallback(() => {
        timeoutRef.current = setTimeout(() => {
            if (mountedRef.current) {
                hideSnackbar();
                onSnackBarDismiss?.();
            }
        }, DEFAULT_TIMEOUT);
    }, [hideSnackbar, mountedRef, onSnackBarDismiss]);

    const showSnackbar = useCallback(() => {
        mountedRef.current &&
            Animated.timing(animated, {
                toValue: ANIMATION_SHOW,
                duration: ANIMATION_OUT_DURATION,
                easing: Easing.linear,
                useNativeDriver: true,
            }).start(isAutoDismiss ? hideSnackbarTimeout : undefined);
    }, [mountedRef, animated, isAutoDismiss, hideSnackbarTimeout]);

    useEffect(() => {
        if (isActive) {
            showSnackbar();
        } else {
            if (timeoutRef?.current) {
                clearTimeout(timeoutRef.current);
            }
            hideSnackbar();
        }
        return () => {
            timeoutRef?.current && clearTimeout(timeoutRef.current);
        };
    }, [isActive, hideSnackbar, timeoutRef, showSnackbar]);

    const onPressAndDismiss = useCallback(() => {
        onPress?.();
        hideSnackbar();
    }, [hideSnackbar, onPress]);

    return (
        <Animated.View
            {...otherProps}
            style={[componentStyle.container, styles.container, style, slideInOutAnimatedStyle]}>
            {accessoryLeft && (
                <View style={[componentStyle.iconLeftContainer, styles.iconLeftContainer]}>
                    <FalsyFC component={accessoryLeft} />
                </View>
            )}
            <FalsyText style={[componentStyle.text, childrenStyle]} component={children} />
            {(touchableRight || isDefaultTouchable) && (
                <TouchableComponent onPress={onPressAndDismiss}>
                    <FalsyText
                        component={touchableRight ?? DEFAULT_TOUCHABLE_TEXT}
                        style={[componentStyle.touchableRight, styles.touchableRight]}
                    />
                </TouchableComponent>
            )}
        </Animated.View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        position: "absolute",
    },
    iconLeftContainer: {
        justifyContent: "center",
        alignItems: "center",
    },
    touchableRight: {
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
    },
});

Snackbar.defaultProps = {
    isActive: false,
    isAutoDismiss: true,
    isDefaultTouchable: false,
};
