export const DEFAULTS_GRADIENT = {
    QUANTITY_GRADIENT_COLORS: ["rgba(0, 0, 0, 0.50)", "rgba(0, 0, 0, 0.09)", "rgba(0, 0, 0, 0.00)"],
    QUANTITY_GRADIENT_COLORS_START_POINT: { x: 1, y: 0 },
    QUANTITY_GRADIENT_COLORS_LOCATIONS: [0, 0.8, 1],
};

export const DEFAULTS_GRADIENT_FOOTER = {
    QUANTITY_GRADIENT_COLORS: ["rgba(0, 0, 0, 0)", "rgba(0, 0, 0, 0.5)", "rgba(0, 0, 0, 1.0)"],
    QUANTITY_GRADIENT_COLORS_START_POINT: { x: 1, y: 0 },
    QUANTITY_GRADIENT_COLORS_LOCATIONS: [0, 0.35, 1],
};

export const DEFAULTS__GRADIENT_COVER = {
    QUANTITY_GRADIENT_COLORS: ["rgba(0, 0, 0, 0.9)", "rgba(0, 0, 0, 0.85)", "rgba(0, 0, 0, 0.9)"],
    QUANTITY_GRADIENT_COLORS_START_POINT: { x: 1, y: 0 },
    QUANTITY_GRADIENT_COLORS_LOCATIONS: [0.45, 0.85, 1],
};

export const BLACK_COLOR = "#000000";
