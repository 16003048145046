import { IMAGEKIT_MEDIA_BASE_URL } from "@env";

import React, { JSXElementConstructor, ReactElement } from "react";
import { Platform, PixelRatio } from "react-native";

import rgbHex from "rgb-hex";
import memoize from "memoize-one";

const THUMBNAIL_SUFFIX = "/ik-thumbnail.jpg";
const IK_VIDEO_FORMAT = ".mp4";
const VIDEO_FORMAT_REGEX = /\.(mp4|mov)$/i;

interface GetImagKitImageUrlParams {
    cloudName: string;
    id: string;
    cloudBaseUrl?: string;
    cloudResourceType?: "upload" | "youtube";
    cloudUrl?: string;
    folderName?: string;
    transformations?: Partial<ImageKitTransformation> | Partial<ImageKitTransformation>[];
    shouldFetchThumbnail?: boolean;
}

export const getImageKitImageUrl = (params: GetImagKitImageUrlParams): string => {
    const baseUrl = params.cloudUrl
        ? params.cloudUrl
        : getImageKitImageBaseUrl({
              cloudName: params.cloudName,
              baseUrl: params.cloudBaseUrl,
              type: params.cloudResourceType,
          });

    const transformations = params.transformations
        ? getImageKitTransformation(params.transformations)
        : null;

    const imageId = params.folderName ? `${params.folderName}/${params.id}` : params.id;

    return transformations ? `${baseUrl}/${transformations}/${imageId}` : `${baseUrl}/${imageId}`;
};

export const getImageKitVideoUrl = (params: GetImagKitImageUrlParams): string => {
    const baseUrl = params.cloudUrl
        ? params.cloudUrl
        : getImageKitVideoBaseUrl({
              cloudName: params.cloudName,
              baseUrl: params.cloudBaseUrl,
              type: params.cloudResourceType,
          });

    const transformations = params.transformations
        ? getImageKitTransformation(params.transformations)
        : null;

    const videoId = params.folderName ? `${params.folderName}/${params.id}` : params.id;

    const videoWithExtension = checkVideoExtension(videoId);

    const finalAssetId = params.shouldFetchThumbnail
        ? addThumbnailSuffix(videoWithExtension)
        : videoWithExtension;

    return transformations
        ? `${baseUrl}/${transformations}/${finalAssetId}`
        : `${baseUrl}/${finalAssetId}`;
};

export const getImageKitImageBaseUrl = memoize(
    (opts: { cloudName: string; baseUrl?: string; type?: "upload" | "youtube" }): string => {
        const baseUrl = opts.baseUrl || IMAGEKIT_MEDIA_BASE_URL;
        return `${baseUrl}/${opts.cloudName}/image/${opts.type ?? "upload"}`;
    },
);

export const getImageKitVideoBaseUrl = memoize(
    (opts: { cloudName: string; baseUrl?: string; type?: "upload" | "youtube" }): string => {
        const baseUrl = opts.baseUrl || IMAGEKIT_MEDIA_BASE_URL;
        return `${baseUrl}/${opts.cloudName}/video/${opts.type ?? "upload"}`;
    },
);

export const getImagePixelRatio = (optimizedDpr?: boolean): number => {
    const pixelRatio = PixelRatio.get();
    if (Platform.OS === "ios" || !optimizedDpr) {
        return pixelRatio;
    }
    return Math.min(pixelRatio, 2);
};

// https://docs.imagekit.io/features/image-transformations
export interface ImageKitTransformation {
    width: number;
    height: number;

    /** TODO: To be replaced with ImageKit supported crop */
    // https://docs.imagekit.io/features/url-rewriters/cloudinary
    crop: "scale" | "thumb" | "fit" | "limit";

    // Range: 1 to 100.
    // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#quality-q
    quality: number;

    /** TODO: To be removed */
    // https://cloudinary.com/documentation/image_optimization#automatic_quality_selection_q_auto
    qualityAuto: boolean | "good" | "best" | "eco" | "low";

    // An RGB or RGBA hex triplet or quadruplet (6 or 8 digits).
    // https://docs.imagekit.io/features/url-rewriters/cloudinary
    color: string;
    // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#format-f
    format: "auto" | "png" | "jpg" | "webp" | "jpeg" | "svg";

    // TODO: not being used for now, but should be replaced with IK video thumbnail
    // https://docs.imagekit.io/features/video-transformation/resize-crop-and-other-common-video-transformations#get-thumbnail-from-a-video
    preview: boolean | { duration: number };

    // border-roundness to the image in pixel
    // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#radius-r
    round: number;
    // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#dpr-dpr
    dpr: number;

    /** TODO: not being used for now, but should be replaced with ImageKit supported bitrate streaming */
    // https://docs.imagekit.io/features/video-transformation/adaptive-bitrate-streaming
    bitrate: string;
}

export const getImageKitTransformation = (
    transformations: Partial<ImageKitTransformation> | Partial<ImageKitTransformation>[],
): string => {
    if (!Array.isArray(transformations)) {
        return getImageKitTransformation([transformations]);
    }

    return transformations
        .map((t) => {
            const arr: string[] = [];

            if (t.color) {
                arr.push("co_" + getImageKitColorValue(t.color));
                arr.push("e_colorize:100");
            }

            if (t.width) {
                arr.push("w_" + t.width);
            }

            if (t.height) {
                arr.push("h_" + t.height);
            }

            if (t.crop) {
                arr.push("c_" + t.crop);
            }

            if (t.round) {
                arr.push("r_" + t.round);
            }

            if (t.quality) {
                arr.push("q_" + t.quality);
            } else if (t.qualityAuto) {
                arr.push("fl_lossy");
                arr.push(
                    typeof t.qualityAuto === "boolean" ? "q_auto:eco" : `q_auto:${t.qualityAuto}`,
                );
            }

            if (t.format) {
                arr.push("f_" + t.format);
            } else {
                arr.push("f_auto");
            }

            if (t.dpr) {
                arr.push("dpr_" + t.dpr);
            }

            if (t.bitrate) {
                arr.push("br_" + t.bitrate);
            }

            if (t.preview) {
                if (typeof t.preview === "boolean") {
                    arr.push("e_preview");
                } else {
                    arr.push("e_preview:duration_" + t.preview.duration);
                }
            }

            return arr.join(",");
        })
        .filter(Boolean)
        .join("/");
};

const getImageKitColorValue = (color: string): string => {
    if (color.startsWith("#")) {
        return `rgb:${color.substring(1)}`;
    }

    if (color.startsWith("rgb")) {
        return `rgb:${rgbHex(color)}`;
    }

    return color;
};

export const getImageKitTransformationsFromChildren = <
    T extends JSXElementConstructor<Partial<ImageKitTransformation>>,
    C extends ReactElement<Partial<ImageKitTransformation>, T> = ReactElement<
        Partial<ImageKitTransformation>,
        T
    >,
>(
    type: T,
    children?: C | C[],
): Partial<ImageKitTransformation>[] =>
    React.Children.toArray(children)
        .filter((e) => React.isValidElement(e) && e.type === type)
        .map((e) => (e as C).props);

export const isImageKitUrl = memoize((url: string): boolean =>
    url.includes(IMAGEKIT_MEDIA_BASE_URL),
);

/**
 * sample url https://minis-media-assets.swiggy.com/swiggymini/image/upload
 */
export const parseImageKitUrl = memoize(
    (
        url: string,
    ): {
        cloudName?: string;
        folder?: string;
    } => {
        const regex =
            /https:\/\/minis-media-assets\.swiggy\.com\/([a-zA-Z-\d]+)\/image\/upload(\/[a-zA-Z-\d]+)?/;

        const [, cloudName, folder] = url.match(regex) || [];

        return { cloudName, folder: folder?.replace("/", "") };
    },
);

export const getIKImageFormatForPlatform = (): ImageKitTransformation["format"] => {
    if (Platform.OS === "ios") {
        const majorVersionIOS = parseInt(Platform.Version as string, 10);
        if (majorVersionIOS <= 14) {
            return "png";
        }
    }

    return Platform.OS === "web" ? "auto" : "webp";
};

export const checkVideoExtension = (videoId: string): string => {
    if (!videoId) {
        return videoId;
    }

    const hasExtension = VIDEO_FORMAT_REGEX.test(videoId);

    return hasExtension ? videoId : `${videoId}${IK_VIDEO_FORMAT}`;
};

export const addThumbnailSuffix = (videoId: string): string => {
    if (!videoId) {
        return videoId;
    }

    return videoId + THUMBNAIL_SUFFIX;
};
