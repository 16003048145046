import { useCallback, useEffect, useRef, useState } from "react";
import ChatSdk, { ChatSdkConfig, ChatSdkError } from "@swiggy-private/connect-chat-sdk";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { HttpError } from "@swiggy-private/http-client";

import { logError } from "../helpers/log";

export const useInitializeChatSdk = (
    config: ChatSdkConfig,
    creds: Record<string, string> | null,
): ChatSdk | null => {
    const [sdk, setSdk] = useState<ChatSdk | null>(null);
    const mountedRef = useMountedRef();
    const credsRef = useRef(creds);

    const initializeSdk = useCallback(
        (_sdk: ChatSdk, _creds: Record<string, string>, retryCount = 0) => {
            _sdk.initialize(_creds)
                .then(() => mountedRef.current && setSdk(_sdk))
                .catch((err) => {
                    if (mountedRef.current) {
                        if (err instanceof HttpError && err.isAbortError()) {
                            initializeSdk(_sdk, _creds, retryCount);
                            return;
                        }

                        if (err instanceof ChatSdkError && err.message === "Aborted") {
                            initializeSdk(_sdk, _creds, retryCount);
                            return;
                        }

                        if (retryCount < 3) {
                            initializeSdk(_sdk, _creds, retryCount + 1);
                            return;
                        }

                        logError(err);
                        config.onError?.(err);
                    }
                });
        },
        [config, mountedRef],
    );

    useEffect(() => {
        if (credsRef.current !== creds) {
            credsRef.current = creds;
            if (sdk && creds) {
                initializeSdk(sdk, creds);
                return;
            }
        }

        if (creds && !sdk) {
            initializeSdk(new ChatSdk(config), creds);
        } else if (sdk && !creds) {
            sdk?.stop();
            setSdk(null);
        }
    }, [config, creds, initializeSdk, sdk]);

    return sdk;
};
