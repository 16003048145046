import { ImageStyle, Platform, TextStyle, ViewStyle } from "react-native";

import type { Elevation } from "../interfaces/elevation";

const SHADOW_COLOR = "#1B1E24";
const SHADOW_OPACITY = 0.04;

export const shadow = (
    elevation: Elevation,
    shadowOpacity?: number,
    shadowColor = SHADOW_COLOR,
): ViewStyle | TextStyle | ImageStyle => {
    if (elevation === 0) {
        return Platform.select({
            android: {
                elevation: 0,
            },

            default: {
                shadowColor: SHADOW_COLOR,
                shadowOpacity: 0,
                shadowOffset: {
                    width: 0,
                    height: 0,
                },
                shadowRadius: 0,
            },

            web: {
                boxShadow: "none",
            },
        });
    }

    let height, radius;
    switch (elevation) {
        case 1:
            height = 1;
            radius = 1;
            break;
        case 2:
            height = 1;
            radius = 1.5;
            break;
        default:
            height = elevation - 1;
            radius = elevation;
    }

    return Platform.select({
        android: {
            elevation,
            shadowColor,
        },

        default: {
            shadowColor,
            shadowOpacity:
                shadowOpacity == null ? SHADOW_OPACITY + elevation * 0.02 : shadowOpacity,
            shadowRadius: radius,
            shadowOffset: {
                width: 0,
                height,
            },
        },
    });
};
