import { Platform, Vibration } from "react-native";

import { HapticAPI, HapticOptions, HapticFeedbackTypes } from "../interfaces/haptic";
import HapticFeedbackPackage from "../optional-dependencies/haptic-feedback-package";

const defaultHapticOptions: HapticOptions = {
    enableVibrateFallback: true,
    ignoreAndroidSystemSettings: false,
};

const VibrateMap: Partial<Record<HapticFeedbackTypes, number[]>> = {
    selection: [22],
    impactLight: [20],
    impactMedium: [40],
    impactHeavy: [60],
    rigid: [30],
    soft: [10],
    notificationSuccess: [40, 60, 20],
    notificationWarning: [20, 60, 40],
    notificationError: [20, 40, 30, 40, 40],
};

function trigger(
    hapticType: HapticFeedbackTypes,
    opts: HapticOptions = defaultHapticOptions,
): void {
    if (HapticFeedbackPackage) {
        HapticFeedbackPackage.trigger(hapticType, opts);
    } else {
        if (opts.enableVibrateFallback) {
            const pattern = VibrateMap[hapticType] ?? [22];
            Vibration.vibrate(Platform.OS === "android" ? [0, ...pattern] : pattern);
        }
    }
}

export const HapticService: HapticAPI = {
    trigger,
};
