import { useMountedRef } from "@swiggy-private/react-hooks";
import { useEffect, useMemo, useState } from "react";
import { AppState } from "react-native";

import { logError } from "../helpers/log";
import { useChatService } from "./use-chat-service";
import { useChatState } from "./use-chatstate";

let unreadCountCache: {
    count: number;
    timestamp: number;
} = {
    count: 0,
    timestamp: 0,
};

const REFRESH_INTERVAL = 2_000;

export const useChatUnreadCount = (refresh = true): number => {
    const { conversationsMeta, conversations } = useChatState();
    const service = useChatService();
    const mounted = useMountedRef();

    const [dbUnreadCount, setDbUnreadCount] = useState(0);
    const stateUnreadCount = useMemo(
        () =>
            Object.values(conversationsMeta).reduce((acc, conversation) => {
                const count = conversation?.unreadCount ?? 0;
                return acc + count;
            }, 0),
        [conversationsMeta],
    );

    useEffect(() => {
        let canFetch = true;

        const fn = async (): Promise<void> => {
            if (!canFetch || AppState.currentState !== "active") {
                return;
            }

            const curTime = Date.now();

            if (
                unreadCountCache.timestamp &&
                unreadCountCache.timestamp + REFRESH_INTERVAL > curTime
            ) {
                setDbUnreadCount(unreadCountCache.count);
                return;
            }

            canFetch = false;

            await service
                ?.getUnreadMessageCount()
                .then((count) => {
                    canFetch = true;
                    unreadCountCache = {
                        count,
                        timestamp: curTime,
                    };
                    mounted.current && setDbUnreadCount(count);
                })
                .catch((err) => {
                    canFetch = true;
                    logError(err);
                });
        };

        fn();

        if (refresh) {
            const timer = setInterval(fn, REFRESH_INTERVAL);
            return () => clearInterval(timer);
        }

        return () => {
            // do nothing...
        };
    }, [mounted, refresh, service]);

    return Object.keys(conversations).length > 0 ? stateUnreadCount : dbUnreadCount;
};
