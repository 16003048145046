import memoizeOne from "memoize-one";

import type { Theme } from "@swiggy-private/rn-dls-theme";
import type { AppBarStyle, AppBarStyleMeta } from "../interfaces/appbar";

const style = memoizeOne(
    (theme: Theme): AppBarStyle => ({
        backgroundColor: theme["color-background-primary"],

        textColor: theme["color-basic-75"],
        activeTextColor: theme["color-primary"],

        iconColor: theme["color-basic-75"],
        activeIconColor: theme["color-primary"],

        textFontFamily: theme["text-body-1-regular-font-family"],
        textFontSize: theme["text-body-1-regular-font-size"],

        activeTextFontFamily: theme["text-body-1-bold-font-family"],
        activeTextFontSize: theme["text-body-1-bold-font-size"],

        trayColor: theme["color-primary"],
    }),
);

export const getAppBarStyles = (_: AppBarStyleMeta, theme: Theme): AppBarStyle => {
    return style(theme);
};
