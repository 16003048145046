import React, {
    forwardRef,
    ForwardRefRenderFunction,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
} from "react";
import { View, ViewProps } from "react-native";

import { FormContext, FormContextValue } from "./form-context";
import { useFormError } from "../../hooks/use-form-error";

export interface FormProps extends ViewProps {
    onSubmit: (formData: Record<string, unknown>, meta?: Record<string, unknown>) => void;
    children: React.ReactNode;
    trackUnsavedChanges?: boolean;
    errors?: Record<string, string | undefined>;
}

export interface FormHandler {
    submit: () => void;
    hasUnsavedChanges: () => boolean;
    clear: () => void;
    setTrackUnsavedChanges: (b: boolean) => void;
}

const FormRenderFunction: ForwardRefRenderFunction<FormHandler, FormProps> = (
    { onSubmit, trackUnsavedChanges = true, errors, ...props },
    ref,
) => {
    const trackUnsavedChangesRef = useRef(trackUnsavedChanges);
    const hasUnsavedChangesRef = useRef(false);
    const formDataRef = useRef<Record<string, unknown>>({});
    const [formErrors, setErrors, removeError] = useFormError();

    const contextValue: FormContextValue = useMemo(
        () => ({
            setField: (name, value) => {
                if (value !== undefined) {
                    formDataRef.current[name] = value;
                }
            },
            updateField: (name, value) => {
                hasUnsavedChangesRef.current = true;
                formDataRef.current[name] = value;
            },
            submit: (meta?: Record<string, unknown>) => {
                onSubmit(formDataRef.current, meta);
            },
            getErrorForField: (fieldName) => formErrors[fieldName],
            removeErrorForField: (fieldName) => removeError(fieldName),
        }),
        [formErrors, onSubmit, removeError],
    );

    useImperativeHandle(
        ref,
        () => ({
            clear: () => {
                hasUnsavedChangesRef.current = false;
                formDataRef.current = {};
            },
            submit: (meta?: Record<string, unknown>) => onSubmit(formDataRef.current, meta),
            hasUnsavedChanges: (): boolean => {
                if (
                    !trackUnsavedChangesRef.current ||
                    !hasUnsavedChangesRef.current ||
                    !formDataRef.current ||
                    !Object.keys(formDataRef.current).length
                ) {
                    return false;
                }

                return Object.values(formDataRef.current).some((v) => !!v);
            },
            setTrackUnsavedChanges: (v) => {
                trackUnsavedChangesRef.current = v;
            },
        }),
        [onSubmit],
    );

    useEffect(() => {
        errors && setErrors(errors);
    }, [errors, setErrors]);

    return (
        <FormContext.Provider value={contextValue}>
            <View {...props} />
        </FormContext.Provider>
    );
};

export const Form = forwardRef(FormRenderFunction);
