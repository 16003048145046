import {
    ApiClient,
    ApiError,
    ApiResponse,
    API_SUCCESS_STATUS_CODE,
} from "@swiggy-private/connect-api-core";

const GENERIC_ERROR_MESSAGE = "Oops! something went wrong";

export abstract class APIService {
    protected client: ApiClient;
    protected endpoint: string;

    constructor(endpoint: string, client: ApiClient) {
        this.endpoint = endpoint;
        this.client = client;
    }

    async handleResponse<T>(
        response: ApiResponse<T>,
        errMessage = GENERIC_ERROR_MESSAGE,
    ): Promise<T> {
        if (response.statusCode === API_SUCCESS_STATUS_CODE) {
            return response.data as unknown as T;
        }

        return Promise.reject(new ApiError(response.statusMessage || errMessage, response));
    }
}
