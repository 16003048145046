/**
 * @param asset - Will be something like
 * asset-type/uuid/uuid/asset-name
 * PDF/uuid/uuid/dummy.pdf
 * IMAGE/uuid/uuid/dummy.pdf
 *
 * @returns - dummy.pdf
 */
export const getMediaAssetName = (asset: string): string => {
    if (!asset) {
        return "";
    }

    const assetDetails = asset.split("/");
    const lastIndex = assetDetails.length - 1;
    const lastValue = assetDetails?.[lastIndex] || "";

    const hyphenIndex = lastValue.indexOf("-");

    if (hyphenIndex !== -1) {
        return lastValue.substring(hyphenIndex + 1);
    } else {
        return "";
    }
};
