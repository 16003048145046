import * as React from "react";
import {
    Animated,
    Platform,
    Pressable,
    StatusBar,
    StyleProp,
    StyleSheet,
    useWindowDimensions,
    View,
    ViewStyle,
} from "react-native";
import { Box, BoxProps, ScreenSizeProp, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";

import { Portal } from "../portal";
import { useDimissListeners } from "../../hooks/use-dismiss-listeners";
import { Overlay } from "../../support/overlay";
import { useTheme } from "../../styles/theme-service";
import { DialogTitle } from "././dialog-title";
import { DialogContent } from "././dialog-content";
import { useLayout } from "../../hooks/use-layout";
import { useAnimatedValue } from "../../hooks/use-animated-value";

const EmptyFunction = (): void => undefined;

export interface DialogProps {
    /** If `true`, the component is shown. */
    open?: boolean;
    /** If true, hitting escape will not fire the onClose callback. */
    disableEscapeKeyClose?: boolean;
    /** If true, pressing backdrop will not fire the onClose callback. */
    disableBackdropClose?: boolean;
    /** If true, pressing hardware back will not fire the onClose callback. */
    disableHardwareBackClose?: boolean;
    /** If true, the dialog is full-screen. */
    fullScreen?: boolean;
    /** if true, the dialog stretches to maxWidth. */
    fullWidth?: boolean;
    /** Callback fired when the component requests to be closed. */
    onClose?: (reason: "escapekey" | "backdropclick" | "hardwareback") => void;
    maxWidth?: ScreenSizeProp<string | number>;
    BackdropComponent?: React.FC<{ onPress: () => void }>;
    style?: StyleProp<ViewStyle>;
    /** Defines where the dialog is vertically aligned. Default: "center" */
    verticalAlignDialog?: "top" | "center" | "bottom";
    showBackdrop?: boolean;
    animate?: boolean;
    onShow?: () => void;
    usePortal?: boolean;
    overlayStyle?: StyleProp<ViewStyle>;
}

const useNativeDriver = Platform.OS !== "web";

export const Dialog: React.FC<React.PropsWithChildren<DialogProps>> & {
    Title: typeof DialogTitle;
    Content: typeof DialogContent;
} = ({
    children,
    open,
    onClose,
    fullScreen,
    fullWidth,
    maxWidth,
    disableEscapeKeyClose = false,
    disableHardwareBackClose = false,
    disableBackdropClose,
    showBackdrop = true,
    BackdropComponent,
    verticalAlignDialog = "center",
    animate = true,
    onShow,
    style,
    usePortal = true,
    overlayStyle,
}) => {
    const screenSize = useScreenSize();
    const { value: theme } = useTheme();
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    const [layout, onLayout] = useLayout();
    const animatedValue = useAnimatedValue(0);

    const onDismiss: DialogProps["onClose"] = React.useCallback(
        (reason: "escapekey" | "backdropclick" | "hardwareback") => {
            if (!animate) {
                onClose?.(reason);
                return;
            }

            Animated.timing(animatedValue, {
                toValue: 0,
                duration: 100,
                useNativeDriver,
            }).start((result) => {
                if (result.finished) {
                    onClose?.(reason);
                }
            });
        },
        [animate, animatedValue, onClose],
    );

    const [attachListeners, removeListeners] = useDimissListeners(
        React.useCallback(
            (reason) => {
                if (reason === "hardwareback") {
                    onDismiss("hardwareback");
                } else if (reason === "keyboardesc") {
                    onDismiss("escapekey");
                }
            },
            [onDismiss],
        ),
        {
            onHardwareBack: !disableHardwareBackClose,
            onKeyboardEsc: !disableEscapeKeyClose,
            onDimensionChange: false,
        },
    );

    const onBackdropPress = React.useCallback((): void => {
        if (!disableBackdropClose) {
            onDismiss("backdropclick");
        }
    }, [disableBackdropClose, onDismiss]);

    React.useEffect(() => {
        if (open) {
            attachListeners();
        }

        return removeListeners;
    }, [open, attachListeners, removeListeners]);

    React.useEffect(() => {
        if (!animate || !open) {
            return;
        }

        Animated.timing(animatedValue, {
            toValue: 1,
            duration: 200,
            useNativeDriver,
        }).start((result) => {
            if (result.finished) {
                onShow?.();
            }
        });
    }, [animate, animatedValue, onShow, open]);

    const justifyContent = React.useMemo<BoxProps["justifyContent"]>(() => {
        if (verticalAlignDialog === "top") {
            return "flex-start";
        }

        if (verticalAlignDialog === "bottom") {
            return "flex-end";
        }

        return "center";
    }, [verticalAlignDialog]);

    const contentStyle = React.useMemo(() => {
        if (!animate) {
            return null;
        }

        const styles: StyleProp<ViewStyle | Animated.WithAnimatedObject<ViewStyle>> = [
            {
                opacity: open && layout.measured ? 1 : 0,
            },
        ];

        if (verticalAlignDialog === "bottom") {
            styles.push({
                transform: [
                    {
                        translateY: animatedValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [layout.height, 0],
                        }),
                    },
                ],
            });
        }

        if (verticalAlignDialog === "center") {
            styles.push({
                transform: [
                    {
                        scale: animatedValue.interpolate({
                            inputRange: [0, 1],
                            outputRange: [0, 1],
                        }),
                    },
                ],
            });
        }

        return styles as StyleProp<ViewStyle>;
    }, [animate, animatedValue, layout.height, layout.measured, open, verticalAlignDialog]);

    const gutter = SpacingValue["space-x-large"];
    const _maxWidth =
        fullWidth || fullScreen
            ? "100%"
            : typeof maxWidth === "string" || typeof maxWidth === "number"
            ? maxWidth
            : maxWidth?.[screenSize] ?? maxWidth?.default ?? windowWidth - gutter * 2;

    const _maxHeight = fullScreen
        ? "100%"
        : windowHeight - gutter * 2 - (StatusBar.currentHeight ?? 0);

    const containerStyle: ViewStyle = {
        backgroundColor: theme["color-background-primary"],
        flex: fullScreen ? 1 : undefined,
        maxWidth: fullScreen ? undefined : _maxWidth,
        maxHeight: fullScreen ? undefined : _maxHeight,
    };

    const canShowBackdrop = open && !fullScreen && showBackdrop === true;

    const Wrapper = usePortal ? Portal : InlineWrapper;

    return (
        <Wrapper>
            {!canShowBackdrop ? null : BackdropComponent ? (
                <BackdropComponent onPress={onBackdropPress} />
            ) : (
                <Overlay onPress={onBackdropPress} backgroundColor="primary" style={overlayStyle} />
            )}
            <Box
                flex={1}
                justifyContent={justifyContent}
                alignItems="center"
                pointerEvents="box-none"
                style={StyleSheet.absoluteFillObject}>
                <Animated.View
                    style={[containerStyle, contentStyle, style]}
                    pointerEvents={open ? "box-none" : "none"}
                    onLayout={onLayout}>
                    {/* Pressable is added to avoid the overlay pressable events getting captured by the children*/}
                    <Pressable onPress={EmptyFunction}>{children}</Pressable>
                </Animated.View>
            </Box>
        </Wrapper>
    );
};

const InlineWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <View
            collapsable={
                false /* Need collapsable=false here to clip the elevations, otherwise they appear above sibling components */
            }
            pointerEvents="box-none"
            style={StyleSheet.absoluteFillObject}>
            {children}
        </View>
    );
};

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
