import { useCallback, useEffect, useState } from "react";
import { Dimensions, NativeMethods, Platform, StatusBar } from "react-native";
import { useMountedRef } from "@swiggy-private/react-hooks";

export interface Position {
    width: number;
    height: number;
    pageX: number;
    pageY: number;
}

const isIOS = Platform.OS === "ios";

export const useMeasure = (
    element?: NativeMethods | null,
    skipAndroidStatusBar = false,
): Readonly<Position | null> => {
    const [position, setPosition] = useState<Position | null>(null);
    const mountedRef = useMountedRef();

    const getElementPosition = useCallback(
        (signal: AbortSignal) => {
            element &&
                // eslint-disable-next-line max-params
                element.measure((_x, _y, width, height, pageX, pageY) => {
                    if (mountedRef.current && !signal.aborted) {
                        setPosition({
                            width,
                            height,
                            pageX,
                            pageY:
                                skipAndroidStatusBar || isIOS
                                    ? pageY
                                    : pageY -
                                      Platform.select({
                                          android: StatusBar.currentHeight,
                                          ios: 20,
                                          default: 0,
                                      }),
                        });
                    }
                });
        },
        [element, mountedRef, skipAndroidStatusBar],
    );

    useEffect(() => {
        // Wait till element's position is calculated
        const controller = new AbortController();
        const timer = requestAnimationFrame(() => getElementPosition(controller.signal));

        const fn = (): void => {
            getElementPosition(controller.signal);
        };

        const removeListener = Dimensions.addEventListener("change", fn);

        return () => {
            cancelAnimationFrame(timer);
            controller.abort();

            if (removeListener) {
                removeListener.remove();
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-expect-error
                Dimensions.removeEventListener("change", fn);
            }
        };
    }, [getElementPosition]);

    return position;
};
