import * as React from "react";

export function useForwardedRef<T>(forwardedRef: React.ForwardedRef<T>): React.RefObject<T> {
    // final ref that will share value with forward ref.
    // this is the one we will attach to components
    const innerRef = React.useRef<T>(null);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.useImperativeHandle(forwardedRef, () => innerRef.current);

    return innerRef;
}
