import { ClientEvent, DPEvent } from "./interfaces/event";
import { DpClient, DpClientConfig } from "./network/dp-client";
import { EventQueue } from "./queue/event-queue";
import { Scheduler } from "./scheduler/single-task-scheduler";

let _scheduler: Scheduler | null = null;
let _dpClient: DpClient | null = null;

const sendDpQueuedEvents = async (batchSize: number): Promise<void> => {
    await sendDpEvents(EventQueue.pop<DPEvent>(batchSize));
};

const __DEV__ = process.env.NODE_ENV !== "production";

const sendDpEvents = async (events: DPEvent[]): Promise<void> => {
    if (!events.length) {
        return;
    }

    try {
        await _dpClient?.sendEvent(events);
    } catch (err) {
        __DEV__ &&
            console.debug(
                "failed to send dp event. Events will be added back into queue for retry",
                err,
            );
        EventQueue.retry(...events);
    }
};

interface AnalyticsConfig extends DpClientConfig {
    /** Maximum time after which to batch and send events to DP. In milliseconds. */
    flushInterval?: number;
    /** Maximum size of batch of events to send to DP */
    batchSize?: number;
}

export const Analytics = {
    trackEvent: (event: ClientEvent, immediate = false): void => {
        if (immediate && _dpClient) {
            sendDpEvents([event]);
        } else {
            EventQueue.push(event);
        }

        _scheduler?.startTimer();
    },

    init: ({ flushInterval = 3000, batchSize = 10, ...dpConfig }: AnalyticsConfig) => {
        _scheduler?.destroy();
        _dpClient = new DpClient(dpConfig);
        _scheduler = Scheduler.schedule(() => sendDpQueuedEvents(batchSize), flushInterval);
    },

    destroy: () => {
        _scheduler?.destroy();
        _dpClient = null;
        _scheduler = null;
    },
};
