import React from "react";

import { IKImageBackgroundProps, ImageKitImageBackground } from "../imagekit-image-background";

export type CdnImageBackgroundProps = IKImageBackgroundProps & {
    // TODO: remove towards the end of IK migration
    isImageKitEnabled?: boolean;
};

export const CdnImageBackground: React.FC<CdnImageBackgroundProps> = (props) => {
    return <ImageKitImageBackground {...props} />;
};
