import React, { memo, useCallback, useMemo, useRef } from "react";
import {
    KeyboardAvoidingView,
    Platform,
    SectionListProps,
    StyleProp,
    StyleSheet,
    View,
    ViewProps,
    ViewStyle,
} from "react-native";

import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { useMeasure } from "@swiggy-private/react-native-ui";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ActivityIndicator } from "@swiggy-private/rn-dls";

import { ChatConversationContent } from "./components/content";
import { ChatConversationFooter, ChatConversationFooterProps } from "./components/footer";
import { ChatConversationHeader } from "./components/header";

import { MessageSection } from "../../interfaces/types";
import type { Navigation } from "../../interfaces";

import { useSendTypingSignal } from "../../hooks/use-send-typing-signal";
import { useChatRenderer } from "../../hooks/use-chatrender";
import { useConversationIndicators } from "../../hooks/use-conversation-indicators";
import { useConversationMarkUnread } from "../../hooks/use-conversation-unread";
import { useGetChatUserDetailsFromConversation } from "../../hooks/use-get-user-details";
import { ChatConversationAdvanceFooter } from "./components/advance-footer";

export interface ChatConversationViewProps extends ViewProps {
    conversation: SdkConversation;
    messageSections: MessageSection[];
    onContentScrollEndReached: SectionListProps<unknown>["onEndReached"];
    sendMessage: ChatConversationFooterProps["onSend"];
    navigation?: Navigation;
    contentStyle?: StyleProp<ViewStyle>;
    loading?: boolean;
    showPopup?: boolean;
    messageTemplate?: string;
    ChatInfoComponent?: React.ComponentType<{
        showPopup?: boolean;
        closeInfoPopup?: VoidFunction;
    }>;
    closeInfoPopup?: VoidFunction;
    shouldShowAdvanceFooterByDefault?: boolean;
}

const isIOS = Platform.OS === "ios";

export const ChatConversationView: React.FC<ChatConversationViewProps> = memo((props) => {
    const {
        ChatInfoComponent,
        closeInfoPopup,
        showPopup,
        onContentScrollEndReached,
        conversation,
        messageSections,
        sendMessage,
        style,
        navigation,
        contentStyle,
        loading = false,
        messageTemplate = "",
        shouldShowAdvanceFooterByDefault,
        ...restProps
    } = props;

    const inset = useSafeAreaInsets();
    const viewRef = useRef<View>(null);
    const keyboardVerticalOffset = (useMeasure(viewRef.current)?.pageY ?? 0) - inset.bottom;

    const sendTypingSignal = useSendTypingSignal(conversation.id);
    const onInputChangeText = useCallback(() => sendTypingSignal(true), [sendTypingSignal]);
    const onInputBlur = useCallback(() => sendTypingSignal(false), [sendTypingSignal]);

    useConversationMarkUnread(conversation.id);

    const onSendMessage: typeof sendMessage = useCallback(
        (...args) => {
            const sentMessageResponse = sendMessage(...args);
            sendTypingSignal(false);
            return sentMessageResponse;
        },
        [sendMessage, sendTypingSignal],
    );

    const indicators = useConversationIndicators(conversation.id);
    const conversationUser = useGetChatUserDetailsFromConversation(conversation);

    const Header = useChatRenderer()?.renderConversationHeader ?? ChatConversationHeader;
    const Content = useChatRenderer()?.renderConversationContent ?? ChatConversationContent;
    const Footer = useChatRenderer()?.renderConversationFooter ?? ChatConversationFooter;
    const AdvanceFooter =
        useChatRenderer()?.renderConversationAdvanceFooter ?? ChatConversationAdvanceFooter;
    const EmptyComponent = useChatRenderer()?.renderConversationEmptyContent;
    const ConversationNudge = useChatRenderer()?.renderConversationNudge;

    const hasMessages = messageSections.length > 0;

    const EmptyContent = useMemo(() => {
        if (!hasMessages && loading) {
            return (
                <View style={styles.loader}>
                    <ActivityIndicator />
                </View>
            );
        }

        if (!hasMessages && EmptyComponent) {
            return (
                <EmptyComponent
                    participantDeleted={conversation.deleted}
                    closeInfoPopup={closeInfoPopup}
                    showPopup={showPopup}
                    ChatInfoComponent={ChatInfoComponent}
                />
            );
        }

        return null;
    }, [
        ChatInfoComponent,
        EmptyComponent,
        closeInfoPopup,
        conversation.deleted,
        hasMessages,
        loading,
        showPopup,
    ]);

    return (
        <View {...restProps} style={[styles.container, style]} ref={viewRef} collapsable={false}>
            <KeyboardAvoidingView
                style={styles.container}
                behavior={isIOS ? "padding" : undefined}
                keyboardVerticalOffset={keyboardVerticalOffset}>
                <Header
                    title={conversation.title}
                    active={!!indicators.online || conversationUser?.online === true}
                    typing={!!indicators.typing}
                    lastSeenAt={conversationUser?.lastSeenAt}
                    navigation={navigation}
                    conversation={conversation}
                    userId={conversationUser?.userId}
                />
                {EmptyContent ? (
                    <>
                        {ConversationNudge ? (
                            <ConversationNudge conversation={conversation} />
                        ) : null}
                        {EmptyContent}
                    </>
                ) : (
                    <>
                        {ConversationNudge ? (
                            <ConversationNudge conversation={conversation} />
                        ) : null}
                        <Content
                            title={conversation.title}
                            ChatInfoComponent={ChatInfoComponent}
                            closeInfoPopup={closeInfoPopup}
                            showPopup={showPopup}
                            messageSections={messageSections}
                            onEndReached={onContentScrollEndReached}
                            style={contentStyle}
                            blocked={conversation.blocked}
                            deleted={conversation.deleted}
                            conversationMeta={conversation.meta}
                            conversationId={conversation.id}
                            MessageListEmptyComponent={EmptyContent}
                        />
                    </>
                )}
                <Footer
                    onSend={onSendMessage}
                    onChangeText={onInputChangeText}
                    onBlur={onInputBlur}
                    conversation={conversation}
                    defaultValue={messageTemplate}
                />
            </KeyboardAvoidingView>
            <AdvanceFooter
                onSend={onSendMessage}
                onChangeText={onInputChangeText}
                onBlur={onInputBlur}
                conversation={conversation}
                defaultValue={messageTemplate}
                shouldShowAdvanceFooterByDefault={shouldShowAdvanceFooterByDefault}
            />
        </View>
    );
});

if (__DEV__) {
    ChatConversationView.displayName = "ChatConversationView";
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    loader: {
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
    },
});
