import { IMAGEKIT_CLOUDNAME, IMAGEKIT_MEDIA_BASE_URL } from "@env";
import React, { createContext, useMemo } from "react";

export interface ICloudinaryContext {
    cloudName: string;
    baseUrl: string;
    folderName?: string;
}

const DEFAULT_CONTEXT_VALUE: ICloudinaryContext = {
    cloudName: IMAGEKIT_MEDIA_BASE_URL,
    baseUrl: IMAGEKIT_CLOUDNAME,
};

export const CloudinaryContext = createContext<ICloudinaryContext>(DEFAULT_CONTEXT_VALUE);

export const CloudinaryContextProvider: React.FC<
    React.PropsWithChildren<Partial<ICloudinaryContext>>
> = ({
    children,
    baseUrl = DEFAULT_CONTEXT_VALUE.baseUrl,
    cloudName = DEFAULT_CONTEXT_VALUE.cloudName,
    folderName,
}) => {
    const value = useMemo(
        () => ({
            baseUrl,
            cloudName,
            folderName,
        }),
        [baseUrl, cloudName, folderName],
    );

    return <CloudinaryContext.Provider value={value}>{children}</CloudinaryContext.Provider>;
};
