import { ColorValue } from "react-native";

import { SdkCustomMessage, SdkCustomMessagePayload } from "@swiggy-private/connect-chat-sdk";

export type DigitalAssetType = "LINK" | "ASSET";
export type ProductType = "PHYSICAL" | "DIGITAL" | "APPOINTMENT" | "PLAN";
export type Duration = "MINS" | "DAYS" | "WEEKS" | "MONTHS" | "SESSIONS";

export enum CustomMessageTypes {
    PRODUCT_SHARE = "productShare",
    PRODUCT_ENQUIRE = "productEnquire",
    IMAGE_ENQUIRE = "imageEnquire",
    CUSTOM_PRODUCT_ENQUIRE = "customProductEnquire",
    ORDER_UPDATE = "orderUpdate",
    INITIATE_REVIEW = "initiateReview",
    SUBMITTED_REVIEW = "submittedReview",
    VIEW_STORY = "viewStory",
    COUPON = "coupon",
    RETURN_ORDER = "returnOrder",
    ENQUIRE_CONFIRM = "enquireConfirm",
    ADDED_TO_CART = "addedToCart",
}

export interface AddedToCartMessage extends SdkCustomMessage {
    type: CustomMessageTypes.ADDED_TO_CART;
    payload: ProductShareMessagePayload;
}

export interface AddedToCartMessagePayload extends SdkCustomMessagePayload {
    productId: string;
    productName: string;
    storeId: string;
}

export interface ProductShareMessage extends SdkCustomMessage {
    type: CustomMessageTypes.PRODUCT_SHARE;
    payload: ProductShareMessagePayload;
}

export interface ProductShareMessagePayload extends SdkCustomMessagePayload {
    text?: string;
    id: string;
    name: string;
    storeId: string;

    description?: string;
    imageUrl?: string | null;
    imageId?: string | null;
    price?: number;
    discountPrice?: number;
    custom?: boolean;
    discountPercentage?: number;
    variantLabel?: string;
    variantId?: string;
    productType?: ProductType;
    suggestedTime?: number;
    duration?: {
        value: number;
        unit: Duration;
    };
    digitalAssetType?: DigitalAssetType;
}

export interface ProductEnquireMessagePayload {
    text: string;
    productId: string;
    productName: string;
    storeId: string;
    productImageId?: string;
    productPrice?: number;
    productDiscountedPrice?: number;
    productDescription?: string;
    variantLabel?: string;
    variantId?: string;
    formData?: string;
    productDuration?: string;
    productSlot?: string;
    contactInfo?: string;
    assetType?: string;
    productType?: ProductType;
}

export interface ProductEnquireMessage extends SdkCustomMessage {
    type: CustomMessageTypes.PRODUCT_ENQUIRE;
    payload: ProductEnquireMessagePayload;
}

export interface ImageEnquireMessagePayload {
    text: string;
    imageUrl: string;
    storeId: string;
}

export interface ImageEnquireMessage extends SdkCustomMessage {
    type: CustomMessageTypes.IMAGE_ENQUIRE;
    payload: ImageEnquireMessagePayload;
}

export interface CustomProductsEnquireMessagePayload {
    text: string;
    customProductList: string[];
    storeId: string;
}

export interface CustomProductsEnquireMessage extends SdkCustomMessage {
    type: CustomMessageTypes.CUSTOM_PRODUCT_ENQUIRE;
    payload: CustomProductsEnquireMessagePayload;
}

export interface IEnquireConfirmMessage extends SdkCustomMessage {
    type: CustomMessageTypes.ENQUIRE_CONFIRM;
    payload: EnquireConfirmMessagePayload;
}

export interface EnquireConfirmMessagePayload {
    text?: string;
    storeSlug: string;
}

export interface OrderUpdateMessagePayload {
    text: string;
    orderId: string;
    title?: string;
    orderStatus?: string;
    orderType?: string;
}

export interface OrderUpdateMessage extends SdkCustomMessage {
    type: CustomMessageTypes.ORDER_UPDATE;
    payload: OrderUpdateMessagePayload;
}

export interface InitiateReviewMessagePayload {
    text: string;
    orderId: string;
    storeId: string;
    description: string;
    imageIds?: string[];
}

export interface InitiateReviewMessage extends SdkCustomMessage {
    type: CustomMessageTypes.INITIATE_REVIEW;
    payload: InitiateReviewMessagePayload;
}

export interface SubmittedReviewMessagePayload {
    text: string;
    orderId: string;
    storeId: string;
    value: number;
    description?: string;
    imageIds?: string[];
}

export interface SubmittedReviewMessage extends SdkCustomMessage {
    type: CustomMessageTypes.SUBMITTED_REVIEW;
    payload: SubmittedReviewMessagePayload;
}

export interface ViewStoryMessagePayload {
    text: string;
    imageUrl: string;
    storyData: string;
    storeId: string;
    highlightName?: string;
}

export interface IViewStoryMessage extends SdkCustomMessage {
    type: CustomMessageTypes.VIEW_STORY;
    payload: ViewStoryMessagePayload;
}

export type CouponType =
    | "DISCOUNT_TYPE_FLAT_OFF"
    | "DISCOUNT_TYPE_PERCENT_OFF"
    | "DISCOUNT_TYPE_FREE_DELIVERY";

export enum CouponDeliveryType {
    INTER = "INTER",
    INTRA = "INTRA",
}

export enum CouponSegmentType {
    PURCHASED = "PURCHASED",
    NEW_USER = "NEW_USER",
    ABANDONED_CART = "ABANDONED_CART",
    FAVOURITED = "FAVOURITED",
}

export interface Coupon {
    id: string;
    code: string;
    active: boolean;
    type: CouponType;

    minOrderValue?: number;
    discountPercent?: number;
    maxDiscountAmount?: number;
    hidden?: boolean;
    deliveryType?: CouponDeliveryType;
    segments?: CouponSegmentType[];
    segmentedOffer?: boolean;
}

export interface ICouponWithTextMessagePayload {
    storeId: string;
    brandColor: ColorValue;
    brandImageId: string;
    couponData: Coupon;

    city?: string;
    text?: string;
}

export interface ICouponWithTextMessage extends SdkCustomMessage {
    type: CustomMessageTypes.COUPON;
    payload: ICouponWithTextMessagePayload;
}
export interface ReturnOrderData {
    issue: string;

    comments?: string;
    number?: string;
}

export interface ReturnOrderMessagePayload {
    orderId: string;
    storeId: string;
    itemsTitle: string;

    text?: string;
    comments?: string;
    phoneNumber?: string;
}

export interface IReturnOrderMessage extends SdkCustomMessage {
    type: CustomMessageTypes.RETURN_ORDER;
    payload: ReturnOrderMessagePayload;
}

// export interface AddedToCartMessagePayload extends SdkCustomMessagePayload {
//     productId: string;
//     productName: string;
//     storeId: string;
// }

// export interface AddedToCartMessage extends SdkCustomMessage {
//     type: CustomMessageTypes.ADDED_TO_CART;
//     payload: AddedToCartMessagePayload;
// }
