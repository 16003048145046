import { BlockedInfo } from ".";

export type UserMode = "customer" | "merchant";

/**
 * This type, `ChatServerType`, represents the types of chat servers that our application can connect to. It can take one of the following values:
 *
 * - `PUBNUB`: This represents the PubNub server which is a global Data Stream Network (DSN) and realtime infrastructure-as-a-service (IaaS) company. Use this when you want to connect to a PubNub server.
 *
 * - `MINIS_SERVICE`: This represents our custom Minis-Server. This is a server that we have set up to handle chat messages within our application. Use this when you want to connect to our internal server.
 */
export type ChatServerType = "PUBNUB" | "MINIS_SERVICE";

export interface ServerHttpResponse<T> {
    statusCode: number;
    statusMessage?: string;
    data?: T | null;
}

export enum CHAT_PARTICIPANT_TYPE {
    STORE = "STORE",
    CUSTOMER = "CUSTOMER",
}

export interface ServerGrantTokenResponse {
    ttl: number;
    token: string;
}

export interface ServerUser {
    id: string;
    name: string;
    type?: CHAT_PARTICIPANT_TYPE;
    avatarUrl?: string;
    userId?: string;
}

export interface ServerLaunchResponseMetaData {
    saveLastReadTimeEndpoint?: ChatServerType;
    unreadChatCountEndpoint?: ChatServerType;
}

export interface ServerLaunchResponse {
    ttl: number;
    token: string;
    user: ServerUser;

    metaData?: ServerLaunchResponseMetaData;
}

export interface ServerConversation {
    id: string;
    participants: ServerUser[];
    conversationId: string;
    blocked?: boolean;
    deleted?: boolean;
    meta?: Record<string, unknown> & {
        storeId: string;
        storeSlug: string;
        storeBrandColor?: string;
        storeBadges?: {
            badgeType?: "STAR_SELLER";
        }[];
        isGuestUser?: boolean;
    };
    lastMessageAt?: number;
    blockedInfo?: BlockedInfo;
    chatBlockFeatureEnabled?: boolean;
}

export interface ServerGetConversationRequest {
    id: string;
}

export type ServerGetConversationResponse = ServerConversation;

export interface ServerStartConversationRequest {
    uuid: string;
}

export type ServerStartConversationResponse = ServerConversation;

export interface ServerFetchAllConversationsRequest {
    nextPage?: string;
    limit?: number;
}

export interface ServerFetchAllConversationsResponse {
    conversations: ServerConversation[];
    totalCount?: number;
    nextPage?: string;
}

export interface ServerBlockConversationRequest {
    id: string;
    blocked: boolean;
}

export type ServerBlockConversationResponse = Pick<
    ServerConversation,
    "conversationId" | "blockedInfo"
> & {
    vendorChannelId: string;
};

export interface ServerUnreadCountRequest {
    channelIds: string[];
}

export type ServerUnreadCountResponse = Record<string, number>;

export interface ServerSaveLastReadTimeRequest {
    conversationId: string;
}
