import { useCallback } from "react";
import type { NativeSyntheticEvent, TargetedEvent, PressableProps } from "react-native";

import { isCursorBrowser } from "../../support/browser";

export enum TooltipTriggerMode {
    /** Tooltip will be shown after a long press. */
    LONG_PRESS,
    /** Tooltip will be shown after a single tap. */
    TAP,
    /** Tooltip will be shown on hover. Available on browsers only. */
    HOVER,
    /** Tooltip will only be shown by passing `visible` as `true` to {@link Tooltip} component. */
    MANUAL,
}

type TriggerHandlers = Pick<PressableProps, "onPress" | "onLongPress" | "onPressOut"> & {
    onMouseEnter?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
    onMouseLeave?: (e: NativeSyntheticEvent<TargetedEvent>) => void;
};

export const useTooltipTriggerHandlers = (
    triggers: TriggerHandlers,
    dispatch: (triggerMode: TooltipTriggerMode[]) => void,
): TriggerHandlers => {
    const { onMouseEnter, onMouseLeave, onPress, onLongPress, onPressOut } = triggers;
    const _isCursorBrowser = isCursorBrowser();

    const onMouseEnterCallback: NonNullable<TriggerHandlers["onMouseEnter"]> = useCallback(
        (e) => {
            dispatch([TooltipTriggerMode.HOVER]);
            onMouseEnter?.(e);
        },
        [onMouseEnter, dispatch],
    );

    const onMouseLeaveCallback: NonNullable<TriggerHandlers["onMouseLeave"]> = useCallback(
        (e) => {
            dispatch([]);
            onMouseLeave?.(e);
        },
        [onMouseLeave, dispatch],
    );

    const onPressCallback: NonNullable<TriggerHandlers["onPress"]> = useCallback(
        (e) => {
            dispatch([TooltipTriggerMode.TAP]);
            onPress?.(e);
        },
        [dispatch, onPress],
    );

    const onLongPressCallback: NonNullable<TriggerHandlers["onLongPress"]> = useCallback(
        (e) => {
            dispatch([TooltipTriggerMode.LONG_PRESS]);
            onLongPress?.(e);
        },
        [dispatch, onLongPress],
    );

    const onPressOutCallback: NonNullable<TriggerHandlers["onPressOut"]> = useCallback(
        (e) => {
            dispatch([]);
            onPressOut?.(e);
        },
        [dispatch, onPressOut],
    );

    return {
        onMouseEnter: _isCursorBrowser ? onMouseEnterCallback : undefined,
        onMouseLeave: onMouseLeaveCallback,
        onPress: onPressCallback,
        onLongPress: onLongPressCallback,
        onPressOut: onPressOutCallback,
    };
};
