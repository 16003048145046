import { ScreenSize } from "../interfaces/screen";
import { useScreenSize } from "../hooks/use-screen-size";

type UseSelectScreenParams<T> = Partial<Record<ScreenSize, T>> & { default: T };

/**
 * ## Usage
 *
 * ```ts
 * import { ScreenSize, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
 *
 * useSelectScreen({
 *     md: { paddingTop: 8 },
 *     default: { paddingTop: 0}
 * });
 * ```
 */
export const useSelectScreen = <T>(params: UseSelectScreenParams<T>): T => {
    const screenSize = useScreenSize();
    return params[screenSize] ?? params.default;
};
