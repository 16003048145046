import { Elevations } from "../interfaces/elevation";
import { shadow } from "../utils/shadow";

export const ElevationValue: Elevations = {
    0: shadow(0),
    1: shadow(1),
    2: shadow(2),
    3: shadow(3),
    4: shadow(4),
    5: shadow(5),
    6: shadow(6),
    7: shadow(7),
    8: shadow(8),
    9: shadow(9),
    10: shadow(10),
    11: shadow(11),
    12: shadow(12),
    13: shadow(13),
    14: shadow(14),
    15: shadow(15),
    16: shadow(16),
    17: shadow(17),
    18: shadow(18),
    19: shadow(19),
    20: shadow(20),
    21: shadow(21),
    22: shadow(22),
    23: shadow(23),
    24: shadow(24),
};
