import React from "react";
import {
    ActivityIndicator as RNActivityIndicator,
    ActivityIndicatorProps as RNActivityIndicatorProps,
} from "react-native";

import { useTheme } from "../../styles/theme-service";
import type { ColorPalette } from "@swiggy-private/rn-dls-theme";

export type ActivityIndicatorProps = RNActivityIndicatorProps & { color?: keyof ColorPalette };

/**
 * Activity indicator is used to present progress of some activity in the app.
 *
 * ## Usage
 * ```js
 * import * as React from 'react';
 * import { ActivityIndicator } from '@swiggy-private/rn-dls';
 *
 * export const MyComponent = () => (
 *   <ActivityIndicator />
 * );
 * ```
 *
 * @param param0 {@link ActivityIndicatorProps}
 */
export const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({
    style,
    size = "small",
    color = "color-primary",
    ...props
}) => {
    const { value: theme } = useTheme();

    return (
        <RNActivityIndicator
            animating
            style={style}
            size={size}
            color={theme[color] ?? color}
            {...props}
        />
    );
};
