import React from "react";

import { DefaultThemes } from "../themes";
import type { Theme } from "../interfaces";

export interface IThemeContext {
    type: string;
    value: Theme;
}

const defaultValue: IThemeContext = {
    type: "light",
    value: DefaultThemes.light,
};

export const ThemeContext: React.Context<IThemeContext> = React.createContext(defaultValue);

if (process.env.NODE_ENV !== "production") {
    ThemeContext.displayName = "ThemeContext";
}
