import React, {
    forwardRef,
    ForwardRefRenderFunction,
    memo,
    useCallback,
    useMemo,
    useRef,
} from "react";
import { Platform, StyleProp, StyleSheet, View, ViewabilityConfig, ViewStyle } from "react-native";

import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { useForwardedRef } from "@swiggy-private/react-hooks";
import {
    IOSectionList,
    type IOSectionListData,
    type IOSectionListProps,
    type IOSectionListRefProps,
    type IOSectionListRenderItemInfo,
} from "@swiggy-private/react-native-ui";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import format from "date-fns/format";

import { SYSTEM_MESSAGE_PUBLISHER } from "../../../../constants";
import { useChatRenderer } from "../../../../hooks/use-chatrender";
import { useChatState } from "../../../../hooks/use-chatstate";
import { useChatUserId } from "../../../../hooks/use-chatuserid";
import { Message, MessageSection } from "../../../../interfaces/types";
import { ChatConversationMessageView } from "../../../message-view";
import {
    ChatConversationContentContextProvider,
    ChatConversationContentContextRefHandler,
} from "./context";
import { buildMessageSectionListData } from "./helper";

interface Section {
    id: string;
    timestamp: number;
}

const SectionHeaderComponent: React.FC<{ section: IOSectionListData<Message, Section> }> = ({
    section,
}) => {
    const date = format(section.timestamp, "do MMM yyyy");
    return (
        <View style={styles.sectionHeader}>
            <Text category="b2" weight="bold" color="color-basic-60">
                {date}
            </Text>
        </View>
    );
};

const ItemSeparatorComponent: React.FC = () => <View style={styles.itemSeparator} />;
const SectionSeparatorComponent: React.FC = () => <View style={styles.sectionSeparator} />;

export interface ChatConversationContentProps {
    messageSections: MessageSection[];
    conversationId: string;
    title: string;
    blocked?: SdkConversation["blocked"];
    deleted?: SdkConversation["deleted"];
    onEndReached?: IOSectionListProps<unknown>["onEndReached"];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MessageListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MessageListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MessageListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
    style?: StyleProp<ViewStyle>;
    conversationMeta?: SdkConversation["meta"];
    showPopup?: boolean;
    ChatInfoComponent?: React.ComponentType<{
        showPopup?: boolean;
        closeInfoPopup?: VoidFunction;
    }>;
    closeInfoPopup?: VoidFunction;
}

export const ChatConversationContent: ForwardRefRenderFunction<
    IOSectionListRefProps<Message, Section>,
    ChatConversationContentProps
> = memo(
    forwardRef((props, ref) => {
        const {
            title: conversationTitle,
            messageSections,
            onEndReached,
            MessageListHeaderComponent,
            MessageListFooterComponent,
            MessageListEmptyComponent,
            style,
            blocked,
            deleted,
            conversationId,
            conversationMeta,
            showPopup = false,
            ChatInfoComponent,
            closeInfoPopup,
        } = props;

        const sectionListRef = useForwardedRef(ref) as IOSectionListRefProps<Message, Section>;

        const contentContextRef = useRef<ChatConversationContentContextRefHandler>(null);

        const messages = useChatState().messages[conversationId];
        const userId = useChatUserId();
        const extraData = useMemo(() => ({ userId }), [userId]);
        const sections = useMemo(
            () => buildMessageSectionListData(messageSections, messages),
            [messageSections, messages],
        );

        const MessageView = useChatRenderer()?.renderMessage ?? ChatConversationMessageView;
        const ItemComponent: React.FC<IOSectionListRenderItemInfo<Message>> = useCallback(
            ({ item, index, section }) => {
                const isPublisher = userId
                    ? userId === item.publisher ||
                      (item.payload.senderId
                          ? userId?.indexOf(item.payload?.senderId ?? "") > -1
                          : false)
                    : false;

                const isSystemMessage =
                    item.publisher === SYSTEM_MESSAGE_PUBLISHER && !item.payload.senderId;

                return (
                    <MessageView
                        conversationTitle={conversationTitle}
                        message={item}
                        isPublisher={isPublisher}
                        isSystemMessage={isSystemMessage}
                        blocked={blocked}
                        deleted={deleted}
                        conversationMeta={conversationMeta}
                        conversationId={conversationId}
                        nextMessage={section.data[index - 1]}
                        previousMessage={section.data[index + 1]}
                    />
                );
            },
            [
                MessageView,
                userId,
                blocked,
                deleted,
                conversationMeta,
                conversationId,
                conversationTitle,
            ],
        );

        const { value: theme } = useTheme();

        const containerStyle = useMemo(
            () => ({
                backgroundColor: theme["color-basic-5"],
                opacity: blocked || deleted ? 0.3 : 1,
            }),
            [blocked, deleted, theme],
        );

        const viewabilityConfig: ViewabilityConfig = useMemo(
            () => ({
                waitForInteraction: false,
                itemVisiblePercentThreshold: 90,
                minimumViewTime: 100,
            }),
            [],
        );

        const onViewableItemsChanged: NonNullable<
            IOSectionListProps<Message, Section>["onViewableItemsChanged"]
        > = useCallback(({ viewableItems }) => {
            const items = viewableItems.filter((i) => i.isViewable).map((item) => item.item);
            contentContextRef.current?.setVisibleMessages(items);
        }, []);

        return (
            <ChatConversationContentContextProvider ref={contentContextRef}>
                <>
                    {ChatInfoComponent ? (
                        <ChatInfoComponent showPopup={showPopup} closeInfoPopup={closeInfoPopup} />
                    ) : null}
                    <View style={[styles.container, containerStyle, style]}>
                        <IOSectionList
                            ref={sectionListRef}
                            inverted
                            invertStickyHeaders={false}
                            stickySectionHeadersEnabled={false}
                            sections={sections}
                            ListHeaderComponent={MessageListFooterComponent}
                            ListFooterComponent={MessageListHeaderComponent}
                            ListEmptyComponent={MessageListEmptyComponent}
                            renderItem={ItemComponent}
                            contentContainerStyle={styles.contentContainer}
                            keyExtractor={(m) => m.id}
                            renderSectionFooter={SectionHeaderComponent}
                            ItemSeparatorComponent={ItemSeparatorComponent}
                            SectionSeparatorComponent={SectionSeparatorComponent}
                            maxToRenderPerBatch={4}
                            onEndReached={onEndReached}
                            onEndReachedThreshold={1}
                            extraData={extraData}
                            viewabilityConfig={viewabilityConfig}
                            onViewableItemsChanged={onViewableItemsChanged}
                            disableVirtualization={Platform.OS === "web"}
                            // https://github.com/facebook/react-native/issues/26264
                            removeClippedSubviews={false}
                        />
                    </View>
                </>
            </ChatConversationContentContextProvider>
        );
    }),
);

if (process.env.NODE_ENV !== "production") {
    ChatConversationContent.displayName = "ChatConversationContent";
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    contentContainer: {
        paddingBottom: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-medium"],
        paddingTop: SpacingValue["space-medium"],
    },
    sectionHeader: {
        alignItems: "center",
    },
    itemSeparator: {
        marginTop: SpacingValue["space-x-large"],
    },
    sectionSeparator: {
        marginTop: 20,
    },
});
