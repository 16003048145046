import { useCallback } from "react";
import type { SdkImageMessagePayload } from "@swiggy-private/connect-chat-sdk";

import { useSendMessage } from "./use-send-message";
import { useChatSdk } from "./use-chatsdk";
import { ISentMessageResponse } from "../interfaces/types";

export const useSendImageMessage = (
    conversationId: string,
): ((payload: SdkImageMessagePayload) => ISentMessageResponse | undefined) => {
    const sdk = useChatSdk();
    const sendMessage = useSendMessage(conversationId);

    const sendImageMessage = useCallback(
        (payload: SdkImageMessagePayload): ISentMessageResponse | undefined => {
            if (!sdk) {
                return;
            }

            const message = sdk.prepareMessage({
                type: "image",
                payload,
            });

            return sendMessage(message);
        },
        [sdk, sendMessage],
    );

    return sendImageMessage;
};
