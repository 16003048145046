import * as React from "react";
import { Button, ButtonProps } from "@swiggy-private/rn-dls";

import { ThreeDotsLoader } from "../three-dots-loader";

export const ActionButton: React.FC<ButtonProps & { loading?: boolean }> = ({
    loading,
    disabled = loading,
    children,
    ...props
}) => {
    return (
        <Button disabled={disabled} {...props}>
            {loading ? <ThreeDotsLoader /> : children}
        </Button>
    );
};
