import React, { useRef } from "react";
import { StyleSheet, ScrollView, View, useWindowDimensions } from "react-native";
import { useNavigation } from "@react-navigation/native";

import { Button, Text, SpacingValue } from "@swiggy-private/rn-dls";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { ApiError } from "@swiggy-private/connect-api-core/src";
import { Form, FormHandler } from "@swiggy-private/react-native-ui";

import { useAddressContext } from "../../contexts";
import { GoogleMap } from "../google-map";
import { useAddressData } from "../../hooks/use-address-data";
import { MapAddressPill } from "../map-address-pill";
import { AddressFormProps } from "../../interfaces";

import { InputArea } from "./components/input-area";
import { ADDRESS_FORM_CONSTS } from "./constants";
import { useBtnState } from "./hooks/use-btn-state";

export const AddressForm: React.FC<AddressFormProps> = ({
    onPressCTA,
    addressId,
    context,
    onEditPress,
    resourceGetter,
    onMountMap,
    onErrorMap,
    onMountMapAddressPill,
    LoaderComponent,
    updateStoreAddressApi,
    logger,
    toast,
    saveOrUpdateAnalyticsEvent,
    editAddressAnalyticsEvent,
    storeId,
    triggerStoreUpdate,
    createStoreAddressApi,
    createConsumerAddressApi,
    refreshCart,
    provider,
}) => {
    const navigation = useNavigation();
    const item = resourceGetter?.(addressId ?? "");
    const { state, updateState } = useAddressContext();

    const latitudeValue = React.useMemo(() => Number(state.lat), [state.lat]);
    const longitudeValue = React.useMemo(() => Number(state.lng), [state.lng]);

    const addressValue = React.useMemo(
        () => (!state.address && item?.address ? item.address : state.address || ""),
        [item, state.address],
    );
    const landmarkValue = React.useMemo(
        () => (!state.landmark && item?.landmark ? item.landmark : state.landmark || ""),
        [item, state.landmark],
    );
    const instructionsValue = React.useMemo(
        () =>
            !state.instructions && item?.instructions
                ? item.instructions
                : state.instructions || "",
        [item, state.instructions],
    );
    const annotationValue = React.useMemo(
        () => (!state.annotation && item?.annotation ? item.annotation : state.annotation || ""),
        [item, state.annotation],
    );
    const postalCodeValue = React.useMemo(
        () => (!state.postalCode && item?.postalCode ? item.postalCode : state.postalCode || ""),
        [item, state.postalCode],
    );
    const cityValue = React.useMemo(
        () => (!state.city && item?.city ? item.city : state.city || ""),
        [item?.city, state.city],
    );
    const stateValue = React.useMemo(
        () => (!state.state && item?.state ? item.state : state.state || ""),
        [item?.state, state.state],
    );
    const addressData = useAddressData({ latitudeValue, longitudeValue });

    const placeTitle = React.useMemo(
        () => (state && addressData ? addressData.locationTitle : state.googleLocationTitle),
        [addressData, state],
    );

    const streetAddress = React.useMemo(
        () => (state && addressData ? addressData.streetAddress : state.googleStreetAddress),
        [addressData, state],
    );

    const [completeAddress, setCompleteAddress] = React.useState<string>(addressValue);
    const [landmark, setLandmark] = React.useState<string>(landmarkValue);
    const [instructions, setInstructions] = React.useState<string>(instructionsValue);
    const [addressLabel, setAddressLabel] = React.useState<string>(annotationValue);
    const [pincode, setPincode] = React.useState<string>(postalCodeValue.toString());
    const [city, setCity] = React.useState(cityValue);
    const [addressState, setAddressState] = React.useState(stateValue);
    const [errors, setErrors] = React.useState<Record<string, string>>();

    const [saving, setSaving] = React.useState(false);

    const invalidPin = pincode.length < 6;
    const mountedRef = useMountedRef();
    const scrollRef = useRef<ScrollView>(null);
    const formRef = useRef<FormHandler>(null);
    const { height: windowHeight } = useWindowDimensions();

    const [buttonDisabled, buttonText] = useBtnState({
        completeAddress,
        landmark,
        instructions,
        pincode,
        addressLabel,
        latitudeValue,
        longitudeValue,
        item,
        city,
        addressState,
    });

    const saveOrUpdateAddressDetails = React.useCallback(
        async (formData: Record<string, unknown>) => {
            try {
                let responseVal;
                setSaving(true);

                saveOrUpdateAnalyticsEvent();

                const commonPayload = {
                    defaultAddress: !!(state?.defaultAddress || context === "default" || !context),
                    lat: latitudeValue.toString(),
                    lng: longitudeValue.toString(),
                    instructions,
                    address: completeAddress.trim(),
                    annotation: formData.tag ? formData.tag.toString().trim() : addressLabel.trim(),
                    landmark,
                    locality: addressData?.locality || city,
                    city,
                    state: addressState,
                    country: addressData?.country || "",
                };

                const payload = {
                    ...commonPayload,
                    postalCode: Number(pincode),
                };
                //This API is called when address is created in consumer app
                if (createConsumerAddressApi) {
                    const consumerPayload = {
                        ...commonPayload,
                        postalCode: pincode,
                        formattedAddress: state.googleStreetAddress || "",
                        addressLine2: "",
                    };

                    const response = await createConsumerAddressApi({ payload: consumerPayload });
                    responseVal = response;

                    mountedRef.current && refreshCart?.(response.id);

                    if (!mountedRef.current) {
                        return;
                    }
                    //This API will be called when store address is updated in seller app.
                } else if (context === "update" && item && updateStoreAddressApi && storeId) {
                    await updateStoreAddressApi({
                        storeId,
                        addressId: item.addressId,
                        payload,
                    });
                } else {
                    //This API will be called when store address is created in seller app.
                    if (storeId && createStoreAddressApi) {
                        const address = await createStoreAddressApi({ storeId, payload });

                        if (!mountedRef.current) {
                            return;
                        }

                        triggerStoreUpdate?.(address);
                    }
                }

                mountedRef.current && setSaving(false);

                if ((context === "default" || !context) && mountedRef.current) {
                    updateState({
                        annotation: addressLabel,
                        lat: latitudeValue.toString(),
                        lng: longitudeValue.toString(),
                        address: completeAddress,
                        postalCode: Number(pincode),
                        city,
                        state: addressState,
                    });
                }

                mountedRef.current && onPressCTA?.(responseVal);
            } catch (err) {
                logger?.(err);
                if (mountedRef.current) {
                    setSaving(false);
                    if (err instanceof ApiError) {
                        if (err.errors) {
                            setErrors(err?.errors);
                        } else {
                            toast?.((err as Error).message);
                        }
                    }
                }
            }
        },
        [
            addressData?.country,
            addressData?.locality,
            addressLabel,
            addressState,
            city,
            completeAddress,
            context,
            createConsumerAddressApi,
            createStoreAddressApi,
            instructions,
            item,
            landmark,
            latitudeValue,
            logger,
            longitudeValue,
            mountedRef,
            onPressCTA,
            pincode,
            refreshCart,
            saveOrUpdateAnalyticsEvent,
            state?.defaultAddress,
            state.googleStreetAddress,
            storeId,
            toast,
            triggerStoreUpdate,
            updateState,
            updateStoreAddressApi,
        ],
    );

    const handleEditPress = React.useCallback(() => {
        if (context === "update" && onEditPress) {
            onEditPress({ lat: latitudeValue, long: longitudeValue });
        } else {
            editAddressAnalyticsEvent?.();

            if (navigation.canGoBack()) {
                navigation.goBack();
            }
        }
    }, [
        context,
        editAddressAnalyticsEvent,
        latitudeValue,
        longitudeValue,
        navigation,
        onEditPress,
    ]);

    React.useEffect(() => {
        if (item && !state.lat) {
            updateState(item);
        }
    }, [item, state, updateState]);

    return (
        <ScrollView
            ref={scrollRef}
            bounces={false}
            showsVerticalScrollIndicator={false}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={styles.scrollContainer}>
            <View style={[{ height: windowHeight * 0.26 }, styles.mapArea]}>
                {latitudeValue && longitudeValue ? (
                    <GoogleMap
                        lat={latitudeValue}
                        lng={longitudeValue}
                        showsUserLocation={false}
                        markerDraggable={false}
                        scrollEnabled={false}
                        loadingEnabled={false}
                        latitudeDelta={0.005}
                        longitudeDelta={0.005}
                        mapMarker
                        style={styles.mapStyles}
                        onMount={onMountMap}
                        onError={onErrorMap}
                        provider={provider}
                    />
                ) : null}

                <MapAddressPill
                    addressTitle={placeTitle || ""}
                    streetAddress={streetAddress || ""}
                    navigationLabel={ADDRESS_FORM_CONSTS.NAVIGATION_LABEL}
                    handleNavigation={handleEditPress}
                    style={styles.addressPill}
                    onMount={onMountMapAddressPill}
                />
            </View>
            <Form
                onSubmit={saveOrUpdateAddressDetails}
                ref={formRef}
                style={styles.container}
                errors={errors}>
                <InputArea
                    handleCompleteAddress={setCompleteAddress}
                    handleLandmark={setLandmark}
                    handleInstructions={setInstructions}
                    handleAddressName={setAddressLabel}
                    city={city}
                    handleCity={setCity}
                    stateValue={addressState}
                    handleState={setAddressState}
                    pincode={pincode}
                    handlePincode={setPincode}
                    pinCodeError={invalidPin}
                    completeAddress={completeAddress}
                    landmark={landmark}
                    instructions={instructions}
                    addressLabel={addressLabel}
                    scrollRef={scrollRef}
                />
                <Button size="large" disabled={buttonDisabled} onPress={formRef?.current?.submit}>
                    {saving ? (
                        <LoaderComponent />
                    ) : (
                        <Text category="btn2" weight="bold" color="color-basic-0">
                            {buttonText}
                        </Text>
                    )}
                </Button>
            </Form>
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    scrollContainer: {
        flexGrow: 1,
        paddingTop: SpacingValue["space-large"],
        paddingHorizontal: SpacingValue["space-medium"],
        paddingBottom: SpacingValue["space-large"],
    },
    mapArea: {
        alignItems: "center",
        justifyContent: "flex-end",
        borderRadius: 16,
        overflow: "hidden",
    },
    mapStyles: {
        ...StyleSheet.absoluteFillObject,
        zIndex: 2,
    },
    addressPill: {
        marginBottom: 6,
        width: "95%",
        zIndex: 10,
        paddingHorizontal: SpacingValue["space-small"],
    },
});
