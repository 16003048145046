import React from "react";
import { Text, TextProps } from "../components/text";
import { RenderProp } from "./falsy-fc";

export interface FalsyTextProps extends Omit<TextProps, "children"> {
    component?: RenderProp<TextProps> | React.ReactText | null;
}

/**
 * Helper component for optional text properties.
 *
 * Accepts same props as Text component and
 * `component` which may be a string, a function, null or undefined.
 *
 * @example Will render nothing.
 * ```
 * <FalsyText />
 * ```
 *
 * @example Will render red title.
 * ```
 * const Title = () => (<FalsyText style={{ color: 'red' }} component='Title' />);
 * ```
 *
 * @example Will render image and red title.
 * ```
 * const renderTitle = (props) => (
 *   <React.Fragment>
 *     <Image source={require('../asset.png')}/>
 *     <Text {...props}>Title</Text>
 *   </React.Fragment>
 * );
 *
 * const Title = () => (
 *   <FalsyText
 *     style={{ color: 'red' }}
 *     component={renderTitle}
 *   />
 * );
 * ```
 *
 * @param param0 {@link FalsyTextProps}
 */
export const FalsyText: React.FC<FalsyTextProps> = ({
    component,
    allowFontScaling = false,
    ...textProps
}) => {
    if (!component) {
        return null;
    }

    if (React.isValidElement(component)) {
        return React.cloneElement(component, { ...textProps, allowFontScaling } as TextProps);
    }

    if (typeof component === "function") {
        return React.createElement(component, { ...textProps, allowFontScaling } as TextProps);
    }

    return (
        <Text {...textProps} allowFontScaling={allowFontScaling}>
            {component}
        </Text>
    );
};
