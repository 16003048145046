import { useMountedRef } from "@swiggy-private/react-hooks";
import React, {
    ForwardRefRenderFunction,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { Animated, Easing, LayoutChangeEvent, Platform, StyleSheet, ViewProps } from "react-native";
import { useAnimatedValue } from "../../hooks/use-animated-value";

export type InputLabelProps = ViewProps & {
    label: NonNullable<React.ReactText>;
    size?: "small" | "large";
    parentHeight?: number;
    leftPosition?: number;
};

export type InputLabelHandler = {
    setParentHeight: (height: number) => void;
    setLabelSize: (mode: NonNullable<InputLabelProps["size"]>) => void;
};

const InputLabelComponent: ForwardRefRenderFunction<InputLabelHandler, InputLabelProps> = (
    { style, label, size = "large", leftPosition, parentHeight: ph, ...props },
    ref,
) => {
    const [height, setHeight] = useState(0);
    const [parentHeight, setParentHeight] = useState(ph ?? 0);
    const [labelSize, setLabelSize] = useState(size);

    const animatingRef = useRef<Animated.CompositeAnimation>();
    const translateValueYRef = useAnimatedValue(0);
    const translateValueXRef = useAnimatedValue(0);
    const mounted = useMountedRef();

    const onLayout = useCallback(
        (event: LayoutChangeEvent) => setHeight(event.nativeEvent.layout.height),
        [],
    );

    const getTransformStyle = useCallback(
        (lsize: NonNullable<InputLabelProps["size"]>) => {
            return {
                translateY: lsize === "small" ? -height / 2 : parentHeight / 2 - height / 2,
                translateX: leftPosition ?? 0,
            };
        },
        [height, parentHeight, leftPosition],
    );

    const onLabelSizeChange = useCallback(
        (lsize: NonNullable<InputLabelProps["size"]>) => {
            animatingRef.current?.stop();

            const currentStyle = getTransformStyle(labelSize);
            const toStyle = getTransformStyle(lsize);

            if (currentStyle.translateY === toStyle.translateY) {
                return;
            }

            translateValueYRef.setValue(currentStyle.translateY);
            translateValueXRef.setValue(currentStyle.translateX);

            animatingRef.current = Animated.parallel(
                [
                    Animated.timing(translateValueYRef, {
                        toValue: toStyle.translateY,
                        duration: 150,
                        easing: Easing.bezier(0, 0, 0.2, 1),
                        useNativeDriver: Platform.OS !== "web",
                    }),
                    Animated.timing(translateValueXRef, {
                        toValue: toStyle.translateX,
                        duration: 150,
                        easing: Easing.bezier(0, 0, 0.2, 1),
                        useNativeDriver: Platform.OS !== "web",
                    }),
                ],
                { stopTogether: true },
            );

            animatingRef.current.start(({ finished }) => {
                if (!finished || !mounted.current) {
                    animatingRef.current = undefined;
                    return;
                }

                animatingRef.current = undefined;
                translateValueYRef.setValue(0);
                translateValueXRef.setValue(0);
                setLabelSize(lsize);
            });
        },
        [getTransformStyle, labelSize, mounted, translateValueXRef, translateValueYRef],
    );

    useImperativeHandle(
        ref,
        () => ({
            setParentHeight,
            setLabelSize: onLabelSizeChange,
        }),
        [onLabelSizeChange],
    );

    const transformStyle = getTransformStyle(labelSize);
    const labelStyle = {
        ...StyleSheet.flatten(style),
        transform: [
            {
                translateY: animatingRef.current ? translateValueYRef : transformStyle.translateY,
            },
            {
                translateX: animatingRef.current ? translateValueXRef : transformStyle.translateX,
            },
        ],
    };

    // render label only when we have the parent component height and label leftPosition.
    if (!parentHeight || typeof leftPosition === "undefined") {
        labelStyle.opacity = 0;
        if (Platform.OS === "web") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            labelStyle.visibility = "hidden";
        }
    }

    return (
        <Animated.Text
            {...props}
            allowFontScaling={false}
            style={labelStyle}
            onLayout={onLayout}
            selectable={false}
            numberOfLines={1}>
            {label}
        </Animated.Text>
    );
};

export const InputLabel = React.forwardRef(InputLabelComponent);
