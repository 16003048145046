export type ThrottleFunction<T extends unknown[]> = (...args: T) => void;
export type DebounceFunction<T extends unknown[]> = (...args: T) => void;

const throttleFn = <T extends unknown[]>(
    func: ThrottleFunction<T>,
    delay: number,
): ThrottleFunction<T> => {
    let lastTime = 0;
    let timerId: ReturnType<typeof setTimeout> | null = null;

    return (...args: T) => {
        const currentTime = Date.now();
        // eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias
        const self = this;

        if (currentTime - lastTime >= delay) {
            func.apply(self, args);
            lastTime = currentTime;
        } else {
            if (timerId) {
                clearTimeout(timerId);
            }

            timerId = setTimeout(() => {
                func.apply(self, args);
                lastTime = Date.now();
            }, delay - (currentTime - lastTime));
        }
    };
};

const debounceFn = <T extends unknown[]>(
    func: DebounceFunction<T>,
    delay: number,
): DebounceFunction<T> => {
    let timerId: ReturnType<typeof setTimeout> | null;

    return function (this: unknown, ...args: T) {
        if (timerId) {
            clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
};

// * helps us to delay the execution of code after its usage
const delay = (ms: number): Promise<void> => {
    return new Promise((resolve, _) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};

export { throttleFn, debounceFn, delay };
