/* eslint-disable @typescript-eslint/ban-types */
import React from "react";

export type RenderProp<Props = {}> = React.FC<React.PropsWithChildren<Props>> | React.ReactElement;

export type FalsyFCProps<Props = {}> = Props & {
    component?: RenderProp<Props>;
    fallback?: React.ReactElement;
};

/**
 * Helper component for optional properties that should render a component.
 * @param props {@link FalsyFCProps}
 *
 * @example Will render red title.
 * ```
 * const Title = () => (
 *   <FalsyFC
 *     style={{ color: 'red' }}
 *     component={props => <Text {...props}>Title</Text>}
 *   />
 * );
 * ```
 */
export function FalsyFC<T = {}>(props: FalsyFCProps<T>): React.ReactElement | null {
    const { component, fallback, ...otherProps } = props;

    if (!component) {
        return fallback || null;
    }

    if (React.isValidElement(component)) {
        return React.cloneElement(component, otherProps);
    }

    return React.createElement(
        component as React.FC<React.PropsWithChildren<T>>,
        otherProps as React.PropsWithChildren<T>,
    );
}
