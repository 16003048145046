import * as React from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import { useTheme, ColorPalette } from "@swiggy-private/rn-dls";

import { TEST_ID } from "../constants/divider";

export interface DividerProps extends ViewProps {
    color?: keyof ColorPalette;
    isHorizontal?: boolean;
}

/** TODO: Use this element in all apps in future and deprecate the one written only in consumer */
export const Divider: React.FC<DividerProps> = ({
    style: propStyle,
    color,
    isHorizontal = true,
    ...props
}) => {
    const { value: theme } = useTheme();

    const style = {
        height: isHorizontal ? StyleSheet.hairlineWidth : "100%",
        width: isHorizontal ? "100%" : StyleSheet.hairlineWidth,
        backgroundColor: theme[color ?? "color-basic-15"],
    };

    return <View style={[style, propStyle]} {...props} testID={TEST_ID} />;
};
