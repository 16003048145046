import { TextProps } from "@swiggy-private/rn-dls";

export const CONSTANTS = {
    MIN_MSG_WIDTH: 240,
    PRIMARY_BTN_TEXT: "Copy coupon code",
};

export const textProps: {
    title: TextProps;
    description: TextProps;
    prefix: TextProps;
    code: TextProps;
} = {
    title: {
        category: "h3",
        color: "color-basic-75",
        weight: "bold",
    },
    description: { category: "b3", color: "color-basic-60" },
    prefix: {
        category: "b2",
        color: "color-basic-75",
    },
    code: { category: "b2", weight: "bold" },
};
