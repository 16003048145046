import { HttpError } from "@swiggy-private/http-client";
import { ApiError } from "@swiggy-private/connect-api-core";

export const getErrorTitle = (error: Error): string | null => {
    if (error instanceof HttpError) {
        return "Connection Error";
    } else if (error instanceof ApiError) {
        return error.errorTitle;
    }

    return null;
};

export const getErrorDescription = (error: Error): string | null => {
    if (error instanceof HttpError || (error?.message || "").toLowerCase().includes("aborted")) {
        return "Something's not right. Please try checking both wifi/4G or try again later.";
    } else if (error instanceof ApiError) {
        if (
            error.is5xxError() ||
            (error.errorMessage || "").toLowerCase().includes("server error")
        ) {
            return "Something's not right. Please try again later.";
        }

        return error.errorMessage;
    }

    return null;
};
