import React from "react";
import { Line } from "react-native-svg";

import { useCountdown } from "../hooks/use-countdown";
import { Props } from "../types";

const DashesComponent: React.FC<Props> = (props) => {
    const { numberOfDashes = 60, biggerDashNumber = 4, dashesColor = "#F0F0F5" } = props;
    const { size, stroke, pathLength, strokeDashoffset } = useCountdown(props);

    const numberDashArray = React.useMemo(
        () => new Array(numberOfDashes).fill(true),
        [numberOfDashes],
    );

    const degree = React.useMemo(() => 360 / numberOfDashes, [numberOfDashes]);

    const bigDashDivisor = React.useMemo(
        () => (biggerDashNumber === 0 ? 0 : numberOfDashes / biggerDashNumber),
        [biggerDashNumber, numberOfDashes],
    );

    const onColorPercent = React.useMemo(
        () => ((pathLength - strokeDashoffset) * 100) / pathLength,
        [pathLength, strokeDashoffset],
    );

    return (
        <>
            {numberDashArray.map((_, index) => (
                <Dash
                    key={index}
                    index={index}
                    degree={degree}
                    size={size}
                    offColor={dashesColor}
                    onColor={stroke}
                    onColorPercent={onColorPercent}
                    bigDashDivisor={bigDashDivisor}
                />
            ))}
        </>
    );
};

export const Dashes = React.memo(DashesComponent);

interface DashProps {
    index: number;
    degree: number;
    size: number;
    offColor: string;
    onColor: string;
    onColorPercent: number;
    bigDashDivisor: number;
}

const Dash: React.FC<DashProps> = ({
    size,
    offColor,
    onColor,
    onColorPercent,
    degree,
    index,
    bigDashDivisor,
}) => {
    const center = React.useMemo(() => size / 2, [size]);
    const rotation = React.useMemo(() => degree * index, [degree, index]);
    const rotationPercent = React.useMemo(() => (rotation / 360) * 100, [rotation]);

    const color = React.useMemo(
        () => (rotationPercent < onColorPercent ? onColor : offColor),
        [offColor, onColor, onColorPercent, rotationPercent],
    );

    const y2 = React.useMemo(
        () => (bigDashDivisor === 0 || index % bigDashDivisor !== 0 ? 10 : 14),
        [bigDashDivisor, index],
    );

    return (
        <Line
            x1={center}
            x2={center}
            y1={2}
            y2={y2}
            transform={`rotate(${rotation} ${center} ${center})`}
            strokeWidth={1}
            stroke={color}
            strokeLinecap="round"
        />
    );
};
