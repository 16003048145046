import memoizeOne from "memoize-one";
import {
    InputColor,
    InputColorStyle,
    InputSize,
    InputSizeStyle,
    InputState,
    InputStyle,
    InputStyleMeta,
} from "../interfaces/input";
import { Theme } from "@swiggy-private/rn-dls-theme";

type InputColorWithStateStyle = Record<
    InputColor,
    {
        state: Record<InputState, Partial<InputStyle>>;
    } & InputColorStyle
>;

const defaultColorStyle = (theme: Theme): InputColorStyle => ({
    backgroundColor: "#fff",
    borderColor: theme["color-basic-30"],
    textColor: theme["color-basic-100"],
    placeholderColor: theme["color-basic-45"],
    iconTintColor: theme["color-basic-45"],
    labelColor: theme["color-basic-60"],
    hintColor: theme["color-basic-60"],
    hintContainerBackgroundColor: theme["color-primary-light"],
    hintContainerBorderColor: theme["color-primary-300"],
    errorColor: theme["negative"],
    errorBorderColor: theme["negative"],
    errorContainerBackgroundColor: theme["color-critical-light"],
    errorContainerBorderColor: theme["color-critical-light"],
    smallLabelBackgroundColor: theme["color-basic-0"],
    caretColor: theme["color-basic-100"],
    counterColor: theme["color-basic-60"],
});

const inputColorStyleMap = memoizeOne(
    (theme: Theme): InputColorWithStateStyle => ({
        primary: {
            ...defaultColorStyle(theme),
            caretColor: theme["color-primary"],
            state: {
                focused: {
                    labelColor: theme["color-primary"],
                    borderColor: theme["color-primary"],
                    iconTintColor: theme["color-primary"],
                },
                disabled: {
                    opacity: 0.4,
                },
                active: {},
                hover: {},
            },
        },
        secondary: {
            ...defaultColorStyle(theme),
            caretColor: theme["color-primary-light"],
            state: {
                focused: {
                    labelColor: theme["color-primary-light"],
                    borderColor: theme["color-primary-light"],
                    iconTintColor: theme["color-primary-light"],
                },
                disabled: {
                    opacity: 0.4,
                },
                active: {},
                hover: {},
            },
        },
    }),
);

const defaultInputSizeStyle = memoizeOne(
    (theme: Theme): InputSizeStyle => ({
        paddingHorizontal: theme["space-medium"],
        paddingVertical: theme["space-medium"],
        borderRadius: theme["border-radius"],
        borderWidth: theme["border-width"],
        minHeight: 56,
        textMarginHorizontal: 0,

        textFontFamily: theme["text-subheader-2-font-family"],
        textFontSize: theme["text-subheader-2-font-size"],
        textLetterSpacing: theme["text-subheader-2-letter-spacing"],
        textLineHeight: theme["text-subheader-2-line-height"],

        iconWidth: 20,
        iconHeight: 20,
        iconMarginHorizontal: theme["space-x-small"],

        labelFontFamily: theme["text-subheader-2-font-family"],
        labelFontSize: theme["text-subheader-2-font-size"],
        labelLineHeight: theme["text-subheader-2-line-height"],

        smallLabelFontFamily: theme["text-body-3-regular-font-family"],
        smallLabelFontSize: theme["text-body-3-regular-font-size"],
        hintFontFamily: theme["text-body-3-regular-font-family"],
        hintFontSize: theme["text-body-3-regular-font-size"],

        hintBorderWidth: 1.5,

        counterFontFamily: theme["text-body-3-regular-font-family"],
        counterFontSize: theme["text-body-3-regular-font-size"],
    }),
);

const inputSizeStyleMap = memoizeOne(
    (theme: Theme): Record<InputSize, InputSizeStyle> => ({
        small: defaultInputSizeStyle(theme),
        large: defaultInputSizeStyle(theme),
    }),
);

export const getInputStyles = (meta: InputStyleMeta, theme: Theme): InputStyle => {
    const color = meta.color ?? "primary";
    const size = meta.size ?? "large";
    const state = Array.isArray(meta.state) ? meta.state[0] : meta.state;

    const sizeStyle = inputSizeStyleMap(theme)[size];
    const { state: stateStyle, ...colorStyle } = inputColorStyleMap(theme)[color];

    return {
        ...sizeStyle,
        ...colorStyle,
        ...(state ? stateStyle[state] : {}),
    };
};
