import React, { useContext, useMemo } from "react";
import { ImageBackground, ImageStyle, StyleProp } from "react-native";

import { CloudinaryContext } from "../../contexts/cloudinary-context";
import { CImageProps, getImageUrl } from "../cloudinary-image";

export type CImageBackgroundProps = CImageProps & {
    imageStyle?: StyleProp<ImageStyle>;
};

export const CImageBackground: React.FC<CImageBackgroundProps> = ({
    id,
    resizeMode = "cover",
    width,
    height,
    children,
    resourceType: cloudResourceType = "upload",
    ...props
}) => {
    const { cloudName, baseUrl: cloudBaseUrl, folderName } = useContext(CloudinaryContext);

    const imageUrl = useMemo(
        () =>
            getImageUrl({
                id,
                cloudBaseUrl,
                cloudName,
                cloudResourceType,
                folderName,
                height,
                width,
                children,
            }),
        [children, cloudBaseUrl, cloudName, cloudResourceType, folderName, height, id, width],
    );

    return (
        <ImageBackground
            {...props}
            source={{ uri: imageUrl, width, height, cache: "force-cache" }}
            resizeMode={resizeMode}>
            {children}
        </ImageBackground>
    );
};
