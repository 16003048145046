import { IMAGEKIT_MEDIA_BASE_URL } from "@env";

/**
 * Converts cloudinary Avatar URL to IK
 */
export const formatAvatarUrl = (avatarUrl?: string): string => {
    if (!avatarUrl?.trim()) {
        return "";
    }

    const MEDIA_URL = IMAGEKIT_MEDIA_BASE_URL ?? "https://minis-media-assets.swiggy.com";
    const formatHost = avatarUrl?.replace("https://res.cloudinary.com", MEDIA_URL);

    const fomattedFolder = formatHost.replace("mini-assets/", "");

    return fomattedFolder;
};
