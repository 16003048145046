import React from "react";
import { Platform } from "react-native";

import { EmptyFunction } from "../helpers";
import { MapEndpointContextInterface } from "../interfaces";

export const DefaultMapEndpoint =
    Platform.OS === "web"
        ? process.env.CONSUMER_SERVICE_HOST || "https://capi.mini.store"
        : process.env.LOCATION_SERVICE_API_URL || "https://maps.swiggy.com";

export const MapEndpointContext = React.createContext<MapEndpointContextInterface>({
    mapEndpoint: DefaultMapEndpoint,
    updateMapEndpoint: EmptyFunction,
});

type MapEndpointContextProviderProps = {
    mapEndpoint?: string;
};

/**
 * This context provided map api endpoint
 */
export const MapEndpointContextProvider: React.FC<
    React.PropsWithChildren<MapEndpointContextProviderProps>
> = (props) => {
    const [mapEndpoint, updateEndpointState] = React.useState(
        props.mapEndpoint ?? DefaultMapEndpoint,
    );

    const updateMapEndpoint = React.useCallback((newEndpoint: string) => {
        updateEndpointState(newEndpoint);
    }, []);

    const endpointValue: MapEndpointContextInterface = React.useMemo(() => {
        return {
            mapEndpoint,
            updateMapEndpoint,
        };
    }, [mapEndpoint, updateMapEndpoint]);

    return (
        <MapEndpointContext.Provider value={endpointValue}>
            {props.children}
        </MapEndpointContext.Provider>
    );
};

export const useMapEndpointContext = (): MapEndpointContextInterface =>
    React.useContext(MapEndpointContext);
