import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";
import isEqual from "fast-deep-equal/es6";

import { getMessageSectionKey } from "../helpers/message";
import { ConversationMeta, MessageSection, User, Message, Conversation } from "../interfaces/types";

type MessageSectionRecord = Record<string, MessageSection>;

export interface ChatState {
    conversations: Record<string, Conversation>;
    conversationsMeta: Record<string, ConversationMeta | undefined>;
    users: Record<string, User>;
    messageBlocks: Record<
        string,
        | {
              messageSections: MessageSectionRecord;
              messageSectionSortedList: string[];
          }
        | undefined
    >;
    messages: Record<string, Record<string, Message>>;
}

const getInitialState = (initialState?: Partial<ChatState>): ChatState => ({
    conversations: {},
    conversationsMeta: {},
    users: {},
    messages: {},
    messageBlocks: {},
    ...initialState,
});

function messagesToMessageSectionRecord(messages: Message[]): MessageSectionRecord {
    return messages.reduce((acc, message) => {
        const sectionKey = getMessageSectionKey(message);

        if (!acc[sectionKey]) {
            acc[sectionKey] = {
                id: sectionKey,
                messages: [],
                timestamp: message.timestamp,
            };
        }

        acc[sectionKey].messages.push(message.id);

        return acc;
    }, {} as MessageSectionRecord);
}

function chatReducer(
    state: Readonly<ChatState>,
    action: Readonly<ChatAction>,
): Readonly<ChatState> {
    switch (action.type) {
        case "ADD_CONVERSATION_ACTION":
            const newConversations = Object.fromEntries(
                action.payload.conversations
                    .filter((newConversation) => {
                        const conversation = state.conversations[newConversation.id];
                        if (conversation != null && isEqual(newConversation, conversation)) {
                            return false;
                        }

                        return true;
                    })
                    .map((c) => [c.id, c]),
            );

            if (!Object.keys(newConversations).length) {
                return state;
            }

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    ...newConversations,
                },
            };

        case "ADD_USER_ACTION":
            const users = action.payload.users
                .map((u) => ({
                    ...u,
                    online: state.users[u.id]?.online === true,
                    lastSeenAt: state.users[u.id]?.lastSeenAt,
                }))
                .filter((u) => (state.users[u.id] == null ? true : !isEqual(u, state.users[u.id])));

            if (!users.length) {
                return state;
            }

            return {
                ...state,
                users: {
                    ...state.users,
                    ...Object.fromEntries(users.map((u) => [u.id, u])),
                },
            };

        case "SET_USER_ONLINE_ACTION":
            const payload = action.payload;

            if (
                state.users[payload.userId] == null ||
                (payload.online === state.users[payload.userId].online &&
                    payload.lastSeenAt === state.users[payload.userId].lastSeenAt)
            ) {
                return state;
            }

            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.userId]: {
                        ...state.users[action.payload.userId],
                        online: action.payload.online,
                        lastSeenAt: action.payload.lastSeenAt,
                    },
                },
            };

        case "ADD_CONVERSATION_MESSAGE_ACTION":
            // create sections using given list of messages
            const { conversationId, messages } = action.payload;
            const newMessageSections = messagesToMessageSectionRecord(messages);

            // merge existing sections with new sections
            const messageSections = { ...state.messageBlocks[conversationId]?.messageSections };
            let messageSectionSortedList =
                state.messageBlocks[conversationId]?.messageSectionSortedList ?? [];

            let messagesRecord = state.messages;
            if (messages.length > 0) {
                messagesRecord = { ...messagesRecord };
                if (!messagesRecord[conversationId]) {
                    messagesRecord[conversationId] = {};
                }

                messages.forEach((msg) => {
                    messagesRecord[conversationId][msg.id] = {
                        ...messagesRecord[conversationId][msg.id],
                        ...msg,
                    };
                });
            }

            Object.entries(newMessageSections).forEach(([sectionId, section]) => {
                if (!messageSections[sectionId]) {
                    messageSections[sectionId] = section;
                    messageSectionSortedList = [...messageSectionSortedList, sectionId].sort(
                        (a, b) => {
                            return messageSections[b].timestamp - messageSections[a].timestamp;
                        },
                    );
                } else {
                    const existingMessages = messageSections[sectionId].messages;
                    const newMessages = section.messages.filter(
                        (id) => existingMessages.findIndex((vid) => vid === id) === -1,
                    );

                    messageSections[sectionId] = {
                        ...section,
                        messages: newMessages.length
                            ? [...existingMessages, ...newMessages]
                            : existingMessages,
                    };
                }

                messageSections[sectionId].messages = [
                    ...messageSections[sectionId].messages.sort(
                        (a, b) =>
                            (messagesRecord[conversationId][b]?.timestamp || 0) -
                            (messagesRecord[conversationId][a]?.timestamp || 0),
                    ),
                ];
            });

            return {
                ...state,
                messages: messagesRecord,
                messageBlocks: {
                    ...state.messageBlocks,
                    [conversationId]: {
                        ...state.messageBlocks[conversationId],
                        messageSections,
                        messageSectionSortedList,
                    },
                },
            };

        case "SET_INDICATOR_ACTION":
            const oldMeta = state.conversationsMeta[action.payload.conversationId] ?? {
                indicators: {},
            };

            const newIndicator = {
                ...oldMeta.indicators,
                ...action.payload.indicators,
            };

            if (isEqual(oldMeta.indicators, newIndicator)) {
                return state;
            }

            return {
                ...state,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    [action.payload.conversationId]: {
                        ...oldMeta,
                        indicators: newIndicator,
                    },
                },
            };

        case "SET_UNREAD_COUNT_ACTION":
            const currentCount = state.conversationsMeta[action.payload.conversationId] ?? {
                indicators: {},
                unreadCount: 0,
            };

            if (currentCount.unreadCount === action.payload.count) {
                return state;
            }

            return {
                ...state,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    [action.payload.conversationId]: {
                        ...currentCount,
                        unreadCount: action.payload.count,
                    },
                },
            };

        case "SET_CONVERSATION_LAST_READ_ACTION":
            const currentLastReadTimestamp =
                state.conversationsMeta[action.payload.conversationId]?.lastReadTimestamp;

            if (currentLastReadTimestamp && action.payload.timestamp <= currentLastReadTimestamp) {
                return state;
            }

            return {
                ...state,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    [action.payload.conversationId]: {
                        ...(state.conversationsMeta[action.payload.conversationId] ?? {
                            indicators: {},
                            unreadCount: 0,
                        }),
                        lastReadTimestamp: action.payload.timestamp,
                    },
                },
            };

        case "INCREMENT_UNREAD_COUNT_ACTION":
            const currentUnreadCount =
                state.conversationsMeta[action.payload.conversationId]?.unreadCount ?? 0;

            const messagesBlob = state.messages[action.payload.conversationId] || {};

            if (messagesBlob[action.payload.messageId] != null) {
                return state;
            }

            return {
                ...state,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    [action.payload.conversationId]: {
                        ...(state.conversationsMeta[action.payload.conversationId] ?? {
                            indicators: {},
                            unreadCount: 0,
                        }),
                        unreadCount: currentUnreadCount + 1,
                    },
                },
            };

        case "DECREMENT_UNREAD_COUNT_ACTION":
            const finalUnreadCount = Math.max(
                0,
                (state.conversationsMeta[action.payload.conversationId]?.unreadCount ?? 0) - 1,
            );

            const currentMeta = state.conversationsMeta[action.payload.conversationId] ?? {
                indicators: {},
                unreadCount: 0,
            };

            if (finalUnreadCount === currentMeta.unreadCount) {
                return state;
            }

            return {
                ...state,
                conversationsMeta: {
                    ...state.conversationsMeta,
                    [action.payload.conversationId]: {
                        ...currentMeta,
                        unreadCount: finalUnreadCount,
                    },
                },
            };

        case "ADD_MESSAGE_ACTION":
            const _messages = state.messages[action.payload.conversationId] || {};
            for (const messageId of Object.keys(_messages)) {
                const message = _messages[messageId];
                if (message.timetoken !== action.payload.messageTimeToken) {
                    continue;
                }

                if (!message.actions) {
                    message.actions = {};
                } else {
                    message.actions = { ...message.actions };
                }

                const { type, actionTimetoken, uuid, value } = action.payload;
                if (!message.actions[type]) {
                    message.actions[type] = {};
                } else {
                    message.actions[type] = {
                        ...message.actions[type],
                    };
                }

                if (!Array.isArray(message.actions[type][value])) {
                    message.actions[type][value] = [];
                }

                message.actions[type][value] = [
                    ...message.actions[type][value],
                    {
                        uuid,
                        actionTimetoken,
                    },
                ];

                _messages[messageId] = {
                    ...message,
                };

                return {
                    ...state,
                    messages: {
                        ...state.messages,
                        [action.payload.conversationId]: {
                            ..._messages,
                        },
                    },
                };
            }

            return state;

        case "RESET":
            return getInitialState();

        case "MULTI_DISPATCH":
            return action.payload.reduce(
                (newState, actualAction) => chatReducer(newState, actualAction),
                state,
            );

        case "BLOCK_CONVERSATION":
            const targetConversation = state.conversations[action.payload.conversationId];

            return {
                ...state,
                conversations: {
                    ...state.conversations,
                    [action.payload.conversationId]: {
                        ...targetConversation,
                        blockedInfo: action.payload.blockedInfo,
                    },
                },
            };
    }
}

export function useChatReducer(
    initialState?: Partial<ChatState>,
): [
    ReducerState<Reducer<ChatState, ChatAction>>,
    Dispatch<ReducerAction<Reducer<ChatState, ChatAction>>>,
] {
    return useReducer(chatReducer, initialState, getInitialState);
}

export interface AddConversationAction {
    type: "ADD_CONVERSATION_ACTION";
    payload: {
        conversations: Conversation[];
    };
}

export interface AddUserAction {
    type: "ADD_USER_ACTION";
    payload: {
        users: User[];
    };
}

export interface AddConversationMessageAction {
    type: "ADD_CONVERSATION_MESSAGE_ACTION";
    payload: {
        conversationId: string;
        messages: Message[];
    };
}

export interface SetIndicatorAction {
    type: "SET_INDICATOR_ACTION";
    payload: {
        conversationId: string;
        indicators: {
            typing?: boolean;
            online?: boolean;
        };
    };
}

export interface SetUserOnlineAction {
    type: "SET_USER_ONLINE_ACTION";
    payload: {
        userId: string;
        online: boolean;
        lastSeenAt?: number;
    };
}

export interface SetUnreadCountAction {
    type: "SET_UNREAD_COUNT_ACTION";
    payload: {
        conversationId: string;
        count: number;
    };
}

export interface SetConversationLastReadAction {
    type: "SET_CONVERSATION_LAST_READ_ACTION";
    payload: {
        conversationId: string;
        timestamp: number;
    };
}

export interface IncrementUnreadCountAction {
    type: "INCREMENT_UNREAD_COUNT_ACTION";
    payload: {
        conversationId: string;
        messageId: string;
    };
}

export interface DecrementUnreadCountAction {
    type: "DECREMENT_UNREAD_COUNT_ACTION";
    payload: {
        conversationId: string;
    };
}

export interface AddMessageAction {
    type: "ADD_MESSAGE_ACTION";
    payload: {
        conversationId: string;
        messageTimeToken: string;
        type: string;
        value: string;
        uuid: string;
        actionTimetoken: string | number;
    };
}

export interface ResetAction {
    type: "RESET";
}

export interface MultiDispatchAction {
    type: "MULTI_DISPATCH";
    payload: ChatAction[];
}

export interface BlockConversationAction {
    type: "BLOCK_CONVERSATION";
    payload: {
        conversationId: string;
        blockedInfo: Conversation["blockedInfo"];
    };
}

export type ChatAction =
    | AddConversationAction
    | AddUserAction
    | AddConversationMessageAction
    | AddMessageAction
    | ResetAction
    | SetIndicatorAction
    | SetUnreadCountAction
    | IncrementUnreadCountAction
    | DecrementUnreadCountAction
    | SetUserOnlineAction
    | MultiDispatchAction
    | SetConversationLastReadAction
    | BlockConversationAction;
