import * as React from "react";
import { ScreenSize } from "../interfaces/screen";

export interface ISSRLayoutContext {
    screenType: ScreenSize;
    isServer: boolean;
}

export const SSRLayoutContext = React.createContext<ISSRLayoutContext>({
    screenType: ScreenSize.Normal,
    isServer: false,
});
