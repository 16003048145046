import { useRef, MutableRefObject } from "react";

import { useMount } from "./use-mount";

/**
 * A hook of whether the current component has been mounted,
 * used to avoid memory leaks caused by updating the state after the component has been unmounted.
 *
 * @returns `true` if component is mounted
 */
export const useMountedRef = (): MutableRefObject<boolean> => {
    const ref = useRef(false);

    useMount(() => {
        ref.current = true;
        return () => {
            ref.current = false;
        };
    });

    return ref;
};
