export const ADDRESS_FORM_CONSTS = {
    HEADER_TITLE: "Enter address details",
    ADDRESS_INPUT_LABEL: "Complete address",
    LANDMARK_INPUT_LABEL: "Landmark (Optional)",
    INSTRUCTIONS_INPUT: {
        LABEL: "How to reach instructions (Optional)",
        MAX_LENGTH: 200,
    },
    ADDRESS_NAME_INPUT_PLACEHOLDER: "Eg: Home, Store",
    SAVE_ADDRESS_AS_TITLE: "Save address as",
    SAVE_ADDRESS_INPUT: {
        LABEL: "Name your address",
        MAX_LENGTH: 30,
    },
    BUTTON: {
        ACTIVE_LABEL: "Save Address",
        UPDATE_LABEL: "Update Address",
    },
    PINCODE_LABEL: "Pincode",
    ERROR_HINT: "mandatory field",
    NAVIGATION_LABEL: "Edit",
    PINCODE_INVALID_MSG: "* pincode should be six digits",
    API_FAILURE: "Failed to store the address",
    CITY_INPUT_LABEL: "City",
    STATE_INPUT_LABEL: "State",
};
