/* eslint-disable no-void */
import { createContext } from "react";

export type FormContextValue = {
    setField: (fieldName: string, fieldValue: unknown) => void;
    updateField: (fieldName: string, fieldValue: unknown) => void;
    submit: (meta?: Record<string, unknown>) => void;
    getErrorForField: (fieldName: string) => string | undefined;
    removeErrorForField: (fieldName: string) => void;
};

export const FormContext = createContext<FormContextValue>({
    setField: () => void 0,
    updateField: () => void 0,
    submit: () => void 0,
    getErrorForField: (_f: string) => void 0,
    removeErrorForField: () => void 0,
});
