import React from "react";
import { ViewStyle } from "react-native";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useTheme } from "@swiggy-private/rn-dls";

import { FormInput } from "@swiggy-private/react-native-ui";

type Props = {
    placeholderText: string;
    onChangeText: (text: string) => void;
    style?: ViewStyle;
};

export const Search: React.FC<Props> = ({ placeholderText, onChangeText, style }) => {
    const { value: theme } = useTheme();

    const inputStyle = {
        fontFamily: theme["text-subheader-3-font-family"],
        letterSpacing: theme["text-subheader-3-letter-spacing"],
        color: theme["color-basic-100"],
    };

    return (
        <FormInput
            name="search-location"
            placeholder={placeholderText}
            accessoryLeft={
                <SvgIcon
                    color="color-primary"
                    accessibilityLabel="Search"
                    accessible
                    icon="Magnifier"
                    height={18}
                />
            }
            textStyle={inputStyle}
            style={style}
            onChangeText={onChangeText}
            placeholderTextColor={theme["color-basic-60"]}
            autoFocus
            spellCheck={false}
            autoCorrect={false}
        />
    );
};
