import memoizeOne from "memoize-one";
import { Theme } from "@swiggy-private/rn-dls-theme";

import {
    SnackbarSizeStyle,
    SnackbarStyle,
    SnackbarStyleMeta,
    SnackbarColorStyleMap,
} from "../interfaces/snackbar";

const colorStyleMap = memoizeOne(
    (theme: Theme): SnackbarColorStyleMap => ({
        primary: {
            backgroundColor: theme["color-basic-75"],
            textColor: theme["color-basic-0"],
            touchableTextColor: theme["color-warning-200"],
        },
        secondary: {
            backgroundColor: theme["color-basic-60"],
            textColor: theme["color-basic-0"],
            touchableTextColor: theme["color-primary"],
        },
        tertiary: {
            backgroundColor: theme["color-basic-45"],
            textColor: theme["color-basic-0"],
            touchableTextColor: theme["color-primary"],
        },
        dark: {
            backgroundColor: theme["color-basic-100"],
            textColor: theme["color-basic-0"],
            touchableTextColor: theme["color-primary"],
        },
        light: {
            backgroundColor: theme["color-basic-30"],
            textColor: theme["color-basic-0"],
            touchableTextColor: theme["primary"],
        },
        dls_primary: {
            backgroundColor: theme["color-basic-60"],
            textColor: theme["color-basic-0"],
            touchableTextColor: theme["primary"],
        },
    }),
);

const sizeStyle = memoizeOne(
    (theme: Theme): SnackbarSizeStyle => ({
        minWidth: theme["size-tiny"],
        minHeight: theme["size-tiny"],
        paddingHorizontal: theme["space-medium"],
        paddingVertical: theme["space-small"],
        textMarginHorizontal: theme["space-x-small"],
        textFontSize: theme["text-body-3-regular-font-size"],
        textFontFamily: theme["text-body-3-regular-font-family"],
        textLineHeight: theme["text-body-3-regular-line-height"],
        touchableTextFontSize: theme["text-body-3-regular-font-size"],
        touchableTextFontFamily: theme["text-body-3-regular-font-family"],
        letterSpacing: theme["text-body-3-regular-letter-spacing"],
        iconWidth: 20,
        iconHeight: 20,
        flex: 1,
        bottom: 0,
        right: 0,
        left: 0,
    }),
);

export const getSnackbarStyles = (meta: SnackbarStyleMeta, theme: Theme): SnackbarStyle => {
    const color = meta.color ?? "primary";

    const { ...colorStyle } = colorStyleMap(theme)[color];

    return {
        ...sizeStyle(theme),
        ...colorStyle,
    };
};
