import { throttleFn } from "@swiggy-private/common-helpers";

import IntersectionObserverDefaultTypes, {
    type Element,
    type IntersectionObserverCallback,
    type IntersectionObserverOptions,
    type RootMargin,
} from "./typings/IntersectionObserver.d";

export const defaultRootMargin: RootMargin = {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
};

class IntersectionObserver implements IntersectionObserverDefaultTypes {
    // A function that will be called when the intersection status of the observed targets changes.
    callback: IntersectionObserverCallback;
    // An object that holds configuration options for the IntersectionObserver.
    options: IntersectionObserverOptions;
    // An array that holds the elements to be observed for intersection changes.
    targets: Element[];
    constructor(callback: IntersectionObserverCallback, options: IntersectionObserverOptions) {
        this.callback = callback;
        this.options = options;
        this.targets = [];
        this.options.root.onLayout = this.handleLayout;
        this.options.root.onScroll = this.handleScroll;
    }
    // The measureTarget method is a helper function used to measure the layout of a target element relative to the root node. This method is called to update the layout of targets when the root node's layout changes.
    measureTarget: IntersectionObserverDefaultTypes["measureTarget"] = (target) => {
        const rootNode = this.options.root.node;
        if (rootNode) {
            // eslint-disable-next-line max-params
            target.measureLayout(rootNode, (x, y, width, height) => {
                target.layout = {
                    x,
                    y,
                    width,
                    height,
                };
                this.handleScroll();
            });
        }
    };
    // The handleLayout method is a throttled function that is triggered when the root element's layout changes. It iterates through all the targets and measures their layout using the measureTarget function.
    handleLayout = throttleFn(() => {
        for (let index = 0; index < this.targets.length; index += 1) {
            this.measureTarget(this.targets[index]);
        }
    }, 400);
    // The handleScroll method is another throttled function triggered when the root element is scrolled. It calculates the intersection status of the targets based on the root element's scroll position and their layout.
    handleScroll = throttleFn(() => {
        const {
            horizontal,
            current: { contentOffset, contentSize, layoutMeasurement },
        } = this.options.root;
        if (
            contentSize.width <= 0 ||
            contentSize.height <= 0 ||
            layoutMeasurement.width <= 0 ||
            layoutMeasurement.height <= 0
        ) {
            return;
        }

        const changedTargets = [];
        for (let index = 0; index < this.targets.length; index += 1) {
            const target = this.targets[index];
            const targetLayout = target.layout;
            if (!targetLayout || targetLayout.width === 0 || targetLayout.height === 0) {
                continue;
            }
            let isIntersecting = false;

            const tempDistanceCoveredInX = targetLayout.x + targetLayout.width - contentOffset.x;
            const tempDistanceCoveredInY = targetLayout.y + targetLayout.height - contentOffset.y;

            const calculateVerticalDistance =
                tempDistanceCoveredInY <= layoutMeasurement.height &&
                targetLayout.y + targetLayout.height >= contentOffset.y &&
                tempDistanceCoveredInY > 0;
            if (horizontal) {
                isIntersecting =
                    tempDistanceCoveredInX <= layoutMeasurement.width &&
                    targetLayout.x + targetLayout.width >= contentOffset.x &&
                    tempDistanceCoveredInX > 0;
            } else {
                isIntersecting = calculateVerticalDistance;
            }

            if (target.inView !== isIntersecting) {
                target.inView = isIntersecting;
                changedTargets.push({
                    target,
                    isIntersecting,
                });
            }
        }
        this.callback(changedTargets);
    }, 700);
    // The observe method is used to add a target element to the list of observed elements. It attaches the handleLayout method to the target's onLayout event and adds the target to the targets array.
    observe: IntersectionObserverDefaultTypes["observe"] = (target) => {
        const index = this.targets.indexOf(target);
        if (index < 0) {
            target.onLayout = this.handleLayout;
            this.targets.push(target);
        }
    };
    // The unobserve method is used to remove a target element from the list of observed elements. It removes the target's onLayout event handler and removes the target from the targets array.
    unobserve: IntersectionObserverDefaultTypes["unobserve"] = (target) => {
        const index = this.targets.indexOf(target);
        if (index >= 0) {
            target.onLayout = undefined;
            this.targets.splice(index, 1);
        }
    };
}
export default IntersectionObserver;
