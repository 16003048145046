const webProtocols = ["https://", "http://"];

// Determines if a string is a Web URI
export const isWebUri = (uri: string): boolean => {
    return webProtocols.some((val) => uri.startsWith(val));
};

// Determines if a string is a Web or File URI
export const isUri = (uri: string): boolean => {
    return [...webProtocols, "file://", "data:image"].some((val) => uri.startsWith(val));
};

export const hasHttpUrlScheme = (link: string): boolean => {
    if (!link?.trim()) {
        return false;
    }

    const hasHttp = link.indexOf("http://") > -1;
    const hasHttps = link.indexOf("https://") > -1;

    if (!hasHttp && !hasHttps) {
        return false;
    }

    return true;
};
