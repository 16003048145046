import memoizeOne from "memoize-one";

import type { Theme } from "@swiggy-private/rn-dls-theme";
import type {
    ToggleColor,
    ToggleColorStyle,
    ToggleSizeStyle,
    ToggleState,
    ToggleStyle,
    ToggleStyleMeta,
} from "../interfaces/toggle";
import type { Nullable } from "../../../typings";

type ToggleColorWithStateStyle = Record<
    ToggleColor,
    {
        state: Partial<Record<ToggleState, Partial<ToggleColorStyle>>>;
    } & ToggleColorStyle
>;

const defaultSizeStyle = memoizeOne(
    (theme: Theme): ToggleSizeStyle => ({
        width: 50,
        height: 26,
        borderRadius: 20,
        borderWidth: theme["border-width"],

        thumbWidth: 20,
        thumbHeight: 20,
        thumbBorderRadius: 20,

        textMarginHorizontal: theme["space-small"],
        textFontFamily: theme["text-body-2-medium-font-family"],
        textFontSize: theme["text-body-2-medium-font-size"],
        textLetterSpacing: theme["text-body-2-medium-letter-spacing"],
        textLineHeight: theme["text-body-2-medium-line-height"],
    }),
);

const defaultColorStyle = memoizeOne(
    (theme: Theme): ToggleColorStyle => ({
        borderColor: theme["color-basic-30"],
        backgroundColor: theme["color-basic-30"],
        textColor: theme["color-basic-100"],
        thumbBackgroundColor: theme["color-basic-0"],
    }),
);

const colorStyleMap = memoizeOne(
    (theme: Theme): ToggleColorWithStateStyle => ({
        primary: {
            ...defaultColorStyle(theme),
            state: {
                checked: {
                    borderColor: theme.positive,
                    backgroundColor: theme.positive,
                },
            },
        },
        secondary: {
            ...defaultColorStyle(theme),
            state: {
                checked: {
                    borderColor: theme["color-secondary"],
                    backgroundColor: theme["color-secondary"],
                },
            },
        },
    }),
);

export const getToggleStyles = (meta: ToggleStyleMeta, theme: Theme): ToggleStyle => {
    const color = meta.color ?? "primary";
    const state: Nullable<ToggleState> = meta.checked
        ? "checked"
        : Array.isArray(meta.state)
        ? meta.state[0]
        : meta.state;

    const { state: stateStyle, ...colorStyle } = colorStyleMap(theme)[color];

    return {
        ...defaultSizeStyle(theme),
        ...colorStyle,
        ...(state ? stateStyle[state] ?? {} : {}),
    };
};
