import { getObjectValue, removeValue, setValue } from "@swiggy-private/common-helpers";

import type { ICustomerData, IFBAdsData, TPixelIDCollection } from "./interface";

const STORAGE_KEY_SELLER = "@facebookUser";
const STORAGE_KEY_CUSTOMERS = "@storeCustomers";
const STORAGE_KEY_PIXEL_COLLECTION = "@pixelIDCollections";

let facebookCache: Record<string, IFBAdsData> | null = null;
let storeCustomerCache: Record<string, ICustomerData> | null = null;
let pixelIDCollections: Record<string, TPixelIDCollection> | null = null;

/**
 * * sets Facebook user data
 *
 * @param {string} storeId
 * @param {IFBAdsData} user
 * @return {*}  {Promise<void>}
 */
export const setFacebookUser = async (storeId: string, user: IFBAdsData): Promise<void> => {
    if (facebookCache == null) {
        facebookCache = {};
    }

    facebookCache = {
        [storeId]: user,
    };

    return setValue(STORAGE_KEY_SELLER, facebookCache);
};

/**
 * * saves the given PixelCollection in storage.
 *
 * @param {string} storeId
 * @param {TPixelIDCollection} user
 * @return {*}  {Promise<void>}
 */
export const savePixelIDCollection = async (
    storeId: string,
    user: TPixelIDCollection,
): Promise<void> => {
    if (pixelIDCollections == null) {
        pixelIDCollections = {};
    }

    pixelIDCollections = {
        [storeId]: user,
    };

    return setValue(STORAGE_KEY_PIXEL_COLLECTION, pixelIDCollections);
};

/**
 * * saves the given StoreCustomer in storage.
 *
 * @param {string} storeId
 * @param {ICustomerData} user
 * @return {*}  {Promise<void>}
 */
export const setStoreCustomer = async (storeId: string, user: ICustomerData): Promise<void> => {
    if (storeCustomerCache == null) {
        storeCustomerCache = {};
    }
    storeCustomerCache = {
        [storeId]: user,
    };
    return setValue(STORAGE_KEY_CUSTOMERS, storeCustomerCache);
};

/**
 * * removes the FacebookUser from storage and cache.
 *
 * @param {string} [storeId]
 * @return {*}  {Promise<void>}
 */
export const removeFacebookUser = async (storeId?: string): Promise<void> => {
    if (storeId && facebookCache) {
        delete facebookCache[storeId];
        return setValue(STORAGE_KEY_SELLER, facebookCache);
    } else {
        facebookCache = null;
        return removeValue(STORAGE_KEY_SELLER);
    }
};

/**
 * * removes the PixelCollection from storage and cache.
 *
 * @param {string} [storeId]
 * @return {*}  {Promise<void>}
 */
export const removePixelIDCollection = async (storeId?: string): Promise<void> => {
    if (storeId && pixelIDCollections) {
        delete pixelIDCollections[storeId];
        return setValue(STORAGE_KEY_PIXEL_COLLECTION, pixelIDCollections);
    } else {
        pixelIDCollections = null;
        return removeValue(STORAGE_KEY_PIXEL_COLLECTION);
    }
};

/**
 * * removes the StoreCustomer from storage and cache.
 *
 * @param {string} [storeId]
 * @return {*}  {Promise<void>}
 */
export const removeStoreCustomer = async (storeId?: string): Promise<void> => {
    if (storeId && storeCustomerCache) {
        delete storeCustomerCache[storeId];
        return setValue(STORAGE_KEY_CUSTOMERS, storeCustomerCache);
    } else {
        storeCustomerCache = null;
        return removeValue(STORAGE_KEY_CUSTOMERS);
    }
};

/**
 * * gets the FacebookUser from storage and cache.
 *
 * @param {string} storeId
 * @return {*}  {(IFBAdsData | null)}
 */
export const getFacebookUser = (storeId: string): IFBAdsData | null =>
    facebookCache?.[storeId] || null;

/**
 * * gets the PixelCollection from storage and cache.
 *
 * @param {string} storeId
 * @return {*}  {(TPixelIDCollection | null)}
 */
export const retrievePixelIDCollection = (storeId: string): TPixelIDCollection | null =>
    pixelIDCollections?.[storeId] || null;

/**
 * * gets the StoreCustomer from storage and cache.
 *
 * @param {string} storeId
 * @return {*}  {(ICustomerData | null)}
 */
export const getStoreCustomer = (storeId: string): ICustomerData | null =>
    storeCustomerCache?.[storeId] || null;

/**
 * * gets all datas from cache to storage
 *
 * @return {*}  {Promise<void>}
 */
export const loadFacebookData = async (): Promise<void> => {
    facebookCache = await getObjectValue<Record<string, IFBAdsData>>(STORAGE_KEY_SELLER);
    storeCustomerCache = await getObjectValue<Record<string, ICustomerData>>(STORAGE_KEY_CUSTOMERS);
    pixelIDCollections = await getObjectValue<Record<string, TPixelIDCollection>>(
        STORAGE_KEY_PIXEL_COLLECTION,
    );
};
