import { IMAGEKIT_CLOUDNAME, IMAGEKIT_MEDIA_BASE_URL } from "@env";

import React, { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { SdkVideoMessagePayload } from "@swiggy-private/connect-chat-sdk";
import { Portal, SpacingValue } from "@swiggy-private/rn-dls";
import {
    ImageBackground,
    ImageStyle,
    Platform,
    StyleProp,
    StyleSheet,
    useWindowDimensions,
    View,
    ViewProps,
    ViewStyle,
} from "react-native";
import {
    CloudinaryContextProvider,
    getImageKitVideoUrl,
    useBackListener,
} from "@swiggy-private/react-native-ui";
import Video, { VideoProperties } from "react-native-video";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { ChatConversationContentContext } from "../../../conversation-view/components/content/context";

interface MessageViewVideoMessageProps extends SdkVideoMessagePayload {
    messageId: string;
    style?: ViewProps["style"];
    cloudName?: string;
    cloudFolderName?: string;
}

const MAX_VIDEO_SIZE = 240;

const VideoControl: React.FC<{ paused: boolean; onPress: () => void }> = ({ onPress, paused }) => {
    const style = !paused ? { opacity: 0 } : null;

    return (
        <View style={[styles.controlContainer, style]}>
            <SvgIcon
                width={40}
                height={40}
                icon={paused ? "Play" : "Paused"}
                onPress={onPress}
                style={styles.control}
            />
        </View>
    );
};

export const MessageViewVideoMessage: React.FC<MessageViewVideoMessageProps> = memo(
    ({ messageId, video, thumbnail, style }) => {
        const videoRef = useRef<Video>(null);
        const videoId = thumbnail || video;
        const videoSize = Math.min(useWindowDimensions().width * 0.65, MAX_VIDEO_SIZE);
        const onVisibilityChange = useContext(ChatConversationContentContext).onVisibilityChange;

        const [muted, setMuted] = useState(true);
        const [paused, setPaused] = useState(true);
        const [fullscreen, setFullscreen] = useState(false);
        const [showVideoControl, setShowVideoControl] = useState(Platform.OS !== "web");

        const videoUrl = useMemo(
            () =>
                getImageKitVideoUrl({
                    transformations: [{ qualityAuto: "eco", format: "auto" }],
                    id: videoId,
                    cloudBaseUrl: IMAGEKIT_MEDIA_BASE_URL,
                    cloudName: IMAGEKIT_CLOUDNAME,
                }),
            [videoId],
        );

        const thumbUrl = useMemo(
            () =>
                getImageKitVideoUrl({
                    transformations: [{ qualityAuto: "eco", crop: "fit" }],
                    id: videoId,
                    cloudBaseUrl: IMAGEKIT_MEDIA_BASE_URL,
                    cloudName: IMAGEKIT_CLOUDNAME,
                    shouldFetchThumbnail: true,
                }),
            [videoId],
        );

        const onPlay = useCallback(() => {
            if (Platform.OS === "android") {
                setFullscreen(true);
            }

            if (Platform.OS === "ios") {
                setShowVideoControl(false);
                setMuted(false);
                setPaused(false);
            }
        }, []);

        const onExit = useCallback(() => {
            setFullscreen(false);
            setPaused(true);
        }, []);

        const onVideoEnd = useCallback(() => setPaused(true), []);

        const canShowVideo = typeof videoId === "string" && videoId.length > 0 && videoUrl;

        useEffect(() => {
            const unsubscribe = onVisibilityChange(messageId, (_, isVisible) => {
                if (!isVisible) {
                    setPaused(true);
                    setMuted(true);
                }
            });

            return () => unsubscribe();
        }, [messageId, onVisibilityChange]);

        if (!canShowVideo) {
            return null;
        }

        const videoStyle: StyleProp<ImageStyle> = [
            styles.video,
            {
                width: videoSize,
                height: videoSize,
                backgroundColor: "#000",
            },
        ];

        return (
            <CloudinaryContextProvider>
                <View style={style}>
                    {Platform.OS !== "android" ? (
                        <Video
                            source={{ uri: videoUrl }}
                            resizeMode="contain"
                            poster={thumbUrl}
                            posterResizeMode="contain"
                            style={videoStyle}
                            muted={muted}
                            paused={paused}
                            controls={true}
                            onEnd={onVideoEnd}
                            onVideoEnd={onVideoEnd}
                            ignoreSilentSwitch="ignore"
                            allowsExternalPlayback={false}
                            repeat
                            ref={videoRef}
                        />
                    ) : (
                        <ImageBackground
                            source={{ uri: thumbUrl }}
                            style={videoStyle}
                            resizeMode="contain"
                        />
                    )}

                    {showVideoControl ? <VideoControl paused={paused} onPress={onPlay} /> : null}

                    {fullscreen ? (
                        <VideoFullScreen
                            source={{ uri: videoUrl }}
                            controls={true}
                            onClose={onExit}
                            ignoreSilentSwitch="ignore"
                            allowsExternalPlayback={false}
                            onEnd={onVideoEnd}
                            onError={onExit}
                            muted={muted}
                            paused={paused}
                            vRef={videoRef}
                            resizeMode="contain"
                            onLoad={() => {
                                videoRef.current?.seek(0);
                                videoRef.current?.presentFullscreenPlayer();
                                setPaused(false);
                                setMuted(false);
                            }}
                            repeat
                        />
                    ) : null}
                </View>
            </CloudinaryContextProvider>
        );
    },
);

const VideoFullScreen: React.FC<
    VideoProperties & { onClose: () => void; vRef?: React.RefObject<Video> }
> = ({ onClose, vRef, ...props }) => {
    const videoStyle = {
        flex: 1,
        backgroundColor: "#000",
    };

    useBackListener(
        useCallback(() => {
            onClose();
            return true;
        }, [onClose]),
    );

    const closeIcon: StyleProp<ViewStyle> = {
        position: "absolute",
        top: SpacingValue["space-x-large"],
        left: SpacingValue["space-medium"],
        zIndex: 1,
    };

    return (
        <Portal>
            <Video {...props} style={videoStyle} ref={vRef} />
            <View style={closeIcon}>
                <SvgIcon
                    color="#fff"
                    icon="Close"
                    width={16}
                    height={16}
                    onPress={onClose}
                    hitSlop={{ top: 16, left: 16, right: 16, bottom: 16 }}
                />
            </View>
        </Portal>
    );
};

const controlContainerSize = 74;

const styles = StyleSheet.create({
    video: {
        borderRadius: 16,
    },
    text: {
        marginTop: SpacingValue["space-small"],
    },
    controlContainer: {
        width: controlContainerSize,
        height: controlContainerSize,
        opacity: 0.7,
        backgroundColor: "#fff",
        position: "absolute",
        borderRadius: controlContainerSize,
        left: "50%",
        top: "50%",
        transform: [
            { translateY: -controlContainerSize / 2 },
            { translateX: -controlContainerSize / 2 },
        ],
        alignItems: "center",
        justifyContent: "center",
    },
    control: {
        marginLeft: SpacingValue["space-xxx-small"],
    },
    audioControlContainer: {
        position: "absolute",
        right: SpacingValue["space-small"],
        top: SpacingValue["space-small"],
    },
});

if (process.env.NODE_ENV !== "production") {
    MessageViewVideoMessage.displayName = "MessageViewVideoMessage";
}
