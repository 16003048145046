import { useEffect, useState } from "react";

import type { DebounceOptions } from "../interfaces/debounce-options";
import { useDebounceFn } from "./use-debounce-fn";

/**
 * A hook that handle the debounce value.
 *
 * @param value value that requires debounce
 * @param options Config the debounce behavior
 */
export const useDebounce = <T>(value: T, options?: DebounceOptions): T => {
    const [debounced, setDebounced] = useState(value);
    const debouncedFn = useDebounceFn(() => setDebounced(value), options);

    useEffect(() => {
        debouncedFn();
    }, [debouncedFn, value]);

    return debounced;
};
