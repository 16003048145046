import { Coupon, CouponDeliveryType } from "@swiggy-private/connect-chat-commons";

import { CITY_NAME_CHARACTER_LIMIT, DELIVERY_TYPE_COPIES } from "../constants/coupon";

interface CouponTitleDescription {
    title: string;
    description: string;
    code: string;
    segmentInfo: string;
}

const couponSegmentMessages = {
    NEW_USER: "Can be availed by new customers",
    PURCHASED: "Can be availed by repeat customers",
    ABANDONED_CART: "Can be availed by customers with abandoned cart",
    FAVOURITED: "Can be availed by customers who have followed your store",
};

const getTitle = (coupon: Omit<Coupon, "id" | "active">): string => {
    if (coupon.type === "DISCOUNT_TYPE_FREE_DELIVERY") {
        return "FREE DELIVERY";
    }

    if (coupon.discountPercent) {
        return `${coupon.discountPercent}% OFF`;
    }

    if (coupon.maxDiscountAmount) {
        return `₹${coupon.maxDiscountAmount} OFF`;
    }

    return "-";
};

const getDescription = (coupon: Omit<Coupon, "id" | "active">, city?: string | null): string => {
    let description = coupon.minOrderValue
        ? `On all orders with a minimum order value of ₹${coupon.minOrderValue}`
        : "On all orders";

    const deliveryType = coupon.deliveryType ?? CouponDeliveryType.INTER;

    if (coupon.discountPercent && coupon.maxDiscountAmount) {
        description += ` | Upto ₹${coupon.maxDiscountAmount}`;
    }

    if (coupon.type === "DISCOUNT_TYPE_FREE_DELIVERY") {
        const cityName =
            city && city.length > CITY_NAME_CHARACTER_LIMIT
                ? city.slice(0, CITY_NAME_CHARACTER_LIMIT) + "..."
                : city ?? "city";

        description = `Free delivery ${DELIVERY_TYPE_COPIES[
            deliveryType
        ].toLowerCase()} ${cityName} ${
            coupon.minOrderValue ? `on orders above ₹${coupon.minOrderValue}` : "on all orders"
        }`;
    }

    return description;
};

export const getSegmentInfo = (coupon: Omit<Coupon, "id" | "active">): string => {
    if (!coupon.segments?.length) {
        return "Can be availed by all customers";
    }

    const info = couponSegmentMessages[coupon.segments[0]];
    return info;
};

export const getCouponData = (
    coupon: Omit<Coupon, "id" | "active">,
    city?: string | null,
): CouponTitleDescription => {
    return {
        title: getTitle(coupon),
        description: getDescription(coupon, city),
        code: coupon?.code,
        segmentInfo: getSegmentInfo(coupon),
    };
};

export const truncateString = (val: string, limit: number): string => {
    if (val.length > limit) {
        return val.slice(0, limit) + "...";
    } else {
        return val;
    }
};
