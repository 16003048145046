import React, { useCallback } from "react";
import { Platform, StyleProp, ViewStyle } from "react-native";
import Video from "react-native-video";
import { useFocusEffect } from "@react-navigation/native";

import { TEST_ID } from "../constants";

const isIOS = Platform.OS === "ios";

interface VideoPreviewMainComponentProps {
    videoUrl: string;
    thumbUrl: string;
    videoStyle: StyleProp<ViewStyle>;
    videoRef: React.RefObject<Video>;
    doPause: VoidFunction;

    muted?: boolean;
    paused?: boolean;
    onVideoEnd?: VoidFunction;
    onMediaLoadStart?: VoidFunction;
    onMediaLoadEnd?: VoidFunction;
}

const VideoPreviewMainComponent: React.FC<VideoPreviewMainComponentProps> = (props) => {
    const {
        videoUrl,
        thumbUrl,
        videoStyle,
        muted,
        paused,
        onVideoEnd,
        onMediaLoadStart,
        onMediaLoadEnd,
        doPause,
        videoRef,
    } = props;

    useFocusEffect(
        useCallback(() => {
            return () => {
                doPause();
            };
        }, [doPause]),
    );

    return (
        <Video
            testID={TEST_ID.VIDEO_PREVIEW}
            source={{ uri: videoUrl }}
            resizeMode="contain"
            poster={thumbUrl}
            posterResizeMode="contain"
            style={videoStyle}
            muted={muted}
            paused={paused}
            onEnd={onVideoEnd}
            onVideoEnd={onVideoEnd}
            onLoadStart={onMediaLoadStart}
            onReadyForDisplay={onMediaLoadEnd}
            ignoreSilentSwitch="ignore"
            fullscreen={isIOS}
            allowsExternalPlayback={false}
            ref={videoRef}
            repeat
        />
    );
};

export const VideoPreviewMain = React.memo(VideoPreviewMainComponent);
