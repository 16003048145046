import PubNub from "pubnub";
import { Endpoint, HttpClient } from "@swiggy-private/http-client";

import {
    Provider,
    ProviderConfig,
    ProviderFetchMessagesParams,
    ProviderFetchMessagesResponse,
    ProviderSendMessageParams,
    ProviderSendSignalParams,
    ProviderSubscribeParams,
    ProviderStatus,
    ProviderAddListenerParams,
    ProviderUnsubscribeParams,
    ProviderRegisterDeviceForPNParams,
    ProviderUnregisterDeviceForPNParams,
    ProviderGetOnlineUsersParams,
    ProviderGetOnlineUsersResponse,
    ProviderAddMessageActionParams,
    ProviderAddMessageActionResponse,
} from "../../interfaces/provider";
import { assert } from "../../helpers/assert";
import { ProviderError } from "./error";

const DEVICE_TOKEN_CHANNEL_ID = "global-channel-pn";
const MAX_SUBSCRIBED_CHANNELS = 20;
const PUBNUB_BASE_URI = "https://ps.pndsn.com";

export class PubnubProvider implements Provider {
    private pubnub: PubNub;
    private httpClient: HttpClient<object>;
    private config: ProviderConfig;
    private authToken: string | null;
    private disconnected = false;

    // @see https://www.pubnub.com/docs/sdks/javascript/api-reference/configuration
    constructor(config: ProviderConfig) {
        this.authToken = null;
        this.pubnub = new PubNub({
            subscribeKey: config.subscribeKey,
            publishKey: config.publishKey,
            presenceTimeout: 60,
            restore: true,
            keepAlive: true,
            uuid: config.uuid ?? "0",
            requestMessageCountThreshold: 200,
            heartbeatInterval: 15,
            logVerbosity: config.logVerbosity,
        });

        if (config.authToken) {
            this.pubnub.setToken(config.authToken);
            this.authToken = config.authToken;
        }

        this.config = config;
        this.httpClient = new HttpClient();
    }

    async getOnlineUsers(
        params: ProviderGetOnlineUsersParams,
    ): Promise<ProviderGetOnlineUsersResponse> {
        if (!this.authToken || this.disconnected) {
            return {};
        }

        try {
            const endpoint = Endpoint.from(this.getPubnubRequestUri("get-online-status"), {
                subscribeKey: this.config.subscribeKey,
            });

            const response = (await this.httpClient.post({
                endpoint,
                body: {
                    accessToken: this.authToken,
                    uuid: params.uuids,
                },
                json: true,
            })) as ProviderGetOnlineUsersResponse;

            return response;
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async sendUserOnlineStatus(params: { online: boolean }): Promise<void> {
        if (!this.authToken || this.disconnected) {
            return;
        }

        try {
            const endpoint = Endpoint.from(this.getPubnubRequestUri("save-online-status"), {
                subscribeKey: this.config.subscribeKey,
            });

            await this.httpClient.post({
                endpoint,
                body: {
                    accessToken: this.authToken,
                    online: params.online,
                },
                json: true,
            });
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async registerDeviceForPushNotifications(
        params: ProviderRegisterDeviceForPNParams,
    ): Promise<void> {
        if (!this.authToken || this.disconnected) {
            return;
        }

        try {
            const endpoint = Endpoint.from(this.getPubnubRequestUri("device-register"), {
                subscribeKey: this.config.subscribeKey,
            });

            await this.httpClient.post({
                endpoint,
                body: {
                    channel: DEVICE_TOKEN_CHANNEL_ID,
                    message: {
                        ...params,
                        operation: "add",
                    },
                    accessToken: this.authToken,
                    uuid: this.getUUID(),
                },
                json: true,
            });
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async unregisterDeviceForPushNotifications({
        token,
    }: ProviderUnregisterDeviceForPNParams): Promise<void> {
        if (!this.authToken || this.disconnected) {
            return;
        }

        try {
            const endpoint = Endpoint.from(this.getPubnubRequestUri("device-register"), {
                subscribeKey: this.config.subscribeKey,
            });

            await this.httpClient.post({
                endpoint,
                body: {
                    channel: DEVICE_TOKEN_CHANNEL_ID,
                    message: {
                        operation: "delete",
                        token,
                    },
                    accessToken: this.authToken,
                    uuid: this.getUUID(),
                },
                json: true,
            });
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async setConversationLastReadTime(conversationId: string): Promise<void> {
        if (!this.authToken || this.disconnected) {
            return;
        }

        try {
            const endpoint = Endpoint.from(this.getPubnubRequestUri("save-last-read-time"), {
                subscribeKey: this.config.subscribeKey,
            });

            const timetoken = String((await this.pubnub.time()).timetoken);

            await this.httpClient.post({
                endpoint,
                body: {
                    channel: conversationId,
                    accessToken: this.authToken,
                    uuid: this.getUUID(),
                    timetoken,
                },
                json: true,
            });
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async getConversationsUnreadCount(
        conversationsIds: string[],
        conversationTimetokens: Record<string, string>,
    ): Promise<Record<string, number>> {
        if (!this.authToken || this.disconnected) {
            return {};
        }

        try {
            const endpoint = Endpoint.from(this.getPubnubRequestUri("unread-count"), {
                subscribeKey: this.config.subscribeKey,
            });

            const response = (await this.httpClient.post({
                endpoint,
                body: {
                    channels: conversationsIds,
                    accessToken: this.authToken,
                    uuid: this.getUUID(),
                    channelTimetokens: conversationTimetokens,
                },
                json: true,
            })) as Record<string, object>;

            if (!response || typeof response !== "object" || !response?.data) {
                return {};
            }

            return response.data as Record<string, number>;
        } catch (err) {
            throw toProviderError(err);
        }
    }

    unsubscribe(params: ProviderUnsubscribeParams): void {
        this.pubnub.unsubscribe({
            channels: params.conversationIds ?? [],
        });
    }

    getSubscribedConversations(): string[] {
        return this.pubnub.getSubscribedChannels();
    }

    setAuthToken(token: string): void {
        assert(token.length > 0);

        this.pubnub.setToken(token);
        this.authToken = token;
    }

    setUUID(id: string): void {
        assert(id.length > 0);
        this.pubnub.setUUID(id);
    }

    getUUID(): string {
        return this.pubnub.getUUID();
    }

    subscribe(params: ProviderSubscribeParams): void {
        if (!this.authToken || !params.conversationIds || !params.conversationIds.length) {
            return;
        }

        const currentSubscribedChannels = this.pubnub.getSubscribedChannels();
        if (currentSubscribedChannels.length > MAX_SUBSCRIBED_CHANNELS) {
            this.unsubscribe({
                conversationIds: currentSubscribedChannels.slice(0, params.conversationIds.length),
            });
        }

        this.pubnub.subscribe({
            channels: params.conversationIds,
            withPresence: true,
            timetoken: params.timetoken ? Number(params.timetoken) : undefined,
        });
    }

    unsubscribeAll(): void {
        this.pubnub.unsubscribeAll();
    }

    stop(): void {
        this.disconnected = true;
        this.pubnub.stop();
    }

    reconnect(): void {
        this.disconnected = false;
        this.pubnub.reconnect();
    }

    disconnect(): void {
        this.disconnected = true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.pubnub.disconnect();
    }

    async sendMessage(params: ProviderSendMessageParams): Promise<string | number> {
        try {
            const response = await this.pubnub.publish({
                message: params.message,
                channel: params.conversationId,
                storeInHistory: true,
                ttl: 0,
                sendByPost: true,
                meta: params.meta,
            });

            return response.timetoken;
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async addMessageAction(
        params: ProviderAddMessageActionParams,
    ): Promise<ProviderAddMessageActionResponse> {
        try {
            return await this.pubnub.addMessageAction({
                ...params,
                channel: params.conversationId,
            });
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async sendSignal(params: ProviderSendSignalParams): Promise<string | number> {
        try {
            const response = await this.pubnub.signal({
                channel: params.conversationId,
                message: params.message,
            });
            return response.timetoken;
        } catch (err) {
            throw toProviderError(err);
        }
    }

    async fetchMessages(
        params: ProviderFetchMessagesParams,
    ): Promise<ProviderFetchMessagesResponse> {
        if (!this.authToken || !params.conversationIds || !params.conversationIds.length) {
            return { conversations: {} };
        }

        const includeMessageActions = params.conversationIds.length <= 1;

        try {
            const { channels } = await this.pubnub.fetchMessages({
                includeMessageActions,
                channels: params.conversationIds,
                count: params.count ?? 25,
                stringifiedTimeToken: true,
                includeUUID: true,
                includeMeta: true,
                includeMessageType: true,
                start: params.startTimetoken,
                end: params.endTimetoken,
            });

            return Object.entries(channels).reduce(
                (acc, [id, messages]) => {
                    if (!Array.isArray(acc.conversations[id])) {
                        acc.conversations[id] = [];
                    }

                    const providerMessages = messages
                        .filter((m) => m.channel && m.uuid && m.timetoken && m.message)
                        .map((m) => {
                            return {
                                timetoken: m.timetoken,
                                message: m.message,
                                publisher: m.uuid ?? "",
                                meta: m.meta,
                                actions: m.actions,
                            };
                        });

                    acc.conversations[id] = providerMessages;

                    return acc;
                },
                { conversations: {} } as ProviderFetchMessagesResponse,
            );
        } catch (err) {
            throw toProviderError(err);
        }
    }

    addListener(params: ProviderAddListenerParams): () => void {
        const listenerParams: PubNub.ListenerParameters = {
            signal: (event) => {
                params.signal?.({
                    conversationId: event.channel,
                    timetoken: event.timetoken,
                    publisher: event.publisher,
                    message: event.message,
                });
            },
            status: (event) => {
                params.status?.({
                    lastTimetoken: event.lastTimetoken,
                    currentTimetoken: event.currentTimetoken,
                    affectedConversations: event.affectedChannels,
                    status: toProviderStatus(event.category as keyof PubNub.Categories),
                    operation: event.operation,
                    subscribedConversations: event.subscribedChannels,
                });
            },
            message: (event) => {
                params.message?.({
                    message: event.message,
                    publisher: event.publisher,
                    conversationId: event.channel,
                    timetoken: event.timetoken,
                });
            },
            presence: (event) => {
                if (
                    !(
                        event.action === "join" ||
                        event.action === "leave" ||
                        event.action === "timeout"
                    )
                ) {
                    return;
                }

                params.presence?.({
                    conversationId: event.channel,
                    timestamp: event.timestamp,
                    timetoken: event.timetoken,
                    uuid: event.uuid,
                    action: event.action,
                });
            },
            messageAction: (event) => {
                params.messageAction?.({
                    conversationId: event.channel,
                    timetoken: event.timetoken,
                    action: event.data,
                });
            },
        };

        this.pubnub.addListener(listenerParams);

        return () => this.pubnub.removeListener(listenerParams);
    }

    private getPubnubRequestUri = (path: string): `${string}{%%subscribeKey%%}${string}` => {
        return `${PUBNUB_BASE_URI}/v1/blocks/sub-key/{%%subscribeKey%%}/${path}`;
    };
}

function isPubnubStatus(status: unknown): status is PubNub.PubnubStatus {
    return typeof (status as PubNub.PubnubStatus).operation !== "undefined";
}

type PubnubError = { name: string; status: PubNub.PubnubStatus };

function toProviderError(error: unknown): ProviderError {
    if (error && (error as PubnubError).name !== "PubNubError") {
        return new ProviderError(
            "operation failed due to unknown reasons: " + JSON.stringify(error),
        );
    }

    const pubnubStatus = (error as PubnubError).status;
    if (!isPubnubStatus(pubnubStatus) || !pubnubStatus.error) {
        return new ProviderError(
            "operation failed due to unknown reasons: " + JSON.stringify(pubnubStatus),
        );
    }

    const status = pubnubStatus.category
        ? toProviderStatus(pubnubStatus.category as keyof PubNub.Categories)
        : ProviderStatus.Unknown;

    let message = `${pubnubStatus.operation}:${status}`;

    if (pubnubStatus.errorData instanceof Error) {
        message += ` ${pubnubStatus.errorData.message}`;
    }

    return new ProviderError(message, status);
}

function toProviderStatus(category: keyof PubNub.Categories): ProviderStatus {
    switch (category) {
        case "PNAccessDeniedCategory":
            return ProviderStatus.AccessDeniedCategory;
        case "PNBadRequestCategory":
            return ProviderStatus.BadRequestCategory;
        case "PNNetworkUpCategory":
            return ProviderStatus.NetworkUpCategory;
        case "PNNetworkDownCategory":
            return ProviderStatus.NetworkDownCategory;
        case "PNNetworkIssuesCategory":
            return ProviderStatus.NetworkIssuesCategory;
        case "PNTimeoutCategory":
            return ProviderStatus.TimeoutCategory;
        case "PNReconnectedCategory":
            return ProviderStatus.ReconnectedCategory;
        case "PNConnectedCategory":
            return ProviderStatus.ConnectedCategory;
        case "PNRequestMessageCountExceedCategory":
            return ProviderStatus.RequestMessageCountExceededCategory;
        case "PNMalformedResponseCategory":
            return ProviderStatus.MalformedResponseCategory;
        case "PNUnknownCategory":
        default:
            return ProviderStatus.Unknown;
    }
}
