import React, { ReactNode } from "react";
import {
    FlexAlignType,
    StyleSheet,
    View,
    FlexStyle,
    StyleProp,
    ViewStyle,
    ViewProps,
} from "react-native";
import { useTheme } from "@swiggy-private/rn-dls";

interface Props extends ViewProps {
    children?: ReactNode;
    flex?: number;
    mainAxisAlignment?: FlexStyle["justifyContent"];
    crossAxisAlignment?: FlexAlignType;
    style?: StyleProp<ViewStyle>;
    testID?: string;
}

export const Column: React.FC<Props> = ({
    children,
    mainAxisAlignment = "flex-start",
    crossAxisAlignment = "flex-start",
    flex = 1,
    style,
    testID,
    ...props
}) => {
    const theme = useTheme();
    const bgColor = theme.value["color-background-primary"];

    return (
        <View
            testID={testID}
            style={[
                styles.column,
                { flex: flex },
                { justifyContent: mainAxisAlignment },
                { alignItems: crossAxisAlignment },
                { backgroundColor: bgColor },
                style,
            ]}
            {...props}>
            {children}
        </View>
    );
};

const styles = StyleSheet.create({
    column: {
        flex: 1,
        flexDirection: "column",
    },
});
