import { IMAGEKIT_MEDIA_BASE_URL, IMAGEKIT_CLOUDNAME } from "@env";

import * as React from "react";
import { Image, ImageProps, Platform, StyleSheet, View } from "react-native";

import { ActivityIndicator } from "@swiggy-private/rn-dls";
import { useConstructor } from "@swiggy-private/react-hooks";

import { ImageKitTransformation } from "../../helpers/imageKit";
import { ImagekitTransformation, ImagekitTransformationProps } from "../imagekit-transformation";

import { formatImageId, getIKImageUrl } from "./helpers";

type Children = React.ReactElement<ImagekitTransformationProps, typeof ImagekitTransformation>;

export interface IKImageProps extends Omit<ImageProps, "source"> {
    id: string;
    width?: number;
    height?: number;
    resourceType?: "upload" | "youtube";
    children?: Children | Children[];
    fallback?: boolean;
    showLoader?: boolean;
    optimizedDpr?: boolean;
    format?: ImageKitTransformation["format"];
    startPerfMarker?: VoidFunction;
    stopPerfMarker?: VoidFunction;
}

export const IKImage: React.FC<IKImageProps> & {
    Component: React.FC<ImageProps> & typeof Image;
    ForceCache: boolean;
} = ({
    id,
    resizeMode = "cover",
    width,
    height,
    style,
    children,
    resourceType: cloudResourceType = "upload",
    showLoader = false,
    optimizedDpr = false,
    onLoadStart,
    onLoadEnd,
    startPerfMarker,
    stopPerfMarker,
    format,
    ...props
}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    // This is for old assets
    const formattedId = formatImageId(id);

    const imageUrl = React.useMemo(
        () =>
            getIKImageUrl({
                id: formattedId,
                cloudBaseUrl: IMAGEKIT_MEDIA_BASE_URL,
                cloudName: IMAGEKIT_CLOUDNAME,
                cloudResourceType,
                height,
                width,
                format,
                children,
                optimizedDpr,
            }),
        [children, cloudResourceType, height, formattedId, width, format, optimizedDpr],
    );

    const onLoadStartCallback: ImageProps["onLoadStart"] = React.useCallback(() => {
        showLoader && setIsLoading(true);

        onLoadStart?.();
    }, [onLoadStart, showLoader]);

    const onLoadEndCallback: ImageProps["onLoadEnd"] = React.useCallback(() => {
        showLoader && setIsLoading(false);

        onLoadEnd?.();
        stopPerfMarker?.();
    }, [onLoadEnd, stopPerfMarker, showLoader]);

    useConstructor(() => {
        startPerfMarker?.();
    });

    if (Platform.OS === "android") {
        props.resizeMethod = "resize";
    }

    if (!id) {
        return null;
    }

    const image = (
        <IKImage.Component
            {...props}
            style={[{ width, height }, style]}
            resizeMode={resizeMode}
            source={{ uri: imageUrl, cache: IKImage.ForceCache ? "force-cache" : undefined }}
            onLoadStart={onLoadStartCallback}
            onLoadEnd={onLoadEndCallback}
        />
    );

    if (!showLoader) {
        return image;
    }

    return (
        <View>
            {image}
            {showLoader && isLoading ? <ActivityIndicator style={styles.loader} /> : null}
        </View>
    );
};

const styles = StyleSheet.create({
    loader: {
        ...StyleSheet.absoluteFillObject,
        justifyContent: "center",
        alignItems: "center",
    },
});

IKImage.ForceCache = false;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
IKImage.Component = Image;
