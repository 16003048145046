import { SdkMessageEvent } from "@swiggy-private/connect-chat-sdk";
import { useCallback } from "react";

import { useChatListener } from "./use-chatlistener";
import { useChatUserId } from "./use-chatuserid";

export const useChatMessageListener = (listener: (event: SdkMessageEvent) => void): void => {
    const userId = useChatUserId();

    const onMessage = useCallback(
        (e: SdkMessageEvent) => {
            if (e.publisher !== userId) {
                return listener(e);
            }
        },
        [listener, userId],
    );

    useChatListener({
        message: onMessage,
    });
};
