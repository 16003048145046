import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import * as React from "react";
import { ViewProps } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

interface BreadcrumbsProps extends ViewProps {
    separator?: string | React.ReactNode | React.FC<{ index: number }>;
    maxItems?: number;
}

export const Breadcrumbs: React.FC<React.PropsWithChildren<BreadcrumbsProps>> = ({
    children,
    separator = <SvgIcon icon="ChevronRight" width={16} height={16} />,
    maxItems = 3,
    ...props
}) => {
    const childrens = React.useMemo(
        () => React.Children.toArray(children).filter(React.isValidElement).slice(-maxItems),
        [children, maxItems],
    );

    if (!childrens.length) {
        return null;
    }

    return (
        <Stack
            alignItems="center"
            direction="row"
            aria-label="breadcumb"
            spacing={SpacingValue["space-x-small"]}
            divider={separator}
            dividerSpacing
            {...props}>
            {childrens}
        </Stack>
    );
};
