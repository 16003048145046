import { IMetaEvents, TFBAdsMetaResponse, TPixelIDCollection, TUserDeviceData } from "./interface";

/**
 * Creates na alpha-num ID based on text-string provided
 *
 * @param {string} salt
 * @return {*}  {((text: string) => string)}
 */
const cipher = (salt: string): ((text: string) => string) => {
    const textToChars = (text: string): number[] =>
        text.split("").map((c: string) => c.charCodeAt(0));
    const byteHex = (n: number): string => ("0" + Number(n).toString(16)).substr(-2);

    const applySaltToChar = (code: number[]): number =>
        // eslint-disable-next-line no-bitwise
        textToChars(salt).reduce((a: number, b: number) => a ^ b, code as unknown as number);

    return (text: string): string =>
        text.split("").map(textToChars).map(applySaltToChar).map(byteHex).join("");
};

/**
 * Adds device related to FB Graph API
 *
 * @param {TUserDeviceData} [userDeviceData]
 * @return {*}  {({ app_data: { extinfo: string[] } } | null)}
 */
export const appendAppEvents = (
    userDeviceData?: TUserDeviceData,
): { app_data: { extinfo: string[] } } | null =>
    ["android", "ios"].includes(userDeviceData?.platform || "")
        ? {
              app_data: {
                  extinfo: [
                      userDeviceData?.platform === "android" ? "a2" : "i2",
                      "com.swiggy",
                      "1.0",
                      "1.0",
                      userDeviceData?.osVersion || "",
                      userDeviceData?.osName || "",
                      "En_US",
                      "IST",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "Asia/Kolkata",
                  ],
              },
          }
        : null;

/**
 * helps us retrieve event-id based passed parameters to avoid de-duplication of events
 *
 * @param {IMetaEvents} { userDeviceData, eventName, customData }
 * @return {*}  {string}
 */
export const deDuplicationId = ({ userDeviceData, eventName, customData }: IMetaEvents): string => {
    const randomData = JSON.stringify({ userDeviceData, eventName, customData });

    // * create a cipher
    const myCipher = cipher("fb_meta_event");

    // * cipher accumulated data for a unique event_id
    return myCipher(randomData);
};

/**
 * Pushes available FB Pixel-IDs to a collection
 *
 * @param {{ data: TFBAdsMetaResponse }} resp
 */
export const pushToCollection = (
    resp: { data: TFBAdsMetaResponse },
    arrayList: TPixelIDCollection,
): TPixelIDCollection => {
    const tempArray = [...arrayList];

    (resp?.data as TFBAdsMetaResponse)?.forEach((val) => {
        if (val?.adspixels && val?.adspixels?.data) {
            val?.adspixels?.data.forEach((valI) => {
                if (valI?.id) {
                    tempArray.push({ id: valI?.id, label: valI?.name || "" });
                }
            });
        }
    });
    return tempArray;
};

/**
 * Elimiates redundant pixel-collection
 *
 * @param {TPixelIDCollection} arrayWithDuplicates
 * @return {*}  {TPixelIDCollection}
 */
export const uniquePixelCollection = (
    arrayWithDuplicates: TPixelIDCollection,
): TPixelIDCollection =>
    arrayWithDuplicates.reduce(
        (accumulator: TPixelIDCollection, currentObject: TPixelIDCollection[0]) => {
            const duplicateObject = accumulator.find((obj) => obj.id === currentObject.id);
            if (!duplicateObject) {
                accumulator.push(currentObject);
            }
            return accumulator;
        },
        [],
    );

// * Error Codes for FaceBook Graph API
export const ERROR_FB_MESSAGE: Record<number, string> = {
    80004: "Access token expired, please login again",
    460: "Password has been modified, please login again",
    463: "Access token expired, please login again",
    190: "Access token expired, please login again",
    368: "Account seems blocked, please visit facebook helpcenter",
    10: "Few Permissions are missing/denied, please login again",
};
