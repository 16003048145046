import { useEffect } from "react";

import { useChatSdk } from "./use-chatsdk";

export const useSubscribeConversation = (conversationIds: string | string[]): void => {
    const chatSdk = useChatSdk();

    useEffect(() => {
        if (conversationIds && chatSdk) {
            const ids = !Array.isArray(conversationIds) ? [conversationIds] : conversationIds;
            chatSdk.subscribe({
                conversationIds: ids,
            });
        }
    }, [chatSdk, conversationIds]);
};
