import { DPEvent } from "../interfaces/event";
import { Endpoint, HttpCallParams, HttpClient } from "@swiggy-private/http-client";

export interface DpClientConfig {
    endpoint: string;
    appName: string;
}

const __DEV__ = process.env.NODE_ENV !== "production";

type DpClientHttpResponse = {
    statusCode: number;
    message: string;
    error?: string;
};

type DpClientMessage = {
    header: {
        eventId: number;
        uuid: string;
        appName: string;
        timestamp: number;
        schemaVersion: string;
        name: string;
    };
    event: Record<string, unknown>;
};

const loggingInterceptor = (params: HttpCallParams): HttpCallParams => {
    __DEV__ && console.debug("sending dp events", params.endpoint);
    return params;
};

export class DpClient {
    private httpClient: HttpClient<DpClientHttpResponse>;

    private endpoint: string;
    private appName: string;

    constructor(config: DpClientConfig) {
        this.appName = config.appName;
        this.endpoint = config.endpoint;

        this.httpClient = new HttpClient({
            timeout: 5_000,
            requestInterceptors: [loggingInterceptor],
        });
    }

    public async sendEvent(
        events: DPEvent[],
        timeout?: number,
    ): Promise<DpClientHttpResponse | null> {
        if (!events.length) {
            return null;
        }

        const message: DpClientMessage[] = events.map((e) => ({
            header: {
                appName: e.appName ?? this.appName,
                eventId: e.eventId,
                schemaVersion: e.schemaVersion,
                timestamp: Date.now(),
                name: e.eventName,
                uuid: e.sessionId,
            },
            event: e.event,
        }));

        return this.httpClient.post({
            endpoint: Endpoint.from(`${this.endpoint}/message-set`),
            body: message,
            timeout: timeout,
        });
    }
}
