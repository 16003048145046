import React, { useCallback } from "react";
import { StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import memoizeOne from "memoize-one";

import { useDLS } from "../../styles/style-service";
import { FalsyText } from "../../support/falsy-text";
import { TouchableComponent, TouchableComponentProps } from "../../support/touchable-component";
import { useEventHandlers } from "../../support/use-event-handlers";

import type { RadioButtonColor, RadioButtonStyle } from "../../styles/interfaces/radio-button";
import type { RenderProp } from "../../support/falsy-fc";
import type { TextProps } from "../text";

export interface RadioButtonProps extends Omit<TouchableComponentProps, "children"> {
    onChange?: (checked: boolean) => void;
    checked?: boolean;
    color?: RadioButtonColor;
    children?: RenderProp<TextProps> | string | number;
}

export type RadioButtonElement = React.ReactElement<RadioButtonProps>;

const getComponentStyle = memoizeOne(
    (
        source: RadioButtonStyle,
    ): {
        text: TextStyle;
        icon: ViewStyle;
        selectContainer: ViewStyle;
    } => {
        const {
            textColor,
            textFontFamily,
            textFontSize,
            textMarginHorizontal,
            textLineHeight,
            letterSpacing,
            iconBorderRadius,
            iconHeight,
            iconTintColor,
            iconWidth,
            ...containerStyle
        } = source;
        return {
            text: {
                letterSpacing,
                color: textColor,
                fontFamily: textFontFamily,
                fontSize: textFontSize,
                marginLeft: textMarginHorizontal,
                textAlignVertical: "center",
                lineHeight: textLineHeight,
            },
            icon: {
                borderRadius: iconBorderRadius,
                height: iconHeight,
                width: iconWidth,
                backgroundColor: iconTintColor,
            },
            selectContainer: containerStyle,
        };
    },
);

/**
 * Radio buttons allow the user to select one option from a set.
 *
 * ## Usage
 * ```ts
 * import * as React from "react";
 * import { RadioButton } from "@swiggy-private/rn-dls";
 *
 * const MyComponent: React.FC = () => {
 *   return <RadioButton checked>Label</RadioButton>;
 * };
 *```
 */
export const RadioButton: React.FC<RadioButtonProps> = (props) => {
    const { checked = false, children, color, onChange, style, ...otherProps } = props;
    const dls = useDLS("radio", { checked, color });
    const dlsStyle = getComponentStyle(dls.style);
    const eventHandlers = useEventHandlers(props, dls.dispatch, props.disabled);

    const onPress = useCallback((): void => {
        onChange && onChange(!checked);
    }, [onChange, checked]);

    return (
        <TouchableComponent
            accessibilityRole="radio"
            {...otherProps}
            {...eventHandlers}
            onPress={onPress}
            style={[styles.container, style]}>
            <View style={[dlsStyle.selectContainer, styles.selectContainer]}>
                <View style={dlsStyle.icon} />
            </View>
            <FalsyText style={dlsStyle.text} component={children} selectable={false} />
        </TouchableComponent>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    selectContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
});
