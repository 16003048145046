import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { StyleSheet } from "react-native";
import { IThemeContext } from "@swiggy-private/rn-dls-theme";
import { StyledComponentProps } from "./styled";
import {
    ComponentStyleMetaMapping,
    ComponentStyleMapping,
    ComponentTypes,
    getStyles,
} from "./styles-map";
import { useTheme } from "./theme-service";

export type Styles<T> = StyleSheet.NamedStyles<T>;

/**
 * User interactions that can be handled by DLS.
 */
export enum UserInteraction {
    HOVER = "hover",
    ACTIVE = "active",
    FOCUSED = "focused",
}

export type ElementState = "hover" | "disabled" | "active" | "focused";

export const useStyleSheet = <T extends ComponentTypes>(
    type: T,
    meta: ComponentStyleMetaMapping[T] = {},
): ComponentStyleMapping[T] => {
    const { value: theme } = useTheme();
    return useMemo(() => getStyles({ type, theme, meta }), [meta, theme, type]);
};

export interface DlsProp<T extends ComponentTypes> {
    theme: IThemeContext;
    style: ComponentStyleMapping[T];
    dispatch: (interaction: UserInteraction[]) => void;
}

export const useDLS = <T extends ComponentTypes>(
    type: T,
    meta: ComponentStyleMetaMapping[T] & StyledComponentProps = {},
): DlsProp<T> => {
    const theme: IThemeContext = useTheme();
    const [state, setState] = useStateManagement(meta.state, meta.disabled);
    const metaData = useMemo(() => ({ ...meta, state }), [meta, state]);
    const style = useStyleSheet(type, metaData);

    const dispatch = useCallback(
        (interaction: UserInteraction[]): void => {
            setState(interaction);
        },
        [setState],
    );

    return { theme, style, dispatch };
};

const useStateManagement = (
    initialState?: ElementState,
    disabled?: boolean | null,
): [
    Array<ElementState> | ElementState | null,
    Dispatch<SetStateAction<Array<ElementState> | ElementState | null>>,
] => {
    const [state, setState] = useState<Array<ElementState> | ElementState | null>(
        initialState ?? null,
    );

    if (disabled) {
        return ["disabled", setState];
    }

    return [state, setState];
};
