import * as React from "react";
import {
    ImageErrorEventData,
    NativeSyntheticEvent,
    Platform,
    StyleSheet,
    ViewProps,
    useWindowDimensions,
} from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { Box, ScreenSize, Stack, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { InitiateReviewMessage as IInitiateReviewMessage } from "@swiggy-private/connect-chat-commons";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { CONSTANTS } from "../constants/initiated-review-message";

export interface InitiatedReviewMessageProps {
    message: IInitiateReviewMessage;

    imageSize?: number;
    imageIds?: string[];
    style?: ViewProps["style"];
    onError?: (error: NativeSyntheticEvent<ImageErrorEventData>) => void;
}

const InitiatedReviewMessageComponent: React.FC<InitiatedReviewMessageProps> = ({
    message,
    imageIds,
    onError,
    imageSize = CONSTANTS.DEFAULT_IMAGE_SIZE,
}) => {
    const screenSize = useScreenSize();
    const { value: theme } = useTheme();

    const imageStyle = {
        width: imageSize,
        height: imageSize,
        borderColor: theme["color-basic-15"],
    };

    const { width: windowWidth } = useWindowDimensions();
    const isDWeb = Platform.OS === "web" && screenSize === ScreenSize.Large;

    const msgWidth = isDWeb ? CONSTANTS.MIN_MSG_WIDTH : windowWidth * 0.7;
    const payload = message.payload;

    return (
        <Box style={[styles.container, { width: msgWidth }]}>
            <Stack
                direction="row"
                justifyContent="space-between"
                spacing={SpacingValue["space-medium"]}>
                <Box style={styles.message}>
                    <Text
                        category="b2"
                        weight="regular"
                        color="high"
                        testID={CONSTANTS.MESSAGE_TEST_ID}>
                        {payload.description}
                    </Text>
                </Box>

                <CdnImage
                    /** TODO: show all images in the next phase */
                    id={imageIds?.[0] || CONSTANTS.PRODUCT_IMAGE_FALLBACK}
                    style={[styles.image, imageStyle]}
                    resizeMode="cover"
                    onError={onError}
                    showLoader
                    testID={imageIds?.[0] || CONSTANTS.PRODUCT_IMAGE_FALLBACK}
                />
            </Stack>
        </Box>
    );
};

export const InitiatedReviewMessage = React.memo(InitiatedReviewMessageComponent);

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        minWidth: 120,
        overflow: "hidden",
    },
    message: {
        flexShrink: 1,
    },
    image: {
        borderRadius: 8,
        borderWidth: 1,
    },
});
