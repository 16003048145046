import * as React from "react";
import { Image, Platform, StyleSheet } from "react-native";

import { useDebounceFn, useMountedRef } from "@swiggy-private/react-hooks";
import {
    CdnImage,
    ConditionalJSX,
    getMediaUrl,
    isMediaIdADataOrTempFile,
    isMediaIdAUrl,
    isUrlVideo,
} from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { ActivityIndicator, useTheme } from "@swiggy-private/rn-dls";

import { IMAGE_PLACEHOLDER } from "./constants";
import { StoreMediaProps } from "./types";
import { VideoPreview } from "./video-preview";

const StoreMediaComponent: React.FC<StoreMediaProps> = ({
    style,
    mediaId,
    onLoadStart,
    onLoadEnd,
    crop,
    ...props
}) => {
    const { value: theme } = useTheme();
    const mounted = useMountedRef();

    // * to retrieve values from style
    const _style = StyleSheet.flatten(style);

    // * to check if its a file containing file:// or https:// protocol
    const isMediaIdUrl = isMediaIdAUrl(mediaId);
    // * to check if it's base64 dataURI or a cropped-file available from device storage.
    const isMediaIdDataOrTempFile = isMediaIdADataOrTempFile(mediaId);
    const checkIfMediaIsVideo = isUrlVideo(mediaId);

    const assetWidth = _style?.width ?? props?.width;
    const assetHeight = _style?.height ?? props?.height;

    const uri = React.useMemo(
        () =>
            isMediaIdUrl || isMediaIdDataOrTempFile
                ? mediaId
                : getMediaUrl(mediaId ?? IMAGE_PLACEHOLDER, {
                      width: assetWidth,
                      height: assetHeight,
                      crop,
                      qualityAuto: true,
                  }),
        [assetHeight, assetWidth, crop, isMediaIdDataOrTempFile, isMediaIdUrl, mediaId],
    );

    const [isLoading, setLoading] = React.useState(false);

    const onLoadStartCallback = React.useCallback(() => {
        if (mounted.current) {
            setLoading(true);
            onLoadStart?.();
        }
    }, [mounted, onLoadStart]);

    const onLoadEndCallback = React.useCallback(() => {
        if (mounted.current) {
            setLoading(false);
            onLoadEnd?.();
        }
    }, [onLoadEnd, mounted]);

    const delayedLoadEnd = useDebounceFn(onLoadEndCallback, { wait: 500 });

    const imageStyle = {
        backgroundColor: theme["color-basic-5"].toString(),
    };

    const isSSRContext = Platform.OS === "web" && typeof window === "undefined";

    if (!uri) {
        return null;
    }

    if (checkIfMediaIsVideo && !isSSRContext) {
        return (
            <VideoPreview
                style={_style}
                {...{ mediaId, onLoadStart, onLoadEnd, crop }}
                {...props}
            />
        );
    }

    const ImageAsset = isMediaIdDataOrTempFile ? Image : CdnImage;

    return (
        <Box>
            <ImageAsset
                style={[imageStyle, _style]}
                id={uri}
                source={{ uri }}
                onLoadStart={onLoadStartCallback}
                onLoadEnd={delayedLoadEnd}
                onError={delayedLoadEnd}
                resizeMode="cover"
                {...props}
            />

            <ConditionalJSX condition={isLoading}>
                <ActivityIndicator
                    animating
                    size="small"
                    style={[styles.loader, _style]}
                    color="color-tertiary"
                />
            </ConditionalJSX>
        </Box>
    );
};

export const StoreMedia = React.memo(StoreMediaComponent);

const styles = StyleSheet.create({
    loader: {
        ...StyleSheet.absoluteFillObject,
        position: "absolute",
        alignItems: "center",
        justifyContent: "center",
    },
});
