import { Theme } from "../interfaces";
import { generateTheme } from "../utils/theme";

const defaultTheme: Theme = generateTheme(
    {
        // primaryColor: "#4AA66A",
        primaryColor: "#FF5200",
        secondaryColor: "#02060C",
        tertiaryColor: "#683896",
        criticalColor: "#C93220",
        highlightColor: "#0A2EBD",
        warningColor: "#FCCD16",
        positiveColor: "#4AA66A",
    },
    "light",
);

export default defaultTheme;
