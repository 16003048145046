import React from "react";
import { StyleSheet } from "react-native";
import Video from "react-native-video";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useBackListener } from "@swiggy-private/react-native-ui";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { Portal, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { TEST_ID, PREVIEW_CONTROL_SIZE, VIDEO_CONTROL_ICON } from "../constants";
import { IVideoControlProps, TVideoFullScreenProps } from "../types";

const closeIconHitSlop = { top: 16, left: 16, right: 16, bottom: 16 };

export const VideoFullScreen: React.FC<TVideoFullScreenProps> = ({ onClose, vRef, ...props }) => {
    const { value: theme } = useTheme();
    const videoStyle = {
        flex: 1,
        backgroundColor: theme["color-basic-100"],
    };

    useBackListener(
        React.useCallback(() => {
            onClose();
            return true;
        }, [onClose]),
    );

    return (
        <Portal>
            <Video {...props} style={videoStyle} ref={vRef} />
            <Box style={styles.closeIcon}>
                <SvgIcon
                    width={PREVIEW_CONTROL_SIZE.ICON.LARGE}
                    height={PREVIEW_CONTROL_SIZE.ICON.LARGE}
                    color={theme["color-basic-0"]}
                    icon="Close"
                    onPress={onClose}
                    hitSlop={closeIconHitSlop}
                />
            </Box>
        </Portal>
    );
};

export const VideoControl: React.FC<IVideoControlProps> = ({
    onPress,
    paused,
    playControlDimension,
}) => {
    const { value: theme } = useTheme();
    const style = !paused ? styles.playOpacity : null;
    const { height, width } = playControlDimension?.container || { height: 0, width: 0 };
    const controlTransformStyle = [
        { translateY: -(height || 0) / 2 },
        { translateX: -(width || 0) / 2 },
    ];

    const videoControlStyles = StyleSheet.flatten([
        styles.controlContainer,
        { transform: controlTransformStyle, backgroundColor: theme["color-basic-100"] },
        style,
        { ...playControlDimension?.container },
    ]);

    return (
        <Box style={videoControlStyles}>
            <SvgIcon
                {...playControlDimension?.icon}
                testID={TEST_ID.VIDEO_PREVIEW_CONTROL}
                color={theme["color-basic-0"]}
                icon={paused ? VIDEO_CONTROL_ICON.play : VIDEO_CONTROL_ICON.pause}
                onPress={onPress}
                style={styles.control}
            />
        </Box>
    );
};

const styles = StyleSheet.create({
    control: {
        marginLeft: SpacingValue["space-xxx-small"],
    },
    playOpacity: {
        opacity: 0,
    },
    closeIcon: {
        position: "absolute",
        top: SpacingValue["space-x-large"],
        left: SpacingValue["space-medium"],
        zIndex: 1,
    },
    controlContainer: {
        opacity: 0.7,
        position: "absolute",
        borderRadius: PREVIEW_CONTROL_SIZE.CONTAINER.SMALL,
        left: "50%",
        top: "50%",
        alignItems: "center",
        justifyContent: "center",
    },
});
