import React, { useState, useEffect, useRef } from "react";

import {
    Animated,
    TouchableOpacity,
    StyleSheet,
    Dimensions,
    TouchableWithoutFeedback,
    View,
    Platform,
    SafeAreaView,
    LayoutChangeEvent,
    Image,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import LinearGradient from "react-native-linear-gradient";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { ActivityIndicator, SpacingValue, Text } from "@swiggy-private/rn-dls";
import { FONT_REGULAR } from "@swiggy-private/rn-dls-theme";

import GestureRecognizer from "./components/GestureRecognizer";

import { usePrevious, fetchStoryImage } from "./helpers";
import { IUserStoryItem, NextOrPrevious, StoryListItemProps, StoriesAnalytics } from "./interfaces";
import {
    DEFAULTS_GRADIENT,
    DEFAULTS_GRADIENT_FOOTER,
    DEFAULTS__GRADIENT_COVER,
    BLACK_COLOR,
} from "./constants";

const { width, height } = Dimensions.get("window");

const modalMarginBottom = Platform.select({
    android: 0,
    ios: 0,
    web: 16,
    default: 0,
});

const ImageLoader: React.FC<{ analytics: StoriesAnalytics | undefined }> = ({ analytics }) => {
    useEffect(() => {
        analytics?.sendStoryLoaderImpression();
    }, []);
    return (
        <View style={styles.spinnerContainer}>
            <ActivityIndicator size={"large"} />
        </View>
    );
};

export const StoryListItemComponent: React.FC<StoryListItemProps> = ({
    index,
    key,
    userId,
    profileImage,
    profileName,
    duration,
    isLastPage,
    onFinish,
    onClosePress,
    stories,
    currentPage,
    onStorySeen,
    renderCloseComponent,
    renderHeaderComponent,
    renderStoryActions,
    renderStoryEndCover,
    renderFooter,
    loadedAnimationBarStyle,
    unloadedAnimationBarStyle,
    animationBarContainerStyle,
    storyUserContainerStyle,
    storyImageStyle,
    storyContainerStyle,
    showHighlightCover,
    selectedStoryIndex,
    pauseAnimation,
    analytics,
    firstStoryImageData,
}: StoryListItemProps) => {
    const [load, setLoad] = useState<boolean>(true);
    const [pressed, setPressed] = useState<boolean>(false);
    const [highlightFinished, setHighlightFinished] = useState<boolean>(false);
    const [content, setContent] = useState<IUserStoryItem[]>(
        stories.map((x) => ({
            ...x,
            finish: 0,
        })),
    );

    const insets = useSafeAreaInsets();
    const [current, setCurrent] = useState(selectedStoryIndex ?? 0); // current story in the highlight

    const [imageDataSet, setImageDataSet] = useState<{ [key: number]: string }>({
        0: firstStoryImageData ?? "",
    });

    const [imageheightSet, setImageHeightSet] = useState<{ [key: number]: number }>({});

    const [footerY, setFooterY] = useState(0);
    const [headerY, setHeaderY] = useState(0);

    const progress = useRef(new Animated.Value(0)).current;
    const footerRef = React.useRef<View>(null);
    const headerRef = React.useRef<View>(null);

    const opacityAnim = useRef(new Animated.Value(0)).current;
    const coverOpacityAnim = useRef(new Animated.Value(1)).current;

    const prevCurrentPage = usePrevious(currentPage) as number;

    const [isHighlightCoverVisible, setHighlightCoverVisible] = useState<boolean>(
        !!showHighlightCover,
    );

    useEffect(() => {
        Image.getSize(content[0].story_image ?? "", (imgW: number, imgH: number) => {
            const desiredH = Math.floor(width * (imgH / imgW));
            setImageHeightSet({ 0: desiredH });
        });
    }, []);

    useEffect(() => {
        [current + 1, current + 2].forEach((i) => {
            const story = content[i];
            if (!story || !story.story_image) {
                return;
            }

            if (!imageDataSet[i]) {
                fetchStoryImage(story.story_image).then(async (response) => {
                    response && setImageDataSet((prev) => ({ ...prev, [i]: response }));
                });
            }

            if (!imageheightSet[i]) {
                Image.getSize(story.story_image ?? "", (imgW: number, imgH: number) => {
                    const desiredH = Math.floor(width * (imgH / imgW));
                    setImageHeightSet((prev) => ({ ...prev, [i]: desiredH }));
                });
            }
        });
    }, [current]);

    useEffect(() => {
        if (!firstStoryImageData || imageDataSet[0]) {
            return;
        }
        setImageDataSet((prev) => ({ ...prev, 0: firstStoryImageData }));
    }, [firstStoryImageData]);

    useEffect(() => {
        if (pauseAnimation) {
            progress.stopAnimation();
        } else {
            startAnimation();
        }
    }, [pauseAnimation, progress]);

    useEffect(() => {
        analytics?.setStoryIndex(current);

        analytics?.sendProgressBarImpression();
    }, [analytics, current]);

    useEffect(() => {
        if (prevCurrentPage !== undefined) {
            setTimeout(() => {
                setCurrent(0);
            }, 0);
        }

        const data = [...content];
        data.map((x) => {
            x.finish = 0;
        });
        setContent(data);
        start();

        setHighlightCoverVisible(true);
        Animated.timing(opacityAnim, {
            toValue: 1,
            duration: 500,
            useNativeDriver: false,
            delay: 500,
        }).start();
        setTimeout(() => {
            Animated.timing(coverOpacityAnim, {
                toValue: 0,
                duration: 500,
                useNativeDriver: false,
            }).start(({ finished }) => {
                if (finished) {
                    setHighlightCoverVisible(false);
                }
            });
        }, 1500);
    }, [currentPage]);

    const start = (): void => {
        if (highlightFinished || pauseAnimation) {
            return;
        }

        progress.setValue(0);
        startAnimation();

        if (prevCurrentPage !== currentPage) {
            coverOpacityAnim.setValue(1);
            opacityAnim.setValue(0);
        }
    };

    const startAnimation = (): void => {
        Animated.timing(progress, {
            toValue: 1,
            duration: duration,
            useNativeDriver: false,
            delay: isHighlightCoverVisible ? 3000 : 0,
        }).start(({ finished }) => {
            if (finished) {
                if (current === content.length - 1 && isLastPage) {
                    if (!renderStoryEndCover) {
                        onClosePress();
                        return;
                    }
                    progress.stopAnimation();
                    setHighlightFinished(true);
                    return;
                }
                next();
            }
        });
    };

    const onSwipeUp = (): void => {
        if (onClosePress) {
            onClosePress();
        }
        if (content[current].onPress) {
            content[current].onPress?.();
        }
    };

    const onSwipeDown = (): void => {
        onClosePress();
    };

    const config = {
        velocityThreshold: 0.3,
        directionalOffsetThreshold: 80,
    };

    const onFooterLayout = (e: LayoutChangeEvent): void => {
        if (!footerRef.current) {
            return;
        }

        setFooterY(e.nativeEvent.layout.y ?? 0);
    };

    const onHeaderLayout = (e: LayoutChangeEvent): void => {
        if (!headerRef.current) {
            return;
        }

        const { y, height: nHeight } = e.nativeEvent.layout;
        if (y === 0) {
            return;
        }

        setHeaderY(Math.floor(y + nHeight) - 50);
    };

    const next = (): void => {
        // check if the next content is not empty
        !imageDataSet[current] && setLoad(true);
        if (current !== content.length - 1) {
            const data = [...content];
            data[current].finish = 1;
            setContent(data);
            setCurrent(current + 1);
            progress.setValue(0);
        } else {
            // the next content is empty
            close("next");
        }
    };

    const previous = (): void => {
        // checking if the previous content is not empty
        !imageDataSet[current] && setLoad(true);
        if (current - 1 >= 0) {
            const data = [...content];
            data[current].finish = 0;
            setContent(data);
            setCurrent(current - 1);
            progress.setValue(0);
        } else {
            // the previous content is empty
            close("previous");
        }
    };

    const close = (state: NextOrPrevious): void => {
        const data = [...content];
        data.map((x) => (x.finish = 0));
        setContent(data);
        progress.setValue(0);
        if (Number(currentPage) === index) {
            if (onFinish) {
                onFinish(state);
            }
        }
    };

    const showEndCover = renderStoryEndCover && highlightFinished;

    React.useEffect(() => {
        if (onStorySeen && currentPage === index) {
            onStorySeen({
                user_id: userId,
                user_image: profileImage,
                user_name: profileName,
                story: content[current],
            });
        }
    }, [currentPage, index, onStorySeen, current, content, userId, profileImage, profileName]);

    const imageStyle = [
        styles.image,
        storyImageStyle,
        { top: height / 2 - (imageheightSet[current] ?? 0) / 2 - insets.top },
    ];

    return (
        <GestureRecognizer
            key={key}
            onSwipeUp={onSwipeUp}
            onSwipeDown={onSwipeDown}
            config={{ ...config, footerThreshold: footerY }}
            style={[styles.container, storyContainerStyle]}>
            <SafeAreaView style={[{ marginTop: insets.top }, styles.wrapper]}>
                <View style={styles.backgroundContainer}>
                    <CdnImage
                        onLoadEnd={() => {
                            start();
                            setLoad(false);
                        }}
                        onError={() => {
                            setLoad(false);
                        }}
                        id={imageDataSet[current] ?? content[current].story_image ?? ""}
                        style={imageStyle}
                        width={width}
                        height={imageheightSet[current] ?? height}
                        isImageKitEnabled
                        showLoader={false}
                    />
                    {load && <ImageLoader analytics={analytics} />}
                </View>

                <View style={[styles.flexCol, styles.content]}>
                    <LinearGradient
                        colors={DEFAULTS_GRADIENT.QUANTITY_GRADIENT_COLORS}
                        start={{ x: 0, y: 0 }}
                        end={{ x: 0, y: 1 }}
                        locations={DEFAULTS_GRADIENT.QUANTITY_GRADIENT_COLORS_LOCATIONS}>
                        <View style={[styles.animationBarContainer, animationBarContainerStyle]}>
                            {content.map((_, num) => {
                                return (
                                    <View
                                        key={num}
                                        style={[
                                            styles.animationBackground,
                                            unloadedAnimationBarStyle,
                                        ]}>
                                        <Animated.View
                                            style={[
                                                {
                                                    flex:
                                                        current === num
                                                            ? progress
                                                            : content[num].finish,
                                                },
                                                styles.progress,
                                                loadedAnimationBarStyle,
                                            ]}
                                        />
                                    </View>
                                );
                            })}
                        </View>

                        <View
                            ref={headerRef}
                            style={[styles.userContainer, storyUserContainerStyle]}
                            onLayout={onHeaderLayout}>
                            <View style={styles.header}>
                                {typeof renderHeaderComponent === "function"
                                    ? renderHeaderComponent(current)
                                    : null}
                            </View>

                            <View style={styles.closeIconContainer}>
                                {typeof renderCloseComponent === "function" ? (
                                    <TouchableOpacity onPress={onClosePress}>
                                        {renderCloseComponent({
                                            onPress: onClosePress,
                                            item: content[current],
                                        })}
                                    </TouchableOpacity>
                                ) : null}
                            </View>
                        </View>
                    </LinearGradient>
                </View>

                <View style={[styles.pressContainer, { top: headerY }]}>
                    <TouchableWithoutFeedback
                        onPressIn={() => progress.stopAnimation()}
                        onLongPress={() => setPressed(true)}
                        onPressOut={() => {
                            setPressed(false);
                            startAnimation();
                        }}
                        onPress={() => {
                            if (!pressed) {
                                analytics?.sendProgressBarClick();
                                previous();
                            }
                        }}>
                        <View style={styles.flex} />
                    </TouchableWithoutFeedback>
                    <TouchableWithoutFeedback
                        onPressIn={() => progress.stopAnimation()}
                        onLongPress={() => setPressed(true)}
                        onPressOut={() => {
                            setPressed(false);
                            startAnimation();
                        }}
                        onPress={() => {
                            if (!pressed) {
                                analytics?.sendProgressBarClick();
                                next();
                            }
                        }}>
                        <View style={styles.flex} />
                    </TouchableWithoutFeedback>
                </View>

                {typeof renderFooter === "function" ? (
                    <View
                        ref={footerRef}
                        onLayout={onFooterLayout}
                        style={[styles.swipeUpBtn, { bottom: insets.bottom + modalMarginBottom }]}>
                        <LinearGradient
                            colors={DEFAULTS_GRADIENT_FOOTER.QUANTITY_GRADIENT_COLORS}
                            start={{ x: 0, y: 0 }}
                            end={{ x: 0, y: 1 }}
                            locations={DEFAULTS_GRADIENT_FOOTER.QUANTITY_GRADIENT_COLORS_LOCATIONS}>
                            <View style={[styles.storyActions]}>
                                {typeof renderStoryActions === "function"
                                    ? renderStoryActions()
                                    : null}
                            </View>
                            {renderFooter(current)}
                        </LinearGradient>
                    </View>
                ) : null}

                {showHighlightCover && isHighlightCoverVisible && (
                    <Animated.View style={[styles.highlightCover, { opacity: coverOpacityAnim }]}>
                        <Animated.View style={{ opacity: opacityAnim }}>
                            <Text
                                category="h3"
                                weight="regular"
                                color={"color-basic-0"}
                                style={[styles.highlightCoverText]}>
                                {profileName}
                            </Text>
                        </Animated.View>
                    </Animated.View>
                )}
                {showEndCover ? (
                    <View style={[styles.highlightCover, { backgroundColor: "transparent" }]}>
                        <LinearGradient
                            colors={DEFAULTS__GRADIENT_COVER.QUANTITY_GRADIENT_COLORS}
                            start={{ x: 0, y: 0 }}
                            end={{ x: 0, y: 1 }}
                            locations={DEFAULTS__GRADIENT_COVER.QUANTITY_GRADIENT_COLORS_LOCATIONS}
                            style={styles.highlightCoverContent}>
                            {renderStoryEndCover?.({
                                onClose: onClosePress,
                            })}
                        </LinearGradient>
                    </View>
                ) : null}
            </SafeAreaView>
        </GestureRecognizer>
    );
};

export const StoryListItem = React.memo(StoryListItemComponent);

StoryListItemComponent.defaultProps = {
    duration: 10000,
    showHighlightCover: true,
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: BLACK_COLOR,
        paddingTop: Platform.OS === "web" ? SpacingValue["space-medium"] : 0,
    },
    wrapper: { width, height, backgroundColor: BLACK_COLOR },
    flex: {
        flex: 1,
    },
    flexCol: {
        flex: 1,
        flexDirection: "column",
    },
    header: {
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
    },
    image: {
        width: width,
        resizeMode: "center",
        borderRadius: 16,
    },
    backgroundContainer: {
        height: "100%",
        width: "100%",
    },
    spinnerContainer: {
        position: "absolute",
        justifyContent: "center",
        alignSelf: "center",
        width: width,
        height: height,
    },
    content: {
        position: "absolute",
        width: "100%",
    },
    animationBarContainer: {
        flexDirection: "row",
        paddingTop: SpacingValue["space-medium"],
        paddingHorizontal: SpacingValue["space-medium"],
    },
    animationBackground: {
        height: 3,
        flex: 1,
        flexDirection: "row",
        backgroundColor: "rgba(2, 6, 12, 0.45)",
        marginHorizontal: 2,
        borderRadius: 6,
        opacity: 0.5,
    },
    userContainer: {
        flexDirection: "row",
        paddingHorizontal: SpacingValue["space-medium"],
        marginTop: SpacingValue["space-small"],
        paddingBottom: 50,
        flex: 1,
    },
    storyActions: {
        alignSelf: "flex-end",
        right: SpacingValue["space-medium"],
        marginBottom: SpacingValue["space-medium"],
    },
    closeIconContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
    pressContainer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        flex: 1,
        flexDirection: "row",
    },
    swipeUpBtn: {
        position: "absolute",
        alignItems: "center",
        bottom: Platform.OS !== "web" ? SpacingValue["space-medium"] : 0,
    },
    progress: {
        height: 3,
        backgroundColor: "white",
        borderRadius: 6,
        opacity: 0.8,
    },
    highlightCover: {
        backgroundColor: "#02060CBF",
        bottom: 0,
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    highlightCoverContent: {
        position: "absolute",
        height,
        width,
        bottom: 0,
    },
    highlightCoverText: {
        fontFamily: FONT_REGULAR,
    },
    gradient: {
        bottom: 0,
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        zIndex: 999,
    },
});
