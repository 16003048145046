import { ITypography, FontWeight } from "@swiggy-private/dls-web-common/dist/components/types";
import { FONT_BLACK, FONT_REGULAR, FONT_BOLD, FONT_MEDIUM } from "../base/typography";

const getNativeSize = (size: string | number): number | undefined => {
    if (typeof size === "number") {
        return size;
    }
    const sizeValue = size ? Number(size?.split("px")?.[0]) : undefined;
    if (!sizeValue || isNaN(sizeValue)) {
        return undefined;
    }
    return sizeValue;
};

const fontTransformer = (
    family: string | undefined,
    weight: string | FontWeight | undefined,
): string | undefined => {
    if (family === "Gilroy_Bold") {
        return FONT_BOLD;
    }

    if (family !== "Gilroy") {
        return family;
    }

    switch (weight) {
        case FontWeight.regular:
            return FONT_REGULAR;
        case FontWeight.bold:
            return FONT_BOLD;
        case FontWeight.medium:
            return FONT_MEDIUM;
        case FontWeight.black:
            return FONT_BLACK;
        default:
            return FONT_REGULAR;
    }
};

const fontWeightTransformer = (
    family: string | undefined,
    weight: string | FontWeight | undefined,
): string | FontWeight | undefined => {
    return family === "Basis Grotesque Pro" || family === "Gilroy_Bold" ? undefined : weight;
};

export const convertToNativeFontMap = (typography: ITypography): ITypography => {
    const newTypography: { [key: string]: any } = {};
    Object.keys(typography).forEach((key) => {
        const fontDetails = typography[key as keyof ITypography];
        const { fontFamily, fontWeight, fontSize, lineHeight, letterSpacing } = fontDetails;
        newTypography[key] = {
            fontFamily: fontTransformer(fontFamily, fontWeight),
            fontWeight: fontWeightTransformer(fontFamily, fontWeight),
            fontSize: getNativeSize(fontSize as string | number),
            lineHeight: getNativeSize(lineHeight as string | number),
            letterSpacing: getNativeSize(letterSpacing as string | number),
        };
    });

    return newTypography as ITypography;
};
