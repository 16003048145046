import React, { useCallback, useEffect, useState } from "react";
import { Platform, Pressable, StyleSheet, useWindowDimensions, ViewStyle } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { ImageList } from "./images-list";
import { ImageGalleryHeaderProps } from "./types";
import { ImageCarouselModal } from "./image-carousel-modal";

interface ImagesGalleryListProps {
    images: string[];

    size?: number;
    showIcon?: boolean;
    showExpandedPreview?: boolean;
    imagesVisible?: number;

    showImagePreview?: number;
    onImagePreviewModalClosed?: VoidFunction;
}

const DEFAULT_MARGIN = 20;

const CloseIcon: React.FC<ImageGalleryHeaderProps> = ({ onClose, imageSize = 0 }) => {
    const { value: theme } = useTheme();

    const { height: windowHeight, width: windowWidth } = useWindowDimensions();

    const topValue =
        Platform.OS === "android"
            ? (windowHeight - imageSize) / 2 - DEFAULT_MARGIN
            : (windowHeight - imageSize - DEFAULT_MARGIN) / 2;

    const backgroundStyles: ViewStyle = {
        top: topValue,
        right: (windowWidth - imageSize - DEFAULT_MARGIN) / 2,
        backgroundColor: theme["color-basic-alpha-75"],
    };

    return (
        <Pressable onPress={onClose} style={[styles.closeIcon, backgroundStyles]}>
            <SvgIcon icon="Close" width={12} height={12} color="color-basic-0" />
        </Pressable>
    );
};

export const ImageGalleryList: React.FC<ImagesGalleryListProps> = ({
    images = [],
    imagesVisible = 4,
    showImagePreview,
    onImagePreviewModalClosed,
}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showModalPreview, setShowModalPreview] = useState(false);

    const { width: windowWidth, height: windowHeight } = useWindowDimensions();

    const onShowModal = useCallback((index: number) => {
        setSelectedIndex(index);
        setShowModalPreview(true);
    }, []);

    const onCloseModal = useCallback(() => {
        setShowModalPreview(false);
        onImagePreviewModalClosed?.();
    }, [onImagePreviewModalClosed]);

    const imageSize = Math.min(
        windowHeight - SpacingValue["space-medium"] * 2,
        windowWidth - SpacingValue["space-medium"] * 2,
    );

    const headerComponent = useCallback(
        () => <CloseIcon onClose={onCloseModal} imageSize={imageSize} />,
        [imageSize, onCloseModal],
    );

    useEffect(() => {
        if (showImagePreview !== undefined) {
            onShowModal(showImagePreview);
        }
    }, [onShowModal, showImagePreview]);

    if (!images.length) {
        return null;
    }

    return (
        <Stack>
            <ImageList images={images} onImageClick={onShowModal} imagesVisible={imagesVisible} />
            {showModalPreview ? (
                <ImageCarouselModal
                    visible
                    images={images}
                    onClose={onCloseModal}
                    HeaderComponent={headerComponent}
                    selectedIndex={selectedIndex}
                    imageSize={imageSize}
                />
            ) : null}
        </Stack>
    );
};

const styles = StyleSheet.create({
    container: {
        display: "flex",
        flexDirection: "row",
    },
    imageContainer: {
        marginRight: SpacingValue["space-small"],
    },
    imageStyles: {
        borderRadius: 8,
    },
    iconContainer: {
        position: "absolute",
        bottom: 2,
        right: 2,
        borderRadius: 16,
        padding: SpacingValue["space-xx-small"],
    },
    closeIcon: {
        zIndex: 2,
        borderRadius: 20,
        position: "absolute",
        padding: SpacingValue["space-x-small"],
    },
});
