import React from "react";

import { Conversation } from "./types";

export type ChatRouteList = {
    ChatConversationList: undefined;
    ChatConversation: {
        id: string;
    };
};

export interface NavigationOptions {
    headerTitle: string | (() => React.ReactNode);
}

export interface Navigation {
    setOptions: (opts: NavigationOptions) => void;
    push: <T extends keyof ChatRouteList>(route: T, params: ChatRouteList[T]) => void;
    navigate: <T extends keyof ChatRouteList>(route: T, params: ChatRouteList[T]) => void;
    replace: <T extends keyof ChatRouteList>(route: T, params: ChatRouteList[T]) => void;
    goBack: () => void;
}

export interface ChatConversationEmptyProps {
    participantDeleted?: boolean;
    showPopup?: boolean;
    ChatInfoComponent?: React.ComponentType<{ showPopup?: boolean; closeInfoPopup?: VoidFunction }>;
    closeInfoPopup?: VoidFunction;
}

export interface chatConversationListCallback {
    searchInputAnalyticClick?: VoidFunction;
    conversationListItemAnalyticImpression?: (item: Conversation, unreadCount: number) => void;
    conversationListItemAnalyticClick?: (item: Conversation, unreadCount: number) => void;
}

export * from "./types";
export * from "./chat-renderer";
