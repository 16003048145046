import { useCallback, useEffect, useMemo } from "react";
import { useMount, useMountedRef } from "@swiggy-private/react-hooks";
import { useIsFocused } from "@react-navigation/core";

import { useChatDispatch } from "./use-chatdispatch";
import { useChatState } from "./use-chatstate";
import { useChatSdk } from "./use-chatsdk";
import { logError } from "../helpers/log";
import { useChatService } from "./use-chat-service";
import { AppState, InteractionManager } from "react-native";
import { ChatAction } from "../reducers/chat";

export const useConversationMessageUnreadCount = (conversationId: string): number => {
    const state = useChatState();
    const meta = useMemo(
        () => state.conversationsMeta[conversationId] ?? { unreadCount: 0 },
        [conversationId, state],
    );

    return meta.unreadCount || 0;
};

const MARK_UNREAD_REFRESH_INTERVAL = 15_000;

export const useConversationMarkUnread = (conversationId: string, refresh = true): void => {
    const dispatch = useChatDispatch();
    const sdk = useChatSdk();
    const service = useChatService();
    const focused = useIsFocused();

    const markUnreadFn = useCallback(() => {
        dispatch({
            type: "MULTI_DISPATCH",
            payload: [
                {
                    type: "SET_UNREAD_COUNT_ACTION",
                    payload: {
                        conversationId,
                        count: 0,
                    },
                },
                {
                    type: "SET_CONVERSATION_LAST_READ_ACTION",
                    payload: {
                        conversationId,
                        timestamp: Date.now(),
                    },
                },
            ],
        });
    }, [conversationId, dispatch]);

    const updateConversationReadTime = useCallback(async (): Promise<void> => {
        if (AppState.currentState !== "active" || !focused) {
            return;
        }

        sdk?.setConversationLastReadTime(conversationId).catch(logError);
        service?.saveConversationLastReadTime(conversationId).catch(logError);
    }, [conversationId, focused, sdk, service]);

    useMount(() => {
        const task = InteractionManager.runAfterInteractions(() => {
            markUnreadFn();
            updateConversationReadTime();
        });

        return () => {
            updateConversationReadTime();
            task.cancel();
        };
    });

    useEffect(() => {
        if (refresh) {
            const timer = setInterval(updateConversationReadTime, MARK_UNREAD_REFRESH_INTERVAL);
            return () => clearInterval(timer);
        }

        return () => {
            // do nothing...
        };
    }, [refresh, updateConversationReadTime]);
};

export const useFetchConversationUnreadCountFromDb = (): ((
    conversationIds: string[],
) => Promise<Record<string, number>>) => {
    const service = useChatService();

    return useCallback(
        async (conversationIds: string[]) => {
            if (!service) {
                return {};
            }

            const results: Record<string, number> = {};
            const promises: Promise<void>[] = conversationIds.map((conversationId) => {
                return new Promise(async (resolve) => {
                    const count = await service
                        .getUnreadMessageCount(conversationId)
                        .catch((err) => {
                            logError(err);
                            return 0;
                        });

                    results[conversationId] = count;

                    resolve();
                });
            });

            await Promise.all(promises);

            return results;
        },
        [service],
    );
};

const lastFetchedUnread: Record<string, number> = {};
const MINIMUM_FETCH_INTERVAL_TIME = 15_000;

export const useFetchConversationUnreadCount = (
    conversationIds: string[],
    refresh = false,
): void => {
    const dispatch = useChatDispatch();
    const sdk = useChatSdk();
    const mounted = useMountedRef();
    const service = useChatService();

    const fetchConversationUnreadCountFromDb = useFetchConversationUnreadCountFromDb();

    const onSuccess = useCallback(
        async (conversationIdsToFetch: string[], results: Record<string, number>) => {
            if (!mounted.current || AppState.currentState !== "active") {
                return;
            }

            const actions: ChatAction[] = [];

            conversationIdsToFetch.forEach((id) => {
                if (results[id] != null) {
                    actions.push({
                        type: "SET_UNREAD_COUNT_ACTION",
                        payload: {
                            conversationId: id,
                            count: results[id],
                        },
                    });
                }
            });

            const dbConversationIds = conversationIdsToFetch.filter((id) => results[id] == null);

            if (dbConversationIds.length > 0) {
                const dbResults = await fetchConversationUnreadCountFromDb(dbConversationIds);
                dbConversationIds.forEach((id) => {
                    actions.push({
                        type: "SET_UNREAD_COUNT_ACTION",
                        payload: {
                            conversationId: id,
                            count: dbResults[id] || 0,
                        },
                    });
                });
            }

            if (actions.length > 0 && mounted.current) {
                dispatch({
                    type: "MULTI_DISPATCH",
                    payload: actions,
                });
            }
        },
        [dispatch, fetchConversationUnreadCountFromDb, mounted],
    );

    useEffect(() => {
        const fn = async (): Promise<void> => {
            if (AppState.currentState !== "active" || !conversationIds.length || !sdk) {
                return;
            }

            const time = Date.now();
            let conversationIdsToFetch = conversationIds.filter(
                (cId) =>
                    lastFetchedUnread[cId] == null ||
                    time > lastFetchedUnread[cId] + MINIMUM_FETCH_INTERVAL_TIME,
            );

            if (!conversationIdsToFetch.length) {
                return;
            }

            const dbTokens =
                (await service?.getConversationLastReadTime(conversationIdsToFetch).catch((err) => {
                    logError(err);
                    return {};
                })) || {};

            const conversationTokens: Record<string, string> = Object.keys(dbTokens).reduce(
                (acc, cur) => {
                    const value = dbTokens[cur];
                    if (value != null) {
                        acc[cur] = String(value * 10_000);
                    }

                    return acc;
                },
                {} as Record<string, string>,
            );

            conversationIdsToFetch = conversationIdsToFetch.filter(
                (id) => conversationTokens[id] != null,
            );

            if (!conversationIdsToFetch.length) {
                return;
            }

            conversationIdsToFetch.forEach((id) => {
                lastFetchedUnread[id] = time;
            });

            await sdk
                .fetchUnreadMessagesCount(conversationIdsToFetch, conversationTokens)
                .then((results) => onSuccess(conversationIdsToFetch, results))
                .catch(logError);
        };

        fn();

        if (refresh) {
            const timer = setInterval(fn, MINIMUM_FETCH_INTERVAL_TIME);
            return () => clearInterval(timer);
        }

        return () => {
            // do nothing...
        };
    }, [conversationIds, dispatch, mounted, onSuccess, refresh, sdk, service]);
};
