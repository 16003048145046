import { useEffect, useRef, useState } from "react";
import { EmitterSubscription, Keyboard, Platform } from "react-native";

export const useIsKeyboardShown = (): boolean => {
    const [keyboardShown, setKeyboardShown] = useState(false);
    const subscriptions = useRef<EmitterSubscription[]>([]).current;

    useEffect(() => {
        if (Platform.OS === "ios") {
            subscriptions.push(
                Keyboard.addListener("keyboardWillShow", () => {
                    setKeyboardShown(true);
                }),
            );

            subscriptions.push(
                Keyboard.addListener("keyboardWillHide", () => {
                    setKeyboardShown(false);
                }),
            );
        } else {
            subscriptions.push(
                Keyboard.addListener("keyboardDidShow", () => {
                    setKeyboardShown(true);
                }),
            );

            subscriptions.push(
                Keyboard.addListener("keyboardDidHide", () => {
                    setKeyboardShown(false);
                }),
            );
        }

        return () => {
            while (subscriptions.length > 0) {
                const s = subscriptions.pop();
                s?.remove();
            }
        };
    }, [subscriptions]);

    return keyboardShown;
};
