import React, { memo, useEffect, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";

import { usePortal } from "./portal-context";
import { PortalHost } from "./portal-host";

interface PortalProps {
    /**
     * Content of the `Portal`.
     */
    children: React.ReactNode;
    /**
     * Identifier for the portal.
     */
    id?: string;
    /**
     * Click behaviour for the portal
     */
    pointerEvents?: "none" | "box-only" | "box-none" | "auto";
}

/**
 * Portal allows to render a component at a different place in the parent tree.
 * You can use it to render content which should appear above other elements, similar to `Modal`.
 * It requires a `Portal.Host` component to be rendered somewhere in the parent tree.
 *
 * ## Usage
 * ```ts
 * import * as React from "react";
 * import { Portal, Text } from "@swiggy-private/rn-dls";
 *
 * export const MyComponent: React.FC = () => (
 *   <Portal>
 *     <Text>This is rendered at a different place</Text>
 *   </Portal>
 * );
 * ```
 *
 * @param props {@link PortalProps}
 */
export const Portal: React.FC<PortalProps> & { Host: typeof PortalHost } = ({
    children,
    id,
    pointerEvents,
}) => {
    const addElement = usePortal();

    const updateElementRef = useRef<(e: React.ReactElement) => void>();
    const removeElementRef = useRef<() => void>();

    const element = useMemo(
        () => <PortalContent pointerEvents={pointerEvents}>{children}</PortalContent>,
        [children, pointerEvents],
    );

    useEffect(() => {
        if (!updateElementRef.current) {
            const [update, remove] = addElement(element, id);
            updateElementRef.current = update;
            removeElementRef.current = remove;
        } else {
            updateElementRef.current(element);
        }
    }, [addElement, element, id]);

    useEffect(() => {
        return () => removeElementRef.current?.();
    }, []);

    return null;
};

const PortalContent: React.FC<PortalProps> = memo(({ children, pointerEvents }) => {
    return (
        <View
            collapsable={
                false /* Need collapsable=false here to clip the elevations, otherwise they appear above sibling components */
            }
            pointerEvents={pointerEvents || "box-none"}
            style={StyleSheet.absoluteFill}>
            {children}
        </View>
    );
});

if (process.env.NODE_ENV !== "production") {
    PortalContent.displayName = "PortalContent";
}

Portal.Host = PortalHost;
