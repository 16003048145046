import React from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { useDLS } from "../../styles/style-service";

import { Input, InputProps } from "../input";

export interface AppBarSearchProps extends InputProps {
    showIcon?: boolean;
}

type AppBarSearch = React.ForwardRefExoticComponent<
    AppBarSearchProps & React.RefAttributes<TextInput>
>;

export const AppBarSearch: AppBarSearch = React.forwardRef(
    ({ style, textStyle, showIcon = true, ...props }, ref) => {
        const { theme } = useDLS("appbar");

        return (
            <View style={styles.container}>
                <Input
                    ref={ref}
                    style={[styles.input, { borderColor: theme.value["color-basic-30"] }, style]}
                    textStyle={[styles.inputText, textStyle]}
                    accessoryLeft={() =>
                        showIcon ? (
                            <SvgIcon
                                icon="Magnifier"
                                width={16}
                                height={16}
                                style={styles.icon}
                                color="color-basic-75"
                            />
                        ) : null
                    }
                    {...props}
                />
            </View>
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    AppBarSearch.displayName = "AppBarSearch";
}

const styles = StyleSheet.create({
    container: {
        justifyContent: "center",
        height: "100%",
        flexGrow: 1,
        flexShrink: 1,
    },
    input: {
        margin: 0,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-x-small"],
        height: "auto",
        minHeight: 0,
        borderRadius: 32,
    },
    inputText: {
        fontSize: 14,
    },
    icon: {
        marginRight: SpacingValue["space-x-small"],
    },
});
