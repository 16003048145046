import React, { useEffect, useState } from "react";
import { Animated, Platform, StyleSheet, View } from "react-native";
import { ColorPalette, SpacingValue, useAnimatedValue, useTheme } from "@swiggy-private/rn-dls";

const dots = [1, 2, 3];

export const ThreeDotsLoader: React.FC<{ color?: keyof ColorPalette }> = ({ color }) => {
    const [active, setActive] = useState(0);

    useEffect(() => {
        const timer = setInterval(
            () => setActive(active === 2 ? 0 : active + 1),
            ANIMATION_DURATION + 100,
        );

        return () => clearInterval(timer);
    }, [active]);

    return (
        <View style={styles.main}>
            {dots.map((_, index) => (
                <Dot key={index} active={active === index} color={color} />
            ))}
        </View>
    );
};

const ANIMATION_DURATION = 400;
const ANIMATION_SCALE = 1.25;

const Dot: React.FC<{ active: boolean; color?: keyof ColorPalette }> = ({
    active,
    color = "color-background-primary",
}) => {
    const scale = useAnimatedValue(1);
    const { value: theme } = useTheme();

    React.useEffect(() => {
        Animated.timing(scale, {
            toValue: active ? ANIMATION_SCALE : 1,
            duration: ANIMATION_DURATION,
            useNativeDriver: Platform.OS !== "web",
        }).start();
    }, [scale, active]);

    const style = [
        styles.dot,
        active ? styles.dotActive : undefined,
        { transform: [{ scale }] },
        { backgroundColor: theme[color] },
    ];

    return <Animated.View style={style} />;
};

const styles = StyleSheet.create({
    main: {
        flexDirection: "row",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    dot: {
        borderRadius: 4,
        width: 8,
        height: 8,
        opacity: 0.4,
        marginRight: SpacingValue["space-xx-small"],
    },
    dotActive: {
        opacity: 1,
    },
});
