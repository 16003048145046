export class HttpError extends Error {
    private _cause: Error;

    constructor(message: string, cause: Error) {
        super(message);

        this.name = "HttpError";
        this._cause = cause;
    }

    public override get cause(): Error {
        return this._cause;
    }

    public isNetworkError(): boolean {
        return (
            typeof DOMException !== "undefined" &&
            this._cause instanceof DOMException &&
            this._cause.name === "NetworkError"
        );
    }

    public isAbortError(): boolean {
        return (
            typeof DOMException !== "undefined" &&
            this._cause instanceof DOMException &&
            this._cause.name === "AbortError"
        );
    }
}
