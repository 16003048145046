import { SdkConversation } from "../interfaces";
import { ServerConversation } from "../interfaces/server";

export const transformConversation = (
    conversation: ServerConversation,
    uuid: string,
): SdkConversation => {
    return {
        ...conversation,
        title: conversation.participants.filter((p) => p.id !== uuid)[0]?.name ?? "",
        lastMessageTimestamp: conversation.lastMessageAt,
    };
};
