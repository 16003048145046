import React, { useCallback, useEffect, useState } from "react";
import { ImageStyle, Pressable, StyleProp, StyleSheet, ViewStyle, View } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

const MORE_LABEL = "more";
const ICON_SIZE = 12;

interface ImagesListProps {
    images: string[];

    size?: number;
    icon?: string;
    iconSize?: string;
    imagesVisible?: number;
    optimizedDpr?: boolean;
    imageStyles?: StyleProp<ImageStyle>;
    containerStyles?: StyleProp<ViewStyle>;
    onImageClick?: (index: number) => void;
}

export const ImageList: React.FC<ImagesListProps> = ({
    images = [],
    size = 50,
    onImageClick,
    imageStyles = {},
    optimizedDpr = true,
    containerStyles = {},
    iconSize = ICON_SIZE,
    imagesVisible = 4,
}) => {
    const { value: theme } = useTheme();

    const totalImages = images.length;
    const remainingImages = totalImages - imagesVisible;

    const [imagesList, setImagesList] = useState<string[]>([]);
    const [showMoreButton, setShowMoreButton] = useState(() => remainingImages > 0);

    useEffect(() => {
        const showImagesSubset = images.length > imagesVisible;
        const imagesToShow = showImagesSubset ? images.slice(0, imagesVisible) : images;
        setImagesList(imagesToShow);
    }, [images, imagesVisible]);

    const showAllImages = useCallback(() => {
        setImagesList(images);
        setShowMoreButton(false);
    }, [images]);

    if (!images.length) {
        return null;
    }

    const iconContainerStyles: ViewStyle = {
        backgroundColor: theme["color-basic-alpha-60"],
    };

    const buttonStyles: ViewStyle = {
        width: size,
        height: size,
        borderColor: theme["color-positive-500"],
        backgroundColor: theme["color-positive-25"],
    };

    return (
        <View style={[styles.container, containerStyles]}>
            {imagesList.map((url, index) => (
                <Pressable
                    key={url}
                    style={styles.imageContainer}
                    onPress={() => onImageClick?.(index)}>
                    <CdnImage
                        id={url}
                        showLoader
                        width={size}
                        height={size}
                        optimizedDpr={optimizedDpr}
                        style={[imageStyles]}
                    />
                    <View style={[styles.iconContainer, iconContainerStyles]}>
                        <SvgIcon
                            icon="Expand"
                            width={iconSize}
                            height={iconSize}
                            color="color-basic-0"
                        />
                    </View>
                </Pressable>
            ))}
            {showMoreButton ? (
                <Pressable onPress={showAllImages} style={[styles.showMoreBtn, buttonStyles]}>
                    <Stack justifyContent="center" alignItems="center">
                        <Text
                            category="btn5"
                            color="color-positive-500">{`+ ${remainingImages}`}</Text>
                        <Text category="btn5" color="color-positive-500">
                            {MORE_LABEL}
                        </Text>
                    </Stack>
                </Pressable>
            ) : null}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
    },
    imageContainer: {
        borderRadius: 8,
        overflow: "hidden",
    },
    imageContainerSpacing: {
        marginRight: SpacingValue["space-small"],
        marginBottom: SpacingValue["space-small"],
    },
    iconContainer: {
        position: "absolute",
        bottom: 2,
        right: 2,
        borderRadius: 16,
        padding: SpacingValue["space-xx-small"],
    },
    showMoreBtn: {
        borderWidth: 1,
        borderRadius: 4,
        flexWrap: "wrap",
        borderStyle: "dashed",
        paddingVertical: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-x-small"],
    },
});
