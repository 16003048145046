import { IMAGEKIT_MEDIA_BASE_URL, IMAGEKIT_CLOUDNAME } from "@env";

import React, { useMemo } from "react";
import { ImageBackground, ImageStyle, StyleProp } from "react-native";

import { IKImageProps } from "../imagekit-image";
import { getIKImageUrl, formatImageId } from "../imagekit-image/helpers";

export type IKImageBackgroundProps = IKImageProps & {
    imageStyle?: StyleProp<ImageStyle>;
};

export const ImageKitImageBackground: React.FC<IKImageBackgroundProps> = ({
    id,
    resizeMode = "cover",
    width,
    height,
    children,
    resourceType: cloudResourceType = "upload",
    ...props
}) => {
    // This is for old assets
    const formattedId = formatImageId(id);

    const imageUrl = useMemo(
        () =>
            getIKImageUrl({
                id: formattedId,
                cloudBaseUrl: IMAGEKIT_MEDIA_BASE_URL,
                cloudName: IMAGEKIT_CLOUDNAME,
                cloudResourceType,
                height,
                width,
                children,
            }),
        [children, cloudResourceType, height, formattedId, width],
    );

    return (
        <ImageBackground
            {...props}
            source={{ uri: imageUrl, width, height, cache: "force-cache" }}
            resizeMode={resizeMode}>
            {children}
        </ImageBackground>
    );
};
