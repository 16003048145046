import { Endpoint, HttpCallParams, HttpClient } from "@swiggy-private/http-client";

import { ChatSdkError } from "../../chat-sdk-error";
import {
    ServerUser,
    ServerGrantTokenResponse,
    ServerHttpResponse,
    ServerLaunchResponse,
    ServerStartConversationResponse,
    ServerStartConversationRequest,
    ServerFetchAllConversationsRequest,
    ServerFetchAllConversationsResponse,
    ServerGetConversationRequest,
    ServerGetConversationResponse,
    ServerBlockConversationRequest,
    ServerBlockConversationResponse,
    UserMode,
    ServerUnreadCountRequest,
    ServerUnreadCountResponse,
    ServerSaveLastReadTimeRequest,
} from "../../interfaces/server";

export class ChatServer {
    private httpClient: HttpClient<ServerHttpResponse<unknown>>;
    private authCreds: Record<string, string>;
    private serverUrl: string;
    private userMode: UserMode = "merchant";

    constructor(url: string, userMode: UserMode, authCreds?: Record<string, string>) {
        this.serverUrl = url;
        this.httpClient = new HttpClient();
        this.authCreds = authCreds ?? {};
        this.userMode = userMode;
    }

    setAuthCreds(authCreds: Record<string, string>): void {
        this.authCreds = authCreds;
    }

    async launch(creds?: Record<string, string>): Promise<ServerLaunchResponse> {
        if (creds) {
            this.setAuthCreds(creds);
        }

        const endpoint = Endpoint.from(this.getEndpoint("launch"));

        return this.callServer({
            endpoint,
            options: {
                method: "GET",
            },
        });
    }

    async grantToken(creds?: Record<string, string>): Promise<ServerGrantTokenResponse> {
        if (creds) {
            this.setAuthCreds(creds);
        }

        const endpoint = Endpoint.from(this.getEndpoint("grant-token"));

        return this.callServer({
            endpoint,
            body: {},
            options: {
                method: "POST",
            },
        });
    }

    async getUser(uuid?: string): Promise<ServerUser> {
        const queryParams: Record<string, string> = uuid ? { id: uuid } : {};

        const endpoint = Endpoint.from(this.getEndpoint("get-user"));

        return this.callServer({
            endpoint,
            queryParams,
            options: {
                method: "GET",
            },
        });
    }

    async setUserDetails(details: Omit<ServerUser, "id">): Promise<ServerUser> {
        const endpoint = Endpoint.from(this.getEndpoint("update-user"));

        return this.callServer({
            endpoint,
            body: { ...details },
            options: {
                method: "POST",
            },
        });
    }

    async startConversation(
        params: ServerStartConversationRequest,
    ): Promise<ServerStartConversationResponse> {
        const endpoint = Endpoint.from(this.getEndpoint("conversations"));

        return this.callServer({
            endpoint,
            body: { ...params },
            options: {
                method: "POST",
            },
        });
    }

    async fetchAllConversations({
        nextPage,
        limit = 20,
    }: ServerFetchAllConversationsRequest): Promise<ServerFetchAllConversationsResponse> {
        const queryParams: Record<string, string> = nextPage
            ? { nextPage, limit: String(limit) }
            : { limit: String(limit) };

        const endpoint = Endpoint.from(this.getEndpoint("conversations"));

        return this.callServer({
            endpoint,
            queryParams,
            options: {
                method: "GET",
            },
        });
    }

    async getConversation(
        params: ServerGetConversationRequest,
    ): Promise<ServerGetConversationResponse> {
        const endpoint = Endpoint.from(this.getEndpoint(`conversations/${params.id}`));

        return this.callServer({
            endpoint,
            options: {
                method: "GET",
            },
        });
    }

    async blockConversation(
        params: ServerBlockConversationRequest,
    ): Promise<ServerBlockConversationResponse> {
        const endpoint = Endpoint.from(this.getEndpoint(`conversations/${params.id}`));

        const queryParams: Record<string, string> = {
            userMode: this.userMode.toUpperCase(),
        };

        const body: Record<string, unknown> = {
            blocked: params.blocked,
        };

        return this.callServer({
            endpoint,
            queryParams,
            body,
            options: {
                method: "PATCH",
            },
        });
    }

    async saveLastReadTime(params: ServerSaveLastReadTimeRequest): Promise<void> {
        const { conversationId } = params;

        const endpoint = Endpoint.from(
            this.getEndpoint(`conversations/${conversationId}/users/save-last-read`),
        );

        return this.callServer({
            endpoint,
            body: { lastReadAt: Date.now() },
            options: {
                method: "PATCH",
            },
        });
    }

    async getUnreadMessageCount(
        params: ServerUnreadCountRequest,
    ): Promise<ServerUnreadCountResponse> {
        const endpoint = Endpoint.from(this.getEndpoint("users/unread-count"));

        return this.callServer({
            endpoint,
            body: { ...params },
            options: {
                method: "POST",
            },
        });
    }

    private async callServer<T>(params: HttpCallParams): Promise<T> {
        try {
            const response = (await this.httpClient.call({
                ...params,
                queryParams: {
                    ...params.queryParams,
                    mode: this.userMode.toUpperCase(),
                },
                headers: {
                    ...this.authCreds,
                    ...params.headers,
                },
            })) as ServerHttpResponse<T>;

            if (response.statusCode !== 0) {
                throw new ChatSdkError(response.statusMessage ?? "something went wrong!!");
            }

            return response.data as T;
        } catch (err) {
            throw new ChatSdkError((err as Error).message);
        }
    }

    private getEndpoint(path: string): string {
        return `${this.serverUrl}/api/v1/chat/${path}`;
    }
}
