import type { HapticFeedbackTypes, HapticOptions } from "../interfaces/haptic";

type HapticFeedbackPackage = {
    trigger(type: HapticFeedbackTypes, options?: HapticOptions): void;
};

let HapticFeedbackPackage: HapticFeedbackPackage | null = null;
try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    HapticFeedbackPackage = require("react-native-haptic-feedback").default;
} catch (error) {}

export default HapticFeedbackPackage;
