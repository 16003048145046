import { useCallback } from "react";
import { useDebounceFn } from "@swiggy-private/react-hooks";

import { useSendSignal } from "./use-send-signal";

export const useSendTypingSignal = (conversationId: string): ((typing?: boolean) => void) => {
    const sendSignal = useSendSignal();
    const callback = useDebounceFn(
        useCallback(
            (typing?) => {
                sendSignal({ message: typing ? "typing_on" : "typing_off", conversationId });
            },
            [conversationId, sendSignal],
        ),
        { wait: 300 },
    );

    return callback;
};
