import React, { PureComponent, createRef } from "react";
import { findNodeHandle } from "react-native";
import IOContext from "./IOContext";
import IOManager from "./IOManager";

// TODO : convert this to FC with types
function withIO(Comp, methods) {
    // eslint-disable-next-line react/display-name
    const IOScrollableComponent = class extends PureComponent {
        node;
        scroller;
        root;
        manager;
        contextValue;
        constructor(props) {
            super(props);
            // eslint-disable-next-line consistent-this, @typescript-eslint/no-this-alias
            const self = this;
            this.scroller = createRef();
            this.root = {
                get node() {
                    return self.node;
                },
                get horizontal() {
                    return !!self.props.horizontal;
                },
                current: {
                    contentInset: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                    },
                    contentOffset: {
                        x: 0,
                        y: 0,
                    },
                    contentSize: {
                        width: 0,
                        height: 0,
                    },
                    layoutMeasurement: {
                        width: 0,
                        height: 0,
                    },
                    zoomScale: 1,
                },
            };
            const manager = new IOManager({
                root: this.root,
                get rootMargin() {
                    return self.props.rootMargin;
                },
            });
            this.manager = manager;
            this.contextValue = {
                manager,
            };
        }
        componentDidMount() {
            this.node = findNodeHandle(this.scroller.current);
            methods.forEach((method) => {
                this[method] = (...args) => {
                    this.scroller.current?.[method]?.(...args);
                };
            });
        }
        /*
        handleContentSizeChange: Monitors changes in the content size of the ScrollView.
        It updates the internal state and invokes the onContentSizeChange prop if provided.
        */
        handleContentSizeChange = (width, height) => {
            const { contentSize } = this.root.current;
            if (width !== contentSize.width || height !== contentSize.height) {
                this.root.current.contentSize = { width, height };
                if (width > 0 && height > 0 && this.root.onLayout) {
                    this.root.onLayout();
                }
            }
            const { onContentSizeChange } = this.props;
            if (onContentSizeChange) {
                onContentSizeChange(width, height);
            }
        };
        /*
        handleLayout: Monitors changes in the layout of the ScrollView.
        It updates the internal state and invokes the onLayout prop if provided.
        */
        handleLayout = (event) => {
            const {
                nativeEvent: { layout },
            } = event;
            const { layoutMeasurement } = this.root.current;
            if (
                layoutMeasurement.width !== layout.width ||
                layoutMeasurement.height !== layout.height
            ) {
                this.root.current.layoutMeasurement = layout;
            }
            const { onLayout } = this.props;
            if (onLayout) {
                onLayout(event);
            }
        };
        /*
        handleScroll: Monitors scroll events of the ScrollView.
         It updates the internal state and invokes the onScroll prop if provided.
        */
        handleScroll = (event) => {
            this.root.current = event.nativeEvent;
            if (this.root.onScroll) {
                this.root.onScroll(this.root.current);
            }
            const { onScroll } = this.props;
            if (onScroll) {
                onScroll(event);
            }
        };
        /*
        The IOScrollableComponent renders the wrapped Comp component, passing down the original props, along with the enhanced event handlers and other context data.
        The component is wrapped in an IOContext.Provider to provide the Intersection Observer context to the enhanced component.
         */
        render() {
            return React.createElement(
                IOContext.Provider,
                { value: this.contextValue },
                React.createElement(Comp, {
                    scrollEventThrottle: 16,
                    ...this.props,
                    ref: this.scroller,
                    onContentSizeChange: this.handleContentSizeChange,
                    onLayout: this.handleLayout,
                    onScroll: this.handleScroll,
                }),
            );
        }
    };
    return IOScrollableComponent;
}
export default withIO;
