import * as React from "react";
import type { LayoutChangeEvent } from "react-native";
import { useMountedRef } from "@swiggy-private/react-hooks";

export interface Layout {
    height: number;
    width: number;
    measured: boolean;
}

export const useLayout = (): readonly [Layout, (e: LayoutChangeEvent) => void] => {
    const mounted = useMountedRef();
    const [layout, setLayout] = React.useState<Layout>({
        height: 0,
        width: 0,
        measured: false,
    });

    const onLayout = React.useCallback(
        (e: LayoutChangeEvent) => {
            if (!mounted.current) {
                return;
            }

            const { height, width } = e.nativeEvent.layout;
            if (height === layout.height && width === layout.width) {
                return;
            }

            setLayout({
                height,
                width,
                measured: true,
            });
        },
        [layout.height, layout.width, mounted],
    );

    return [layout, onLayout] as const;
};
