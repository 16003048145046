import { useEffect } from "react";
import { BackHandler } from "react-native";

export const useBackListener = (fn: () => boolean | null): void => {
    useEffect(() => {
        const subscription = BackHandler.addEventListener("hardwareBackPress", fn);
        return (): void => {
            if (subscription == null) {
                BackHandler.removeEventListener("hardwareBackPress", fn);
            } else {
                subscription.remove();
            }
        };
    }, [fn]);
};
