import React from "react";
import { StyleSheet, View, ViewStyle, ColorValue, ViewProps } from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls";

export interface DashBorderLineProps {
    borderColor: ColorValue;
    style?: ViewStyle;
    type?: "dashed" | "dotted" | "solid";
}

export const DashBorderLine: React.FC<ViewProps & DashBorderLineProps> = ({
    borderColor,
    style,
    type = "dashed",
    ...props
}) => {
    const borderType = { borderStyle: type };
    return (
        <View style={[styles.container, style]} {...props}>
            <View
                style={[
                    styles.dashedBorder,
                    {
                        borderColor,
                    },
                    borderType,
                ]}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        height: 1,
        overflow: "hidden",
        marginTop: SpacingValue["space-x-large"],
    },
    dashedBorder: {
        height: 2,
        borderWidth: 1,
    },
});
