import { SdkSendSignalParams } from "@swiggy-private/connect-chat-sdk";
import { useCallback } from "react";

import { logError } from "../helpers/log";
import { useChatSdk } from "./use-chatsdk";

export const useSendSignal = (): ((params: SdkSendSignalParams) => void) => {
    const sdk = useChatSdk();
    const sendSignal = useCallback(
        (params: SdkSendSignalParams) => sdk?.sendSignal(params).catch(logError),
        [sdk],
    );
    return sendSignal;
};
