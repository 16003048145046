import { IMAGEKIT_CLOUDNAME, IMAGEKIT_MEDIA_BASE_URL } from "@env";

import { SvgIconType } from "@swiggy-private/connect-svg-icons";

export const MAX_VIDEO_LAYOUT_SIZE = 60; // px
export const PREVIEW_CONTROL_SIZE = {
    CONTAINER: { LARGE: 48, SMALL: 24 },
    ICON: { LARGE: 18, SMALL: 9 },
};
export const TEST_ID = {
    VIDEO_PREVIEW: "video-preview",
    VIDEO_PREVIEW_THUMBNAIL: "video-preview-thumbnail",
    VIDEO_PREVIEW_CONTROL: "video-preview-control",
};
export const IMAGE_PLACEHOLDER = "minis/catalog_item_placeholder";
export const PRODUCT_IMG_FALLBACK = "minis/product_img_fallback";

// * Default Values to Production
export const IK_PROPS = {
    IMAGEKIT_MEDIA_BASE_URL: IMAGEKIT_MEDIA_BASE_URL,
    IMAGEKIT_CLOUDNAME: IMAGEKIT_CLOUDNAME,
};

export const VIDEO_CONTROL_ICON: Record<string, SvgIconType["icon"]> = {
    play: "Play",
    pause: "Paused",
};

export const SAMPLE_TEST_VIDEO = "https://filesamples.com/samples/video/mov/sample_640x360.mov";
