import React from "react";
import { StyleSheet } from "react-native";

import { Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { ApplyCouponCTA } from "./apply-coupon-cta";
import { CouponCode } from "./coupon-code";
import { CouponCardProps } from "../types";
import { CdnImage } from "../../cdn-image";
import { DESCRIPTION_EMPTY_STRING, DEFAULT_VALUES } from "../constants";

const IMAGE_SIZE = 60;

const { COUPON_ROUND_RADIUS } = DEFAULT_VALUES;

export const CouponContent: React.FC<CouponCardProps> = (props) => {
    const { value: theme } = useTheme();

    const couponTag = props.options?.couponTag;

    const backgroundColor = React.useMemo(() => {
        if (props?.options?.isActive ?? true) {
            if (!props?.options?.brandColor) {
                return theme["color-primary-400"];
            }
            return props.options.brandColor ?? theme["color-primary"];
        } else {
            if (props.footer?.onToggle) {
                return theme["color-basic-15"];
            }
            return theme["color-basic-30"];
        }
    }, [props.footer, props.options, theme]);

    if (!props.options) {
        return null;
    }

    const { brandImageId, brandImageFallbackIcon = "Store" } = props?.options;

    if (!props.textContent) {
        return null;
    }

    const { title, titleTextProps, titleStyle, description, descriptionProps } = props.textContent;
    const footer = props?.footer;
    const hasFooter = footer?.text || footer?.onToggle || props?.footerComponent;

    const descriptionStyle = {
        paddingBottom:
            description === DESCRIPTION_EMPTY_STRING
                ? SpacingValue["space-xx-large"]
                : SpacingValue["space-small"],
    };

    const contentRightStyle = {
        backgroundColor,
        borderBottomRightRadius: hasFooter ? 0 : COUPON_ROUND_RADIUS,
    };

    const contentLeftStyle = {
        borderBottomLeftRadius: hasFooter ? 0 : COUPON_ROUND_RADIUS,
    };

    const imgWrapperStyle = {
        backgroundColor: theme["color-background-primary"],
        borderColor: theme["color-basic-15"],
    };

    return (
        <Box direction="row">
            <Box flex={1} style={[styles.contentLeft, contentLeftStyle]}>
                <Stack direction="row" spacing={3 * SpacingValue["space-xxx-small"]}>
                    <Text {...titleTextProps} style={titleStyle}>
                        {title}
                    </Text>
                    {couponTag?.canShowTag ? (
                        <CdnImage
                            id={couponTag.imgId}
                            height={couponTag.height}
                            width={couponTag.width}
                            style={couponTag.tagStyle}
                        />
                    ) : null}
                </Stack>
                <Text {...descriptionProps} style={descriptionStyle}>
                    {description}
                </Text>
                <CouponCode {...props} />
                {props.activateCoupon?.onActivate ? <ApplyCouponCTA {...props} /> : null}
            </Box>

            <Box
                direction="row"
                style={[styles.contentRight, contentRightStyle]}
                justifyContent="space-between">
                <Box style={[styles.brandImage, imgWrapperStyle, props.options?.imgContainerStyle]}>
                    {brandImageId ? (
                        <CdnImage
                            height={IMAGE_SIZE}
                            width={IMAGE_SIZE}
                            id={brandImageId}
                            resizeMode="cover"
                        />
                    ) : (
                        <SvgIcon icon={brandImageFallbackIcon} color={"color-basic-30"} />
                    )}
                </Box>
                <Box style={styles.patternContainer}>
                    {[1, 2, 3].map((e) => {
                        return (
                            <Box
                                key={e}
                                style={[
                                    styles.pattern,
                                    { backgroundColor: theme["color-basic-5"] },
                                    // eslint-disable-next-line react-native/no-inline-styles
                                    { marginBottom: e === 3 ? 0 : SpacingValue["space-x-small"] },
                                    props.options?.patternStyle,
                                ]}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    contentLeft: {
        borderTopLeftRadius: COUPON_ROUND_RADIUS,
        borderBottomLeftRadius: COUPON_ROUND_RADIUS,
        padding: SpacingValue["space-medium"],
        paddingRight: SpacingValue["space-medium"],
    },
    contentRight: {
        borderTopRightRadius: COUPON_ROUND_RADIUS,
        justifyContent: "space-between",
        alignItems: "center",
        padding: SpacingValue["space-medium"],
    },
    brandImage: {
        width: IMAGE_SIZE,
        height: IMAGE_SIZE,
        borderRadius: 12,
        borderWidth: 1.5,
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
    },
    patternContainer: {
        position: "absolute",
        right: 0,
    },
    pattern: {
        width: 12,
        height: 12,
        borderRadius: 6,
        left: 6,
        marginBottom: SpacingValue["space-x-small"],
    },
});
