import { SvgIconType } from "@swiggy-private/connect-svg-icons";

export const DEFAULT_VALUES = {
    RATING_COUNT: 0,
    TOTAL_STARS: 5,
    ICON_SIZE: 24,
    STAR_ICON_COLOR: "color-secondary-50" as SvgIconType["color"],
    STAR_ICON_FILL_COLOR: "color-primary" as SvgIconType["color"],
    UNSELECTED_STAR_ICON: "Star" as SvgIconType["icon"],
    SELECTED_STAR_ICON: "StarFilled" as SvgIconType["icon"],
};

export const TEST_IDS = {
    CONTAINER: "rating",
};

export const STAR_ICONS = {
    FILLED: "StarFilled",
    BLANK: "Star",
};
