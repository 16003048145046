import type { GeoPosition } from "./types";

export const autoLocate = async (): Promise<GeoPosition | null> => {
    return new Promise(async (resolve, reject) => {
        try {
            await navigator.permissions.query({ name: "geolocation" }).then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                    return;
                } else {
                    resolve(null);
                }
            });
        } catch (err) {
            return reject(err);
        }
    });
};
