import * as React from "react";
import { Box, BoxProps } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";
import { ViewStyle } from "react-native";

import { useTheme } from "../../styles/theme-service";

export interface DialogContentProps extends BoxProps {
    /** Display the top and bottom dividers. */
    dividers?: boolean;
}

export type DialogContentElement = React.ReactElement<DialogContentProps, typeof DialogContent>;

export const DialogContent: React.FC<DialogContentProps> = ({
    dividers,
    ph = SpacingValue["space-medium"],
    pv = SpacingValue["space-large"],
    style,
    ...props
}) => {
    const { value: theme } = useTheme();
    const dividerStyle: ViewStyle | null = dividers
        ? {
              borderTopWidth: 1,
              borderBottomWidth: 1,
              borderTopColor: theme["color-basic-5"],
              borderBottomColor: theme["color-basic-5"],
          }
        : null;

    return <Box ph={ph} pv={pv} style={[dividerStyle, style]} {...props} />;
};
