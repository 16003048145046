import React, { useCallback, useEffect, useMemo } from "react";
import {
    SafeAreaView,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
    NativeSyntheticEvent,
    NativeScrollEvent,
} from "react-native";

import {
    GenericErrorView,
    getErrorDescription,
    getErrorTitle,
} from "@swiggy-private/react-native-ui";
import { ActivityIndicator, SpacingValue } from "@swiggy-private/rn-dls";

import {
    ChatConversationListView,
    ChatConversationListViewProps,
} from "../../components/conversation-list-view";
import {
    useFetchConversations,
    useFetchRecentConversations,
} from "../../hooks/use-fetch-conversations";
import { useSortConversationsByLastMessageTime } from "../../hooks/use-prepare-conversation";
import { ScreenLoader } from "../../components/screen-loader";
import { useChatRenderer } from "../../hooks/use-chatrender";
import { Conversation, IChatSearchInputStyles } from "../../interfaces";
import { useFetchConversationUnreadCount } from "../../hooks/use-conversation-unread";
import { useGetUserOnlineStatus } from "../../hooks/use-get-user-online-status";
import { logError } from "../../helpers/log";
import { useChatSdk } from "../../hooks/use-chatsdk";

type ChatConversationListScreenProps = {
    testID?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
    style?: StyleProp<ViewStyle>;
    onConversationPress: (conversation: Conversation) => void;
    avatarPlaceholderImageId?: string;
    storeId?: string;
    onConversationListLoad?: VoidFunction;
    onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
    canShowGuestUserConversation?: boolean;
    searchWidgetStyles?: IChatSearchInputStyles;
    iconSize?: number;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ChatConversationListHandler = {};

export const ChatConversationListScreen = React.forwardRef<
    ChatConversationListHandler,
    ChatConversationListScreenProps
>((props, _ref) => {
    const {
        ListEmptyComponent,
        style,
        testID,
        onConversationPress,
        avatarPlaceholderImageId,
        storeId,
        onConversationListLoad,
        onScroll,
        canShowGuestUserConversation,
        searchWidgetStyles,
        iconSize,
    } = props;

    const sdk = useChatSdk();
    const [conversations, { loading, error, fetch }] = useFetchConversations(storeId);
    const sortedConversations = useSortConversationsByLastMessageTime(conversations);

    const conversationIds = useMemo(
        () => sortedConversations.map((c) => c.id),
        [sortedConversations],
    );

    useFetchConversationUnreadCount(conversationIds);
    useFetchRecentConversations();

    useEffect(() => {
        if (conversations.length > 0) {
            onConversationListLoad?.();
        }
    }, [conversations, onConversationListLoad]);

    const getUserOnlineStatus = useGetUserOnlineStatus();
    const onEndReached = useCallback(() => fetch(true), [fetch]);

    const ConversationListView =
        useChatRenderer()?.renderConversationListContainer ?? ChatConversationListView;

    const FooterComponent: React.FC = useCallback(
        () =>
            loading ? (
                <View style={styles.loading}>
                    <ActivityIndicator size={"small"} />
                </View>
            ) : null,
        [loading],
    );

    const viewabilityConfig = useMemo(
        () => ({
            waitForInteraction: false,
            minimumViewTime: 500,
            itemVisiblePercentThreshold: 50,
        }),
        [],
    );

    const onViewableItemsChanged: NonNullable<
        ChatConversationListViewProps["onViewableItemsChanged"]
    > = useCallback(
        ({ viewableItems }) => {
            if (!sdk) {
                return;
            }

            const viewConversationIds = viewableItems.map((item) => item.key);
            getUserOnlineStatus(viewConversationIds).catch(logError);

            const subscribedConversationIds = sdk.getSubscribedConversations();

            if (subscribedConversationIds.length < 25) {
                sdk.subscribe({
                    conversationIds: viewConversationIds,
                });
            }
        },
        [getUserOnlineStatus, sdk],
    );

    if (!sortedConversations.length && loading) {
        return (
            <SafeAreaView style={styles.container}>
                <ScreenLoader style={style} />
            </SafeAreaView>
        );
    }

    if (!sortedConversations.length && error) {
        return (
            <GenericErrorView
                title={getErrorTitle(error)}
                message={getErrorDescription(error)}
                onRetry={fetch}
            />
        );
    }

    return (
        <ConversationListView
            conversations={sortedConversations}
            style={style}
            ListEmptyComponent={ListEmptyComponent}
            onEndReached={onEndReached}
            testID={testID}
            onConversationPress={onConversationPress}
            avatarPlaceholderImageId={avatarPlaceholderImageId}
            ListFooterComponent={FooterComponent}
            viewabilityConfig={viewabilityConfig}
            onViewableItemsChanged={onViewableItemsChanged}
            canShowGuestUserConversation={canShowGuestUserConversation}
            onScroll={onScroll}
            searchWidgetStyles={searchWidgetStyles}
            iconSize={iconSize}
        />
    );
});

ChatConversationListScreen.displayName = "ChatConversationListScreen";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    loading: {
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        marginTop: SpacingValue["space-large"],
    },
});
