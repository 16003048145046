import React from "react";
import { ColorValue, GestureResponderEvent, ImageProps, Pressable, StyleSheet } from "react-native";
import { SvgProps } from "react-native-svg";

export type SvgIconType = Partial<ImageProps> & { icon: React.FC<SvgProps> };

export const SvgIcon: React.FC<SvgIconType> = ({ style, icon: Icon, ...props }) => {
    const s = StyleSheet.flatten(style);
    return <Icon {...props} style={s} fill={s?.tintColor as ColorValue} />;
};

export const PressableIcon: React.FC<
    SvgIconType & { onPress?: (e: GestureResponderEvent) => void }
> = ({ onPress, ...props }) => (
    <Pressable onPress={onPress}>
        <SvgIcon {...props} />
    </Pressable>
);
