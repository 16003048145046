import { useCallback } from "react";
import type { SdkMessage, ChatSdk } from "@swiggy-private/connect-chat-sdk";
import bigInt from "big-integer";

import { useChatDispatch } from "../hooks/use-chatdispatch";
import { useChatSdk } from "./use-chatsdk";
import { ISentMessageResponse } from "../interfaces/types";

const prepareTextMessage = (
    sdk: ChatSdk,
    conversationId: string,
    text: string,
): SdkMessage | null => {
    text = text.trim();
    if (!text.length) {
        return null;
    }

    const message = sdk.prepareMessage({ type: "text", payload: { text } });

    return message;
};

export const useSendMessage = (
    conversationId: string,
): ((msg: string | SdkMessage) => ISentMessageResponse | undefined) => {
    const sdk = useChatSdk();
    const chatDispatch = useChatDispatch();

    const sendMessage = useCallback(
        (msg: string | SdkMessage | null): ISentMessageResponse | undefined => {
            if (!sdk) {
                return;
            }

            if (typeof msg === "string") {
                msg = prepareTextMessage(sdk, conversationId, msg) as SdkMessage | null;
            }

            if (msg) {
                sdk.sendMessage({ conversationId, message: msg });

                chatDispatch({
                    type: "ADD_CONVERSATION_MESSAGE_ACTION",
                    payload: {
                        conversationId,
                        messages: [
                            {
                                ...msg,
                                publisher: sdk.getUUID(),
                                timetoken: bigInt(msg.timestamp).multiply(10_000).toString(),
                            },
                        ],
                    },
                });

                return { conversationId, message: msg };
            }

            return;
        },
        [chatDispatch, conversationId, sdk],
    );

    return sendMessage;
};
