import React from "react";
import { Pressable, StyleSheet, ViewStyle } from "react-native";

import { Text, SpacingValue, useTheme, Toggle } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

import { CouponCardProps } from "../types";
import { COUPON_VISIBILITY_COPY, SHARE } from "../constants";

const hitSlop = { left: 100, right: 100 };

export const CouponFooter: React.FC<CouponCardProps> = React.memo((props) => {
    const { value: theme } = useTheme();

    if (!props.footer) {
        return null;
    }

    const isActive = props?.options?.isActive ?? true;
    const {
        onToggle,
        onShare,
        text: footerText,
        style: footerStyle,
        toggleBtnText,
        isHidden = false,
        segmentInfo,
        isDisabled,
    } = props?.footer;

    if (!footerText && !onToggle) {
        return null;
    }

    const style = {
        backgroundColor: theme["color-background-primary"],
        paddingVertical: footerText ? SpacingValue["space-x-small"] : SpacingValue["space-medium"],
        paddingHorizontal: footerText
            ? SpacingValue["space-x-large"]
            : SpacingValue["space-medium"],
    };

    const toggleBoxStyle: ViewStyle = {
        opacity: isDisabled ? 0.5 : 1,
    };

    const extraThumbStyle: ViewStyle = {
        elevation: 0,
    };

    return (
        <Stack>
            <Box style={[styles.footer, style, footerStyle]}>
                {footerText ? (
                    <Text category="b3" style={styles.footerText}>
                        {footerText}
                    </Text>
                ) : null}

                <Box style={toggleBoxStyle}>
                    {onToggle ? (
                        <Box direction="row" justifyContent="space-between" alignItems="center">
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={SpacingValue["space-x-small"]}>
                                <Toggle
                                    color={!isActive ? "secondary" : "primary"}
                                    checked={isActive}
                                    onChange={onToggle}
                                    disabled={isDisabled}
                                    extraThumbStyle={extraThumbStyle}
                                />
                                <Text
                                    category="b2"
                                    color={isActive ? "color-primary" : "color-basic-75"}>
                                    {toggleBtnText}
                                </Text>
                            </Stack>

                            {onShare && isActive ? (
                                <Pressable
                                    onPress={onShare}
                                    hitSlop={hitSlop}
                                    disabled={isDisabled}>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={SpacingValue["space-x-small"]}>
                                        <SvgIcon
                                            icon={"Share"}
                                            color="color-basic-60"
                                            width="16"
                                            height={16}
                                        />
                                        <Text category="b2" color="color-basic-60">
                                            {SHARE}
                                        </Text>
                                    </Stack>
                                </Pressable>
                            ) : null}
                        </Box>
                    ) : null}
                </Box>
            </Box>
            <CouponVisibilityInfo
                isActive={isActive}
                isHidden={isHidden}
                segmentInfo={segmentInfo}
            />
        </Stack>
    );
});

if (__DEV__) {
    CouponFooter.displayName = "CouponFooter";
}

type CouponVisibilityInfoProps = {
    isActive: boolean;
    isHidden: boolean;

    segmentInfo?: string;
};

const CouponVisibilityInfo: React.FC<CouponVisibilityInfoProps> = React.memo(
    ({ isActive, isHidden, segmentInfo }) => {
        const { value: theme } = useTheme();

        const visibilityMessage = React.useMemo(() => {
            if (isHidden) {
                return COUPON_VISIBILITY_COPY.SHARED_CODE;
            }

            return COUPON_VISIBILITY_COPY.PUBLIC;
        }, [isHidden]);

        const isSecretCodeActive = React.useMemo(() => isHidden && isActive, [isHidden, isActive]);

        const bgColor = React.useMemo(
            () => (isSecretCodeActive ? theme["color-basic-60"] : theme["color-basic-15"]),
            [isSecretCodeActive, theme],
        );

        const textColor = React.useMemo(
            () => (isSecretCodeActive ? "color-basic-5" : "color-basic-60"),
            [isSecretCodeActive],
        );

        const iconColor = React.useMemo(
            () => (isSecretCodeActive ? "color-basic-0" : "color-basic-60"),
            [isSecretCodeActive],
        );

        return (
            <Stack
                flex={1}
                direction="row"
                spacing={3 * SpacingValue["space-xxx-small"]}
                style={[styles.infoBanner, { backgroundColor: bgColor }]}>
                <SvgIcon
                    icon={isHidden ? "EyeHideOutline" : "Eye"}
                    color={iconColor}
                    width="12"
                    height="12"
                    style={styles.svg}
                />
                <Box flex={1}>
                    <Text category="b3" color={textColor} weight={"regular"}>
                        {`${visibilityMessage}${segmentInfo?.length ? ` ${segmentInfo}` : ""}`}
                    </Text>
                </Box>
            </Stack>
        );
    },
);

if (__DEV__) {
    CouponVisibilityInfo.displayName = "CouponVisibilityInfo";
}

const styles = StyleSheet.create({
    footer: {
        padding: SpacingValue["space-medium"],
    },
    footerText: { textAlign: "center" },
    infoBanner: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-small"],
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
    },
    svg: {
        marginTop: SpacingValue["space-xxx-small"],
    },
});
