import { useMemo } from "react";
import { useChatState } from "./use-chatstate";

export const useConversationLastReadTime = (conversationId: string): number | null => {
    const state = useChatState();

    const meta = useMemo(
        () => state.conversationsMeta[conversationId] ?? { indicators: {} },
        [conversationId, state],
    );

    return meta.lastReadTimestamp || null;
};
