import { useCallback, useEffect, useRef, useState } from "react";

import { ThrottleOptions } from "../interfaces/throttle-options";

type Callback<T> = (...args: T[]) => void;

export const useThrottleFn = <T>(callback: Callback<T>, options: ThrottleOptions): Callback<T> => {
    const [ready, setReady] = useState(true);
    const timerRef = useRef<number | undefined>(undefined);

    const wait = options.wait ?? 500;

    const throttledFunction = useCallback(
        (...args: T[]) => {
            if (!ready) {
                return;
            }

            setReady(false);
            callback(...args);
        },
        [ready, callback],
    );

    useEffect(() => {
        if (!ready) {
            timerRef.current = window.setTimeout(() => {
                setReady(true);
            }, wait);

            return () => window.clearTimeout(timerRef.current);
        }

        return;
    }, [ready, wait]);

    return throttledFunction;
};
