import { Platform } from "react-native";

import { HttpError } from "./error";
import { HttpRequestInterceptor, HttpResponseInterceptor, HttpCallParams } from "./interceptor";

const DEFAULT_HEADERS: Record<string, string> =
    Platform.OS === "web"
        ? {}
        : {
              "User-Agent": "minis-http-client",
          };

const JSON_DEFAULT_HEADERS: Record<string, string> = {
    "Content-Type": "application/json",
};

export interface HttpClientOpts<T> {
    requestInterceptors?: HttpRequestInterceptor[];
    responseInterceptors?: HttpResponseInterceptor<T>[];
    timeout?: number;
}

export class HttpClient<R> {
    private requestInterceptors: HttpRequestInterceptor[];
    private responseInterceptors: HttpResponseInterceptor<R>[];

    private timeoutInMs: number;

    public static globalRequestInterceptors: HttpRequestInterceptor[] = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public static globalResponseInterceptors: HttpResponseInterceptor<any>[] = [];

    constructor(opts: HttpClientOpts<R> = {}) {
        this.requestInterceptors = opts.requestInterceptors ?? [];
        this.responseInterceptors = opts.responseInterceptors ?? [];
        this.timeoutInMs = opts.timeout ?? 10_000;
    }

    public async get(params: HttpCallParams): Promise<R> {
        return this.call({
            ...params,
            options: {
                ...params.options,
                method: "GET",
            },
        });
    }

    public async post(params: HttpCallParams): Promise<R> {
        return this.call({
            ...params,
            options: {
                ...params.options,
                method: "POST",
            },
        });
    }

    public async put(params: HttpCallParams): Promise<R> {
        return this.call({
            ...params,
            options: {
                ...params.options,
                method: "PUT",
            },
        });
    }

    public async patch(params: HttpCallParams): Promise<R> {
        return this.call({
            ...params,
            options: {
                ...params.options,
                method: "PATCH",
            },
        });
    }

    public async delete(params: HttpCallParams): Promise<R> {
        return this.call({
            ...params,
            options: {
                ...params.options,
                method: "DELETE",
            },
        });
    }

    public async call(params: HttpCallParams): Promise<R> {
        // apply request interceptors
        const callParams = [
            ...HttpClient.globalRequestInterceptors,
            ...this.requestInterceptors,
        ].reduce((p, i) => i(p), params);

        const {
            queryParams,
            endpoint: endpointParam,
            body,
            options,
            headers,
            timeout,
            json = true,
        } = callParams;

        const endpoint = endpointParam.toString();

        const uri = this.prepareEndpoint(endpoint, queryParams);
        const requestHeaders = this.removeEmptyFields({
            ...DEFAULT_HEADERS,
            ...(json ? JSON_DEFAULT_HEADERS : {}),
            ...headers,
        });

        const requestBody =
            body instanceof FormData || typeof body === "string" || body instanceof URLSearchParams
                ? body
                : typeof body === "object" && body != null
                ? json
                    ? JSON.stringify(body)
                    : body
                : undefined;

        const controller = new AbortController();
        const signal = controller.signal;

        const abortTimer = setTimeout(() => controller.abort(), timeout ?? this.timeoutInMs);

        try {
            let originalResponse: Response | null = null;
            let response: R = await fetch(uri, {
                signal,
                headers: requestHeaders,
                body: requestBody as BodyInit,
                ...options,
            }).then((res) => {
                originalResponse = res;
                return json ? res.json() : res;
            });

            clearTimeout(abortTimer);

            // response interceptors
            if (originalResponse != null) {
                const interceptors = [
                    ...HttpClient.globalResponseInterceptors,
                    ...this.responseInterceptors,
                ];

                for await (const i of interceptors) {
                    response = await i.call(null, response, callParams, originalResponse);
                }
            }

            return response;
        } catch (ex) {
            clearTimeout(abortTimer);
            throw new HttpError((ex as Error).message, ex as Error);
        }
    }

    private prepareEndpoint(endpoint: string, queryParams?: Record<string, string>): string {
        if (!queryParams || Object.keys(queryParams).length === 0) {
            return endpoint;
        }

        const params: Record<string, string> = {};
        for (const [key, value] of Object.entries(queryParams)) {
            if (value == null || (typeof value === "string" && !value.length)) {
                continue;
            }

            params[key] = value;
        }

        return `${endpoint}?${new URLSearchParams(params)}`;
    }

    private removeEmptyFields(obj: Record<string, string | null>): Record<string, string> {
        const returnObj: Record<string, string> = {};
        for (const [key, value] of Object.entries(obj)) {
            if (value != null && value.length) {
                returnObj[key] = value;
            }
        }

        return returnObj;
    }
}
