import { useCallback, useState } from "react";

type Errors = Record<string, string | undefined>;
type SetErrors = (errors: Errors) => void;
type RemoveError = (errorName: string) => void;

export const useFormError = (initialErrors?: Errors): [Errors, SetErrors, RemoveError] => {
    const [errors, setErrors] = useState(initialErrors ?? {});
    const removeError = useCallback((name: string) => {
        setErrors((errs) => {
            if (errs[name] != null) {
                delete errs[name];
                return { ...errs };
            }

            return errs;
        });
    }, []);

    return [errors, setErrors, removeError];
};
