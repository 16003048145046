import React from "react";
import { View, Pressable, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";
import { Box } from "@swiggy-private/rn-adaptive-layout";

type Props = {
    addressTitle: string | null;
    streetAddress: string | null;
    navigationLabel: string | null;
    handleNavigation: () => void;
    style?: StyleProp<ViewStyle>;
    onMount?: () => void;
};

export const MapAddressPill: React.FC<Props> = ({
    addressTitle,
    streetAddress,
    navigationLabel,
    handleNavigation,
    style,
    onMount,
}) => {
    const { value: theme } = useTheme();

    const streetAddressPlaceholderStyle = {
        backgroundColor: streetAddress ? theme["color-basic-0"] : theme["color-basic-5"],
    };

    useMount(() => {
        onMount?.();
    });

    return (
        <View
            style={[
                styles.mainContainer,
                { backgroundColor: theme["color-background-primary"] },
                style,
            ]}>
            <View style={styles.firstRow}>
                <View style={styles.titleContainer}>
                    <SvgIcon
                        color="color-tertiary-400"
                        accessibilityLabel="Location"
                        accessible
                        icon="LocationFilled"
                        height={18}
                        width={18}
                        style={styles.svg}
                    />

                    <Box flex={1}>
                        <Text category="h5" weight="bold" color="color-basic-100" numberOfLines={1}>
                            {addressTitle || "Please wait..."}
                        </Text>
                    </Box>
                </View>
                {navigationLabel ? (
                    <Pressable onPress={handleNavigation}>
                        <Text category="b2" weight="bold" color="color-primary-400">
                            {navigationLabel}
                        </Text>
                    </Pressable>
                ) : null}
            </View>
            <View style={[styles.secondRow, streetAddressPlaceholderStyle]}>
                {streetAddress ? (
                    <Text category="b2" color="color-basic-60" numberOfLines={1}>
                        {streetAddress}
                    </Text>
                ) : (
                    <View style={[styles.emptyView, { backgroundColor: theme["color-basic-5"] }]} />
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        borderRadius: 12,
        justifyContent: "space-evenly",
        paddingVertical: SpacingValue["space-small"],
    },
    firstRow: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    svg: {
        marginRight: SpacingValue["space-xx-small"],
    },
    titleContainer: {
        width: "70%",
        flexDirection: "row",
    },
    secondRow: {
        marginRight: 26,
        borderRadius: 8,
    },
    emptyView: {
        height: SpacingValue["space-x-large"],
        width: "100%",
        borderRadius: 8,
    },
});
