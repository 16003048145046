/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Animated, StyleProp, ViewStyle, StyleSheet, ViewToken } from "react-native";
import { useFocusEffect } from "@react-navigation/native";

import { useAnimatedValue, useLayout } from "@swiggy-private/rn-dls";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { useAppStateListener } from "@swiggy-private/react-native-ui";

import { getTimeRemaining } from "./helpers";
import { useAnimationLoop } from "./hooks/use-animated-loop";
import { ChildrenClone } from "./components/clone";
import { ViewableItemsContext } from "./contexts/viewable-items-context";

export interface WidgetAnimatorProps {
    children: React.ReactElement;
    widgetId: string;

    animate?: boolean;
    horizontal?: boolean;
    style?: StyleProp<ViewStyle>;
    dividerWidth?: number;
    speed?: number;
    delay?: number;
    isLTR?: boolean;
    cloneOffset?: number;
}

const WidgetAnimatorComponent: React.FC<WidgetAnimatorProps> = ({
    style,
    children,
    dividerWidth = 0,
    speed = 75,
    isLTR = false,
    horizontal = true,
    widgetId,
    animate = true,
    cloneOffset = 0,
}) => {
    const animatedValue = useAnimatedValue(0);
    const mounted = useMountedRef();
    const [layout, setLayout] = useLayout();
    const animationFn = useAnimationLoop();

    const context = React.useContext(ViewableItemsContext);

    const [scroll, setScroll] = React.useState(false);

    const contentWidth = React.useRef(0);
    const positionRef = React.useRef(0);
    const timeRemainingRef = React.useRef(0);
    const pauseStateRef = React.useRef(false);
    const timerRef = React.useRef<number>();

    const startAnimation = React.useCallback((newContentWidth: number) => {
        if (!newContentWidth) {
            setScroll(false);
            return;
        }

        contentWidth.current = newContentWidth;

        setScroll(true);

        const marqueeWidth = contentWidth.current + dividerWidth;
        const toValue = isLTR ? 0 : -marqueeWidth;

        animatedValue.setValue(isLTR ? -marqueeWidth : 0);

        animationFn({
            animatedValue,
            toValue,
            duration: speed * marqueeWidth,
        });
    }, []);

    const pauseAnimation = React.useCallback(() => {
        animatedValue.setValue(positionRef.current);

        const totalWidth = contentWidth.current + dividerWidth;
        const totalDuration = speed * totalWidth;
        const portionLeft = isLTR ? -positionRef.current : totalWidth + positionRef.current;

        timeRemainingRef.current = getTimeRemaining(totalWidth, totalDuration, portionLeft);

        pauseStateRef.current = true;
    }, []);

    const resumeAnimation = React.useCallback(() => {
        if (!mounted || !pauseStateRef.current) {
            return;
        }

        clearTimeout(timerRef.current);

        pauseStateRef.current = false;

        const marqueeWidth = contentWidth.current + dividerWidth;
        const toValue = isLTR ? 0 : -marqueeWidth;

        if (timeRemainingRef.current > 0) {
            animationFn({
                animatedValue,
                toValue,
                duration: timeRemainingRef.current,
                iterations: 1,
            });
        }

        timerRef.current = setTimeout(() => {
            !pauseStateRef.current && startAnimation(contentWidth.current);
        }, timeRemainingRef.current) as unknown as number;

        return () => clearTimeout(timerRef.current);
    }, []);

    /**
     * Stops animation when the widget is outside viewport
     */
    React.useEffect(() => {
        const fn = (token: ViewToken): void => {
            if (token?.item?.id == null) {
                return;
            }

            if (token.item.id === widgetId) {
                token.isViewable ? resumeAnimation() : pauseAnimation();
            }
        };

        const subscription = context.onViewChange(fn);

        return () => subscription();
    }, []);

    /**
     * Stops animation when app is inactive or at background
     */
    useAppStateListener(
        React.useCallback((visible) => {
            if (!visible) {
                pauseAnimation();
                return;
            }

            if (pauseStateRef.current) {
                resumeAnimation();
            }
        }, []),
    );

    /**
     * Stops animation when screen out of focus
     */
    useFocusEffect(
        React.useCallback(() => {
            if (pauseStateRef.current) {
                resumeAnimation();
            }

            return () => pauseAnimation();
        }, []),
    );

    React.useEffect(() => {
        let id: string;

        if (mounted && animate && layout.measured) {
            startAnimation(layout.width - dividerWidth);

            id = animatedValue.addListener(({ value }) => (positionRef.current = value));
        }

        return () => animatedValue.removeListener(id);
    }, [layout.measured]);

    return (
        <Animated.ScrollView
            style={style}
            horizontal={horizontal}
            bounces={false}
            scrollEnabled={false}
            showsHorizontalScrollIndicator={false}>
            <Animated.View
                onLayout={setLayout}
                style={[styles.animatedView, { transform: [{ translateX: animatedValue }] }]}>
                <Animated.View style={{ width: dividerWidth }} />
                <ChildrenClone cloneOffset={cloneOffset} widgetId={widgetId}>
                    {children}
                </ChildrenClone>
                {scroll && (
                    <>
                        <Animated.View style={{ width: dividerWidth }} />
                        {children}
                    </>
                )}
            </Animated.View>
        </Animated.ScrollView>
    );
};

const styles = StyleSheet.create({
    animatedView: {
        flexDirection: "row",
    },
});

export const WidgetAnimator = React.memo(WidgetAnimatorComponent);
