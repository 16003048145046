import { ForwardRefExoticComponent } from "react";
import { VirtualizedList } from "react-native";
import withIO from "./withIO";

import type { IOVirtualizedListPropsFinal } from "./typings/IOVirtualizedList";
import withIOProps from "./typings/withIO.d";

const IOVirtualizedList: ForwardRefExoticComponent<IOVirtualizedListPropsFinal> = (
    withIO as unknown as typeof withIOProps
)(VirtualizedList, [
    "flashScrollIndicators",
    "getNativeScrollRef",
    "getScrollResponder",
    "getScrollableNode",
    "scrollToEnd",
    "scrollToIndex",
    "scrollToItem",
    "scrollToOffset",
]);
export default IOVirtualizedList;
