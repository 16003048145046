export interface ProviderConstructor {
    new (config: ProviderConfig): Provider;
}

export interface ProviderConfig {
    /** Specifies the subscribeKey to be used for subscribing to a conversation. */
    subscribeKey: string;
    /** Specifies the publishKey to be used for publishing messages to a conversation.*/
    publishKey?: string;
    /** log HTTP information.*/
    logVerbosity?: boolean;
    /** User's auth key.*/
    authToken?: string;
    /** A UUID (Universal Unique Identifier) is a unique alphanumeric identifier used to identify the client.*/
    uuid?: string;
    /** onError */
    onError?: (err: unknown) => void;
}

export interface ProviderSubscribeParams {
    /** Specifies the conversations to subscribe to.*/
    conversationIds: string[];
    /**
     * Specifies timetoken from which to start returning any available cached messages.
     * Message retrieval with timetoken is not guaranteed.
     */
    timetoken?: number | string;
}

export interface ProviderUnsubscribeParams {
    conversationIds: string[];
}

export interface ProviderSendMessageParams {
    conversationId: string;
    message: unknown;
    meta?: unknown;
}

export interface ProviderAddMessageActionParams {
    conversationId: string;
    messageTimetoken: string;
    action: {
        type: string;
        value: string;
    };
}

export interface ProviderAddMessageActionResponse {
    data: {
        type: string;
        value: string;
        uuid: string;
        actionTimetoken: string;
        messageTimetoken: string;
    };
}

export interface ProviderSendSignalParams {
    conversationId: string;
    message: unknown;
}

export interface ProviderMessageResponse {
    // The conversation for which the message belongs.
    conversationId: string;
    // Publish timetoken.
    timetoken: string | number;
    // The payload.
    message: unknown;
    // The publisher of this message.
    publisher: string;
}

export interface ProviderFetchMessagesParams {
    // the list of conversationIds
    conversationIds: string[];
    // Retrieves messages before the start timetoken, excluding any message at that timetoken
    startTimetoken?: string | number;
    // Retrieves messages after the end timetoken, including any message at that timetoken
    endTimetoken?: string | number;
    // max 100 messages can be fetched in a single request
    count?: number;
}

export interface ProviderFetchMessagesResponse {
    conversations: {
        [conversationId: string]: Array<{
            timetoken: string | number;
            message: unknown;
            publisher: string;
            meta?: {
                [key: string]: unknown;
            };
            actions?: {
                [type: string]: {
                    [value: string]: Array<{
                        uuid: string;
                        actionTimetoken: string | number; // timetoken
                    }>;
                };
            };
        }>;
    };
}

export interface ProviderAddListenerParams {
    status?(statusEvent: ProviderStatusEvent): void;
    message?(messageEvent: ProviderMessageEvent): void;
    signal?(signalEvent: ProviderSignalEvent): void;
    presence?(presenceEvent: ProviderPresenceEvent): void;
    messageAction?(messageActionEvent: ProviderMessageActionEvent): void;
}

export interface ProviderStatusEvent {
    status: ProviderStatus;
    operation: string;
    lastTimetoken: number | string;
    currentTimetoken: number | string;
    affectedConversations: string[];
    subscribedConversations: string[];
}

export interface ProviderMessageEvent {
    conversationId: string;
    timetoken: string;
    message: unknown;
    publisher: string;
}

export interface ProviderSignalEvent {
    conversationId: string;
    timetoken: string;
    message: unknown;
    publisher: string;
}

export interface ProviderPresenceEvent {
    action: "join" | "leave" | "timeout";
    conversationId: string;
    timestamp: number;
    timetoken: string;
    uuid: string;
}

export interface ProviderMessageActionEvent {
    conversationId: string;
    timetoken: string;
    action: {
        messageTimetoken: string;
        type: string;
        value: string;
        uuid: string;
        actionTimetoken: string;
    };
}

export interface ProviderRegisterDeviceForPNParams {
    token: string;
    os: "android" | "ios" | "web" | "windows" | "macos";
    userType?: "seller" | "customer";
    [extra: string]: unknown;
}

export interface ProviderUnregisterDeviceForPNParams {
    token: string;
}

export interface ProviderGetOnlineUsersParams {
    uuids: string[];
}

export type ProviderGetOnlineUsersResponse = {
    [uuid: string]: {
        online: boolean;
        // unix timestamp in milliseconds
        time?: number;
    };
};

export interface Provider {
    /** This function provides the capability to reset a user's auth Key. */
    setAuthToken(token: string): void;

    /** This function provides the capability to set the user's UUID. */
    setUUID(id: string): void;

    /** This function provides the capability to get the user's UUID. */
    getUUID(): string;

    /**
     * This function provides the capability to subscribe to conversations. A client will only receive
     * messages published to the conversations after the subscribe() call completes.
     */
    subscribe(params: ProviderSubscribeParams): void;

    /** The function to unsubscribe from single or multiple conversations.*/
    unsubscribe(params: ProviderUnsubscribeParams): void;

    /** Unsubscribe from all conversations.*/
    unsubscribeAll(): void;

    /** Returns list of subscribed conversations Id.*/
    getSubscribedConversations(): string[];

    /** Ends all open requests, subscriptions and timers.*/
    stop(): void;

    /** Call the reconnect method to force the Provider to try and reach out their network.*/
    reconnect(): void;

    /** Call the disconnect method to stop all timers.*/
    disconnect(): void;

    /** Used for publishing a message to a conversation.*/
    sendMessage(params: ProviderSendMessageParams): Promise<string | number>;

    /** Used for adding a message action to a message.*/
    addMessageAction(
        params: ProviderAddMessageActionParams,
    ): Promise<ProviderAddMessageActionResponse>;

    /** Used for publishing a signal to a conversation.*/
    sendSignal(params: ProviderSendSignalParams): Promise<string | number>;

    /** This function fetches historical messages from one or multiple conversations.*/
    fetchMessages(params: ProviderFetchMessagesParams): Promise<ProviderFetchMessagesResponse>;

    /** Adds listener for receiving messages, signals etc.*/
    addListener(params: ProviderAddListenerParams): () => void;

    /**  Registers device for receiving push notifications. */
    registerDeviceForPushNotifications(params: ProviderRegisterDeviceForPNParams): Promise<void>;

    /** Unregisters device to stop push notifications. */
    unregisterDeviceForPushNotifications(
        params: ProviderUnregisterDeviceForPNParams,
    ): Promise<void>;

    /** When a client opens the app, it's often required to discover what other users are already subscribed to that conversation */
    getOnlineUsers(params: ProviderGetOnlineUsersParams): Promise<ProviderGetOnlineUsersResponse>;

    /** This function sends user online status */
    sendUserOnlineStatus(params: { online: boolean }): Promise<void>;

    /** Sets conversation last read time */
    setConversationLastReadTime(conversationId: string): Promise<void>;

    /** Gets conversation unread count */
    getConversationsUnreadCount(
        conversationIds: string[],
        conversationTimetokens: Record<string, string>,
    ): Promise<Record<string, number>>;
}

export const enum ProviderStatus {
    // Returned when the subscriber gets a non-200 HTTP response code from the server or something
    // else went wrong.
    Unknown = "Unknown",
    // The Provider announces this when a connection isn't available, or
    // when the Provider isn't able to reach their Data Stream Network.
    NetworkDownCategory = "NetworkDownCategory",
    // The Provider detected that the network is online.
    NetworkUpCategory = "NetworkUpCategory",
    // The Provider isn't able to reach the their Data Stream Network.
    NetworkIssuesCategory = "NetworkIssuesCategory",
    // The Provider was able to reconnect to their Network.
    ReconnectedCategory = "ReconnectedCategory",
    // Provider subscribed with a new mix of conversations.
    // This is fired every time the conversation mix changes.
    ConnectedCategory = "ConnectedCategory",
    // PAM permission failure.
    AccessDeniedCategory = "AccessDeniedCategory",
    // JSON parsing crashed.
    MalformedResponseCategory = "MalformedResponseCategory",
    // The server responded with a bad response error because the request is malformed.
    BadRequestCategory = "BadRequestCategory",
    // Failure to establish a connection to PubNub due to a timeout.
    TimeoutCategory = "TimeoutCategory",
    // The Provider announces this error if the number of messages received from
    // Network (in-memory cache messages) exceeds the threshold.
    RequestMessageCountExceededCategory = "RequestMessageCountExceededCategory",
    // If using decryption strategies and the decryption fails.
    DecryptionErrorCategory = "DecryptionErrorCategory",
}
