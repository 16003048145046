import React, { memo } from "react";
import { Linking, StyleSheet } from "react-native";
import { TextProps } from "@swiggy-private/rn-dls";
import { ParsedText, ParsedTextProps } from "@swiggy-private/react-native-ui";

import { logError } from "../../../../helpers/log";

interface MessageViewTextMessageProps extends TextProps {
    text?: string;
}

const handleUrlPress = async (url: string): Promise<void> => {
    await Linking.canOpenURL(url)
        .then((supported) => {
            if (supported) {
                return Linking.openURL(url);
            }

            return Promise.resolve();
        })
        .catch(logError);
};

const handleEmailPress = async (email: string): Promise<void> => {
    await Linking.canOpenURL(`mailto:${email}`)
        .then((supported) => {
            if (supported) {
                return Linking.openURL(`mailto:${email}`);
            }

            return Promise.resolve();
        })
        .catch(logError);
};

const handlePhonePress = async (phoneNumber: string): Promise<void> => {
    await Linking.canOpenURL(`tel:${phoneNumber}`)
        .then((supported) => {
            if (supported) {
                return Linking.openURL(`tel:${phoneNumber}`);
            }

            return Promise.resolve();
        })
        .catch(logError);
};

const styles = StyleSheet.create({
    url: {
        textDecorationLine: "underline",
    },
    email: {
        textDecorationLine: "underline",
    },
    phone: {
        textDecorationLine: "underline",
    },
});

const parseOptions: ParsedTextProps["parse"] = [
    { type: "url", onPress: handleUrlPress, style: styles.url },
    { type: "email", onPress: handleEmailPress, style: styles.email },
    { type: "phone", onPress: handlePhonePress, style: styles.phone },
];

export const MessageViewTextMessage: React.FC<MessageViewTextMessageProps> = memo(
    ({ text, ...props }) => {
        return text ? (
            <ParsedText
                category="b2"
                color="high"
                selectable={true}
                parse={parseOptions}
                {...props}>
                {text}
            </ParsedText>
        ) : null;
    },
);

if (process.env.NODE_ENV !== "production") {
    MessageViewTextMessage.displayName = "MessageViewTextMessage";
}
