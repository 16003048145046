import { SdkFetchMessagesResponse } from "@swiggy-private/connect-chat-sdk";
import { useCallback } from "react";

import { logError } from "../helpers/log";
import { useChatService } from "./use-chat-service";
import { useChatSdk } from "./use-chatsdk";

export const useFetchRecentMessages = (): ((
    conversationIds: string[],
    count?: number,
) => Promise<SdkFetchMessagesResponse>) => {
    const chatSdk = useChatSdk();

    return useCallback(
        async (conversationIds: string[], count = 1): Promise<SdkFetchMessagesResponse> => {
            if (!chatSdk || !conversationIds.length || count < 1) {
                return {
                    conversations: {},
                };
            }

            const startTimetoken = String(Date.now() * 10_000);
            return chatSdk.fetchMessages({
                conversationIds,
                count,
                startTimetoken: startTimetoken,
            });
        },
        [chatSdk],
    );
};

export const useFetchRecentMessagesFromDb = (): ((
    conversationIds: string[],
    count?: number,
) => Promise<SdkFetchMessagesResponse>) => {
    const service = useChatService();

    return useCallback(
        async (conversationIds: string[], count = 1): Promise<SdkFetchMessagesResponse> => {
            const result: SdkFetchMessagesResponse = {
                conversations: {},
            };

            const curTime = Date.now();
            const promises: Promise<void>[] = conversationIds.map((conversationId) => {
                return new Promise(async (resolve) => {
                    const messages = await service
                        ?.findMessages({
                            conversationIds: conversationId,
                            limit: count,
                            timestamp: curTime,
                        })
                        .catch((err) => {
                            logError(err);
                            return [];
                        });

                    if (Array.isArray(messages) && messages.length > 0) {
                        result.conversations[conversationId] = messages.map((message) => ({
                            message: message,
                            publisher: message.publisher,
                            timetoken: message.timetoken,
                        }));
                    }

                    resolve();
                });
            });

            await Promise.all(promises);

            return result;
        },
        [service],
    );
};
