import { useEffect, useRef } from "react";
import { AppState, AppStateStatus } from "react-native";
import type { SdkAddListenerParams } from "@swiggy-private/connect-chat-sdk";

import { useChatSdk } from "./use-chatsdk";

export const useChatListener = (params: SdkAddListenerParams): void => {
    const sdk = useChatSdk();
    const removeListenerRef = useRef<() => void>();
    const {
        status: onStatus,
        presence: onPresence,
        message: onMessage,
        signal: onSignal,
        messageAction: onMessageAction,
    } = params;

    useEffect(() => {
        if (!sdk) {
            // eslint-disable-next-line no-void
            return () => void 0;
        }

        removeListenerRef.current?.();
        removeListenerRef.current = sdk.addListener({
            signal: onSignal,
            message: onMessage,
            presence: onPresence,
            status: onStatus,
            messageAction: onMessageAction,
        });

        const fn = (state: AppStateStatus): void => {
            if (state === "active") {
                removeListenerRef.current?.();
                removeListenerRef.current = sdk.addListener({
                    signal: onSignal,
                    message: onMessage,
                    presence: onPresence,
                    status: onStatus,
                    messageAction: onMessageAction,
                });
            } else {
                removeListenerRef.current?.();
            }
        };

        const appStateSubscription = AppState.addEventListener("change", fn);

        return () => {
            removeListenerRef.current?.();

            if (appStateSubscription == null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-expect-error
                AppState.removeEventListener("change", fn);
            } else {
                appStateSubscription.remove();
            }
        };
    }, [onMessage, onMessageAction, onPresence, onSignal, onStatus, sdk]);
};
