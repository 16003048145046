import type {
    SdkCustomMessage,
    SdkImageMessage,
    SdkMessage,
    SdkMessageActions,
    SdkMessageActionTypes,
    SdkQuoteMessage,
    SdkTextMessage,
    SdkVideoMessage,
} from "@swiggy-private/connect-chat-sdk";
import {
    CustomMessageTypes,
    MESSAGE_SHORT_DESCRIPTION,
} from "@swiggy-private/connect-chat-commons";

export const getMessageShortText = (message: SdkMessage): string => {
    if (message.payload.displayText) {
        return message.payload.displayText ?? "";
    } else if (message.type === "text") {
        return message.payload.text ?? "";
    } else if (message.type === "quote") {
        return message.payload.text ?? "";
    } else if (message.type === "image") {
        return message.payload.text ?? "Sent a photo";
    } else if (message.type === "video") {
        return message.payload.text ?? "Sent a video";
    } else if (message.type === "coupon") {
        return message.payload.text?.length ? message.payload.text : "Sent a coupon";
    }

    return (
        message.payload.text || MESSAGE_SHORT_DESCRIPTION[message.type as CustomMessageTypes] || ""
    );
};

export const isTextMessage = (message: SdkMessage): message is SdkTextMessage => {
    return message.type === "text";
};

export const isImageMessage = (message: SdkMessage): message is SdkImageMessage => {
    return message.type === "image";
};

export const isVideoMessage = (message: SdkMessage): message is SdkVideoMessage => {
    return message.type === "video";
};

export const isQuoteMessage = (message: SdkMessage): message is SdkQuoteMessage => {
    return message.type === "quote";
};

export const isCustomMessage = (message: SdkMessage): message is SdkCustomMessage => {
    return (
        !isTextMessage(message) &&
        !isImageMessage(message) &&
        !isVideoMessage(message) &&
        !isQuoteMessage(message)
    );
};

export const getMessageSectionKey = (message: SdkMessage): string => {
    const date = new Date(message.timestamp);
    return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
};

export const hasMessageAction = (
    messageActions: SdkMessageActions,
    type: SdkMessageActionTypes,
    uuid: string,
    equal = true,
    // eslint-disable-next-line max-params
): boolean => {
    if (!messageActions[type]) {
        return false;
    }

    return (
        Object.keys(messageActions[type]).filter((key) =>
            messageActions[type][key].some((i) => (equal ? i.uuid === uuid : i.uuid !== uuid)),
        ).length > 0
    );
};
