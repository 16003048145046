import React, { FC, memo, useCallback, useRef, useState } from "react";
import { GestureResponderEvent, Platform, Pressable, StyleSheet, View } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps } from "@swiggy-private/rn-dls";
import { SvgIcon, Icon, SvgIconType } from "@swiggy-private/connect-svg-icons";
import { SimpleMenu } from "@swiggy-private/react-native-ui";

export enum MENU_ITEM_IDS {
    BLOCK = "BLOCK",
}

export interface MenuItem {
    id: MENU_ITEM_IDS;
    text: string;
    /** TODO: refactor during seller changes */
    analyticsEventName: string;

    icon?: Icon;
    textProps?: TextProps;
    iconProps?: Partial<SvgIconType>;
}

interface MenuItemsProps {
    menuItems: MenuItem[];
    onMenuItemPress: (item: MenuItem) => void;
}

const isWeb = Platform.OS === "web";
const MENU_ITEM_ICON_SIZE = isWeb ? 24 : 16;

const MenuItems: FC<MenuItemsProps> = ({ menuItems = [], onMenuItemPress }) => {
    const handleItemPress = React.useCallback(
        (item: MenuItem) => {
            onMenuItemPress(item);
        },
        [onMenuItemPress],
    );

    if (!menuItems.length) {
        return null;
    }

    return (
        <Stack style={styles.menuItemsContainer}>
            {menuItems.map((item) => {
                const { id, text, icon, textProps, iconProps } = item;

                return (
                    <Pressable key={id} onPress={() => handleItemPress(item)}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={SpacingValue["space-small"]}>
                            <Text category="b1" weight="medium" {...textProps}>
                                {text}
                            </Text>

                            {icon ? (
                                <SvgIcon
                                    color={"color-basic-75"}
                                    width={MENU_ITEM_ICON_SIZE}
                                    height={MENU_ITEM_ICON_SIZE}
                                    {...iconProps}
                                    icon={icon}
                                />
                            ) : null}
                        </Stack>
                    </Pressable>
                );
            })}
        </Stack>
    );
};

interface KebabMenuProps {
    menuItems: MenuItem[];
    onMenuItemPress: (item: MenuItem) => void;
    onViewCallback?: VoidFunction;
}

const ICON_SIZE = 20;

const KebabMenuComponent: FC<KebabMenuProps> = ({ menuItems, onMenuItemPress, onViewCallback }) => {
    const anchorEl = useRef<View>(null);

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const openMenu = useCallback((event: GestureResponderEvent) => {
        setIsMenuVisible(true);
        event.stopPropagation();
    }, []);

    const closeMenu = useCallback(() => {
        setIsMenuVisible(false);
    }, []);

    const handleMenuItemPress = useCallback(
        (item: MenuItem) => {
            onMenuItemPress(item);
            closeMenu();
        },
        [closeMenu, onMenuItemPress],
    );

    React.useEffect(() => {
        isMenuVisible && onViewCallback?.();
    }, [isMenuVisible, onViewCallback]);

    return (
        <Box ph={SpacingValue["space-x-small"]}>
            <Pressable onPress={openMenu} ref={anchorEl} hitSlop={10}>
                <SvgIcon
                    icon="More"
                    color={"color-basic-75"}
                    width={ICON_SIZE}
                    height={ICON_SIZE}
                />
            </Pressable>

            {isMenuVisible && anchorEl.current ? (
                <SimpleMenu
                    xOrigin="right"
                    yOrigin="top"
                    onClose={closeMenu}
                    anchorEl={anchorEl.current}
                    style={styles.menu}
                    skipAndroidStatusBar>
                    <MenuItems menuItems={menuItems} onMenuItemPress={handleMenuItemPress} />
                </SimpleMenu>
            ) : null}
        </Box>
    );
};

export const KebabMenu = memo(KebabMenuComponent);

const styles = StyleSheet.create({
    menu: {
        marginTop: SpacingValue["space-xx-large"],
        borderRadius: 16,
        minWidth: 100,
    },

    menuItemsContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-xx-small"],
    },
});
