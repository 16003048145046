import memoizeOne from "memoize-one";
import {
    ButtonGroupColorStyleMap,
    ButtonGroupSizeStyleMap,
    ButtonGroupStyle,
    ButtonGroupStyleMeta,
} from "../interfaces/button-group";
import { Theme } from "@swiggy-private/rn-dls-theme";

const buttonGroupSizeStyleMap = memoizeOne(
    (theme: Theme): ButtonGroupSizeStyleMap => ({
        tiny: {
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            dividerWidth: 1,
        },
        small: {
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            dividerWidth: 1,
        },
        medium: {
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            dividerWidth: 1,
        },
        large: {
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            dividerWidth: 1,
        },
        giant: {
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            dividerWidth: 1,
        },
    }),
);

const buttonGroupColorStyleMap = memoizeOne(
    (theme: Theme): ButtonGroupColorStyleMap => ({
        primary: {
            borderColor: theme["color-primary"],
            dividerBackgroundColor: theme["color-primary-light"],
        },
        secondary: {
            borderColor: theme["color-primary-light"],
            dividerBackgroundColor: theme["color-primary-light"],
        },
        tertiary: {
            borderColor: theme["color-basic-15"],
            dividerBackgroundColor: theme["color-basic-15"],
        },
        warning: {
            borderColor: theme["color-warning"],
            dividerBackgroundColor: theme["color-warning-light"],
        },
        critical: {
            borderColor: theme["color-critical"],
            dividerBackgroundColor: theme["color-critical-light"],
        },
        positive: {
            borderColor: theme["color-positive"],
            dividerBackgroundColor: theme["color-positive-light"],
        },
        rn_dls_primary: {
            borderColor: theme.primary,
            dividerBackgroundColor: theme.primary_Sub,
        },
        rn_dls_secondary: {
            borderColor: theme.primary_Sub,
            dividerBackgroundColor: theme.primary_Sub,
        },
        rn_dls_tertiary: {
            borderColor: theme.primary_Sub,
            dividerBackgroundColor: theme.primary_Sub,
        },
        rn_dls_warning: {
            borderColor: theme.negative,
            dividerBackgroundColor: theme.negative_Sub,
        },
        rn_dls_critical: {
            borderColor: theme.negative,
            dividerBackgroundColor: theme.negative_Sub,
        },
        rn_dls_positive: {
            borderColor: theme.positive,
            dividerBackgroundColor: theme.positive_Sub,
        },
    }),
);

export const getButtonGroupStyles = (
    meta: ButtonGroupStyleMeta,
    theme: Theme,
): ButtonGroupStyle => {
    const size = meta.size ?? "medium";
    const color = meta.color ?? "primary";

    const sizeStyle = buttonGroupSizeStyleMap(theme)[size];
    const colorStyle = buttonGroupColorStyleMap(theme)[color];

    return {
        ...sizeStyle,
        ...colorStyle,
    };
};
