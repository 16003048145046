import React, { ForwardRefRenderFunction } from "react";
import { StyleSheet, View, ViewProps, ViewStyle } from "react-native";

import { useScreenSize } from "../../hooks/use-screen-size";
import { ScreenSizeProp } from "../../interfaces/screen";

export interface BoxProps extends ViewProps {
    display?: ScreenSizeProp<"flex" | "none">;
    flex?: number;
    /** Defines the flex-direction style property. */
    direction?: ViewStyle["flexDirection"];
    /** padding-vertical */
    pv?: number;
    /** padding-horizontal */
    ph?: number;
    /** padding-top */
    pt?: number;
    /** padding-bottom */
    pb?: number;
    /** margin-vertical */
    mv?: number;
    /** margin-horizontal */
    mh?: number;
    /** margin-top */
    mt?: number;
    /** margin-bottom */
    mb?: number;
    /** margin-right */
    mr?: number;
    /** margin-left */
    ml?: number;
    alignItems?: ViewStyle["alignItems"];
    justifyContent?: ViewStyle["justifyContent"];
    gap?: number;
}

const BoxComponent: ForwardRefRenderFunction<View, BoxProps> = (
    {
        display = "flex",
        flex,
        direction: flexDirection,
        pv: paddingVertical,
        ph: paddingHorizontal,
        pb: paddingBottom,
        pt: paddingTop,
        mv: marginVertical,
        mh: marginHorizontal,
        mb: marginBottom,
        mt: marginTop,
        mr: marginRight,
        ml: marginLeft,
        alignItems,
        justifyContent,
        style: propStyle,
        gap,
        ...props
    },
    ref,
) => {
    const screenSize = useScreenSize();

    const _display =
        typeof display === "string" ? display : display?.[screenSize] ?? display.default;

    if (_display === "none") {
        return null;
    }

    const style: ViewStyle = {
        flex,
        flexDirection,
        paddingHorizontal,
        paddingVertical,
        paddingBottom,
        paddingTop,
        marginHorizontal,
        marginVertical,
        marginBottom,
        marginTop,
        marginRight,
        marginLeft,
        alignItems,
        justifyContent,
        gap,
    };

    return <View {...props} style={StyleSheet.compose(style, propStyle)} ref={ref} />;
};

/**
 * The Box component serves as a wrapper component for most of the layout utility needs.
 */
export const Box = React.forwardRef(BoxComponent);
