import { ForwardRefExoticComponent } from "react";
import { ScrollView } from "react-native";

import withIO from "./withIO";

import type { IOScrollViewPropsFinal } from "./typings/IOScrollView.d";
import withIOProps from "./typings/withIO.d";

const IOScrollView: ForwardRefExoticComponent<IOScrollViewPropsFinal> = (
    withIO as unknown as typeof withIOProps
)(ScrollView, [
    "scrollTo",
    "scrollToEnd",
    "getScrollResponder",
    "getScrollableNode",
    "getInnerViewNode",
]);
export default IOScrollView;
