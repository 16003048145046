import * as React from "react";
import {
    FlatList,
    ImageStyle,
    InteractionManager,
    NativeScrollEvent,
    NativeSyntheticEvent,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    ViewStyle,
} from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { SlideArrows } from "./slide-arrows";

interface PhotosCarouselProps {
    imageList: string[];
    size: number;

    slideIndex?: number;
    resizeMode?: string;
    style?: StyleProp<ViewStyle>;
    imageStyle?: StyleProp<ImageStyle>;
    optimizedDpr?: boolean;
    onItemPress?: VoidFunction;
    onImageScroll?: VoidFunction;
}

interface IRenderItemProps {
    onPress: VoidFunction;
    imageSize: number;
    item: string;

    optimizedDpr?: boolean;
    gutter?: number;
    resizeMode?: string;
    imageStyles?: StyleProp<ImageStyle>;
}

const RenderItem: React.FC<IRenderItemProps> = ({
    onPress,
    imageSize,
    item,
    optimizedDpr,
    gutter = SpacingValue["space-small"],
    imageStyles = {},
}) => {
    const { value: theme } = useTheme();

    const imageContainerStyles: ViewStyle = {
        backgroundColor: theme["color-basic-0"],
    };

    const size = imageSize - gutter * 2;

    return (
        <Stack style={[styles.imageContainer, imageContainerStyles]}>
            <Pressable onPress={onPress} style={styles.imageWrapper}>
                <CdnImage
                    resizeMode="contain"
                    width={size}
                    height={size}
                    id={item}
                    showLoader
                    optimizedDpr={optimizedDpr}
                    style={[imageStyles]}
                />
            </Pressable>
        </Stack>
    );
};

const RenderItemComponent = React.memo(RenderItem);

const PhotosCarouselComponent: React.FC<PhotosCarouselProps> = ({
    imageList,
    size,
    style,
    optimizedDpr,
    onItemPress,
    onImageScroll,
    resizeMode,
    slideIndex = 0,
}) => {
    const { value: theme } = useTheme();
    const flatlistRef = React.useRef<FlatList>(null);

    const imageListLength = imageList.length;

    const [currentSlideIndex, setCurrentSlideIndex] = React.useState(slideIndex);

    const containerStyle: ViewStyle = {
        height: size,
        width: size,
        borderColor: theme["color-basic-15"],
    };

    const listStyle = {
        backgroundColor: theme["color-basic-0"],
    };

    const imageSize = React.useMemo(() => size - 2 * StyleSheet.hairlineWidth, [size]);

    React.useEffect(() => {
        InteractionManager.runAfterInteractions(() => {
            slideToIndex(slideIndex, false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPress = React.useCallback(() => {
        onItemPress?.();
    }, [onItemPress]);

    const renderItem = React.useCallback(
        ({ item }: { item: typeof imageList[number] }): JSX.Element => (
            <RenderItemComponent
                item={item}
                imageSize={imageSize}
                onPress={onPress}
                optimizedDpr={optimizedDpr}
                resizeMode={resizeMode}
            />
        ),
        [imageSize, onPress, optimizedDpr, resizeMode],
    );

    const updateCurrentSlideIndex = React.useCallback(
        (e: NativeSyntheticEvent<NativeScrollEvent>): void => {
            const contentOffsetX = e.nativeEvent.contentOffset.x;
            const currentIndex = Math.round(contentOffsetX / size);
            setCurrentSlideIndex(currentIndex);

            currentIndex !== currentSlideIndex && onImageScroll?.();
        },
        [size, onImageScroll, currentSlideIndex],
    );

    const slideToIndex = React.useCallback(
        (index: number, animated = true) => {
            if (index > -1 && index < imageListLength) {
                setCurrentSlideIndex(index);
                flatlistRef?.current?.scrollToOffset({
                    animated,
                    offset: index * size,
                });
            }
        },
        [imageListLength, size],
    );

    const scrollToNextSlide = React.useCallback(() => {
        if (currentSlideIndex < imageListLength - 1) {
            slideToIndex(currentSlideIndex + 1);
        }
    }, [currentSlideIndex, imageListLength, slideToIndex]);

    const scrollToPreviousSlide = React.useCallback(() => {
        if (currentSlideIndex > 0) {
            slideToIndex(currentSlideIndex - 1);
        }
    }, [currentSlideIndex, slideToIndex]);

    const scrollEnabled = Platform.OS === "android" || Platform.OS === "ios";

    return (
        <>
            <Box style={[styles.container, containerStyle, style]}>
                <FlatList
                    style={listStyle}
                    ref={flatlistRef}
                    data={imageList}
                    scrollEnabled={scrollEnabled}
                    disableScrollViewPanResponder={scrollEnabled !== true}
                    horizontal
                    pagingEnabled={imageListLength > 1}
                    showsHorizontalScrollIndicator={false}
                    onMomentumScrollEnd={updateCurrentSlideIndex}
                    renderItem={renderItem}
                    keyExtractor={(i: string, index: number) => i + index.toString()}
                    contentContainerStyle={!imageListLength && styles.contentContainer}
                    extraData={currentSlideIndex}
                    pointerEvents={scrollEnabled ? "auto" : "none"}
                />

                {imageListLength > 1 ? (
                    <SlideArrows
                        totalImages={imageListLength}
                        currentSlideIndex={currentSlideIndex}
                        scrollToNextSlide={scrollToNextSlide}
                        scrollToPreviousSlide={scrollToPreviousSlide}
                        imageSize={size}
                    />
                ) : null}
            </Box>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 24,
        overflow: "hidden",
    },
    contentContainer: {
        flex: 1,
        alignItems: "center",
    },
    imageContainer: {
        borderRadius: 24,
        alignItems: "center",
        justifyContent: "center",
        padding: SpacingValue["space-small"],
    },
    imageWrapper: {
        borderRadius: 16,
        overflow: "hidden",
    },
});

export const PhotosCarousel = React.memo(PhotosCarouselComponent);
