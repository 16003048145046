import { useCallback } from "react";
import type { SdkVideoMessagePayload } from "@swiggy-private/connect-chat-sdk";

import { useSendMessage } from "./use-send-message";
import { useChatSdk } from "./use-chatsdk";
import { ISentMessageResponse } from "../interfaces/types";

export const useSendVideoMessage = (
    conversationId: string,
): ((payload: SdkVideoMessagePayload) => ISentMessageResponse | undefined) => {
    const sdk = useChatSdk();
    const sendMessage = useSendMessage(conversationId);

    const sendVideoMessage = useCallback(
        (payload: SdkVideoMessagePayload): ISentMessageResponse | undefined => {
            if (!sdk) {
                return;
            }

            const message = sdk.prepareMessage({
                type: "video",
                payload,
            });

            return sendMessage(message);
        },
        [sdk, sendMessage],
    );

    return sendVideoMessage;
};
