import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMountedRef } from "@swiggy-private/react-hooks";

import { useChatState } from "./use-chatstate";
import { useChatDispatch } from "./use-chatdispatch";
import { useChatSdk } from "./use-chatsdk";
import { useChatService } from "./use-chat-service";
import { logError } from "../helpers/log";

type UseGetConversationReturnValue = [
    SdkConversation | null,
    { loading: boolean; error?: Error | null; fetch: () => void },
];

export const useGetConversationFromNetwork = (): ((
    conversation: string,
    canUpdateState?: boolean,
) => Promise<SdkConversation | null>) => {
    const chatSdk = useChatSdk();
    const dispatch = useChatDispatch();

    return useCallback(
        async (conversationId: string, canUpdateState = false): Promise<SdkConversation | null> => {
            const conversation = await chatSdk?.getConversation(conversationId).catch(logError);

            if (conversation && canUpdateState) {
                dispatch({
                    type: "MULTI_DISPATCH",
                    payload: [
                        {
                            type: "ADD_CONVERSATION_ACTION",
                            payload: {
                                conversations: [conversation],
                            },
                        },
                        {
                            type: "ADD_USER_ACTION",
                            payload: {
                                users: conversation.participants,
                            },
                        },
                    ],
                });
            }

            return conversation || null;
        },
        [chatSdk, dispatch],
    );
};

export const useGetConversation = (conversationId: string): UseGetConversationReturnValue => {
    const chatSdk = useChatSdk();
    const mountedRef = useMountedRef();

    const state = useChatState();
    const dispatch = useChatDispatch();
    const service = useChatService();

    const stateConversation: SdkConversation | null = useMemo(
        () => state.conversations[conversationId] ?? null,
        [conversationId, state.conversations],
    );

    const [loading, setLoading] = useState(stateConversation == null);
    const [error, setError] = useState<Error | null>(null);

    const cancelRef = useRef<AbortController>();
    const initRef = useRef(false);

    const getConversationFromNetwork = useGetConversationFromNetwork();

    const onSuccess = useCallback(
        (conversation: SdkConversation) => {
            setLoading(false);
            setError(null);

            dispatch({
                type: "MULTI_DISPATCH",
                payload: [
                    {
                        type: "ADD_CONVERSATION_ACTION",
                        payload: {
                            conversations: [conversation],
                        },
                    },
                    {
                        type: "ADD_USER_ACTION",
                        payload: {
                            users: conversation.participants,
                        },
                    },
                ],
            });
        },
        [dispatch],
    );

    const fetchConversation = useCallback(
        async (signal: AbortSignal) => {
            if (!chatSdk && !service) {
                return;
            }

            initRef.current = true;
            setLoading(true);
            setError(null);

            let conversationDb: SdkConversation | null = null;
            let conversationNetwork: SdkConversation | null = null;

            const conversationDbPromise = service
                ?.findConversationById(conversationId)
                .then((conversation) => {
                    if (mountedRef.current && !signal.aborted && conversation) {
                        conversationDb = conversation;
                        onSuccess(conversation);
                    }
                })
                .catch(logError);

            const conversationNetworkPromise = getConversationFromNetwork(conversationId).then(
                (c) => (conversationNetwork = c),
            );

            await Promise.all([conversationDbPromise, conversationNetworkPromise]).then(() => {
                if (!mountedRef.current || signal.aborted) {
                    return;
                }

                if (conversationNetwork) {
                    const lastMessageTimestamp = Number(conversationDb?.lastMessageTimestamp || 0);
                    const conversation = {
                        ...conversationDb,
                        ...conversationNetwork,
                    } as SdkConversation;

                    // compare and use latest lastMessageTimestamp
                    if (lastMessageTimestamp > Number(conversation.lastMessageTimestamp || 0)) {
                        conversation.lastMessageTimestamp = lastMessageTimestamp;
                    }

                    // save updated conversation via service
                    service?.updateConversation(conversation).catch(logError);

                    // success callback
                    onSuccess(conversation);
                }

                if (!conversationDb && !conversationNetwork) {
                    throw new Error("Conversation not found");
                }
            });
        },
        [chatSdk, conversationId, getConversationFromNetwork, mountedRef, onSuccess, service],
    );

    const fetch = useCallback(() => {
        if (!mountedRef.current) {
            return;
        }

        cancelRef.current?.abort();
        cancelRef.current = new AbortController();
        const signal = cancelRef.current.signal;

        fetchConversation(signal).catch((err) => {
            if (mountedRef.current && !signal.aborted) {
                setError(err as Error);
                setLoading(false);
            }
        });
    }, [fetchConversation, mountedRef]);

    useEffect(() => {
        if (!initRef.current) {
            fetch();
        }
    }, [chatSdk, fetch]);

    return [
        stateConversation,
        {
            loading,
            error: stateConversation == null ? error : null,
            fetch,
        },
    ];
};
