import { Dispatch, useContext } from "react";
import { ChatConversationDispatchContext } from "../contexts/chat-conversation-context";
import { ChatConversationAction } from "../reducers/conversation";

export function useChatConversationDispatch(): Dispatch<ChatConversationAction> {
    const ctx = useContext(ChatConversationDispatchContext);
    if (!ctx) {
        throw new Error("Missing chat conversation dispatch context");
    }

    return ctx;
}
