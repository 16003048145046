const MAX_INDICATOR_COUNT = 3;
const MIN_INDICATOR_COUNT = 2;

export const isCurrentIndexActive = ({
    index,
    currentSlideIndex,
    count,
    indicatorDotCount,
}: {
    index: number;
    currentSlideIndex: number;
    count: number;
    indicatorDotCount: number;
}): boolean => {
    if (indicatorDotCount === 2) {
        const isFirstIndexAndCurrent = index === 0 && currentSlideIndex === 0;
        const isSecondIndexAndCurrent = index === 1 && index === currentSlideIndex;

        return isFirstIndexAndCurrent || isSecondIndexAndCurrent;
    }

    const isFirstIndexAndCurrent = index === 0 && currentSlideIndex === 0;
    const isLastIndexAndCurrent = index === 2 && currentSlideIndex === count - 1;
    const isMiddleIndexAndCurrent =
        index === 1 && currentSlideIndex < count - 1 && currentSlideIndex > 0;

    return isFirstIndexAndCurrent || isLastIndexAndCurrent || isMiddleIndexAndCurrent;
};

export const getIndicatorDotCount = (count: number): number => {
    return count === MIN_INDICATOR_COUNT ? MIN_INDICATOR_COUNT : MAX_INDICATOR_COUNT;
};
