import { HttpCallParams, HttpClient, HttpClientOpts } from "@swiggy-private/http-client";
import { ApiResponse } from "./types";

export class ApiClient {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _httpClient: HttpClient<ApiResponse<any>>;

    constructor(opts: HttpClientOpts<ApiResponse<unknown>> = {}) {
        this._httpClient = new HttpClient(opts);
    }

    httpClient<T>(): HttpClient<ApiResponse<T>> {
        return this._httpClient as HttpClient<ApiResponse<T>>;
    }

    async get<T>(params: HttpCallParams): Promise<ApiResponse<T>> {
        return this._httpClient.get(params);
    }

    async post<T>(params: HttpCallParams): Promise<ApiResponse<T>> {
        return this._httpClient.post(params);
    }

    async delete<T>(params: HttpCallParams): Promise<ApiResponse<T>> {
        return this._httpClient.delete(params);
    }

    async patch<T>(params: HttpCallParams): Promise<ApiResponse<T>> {
        return this._httpClient.patch(params);
    }

    async put<T>(params: HttpCallParams): Promise<ApiResponse<T>> {
        return this._httpClient.put(params);
    }
}
