import { Platform } from "react-native";

export interface XHRFetchOptions {
    method: string;
    timeout: number;
    headers?: Record<string, string | string[]>;
    body?: Document | XMLHttpRequestBodyInit | null;
    signal?: AbortSignal;
    onProgress?: (e: ProgressEvent<EventTarget>) => void;
}

const __DEV__ = process.env.NODE_ENV !== "production";

const BLACKLIST_HEADERS = ["content-length", "host", "user-agent"];

export const xhrFetch = (url: string, opts: Partial<XHRFetchOptions> = {}): Promise<unknown> => {
    const xhr = new XMLHttpRequest();
    const onCancel = (): void => xhr.abort();
    const timeoutTimer = setTimeout(onCancel, opts.timeout ?? 10_000);

    if (opts.signal) {
        opts.signal.addEventListener("abort", onCancel);
    }

    const promise = new Promise((resolve, reject) => {
        xhr.open(opts.method ?? "GET", url);

        if (opts.headers) {
            Object.entries(opts.headers).forEach(([key, value]) => {
                const isWeb = Platform.OS === "web";
                if ((isWeb && !BLACKLIST_HEADERS.includes(key.toLowerCase())) || !isWeb) {
                    xhr.setRequestHeader(key, (Array.isArray(value) ? value : [value]).join(","));
                }
            });
        }

        xhr.onabort = function () {
            clearTimeout(timeoutTimer);
            reject(new Error("request aborted"));
        };

        xhr.onload = function () {
            clearTimeout(timeoutTimer);

            const status = xhr.status;

            if (status === 0 || (status >= 200 && status < 400)) {
                // The request has been completed successfully
                resolve(xhr.response);
            } else {
                reject({
                    status: xhr.status,
                    statusText: xhr.statusText,
                    response: xhr.response,
                });
            }
        };

        xhr.onprogress = function (e) {
            opts?.onProgress?.(e);
        };

        xhr.onerror = function () {
            clearTimeout(timeoutTimer);

            reject({
                status: xhr.status,
                statusText: xhr.statusText,
                response: xhr.response,
            });
        };

        __DEV__ && console.debug("sending xhr request: ", url);

        xhr.send(opts.body);
    });

    return promise;
};
