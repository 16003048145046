import React from "react";

import { ScreenSize } from "../../interfaces/screen";
import { useScreenSize } from "../../hooks/use-screen-size";
import { compareScreenSizes } from "../../utils/screen";

interface DisplayProps {
    /** Renders for only screen sizes equal and greater than this */
    gt?: ScreenSize;
    /** Renders for only screen sizes equal and lesser than this */
    lt?: ScreenSize;
    /** Renders for only screen sizes equal to this */
    eq?: ScreenSize;
    children: React.ReactNode;
}

export const Display: React.FC<React.PropsWithChildren<DisplayProps>> = ({
    gt,
    lt,
    eq,
    children,
}) => {
    const screenSize = useScreenSize();

    if (
        (eq != null && eq !== screenSize) ||
        (lt != null && !compareScreenSizes(lt, screenSize)) ||
        (gt != null && !compareScreenSizes(screenSize, gt))
    ) {
        return null;
    }

    return <>{children}</>;
};
