enum Status {
    Uninitialized,
    Pending,
    Resolved,
    Rejected,
}

interface Payload<T> {
    status: Status;
    promise: () => Promise<T>;
    result?: T | Promise<T>;
}

export function suspensePromise<T>(promise: () => Promise<T>): () => T {
    const payload: Payload<T> = {
        status: Status.Uninitialized,
        promise: promise,
    };

    return () => {
        if (payload.status === Status.Uninitialized) {
            const _promise = payload.promise();
            payload.status = Status.Pending;
            payload.result = _promise;

            _promise.then(
                (res) => {
                    if (payload.status === Status.Pending) {
                        payload.status = Status.Resolved;
                        payload.result = res;
                    }
                },
                (err) => {
                    if (payload.status === Status.Pending) {
                        payload.status = Status.Rejected;
                        payload.result = err;
                    }
                },
            );

            throw _promise;
        } else if (payload.status === Status.Resolved) {
            return payload.result as unknown as T;
        } else {
            throw payload.result;
        }
    };
}
