import React from "react";
import { Linking, Platform } from "react-native";
import { Modal } from "@swiggy-private/rn-dls";

import { EmptyFunction } from "../../helpers";

type LocationPermissionModalProps = {
    showModal: boolean;
    setShowModal: (b: boolean) => void;
};

const LocationPermissionModalComponent: React.FC<LocationPermissionModalProps> = ({
    showModal,
    setShowModal,
}) => {
    return (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            dismissOnPressingBackdrop
            title="Enable Location Services"
            description={
                Platform.OS === "web"
                    ? "Minis does not have permission to use your location. Please enable in web settings"
                    : "Please go to Settings to enable location services for Minis"
            }
            buttons={{
                primary: {
                    title: {
                        text: Platform.OS === "web" ? "Okay" : "Settings",
                        color: "color-primary-400",
                    },
                    callback:
                        Platform.OS === "web"
                            ? EmptyFunction
                            : async () => await Linking.openSettings(),
                    dismissOnPress: true,
                },
                secondary: {
                    title: {
                        text: Platform.OS === "web" ? "" : "Cancel",
                        color: "color-primary-400",
                    },
                    callback: EmptyFunction,
                    dismissOnPress: true,
                },
            }}
        />
    );
};

/**
 * This modal is triggered when the location service is disabled. It provides option to enable the same via device settings
 */
export const LocationPermissionModal = React.memo(LocationPermissionModalComponent);
