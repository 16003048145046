import React from "react";
import { Text as RNText, TextProps as RNTextProps, TextStyle } from "react-native";
import { useDLS } from "../styles/style-service";

import type { TextCategory, TextColor, TextWeight } from "../styles/interfaces/text";
export type { TextColor } from "../styles/interfaces/text";

export interface TextProps extends RNTextProps {
    children?: ChildElement | ChildElement[];
    category?: TextCategory;
    color?: TextColor;
    weight?: TextWeight;
}

type ChildElement = React.ReactText | TextElement;
export type TextElement = React.ReactElement<TextProps>;

/**
 * Basic text writing.
 * Text has pre-defined set of styles for headings, subtitles, paragraphs, and more.
 *
 * ## Usage
 * ```js
 * import { Text } from '@swiggy-private/rn-dls';
 * <Text style={...}>Place your Text</Text>
 * ```
 *
 * @param param0 {@link TextProps}
 */
export const Text: React.FC<TextProps> = ({
    style,
    children,
    allowFontScaling = false,
    ...props
}) => {
    const dls = useDLS("text", props);
    const { category, color, weight, ...textProps } = props;

    return (
        <RNText
            {...textProps}
            allowFontScaling={allowFontScaling}
            style={[dls.style as TextStyle, style]}>
            {children}
        </RNText>
    );
};
