import React, { useCallback, useEffect, useState } from "react";
import {
    BackHandler,
    StyleSheet,
    TouchableWithoutFeedback,
    useWindowDimensions,
    ViewStyle,
} from "react-native";
import { Portal, useTheme, SpacingValue, Overlay, useLayout } from "@swiggy-private/rn-dls";

import { useMeasure } from "../../hooks/use-measure";
import { SimpleMenuProps } from "./types";
import { AnimatedView } from "../animated-view";

export const SimpleMenu: React.FC<SimpleMenuProps> = (props) => {
    const {
        onClose,
        children,
        anchorEl,
        style,
        skipAndroidStatusBar = false,
        showOverlay = true,
        xOrigin,
        yOrigin,
    } = props;

    const { value: theme } = useTheme();
    const [isOpen, setOpen] = useState(true);
    const position = useMeasure(anchorEl, skipAndroidStatusBar);
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    const [layout, onLayout] = useLayout();

    const onCloseCallback = useCallback(() => setOpen(false), []);
    const onAnimationFinish = useCallback(() => {
        if (!isOpen) {
            onClose();
        }
    }, [isOpen, onClose]);

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        const fn = (): boolean | null => {
            onClose();
            return true;
        };

        const subscription = BackHandler.addEventListener("hardwareBackPress", fn);
        return () => {
            if (subscription != null) {
                subscription.remove();
            } else {
                BackHandler.removeEventListener("hardwareBackPress", fn);
            }
        };
    }, [isOpen, onClose]);

    if (!position) {
        return null;
    }

    const gutterSize = 16;

    const top = layout.measured
        ? position.pageY + layout.height > windowHeight - gutterSize
            ? windowHeight - gutterSize - layout.height
            : position.pageY
        : 0;

    const left = layout.measured
        ? position.pageX + layout.width > windowWidth - gutterSize
            ? windowWidth - gutterSize - layout.width
            : position.pageX
        : 0;

    const menuStyle = StyleSheet.flatten([
        styles.menu,
        {
            position: "absolute",
            left,
            top,
            backgroundColor: theme["color-background-primary"],
            borderColor: theme["color-basic-15"],
            opacity: layout.measured ? 1 : 0,
        },
        theme.elevations[1],
        style,
    ]) as ViewStyle;

    return (
        <Portal>
            {showOverlay ? <Overlay onPress={onCloseCallback} /> : null}
            <TouchableWithoutFeedback>
                <AnimatedView
                    style={menuStyle}
                    animationType="scale"
                    xOrigin={xOrigin ?? "left"}
                    yOrigin={yOrigin ?? "top"}
                    reverse={!isOpen}
                    duration={75}
                    onFinish={onAnimationFinish}
                    onLayout={onLayout}>
                    {children}
                </AnimatedView>
            </TouchableWithoutFeedback>
        </Portal>
    );
};

const styles = StyleSheet.create({
    menu: {
        minWidth: 160,
        borderRadius: 8,
        borderWidth: 1,
        paddingTop: SpacingValue["space-small"],
        paddingBottom: SpacingValue["space-small"],
    },
    container: {
        flex: 1,
    },
});
