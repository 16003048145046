import React from "react";
import { useMountedRef } from "@swiggy-private/react-hooks";

import { EmptyFunction } from "../helpers";
import { AddressContextType, StoreAddressType as StoreAddress } from "../interfaces";

const initialState: Partial<StoreAddress> = {};

export const AddressContext = React.createContext<AddressContextType>({
    state: initialState,
    updateState: EmptyFunction,
    resetAddressState: EmptyFunction,
});

/**
 * This context is used for store address state
 */
export const AddressContextProvider: React.FC<React.PropsWithChildren> = (props) => {
    const mountedRef = useMountedRef();
    const [addressState, updateAddressState] = React.useState<Partial<StoreAddress>>(initialState);

    const updateState = React.useCallback(
        (payload: Partial<StoreAddress>) => {
            mountedRef.current && updateAddressState({ ...addressState, ...payload });
        },
        [addressState, mountedRef],
    );

    const resetAddressState = React.useCallback(() => {
        mountedRef.current && updateAddressState({});
    }, [mountedRef]);

    const value: AddressContextType = React.useMemo(() => {
        return {
            state: addressState,
            updateState,
            resetAddressState,
        };
    }, [addressState, resetAddressState, updateState]);

    return <AddressContext.Provider value={value}>{props.children}</AddressContext.Provider>;
};

export const useAddressContext = (): AddressContextType => React.useContext(AddressContext);
