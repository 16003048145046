export const black = "#000000";
export const white = "#ffffff";

// pad a hexadecimal string with zeros if it needs it
function pad(number: string, length: number): string {
    let str = "" + number;
    while (str.length < length) {
        str = "0" + str;
    }
    return str;
}

interface RGB {
    red: number;
    green: number;
    blue: number;
}

// convert a hex string into an object with red, green, blue numeric properties
// '501214' => { red: 80, green: 18, blue: 20 }
export function hexToRGB(colorValue: string): RGB {
    colorValue = colorValue.startsWith("#") ? colorValue.substring(1) : colorValue;

    return {
        red: parseInt(colorValue.substr(0, 2), 16),
        green: parseInt(colorValue.substr(2, 2), 16),
        blue: parseInt(colorValue.substr(4, 2), 16),
    };
}

// convert an integer to a 2-char hex string
// for sanity, round it and ensure it is between 0 and 255
// 43 => '2b'
function intToHex(rgbint: number): string {
    return pad(Math.min(Math.max(Math.round(rgbint), 0), 255).toString(16), 2);
}

// convert one of our rgb color objects to a full hex color string
// { red: 80, green: 18, blue: 20 } => '501214'
function rgbToHex(rgb: RGB): string {
    return intToHex(rgb.red) + intToHex(rgb.green) + intToHex(rgb.blue);
}

// shade one of our rgb color objects to a distance of i*10%
// ({ red: 80, green: 18, blue: 20 }, 1) => { red: 72, green: 16, blue: 18 }
function rgbShade(rgb: RGB, i: number): RGB {
    return {
        red: rgb.red * (1 - 0.1 * i),
        green: rgb.green * (1 - 0.1 * i),
        blue: rgb.blue * (1 - 0.1 * i),
    };
}

// tint one of our rgb color objects to a distance of i*10%
// ({ red: 80, green: 18, blue: 20 }, 1) => { red: 98, green: 42, blue: 44 }
function rgbTint(rgb: RGB, i: number): RGB {
    return {
        red: rgb.red + (255 - rgb.red) * i * 0.1,
        green: rgb.green + (255 - rgb.green) * i * 0.1,
        blue: rgb.blue + (255 - rgb.blue) * i * 0.1,
    };
}

// take a hex color string and produce a list of 10 tints or shades of that color
// shadeOrTint should be either `rgbShade` or `rgbTint`, as defined above
// this allows us to use `calculate` for both shade and tint
function calculate(colorValue: string, shadeOrTint: typeof rgbShade): string[] {
    const color = hexToRGB(colorValue);
    const shadeValues = [];

    for (let i = 0; i < 10; i++) {
        shadeValues[i] = "#" + rgbToHex(shadeOrTint(color, i));
    }

    return shadeValues;
}

// given a color value, return an array of ten shades in 10% increments
export function calculateShades(colorValue: string): string[] {
    return calculate(colorValue, rgbShade).concat(black);
}

// given a color value, return an array of ten tints in 10% increments
export function calculateTints(colorValue: string): string[] {
    return calculate(colorValue, rgbTint).concat(white);
}

/**
 * Converts an RGB color value to HSL. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and l in the set [0, 1].
 *
 * @param   Number  r       The red color value
 * @param   Number  g       The green color value
 * @param   Number  b       The blue color value
 * @return  Array           The HSL representation
 */

export function rgbToHsl(rgb: RGB): number[] {
    let { red: r, blue: b, green: g } = rgb;

    (r /= 255), (g /= 255), (b /= 255);

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s = 0;
    const l = (max + min) / 2;

    if (max !== min) {
        h = 0; // achromatic
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    return [h, s, l];
}
