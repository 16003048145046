import React, { memo } from "react";
import { View, StyleSheet, StyleProp, ViewStyle, ColorValue } from "react-native";

import { TooltipPointerDirection } from "./placement";

type TriangleProps = {
    size?: number;
    color?: ColorValue;
    direction?: TooltipPointerDirection;
    style?: StyleProp<ViewStyle>;
};

export const Triangle: React.FC<TriangleProps> = memo(
    ({ style, color = "#000", direction, size = 6 }) => {
        let triangleStyle: StyleProp<ViewStyle> | null = null;
        if (direction === TooltipPointerDirection.TOP) {
            triangleStyle = [
                styles.vertical,
                {
                    borderLeftWidth: size,
                    borderRightWidth: size,
                    borderBottomWidth: size,
                    borderBottomColor: color,
                },
            ];
        } else if (direction === TooltipPointerDirection.DOWN) {
            triangleStyle = [
                styles.vertical,
                {
                    borderLeftWidth: size,
                    borderRightWidth: size,
                    borderTopWidth: size,
                    borderTopColor: color,
                },
            ];
        } else if (direction === TooltipPointerDirection.LEFT) {
            triangleStyle = [
                styles.horizontal,
                {
                    borderTopWidth: size,
                    borderBottomWidth: size,
                    borderRightWidth: size,
                    borderRightColor: color,
                },
            ];
        } else if (direction === TooltipPointerDirection.RIGHT) {
            triangleStyle = [
                styles.horizontal,
                {
                    borderTopWidth: size,
                    borderBottomWidth: size,
                    borderLeftWidth: size,
                    borderLeftColor: color,
                },
            ];
        }

        return (
            <View
                testID="DLS__Tooltip_Triangle"
                style={StyleSheet.flatten([styles.triangle, triangleStyle, style])}
            />
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    Triangle.displayName = "TooltipTriangle";
}

const styles = StyleSheet.create({
    horizontal: {
        borderTopColor: "transparent",
        borderBottomColor: "transparent",
    },
    vertical: {
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
    },
    triangle: {
        width: 0,
        height: 0,
        backgroundColor: "transparent",
        borderStyle: "solid",
    },
});
