import * as React from "react";
import { Box, BoxProps } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";

import { FalsyText } from "../../support/falsy-text";
import type { RenderProp } from "../../support/falsy-fc";
import type { TextProps } from "../text";

export interface DialogTitleProps extends Omit<BoxProps, "children"> {
    children?: RenderProp<TextProps> | React.ReactText;
}

export type DialogTitleElement = React.ReactElement<DialogTitleProps, typeof DialogTitle>;

export const DialogTitle: React.FC<DialogTitleProps> = ({
    children,
    ph = SpacingValue["space-medium"],
    pv = SpacingValue["space-large"],
    ...props
}) => {
    return (
        <Box ph={ph} pv={pv} {...props}>
            <FalsyText component={children} numberOfLines={1} category="h4" weight="medium" />
        </Box>
    );
};
