const FORMAT_REGEX = new RegExp("{-?[0-9]+}", "g");
const ELLIPSIS_LENGTH = 3;

/**
 *
 * @param str
 * @param args
 * @returns
 *
 * usage:
 * format("Hello {0} {1}", "World", "!")
 * return "Hello World !"
 *
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function format(str: string, ...args: any): string {
    return str.replace(FORMAT_REGEX, (item) => {
        const intVal = parseInt(item.substring(1, item.length - 1), 10);
        let replace;
        if (intVal >= 0) {
            replace = args[intVal];
        } else if (intVal === -1) {
            replace = "{";
        } else if (intVal === -2) {
            replace = "}";
        } else {
            replace = "";
        }
        return replace;
    });
}

/**
 * Add ellipsis to a string if it is longer than the specified max length
 * @param str String to be truncated
 * @param maxLength Maximum length of the string - must be greater than 3
 * @returns Truncated string with ellipsis
 */
export const truncateStringWithEllipsis = (str: string, maxLength: number): string => {
    // Check if the string is longer than the specified max length
    // and if maxLength is greater than 3 (for ellipsis)
    if (str.length > maxLength && maxLength > ELLIPSIS_LENGTH) {
        // Calculate the length available for the string content
        const availableLength = maxLength - ELLIPSIS_LENGTH;

        // Truncate the string and add ellipsis
        const truncatedString = str.substring(0, availableLength) + "...";

        return truncatedString;
    } else {
        // If the string is shorter than or equal to the max length, return it as is
        return str;
    }
};

/**
 * To check if the string is an HTML or not
 * @param inputString
 * @returns boolean
 */
export const isHTMLString = (inputString?: string): boolean => {
    if (!inputString) {
        return false;
    }

    const htmlRegex = /<[a-z][\s\S]*>/i;
    return htmlRegex.test(inputString);
};

/**
 * To extract only text from an html string
 * @param htmlString
 * @returns string
 */
export const extractTextFromHTMLString = (htmlString: string): string => {
    // Replace HTML tags with a space
    const textWithSpacing = htmlString.replace(/<[^>]*>/g, " ");

    const textWithoutNBSP = textWithSpacing.replace(/&nbsp;/g, " ");

    // Remove extra spaces and trim the result
    const cleanedText = textWithoutNBSP.replace(/\s+/g, " ").trim();

    return cleanedText;
};
