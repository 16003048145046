import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { useAddressContext } from "../../contexts";
import { GoogleMap } from "../google-map";
import { useAddressData } from "../../hooks/use-address-data";
import { StoreAddressType } from "../../interfaces";

import { ProceedAhead } from "./components/proceed-ahead";

type Props = {
    onPressCTA: () => void;
    resourceGetter?: (addressId: string) => StoreAddressType | null;
    onSearchPress?: () => void;
    context?: "update" | "settings" | "default";
    addressId?: string;
    onMountProceedAhead?: () => void;
    onMountMapAddressPill?: () => void;
    onMount?: () => void;
    onLocateMePress?: () => void;
    onError?: (err: string) => void;
    forwardNavigationAnalytics?: () => void;
    calloutMarkerId?: string;
    provider?: "google" | "default";
};

export const MinisMapView: React.FC<Props> = ({
    onPressCTA,
    onSearchPress,
    context,
    addressId,
    onMountMapAddressPill,
    onMountProceedAhead,
    resourceGetter,
    onMount,
    onLocateMePress,
    onError,
    forwardNavigationAnalytics,
    calloutMarkerId,
    provider,
}) => {
    const navigation = useNavigation();
    const item = resourceGetter?.(addressId ?? "");
    const { value: theme } = useTheme();

    const { state, updateState } = useAddressContext();

    const [active, setActive] = React.useState(false);
    const [title, setTitle] = React.useState<null | string>(null);
    const [street, setStreet] = React.useState<null | string>(null);
    const [pincode, setPincode] = React.useState("");
    const [lat, setLat] = React.useState(Number(state.lat));
    const [lng, setlng] = React.useState(Number(state.lng));

    const addressData = useAddressData({
        latitudeValue: lat,
        longitudeValue: lng,
    });

    const forwardNavigation = React.useCallback(() => {
        forwardNavigationAnalytics?.();

        updateState({
            lat: lat.toString(),
            lng: lng.toString(),
            postalCode: Number(pincode),
            googleLocationTitle: title || "",
            googleStreetAddress: street || "",
            city: addressData?.city || "",
            state: addressData?.stateValue || "",
        });

        onPressCTA();
    }, [
        addressData?.city,
        addressData?.stateValue,
        forwardNavigationAnalytics,
        lat,
        lng,
        onPressCTA,
        pincode,
        street,
        title,
        updateState,
    ]);

    const determineCoordinatesDescription = React.useCallback(() => {
        if (addressData) {
            setTitle(addressData.locationTitle);
            setStreet(addressData.streetAddress);
            setPincode(addressData.postalCode);
            setActive(true);
        }
    }, [addressData]);

    const getMarkerCoordinates = React.useCallback(
        (region: { latitude: number; longitude: number }) => {
            setLat(region.latitude);
            setlng(region.longitude);
        },
        [],
    );

    const handleSearchPress = React.useCallback(() => {
        if (context === "update" && onSearchPress) {
            onSearchPress();
        } else {
            if (navigation.canGoBack()) {
                navigation.goBack();
            }
        }
    }, [context, navigation, onSearchPress]);

    React.useEffect(() => {
        determineCoordinatesDescription();
    }, [determineCoordinatesDescription]);

    React.useEffect(() => {
        if (item && !state.lat) {
            updateState(item);
        }
    }, [item, state, updateState]);

    return (
        <View style={styles.mainContainer}>
            <GoogleMap
                lat={lat}
                lng={lng}
                showsUserLocation
                showsMyLocationButton
                markerDraggable={false}
                getMarkerCoordinates={getMarkerCoordinates}
                scrollEnabled
                calloutMarker
                style={styles.map}
                onMount={onMount}
                onError={onError}
                onLocateMePress={onLocateMePress}
                calloutMarkerId={calloutMarkerId}
                provider={provider}
            />
            <View
                style={[
                    styles.proceedContainer,
                    { backgroundColor: theme["color-background-primary"] },
                ]}>
                <ProceedAhead
                    handleBackNavigation={handleSearchPress}
                    isActive={active}
                    showNextScreen={forwardNavigation}
                    title={title}
                    streetAddress={street}
                    onMount={onMountProceedAhead}
                    onMountMapAddressPill={onMountMapAddressPill}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        justifyContent: "space-between",
        paddingHorizontal: SpacingValue["space-medium"],
    },
    map: {
        marginLeft: -16,
        marginRight: -16,
    },
    proceedContainer: {
        bottom: Platform.OS === "web" ? 0 : SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-medium"],
        borderTopLeftRadius: SpacingValue["space-medium"],
        borderTopRightRadius: SpacingValue["space-medium"],
        marginLeft: -16,
        marginRight: -16,
        shadowOffset: { width: 0, height: -1 },
        shadowOpacity: 0.06,
        shadowRadius: 1,
        paddingBottom: SpacingValue["space-xx-small"],
    },
});
