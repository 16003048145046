export const API_SUCCESS_STATUS_CODE = 0;
export const API_FAILURE_STORE_DELETED_CODE = 410;
export const API_UNAUTHORIZED_CODE = 401;

export type ApiResponse<T> = Readonly<{
    readonly statusCode: number;
    readonly statusMessage?: string | null;
    readonly data?: T | null;
    readonly tid?: string | null;
    readonly sid?: string | null;
    readonly deviceId?: string | null;
    readonly errors?: Record<string, string> | null;
}>;
