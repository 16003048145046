import React, { memo } from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { SpacingValue, ElevationValue, useTheme } from "@swiggy-private/rn-dls";

import { ChatMessageInputView, ChatMessageInputViewProps } from "../../../message-input";
import { useChatRenderer } from "../../../../hooks/use-chatrender";
import { ISentMessageResponse } from "../../../../interfaces/types";

export interface ChatConversationFooterProps extends ChatMessageInputViewProps {
    style?: StyleProp<ViewStyle>;
    onMessageSent?: (T: ISentMessageResponse) => void;
    shouldShowAdvanceFooterByDefault?: boolean;
}

export const ChatConversationFooter: React.FC<ChatConversationFooterProps> = memo((props) => {
    const { style, ...inputProps } = props;
    const { value: theme } = useTheme();
    const Input = useChatRenderer()?.renderConversationInput ?? ChatMessageInputView;

    return (
        <View
            style={[
                styles.container,
                { backgroundColor: theme["color-background-primary"] },
                style,
            ]}>
            <Input {...inputProps} />
        </View>
    );
});

ChatConversationFooter.displayName = "ChatConversationFooter";

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-small"],
        paddingVertical: SpacingValue["space-medium"],
        flexDirection: "row",
        alignItems: "center",
        ...ElevationValue[4],
    },
});
