export const CONSTANTS = {
    MESSAGE_TEST_ID: "submitted-review-message",
};

export const DEFAULT_VALUES = {
    MAX_WINDOW_SIZE_FOR_GALLERY_VIEW: 768,
    IMAGE_HEIGHT: 160,
    MAX_IMAGE_WIDTH: 240,
    MAX_TEXT_LENGTH_COUNT: 140,
    STAR_ICON_SIZE: 16,
    MIN_MSG_WIDTH: 240,
};
