import { useCallback } from "react";
import type {
    SdkBlockConversationResponse,
    SdkBlockConversationRequest,
} from "@swiggy-private/connect-chat-sdk";

import { useChatDispatch } from "./use-chatdispatch";
import { useChatSdk } from "./use-chatsdk";
import { useChatConversationDispatch } from "./use-chat-conversation-dispatch";

export const useBlockConversation = (): ((
    params: SdkBlockConversationRequest,
) => Promise<SdkBlockConversationResponse | void>) => {
    const sdk = useChatSdk();
    const chatDispatch = useChatDispatch();
    const conversationDispatch = useChatConversationDispatch();

    const blockConversation = useCallback(
        async (
            params: SdkBlockConversationRequest,
        ): Promise<SdkBlockConversationResponse | void> => {
            if (!sdk || !params.id) {
                return;
            }

            const response = await sdk.blockConversation(params);

            conversationDispatch({
                type: "SET_BLOCKED_INFO",
                payload: {
                    blockedInfo: response.blockedInfo,
                },
            });

            chatDispatch({
                type: "BLOCK_CONVERSATION",
                payload: {
                    conversationId: response.vendorChannelId,
                    blockedInfo: response.blockedInfo,
                },
            });

            return response;
        },
        [conversationDispatch, chatDispatch, sdk],
    );

    return blockConversation;
};
