import { ForwardRefExoticComponent } from "react";
import { FlatList } from "react-native";
import withIO from "./withIO";

import type { IOFlatListPropsFinal } from "./typings/IOFlatList.d";
import withIOProps from "./typings/withIO.d";

const IOFlatList: ForwardRefExoticComponent<IOFlatListPropsFinal> = (
    withIO as unknown as typeof withIOProps
)(FlatList, [
    "flashScrollIndicators",
    "getNativeScrollRef",
    "getScrollResponder",
    "getScrollableNode",
    "scrollToEnd",
    "scrollToIndex",
    "scrollToItem",
    "scrollToOffset",
]);
export default IOFlatList;
