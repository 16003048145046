import * as React from "react";
import { Text, TextProps } from "@swiggy-private/rn-dls";

import TextExtraction from "./text-extraction";
import { CustomParseShape, DefaultParseShape, ParsedTextProps } from "./types";

export type { ParsedTextProps } from "./types";

/* eslint-disable no-useless-escape */
/**
 * This is a list of the known patterns that are provided by this library
 * @typedef {('url'|'phone'|'email')} KnownParsePattern
 */
/**
 * @type {Object.<string, RegExp>}
 * // The keys really should be KnownParsePattern -- but this is unsupported in jsdoc, sadly
 */
export const TEXT_PATTERNS: { [s: string]: RegExp } = {
    /**
     * Segments/Features:
     *  - http/https support https?
     *  - auto-detecting loose domains if preceded by `www.`
     *  - Localized & Long top-level domains \.(xn--)?[a-z0-9-]{2,20}\b
     *  - Allowed query parameters & values, it's two blocks of matchers
     *    ([-a-zA-Z0-9@:%_\+,.~#?&\/=]*[-a-zA-Z0-9@:%_\+~#?&\/=])*
     *    - First block is [-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]* -- this matches parameter names & values (including commas, dots, opening & closing brackets)
     *    - The first block must be followed by a closing block [-a-zA-Z0-9@:%_\+\]~#?&\/=] -- this doesn't match commas, dots, and opening brackets
     */
    url: /(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}\.(xn--)?[a-z0-9-]{2,20}\b([-a-zA-Z0-9@:%_\+\[\],.~#?&\/=]*[-a-zA-Z0-9@:%_\+\]~#?&\/=])*/i,
    phone: /(0|\+?91)?[6-9][0-9]{9}/,
    email: /\S+@\S+\.\S+/,
};

export const ParsedText: React.FC<ParsedTextProps> = ({
    parse = null,
    childrenProps = {},
    ...rootProps
}) => {
    const patterns = React.useMemo(
        () =>
            (parse?.map((option) => {
                const { type, ...patternOption } = option as DefaultParseShape;

                if (type) {
                    if (!TEXT_PATTERNS[type]) {
                        throw new Error(`${type} is not a supported type`);
                    }

                    (patternOption as CustomParseShape).pattern = TEXT_PATTERNS[type];
                }

                return patternOption;
            }) as CustomParseShape[]) || null,
        [parse],
    );

    const parsedText = React.useMemo(() => {
        if (!parse || parse.length === 0) {
            return rootProps.children;
        }

        if (typeof rootProps.children !== "string") {
            return rootProps.children;
        }

        const textExtraction = new TextExtraction(rootProps.children, patterns);

        return textExtraction.parse().map((props, index) => {
            const { style: parentStyle, category, color, weight } = rootProps;
            const { style, ...remainderProps } = props as TextProps;

            return (
                <Text
                    key={`parsedText-${index}`}
                    style={[parentStyle, style]}
                    category={category}
                    color={color}
                    weight={weight}
                    {...childrenProps}
                    {...remainderProps}
                />
            );
        });
    }, [childrenProps, parse, patterns, rootProps]);

    return <Text {...rootProps}>{parsedText}</Text>;
};
