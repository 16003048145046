import React from "react";
import {
    FlatList,
    ListRenderItem,
    Modal,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    TouchableWithoutFeedback,
    View,
    ViewStyle,
} from "react-native";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { ScreenSize, useScreenSize } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

import { UPLOAD_MEDIA_TITLE, MEDIA_SOURCE_DATA, MEDIA_SOURCE_IDS } from "./constants";
import { MediaSourceItem } from "./types";

interface UploadMediaCardProps {
    onPress: (param: MediaSourceItem) => void;
    title: string;
    data: MediaSourceItem[];
    style?: StyleProp<ViewStyle>;
}

interface UploadMediaCardItemProps {
    item: MediaSourceItem;
    onPress: (param: MediaSourceItem) => void;
}

const UploadMediaItem: React.FC<UploadMediaCardItemProps> = ({ item, onPress }) => {
    const handlePress = React.useCallback(() => onPress(item), [item, onPress]);

    return (
        <Pressable
            accessible
            accessibilityRole="button"
            accessibilityLabel={item.name}
            style={styles.uploadMediaItem}
            onPress={handlePress}>
            <SvgIcon icon={item.svg} color="color-basic-60" />
            <Text style={styles.textStyle} category="s2" color="color-basic-100">
                {item.name}
            </Text>
        </Pressable>
    );
};

const UploadMediaCard: React.FC<UploadMediaCardProps> = ({ onPress, title, data, style }) => {
    const { value: theme } = useTheme();

    const _style = StyleSheet.flatten(style);
    const cardContainerStyle = {
        borderColor: theme["color-basic-5"],
        backgroundColor: theme["color-background-primary"],
        bottom:
            _style?.bottom != null || _style?.top != null
                ? _style.bottom
                : SpacingValue["space-medium"],
    };

    const headerStyle = {
        backgroundColor: theme["color-primary-light"],
    };

    const renderItem: ListRenderItem<MediaSourceItem> = ({ item }) => {
        return <UploadMediaItem item={item} onPress={onPress} />;
    };

    return (
        <TouchableWithoutFeedback>
            <View style={[styles.cardContainer, cardContainerStyle, style]}>
                <View style={[styles.header, headerStyle]}>
                    <Text style={styles.title} category="b1" color="high" weight="bold">
                        {title}
                    </Text>
                </View>
                <FlatList
                    data={data}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.id}
                    contentContainerStyle={styles.flatlist}
                    scrollEnabled={false}
                />
            </View>
        </TouchableWithoutFeedback>
    );
};

export interface MediaSourceModalProps {
    onRequestClose: () => void;
    onDismiss?: () => void;
    visible?: boolean;
    title?: string;
    testID?: string;
    style?: StyleProp<ViewStyle>;
    contentStyle?: StyleProp<ViewStyle>;
    sourceData?: MediaSourceItem[];
    sourceAction?: Record<string, () => void>;
    handleUploadOptionSelect?: (s: string) => void;
}

export const MediaSourceModal: React.FC<MediaSourceModalProps> = (props) => {
    const {
        onRequestClose,
        onDismiss,
        visible,
        title = UPLOAD_MEDIA_TITLE,
        style,
        testID,
        contentStyle,
        sourceData = MEDIA_SOURCE_DATA,
        sourceAction,
        handleUploadOptionSelect,
    } = props;

    const screenSize = useScreenSize();
    const handleUploadSelection = React.useCallback(
        (item: { id: string }) => {
            if (sourceAction?.[item.id]) {
                sourceAction[item.id]();
                handleUploadOptionSelect?.(item.id);
            }
        },
        [handleUploadOptionSelect, sourceAction],
    );

    const { value: theme } = useTheme();
    const isDesktop = screenSize === ScreenSize.Large && Platform.OS === "web";

    useMount(() => {
        if (isDesktop) {
            sourceAction?.[MEDIA_SOURCE_IDS.gallery]?.();
        }
    });

    if (isDesktop) {
        return null;
    }

    return (
        <Modal
            animationType="none"
            transparent
            presentationStyle="overFullScreen"
            visible={visible}
            onDismiss={onDismiss}
            onRequestClose={onRequestClose}>
            <TouchableWithoutFeedback onPress={onRequestClose}>
                <View
                    style={[
                        styles.container,
                        { backgroundColor: theme["color-overlay-primary"] },
                        style,
                    ]}
                    testID={testID}>
                    <UploadMediaCard
                        onPress={handleUploadSelection}
                        title={title}
                        data={sourceData}
                        style={contentStyle}
                    />
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    cardContainer: {
        position: "absolute",
        left: SpacingValue["space-medium"],
        right: SpacingValue["space-medium"],
        borderRadius: 16,
        borderWidth: 1,
        borderStyle: "solid",
        zIndex: 10,
    },
    header: {
        height: 54,
        borderTopLeftRadius: SpacingValue["space-medium"],
        borderTopRightRadius: SpacingValue["space-medium"],
    },
    title: {
        marginLeft: SpacingValue["space-large"],
        marginTop: SpacingValue["space-medium"] + 2,
        marginBottom: SpacingValue["space-medium"],
    },
    flatlist: {
        marginLeft: SpacingValue["space-large"],
        justifyContent: "space-evenly",
    },
    uploadMediaItem: {
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: SpacingValue["space-small"],
    },
    textStyle: {
        marginLeft: 18,
    },
});
