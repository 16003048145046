import React, { useContext, useRef } from "react";
import { View, StyleSheet, TextInput, ScrollView } from "react-native";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { FormInput } from "@swiggy-private/react-native-ui";
import { FormContext } from "@swiggy-private/react-native-ui";

import { ADDRESS_FORM_CONSTS } from "../constants";

interface Props {
    handleCompleteAddress: (text: string) => void;
    handleLandmark: (text: string) => void;
    handleInstructions: (text: string) => void;
    handleAddressName: (text: string) => void;
    handlePincode: (text: string) => void;
    handleCity: (text: string) => void;
    handleState: (text: string) => void;
    pinCodeError: boolean;
    pincode: string;
    completeAddress: string;
    landmark: string;
    instructions: string;
    addressLabel: string;
    city: string;
    stateValue: string;
    scrollRef?: React.RefObject<ScrollView>;
}

export const InputArea: React.FC<Props> = ({
    handleCompleteAddress,
    handleLandmark,
    handleInstructions,
    handleAddressName,
    handlePincode,
    handleCity,
    handleState,
    pinCodeError,
    pincode,
    completeAddress,
    landmark,
    instructions,
    addressLabel,
    city,
    stateValue,
}) => {
    const { value: theme } = useTheme();
    const inputStyle = {
        fontFamily: theme["text-subheader-2-font-family"],
        letterSpacing: theme["text-subheader-2-letter-spacing"],
        color: theme["color-basic-100"],
    };

    const landmarkRef = useRef<TextInput>(null);
    const pincodeRef = useRef<TextInput>(null);
    const cityRef = useRef<TextInput>(null);
    const stateRef = useRef<TextInput>(null);
    const instructionsRef = useRef<TextInput>(null);
    const annotationRef = useRef<TextInput>(null);

    const { getErrorForField, removeErrorForField } = useContext(FormContext);

    const addressLabelError = getErrorForField("tag");

    return (
        <>
            <View style={styles.mt12}>
                <FormInput
                    name="complete-address"
                    label={ADDRESS_FORM_CONSTS.ADDRESS_INPUT_LABEL}
                    style={[styles.largeInputBox, styles.input]}
                    onChangeText={handleCompleteAddress}
                    placeholderTextColor={theme["color-basic-60"]}
                    textStyle={inputStyle}
                    testID="complete-address"
                    textContentType="fullStreetAddress"
                    spellCheck={false}
                    multiline
                    blurOnSubmit={false}
                    value={completeAddress}
                    returnKeyType="done"
                    onSubmitEditing={() => landmarkRef.current?.focus()}
                />

                <FormInput
                    ref={landmarkRef}
                    name="landmark"
                    label={ADDRESS_FORM_CONSTS.LANDMARK_INPUT_LABEL}
                    onChangeText={handleLandmark}
                    placeholderTextColor={theme["color-basic-60"]}
                    textStyle={inputStyle}
                    testID="landmark"
                    textContentType="location"
                    spellCheck={false}
                    style={styles.input}
                    blurOnSubmit={false}
                    returnKeyType="default"
                    value={landmark}
                    onSubmitEditing={() => cityRef.current?.focus()}
                />

                <FormInput
                    ref={cityRef}
                    name="city"
                    label={ADDRESS_FORM_CONSTS.CITY_INPUT_LABEL}
                    onChangeText={handleCity}
                    placeholderTextColor={theme["color-basic-60"]}
                    textStyle={inputStyle}
                    testID="city"
                    textContentType="addressCity"
                    spellCheck={false}
                    style={styles.input}
                    blurOnSubmit={false}
                    returnKeyType="default"
                    value={city}
                    onSubmitEditing={() => stateRef.current?.focus()}
                />

                <FormInput
                    ref={stateRef}
                    name="state"
                    label={ADDRESS_FORM_CONSTS.STATE_INPUT_LABEL}
                    onChangeText={handleState}
                    placeholderTextColor={theme["color-basic-60"]}
                    textStyle={inputStyle}
                    testID="state"
                    textContentType="addressState"
                    spellCheck={false}
                    style={styles.input}
                    blurOnSubmit={false}
                    returnKeyType="default"
                    value={stateValue}
                    onSubmitEditing={() => pincodeRef.current?.focus()}
                />

                <FormInput
                    ref={pincodeRef}
                    name="pincode"
                    label={ADDRESS_FORM_CONSTS.PINCODE_LABEL}
                    onChangeText={handlePincode}
                    placeholderTextColor={theme["color-basic-60"]}
                    textStyle={inputStyle}
                    testID="pincode"
                    textContentType="postalCode"
                    keyboardType="number-pad"
                    returnKeyType="default"
                    maxLength={6}
                    value={pincode}
                    error={pinCodeError}
                    hint={pinCodeError ? ADDRESS_FORM_CONSTS.PINCODE_INVALID_MSG : ""}
                    style={styles.input}
                />

                <FormInput
                    ref={instructionsRef}
                    name="location-instructions"
                    label={ADDRESS_FORM_CONSTS.INSTRUCTIONS_INPUT.LABEL}
                    multiline
                    maxLength={ADDRESS_FORM_CONSTS.INSTRUCTIONS_INPUT.MAX_LENGTH}
                    style={[styles.largeInputBox, styles.input]}
                    onChangeText={handleInstructions}
                    placeholderTextColor={theme["color-basic-60"]}
                    textStyle={inputStyle}
                    testID="instructions"
                    textContentType="location"
                    spellCheck={false}
                    blurOnSubmit={false}
                    value={instructions}
                    returnKeyType="default"
                    onSubmitEditing={() => annotationRef.current?.focus()}
                />
            </View>

            <View style={styles.addressLabelContainer}>
                <Text category="b1" color="high" weight="bold">
                    {ADDRESS_FORM_CONSTS.SAVE_ADDRESS_AS_TITLE}
                </Text>
                <FormInput
                    ref={annotationRef}
                    name="tag"
                    placeholder={ADDRESS_FORM_CONSTS.ADDRESS_NAME_INPUT_PLACEHOLDER}
                    onChangeText={handleAddressName}
                    placeholderTextColor={theme["color-basic-60"]}
                    textStyle={inputStyle}
                    style={styles.mt16}
                    testID="address-label"
                    textContentType="name"
                    returnKeyType="default"
                    value={addressLabel}
                    spellCheck={false}
                    label={ADDRESS_FORM_CONSTS.SAVE_ADDRESS_INPUT.LABEL}
                    error={
                        addressLabel.length > ADDRESS_FORM_CONSTS.SAVE_ADDRESS_INPUT.MAX_LENGTH ||
                        !!addressLabelError
                    }
                    hint={addressLabelError}
                    showCounter={!!addressLabel.length}
                    maxLength={ADDRESS_FORM_CONSTS.SAVE_ADDRESS_INPUT.MAX_LENGTH}
                    removeError={removeErrorForField}
                />
            </View>
        </>
    );
};

const styles = StyleSheet.create({
    input: {
        marginTop: SpacingValue["space-x-large"],
    },
    largeInputBox: {
        height: 104,
    },
    addressLabelContainer: {
        marginVertical: SpacingValue["space-x-large"],
    },
    mt12: {
        marginTop: SpacingValue["space-small"],
    },
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
});
