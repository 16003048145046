import memoizeOne from "memoize-one";
import { ColorValue } from "react-native";

import {
    TextColor,
    TextStyle,
    TextStyleMap,
    TextStyleMeta,
    SwiggyTextStyleMap,
} from "../interfaces/text";
import { Theme } from "@swiggy-private/rn-dls-theme";

const textColorMap = memoizeOne(
    (theme: Theme): Record<TextColor, ColorValue> => ({
        ...theme,
        primary: theme["color-primary"],
        warning: theme["color-warning"],
        critical: theme["color-critical"],
        success: theme["color-positive"],
        positive: theme["color-positive"],
        highlight: theme["color-highlight"],
        highest: theme["color-basic-100"],
        high: theme["color-basic-75"],
        medium: theme["color-basic-60"],
        low: theme["color-basic-45"],
        lowest: theme["color-basic-30"],
    }),
);

const textStyleMap = memoizeOne(
    ({ weight = "regular" }: TextStyleMeta, theme: Theme): TextStyleMap => ({
        h1: {
            fontSize: theme["text-heading-1-font-size"],
            fontFamily: theme["text-heading-1-font-family"],
            lineHeight: theme["text-heading-1-line-height"],
            letterSpacing: theme["text-heading-1-letter-spacing"],
        },
        h2: {
            fontSize: theme["text-heading-2-font-size"],
            fontFamily: theme["text-heading-2-font-family"],
            lineHeight: theme["text-heading-2-line-height"],
            letterSpacing: theme["text-heading-2-letter-spacing"],
        },
        h3: {
            fontSize: theme["text-heading-3-font-size"],
            fontFamily: theme["text-heading-3-font-family"],
            lineHeight: theme["text-heading-3-line-height"],
            letterSpacing: theme["text-heading-3-letter-spacing"],
        },
        h4: {
            fontSize: theme["text-heading-4-font-size"],
            fontFamily: theme["text-heading-4-font-family"],
            lineHeight: theme["text-heading-4-line-height"],
            letterSpacing: theme["text-heading-4-letter-spacing"],
        },
        h5: {
            fontSize: theme["text-heading-5-font-size"],
            fontFamily: theme["text-heading-5-font-family"],
            lineHeight: theme["text-heading-5-line-height"],
            letterSpacing: theme["text-heading-5-letter-spacing"],
        },
        s1: {
            fontSize: theme["text-subheader-1-font-size"],
            fontFamily:
                weight === "bold"
                    ? theme["text-subheader-1-bold-font-family"]
                    : theme["text-subheader-1-font-family"],
            lineHeight: theme["text-subheader-1-line-height"],
            letterSpacing: theme["text-subheader-1-letter-spacing"],
        },
        s2: {
            fontSize: theme["text-subheader-2-font-size"],
            fontFamily: theme["text-subheader-2-font-family"],
            lineHeight: theme["text-subheader-2-line-height"],
            letterSpacing: theme["text-subheader-2-letter-spacing"],
        },
        s3: {
            fontSize: theme["text-subheader-3-font-size"],
            fontFamily: theme["text-subheader-3-font-family"],
            lineHeight: theme["text-subheader-3-line-height"],
            letterSpacing: theme["text-subheader-3-letter-spacing"],
        },
        b1: {
            fontSize: theme[`text-body-1-${weight}-font-size`],
            fontFamily: theme[`text-body-1-${weight}-font-family`],
            lineHeight: theme[`text-body-1-${weight}-line-height`],
            letterSpacing: theme[`text-body-1-${weight}-letter-spacing`],
        },
        b2: {
            fontSize: theme[`text-body-2-${weight}-font-size`],
            fontFamily: theme[`text-body-2-${weight}-font-family`],
            lineHeight: theme[`text-body-2-${weight}-line-height`],
            letterSpacing: theme[`text-body-2-${weight}-letter-spacing`],
        },
        b3: {
            fontSize: theme[`text-body-3-${weight}-font-size`],
            fontFamily: theme[`text-body-3-${weight}-font-family`],
            lineHeight: theme[`text-body-3-${weight}-line-height`],
            letterSpacing: theme[`text-body-3-${weight}-letter-spacing`],
        },
        b4: {
            fontSize: theme[`text-body-4-${weight}-font-size`],
            fontFamily: theme[`text-body-4-${weight}-font-family`],
            lineHeight: theme[`text-body-4-${weight}-line-height`],
            letterSpacing: theme[`text-body-4-${weight}-letter-spacing`],
        },
        btn1: {
            fontSize: theme["text-btn-1-font-size"],
            fontFamily: theme["text-btn-1-font-family"],
            lineHeight: theme["text-btn-1-line-height"],
            letterSpacing: theme["text-btn-1-letter-spacing"],
        },
        btn2: {
            fontSize: theme["text-btn-2-font-size"],
            fontFamily: theme["text-btn-2-font-family"],
            lineHeight: theme["text-btn-2-line-height"],
            letterSpacing: theme["text-btn-2-letter-spacing"],
        },
        btn3: {
            fontSize: theme["text-btn-3-font-size"],
            fontFamily: theme["text-btn-3-font-family"],
            lineHeight: theme["text-btn-3-line-height"],
            letterSpacing: theme["text-btn-3-letter-spacing"],
        },
        btn4: {
            fontSize: theme["text-btn-4-font-size"],
            fontFamily: theme["text-btn-4-font-family"],
            lineHeight: theme["text-btn-4-line-height"],
            letterSpacing: theme["text-btn-4-letter-spacing"],
        },
        btn5: {
            fontSize: theme["text-btn-5-font-size"],
            fontFamily: theme["text-btn-5-font-family"],
            lineHeight: theme["text-btn-5-line-height"],
            letterSpacing: theme["text-btn-5-letter-spacing"],
        },
        ...(theme.swiggyTypography as SwiggyTextStyleMap),
    }),
);

export const getTextStyles = (meta: TextStyleMeta, theme: Theme): TextStyle => {
    const category = meta.category ?? "b1";
    const color = meta.color ?? "high";

    const textStyle = textStyleMap(meta, theme)[category];
    const textColor = textColorMap(theme)[color];

    return {
        color: textColor,
        ...textStyle,
    };
};
