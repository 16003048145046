import { extractTextFromHTMLString, isHTMLString } from "@swiggy-private/common-helpers";
import { MEDIA_TYPE } from "../constants/media";

export const getTextualDescription = (description: string): string => {
    if (!description?.trim()) {
        return "";
    }

    if (!isHTMLString(description)) {
        return description;
    }

    let parsedDescription = description;
    try {
        parsedDescription = JSON.parse(description);
    } catch (err) {}

    return extractTextFromHTMLString(parsedDescription);
};

/*
 * Calculate total size of different media assets
 * can take regular `File` type as well custom one having a common property `type`
 */
export const calculateMediaTypeAssets = <T extends { type: string }>(
    media: T[],
): { video: number; image: number } => {
    return media.reduce(
        (acc, file) => {
            acc.image += file?.type?.indexOf(MEDIA_TYPE.IMAGE) > -1 ? 1 : 0;
            acc.video += file?.type?.indexOf(MEDIA_TYPE.VIDEO) > -1 ? 1 : 0;
            return acc;
        },
        { video: 0, image: 0 },
    );
};
