interface KeyDetails {
    [key: string]: string;
}

export const replaceSpacesInArrayMap = (jsonArray: KeyDetails[]): KeyDetails[] => {
    if (!jsonArray || !jsonArray?.length) {
        return [];
    }

    // Helper function to replace spaces in a string
    const replaceSpaces = (str: string): string => str.replace(/\s+/g, " ");

    // Process each JSON object in the array
    return jsonArray.map((obj) => {
        // Create a new object to avoid mutating the original
        const newObj = { ...obj };
        // Process each key-value pair in the object
        Object.keys(newObj).forEach((key) => {
            if (typeof newObj[key] === "string") {
                // Replace spaces in the key and value
                newObj[key] = replaceSpaces(newObj[key]);
            }
        });
        return newObj;
    });
};
