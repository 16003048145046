import React from "react";
import { getPathProps, getStartAt, getStroke, linearEase } from "../helpers";
import { ColorFormat, Props } from "../types";
import { useElapsedTime } from "./use-elapsed-time";

interface ReturnValue {
    elapsedTime: number;
    path: string;
    pathLength: number;
    remainingTime: number;
    rotation: string;
    size: number;
    stroke: ColorFormat;
    strokeDashoffset: number;
    strokeWidth: number;
}

export const useCountdown = (props: Props): ReturnValue => {
    const {
        duration,
        initialRemainingTime,
        updateInterval,
        size = 180,
        strokeWidth = 12,
        trailStrokeWidth,
        isPlaying = true,
        rotation = "clockwise",
        onComplete,
        onUpdate,
    } = props;
    const remainingTimeRef = React.useRef<number>();
    const maxStrokeWidth = Math.max(strokeWidth, trailStrokeWidth ?? 0);
    const { path, pathLength } = getPathProps(size, maxStrokeWidth, rotation);

    const { elapsedTime } = useElapsedTime({
        isPlaying,
        duration,
        startAt: getStartAt(duration, initialRemainingTime),
        updateInterval,
        onUpdate:
            typeof onUpdate === "function"
                ? (elapsed: number) => {
                      const remainingTime = Math.ceil(duration - elapsed);
                      if (remainingTime !== remainingTimeRef.current) {
                          remainingTimeRef.current = remainingTime;
                          onUpdate(remainingTime);
                      }
                  }
                : undefined,
        onComplete:
            typeof onComplete === "function"
                ? (totalElapsedTime: number) => {
                      const { shouldRepeat, delay, newInitialRemainingTime } =
                          onComplete(totalElapsedTime) ?? {};
                      if (shouldRepeat) {
                          return {
                              shouldRepeat,
                              delay,
                              newStartAt: getStartAt(duration, newInitialRemainingTime),
                          };
                      }

                      return;
                  }
                : undefined,
    });

    const remainingTimeRow = duration - elapsedTime;

    return {
        elapsedTime,
        path,
        pathLength,
        remainingTime: Math.ceil(remainingTimeRow),
        rotation,
        size,
        stroke: getStroke(props, remainingTimeRow),
        strokeDashoffset: linearEase({
            time: elapsedTime,
            start: 0,
            goal: pathLength,
            duration: duration,
        }),
        strokeWidth,
    };
};
