import React, { Suspense, SuspenseProps } from "react";

import { useSuspensePromise } from "../../hooks/use-suspense-promise";

interface AsyncComponentProps<T> {
    loader: SuspenseProps["fallback"];
    fetch: () => Promise<T>;
    ContentComponent: React.ComponentType<{ data: T }>;
}

interface AsyncComponentInnerProps<T> {
    readData: () => T;
    ContentComponent: AsyncComponentProps<T>["ContentComponent"];
}

const AsyncComponentInner = <T,>({
    readData,
    ContentComponent,
}: AsyncComponentInnerProps<T>): JSX.Element => {
    const data = readData();
    return <ContentComponent data={data} />;
};

export const AsyncComponent = <T,>({
    loader,
    fetch,
    ContentComponent,
}: AsyncComponentProps<T>): JSX.Element => {
    const suspensePromise = useSuspensePromise(fetch);
    return (
        <Suspense fallback={loader}>
            <AsyncComponentInner readData={suspensePromise} ContentComponent={ContentComponent} />
        </Suspense>
    );
};
