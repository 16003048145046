export const SEARCH_LOCATION_CONSTS = {
    LOCATE_ME_TEXT: "Use my current location via GPS",
    PICKUP_ADDRESS_QUESTION_TITLE: "Add your pickup address",
    INPUT_PLACEHOLDER: "Search for your pickup location",
    DEBOUNCE_TIME: 300,
    ANDROID_LOCATION_PERMISSION_MESSAGE:
        "Allowing this helps Mini auto-locate your store's pickup adress",
    PERMISSION_GRANTED: "granted",
};

export const PLATFORM = {
    ANDROID: "android",
    IOS: "ios",
};

export const EMPTY_RESULT_TEXT =
    "We could not find what you are looking for. Please try another search";
