import React from "react";
import { View } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import {
    createShimmerPlaceholder,
    ShimmerPlaceholderProps,
} from "react-native-shimmer-placeholder";

import { useLayout, useTheme } from "@swiggy-private/rn-dls";

const ShimmerPlaceholder = createShimmerPlaceholder(LinearGradient);

export interface ShimmerProps extends ShimmerPlaceholderProps {
    width?: string | number;
    height?: string | number;
}

export const Shimmer: React.FC<ShimmerProps> = ({
    width = "100%",
    height = "100%",
    style,
    ...props
}) => {
    const [layout, onLayout] = useLayout();
    const { value: theme } = useTheme();

    const _width = layout.measured ? layout.width : Number(width) || 0;
    const _height = layout.measured ? layout.height : Number(height) || 0;

    const backgroundColor = theme["color-basic-5"] as string;
    // TODO : Add to DLS
    const backgroundColor2 = "#FDFDFE";

    return _width > 0 && _height > 0 && layout.measured ? (
        <ShimmerPlaceholder
            style={style}
            width={_width}
            height={_height}
            duration={1000}
            shimmerColors={[backgroundColor, backgroundColor2, backgroundColor]}
            {...props}
        />
    ) : (
        <View
            onLayout={onLayout}
            style={[
                {
                    width,
                    height,
                    backgroundColor,
                },
                style,
            ]}
        />
    );
};
