const GRAPH_API_ENDPOINT = "https://graph.facebook.com/v19.0";

import { Endpoint, HttpClient } from "@swiggy-private/http-client";

import {
    appendAppEvents,
    deDuplicationId,
    pushToCollection,
    uniquePixelCollection,
} from "./helpers";
import { FBError, IMetaEvents, TFBAdsMetaResponse, TPixelIDCollection } from "./interface";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const httpClient = new HttpClient<any>();
class FB_GRAPH_API {
    /**
     * * Fires FB GRAPH API calls
     *
     * @static
     * @param {string} endpoint
     * @param {Record<string, unknown>} [requestBody]
     * @memberof FB_GRAPH_API
     */
    static callFBAPI = async (
        endpoint: string,
        requestBody?: Record<string, unknown>,
    ): Promise<Record<string, unknown> | undefined> => {
        if (!endpoint) {
            return;
        }
        try {
            const endpointNew = Endpoint.from(endpoint);

            const options = {
                headers: {
                    "Content-Type": "application/json",
                },
                body: requestBody ? JSON.stringify(requestBody) : undefined,
            };
            const reqContent = { endpoint: endpointNew, ...options };

            const response = await (requestBody
                ? httpClient.post(reqContent)
                : httpClient.get(reqContent));

            return response;
        } catch (err) {
            if (__DEV__) {
                console.error(err);
            }
        }
        return;
    };

    /**
     * * Fetches Pixel-ID from Facebook based on accessToken
     *
     * @static
     * @param {string} accessToken
     * @memberof FB_GRAPH_API
     */
    static fetchPixelID = async (
        accessToken: string,
    ): Promise<[TPixelIDCollection, number | undefined]> => {
        let pixelIDCollection: TPixelIDCollection = [];
        let errorCode;

        if (!accessToken) {
            return [[], undefined];
        }

        // * Retrieves Pixel Collections from a Personal Account
        const respBusiness = await this.callFBAPI(
            `${GRAPH_API_ENDPOINT}/me/businesses?fields=adspixels{id,name}&access_token=${accessToken}`,
        );

        if (respBusiness?.error) {
            errorCode = (respBusiness as FBError)?.error?.code;
        }

        if ((respBusiness?.data as TFBAdsMetaResponse)?.length) {
            pixelIDCollection = pushToCollection(
                respBusiness as { data: TFBAdsMetaResponse },
                pixelIDCollection,
            );
        }

        // * Retrieves Pixel Collections from a Business Account
        const respPersonal = await this.callFBAPI(
            `${GRAPH_API_ENDPOINT}/me/adaccounts?fields=adspixels{id,name}&access_token=${accessToken}`,
        );

        if (respPersonal?.error) {
            errorCode = (respPersonal as FBError)?.error?.code;
        }

        if ((respPersonal?.data as TFBAdsMetaResponse)?.length) {
            pixelIDCollection = pushToCollection(
                respPersonal as { data: TFBAdsMetaResponse },
                pixelIDCollection,
            );
        }

        const uniqueArray = uniquePixelCollection(pixelIDCollection);

        return [uniqueArray, errorCode];
    };

    /**
     * * Sends FB Pixel Events
     *
     * @static
     * @param {IMetaEvents} {
     *         eventName,
     *         customData,
     *         FBMetaData,
     *         userDeviceData,
     *         userAgent,
     *     }
     * @memberof FB_GRAPH_API
     */
    static postMetaEvents = async ({
        eventName,
        customData,
        FBMetaData,
        userDeviceData,
    }: IMetaEvents): Promise<Record<string, unknown> | undefined | void> => {
        // * Don't fire events if we don't have pixelId and accessToken
        if (!FBMetaData?.metaPixelId || !FBMetaData?.accessToken) {
            return;
        }

        // * Don't fire events if its manually turned-off by Seller
        if (!FBMetaData?.enableEventCapture) {
            return;
        }

        if (customData) {
            customData.currency = "INR";
        }

        const userPlatform = userDeviceData?.platform;

        const AppData = appendAppEvents(userDeviceData);
        const eventID = deDuplicationId({ userDeviceData, eventName, customData });

        if (typeof window !== "undefined" && typeof window.fbq === "function") {
            try {
                window.fbq("track", eventName, {
                    ...customData,
                    ...AppData,
                    client_user_agent: userDeviceData?.userAgent,
                });
            } catch (err) {
                // the parent container using this package should handle
                throw err;
            }
        }

        // if conversion api is allowed, trigger the conversion api.
        if (FBMetaData?.enableConversionApi) {
            const resp = await this.callFBAPI(
                `${GRAPH_API_ENDPOINT}/${FBMetaData?.metaPixelId}/events?access_token=${FBMetaData?.accessToken}`,
                {
                    data: [
                        {
                            event_name: eventName,
                            event_time: Math.floor(Date.now() / 1000),
                            action_source: userPlatform === "web" ? "website" : "app",
                            user_data: {
                                // TODO : tobe added from `userDeviceData`
                                em: [null],
                                ph: [null],
                                client_user_agent: userDeviceData?.userAgent,
                            },
                            custom_data: customData,
                            event_id: eventID,
                            ...AppData,
                        },
                    ],
                },
            );

            return resp;
        }
    };
}

export default FB_GRAPH_API;

/**
 * * Guide for FB responses
 *
 * @param accessToken
 * @memberof FB_GRAPH_API
 */
/*
* Event Error [example of malformed array in AppData]
{
  "error": {
    "message": "(#100) param data must be an array.",
    "type": "OAuthException",
    "code": 100,
    "fbtrace_id": "AHiGsGE5H8SOtcjSLOJtuTq"
  }
}


* expired eventTime
{
  "error": {
    "message": "Invalid parameter",
    "type": "OAuthException",
    "code": 100,
    "error_subcode": 2804003,
    "is_transient": false,
    "error_user_title": "Event timestamp too old",
    "error_user_msg": "The timestamp for this event is too far in the past. Events need to be sent from your server within seven days of when they occurred. Enter a timestamp that has occurred within the last seven days.",
    "fbtrace_id": "AXSV1pzxRmCCXvolWxRzJQm"
  }
}

* malformed accessToken
{
  "error": {
    "message": "Malformed access token",
    "type": "OAuthException",
    "code": 190,
    "fbtrace_id": "AJiFVHyxsCKQQhK7gxxUeIG"
  }
}

* multiple calls to FB graph api

{
   "error": {
      "message": "(#80004) There have been too many calls to this ad-account. Wait a bit and try again. For more info, please refer to https://developers.facebook.com/docs/graph-api/overview/rate-limiting.",
      "type": "OAuthException",
      "code": 80004,
      "error_subcode": 2446079,
      "fbtrace_id": "Awt44YVBERyuNbqZ5dvVpwg"
   }
}

* success response for events
{
  "events_received": 1,
  "messages": [
  ],
  "fbtrace_id": "Axc47AJu-ERAPdQzHkM8THB"
}



* delete permissions
me/permissions?fields=read_insights,ads_read


* success response for pixel-id

{
  "data": [
    {
      "adspixels": {
        "data": [
          {
            "id": "551749231928188"
          }
        ],
        "paging": {
          "cursors": {
            "before": "NTUxNzQ5MjMxOTI4MTg4",
            "after": "NTUxNzQ5MjMxOTI4MTg4"
          }
        }
      },
      "name": "Naveen F",
      "id": "act_367293225"
    },
    {
      "adspixels": {
        "data": [
          {
            "id": "927459041746436"
          }
        ],
        "paging": {
          "cursors": {
            "before": "OTI3NDU5MDQxNzQ2NDM2",
            "after": "OTI3NDU5MDQxNzQ2NDM2"
          }
        }
      },
      "name": "A Kumar",
      "id": "act_222241601247618"
    },
    {
      "name": "Page3 ADs",
      "id": "act_177543681376467"
    }
  ],
  "paging": {
    "cursors": {
      "before": "NjAwMzE3NTQ5ODczNwZDZD",
      "after": "MjM4NTM1NTk2MjQ2NDAxMjYZD"
    }
  }
}

*/
