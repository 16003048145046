import React, { useContext, useMemo } from "react";
import { IThemeContext, Theme, ThemeContext, DefaultThemes } from "@swiggy-private/rn-dls-theme";

const themeRegistry: Record<string, Theme> = {
    ...DefaultThemes,
};

export const ThemeRegistry = {
    add(type: string, theme: Theme): void {
        themeRegistry[type] = theme;
    },
    delete(type: string): void {
        delete themeRegistry[type];
    },
    get(type: string): Theme {
        return themeRegistry[type];
    },
};

export const ThemeProvider: React.FC<React.PropsWithChildren<{ theme: string }>> = ({
    theme: type,
    ...props
}) => {
    const theme = ThemeRegistry.get(type);
    const value = useMemo(
        () => ({
            type,
            value: theme,
        }),
        [type, theme],
    );

    return <ThemeContext.Provider value={value} {...props} />;
};

export const useTheme = (): IThemeContext => {
    return useContext(ThemeContext);
};
