import * as React from "react";
import { StyleSheet, Pressable, View, Platform } from "react-native";

import {
    GoogleMap as GoogleApiWrapper,
    Marker,
    GoogleMapProps as IMapProps,
    useJsApiLoader,
} from "@react-google-maps/api";
import { useMountedRef, useMount } from "@swiggy-private/react-hooks";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

import { LocationPermissionModal } from "../location-permission-modal";
import { autoLocate } from "../search-location/helpers";
import { CalloutMarker } from "./callout-marker";

import type { GoogleMapProps } from "./types";
import type { GeoPosition } from "../search-location/helpers/types";

type MapWebProps = IMapProps & GoogleMapProps;

const isLocalhost = typeof window !== "undefined" && window.location.hostname === "localhost";

const MapWebComponent: React.FC<React.PropsWithChildren<MapWebProps>> = (props) => {
    const {
        lat,
        lng,
        style,
        scrollEnabled,
        showsUserLocation,
        showsMyLocationButton,
        markerDraggable,
        getMarkerCoordinates,
        calloutMarker,
        mapMarker,
        onMount,
        onLocateMePress,
        onError,
        calloutMarkerId,
    } = props;

    const [currentCoordinates, setCurrentCoordinates] = React.useState<{
        lat: number;
        lng: number;
    }>({ lat, lng });

    const [showModal, setShowModal] = React.useState(false);
    const [googleMap, setMap] = React.useState<google.maps.Map>();

    const mountedRef = useMountedRef();

    const currentLocationCallback = React.useCallback((position: GeoPosition) => {
        const { latitude, longitude } = position.coords;
        setCurrentCoordinates({ lat: latitude, lng: longitude });
    }, []);

    const goToCurrentLocation = React.useCallback(() => {
        try {
            onLocateMePress?.();

            if (showsUserLocation) {
                autoLocate()
                    .then((position) => {
                        if (mountedRef.current && position) {
                            currentLocationCallback(position);
                        } else {
                            mountedRef.current && setShowModal(true);
                        }
                    })
                    .catch((err) => {
                        if (mountedRef.current) {
                            setShowModal(true);
                            onError?.(err.message);
                        }
                    });
            }
        } catch (err) {
            mountedRef && onError?.(err as string);
        }
    }, [currentLocationCallback, mountedRef, onError, onLocateMePress, showsUserLocation]);

    const handleRegionChange = React.useCallback(() => {
        if (!googleMap) {
            return;
        }
        const region = googleMap.getCenter()?.toJSON();
        if (!region) {
            return;
        }
        setCurrentCoordinates({
            lat: region.lat,
            lng: region.lng,
        });

        if (getMarkerCoordinates) {
            getMarkerCoordinates({ latitude: region.lat, longitude: region.lng });
        }
    }, [getMarkerCoordinates, googleMap]);

    useMount(() => {
        onMount?.();
    });

    const calloutLeftValue = useSelectScreen({
        lg: "40%",
        default: "26%",
    });

    const autoLocateAdditionalStyles = useSelectScreen({
        lg: {
            left: "88%",
            top: "62%",
        },
        default: {
            left: "82%",
            top: "65%",
        },
    });

    const mapContainerStyle = {
        width: "100%",
        height: "100%",
    };

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey:
            Platform.OS === "web" && !isLocalhost
                ? "AIzaSyD6b95EVxnrx5Vcdh_1AqcsI8YEeX7Kw60"
                : "AIzaSyDUyd2rm-EKfPQLdX7NWxZ--sv-5xz63BM",
    });

    const onLoad = React.useCallback((map: google.maps.Map) => {
        setMap(map);
    }, []);

    const MapElement = (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore

        <GoogleApiWrapper
            mapContainerStyle={mapContainerStyle}
            options={{
                draggable: scrollEnabled,
                disableDefaultUI: true,
                keyboardShortcuts: false,
                center: {
                    lat: currentCoordinates.lat,
                    lng: currentCoordinates.lng,
                },
            }}
            center={{
                lat: currentCoordinates.lat,
                lng: currentCoordinates.lng,
            }}
            zoom={18}
            onLoad={onLoad}
            onDragEnd={handleRegionChange}>
            {mapMarker ? (
                <Marker
                    draggable={markerDraggable}
                    zIndex={11}
                    position={{ lat, lng }}
                    icon={
                        "https://minis-media-assets.swiggy.com/swiggymini/image/upload/static-assets/map_marker"
                    }
                />
            ) : null}
        </GoogleApiWrapper>
    );

    return (
        <View style={[styles.mapContainer, style]}>
            {calloutMarker ? (
                <CalloutMarker
                    imgStyle={StyleSheet.flatten([styles.callout, { left: calloutLeftValue }])}
                    imgId={calloutMarkerId}
                />
            ) : null}
            {isLoaded ? MapElement : null}
            {showsMyLocationButton ? (
                <Pressable
                    onPress={goToCurrentLocation}
                    testID="location-handler"
                    style={StyleSheet.flatten([
                        styles.currentLocationCta,
                        autoLocateAdditionalStyles,
                    ])}>
                    <CdnImage id="minis/current_location_getter" width={60} height={60} />
                </Pressable>
            ) : null}

            <LocationPermissionModal showModal={showModal} setShowModal={setShowModal} />
        </View>
    );
};

const styles = StyleSheet.create({
    mapContainer: {
        ...StyleSheet.absoluteFillObject,
        position: "relative",
        flex: 1,
    },
    callout: {
        zIndex: 11,
        position: "absolute",
        top: "30%",
    },
    currentLocationCta: {
        position: "absolute",
        zIndex: 11,
    },
});

/**
 * The API Key will be secured at a later stage
 */

export default React.memo(MapWebComponent);
