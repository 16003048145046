import React, { Ref, useEffect } from "react";
import {
    Animated,
    Easing,
    InteractionManager,
    LayoutAnimation,
    LayoutAnimationAnim,
    LayoutChangeEvent,
    Platform,
    Pressable,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
} from "react-native";

import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { useMountedRef, useThrottleFn } from "@swiggy-private/react-hooks";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Surface, Text, useAnimatedValue, useTheme } from "@swiggy-private/rn-dls";
import { HapticService } from "@swiggy-private/rn-services";

export const TEST_IDS = {
    accordion_title: "accordion_title",
    accordion_content: "accordion_content",
};

interface IAccordionProps {
    accordionTitle: string;
    children: React.ReactNode;
    accordionLogo?: string;
    triggerOpen?: boolean;
    onPress?: (T: boolean) => void;
    accordionLogoDimension?: { width: number; height: number };
    duration?: number;
    isDefaultStateOpen?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
    isAccordionLogoTransparent?: boolean;
}

type TElementAnimationCateogry = "BOUNCE" | "HEIGHT" | "ARROW";

const DEFAULT_DURATION = 150;

const DEFAULT_LOGO_DIMENSION = { width: 26, height: 26 };
const DEFAULT_NO_BG_LOGO_DIMENSION = { width: 42, height: 42 };

const PADDING_SLOP = 16;

const accordionPressHitslop = {
    top: PADDING_SLOP,
    bottom: PADDING_SLOP,
    left: PADDING_SLOP,
    right: PADDING_SLOP,
};
const useNativeDriver = Platform.OS !== "web";
const isIOS = Platform.OS === "ios";

/**
 * An Animated Accordion component having configurable Logo, Title and trigger Options
 *
 * @param {*} props // {accordionTitle, accordionLogo, triggerOpen, onPress}
 * @return {*} Returns Accordion Component with styles and JSX.element as child
 */
const AccordionComponent: React.FC<IAccordionProps> = (props) => {
    const {
        accordionTitle,
        accordionLogo,
        triggerOpen,
        onPress,
        containerStyle,
        duration = DEFAULT_DURATION,
        accordionLogoDimension = DEFAULT_LOGO_DIMENSION,
        isDefaultStateOpen = false,
        isAccordionLogoTransparent = true,
    } = props;
    const [hadOpened, setOpenState] = React.useState(isDefaultStateOpen);
    const { value: theme } = useTheme();
    const contentRef = React.useRef<View>();
    const [contentBodyHeight, setContentBodyHeight] = React.useState(0);

    const mounted = useMountedRef();
    const innerContentHeight = React.useRef({ height: 0, measured: false });
    const arrowAnimation = useAnimatedValue(Number(isDefaultStateOpen));
    const slideAnimation = useAnimatedValue(Number(isDefaultStateOpen));
    const bounceAnimation = useAnimatedValue(0);

    const toggleAccordion = React.useCallback(
        (toggleProp?: { disableOnPress: boolean }): void => {
            const commonAnimationProps = {
                toValue: hadOpened ? 0 : 1,
                duration: duration,
                easing: Easing.ease,
                useNativeDriver,
            };

            const iOSLayoutDurationFix = isIOS ? duration - 100 : duration;

            const commonLayoutAnimation = {
                type: "easeInEaseOut",
                property: "scaleY",
            } as unknown as LayoutAnimationAnim;

            useNativeDriver &&
                LayoutAnimation.configureNext({
                    duration: duration,
                    create: commonLayoutAnimation,
                    update: { type: "linear", property: "opacity", duration: iOSLayoutDurationFix },
                    delete: commonLayoutAnimation,
                });

            InteractionManager.runAfterInteractions(() => {
                Animated.parallel([
                    Animated.timing(bounceAnimation, {
                        ...commonAnimationProps,
                    }),
                    Animated.timing(arrowAnimation, {
                        ...commonAnimationProps,
                    }),
                    Animated.timing(slideAnimation, {
                        ...commonAnimationProps,
                        duration: 80,
                    }),
                ]).start();
            });

            if (!toggleProp?.disableOnPress) {
                onPress?.(!hadOpened);
                HapticService?.trigger("impactMedium");
            }

            setOpenState(!hadOpened);
        },
        [hadOpened, duration, bounceAnimation, arrowAnimation, slideAnimation, onPress],
    );

    // * Animation interpolation ranges
    const getAnimationRange = React.useCallback(
        (type: TElementAnimationCateogry): unknown => {
            switch (type) {
                case "ARROW":
                    return arrowAnimation.interpolate({
                        inputRange: [0, 1],
                        outputRange: ["0deg", "-180deg"],
                    }) as unknown as string;

                case "HEIGHT":
                    return slideAnimation.interpolate({
                        inputRange: [0, 1],
                        outputRange: useNativeDriver
                            ? [-3, 0]
                            : [
                                  0,
                                  (contentBodyHeight || innerContentHeight.current.height) +
                                      PADDING_SLOP,
                              ],
                    }) as unknown as number;

                case "BOUNCE":
                    const bounceOffset = useNativeDriver ? 0.98 : 0.991;
                    return bounceAnimation.interpolate({
                        inputRange: [0, 0.5, 1],
                        outputRange: [1, !hadOpened ? 1 : bounceOffset, 1],
                    }) as unknown as number;

                default:
                    return null;
            }
        },
        [arrowAnimation, bounceAnimation, contentBodyHeight, hadOpened, slideAnimation],
    );

    const contentOuterBodyStyle: StyleProp<ViewStyle> = React.useMemo(
        () =>
            StyleSheet.flatten([
                styles.container,
                styles.contentOuterBody,
                {
                    elevation: hadOpened ? 4 : 1,
                    transform: [{ scaleX: getAnimationRange("BOUNCE") as number }],
                },
                containerStyle,
            ]),
        [hadOpened, getAnimationRange],
    );

    const contentInnerBodyStyle: StyleProp<ViewStyle> = React.useMemo(() => {
        const heightAnimationStyle = getAnimationRange("HEIGHT") as number;

        const webHeightStyle = {
            height: heightAnimationStyle || "auto",
        };
        const nativeHeightStyle = {
            transform: [{ translateY: heightAnimationStyle }],
            height: hadOpened ? "auto" : 0,
        };

        return {
            padding: hadOpened ? SpacingValue["space-medium"] : 0,
            ...(useNativeDriver ? nativeHeightStyle : webHeightStyle),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hadOpened, contentBodyHeight, getAnimationRange, useNativeDriver]);

    const contentWrapperStyle: ViewStyle = React.useMemo(
        () => ({
            elevation: 0,
            opacity: hadOpened ? 1 : 0,
            transform: [{ translateY: hadOpened ? 0 : 20 }],
        }),
        [hadOpened],
    );

    const arrowStyle: ViewStyle = {
        elevation: 0,
        transform: [{ rotate: getAnimationRange("ARROW") as string }],
    };

    const imageBGStyle: ViewStyle = React.useMemo(
        () => ({
            borderWidth: 1,
            borderColor: theme["color-basic-5"],
            borderRadius: 8,
            padding: SpacingValue["space-x-small"],
        }),
        [theme],
    );

    const setInnerHeight = React.useCallback(
        (height?: number) => {
            if (height === undefined || contentBodyHeight === height) {
                return;
            }

            setContentBodyHeight(height);
            if (!innerContentHeight.current.measured && height > 0) {
                innerContentHeight.current = { height, measured: true };
            }
        },
        [contentBodyHeight],
    );

    // * Calculate body height if anything changes
    const onContentMeaurement = React.useCallback(
        (e?: LayoutChangeEvent) => {
            if (e?.nativeEvent?.layout) {
                const layoutHeight = e?.nativeEvent.layout.height;
                setInnerHeight(layoutHeight);
                return;
            }
            // * Measure the contentBody after it has been rendered
            if (contentRef.current) {
                // eslint-disable-next-line max-params
                contentRef.current.measure((x, y, w, height) => {
                    setInnerHeight(height);
                });
            }
        },
        [setInnerHeight],
    );

    const delayedContentMeasurement = useThrottleFn(onContentMeaurement, { wait: 100 });

    const userTappedAccordion = React.useCallback(() => {
        toggleAccordion();
    }, [toggleAccordion]);

    const userTappedContent = React.useCallback(() => {
        delayedContentMeasurement();
    }, [delayedContentMeasurement]);

    // * logic to trigger the Accordion based on a flag
    useEffect(() => {
        if (
            typeof triggerOpen !== "boolean" &&
            !innerContentHeight.current.measured &&
            !mounted.current
        ) {
            return;
        }

        const triggerAccordion = (): void => toggleAccordion({ disableOnPress: true });

        if ((triggerOpen && !hadOpened) || (!triggerOpen && hadOpened)) {
            triggerAccordion();
        }
    }, [hadOpened, toggleAccordion, triggerOpen, contentBodyHeight, mounted]);

    return (
        <Surface style={contentOuterBodyStyle}>
            <Pressable
                hitSlop={accordionPressHitslop}
                style={styles.pressableArea}
                testID={TEST_IDS.accordion_title}
                onPress={userTappedAccordion}>
                <Stack
                    direction={"row"}
                    spacing={SpacingValue["space-medium"]}
                    justifyContent="space-between"
                    alignItems="center">
                    {accordionLogo ? (
                        <Box style={isAccordionLogoTransparent ? {} : imageBGStyle}>
                            <CdnImage
                                id={accordionLogo}
                                {...(isAccordionLogoTransparent
                                    ? DEFAULT_NO_BG_LOGO_DIMENSION
                                    : accordionLogoDimension)}
                                format={"png"}
                                resizeMode={"contain"}
                                isImageKitEnabled
                            />
                        </Box>
                    ) : null}
                    {accordionTitle ? (
                        <Text category="b1" weight="medium">
                            {accordionTitle}
                        </Text>
                    ) : null}
                </Stack>
                <Surface style={arrowStyle}>
                    <SvgIcon icon={"ChevronDown"} color={"color-basic-60"} />
                </Surface>
            </Pressable>

            <Surface style={[contentInnerBodyStyle, styles.contentInnerBodyStyle]}>
                <Surface
                    testID={TEST_IDS.accordion_content}
                    onTouchEnd={userTappedContent}
                    onLayout={onContentMeaurement}
                    ref={contentRef as Ref<View> | undefined}
                    style={contentWrapperStyle}>
                    {props?.children}
                </Surface>
            </Surface>
        </Surface>
    );
};

export const Accordion = React.memo(AccordionComponent);

const styles = StyleSheet.create({
    container: {
        borderRadius: 16,
    },
    pressableArea: {
        zIndex: 3,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: SpacingValue["space-medium"],
    },
    accordionTitle: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        zIndex: 5,
    },
    contentInnerBodyStyle: {
        paddingTop: 0,
        zIndex: 2,
        elevation: 0,
    },
    contentOuterBody: {
        overflow: "hidden",
        zIndex: 1,
        elevation: 1,
    },
});
