import { ApiResponse, API_SUCCESS_STATUS_CODE } from "./types";

export class ApiError extends Error {
    private _statusMessage: string;
    private _response: ApiResponse<unknown> | null;

    constructor(message: string, apiResponse?: ApiResponse<unknown>) {
        super(message);

        this.name = "ApiError";

        this._response = apiResponse || null;
        this._statusMessage = apiResponse?.statusMessage ?? "";
    }

    get errors(): Record<string, string> | null {
        return this._response?.errors ?? null;
    }

    get errorCode(): number {
        return this._response?.statusCode ?? API_SUCCESS_STATUS_CODE;
    }

    get errorMessage(): string {
        return this._statusMessage;
    }

    get errorTitle(): string {
        if (this.errorCode === 400) {
            return "Bad Request";
        } else if (this.errorCode === 401) {
            return "Unauthorized";
        } else if (this.errorCode === 403) {
            return "Forbidden";
        } else if (this.errorCode === 404) {
            return "There's nothing here";
        } else if (this.errorCode === 429) {
            return "Too many requests";
        }

        return "Ooops!";
    }

    get errorResponse(): ApiResponse<unknown> | null {
        return this._response;
    }

    is4xxError(): boolean {
        return this.errorCode >= 400 && this.errorCode <= 499;
    }

    is5xxError(): boolean {
        return this.errorCode >= 500 && this.errorCode <= 599;
    }
}
