import React from "react";
import { Animated, Easing, InteractionManager, Platform } from "react-native";

type Props = {
    /** Value for driving animations */
    animatedValue: Animated.Value;
    /** Value at which animation will be complete */
    toValue: number;
    /** Milliseconds to complete one loop of the animation */
    duration: number;

    /** The number of iterations after which animation should stop. Default value is set to -1 (infinite loop) */
    iterations?: number;
};

export const useAnimationLoop = (): ((props: Props) => void) => {
    const taskRef = React.useRef<ReturnType<typeof InteractionManager.runAfterInteractions> | null>(
        null,
    );

    return React.useCallback(({ animatedValue, toValue, duration, iterations = -1 }: Props) => {
        taskRef.current?.cancel();

        taskRef.current = InteractionManager.runAfterInteractions(() => {
            Animated.loop(
                Animated.timing(animatedValue, {
                    toValue,
                    duration,
                    delay: 0,
                    easing: Easing.linear,
                    useNativeDriver: Platform.OS !== "web",
                    isInteraction: false,
                }),
                { iterations },
            ).start();
        });
    }, []);
};
