import { Event } from "../interfaces/event";

type RetryEvent = Event & { retryCount?: number };

let _eventQueue: Event[] = [];
let _eventRetryQueue: RetryEvent[] = [];

const MAX_QUEUE_EVENTS = 256;
const MAX_RETRY_EVENTS = 128;

export const EventQueue = {
    push: (...events: Event[]): void => {
        if (_eventQueue.length > MAX_QUEUE_EVENTS) {
            _eventQueue.splice(0, _eventQueue.length - MAX_QUEUE_EVENTS);
        }

        _eventQueue.push(...events);
    },
    retry: (...events: RetryEvent[]): void => {
        if (_eventRetryQueue.length > MAX_RETRY_EVENTS) {
            _eventRetryQueue.splice(0, _eventRetryQueue.length - MAX_QUEUE_EVENTS);
        }

        _eventRetryQueue.push(
            ...events
                .filter((e) => (e.retryCount ?? 0) < 3)
                .map((e) => ({ ...e, retryCount: (e.retryCount ?? 0) + 1 })),
        );
    },
    pop: <T extends Event>(count = 1): T[] => {
        if (_eventRetryQueue.length) {
            return _eventRetryQueue.splice(0, count) as T[];
        }

        return _eventQueue.splice(0, count) as T[];
    },
    clear: () => {
        _eventQueue = [];
        _eventRetryQueue = [];
    },
};
