import { isBrowser } from "../helpers/browser";
import SharePackage from "../optional-dependencies/share-package";

import type { ShareAPI, ShareData } from "../interfaces/share";

export const ShareService: ShareAPI = {
    canShare: function (data?: ShareData): boolean {
        if (SharePackage == null) {
            return false;
        }

        if (!isBrowser()) {
            return true;
        }

        if ("navigator" in global && typeof navigator.canShare === "function") {
            return navigator.canShare(data);
        }

        return false;
    },

    share: function (data: ShareData): Promise<void> {
        if (SharePackage == null) {
            return Promise.reject(new Error("Share is not supported in this platform"));
        }

        return SharePackage.share(data);
    },
};
