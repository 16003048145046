import React, { useCallback, useState } from "react";
import { Pressable, StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue } from "@swiggy-private/rn-dls";

import { DEFAULT_VALUES, TEST_IDS } from "./constants";

interface StarRatingProps {
    ratingCount?: number;
    totalStars?: number;
    iconSize?: number;
    showFilled?: boolean;

    isDisabled?: boolean;
    onPress?: (rating: number) => void;

    style?: StyleProp<ViewStyle>;
    iconProps?: Partial<SvgIconType>;
    starColor?: SvgIconType["color"];
    starFillColor?: SvgIconType["color"];

    selectedStarIcon?: SvgIconType["icon"];
    unselectedStarIcon?: SvgIconType["icon"];
    starIconStyle?: StyleProp<ViewStyle>;
}

const StarRatingComponent: React.FC<StarRatingProps> = ({
    ratingCount = DEFAULT_VALUES.RATING_COUNT,
    totalStars = DEFAULT_VALUES.TOTAL_STARS,
    iconSize = DEFAULT_VALUES.ICON_SIZE,
    showFilled = false,
    isDisabled,
    onPress,
    style,
    starIconStyle,
    iconProps,
    starColor = DEFAULT_VALUES.STAR_ICON_COLOR,
    starFillColor = DEFAULT_VALUES.STAR_ICON_FILL_COLOR,
    selectedStarIcon = DEFAULT_VALUES.SELECTED_STAR_ICON,
    unselectedStarIcon = DEFAULT_VALUES.UNSELECTED_STAR_ICON,
}) => {
    const [rating, setRating] = useState(ratingCount);

    const handleStarPress = useCallback(
        (starIndex: number) => {
            setRating(starIndex + 1);
            onPress?.(starIndex + 1);
        },

        [onPress],
    );

    return (
        <Stack direction="row" justifyContent="center" style={style} testID={TEST_IDS.CONTAINER}>
            {Array.from({ length: totalStars }).map((_, index) => (
                <Pressable
                    key={index}
                    onPress={() => handleStarPress(index)}
                    testID={`star-${index}`}
                    disabled={isDisabled}>
                    <SvgIcon
                        icon={showFilled && index < rating ? selectedStarIcon : unselectedStarIcon}
                        color={showFilled && index < rating ? starFillColor : starColor}
                        height={iconSize}
                        width={iconSize}
                        style={[styles.icon, starIconStyle]}
                        {...iconProps}
                    />
                </Pressable>
            ))}
        </Stack>
    );
};

export const StarRating = React.memo(StarRatingComponent);

const styles = StyleSheet.create({
    icon: {
        margin: SpacingValue["space-small"] / 2,
    },
});
