import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMountedRef } from "@swiggy-private/react-hooks";
import bigInt from "big-integer";

import { useChatDispatch } from "./use-chatdispatch";
import { useChatState } from "./use-chatstate";
import { Message, MessageSection } from "../interfaces/types";
import { useChatSdk } from "./use-chatsdk";
import { useChatMessageAction } from "./use-chat-message-action";
import { useChatService } from "./use-chat-service";
import { logError } from "../helpers/log";
import { useChatUserId } from "./use-chatuserid";
import { hasMessageAction } from "../helpers";

type UseGetMessagesReturnValue = [
    MessageSection[],
    {
        loading: boolean;
        fetch: (next?: boolean) => Promise<void>;
        error?: Error | null;
    },
];

export const useGetMessages = (conversationId: string, limit = 15): UseGetMessagesReturnValue => {
    const mountedRef = useMountedRef();

    const chatSdk = useChatSdk();
    const state = useChatState();
    const dispatch = useChatDispatch();
    const addMessageAction = useChatMessageAction();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const cancelRef = useRef<AbortController>();
    const chatService = useChatService();
    const chatUuid = useChatUserId();
    const initRef = useRef(false);

    const lastTimeTokenRef = useRef<string | null>();
    const callInProgress = useRef(false);
    const nextCallTimer = useRef<number>(0);

    const messageList = useMemo(() => {
        const { messageSectionSortedList = [], messageSections = {} } =
            state.messageBlocks[conversationId] ?? {};
        return messageSectionSortedList.map((id) => messageSections[id]).filter(Boolean);
    }, [conversationId, state.messageBlocks]);

    const onSuccess = useCallback(
        async (messages: Message[]) => {
            setLoading(false);
            setError(null);

            if (!messages.length) {
                return;
            }

            dispatch({
                type: "ADD_CONVERSATION_MESSAGE_ACTION",
                payload: {
                    conversationId,
                    messages,
                },
            });

            for await (const message of messages) {
                addMessageAction({
                    conversationId,
                    message,
                    type: "ack",
                });

                const isUnreadMessage =
                    message.publisher === chatUuid || chatUuid == null
                        ? false
                        : hasMessageAction(message.actions ?? {}, "read", chatUuid) === false;

                await chatService
                    ?.putMessage({
                        message,
                        conversationId: conversationId,
                        unread: isUnreadMessage,
                        publisher: message.publisher,
                        timetoken: String(message.timetoken),
                    })
                    .catch(logError);
            }
        },
        [addMessageAction, chatService, chatUuid, conversationId, dispatch],
    );

    const getMessagesFromDb = useCallback(async (): Promise<void> => {
        const messages = await chatService?.findMessages({
            conversationIds: [conversationId],
            timestamp: Date.now(),
            limit,
        });

        if (!Array.isArray(messages) || !messages.length || !mountedRef.current) {
            return;
        }

        dispatch({
            type: "ADD_CONVERSATION_MESSAGE_ACTION",
            payload: {
                conversationId,
                messages,
            },
        });
    }, [chatService, conversationId, dispatch, limit, mountedRef]);

    const getMessagesFromNetwork = useCallback(
        async (signal: AbortSignal): Promise<void> => {
            initRef.current = true;

            try {
                const startTimetoken = lastTimeTokenRef.current
                    ? lastTimeTokenRef.current
                    : bigInt(Date.now()).add(86400).multiply(10_000).toString();

                const response = await chatSdk?.fetchMessages({
                    conversationIds: [conversationId],
                    startTimetoken,
                    count: limit,
                });

                if (!mountedRef.current || signal.aborted) {
                    return;
                }

                const messages = (response?.conversations ?? {})[conversationId];
                if (!Array.isArray(messages) || !messages.length) {
                    onSuccess([]);
                    return;
                }

                lastTimeTokenRef.current = String(messages[0].timetoken);
                const newMessages: Message[] = messages.map((c) => ({
                    ...c.message,
                    publisher: c.publisher,
                    timetoken: c.timetoken,
                    actions: c.actions,
                }));

                await onSuccess(newMessages);
            } catch (err) {
                if (mountedRef.current && !signal.aborted) {
                    setLoading(false);
                    setError(err as Error);
                }
            }
        },
        [chatSdk, conversationId, limit, mountedRef, onSuccess],
    );

    const fetchMessages = useCallback(
        async (next?: boolean) => {
            if (callInProgress.current) {
                clearTimeout(nextCallTimer.current);
                nextCallTimer.current = setTimeout(
                    () => fetchMessages(next),
                    1_000,
                ) as unknown as number;
                return;
            }

            if (!mountedRef.current) {
                return;
            }

            cancelRef.current?.abort();
            cancelRef.current = new AbortController();

            setLoading(true);
            setError(null);

            callInProgress.current = true;

            if (!next) {
                lastTimeTokenRef.current = null;
                await getMessagesFromDb().catch(logError);
            }

            await getMessagesFromNetwork(cancelRef.current.signal).catch(logError);

            callInProgress.current = false;
        },
        [getMessagesFromDb, getMessagesFromNetwork, mountedRef],
    );

    useEffect(() => {
        if (chatSdk && !initRef.current) {
            fetchMessages();
        }
    }, [chatSdk, fetchMessages]);

    return [messageList, { loading, fetch: fetchMessages, error }];
};
