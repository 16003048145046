import { useEffect, useRef } from "react";
import { AppState, AppStateStatus } from "react-native";

export const useAppStateListener = (callback: (visible: boolean) => void): void => {
    const appState = useRef(AppState.currentState);

    useEffect(() => {
        const fn = (nextAppState: AppStateStatus): void => {
            appState.current = nextAppState;
            callback(appState.current === "active");
        };

        const appStateSubscription = AppState.addEventListener("change", fn);

        return () => {
            if (appStateSubscription == null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                AppState.removeEventListener("change", fn);
            } else {
                appStateSubscription.remove();
            }
        };
    }, [callback]);
};
