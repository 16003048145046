import React, { useCallback } from "react";
import { StyleSheet, TextStyle, View, ViewStyle, StyleProp } from "react-native";
import memoizeOne from "memoize-one";

import { useDLS } from "../../styles/style-service";
import { useEventHandlers } from "../../support/use-event-handlers";
import { FalsyText } from "../../support/falsy-text";
import { TouchableComponent, TouchableComponentProps } from "../../support/touchable-component";

import type { RenderProp } from "../../support/falsy-fc";
import type { CheckBoxColor, CheckBoxStyle } from "../../styles/interfaces/check-box";
import type { TextProps } from "../text";

import { SvgIcon } from "../../support/svg-icon";
import Tick from "../../../assets/svgs/tick.svg";

export interface CheckBoxProps extends Omit<TouchableComponentProps, "children"> {
    onChange?: (checked: boolean) => void;
    checked?: boolean;
    color?: CheckBoxColor;
    children?: RenderProp<TextProps> | React.ReactText;
    styleContainer?: StyleProp<ViewStyle>;
    styleBoxContainer?: StyleProp<ViewStyle>;
    styleText?: StyleProp<TextStyle>;
}

export type CheckBoxElement = React.ReactElement<CheckBoxProps>;

const getComponentStyle = memoizeOne(
    (
        source: CheckBoxStyle,
    ): {
        text: TextStyle;
        icon: ViewStyle;
        selectContainer: ViewStyle;
        mainContainer: ViewStyle;
    } => {
        const {
            textColor,
            textFontFamily,
            textFontSize,
            textLineHeight,
            textMarginHorizontal,
            letterSpacing,
            iconBorderRadius,
            iconHeight,
            iconTintColor,
            iconWidth,
            height,
            ...others
        } = source;

        return {
            text: {
                letterSpacing,
                color: textColor,
                fontFamily: textFontFamily,
                fontSize: textFontSize,
                marginLeft: textMarginHorizontal,
                lineHeight: textLineHeight,
            },
            icon: {
                borderRadius: iconBorderRadius,
                height: iconHeight,
                width: iconWidth,
                backgroundColor: iconTintColor,
            },
            selectContainer: {
                height,
                ...others,
            },
            mainContainer: {
                height: height + 8,
            },
        };
    },
);

/**
 * Checkboxes allow the selection of multiple options from a set.
 *
 * ## Usage
 * ```js
 * import * as React from "react";
 * import { Checkbox } from "@swiggy-private/rn-dls";
 *
 * const MyComponent = () => {
 *   const [checked, setChecked] = React.useState(false);
 *
 *   return (
 *     <Checkbox
 *       checked={checked}
 *       onPress={() => {
 *         setChecked(!checked);
 *       }}
 *     />
 *   );
 * };
 * ```
 *
 * @param props {@link CheckBoxProps}
 */
export const CheckBox: React.FC<CheckBoxProps> = (props) => {
    const {
        checked = false,
        children,
        color,
        onChange,
        styleContainer,
        styleBoxContainer,
        styleText,
        ...otherProps
    } = props;

    const dls = useDLS("checkBox", { checked, color });
    const dlsStyle = getComponentStyle(dls.style);
    const eventHandlers = useEventHandlers(props, dls.dispatch, props.disabled);
    const onPress = useCallback((): void => onChange?.(!checked), [onChange, checked]);

    return (
        <View
            style={StyleSheet.compose([styles.container, dlsStyle.mainContainer], styleContainer)}>
            <TouchableComponent
                accessibilityRole="checkbox"
                {...otherProps}
                {...eventHandlers}
                onPress={onPress}
                style={StyleSheet.compose(
                    [dlsStyle.selectContainer, styles.selectContainer],
                    styleBoxContainer,
                )}>
                <SvgIcon icon={Tick} />
            </TouchableComponent>
            <FalsyText style={StyleSheet.compose(dlsStyle.text, styleText)} component={children} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    selectContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
});
