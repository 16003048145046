import { useCallback, useEffect, useRef } from "react";
import { useMountedRef } from "@swiggy-private/react-hooks";
import { AppState } from "react-native";

import { useChatSdk } from "./use-chatsdk";
import { useChatDispatch } from "./use-chatdispatch";
import { useChatUserId } from "./use-chatuserid";
import { ChatAction } from "../reducers/chat";
import { useChatState } from "./use-chatstate";

const lastFetchConversationTime: Record<string, number> = {};
const MIN_FETCH_INTERVAL_TIME = 20_000;

export const useGetUserOnlineStatus = (): ((
    conversationIds: string | string[],
) => Promise<void>) => {
    const mountedRef = useMountedRef();

    const sdk = useChatSdk();
    const dispatch = useChatDispatch();
    const userId = useChatUserId();
    const conversationUserIdMap = useRef<Record<string, string>>({});
    const chatState = useChatState();

    useEffect(() => {
        if (userId) {
            Object.values(chatState.conversations).forEach((conversation) => {
                if (conversationUserIdMap.current[conversation.id] == null) {
                    conversationUserIdMap.current[conversation.id] =
                        conversation.participants.filter((u) => u.id !== userId)[0]?.id || "";
                }
            });
        }
    }, [chatState.conversations, userId]);

    const fn = useCallback(
        async (conversationIds: string | string[]): Promise<void> => {
            const time = Date.now();
            const _conversationIds = (
                Array.isArray(conversationIds) ? conversationIds : [conversationIds]
            )
                .filter(
                    (id) => (lastFetchConversationTime[id] || 0) + MIN_FETCH_INTERVAL_TIME < time,
                )
                .slice(0, 20);

            if (!_conversationIds.length || AppState.currentState !== "active") {
                return;
            }

            _conversationIds.forEach((id) => {
                lastFetchConversationTime[id] = time;
            });

            const uuids = _conversationIds
                .map((id) => conversationUserIdMap.current[id])
                .filter(Boolean);

            await sdk
                ?.getOnlineUsers({
                    uuids,
                })
                .then((response) => {
                    if (!mountedRef.current || !response || AppState.currentState !== "active") {
                        return;
                    }

                    const actions: ChatAction[] = [];
                    const curTime = Date.now();

                    Object.keys(response).forEach((uuId) => {
                        if (uuId !== userId && response[uuId]) {
                            const isOnline =
                                response[uuId].online &&
                                curTime - (response[uuId].time || 0) < 60_000;

                            actions.push({
                                type: "SET_USER_ONLINE_ACTION",
                                payload: {
                                    userId: uuId,
                                    online: isOnline,
                                    lastSeenAt: response[uuId].time,
                                },
                            });
                        }
                    });

                    if (actions.length > 0) {
                        dispatch({
                            type: "MULTI_DISPATCH",
                            payload: actions,
                        });
                    }
                });
        },
        [dispatch, mountedRef, sdk, userId],
    );

    return fn;
};
