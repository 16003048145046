import React, { FC, memo } from "react";
import { Pressable } from "react-native";

import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { useMount } from "@swiggy-private/react-hooks";

interface BlockedInputProps {
    isCurrentUserBlocker: boolean;
    onUnblockPress: VoidFunction;

    onMountCallback?: VoidFunction;
    blockedText?: string;
    unblockText?: string;
    chatUnavailableText?: string;
}

const BlockedInputComponent: FC<BlockedInputProps> = ({
    isCurrentUserBlocker = false,
    onUnblockPress,
    onMountCallback,
    blockedText,
    unblockText,
    chatUnavailableText,
}) => {
    useMount(() => {
        onMountCallback?.();
    });

    return isCurrentUserBlocker ? (
        <Stack justifyContent="center" alignItems="center" flex={1}>
            <Box mb={SpacingValue["space-xx-small"]}>
                <Text category="b1" weight="bold" color="medium">
                    {blockedText}
                </Text>
            </Box>

            <Pressable onPress={onUnblockPress}>
                <Text category="btn4" weight="bold" color="primary">
                    {unblockText}
                </Text>
            </Pressable>
        </Stack>
    ) : (
        <Stack justifyContent="center" alignItems="center" flex={1}>
            <Text category="b2" color="medium">
                {chatUnavailableText}
            </Text>
        </Stack>
    );
};

export const BlockedInput = memo(BlockedInputComponent);
