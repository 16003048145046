import React, { useCallback, useMemo, useRef } from "react";
import { StyleSheet, View, ViewStyle, TextStyle, Animated, Easing } from "react-native";
import memoizeOne from "memoize-one";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";
import { HapticService } from "@swiggy-private/rn-services";

import { useDLS } from "../../styles/style-service";
import { useEventHandlers } from "../../support/use-event-handlers";
import { FalsyText } from "../../support/falsy-text";
import { TouchableComponent, TouchableComponentProps } from "../../support/touchable-component";
import { useAnimatedValue } from "../../hooks/use-animated-value";

import type { RenderProp } from "../../support/falsy-fc";
import type { ToggleStyle, ToggleColor } from "../../styles/interfaces/toggle";
import type { TextProps } from "../text";

export interface ToggleProps extends Omit<TouchableComponentProps, "children"> {
    /**
     * Disable toggling the switch.
     */
    disabled?: boolean;
    /**
     * Callback called with the new value when it changes.
     */
    onChange?: (checked: boolean) => void;
    /**
     * true means 'on', false means 'off'.
     */
    checked?: boolean;
    /**
     * The background color of the toggle.
     */
    color?: ToggleColor;
    ellipseStyle?: ViewStyle;
    /**
     * Duration of the animation.
     */
    duration?: number;
    children?: RenderProp<TextProps> | React.ReactText;
    extraThumbStyle?: ViewStyle;

    activeHelperText?: RenderProp<TextProps> | React.ReactText;
    disabledHelperText?: RenderProp<TextProps> | React.ReactText;
}

export type ToggleElement = React.ReactElement<ToggleProps>;

const getComponentStyle = memoizeOne(
    (
        source: ToggleStyle,
        checked: boolean,
    ): {
        text: TextStyle;
        ellipseContainer: ViewStyle;
        thumb: ViewStyle;
    } => {
        const {
            textColor,
            textFontFamily,
            textFontSize,
            textLetterSpacing,
            textLineHeight,
            textMarginHorizontal,
            thumbBackgroundColor,
            thumbHeight,
            thumbWidth,
            thumbBorderRadius,
            ...containerProps
        } = source;

        return {
            ellipseContainer: containerProps,
            thumb: {
                alignSelf: checked ? "flex-end" : "flex-start",
                backgroundColor: thumbBackgroundColor,
                height: thumbHeight,
                width: thumbWidth,
                borderRadius: thumbBorderRadius,
            },
            text: {
                color: textColor,
                fontFamily: textFontFamily,
                fontSize: textFontSize,
                letterSpacing: textLetterSpacing,
                lineHeight: textLineHeight,
                marginHorizontal: 2,
            },
        };
    },
);

/**
 * Toggle is a visual toggle between two mutually exclusive states — on and off.
 *
 * ## Usage
 * ```js
 * import * as React from "react";
 * import { Toggle } from "@swiggy-private/rn-dls";
 *
 * const MyComponent = () => {
 *   const [isSwitchOn, setIsSwitchOn] = React.useState(false);
 *   const onToggleSwitch = () => setIsSwitchOn(!isSwitchOn);
 *
 *   return <Toggle checked={isSwitchOn} onChange={onToggleSwitch} />;
 * };
 *```
 * @param props {@link ToggleProps}
 */
export const Toggle: React.FC<ToggleProps> = (props) => {
    const {
        checked = false,
        children,
        onChange,
        testID,
        style,
        color,
        ellipseStyle,
        duration = 100,
        extraThumbStyle,
        activeHelperText,
        disabledHelperText,
        ...toggleProps
    } = props;

    const { theme, ...dls } = useDLS("toggle", { checked, color });
    const dlsStyle = useMemo(
        () => getComponentStyle(dls.style, checked === true),
        [dls.style, checked],
    );

    const eventHandlers = useEventHandlers(toggleProps, dls.dispatch, toggleProps.disabled);
    const thumbAnimatingRef = useRef<Animated.CompositeAnimation>();
    const thumbTranslateAnimationValue = useAnimatedValue(0);

    const thumbAnimatedStyle = {
        transform: [{ translateX: thumbTranslateAnimationValue as unknown as number }],
    };

    const thumbStyle = StyleSheet.flatten<ViewStyle>([
        dlsStyle.thumb,
        styles.thumb,
        theme.value.elevations[4],
        thumbAnimatedStyle,
        extraThumbStyle,
    ]);

    const hasTextAccessory = activeHelperText || disabledHelperText;

    const _ellipseStyle = StyleSheet.flatten<ViewStyle>([
        dlsStyle.ellipseContainer,
        styles.ellipseContainer,
        theme.value.elevations[1],
        ellipseStyle,
        hasTextAccessory ? styles.ellipseContainerWithText : null,
    ]);

    const ellipseWidth = _ellipseStyle?.width ?? 0;
    const thumbWidth = thumbStyle?.width ?? 0;

    const toggle = useCallback(() => {
        thumbAnimatingRef.current?.stop();
        thumbTranslateAnimationValue.setValue(0);

        const maxTranslate: number = Number(ellipseWidth) - Number(thumbWidth) - 8;
        thumbAnimatingRef.current = Animated.timing(thumbTranslateAnimationValue, {
            toValue: !checked ? maxTranslate : -maxTranslate,
            duration,
            easing: Easing.linear,
            useNativeDriver: true,
        });

        HapticService.trigger("impactMedium");
        thumbAnimatingRef.current.start(({ finished }) => {
            thumbAnimatingRef.current = undefined;
            if (finished) {
                thumbTranslateAnimationValue.setValue(0);
                onChange && onChange(!checked);
            }
        });
    }, [checked, duration, ellipseWidth, onChange, thumbTranslateAnimationValue, thumbWidth]);

    return (
        <View testID={testID} style={StyleSheet.compose(styles.container, style)}>
            <TouchableComponent
                accessibilityRole="switch"
                {...toggleProps}
                {...eventHandlers}
                onPress={toggle}
                style={styles.toggleContainer}>
                <Animated.View style={_ellipseStyle}>
                    {checked ? (
                        <FalsyText style={dlsStyle.text} component={activeHelperText} />
                    ) : null}
                    <Animated.View style={thumbStyle} />
                    {!checked ? (
                        <FalsyText style={dlsStyle.text} component={disabledHelperText} />
                    ) : null}
                </Animated.View>
            </TouchableComponent>
            <FalsyText style={dlsStyle.text} component={children} />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    toggleContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
    ellipseContainer: {
        justifyContent: "center",
        alignSelf: "center",
        overflow: "hidden",
        paddingHorizontal: SpacingValue["space-xxx-small"],
    },
    ellipseContainerWithText: {
        overflow: "hidden",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    thumb: {
        justifyContent: "center",
        overflow: "hidden",
    },
});
