export const logError = (message?: unknown, ...optionalParams: unknown[]): void => {
    __DEV__ && console.info(message, ...optionalParams);
};

export const logInfo = (message?: unknown, ...optionalParams: unknown[]): void => {
    __DEV__ && console.info(message, ...optionalParams);
};

export const logDebug = (message?: unknown, ...optionalParams: unknown[]): void => {
    __DEV__ && console.debug(message, ...optionalParams);
};
