import * as React from "react";
import { StyleSheet, View, ViewProps } from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";

export interface TabPanelProps extends ViewProps {
    hidden?: boolean;
}

export const TabPanel = React.forwardRef<View, TabPanelProps>((props, ref) => {
    const { hidden = true, style, ...restProps } = props;
    const mounted = React.useRef(false);

    if (!hidden && !mounted.current) {
        mounted.current = true;
    }

    return mounted.current ? (
        <View ref={ref} style={[hidden ? styles.hidden : styles.container, style]} {...restProps} />
    ) : null;
});

if (process.env.NODE_ENV !== "production") {
    TabPanel.displayName = "TabPanel";
}

const styles = StyleSheet.create({
    hidden: { height: 0, width: 0, opacity: 0, overflow: "hidden" },
    container: { flex: 1, paddingTop: SpacingValue["space-medium"] },
});
