import React, { memo } from "react";
import { Pressable, StyleSheet } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";

interface IndicatorProps {
    imageSize: number;
    totalImages: number;
    currentSlideIndex: number;

    scrollToNextSlide?: VoidFunction;
    scrollToPreviousSlide?: VoidFunction;
}

const SIZE = 20;

const SlideArrowsComponent: React.FC<IndicatorProps> = ({
    totalImages,
    currentSlideIndex,
    scrollToNextSlide,
    scrollToPreviousSlide,
    imageSize,
}) => {
    const { value: theme } = useTheme();

    const containerBg = {
        backgroundColor: theme["color-basic-alpha-75"],
    };

    const arrowContainerTop = {
        top: imageSize / 2 - SpacingValue["space-medium"],
    };

    return (
        <>
            {currentSlideIndex !== 0 ? (
                <Pressable
                    style={[styles.leftContainer, styles.container, containerBg, arrowContainerTop]}
                    onPress={scrollToPreviousSlide}>
                    <SvgIcon icon="ChevronLeft" height={SIZE} width={SIZE} color="color-basic-0" />
                </Pressable>
            ) : null}

            {currentSlideIndex !== totalImages - 1 ? (
                <Pressable
                    style={[
                        styles.rightContainer,
                        styles.container,
                        containerBg,
                        arrowContainerTop,
                    ]}
                    onPress={scrollToNextSlide}>
                    <SvgIcon icon="ChevronRight" height={SIZE} width={SIZE} color="color-basic-0" />
                </Pressable>
            ) : null}
        </>
    );
};

export const SlideArrows = memo(SlideArrowsComponent);

const styles = StyleSheet.create({
    container: {
        zIndex: 2,
        width: 24,
        height: 24,
        position: "absolute",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    leftContainer: {
        left: 4,
        borderRadius: 16,
    },
    rightContainer: {
        right: 4,
        borderRadius: 16,
    },
});
