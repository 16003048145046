import { ClipboardAPI } from "../interfaces/clipboard";
import ClipboardPackage from "../optional-dependencies/clipboard-package";

export const ClipboardService: ClipboardAPI = {
    isAvailable: function (): boolean {
        return ClipboardPackage != null;
    },

    getString: function (): Promise<string> {
        return ClipboardPackage?.getString() ?? Promise.resolve("");
    },

    setString: function (content: string): void {
        return ClipboardPackage?.setString(content);
    },
};
