import { Theme } from "@swiggy-private/rn-dls-theme";
import memoizeOne from "memoize-one";

import {
    RadioButtonColor,
    RadioButtonColorStyle,
    RadioButtonSizeStyle,
    RadioButtonState,
    RadioButtonStyle,
    RadioButtonStyleMeta,
} from "../interfaces/radio-button";

type RadioButtonColorWithStateStyle = Record<
    RadioButtonColor,
    {
        state: Partial<Record<RadioButtonState, Partial<RadioButtonColorStyle>>>;
    } & RadioButtonColorStyle
>;

const sizeStyle = memoizeOne(
    (theme: Theme): RadioButtonSizeStyle => ({
        width: 16,
        height: 16,
        borderRadius: 8,
        iconWidth: 8,
        iconHeight: 8,
        iconBorderRadius: 4,
        borderWidth: 1,
        textFontFamily: theme["text-body-1-medium-font-family"],
        textFontSize: theme["text-body-1-medium-font-size"],
        textLineHeight: theme["text-body-1-medium-line-height"],
        letterSpacing: theme["text-body-1-medium-letter-spacing"],
        textMarginHorizontal: theme["space-medium"],
    }),
);

const defaultColorStyle = (theme: Theme): RadioButtonColorStyle => ({
    borderColor: theme["color-basic-30"],
    backgroundColor: "transparent",
    iconTintColor: "transparent",
    textColor: theme["color-basic-100"],
});

const colorStyleMap = memoizeOne(
    (theme: Theme): RadioButtonColorWithStateStyle => ({
        primary: {
            ...defaultColorStyle(theme),
            state: {
                checked: {
                    iconTintColor: theme["color-primary"],
                    borderColor: theme["color-primary"],
                },
            },
        },
        secondary: {
            ...defaultColorStyle(theme),
            state: {
                checked: {
                    iconTintColor: theme["color-primary-light"],
                    borderColor: theme["color-primary-light"],
                },
            },
        },
    }),
);

export const getRadioButtonStyles = (
    meta: RadioButtonStyleMeta,
    theme: Theme,
): RadioButtonStyle => {
    const color = meta.color ?? "primary";
    const state = meta.checked ? "checked" : Array.isArray(meta.state) ? meta.state[0] : meta.state;
    const { state: stateStyle, ...colorStyle } = colorStyleMap(theme)[color];

    return {
        ...sizeStyle(theme),
        ...colorStyle,
        ...(state ? stateStyle[state] ?? {} : {}),
    };
};
