import { Dispatch, useContext } from "react";
import { ChatDispatchContext } from "../contexts/chat-state";
import { ChatAction } from "../reducers/chat";

export function useChatDispatch(): Dispatch<ChatAction> {
    const ctx = useContext(ChatDispatchContext);
    if (!ctx) {
        throw new Error("Missing chat dispatch context");
    }

    return ctx;
}
