import React from "react";
import { Pressable, StyleSheet } from "react-native";

import { Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

import { CouponCardProps } from "../types";
import { APPLY_COUPON } from "../constants";

export const ApplyCouponCTA: React.FC<CouponCardProps> = (props) => {
    const { value: theme } = useTheme();

    if (!props.activateCoupon) {
        return null;
    }

    const {
        onActivate,
        text = APPLY_COUPON,
        textProps,
        style: activateBtnStyle,
    } = props.activateCoupon;

    const style = { borderColor: theme["color-primary"] };

    return (
        <Pressable onPress={onActivate} style={[styles.applyCoupon, style, activateBtnStyle]}>
            <Box direction="row" justifyContent="center" alignItems="center">
                <Text category="b2" color="color-primary" weight="bold" {...textProps}>
                    {text}
                </Text>
            </Box>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    applyCoupon: {
        paddingVertical: SpacingValue["space-x-small"],
        paddingHorizontal: SpacingValue["space-medium"],
        borderRadius: 24,
        borderWidth: 1,
        marginTop: SpacingValue["space-x-small"],
        alignSelf: "flex-start",
    },
});
