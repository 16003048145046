import React from "react";
import { StyleProp, StyleSheet, ViewStyle } from "react-native";

import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";

export const CouponLoader: React.FC<{ style?: StyleProp<ViewStyle> }> = ({ style }) => {
    const { value: theme } = useTheme();

    return (
        <Box
            direction="row"
            style={[
                styles.card,
                {
                    backgroundColor: theme["color-background-primary"],
                },
                style,
            ]}>
            <Box
                flex={1}
                style={[
                    styles.left,
                    {
                        borderColor: theme["color-basic-15"],
                        backgroundColor: theme["color-background-primary"],
                    },
                ]}
            />
            <Box
                direction="row"
                style={[styles.contentRight, { backgroundColor: theme["color-basic-15"] }]}
                justifyContent="flex-end">
                <Box>
                    {[1, 2, 3].map((e) => {
                        return (
                            <Box
                                key={e}
                                style={[
                                    styles.pattern,
                                    { backgroundColor: theme["color-background-secondary"] },
                                    // eslint-disable-next-line react-native/no-inline-styles
                                    { marginBottom: e === 3 ? 0 : SpacingValue["space-x-small"] },
                                ]}
                            />
                        );
                    })}
                </Box>
            </Box>
        </Box>
    );
};

const styles = StyleSheet.create({
    card: {
        borderRadius: 16,
        height: 140,
    },
    left: { borderTopLeftRadius: 16, borderBottomLeftRadius: 16, borderWidth: 1 },
    contentRight: {
        width: 108,
        borderTopRightRadius: 16,
        borderBottomRightRadius: 16,
        alignItems: "center",
    },
    pattern: {
        width: 12,
        height: 12,
        borderRadius: 6,
        left: 6,
        marginBottom: SpacingValue["space-x-small"],
        overflow: "hidden",
    },
});
