import { SdkUserDetails } from "@swiggy-private/connect-chat-sdk";
import { useEffect } from "react";

import { useChatUserId } from "./use-chatuserid";
import { useChatSdk } from "./use-chatsdk";

export const useSetChatUserDetails = ({ name, avatarUrl }: SdkUserDetails): void => {
    const sdk = useChatSdk();
    const userId = useChatUserId();

    useEffect(() => {
        if (!sdk || !userId || !name) {
            return;
        }

        sdk.setUserDetails({
            name,
            avatarUrl,
        });
    }, [avatarUrl, name, sdk, userId]);
};
