import * as React from "react";
import { ColorValue, Platform, Pressable, StyleSheet, useWindowDimensions } from "react-native";

import { CouponCard, CodeProp } from "@swiggy-private/react-native-ui";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { ICouponWithTextMessage } from "@swiggy-private/connect-chat-commons";
import { ScreenSize, Stack, useScreenSize } from "@swiggy-private/rn-adaptive-layout";

import { getCouponData } from "../../helpers/coupon";
import { CONSTANTS, textProps } from "../constants/coupon-with-text-message";

interface CouponWithTextMessageProps {
    message: ICouponWithTextMessage;

    conversationMeta?: SdkConversation["meta"];
    onPress?: VoidFunction;
    couponBgColor?: ColorValue;
}

const CouponWithTextMessageComponent: React.FC<CouponWithTextMessageProps> = ({
    message: { payload: data },
    onPress,
    couponBgColor,
}) => {
    const { value: theme } = useTheme();
    const screenSize = useScreenSize();
    const { width: windowWidth } = useWindowDimensions();

    const isDWeb = Platform.OS === "web" && screenSize === ScreenSize.Large;
    const msgWidth = isDWeb ? CONSTANTS.MIN_MSG_WIDTH : windowWidth * 0.7;

    const { couponData: coupon, brandColor, brandImageId, city, text } = data;
    const couponData = getCouponData(coupon, city);

    const msgStyle = {
        container: [styles.container, { width: msgWidth }],
        code: [
            styles.couponCodeContainer,
            {
                borderColor: coupon?.active ? brandColor : theme["color-basic-45"],
            },
        ],
        card: [
            styles.card,
            {
                borderColor: theme["color-basic-15"],
            },
        ],
        pattern: {
            backgroundColor: couponBgColor ?? theme["color-primary-light"],
        },
        button: [styles.button, { backgroundColor: theme["color-positive-500"] }],
    };

    const textContentProp = {
        title: couponData.title,
        titleTextProps: textProps.title,
        description: couponData.description,
        descriptionProps: textProps.description,
    };

    const codeProp: CodeProp = {
        text: coupon.code,
        prefix: "Coupon Code: ",
        prefixProps: textProps.prefix,
        props: {
            ...textProps.code,
            color: coupon?.active ? "color-primary" : "color-basic-60",
        },
        style: msgStyle.code,
    };

    const optionsProp = {
        brandImageId: brandImageId,
        imgContainerStyle: { opacity: coupon.active ? 1 : 0.3 },
        isActive: coupon?.active,
        brandColor: coupon?.active ? brandColor : theme["color-basic-45"],
        cardStyle: msgStyle.card,
        patternStyle: msgStyle.pattern,
    };

    return (
        <Stack spacing={SpacingValue["space-x-small"]} style={msgStyle.container}>
            <CouponCard textContent={textContentProp} code={codeProp} options={optionsProp} />
            {onPress ? (
                <Pressable onPress={onPress} style={msgStyle.button}>
                    <Text color="color-basic-0" category="btn5" weight="bold">
                        {CONSTANTS.PRIMARY_BTN_TEXT}
                    </Text>
                </Pressable>
            ) : null}
            {text ? (
                <Text color="color-basic-75" category="b1">
                    {text}
                </Text>
            ) : null}
        </Stack>
    );
};

export const CouponWithTextMessage = React.memo(CouponWithTextMessageComponent);

const styles = StyleSheet.create({
    container: {
        borderRadius: 16,
    },
    couponCodeContainer: {
        paddingVertical: SpacingValue["space-x-small"],
    },
    button: {
        alignItems: "center",
        borderRadius: 12,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-small"],
    },
    card: {
        borderWidth: 1,
    },
});
