export const SpacingValue = {
    /** 2 */
    "space-xxx-small": 2,
    /** 4 */
    "space-xx-small": 4,
    /** 8 */
    "space-x-small": 8,
    /** 12 */
    "space-small": 12,
    /** 16 */
    "space-medium": 16,
    /** 20 */
    "space-large": 20,
    /** 24 */
    "space-x-large": 24,
    /** 32 */
    "space-xx-large": 32,
};
