import { useContext } from "react";
import { ChatStateContext } from "../contexts/chat-state";
import { ChatState } from "../reducers/chat";

export function useChatState(): ChatState {
    const ctx = useContext(ChatStateContext);
    if (!ctx) {
        throw new Error("Missing chat state context");
    }

    return ctx;
}
