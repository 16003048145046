import React, { ForwardRefRenderFunction, useMemo } from "react";
import {
    StyleProp,
    StyleSheet,
    useWindowDimensions,
    View,
    ViewProps,
    ViewStyle,
} from "react-native";

import { useScreenSize } from "../../hooks/use-screen-size";
import { ScreenSize, ScreenSizeProp } from "../../interfaces/screen";

interface ContainerProps extends ViewProps {
    /**
     * Determine the max-width of the container.
     * The container width grows with the size of the screen. Set to false to disable maxWidth.
     */
    maxWidth?: ScreenSize | false;
    /** If true, the left and right padding is removed. */
    disableGutters?: ScreenSizeProp<boolean>;
    /** Size of the gutter */
    gutterSize?: ScreenSizeProp<number>;
    maxWidthMap?: Partial<Record<ScreenSize, number>>;
    fluid?: boolean;
}

const DEFAULT_GUTTER_SIZE = 16;

const DEFAULT_SCREEN_MAX_WIDTH_MAP: Partial<Record<ScreenSize, number>> = {
    [ScreenSize.Large]: 1032,
    [ScreenSize.Medium]: 900,
};

const ContainerComponent: ForwardRefRenderFunction<View, ContainerProps> = (
    { style: propStyle, ...props },
    ref,
) => {
    const [containerstyle, contentStyle] = useContainerStyle(props);

    return (
        <View style={[styles.container, containerstyle]}>
            <View {...props} style={[styles.content, contentStyle, propStyle]} ref={ref} />
        </View>
    );
};

export const useContainerStyle = ({
    fluid = false,
    disableGutters = false,
    gutterSize = DEFAULT_GUTTER_SIZE,
    maxWidthMap = DEFAULT_SCREEN_MAX_WIDTH_MAP,
    maxWidth,
}: ContainerProps = {}): [StyleProp<ViewStyle>, StyleProp<ViewStyle>] => {
    const screenSize = useScreenSize();
    const { width: windowWidth } = useWindowDimensions();

    const _disableGutters =
        typeof disableGutters === "boolean"
            ? disableGutters
            : disableGutters[screenSize] ?? disableGutters.default ?? false;

    const _gutterSize = _disableGutters
        ? 0
        : typeof gutterSize === "number"
        ? gutterSize
        : gutterSize[screenSize] ?? gutterSize.default ?? 0;

    const width =
        maxWidth === false
            ? "100%"
            : maxWidthMap[maxWidth || screenSize] != null
            ? Math.min(windowWidth, maxWidthMap[maxWidth || screenSize] ?? 0) - _gutterSize * 2
            : "100%";

    return useMemo(() => {
        return [
            { paddingHorizontal: _gutterSize },
            {
                maxWidth: fluid ? "100%" : width,
                width: fluid ? "100%" : width,
                alignSelf: "center",
            },
        ];
    }, [_gutterSize, fluid, width]);
};

/**
 * The container centers your content horizontally. It's the most basic layout element.
 */
export const Container = React.forwardRef(ContainerComponent);

Container.defaultProps = {
    gutterSize: DEFAULT_GUTTER_SIZE,
    maxWidthMap: DEFAULT_SCREEN_MAX_WIDTH_MAP,
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: "100%",
        width: "100%",
        overflow: "hidden",
        flexDirection: "row",
        justifyContent: "center",
    },
    content: {
        height: "100%",
    },
});
