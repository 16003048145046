import { Theme, ThemeType } from "../interfaces";

import { SizeValue } from "../base/size";
import { SpacingValue } from "../base/spacing";
import { TypographyValue } from "../base/typography";
import { ElevationValue } from "../base/elevation";
import { generateColorPalette } from "../utils/color-palette";
import { convertToNativeFontMap } from "../utils/typography";

import { BaseTheme } from "@swiggy-private/dls-web-common/dist/components/themes/Base";

export type GenerateThemeParams = {
    primaryColor: string;
    secondaryColor: string;
    tertiaryColor: string;
    criticalColor: string;
    highlightColor: string;
    warningColor: string;
    positiveColor: string;
} & Partial<Theme>;

export const generateTheme = (params: GenerateThemeParams, type: ThemeType): Theme => {
    const {
        primaryColor,
        secondaryColor,
        tertiaryColor,
        criticalColor,
        warningColor,
        highlightColor,
        positiveColor,
        ...theme
    } = params;

    return {
        ...TypographyValue,
        ...SizeValue,
        ...SpacingValue,

        elevations: ElevationValue,
        ...{ swiggyTypography: convertToNativeFontMap(BaseTheme.typography) },

        "border-radius": 12,
        "border-width": 1,
        "letter-spacing": -0.03,

        ...generateColorPalette(
            {
                primaryColor,
                secondaryColor,
                tertiaryColor,
                criticalColor,
                highlightColor,
                warningColor,
                positiveColor,
            },
            type,
        ),

        ...theme,
    };
};
