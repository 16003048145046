import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer } from "react";
import { SdkImageMessagePayload, SdkQuoteMessagePayload } from "@swiggy-private/connect-chat-sdk";

import { Conversation } from "../interfaces";

export interface ChatConversationState {
    conversation: Conversation;
    newMessageContext?:
        | {
              type: "image";
              payload: SdkImageMessagePayload;
          }
        | {
              type: "quote";
              payload: Pick<SdkQuoteMessagePayload, "quotedMessage">;
          }
        | null;
    blockedInfo?: Conversation["blockedInfo"];
}

function chatConversationReducer(
    state: Readonly<ChatConversationState>,
    action: Readonly<ChatConversationAction>,
): Readonly<ChatConversationState> {
    switch (action.type) {
        case "SET_CONVERSATION_ACTION":
            return {
                ...state,
                conversation: action.payload.conversation,
            };
        case "SET_NEW_MESSAGE_CONTEXT_ACTION":
            return {
                ...state,
                newMessageContext: action.payload.context,
            };
        case "SET_BLOCKED_INFO":
            return {
                ...state,
                blockedInfo: action.payload.blockedInfo,
            };
    }
}

export function useChatConversationReducer(
    initialState: ChatConversationState,
): [
    ReducerState<Reducer<ChatConversationState, ChatConversationAction>>,
    Dispatch<ReducerAction<Reducer<ChatConversationState, ChatConversationAction>>>,
] {
    return useReducer(chatConversationReducer, initialState);
}

export interface SetNewMessageContextAction {
    type: "SET_NEW_MESSAGE_CONTEXT_ACTION";
    payload: {
        context: ChatConversationState["newMessageContext"];
    };
}

export interface SetConversationAction {
    type: "SET_CONVERSATION_ACTION";
    payload: {
        conversation: ChatConversationState["conversation"];
    };
}

export interface SetBlockedInfoAction {
    type: "SET_BLOCKED_INFO";
    payload: {
        blockedInfo: ChatConversationState["blockedInfo"];
    };
}

export type ChatConversationAction =
    | SetNewMessageContextAction
    | SetConversationAction
    | SetBlockedInfoAction;
