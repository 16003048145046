import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Animated,
    ColorValue,
    Dimensions,
    Easing,
    LayoutRectangle,
    NativeMethods,
    Platform,
    StatusBar,
    StyleProp,
    StyleSheet,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";
import memoizeOne from "memoize-one";
import { useMount, useMountedRef } from "@swiggy-private/react-hooks";

import { useDLS } from "../../styles/style-service";
import { FalsyText } from "../../support/falsy-text";
import { useControlled } from "../../hooks/use-controlled";
import { Portal } from "../portal";
import { TooltipTriggerMode, useTooltipTriggerHandlers } from "./trigger-mode";
import {
    Triangle,
    calculateTooltipCoordinates,
    TooltipPlacement,
    TooltipPointerDirection,
} from "../triangle";
import { useAnimatedValue } from "../../hooks/use-animated-value";
import { useDimissListeners } from "../../hooks/use-dismiss-listeners";
import { useSingleOwner } from "../../hooks/use-single-owner";
import { Overlay } from "../../support/overlay";

import { isCursorBrowser } from "../../support/browser";

import type { TooltipStyle } from "../../styles/interfaces/tooltip";

export type TooltipProps = {
    /** Whether the Tooltip is currently visible. */
    visible?: boolean;

    /** Tooltip anchor element. */
    children: React.ReactElement;

    /** The text or content to display in the Tooltip. **/
    message?: string | React.ReactNode;

    /** Callback fired when the Tooltip requests to be open. */
    onClose?(): void;

    /** Callback fired when the Tooltip requests to be closed. */
    onOpen?(): void;

    /** Force skip StatusBar height when calculating anchor position. Default is `false` */
    skipStatusBar?: boolean;

    /** The style to use for container which wraps anchor. */
    containerStyle?: StyleProp<ViewStyle>;

    /** Passes style object to tooltip container */
    style?: StyleProp<ViewStyle>;

    /** The style to use for the message of the Tooltip. */
    textStyle?: StyleProp<TextStyle>;

    /*
     * The length of time that the Tooltip will be shown after a long press is released or mouse pointer exits the anchor.
     * Defaults to 1 seconds for long press released or 0.1 seconds for mouse pointer exits the anchor.
     */
    showDuration?: number;

    /** The {@link TooltipTriggerMode} that will show the tooltip. */
    triggerMode?: TooltipTriggerMode;

    /** If true, adds an pointer to the tooltip. Default value is `true` */
    showPointer?: boolean;

    /** Sets backgroundColor of the tooltip and pointer. */
    backgroundColor?: ColorValue;

    /** Color of tooltip pointer, it defaults to the `backgroundColor` if none is passed. */
    pointerColor?: ColorValue;

    /** Size of the tooltip pointer. */
    pointerSize?: number;

    /** Tooltip placement. */
    placement?: TooltipPlacement;

    /** If true, a background overlay will be added */
    showOverlay?: boolean;

    /** Animation type, it defaults to the `scale animation` if none is passed */
    animationType?: string;

    /** Translate value in case of `slide animation`, it default to 100 if none is passed */
    translateValue?: number;

    /** Animation duration, it default to 100ms */
    // From https://material.io/design/motion/speed.html#duration
    animationDuration?: number;

    /** Additional offset to move pointer position */
    additionalPointerOffset?: {
        horizontal: number;
        vertical: number;
    };

    /** Whether to add a new element in the portal component or not */
    withoutPortal?: boolean;

    containerWrapperStyle?: StyleProp<ViewStyle>;

    pointerCustomStyle?: StyleProp<ViewStyle>;
    overlayBgColor?: "primary" | "secondary" | "transparent";
};

export type TooltipElement = React.ReactElement<TooltipProps>;
export { TooltipTriggerMode, TooltipPlacement };

const LONG_PRESS_DURATION = 250;

// From the 'Standard easing' section of https://material.io/design/motion/speed.html#easing
const EASING = Easing.bezier(0.4, 0, 0.2, 1);

const USE_NATIVE_DRIVER = Platform.OS !== "web";

const getComponentStyle = memoizeOne(
    (style: TooltipStyle): { textStyle: TextStyle; tooltipStyle: ViewStyle } => {
        return {
            textStyle: {
                fontFamily: style.textFontFamily,
                color: style.textColor,
                fontSize: style.textFontSize,
                letterSpacing: style.textLetterSpacing,
                lineHeight: style.textLineHeight,
            },
            tooltipStyle: {
                borderRadius: style.borderRadius,
                paddingHorizontal: style.paddingHorizontal,
                paddingVertical: style.paddingVertical,
                backgroundColor: style.backgroundColor,
            },
        };
    },
);

const measureLayout = (element: NativeMethods | null): Promise<LayoutRectangle | null> =>
    new Promise((resolve) => {
        element === null
            ? resolve(null)
            : // eslint-disable-next-line max-params
              element.measureInWindow((x, y, width, height) => {
                  resolve({ x, y, width, height });
              });
    });

/**
 * Tooltips display informative text when users hover over, focus on, or tap an element.
 *
 * ## Usage
 * When activated, tooltips display a text label identifying an element, such as a description of its function.
 *
 * ```ts
 * import * as React from 'react';
 * import { Tooltip, Button } from '@swiggy-private/rn-dls';
 *
 * export const MyComponent = () => (
 *   <Tooltip message="Hello, World"><Button>I am a tooltip!</Button></Tooltip>
 * );
 * ```
 */
export const Tooltip: React.FC<TooltipProps> = (props) => {
    const {
        style,
        textStyle,
        containerStyle,
        message,
        visible,
        children,
        onOpen,
        onClose,
        backgroundColor,
        pointerColor,
        animationType,
        translateValue = 100,
        animationDuration = 100,
        pointerSize: pointerSizeProp,
        showPointer = true,
        skipStatusBar = false,
        triggerMode = isCursorBrowser() ? TooltipTriggerMode.HOVER : TooltipTriggerMode.LONG_PRESS,
        showDuration = triggerMode === TooltipTriggerMode.HOVER ? 100 : 1000,
        placement = TooltipPlacement.BOTTOM,
        showOverlay = false,
        withoutPortal = false,
        containerWrapperStyle,
        pointerCustomStyle,
        additionalPointerOffset = {
            horizontal: 0,
            vertical: 0,
        },
        overlayBgColor,
    } = props;

    const dls = useDLS("tooltip");
    const dlsStyles = getComponentStyle(dls.style);

    const [open, setOpen] = useControlled(visible, !!visible);
    const [rendered, setRendered] = useState(false);

    const renderTimer = useRef<number>();
    const closeTimer = useRef<number>();

    const mounted = useMountedRef();
    const anchor = useRef<View>(null);
    const tooltip = useRef<View>(null);

    const opacityAnimation = useAnimatedValue(0);
    const scaleAnimation = useAnimatedValue(0);
    const slideAnimation = useAnimatedValue(translateValue);

    const WrapperComponent = withoutPortal ? WithoutPortalWrapper : PortalWrapper;

    const [coordinates, setCoordinates] = useState({
        x: 0,
        y: 0,
        pointerX: 0,
        pointerY: 0,
        pointerDirection: TooltipPointerDirection.TOP,
    });

    const handleOpen = useCallback((): void => {
        setOpen(true);
        onOpen?.();
    }, [onOpen, setOpen]);

    const handleClose = useCallback((): void => {
        if (mounted.current) {
            setOpen(false);
            onClose?.();
        }
    }, [mounted, onClose, setOpen]);

    const [attachListeners, removeListeners] = useDimissListeners(handleClose, {
        onHardwareBack: true,
        onDimensionChange: true,
        onKeyboardEsc: true,
    });

    const claimOwnership = useSingleOwner();

    const triggerHandler = useCallback(
        (triggerModes: TooltipTriggerMode[]) => {
            if (triggerMode === TooltipTriggerMode.MANUAL) {
                return;
            }

            if (!triggerModes.length && open) {
                clearTimeout(closeTimer.current);
                closeTimer.current = setTimeout(handleClose, showDuration) as unknown as number;
            } else if (triggerModes.includes(triggerMode) && open) {
                clearTimeout(closeTimer.current);
                handleClose();
            } else if (triggerModes.includes(triggerMode)) {
                clearTimeout(closeTimer.current);
                return handleOpen();
            }
        },
        [handleClose, handleOpen, open, showDuration, triggerMode],
    );

    const pointerSize = showPointer ? pointerSizeProp ?? dls.style.pointerSize : 0;
    const additionalVerticalValue = Platform.select({
        android: skipStatusBar ? 0 : StatusBar.currentHeight ?? 0,
        default: 0,
    });

    const startRenderTimer = useCallback((fn: () => unknown): (() => void) => {
        renderTimer.current && cancelAnimationFrame(renderTimer.current);
        renderTimer.current = requestAnimationFrame(() => fn());

        return () => {
            renderTimer.current && cancelAnimationFrame(renderTimer.current);
        };
    }, []);

    const getShowAnimationSequence = useCallback(() => {
        const animationSequence = [
            Animated.timing(opacityAnimation, {
                toValue: 1,
                duration: animationDuration,
                easing: EASING,
                useNativeDriver: USE_NATIVE_DRIVER,
            }),
        ];
        if (animationType === "slide") {
            animationSequence.push(
                Animated.timing(slideAnimation, {
                    toValue: 0,
                    duration: animationDuration,
                    easing: EASING,
                    useNativeDriver: USE_NATIVE_DRIVER,
                }),
            );
        } else {
            animationSequence.push(
                Animated.timing(scaleAnimation, {
                    toValue: 1,
                    duration: animationDuration,
                    easing: EASING,
                    useNativeDriver: USE_NATIVE_DRIVER,
                }),
            );
        }

        return animationSequence;
    }, [animationDuration, animationType, opacityAnimation, scaleAnimation, slideAnimation]);

    const onShow = useCallback(async () => {
        if (!mounted.current) {
            return;
        }

        const windowLayout = Dimensions.get("window");
        const [_anchorLayout, _tooltipLayout] = await Promise.all([
            measureLayout(anchor.current),
            measureLayout(tooltip.current),
        ]);

        if (!mounted.current) {
            return;
        }

        // When visible is true for first render native views can be still not rendered and
        // may return wrong values e.g { x:0, y: 0, width: 0, height: 0 }
        // so we have to wait until views are ready and rerun this function.
        if (
            !windowLayout.width ||
            !windowLayout.height ||
            !_tooltipLayout?.width ||
            !_tooltipLayout?.height ||
            !_anchorLayout?.width ||
            !_anchorLayout?.height
        ) {
            startRenderTimer(onShow);
            return;
        }

        !withoutPortal &&
            setCoordinates(
                calculateTooltipCoordinates({
                    anchorLayout: _anchorLayout,
                    windowLayout: windowLayout,
                    tooltipLayout: _tooltipLayout,
                    verticalOffset: additionalVerticalValue,
                    pointerSize,
                    placement,
                }),
            );

        attachListeners();

        Animated.parallel(getShowAnimationSequence()).start();
    }, [
        mounted,
        additionalVerticalValue,
        pointerSize,
        placement,
        attachListeners,
        getShowAnimationSequence,
        startRenderTimer,
        withoutPortal,
    ]);

    const onHide = useCallback(() => {
        renderTimer.current && cancelAnimationFrame(renderTimer.current);

        const animationSequence = [
            Animated.timing(opacityAnimation, {
                toValue: 0,
                duration: animationDuration,
                easing: EASING,
                useNativeDriver: USE_NATIVE_DRIVER,
            }),
        ];

        if (animationType === "slide") {
            animationSequence.push(
                Animated.timing(slideAnimation, {
                    toValue: translateValue,
                    duration: animationDuration,
                    easing: EASING,
                    useNativeDriver: USE_NATIVE_DRIVER,
                }),
            );
        }

        Animated.parallel(animationSequence).start(({ finished }) => {
            if (finished && mounted.current) {
                setRendered(false);
                scaleAnimation.setValue(0);
                removeListeners();
            }
        });
    }, [
        animationDuration,
        animationType,
        mounted,
        opacityAnimation,
        removeListeners,
        scaleAnimation,
        slideAnimation,
        translateValue,
    ]);

    useEffect(() => {
        if (open && !rendered) {
            setRendered(true);
        } else if (!open && rendered) {
            onHide();
        }
    }, [onHide, open, rendered]);

    useEffect(() => {
        if (rendered) {
            const ownerSubscription = claimOwnership(handleClose);
            const timerSubscription = startRenderTimer(onShow);

            return () => {
                ownerSubscription();
                timerSubscription();
            };
        }

        // eslint-disable-next-line no-void
        return () => void 0;
    }, [claimOwnership, handleClose, onShow, rendered, startRenderTimer]);

    useMount(() => () => {
        clearTimeout(closeTimer.current);
        renderTimer.current && cancelAnimationFrame(renderTimer.current);
        removeListeners();
    });

    const childHandlers = useTooltipTriggerHandlers(children.props, triggerHandler);

    const wrapperStyle = {
        top: coordinates.y,
        left: coordinates.x,
    };

    const pointerStyle = {
        top: coordinates.pointerY - additionalPointerOffset?.vertical ?? 0,
        left: coordinates.pointerX - additionalPointerOffset?.horizontal ?? 0,
    };

    const transform =
        animationType === "slide"
            ? [{ translateY: slideAnimation as unknown as number }]
            : [{ scale: scaleAnimation as unknown as number }];

    const tooltipStyle: ViewStyle = {
        opacity: opacityAnimation as unknown as number,
        transform,
    };

    return (
        <View collapsable={false} ref={anchor} style={containerStyle}>
            {React.cloneElement(children, {
                ...childHandlers,
                ...(Platform.OS !== "web" ? { delayLongPress: LONG_PRESS_DURATION } : {}),
            })}
            {rendered ? (
                <WrapperComponent containerWrapperStyle={containerWrapperStyle}>
                    {showOverlay ? (
                        <Overlay onPress={handleClose} backgroundColor={overlayBgColor} />
                    ) : null}
                    <View
                        ref={tooltip}
                        collapsable={false}
                        pointerEvents={open ? "box-none" : "none"}
                        onAccessibilityEscape={handleClose}
                        style={[styles.wrapper, wrapperStyle]}>
                        <Animated.View style={[dlsStyles.tooltipStyle, tooltipStyle, style]}>
                            {showPointer ? (
                                <View
                                    style={[
                                        styles.pointerWrapper,
                                        !withoutPortal && pointerStyle,
                                        pointerCustomStyle,
                                    ]}>
                                    <Triangle
                                        size={pointerSize}
                                        color={
                                            pointerColor ||
                                            backgroundColor ||
                                            dls.style.pointerBackgroundColor
                                        }
                                        direction={coordinates.pointerDirection}
                                    />
                                </View>
                            ) : null}
                            {typeof message === "string" ? (
                                <FalsyText
                                    component={message}
                                    style={[dlsStyles.textStyle, textStyle]}
                                />
                            ) : (
                                message
                            )}
                        </Animated.View>
                    </View>
                </WrapperComponent>
            ) : null}
        </View>
    );
};

const PortalWrapper: React.FC<React.PropsWithChildren> = (props) => {
    return <Portal>{props.children}</Portal>;
};

interface WithoutPortalWrapperProps {
    containerWrapperStyle: TooltipProps["containerWrapperStyle"];
}

const WithoutPortalWrapper: React.FC<React.PropsWithChildren<WithoutPortalWrapperProps>> = (
    props,
) => {
    return (
        <View collapsable={false} pointerEvents="box-none" style={props.containerWrapperStyle}>
            <View style={styles.wrapperSubContainer} collapsable={false}>
                {props.children}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    pointerWrapper: {
        position: "absolute",
    },
    wrapper: {
        position: "absolute",
    },
    wrapperSubContainer: {
        position: "absolute",
    },
});
