import * as React from "react";
import { Pressable, PressableProps, StyleProp, ViewStyle } from "react-native";

import { FalsyText } from "../../support/falsy-text";
import type { RenderProp } from "../../support/falsy-fc";
import type { TextProps } from "../text";

export interface TabProps extends Omit<PressableProps, "children" | "style"> {
    selected?: boolean;
    children?: RenderProp<TextProps> | React.ReactText;
    textProps?: TextProps;
    style?: StyleProp<ViewStyle>;
}

export const Tab: React.FC<TabProps> = ({
    accessibilityRole = "tab",
    accessible = true,
    selected = false,
    accessibilityState = {
        selected,
    },
    children,
    textProps,
    ...props
}) => {
    return (
        <Pressable
            accessibilityRole={accessibilityRole}
            accessible={accessible}
            accessibilityState={accessibilityState}
            {...props}>
            <FalsyText component={children} numberOfLines={1} selectable={false} {...textProps} />
        </Pressable>
    );
};
