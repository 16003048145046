import { useCallback } from "react";

type VoidFunction = () => void;

let currentOwnerRef: VoidFunction | null = null;

export const useSingleOwner = (): ((onRemove: VoidFunction) => VoidFunction) => {
    const claimInstance = useCallback((onRemove: VoidFunction) => {
        currentOwnerRef?.();
        currentOwnerRef = onRemove;

        return () => {
            if (onRemove === currentOwnerRef) {
                currentOwnerRef = null;
            }
        };
    }, []);

    return claimInstance;
};
