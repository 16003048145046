import React from "react";
import { Pressable, StyleSheet, View } from "react-native";

import { Text } from "../text";
import { TextCategory, TextColor, TextWeight } from "../../styles/interfaces/text";

interface TextProp {
    text: string;
    category?: TextCategory;
    color?: TextColor;
    weight?: TextWeight;
}

export type ModalTitleProp = TextProp;
export type ModalDescriptionProp = TextProp;

interface ButtonProp {
    title: TextProp | string;
    callback: () => void;
    dismissOnPress?: boolean;
}

export interface ModalButtonProp {
    primary?: ButtonProp;
    secondary?: ButtonProp;
}

export interface ModalWithTitleProps {
    component?: never;
    title: ModalTitleProp | string;
    description?: ModalDescriptionProp | string;
    buttons?: ModalButtonProp;
}

type ButtonsKeysType = "Primary" | "Secondary";
type ButtonsValueType = "primary" | "secondary";

const Buttons: { [k in ButtonsKeysType]: ButtonsValueType } = {
    Primary: "primary",
    Secondary: "secondary",
};

export const ModalWithTitle: React.FC<
    ModalWithTitleProps & {
        hideModal: () => void;
    }
> = ({ title, description, buttons, hideModal }) => {
    const titleProps = typeof title === "string" ? { text: title } : title;
    const descriptionProps = typeof description === "string" ? { text: description } : description;

    return (
        <>
            <Text
                category={titleProps.category ?? "b1"}
                color={titleProps.color ?? "color-basic-75"}
                weight={titleProps.weight ?? "bold"}>
                {titleProps.text}
            </Text>

            {descriptionProps ? (
                <Text
                    category={descriptionProps.category ?? "b2"}
                    color={descriptionProps.color ?? "color-basic-60"}
                    weight={descriptionProps.weight ?? "regular"}
                    style={styles.description}>
                    {descriptionProps.text}
                </Text>
            ) : null}

            {buttons ? (
                <View style={styles.btnContainer}>
                    {Object.values(Buttons).map((buttonType, index) => (
                        <ActionCTA
                            key={index}
                            button={buttons[buttonType]}
                            type={buttonType}
                            hideModal={hideModal}
                        />
                    ))}
                </View>
            ) : null}
        </>
    );
};

interface ActionCTAProps {
    type: ButtonsValueType;
    button?: ButtonProp;
    hideModal: () => void;
}

const ActionCTA: React.FC<ActionCTAProps> = ({ type, button, hideModal }) => {
    const buttonCallback = React.useCallback(() => {
        const callback = button?.callback;
        const dismiss = button?.dismissOnPress;

        if (callback) {
            callback();
        }

        if (dismiss) {
            hideModal();
        }
    }, [button, hideModal]);

    if (!button) {
        return null;
    }

    const titleProps = typeof button.title === "string" ? { text: button.title } : button.title;

    return (
        <Pressable
            style={type === "secondary" ? styles.secondaryBtn : null}
            onPress={buttonCallback}
            testID={`${type}-btn`}>
            <Text
                category={titleProps.category ?? "btn4"}
                color={titleProps.color ?? "color-primary"}
                weight={titleProps.weight ?? "regular"}>
                {titleProps.text}
            </Text>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    description: {
        marginTop: 4,
    },
    btnContainer: {
        marginTop: 20,
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
    },
    secondaryBtn: {
        marginRight: 28,
    },
});
