import { SdkAddMessageActionTypes } from "@swiggy-private/connect-chat-sdk";
import { useCallback, useMemo } from "react";
import { hasMessageAction } from "../helpers";
import { logError } from "../helpers/log";

import { Message } from "../interfaces/types";

import { useChatSdk } from "./use-chatsdk";
import { useChatUserId } from "./use-chatuserid";

interface ChatMessageActionParams {
    type: SdkAddMessageActionTypes;
    value?: string;
    conversationId: string;
    message: Message;
}

export const useChatMessageAction = (): ((params: ChatMessageActionParams) => boolean) => {
    const sdk = useChatSdk();
    const uuid = useChatUserId();

    return useCallback(
        ({ type, value, conversationId, message }) => {
            if (!sdk || uuid === message.publisher) {
                return false;
            }

            const timetoken = String(message.timetoken);
            const hasAction = uuid ? hasMessageAction(message.actions ?? {}, type, uuid) : false;

            if (hasAction) {
                return false;
            }

            sdk.addMessageAction({
                conversationId,
                messageTimetoken: timetoken,
                action: {
                    type,
                    value: value ?? String(Date.now()),
                },
            }).catch(logError);

            return true;
        },
        [sdk, uuid],
    );
};

export const useIsMessageSent = (_message: Message): boolean => {
    return true;
};

export const useIsMessageReceived = (message: Message): boolean => {
    return useMemo(
        () => hasMessageAction(message.actions ?? {}, "ack", message.publisher, false),
        [message.actions, message.publisher],
    );
};

export const useIsMessageRead = (message: Message): boolean => {
    return useMemo(
        () => hasMessageAction(message.actions ?? {}, "read", message.publisher, false),
        [message.actions, message.publisher],
    );
};
