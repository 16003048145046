import React, { ReactNode } from "react";
import { FlexAlignType, FlexStyle, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { useTheme } from "@swiggy-private/rn-dls";

interface Props {
    children?: ReactNode;
    flex?: number;
    mainAxisAlignment?: FlexStyle["justifyContent"];
    crossAxisAlignment?: FlexAlignType;
    style?: StyleProp<ViewStyle>;
    testID?: string;
}

export const Row: React.FC<Props> = ({
    children,
    mainAxisAlignment = "flex-start",
    crossAxisAlignment = "center",
    flex = 1,
    style,
    testID,
}) => {
    const theme = useTheme();
    const bgColor = theme.value["color-background-primary"];

    return (
        <View
            testID={testID}
            style={[
                styles.row,
                { flex: flex },
                { justifyContent: mainAxisAlignment },
                { alignItems: crossAxisAlignment },
                { backgroundColor: bgColor },
                style,
            ]}>
            {children}
        </View>
    );
};

const styles = StyleSheet.create({
    row: {
        flex: 1,
        flexDirection: "row",
    },
});
