import React from "react";
import {
    SafeAreaView,
    StyleSheet,
    useWindowDimensions,
    View,
    ViewProps,
    ViewStyle,
} from "react-native";

import { Button, Text, useTheme, SpacingValue, ColorPalette } from "@swiggy-private/rn-dls";
import { Box } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";

interface GenericErrorViewProps extends ViewProps {
    title?: string | null;
    message?: string | null;
    onRetry?: () => void;
    applyInset?: boolean;
    retryBtnText?: string;
    innerStyle?: ViewStyle;
    customBGColor?: string;
}

export const GenericErrorView: React.FC<GenericErrorViewProps> = ({
    title,
    message,
    onRetry,
    style,
    innerStyle = {},
    applyInset = true,
    retryBtnText = "Retry",
    customBGColor,
    ...props
}) => {
    title = title || "Ooops!";
    message = message || "Something's not right. Please try again later.";

    const { value: theme } = useTheme();
    const ViewC = applyInset ? SafeAreaView : View;

    const { width: screenWidth } = useWindowDimensions();

    const imgWidth = Math.min(screenWidth * 0.44, 166.17);
    const imgHeight = imgWidth * 0.93;

    const ColorPallete = {
        ViewWrapper: {
            backgroundColor: customBGColor
                ? theme[customBGColor as keyof ColorPalette]
                : theme["color-background-primary"],
        },
    };

    const content = (
        <Box flex={1} style={innerStyle} justifyContent="center" alignItems="center">
            <CdnImage id="minis/generic_error" height={imgHeight} width={imgWidth} />

            <Box
                style={styles.content}
                alignItems="center"
                justifyContent="center"
                mt={2 * SpacingValue["space-xx-large"]}
                mb={SpacingValue["space-x-small"]}>
                <Text category="h3" color="high" style={styles.title}>
                    {title}
                </Text>
                <Text style={styles.message} category="b2" color="color-basic-60" weight="medium">
                    {message}
                </Text>
            </Box>

            {onRetry ? (
                <Box mt={SpacingValue["space-xx-large"]} style={styles.btnContainer}>
                    <Button
                        onPress={() => onRetry()}
                        accessibilityRole="button"
                        accessibilityLabel="Retry">
                        {retryBtnText}
                    </Button>
                </Box>
            ) : null}
        </Box>
    );

    return (
        <ViewC style={[styles.container, ColorPallete.ViewWrapper, style]} {...props}>
            {content}
        </ViewC>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal: SpacingValue["space-medium"],
    },
    content: {
        maxWidth: 280,
    },
    title: {
        textAlign: "center",
    },
    message: {
        textAlign: "center",
        marginTop: SpacingValue["space-x-small"],
    },
    btnContainer: {
        width: 180,
    },
});
