import { useContext } from "react";
import { ChatConversationStateContext } from "../contexts/chat-conversation-context";
import { ChatConversationState } from "../reducers/conversation";

export function useChatConversationState(): ChatConversationState {
    const ctx = useContext(ChatConversationStateContext);
    if (!ctx) {
        throw new Error("Missing chat conversation state context");
    }

    return ctx;
}
