import React, { useCallback, useMemo, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";

// Represents the mode of selection in the CustomSwitch component.
export enum SelectedOption {
    Option1 = 1,
    Option2 = 2,
}

interface Props {
    // Represents the text or label for the first option in the CustomSwitch component.
    option1: string;
    // Represents the text or label for the second option in the CustomSwitch component.
    option2: string;
    onSelectSwitch: (i: number) => void;

    selectedOption?: SelectedOption;
}

const CustomSwitchComponent: React.FC<Props> = ({
    selectedOption = SelectedOption.Option1,
    option1,
    option2,
    onSelectSwitch,
}) => {
    const { value: theme } = useTheme();
    const [currentSelectedOption, setCurrentSelectedOption] = useState(selectedOption);

    const updatedSwitchData = useCallback(
        (val: number): void => {
            setCurrentSelectedOption(val);
            onSelectSwitch(val);
        },
        [onSelectSwitch],
    );

    const handleOption1Press = useCallback(() => {
        updatedSwitchData(SelectedOption.Option1);
    }, [updatedSwitchData]);

    const handleOption2Press = useCallback(() => {
        updatedSwitchData(SelectedOption.Option2);
    }, [updatedSwitchData]);

    const textPropsOption1: TextProps = useMemo(
        () => ({
            weight: currentSelectedOption === SelectedOption.Option1 ? "bold" : "regular",
            color:
                currentSelectedOption === SelectedOption.Option1
                    ? "color-primary-400"
                    : "color-basic-60",
        }),
        [currentSelectedOption],
    );

    const textPropsOption2: TextProps = useMemo(
        () => ({
            weight: currentSelectedOption === SelectedOption.Option2 ? "bold" : "regular",
            color:
                currentSelectedOption === SelectedOption.Option2
                    ? "color-primary-400"
                    : "color-basic-60",
        }),
        [currentSelectedOption],
    );

    return (
        <Stack
            direction="row"
            justifyContent="center"
            style={[
                styles.container,
                {
                    borderColor: theme["color-basic-15"],
                    backgroundColor: theme["color-basic-15"],
                },
            ]}>
            <TouchableOpacity
                activeOpacity={1}
                onPress={handleOption1Press}
                style={[
                    styles.option,
                    {
                        backgroundColor:
                            currentSelectedOption === SelectedOption.Option1
                                ? theme["color-basic-0"]
                                : theme["color-basic-15"],
                    },
                ]}>
                <Text category="b2" {...textPropsOption1}>
                    {option1}
                </Text>
            </TouchableOpacity>
            <TouchableOpacity
                activeOpacity={1}
                onPress={handleOption2Press}
                style={[
                    styles.option,
                    {
                        backgroundColor:
                            currentSelectedOption === SelectedOption.Option2
                                ? theme["color-basic-0"]
                                : theme["color-basic-15"],
                    },
                ]}>
                <Text category="b2" {...textPropsOption2}>
                    {option2}
                </Text>
            </TouchableOpacity>
        </Stack>
    );
};

export const CustomSwitch = React.memo(CustomSwitchComponent);

const styles = StyleSheet.create({
    container: {
        height: 37,
        width: "100%",
        borderWidth: 1,
        padding: SpacingValue["space-xxx-small"],
        borderRadius: 10,
    },
    option: {
        flex: 1,
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center",
    },
});
