import memoize from "memoize-one";
import React, { JSXElementConstructor, ReactElement } from "react";
import { Platform } from "react-native";
import rgbHex from "rgb-hex";

export const CLOUDINARY_BASE_URL = "https://res.cloudinary.com";

interface GetCloudinaryImageUrlParams {
    cloudName: string;
    id: string;
    cloudBaseUrl?: string;
    cloudResourceType?: "upload" | "youtube";
    cloudUrl?: string;
    folderName?: string;
    transformations?: Partial<CloudinaryTransformation> | Partial<CloudinaryTransformation>[];
}

export const getCloudinaryImageUrl = (params: GetCloudinaryImageUrlParams): string => {
    const baseUrl = params.cloudUrl
        ? params.cloudUrl
        : getCloudinaryImageBaseUrl({
              cloudName: params.cloudName,
              baseUrl: params.cloudBaseUrl,
              type: params.cloudResourceType,
          });

    const transformations = params.transformations
        ? getCloudinaryTransformation(params.transformations)
        : null;

    const imageId = params.folderName ? `${params.folderName}/${params.id}` : params.id;

    return transformations ? `${baseUrl}/${transformations}/${imageId}` : `${baseUrl}/${imageId}`;
};

export const getCloudinaryVideoUrl = (params: GetCloudinaryImageUrlParams): string => {
    const baseUrl = params.cloudUrl
        ? params.cloudUrl
        : getCloudinaryVideoBaseUrl({
              cloudName: params.cloudName,
              baseUrl: params.cloudBaseUrl,
              type: params.cloudResourceType,
          });

    const transformations = params.transformations
        ? getCloudinaryTransformation(params.transformations)
        : null;

    const videoId = params.folderName ? `${params.folderName}/${params.id}` : params.id;

    return transformations ? `${baseUrl}/${transformations}/${videoId}` : `${baseUrl}/${videoId}`;
};

export const getCloudinaryImageBaseUrl = memoize(
    (opts: { cloudName: string; baseUrl?: string; type?: "upload" | "youtube" }): string => {
        const baseUrl = opts.baseUrl || CLOUDINARY_BASE_URL;
        return `${baseUrl}/${opts.cloudName}/image/${opts.type ?? "upload"}`;
    },
);

export const getCloudinaryVideoBaseUrl = memoize(
    (opts: { cloudName: string; baseUrl?: string; type?: "upload" | "youtube" }): string => {
        const baseUrl = opts.baseUrl || CLOUDINARY_BASE_URL;
        return `${baseUrl}/${opts.cloudName}/video/${opts.type ?? "upload"}`;
    },
);

// https://cloudinary.com/documentation/transformation_reference
export interface CloudinaryTransformation {
    width: number;
    height: number;
    // https://cloudinary.com/documentation/transformation_reference#c_crop_resize
    crop: "scale" | "thumb" | "fit" | "limit";
    // Range: 1 to 100.
    // https://cloudinary.com/documentation/transformation_reference#q_quality
    quality: number;
    // https://cloudinary.com/documentation/image_optimization#automatic_quality_selection_q_auto
    qualityAuto: boolean | "good" | "best" | "eco" | "low";
    // An RGB or RGBA hex triplet or quadruplet (6 or 8 digits).
    // https://cloudinary.com/documentation/transformation_reference#co_color
    color: string;
    // https://cloudinary.com/documentation/transformation_reference#f_format
    format: "auto" | "png" | "jpg" | "webp" | "jpeg" | "svg";
    // https://cloudinary.com/blog/auto_generate_video_previews_with_great_results_every_time
    preview: boolean | { duration: number };
    // border-roundness to the image in pixel
    round: number;
    dpr: number;
    // https://cloudinary.com/documentation/video_optimization#bitrate_control
    bitrate: string;
}

export const getCloudinaryTransformation = (
    transformations: Partial<CloudinaryTransformation> | Partial<CloudinaryTransformation>[],
): string => {
    if (!Array.isArray(transformations)) {
        return getCloudinaryTransformation([transformations]);
    }

    return transformations
        .map((t) => {
            const arr: string[] = [];

            if (t.color) {
                arr.push("co_" + getCloudinaryColorValue(t.color));
                arr.push("e_colorize:100");
            }

            if (t.width) {
                arr.push("w_" + t.width);
            }

            if (t.height) {
                arr.push("h_" + t.height);
            }

            if (t.crop) {
                arr.push("c_" + t.crop);
            }

            if (t.round) {
                arr.push("r_" + t.round);
            }

            if (t.quality) {
                arr.push("q_" + t.quality);
            } else if (t.qualityAuto) {
                arr.push(typeof t.qualityAuto === "boolean" ? "q_auto" : `q_auto:${t.qualityAuto}`);
            }

            if (t.format) {
                arr.push("f_" + t.format);
            }

            if (t.dpr) {
                arr.push("dpr_" + t.dpr);
            }

            if (t.bitrate) {
                arr.push("br_" + t.bitrate);
            }

            if (t.preview) {
                if (typeof t.preview === "boolean") {
                    arr.push("e_preview");
                } else {
                    arr.push("e_preview:duration_" + t.preview.duration);
                }
            }

            return arr.join(",");
        })
        .filter(Boolean)
        .join("/");
};

const getCloudinaryColorValue = (color: string): string => {
    if (color.startsWith("#")) {
        return `rgb:${color.substring(1)}`;
    }

    if (color.startsWith("rgb")) {
        return `rgb:${rgbHex(color)}`;
    }

    return color;
};

export const getCloudinaryTransformationsFromChildren = <
    T extends JSXElementConstructor<Partial<CloudinaryTransformation>>,
    C extends ReactElement<Partial<CloudinaryTransformation>, T> = ReactElement<
        Partial<CloudinaryTransformation>,
        T
    >,
>(
    type: T,
    children?: C | C[],
): Partial<CloudinaryTransformation>[] =>
    React.Children.toArray(children)
        .filter((e) => React.isValidElement(e) && e.type === type)
        .map((e) => (e as C).props);

export const isCloudinaryUrl = memoize((url: string): boolean => url.includes(CLOUDINARY_BASE_URL));

/**
 * sample url https://res.cloudinary.com/swiggymini/image/upload/mini-assets
 */
export const parseCloudinaryUrl = memoize(
    (
        url: string,
    ): {
        cloudName?: string;
        folder?: string;
    } => {
        const regex =
            /https:\/\/res\.cloudinary\.com\/([a-zA-Z-\d]+)\/image\/upload(\/[a-zA-Z-\d]+)?/;

        const [, cloudName, folder] = url.match(regex) || [];

        return { cloudName, folder: folder?.replace("/", "") };
    },
);

export const getImageFormatForPlatform = (): CloudinaryTransformation["format"] => {
    return Platform.OS === "web" ? "auto" : Platform.OS === "android" ? "webp" : "png";
};
