import { useMountedRef } from "@swiggy-private/react-hooks";
import React, { useCallback, useEffect } from "react";
import {
    Animated,
    LayoutChangeEvent,
    Platform,
    StyleSheet,
    TransformsStyle,
    ViewStyle,
    TranslateXTransform,
    TranslateYTransform,
} from "react-native";

import { useAnimatedValue } from "../../hooks/use-animated-value";
import { useLayout } from "../../hooks/use-layout";
import { AnimatedViewProps } from "./types";

export const AnimatedView: React.FC<AnimatedViewProps> = (props) => {
    const {
        animate = true,
        duration = 100,
        animationType = "scale",
        reverse = false,
        onFinish,
        style,
        onLayout: onLayoutProp,
        yOrigin,
        xOrigin,
        ...viewProps
    } = props;

    const mounted = useMountedRef();
    const animValue = useAnimatedValue(0);
    const [layout, onLayout] = useLayout();

    const onLayoutCallback = useCallback(
        (e: LayoutChangeEvent) => {
            onLayout(e);
            onLayoutProp?.(e);
        },
        [onLayout, onLayoutProp],
    );

    useEffect(() => {
        if (!animate || !layout.measured) {
            return;
        }

        const a = Animated.timing(animValue, {
            duration,
            toValue: reverse ? 0 : 1,
            useNativeDriver: Platform.OS !== "web",
        });

        a.start(({ finished }) => {
            if (mounted.current) {
                finished && onFinish?.();
            }
        });
    }, [animValue, animate, duration, layout.measured, mounted, onFinish, reverse]);

    const _style = StyleSheet.flatten([
        style,
        layout.measured
            ? getAnimationStyle({
                  width: layout.width,
                  height: layout.height,
                  xOrigin,
                  yOrigin,
                  type: animationType,
                  animation: animValue,
                  inverted: reverse,
              })
            : {
                  opacity: 0,
              },
    ]);

    return <Animated.View {...viewProps} style={_style} onLayout={onLayoutCallback} />;
};

interface GetAnimationStyleParams {
    type: AnimatedViewProps["animationType"];
    animation: Animated.Value;
    width: number;
    height: number;
    inverted?: boolean;
    yOrigin?: AnimatedViewProps["yOrigin"];
    xOrigin?: AnimatedViewProps["xOrigin"];
}

const getAnimationStyle = (params: GetAnimationStyleParams): TransformsStyle | ViewStyle | null => {
    if (params.type === "fade") {
        return {
            opacity: params.animation.interpolate({
                inputRange: [0, 1],
                outputRange: params.inverted ? [0, 1] : [0, 1],
            }) as unknown as number,
        };
    }

    if (params.type === "rotate") {
        return {
            transform: getTransformStyleWithOrigin(
                [
                    {
                        rotate: params.animation.interpolate({
                            inputRange: [0, 1],
                            outputRange: params.inverted ? ["0deg", "180deg"] : ["0deg", "180deg"],
                        }) as unknown as string,
                    },
                ],
                params,
            ),
        };
    }

    if (params.type === "scale") {
        return {
            transform: getTransformStyleWithOrigin(
                [
                    {
                        scale: params.animation.interpolate({
                            inputRange: [0, 1],
                            outputRange: params.inverted ? [0, 1] : [0, 1],
                        }) as unknown as number,
                    },
                ],
                params,
            ),
        };
    }

    if (params.type === "slide_vertical") {
        return {
            transform: [
                {
                    translateY: params.animation.interpolate({
                        inputRange: [0, 1],
                        outputRange: params.inverted ? [params.height * 2, 0] : [params.height, 0],
                    }) as unknown as number,
                },
            ],
        };
    }

    if (params.type === "slide_horizontal") {
        return {
            transform: [
                {
                    translateX: params.animation.interpolate({
                        inputRange: [0, 1],
                        outputRange: params.inverted ? [params.width * 2, 0] : [params.width, 0],
                    }) as unknown as number,
                },
            ],
        };
    }

    return null;
};

const getTransformStyleWithOrigin = (
    transforms: NonNullable<TransformsStyle["transform"]>,
    { yOrigin, xOrigin, height, width }: GetAnimationStyleParams,
): NonNullable<TransformsStyle["transform"]> => {
    const startY = yOrigin === "top" ? -height / 2 : height / 2;
    const startX = xOrigin === "left" ? -width / 2 : width / 2;

    const ret: NonNullable<TransformsStyle["transform"]> = [];

    if (yOrigin != null) {
        ret.push({
            translateY: startY,
        });
    }

    if (xOrigin != null) {
        ret.push({
            translateX: startX,
        });
    }

    ret.push(...(transforms as TranslateXTransform[] | TranslateYTransform[]));

    if (yOrigin != null) {
        ret.push({
            translateY: -startY,
        });
    }

    if (xOrigin != null) {
        ret.push({
            translateX: -startX,
        });
    }

    return ret;
};
