import type { Theme } from "@swiggy-private/rn-dls-theme";

import type { ButtonStyle, ButtonStyleMeta } from "../interfaces/button";
import type { ButtonGroupStyle, ButtonGroupStyleMeta } from "../interfaces/button-group";
import type { InputStyle, InputStyleMeta } from "../interfaces/input";
import type { TextStyle, TextStyleMeta } from "../interfaces/text";
import type { ToggleStyle, ToggleStyleMeta } from "../interfaces/toggle";
import type { RadioButtonStyle, RadioButtonStyleMeta } from "../interfaces/radio-button";
import type { CheckBoxStyle, CheckBoxStyleMeta } from "../interfaces/check-box";
import type { ModalStyle, ModalStyleMeta } from "../interfaces/modal";
import type { SnackbarStyle, SnackbarStyleMeta } from "../interfaces/snackbar";
import type { ToastStyle, ToastStyleMeta } from "../interfaces/toast";
import type { TooltipStyle, TooltipStyleMeta } from "../interfaces/tooltip";
import type { AppBarStyle, AppBarStyleMeta } from "../interfaces/appbar";

import { getButtonStyles } from "./button";
import { getButtonGroupStyles } from "./button-group";
import { getInputStyles } from "./input";
import { getTextStyles } from "./text";
import { getToggleStyles } from "./toggle";
import { getRadioButtonStyles } from "./radio-button";
import { getCheckBoxStyles } from "./check-box";
import { getModalStyles } from "./modal";
import { getSnackbarStyles } from "./snackbar";
import { getToastStyles } from "./toast";
import { getTooltipStyles } from "./tooltip";
import { getAppBarStyles } from "./appbar";

export type ComponentStyleMetaMapping = {
    button: ButtonStyleMeta;
    text: TextStyleMeta;
    toggle: ToggleStyleMeta;
    buttonGroup: ButtonGroupStyleMeta;
    input: InputStyleMeta;
    radio: RadioButtonStyleMeta;
    checkBox: CheckBoxStyleMeta;
    modal: ModalStyleMeta;
    snackbar: SnackbarStyleMeta;
    toast: ToastStyleMeta;
    tooltip: TooltipStyleMeta;
    appbar: AppBarStyleMeta;
};

export type ComponentStyleMapping = {
    button: ButtonStyle;
    text: TextStyle;
    toggle: ToggleStyle;
    buttonGroup: ButtonGroupStyle;
    input: InputStyle;
    radio: RadioButtonStyle;
    checkBox: CheckBoxStyle;
    modal: ModalStyle;
    snackbar: SnackbarStyle;
    toast: ToastStyle;
    tooltip: TooltipStyle;
    appbar: AppBarStyle;
};

export type ComponentTypes = keyof ComponentStyleMapping;

export const getStyles = <T extends ComponentTypes, R extends ComponentStyleMapping[T]>(params: {
    type: T;
    meta: ComponentStyleMetaMapping[T];
    theme: Theme;
}): R => {
    const { type, meta, theme } = params;

    if (type === "button") {
        return getButtonStyles(meta as ButtonStyleMeta, theme) as R;
    } else if (type === "buttonGroup") {
        return getButtonGroupStyles(meta as ButtonGroupStyleMeta, theme) as R;
    } else if (type === "text") {
        return getTextStyles(meta as TextStyleMeta, theme) as R;
    } else if (type === "toggle") {
        return getToggleStyles(meta as ToggleStyleMeta, theme) as R;
    } else if (type === "input") {
        return getInputStyles(meta as InputStyleMeta, theme) as R;
    } else if (type === "radio") {
        return getRadioButtonStyles(meta as InputStyleMeta, theme) as R;
    } else if (type === "checkBox") {
        return getCheckBoxStyles(meta as CheckBoxStyleMeta, theme) as R;
    } else if (type === "modal") {
        return getModalStyles(meta as ModalStyleMeta, theme) as R;
    } else if (type === "snackbar") {
        return getSnackbarStyles(meta as SnackbarStyleMeta, theme) as R;
    } else if (type === "toast") {
        return getToastStyles(meta as ToastStyleMeta, theme) as R;
    } else if (type === "tooltip") {
        return getTooltipStyles(meta, theme) as R;
    } else if (type === "appbar") {
        return getAppBarStyles(meta, theme) as R;
    }

    throw new Error("provided unknown component type: " + type);
};
