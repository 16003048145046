import React, { memo, useCallback, useMemo } from "react";
import { Pressable, StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import format from "date-fns/format";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { CdnImage, InView } from "@swiggy-private/react-native-ui";
import { Text, useTheme, SpacingValue } from "@swiggy-private/rn-dls";

import { Conversation, User } from "../../interfaces";
import { useGetChatUserDetailsFromConversation } from "../../hooks/use-get-user-details";
import { useConversationMessageUnreadCount } from "../../hooks/use-conversation-unread";
import { getRandomAvatarColor } from "./helper";
import { useConversationIndicators } from "../../hooks/use-conversation-indicators";
import { useChatRenderer } from "../../hooks/use-chatrender";
import { formatAvatarUrl } from "../../helpers";
import { useChatInstrumentation } from "../../hooks/use-chat-instrumentation";

export type ChatConversationListItemViewProps = {
    conversation: Conversation;
    onConversationPress: (conversation: Conversation) => void;
    avatarPlaceholderImageId?: string;
    isGuestUser?: boolean;
};

const currentTimestamp = Date.now();
const DAY_IN_SECONDS = 86400;
const WEEK_IN_SECONDS = 604800;

interface ChatConversationListItemProps extends ChatConversationListItemViewProps {
    conversationUser: User | null;
    unreadCount: number;
    isUnread: boolean;
    isOnline: boolean;
}

const ChatConversationListItem: React.FC<ChatConversationListItemProps> = memo((props) => {
    const {
        conversation,
        onConversationPress,
        conversationUser,
        unreadCount,
        isUnread,
        isOnline,
        isGuestUser,
    } = props;
    const { value: theme } = useTheme();

    const TitleIcon = useChatRenderer()?.renderConversationListItemTitleIcon;

    const onItemClick = useCallback(
        (): void => onConversationPress(conversation),
        [conversation, onConversationPress],
    );

    const messageTime = useMemo(() => {
        if (!conversation.lastMessageTimestamp) {
            return null;
        }

        const diff = (currentTimestamp - conversation.lastMessageTimestamp) / 1_000;

        if (diff < DAY_IN_SECONDS) {
            // less than a day
            return format(conversation.lastMessageTimestamp, "h:mm a");
        } else if (diff < WEEK_IN_SECONDS) {
            // less than a week
            return format(conversation.lastMessageTimestamp, "EEEE");
        }

        return format(conversation.lastMessageTimestamp, "d/M/yyyy");
    }, [conversation.lastMessageTimestamp]);

    const avatarBgColor = useMemo(
        () => getRandomAvatarColor(conversationUser?.name),
        [conversationUser?.name],
    );

    const avatarStyle = useMemo(
        () => [
            styles.avatar,
            {
                borderColor: theme["color-basic-15"],
            },
        ],
        [theme],
    );

    /** Added to replace cloudinary with IK */
    const avatarUrl = formatAvatarUrl(conversationUser?.avatarUrl ?? "");

    const conversationTitleStyle = {
        flexShrink: TitleIcon ? 1 : 0,
    } as StyleProp<TextStyle>;

    const guestUserAvatarStyle = {
        backgroundColor: theme["color-basic-75"],
    } as ViewStyle;

    return (
        <Pressable onPress={onItemClick} style={styles.container}>
            <Stack spacing={SpacingValue["space-small"]} flex={1} direction="row">
                <Box style={styles.avatarContainer}>
                    {isGuestUser ? (
                        <Box style={[avatarStyle, guestUserAvatarStyle, styles.guestUser]}>
                            <SvgIcon
                                icon="AccountFilled"
                                width={20}
                                height={20}
                                color="color-basic-0"
                            />
                        </Box>
                    ) : avatarUrl && avatarUrl.length > 0 ? (
                        <CdnImage
                            id={avatarUrl}
                            width={AVATAR_SIZE}
                            height={AVATAR_SIZE}
                            style={[avatarStyle, { backgroundColor: theme["color-basic-5"] }]}
                        />
                    ) : (
                        <Box style={[avatarStyle, { backgroundColor: avatarBgColor }]}>
                            <Text
                                category="h2"
                                color="color-basic-0"
                                style={styles.avatarText}
                                adjustsFontSizeToFit={true}>
                                {(conversationUser?.name[0] ?? "M").toUpperCase()}
                            </Text>
                        </Box>
                    )}
                    {isOnline ? (
                        <View
                            style={[
                                styles.onlineIndicator,
                                { backgroundColor: theme["color-positive-400"] },
                            ]}
                        />
                    ) : null}
                </Box>

                <Stack direction="column" flex={1} spacing={SpacingValue["space-xxx-small"]}>
                    <Box direction="row" alignItems="center">
                        <Box
                            direction="row"
                            alignItems="center"
                            flex={1}
                            mr={SpacingValue["space-medium"]}>
                            <Text
                                category="b1"
                                weight="bold"
                                style={[styles.storeName, conversationTitleStyle]}
                                color="high"
                                numberOfLines={1}>
                                {conversationUser?.name ?? conversation.title}
                            </Text>

                            {TitleIcon ? <TitleIcon item={conversation} /> : null}
                        </Box>

                        {messageTime ? (
                            <Text category="b3" color="color-basic-45">
                                {messageTime}
                            </Text>
                        ) : null}
                    </Box>

                    <Box
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={[styles.separator, { borderBottomColor: theme["color-basic-5"] }]}>
                        <Text
                            style={styles.lastMessageText}
                            category={isUnread ? "b2" : "b3"}
                            weight={isUnread ? "bold" : "regular"}
                            color={isUnread ? "high" : "medium"}
                            numberOfLines={2}
                            ellipsizeMode="tail">
                            {conversation.lastMessageText || " "}
                        </Text>

                        {unreadCount > 0 ? (
                            <Box
                                style={[
                                    styles.unReadIndicator,
                                    { backgroundColor: theme["primary"] },
                                ]}>
                                <Text category="b2" weight="bold" color="color-basic-0">
                                    {unreadCount}
                                </Text>
                            </Box>
                        ) : null}
                    </Box>
                </Stack>
            </Stack>
        </Pressable>
    );
});

export const ChatConversationListItemView: React.FC<ChatConversationListItemViewProps> = memo(
    (props) => {
        const { conversation, onConversationPress } = props;

        const instrumentationCb = useChatInstrumentation()?.chatConversationListCb;
        const conversationUser = useGetChatUserDetailsFromConversation(conversation);
        const unreadCount = useConversationMessageUnreadCount(conversation.id) ?? 0;
        const conversationIndicators = useConversationIndicators(conversation.id);
        const isUnread = unreadCount > 0;

        const handleConversationPress = (): void => {
            instrumentationCb?.conversationListItemAnalyticClick?.(conversation, unreadCount);
            onConversationPress?.(conversation);
        };

        const trackConversationListItem = React.useCallback(
            (isVisible: boolean) => {
                isVisible &&
                    instrumentationCb?.conversationListItemAnalyticImpression?.(
                        conversation,
                        unreadCount,
                    );
            },
            [conversation, instrumentationCb, unreadCount],
        );

        return (
            <InView onChange={trackConversationListItem}>
                <ChatConversationListItem
                    {...props}
                    onConversationPress={handleConversationPress}
                    conversationUser={conversationUser}
                    unreadCount={unreadCount}
                    isUnread={isUnread}
                    isOnline={conversationIndicators.online || conversationUser?.online === true}
                />
            </InView>
        );
    },
);

if (__DEV__) {
    ChatConversationListItemView.displayName = "ChatConversationListItem";
    ChatConversationListItem.displayName = "ChatConversationListItem";
}

const AVATAR_SIZE = 44;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    unReadIndicator: {
        paddingHorizontal: SpacingValue["space-xx-small"],
        paddingVertical: SpacingValue["space-xxx-small"],
        minWidth: 20,
        minHeight: 20,
        borderRadius: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    onlineIndicator: {
        width: 16,
        height: 16,
        borderRadius: 16,
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        bottom: -4,
        right: -4,
        borderWidth: 2,
        borderColor: "#fff",
    },
    avatarContainer: {
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
    },
    avatar: {
        width: "100%",
        height: "100%",
        borderRadius: 12,
        borderWidth: 1,
    },
    avatarText: {
        textAlign: "center",
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
        fontSize: 24,
        lineHeight: 28,
        marginTop: 10,
    },
    separator: {
        paddingBottom: SpacingValue["space-large"],
        borderBottomWidth: 1,
    },
    lastMessageText: { flex: 1, marginRight: SpacingValue["space-medium"] },
    storeName: {
        textTransform: "capitalize",
    },
    guestUser: {
        justifyContent: "center",
        alignItems: "center",
        borderRadius: AVATAR_SIZE,
    },
});
