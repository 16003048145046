import React from "react";
import { StyleSheet } from "react-native";
import { ActivityIndicator, ActivityIndicatorProps } from "@swiggy-private/rn-dls";

export const ScreenLoader: React.FC<ActivityIndicatorProps> = ({ style, ...props }) => {
    return <ActivityIndicator style={[styles.container, style]} size="large" {...props} />;
};

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
