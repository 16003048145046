import * as React from "react";
import { StyleSheet, View, ViewProps } from "react-native";

import { TabsProps, Tabs } from "./tabs";
import { TabPanelProps, TabPanel } from "./tab-panel";
import { useControlled } from "../../hooks/use-controlled";

type TabsElement = React.ReactElement<TabsProps, typeof Tabs>;
type TabPanelElement = React.ReactElement<TabPanelProps, typeof TabPanel>;

interface TabViewProps extends ViewProps {
    /** The index of the currently selected Tab. */
    index?: number;
    /** Callback which is called on tab change, receives the index of the new tab as argument. */
    onIndexChange?: (index: number) => void;

    /** React.ReactNode[] added for dynamically adding tab panels, OPEN FOR DISCUSSION */
    children?: React.ReactElement | React.ReactElement[] | React.ReactNode[];
}

/**
 * ## Usage
 * ```ts
 * import * as React from "react";
 * import { TabView, Tabs, Tab, TabPanel, Text } from "@swiggy-private/rn-dls";
 *
 * const MyComponent: React.FC = () => {
 *     return (
 *         <TabView>
 *             <Tabs>
 *                 <Tab>Item 1</Tab>
 *                 <Tab>Item 2</Tab>
 *                 <Tab>Item 3</Tab>
 *             </Tabs>
 *             <TabPanel><Text>Item 1</Text></TabPanel>
 *             <TabPanel><Text>Item 2</Text></TabPanel>
 *             <TabPanel><Text>Item 3</Text></TabPanel>
 *         <TabView>
 *     );
 * };
 *```
 */
export const TabView: React.FC<TabViewProps> = ({
    index,
    onIndexChange,
    children,
    style,
    ...props
}) => {
    const [currentIndex, setCurrentIndex] = useControlled(index, index ?? 0);
    const onTabChange = React.useCallback(
        (i: number) => {
            setCurrentIndex(i);
            onIndexChange?.(i);
        },
        [onIndexChange, setCurrentIndex],
    );

    const tabs = React.Children.toArray(children).find(
        (c) => React.isValidElement(c) && c.type === Tabs,
    ) as TabsElement | undefined;

    const tabPanels = React.Children.toArray(children).filter(
        (c) => React.isValidElement(c) && c.type === TabPanel,
    ) as TabPanelElement[];

    return (
        <View {...props} style={[styles.container, style]}>
            {tabs
                ? React.cloneElement(tabs, {
                      value: currentIndex,
                      onChange: onTabChange,
                  })
                : null}
            <View style={styles.panel}>
                {tabPanels.map((tabPanel, i) =>
                    React.cloneElement(tabPanel, {
                        hidden: i !== currentIndex,
                    }),
                )}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    panel: {
        flex: 1,
    },
});
