import { createContext, useContext } from "react";

export interface IPortalContext {
    addElement: (
        element: React.ReactElement,
        key?: string,
    ) => [(element: React.ReactElement) => void, () => void]; // [updateElement, removeElement]
}

export const PortalContext = createContext<IPortalContext | null>(null);

export const usePortal = (): IPortalContext["addElement"] => {
    const ctx = useContext(PortalContext);
    if (!ctx) {
        throw new Error("Missing Portal context");
    }

    return ctx.addElement;
};
