import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMountedRef } from "@swiggy-private/react-hooks";
import {
    IChatService,
    SdkConversation,
    SdkFetchMessagesResponse,
    SdkListConversationsResponse,
} from "@swiggy-private/connect-chat-sdk";
import { useFocusEffect } from "@react-navigation/core";

import { useChatState } from "./use-chatstate";
import { useChatDispatch } from "./use-chatdispatch";
import { useChatSdk } from "./use-chatsdk";
import { Message, User } from "../interfaces/types";
import { useFetchRecentMessages, useFetchRecentMessagesFromDb } from "./use-fetch-recent-messages";
import { logError } from "../helpers/log";
import { useChatService } from "./use-chat-service";
import { AppState, InteractionManager } from "react-native";
import { ChatAction } from "../reducers/chat";

type UseFetchConversationsReturnValue = [
    Array<SdkConversation>,
    {
        loading: boolean;
        fetch: (next?: boolean) => Promise<void>;
        error?: Error | null;
    },
];

const useProcessConversations = (): ((newConversations: SdkConversation[]) => void) => {
    const dispatch = useChatDispatch();

    return useCallback(
        (newConversations: SdkConversation[]) => {
            const users = newConversations.reduce((acc, cur) => {
                acc.push(...cur.participants);
                return acc;
            }, [] as User[]);

            dispatch({
                type: "MULTI_DISPATCH",
                payload: [
                    // add conversations
                    {
                        type: "ADD_CONVERSATION_ACTION",
                        payload: {
                            conversations: newConversations,
                        },
                    },
                    // add users
                    {
                        type: "ADD_USER_ACTION",
                        payload: {
                            users,
                        },
                    },
                ],
            });
        },
        [dispatch],
    );
};

const useProcessMessages = (): ((response?: SdkFetchMessagesResponse | void) => void) => {
    const dispatch = useChatDispatch();

    return useCallback(
        (response?: SdkFetchMessagesResponse | void) => {
            const actions: ChatAction[] = [];

            Object.keys(response?.conversations || []).forEach((cId) => {
                const messages = response?.conversations[cId] || [];
                if (!messages.length) {
                    return;
                }

                const preparedMessages: Message[] = messages.map((message) => ({
                    ...message.message,
                    publisher: message.publisher,
                    timetoken: message.timetoken,
                    actions: message.actions,
                }));

                actions.push({
                    type: "ADD_CONVERSATION_MESSAGE_ACTION",
                    payload: {
                        conversationId: cId,
                        messages: preparedMessages,
                    },
                });
            });

            if (actions.length > 0) {
                dispatch({
                    type: "MULTI_DISPATCH",
                    payload: actions,
                });
            }
        },
        [dispatch],
    );
};

const saveConversationsInDb = async (
    response: SdkListConversationsResponse | void,
    dbService?: IChatService,
): Promise<void> => {
    for await (const conversation of response?.conversations || []) {
        await dbService?.updateConversation(conversation).catch(logError);
    }
};

const saveMessagesInDb = async (
    response: SdkFetchMessagesResponse,
    dbService?: IChatService,
): Promise<void> => {
    if (!dbService || !response.conversations || !Object.keys(response.conversations).length) {
        return;
    }

    const conversationIds = Object.keys(response.conversations);
    const conversationLastReadTime = await dbService.getConversationLastReadTime(conversationIds);

    for await (const conversationId of conversationIds) {
        const messages = response?.conversations[conversationId] || [];
        if (!messages.length) {
            return;
        }

        for await (const message of messages) {
            const unread =
                (conversationLastReadTime[conversationId] || 0) < message.message.timestamp;

            await dbService
                .putMessage({
                    conversationId: conversationId,
                    publisher: message.publisher,
                    timetoken: String(message.timetoken),
                    message: message.message,
                    unread,
                })
                .catch(logError);
        }
    }
};

const useFetchConversationsFromDb = (): ((storeId?: string) => Promise<void>) => {
    const mountedRef = useMountedRef();
    const processConversations = useProcessConversations();
    const processMessages = useProcessMessages();
    const fetchMessagesFromDb = useFetchRecentMessagesFromDb();
    const chatService = useChatService();

    return useCallback(
        async (storeId?: string): Promise<void> => {
            if (!mountedRef.current) {
                return;
            }

            const dbConversations = await chatService
                ?.findConversations({ storeId, limit: 15 })
                .catch(logError);

            if (
                Array.isArray(dbConversations) &&
                dbConversations.length > 0 &&
                mountedRef.current
            ) {
                const conversationIds = dbConversations.map((c) => c.id);
                const messages = await fetchMessagesFromDb(conversationIds).catch(logError);

                if (mountedRef.current) {
                    processConversations(dbConversations);
                    processMessages(messages);
                }
            }
        },
        [chatService, fetchMessagesFromDb, mountedRef, processConversations, processMessages],
    );
};

const useFetchConversationsFromNetwork = (
    limit = 15,
): ((nextPage?: string) => Promise<SdkListConversationsResponse | void>) => {
    const chatSdk = useChatSdk();
    const fetchMessages = useFetchRecentMessages();
    const fetchMessagesFromDb = useFetchRecentMessagesFromDb();

    const mountedRef = useMountedRef();
    const processConversations = useProcessConversations();
    const processMessages = useProcessMessages();

    return useCallback(
        async (nextPage?: string): Promise<SdkListConversationsResponse | void> => {
            if (!mountedRef.current) {
                return;
            }

            const response = await chatSdk?.listConversations({ nextPage, limit });
            if (!mountedRef.current || !response) {
                return;
            }

            processConversations(response.conversations);
            saveConversationsInDb(response);

            const conversationIds = response.conversations.map((c) => c.id);
            const promiseDb = fetchMessagesFromDb(conversationIds)
                .then((messages) => {
                    if (mountedRef.current) {
                        processMessages(messages);
                    }
                })
                .catch(logError);

            const promiseNetwork = fetchMessages(conversationIds)
                .then((messages) => {
                    if (mountedRef.current) {
                        processMessages(messages);
                        saveMessagesInDb(messages).catch(logError);
                    }
                })
                .catch(logError);

            await Promise.all([promiseDb, promiseNetwork]);

            return response;
        },
        [
            chatSdk,
            fetchMessages,
            fetchMessagesFromDb,
            limit,
            mountedRef,
            processConversations,
            processMessages,
        ],
    );
};

const MINIMUM_FETCH_INTERVAL = 15_000;

export const useFetchRecentConversations = (): void => {
    const lastFetchTime = useRef(0);
    const getConversationsFromNetwork = useFetchConversationsFromNetwork(5);

    if (lastFetchTime.current === 0) {
        lastFetchTime.current = Date.now();
    }

    const fetch = useCallback(async () => {
        if (AppState.currentState !== "active") {
            return;
        }

        const time = Date.now();
        if (lastFetchTime.current + MINIMUM_FETCH_INTERVAL < time) {
            lastFetchTime.current = time;
            await getConversationsFromNetwork().catch(logError);
        }
    }, [getConversationsFromNetwork]);

    useFocusEffect(
        useCallback(() => {
            const timer = setInterval(fetch, MINIMUM_FETCH_INTERVAL / 2);
            const task = InteractionManager.runAfterInteractions(fetch);

            return () => {
                task.cancel();
                clearInterval(timer);
            };
        }, [fetch]),
    );
};

export const useFetchConversations = (storeId?: string): UseFetchConversationsReturnValue => {
    const chatSdk = useChatSdk();
    const mountedRef = useMountedRef();

    const state = useChatState();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    const initRef = useRef(false);
    const nextPageParamsRef = useRef<string>();
    const callProgress = useRef(false);
    const conversations = useMemo(() => Object.values(state.conversations), [state.conversations]);

    const onSuccess = useCallback((response: SdkListConversationsResponse | void) => {
        nextPageParamsRef.current = response?.nextPage;
        setLoading(false);
    }, []);

    const getConversationsFromDb = useFetchConversationsFromDb();
    const getConversationsFromNetwork = useFetchConversationsFromNetwork();

    const fetchConversations = useCallback(
        async (nextPage?: string) => {
            if (!chatSdk) {
                return;
            }

            initRef.current = true;
            setLoading(true);
            setError(null);

            if (!nextPage) {
                await getConversationsFromDb(storeId);
            }

            const response = await getConversationsFromNetwork(nextPage);

            onSuccess(response);
        },
        [chatSdk, getConversationsFromDb, getConversationsFromNetwork, onSuccess, storeId],
    );

    const fetch = useCallback(
        async (next?: boolean) => {
            if (!mountedRef.current || callProgress.current) {
                return Promise.resolve();
            }

            // no more pages to fetch
            if (next && !nextPageParamsRef.current) {
                return Promise.resolve();
            }

            callProgress.current = true;

            try {
                await fetchConversations(next ? nextPageParamsRef.current : undefined);
            } catch (err) {
                if (mountedRef.current) {
                    setLoading(false);
                    setError(err as Error);
                    logError(err);
                }
            }

            callProgress.current = false;
        },
        [fetchConversations, mountedRef],
    );

    useEffect(() => {
        if (chatSdk && !initRef.current) {
            fetch();
        }
    }, [chatSdk, fetch]);

    return [conversations, { loading, error, fetch }];
};
