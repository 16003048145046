import React, { useMemo } from "react";
import { StyleSheet, View, ViewProps, ViewStyle } from "react-native";

import { ScreenSizeProp } from "../../interfaces/screen";
import { useScreenSize } from "../../hooks/use-screen-size";

export interface StackProps extends ViewProps {
    /** Defines the space between immediate children. */
    spacing?: ScreenSizeProp<number>;
    /** Defines the flex-direction style property. */
    direction?: ScreenSizeProp<ViewStyle["flexDirection"]> | ViewStyle["flexDirection"];
    /** Add an element between each child. */
    divider?: React.ReactNode | React.FC<{ index: number }>;
    /** Adds spacing after divider */
    dividerSpacing?: boolean;
    justifyContent?: ViewStyle["justifyContent"];
    alignItems?: ViewStyle["alignItems"];
    flex?: ViewStyle["flex"];
    flexGrow?: number;
    flexShrink?: number;
    children?: React.ReactNode | React.ReactNode[];
}

export const Stack: React.FC<StackProps> = ({
    spacing,
    direction: flexDirection = "column",
    style: propStyle,
    alignItems,
    justifyContent,
    divider,
    flex,
    flexShrink,
    flexGrow,
    children,
    dividerSpacing = false,
    ...props
}) => {
    const screenSize = useScreenSize();
    const childrens = useMemo(
        () => React.Children.toArray(children).filter(React.isValidElement),
        [children],
    );

    if (!childrens.length) {
        return null;
    }

    const _flexDirection =
        typeof flexDirection === "string"
            ? flexDirection
            : flexDirection[screenSize] ?? flexDirection.default ?? "column";

    const style = {
        flex,
        flexDirection: _flexDirection,
        alignItems,
        justifyContent,
        flexShrink,
        flexGrow,
    };

    const _spacing =
        typeof spacing === "number" ? spacing : spacing?.[screenSize] ?? spacing?.default ?? 0;

    const spacer = _spacing ? <Spacer spacing={_spacing} direction={_flexDirection} /> : null;

    return (
        <View style={StyleSheet.compose(style, propStyle)} {...props}>
            {childrens.map((child, index) =>
                index === childrens.length - 1 ? (
                    <React.Fragment key={child.key ?? index}>{child}</React.Fragment>
                ) : (
                    <React.Fragment key={child.key ?? index}>
                        {child}
                        {spacer}
                        {typeof divider === "function" ? divider({ index }) : divider}
                        {dividerSpacing && divider && spacer ? spacer : null}
                    </React.Fragment>
                ),
            )}
        </View>
    );
};

export const Spacer: React.FC<{ spacing: number; direction?: ViewStyle["flexDirection"] }> = ({
    spacing,
    direction = "column",
}) => {
    if (direction === "column" || direction === "column-reverse") {
        return <View style={{ height: spacing }} collapsable={false} />;
    }

    return <View style={{ width: spacing }} collapsable={false} />;
};
