import { CustomMessageTypes } from "../interfaces";

export const MESSAGE_SHORT_DESCRIPTION: {
    [key in CustomMessageTypes]?: string;
} = {
    productShare: "Shared a product",
    productEnquire: "Enquired about a product",
    imageEnquire: "Sent a photo",
    customProductEnquire: "Enquired about a product",
    orderUpdate: "Order update",
};
