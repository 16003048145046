import React, { createContext } from "react";
import { useChatConversationReducer } from "../reducers/conversation";

type ChatConversationReducerType = ReturnType<typeof useChatConversationReducer>;
type State = ChatConversationReducerType[0];
type Dispatch = ChatConversationReducerType[1];

export const ChatConversationStateContext = createContext<State | null>(null);

// eslint-disable-next-line no-void
export const ChatConversationDispatchContext = createContext<Dispatch>(() => void 0);

export const ChatConversationStateProvider: React.FC<
    React.PropsWithChildren<{ initialState: State }>
> = ({ initialState, children }) => {
    const [state, dispatch] = useChatConversationReducer(initialState);

    return (
        <ChatConversationStateContext.Provider value={state}>
            <ChatConversationDispatchContext.Provider value={dispatch}>
                {children}
            </ChatConversationDispatchContext.Provider>
        </ChatConversationStateContext.Provider>
    );
};
