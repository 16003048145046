import React from "react";

import { StoreAddressType as StoreAddress } from "../../../interfaces";
import { removeSpaces } from "../../../helpers";

import { ADDRESS_FORM_CONSTS } from "../constants";

const MAX_PINCODE_LENGTH = 6;

type Props = {
    completeAddress: string;
    pincode: string;
    addressLabel: string;
    landmark: string;
    instructions: string;
    latitudeValue: number;
    longitudeValue: number;
    city: string;
    addressState: string;
    item?: StoreAddress | null;
};

export const useBtnState = ({
    completeAddress,
    pincode,
    addressLabel,
    landmark,
    instructions,
    latitudeValue,
    longitudeValue,
    item,
    city,
    addressState,
}: Props): [boolean, string] => {
    const [disabled, setDisabled] = React.useState(true);
    const [btnText, setBtnText] = React.useState(
        item ? ADDRESS_FORM_CONSTS.BUTTON.UPDATE_LABEL : ADDRESS_FORM_CONSTS.BUTTON.ACTIVE_LABEL,
    );

    const isEqual = Boolean(
        item &&
            item?.address === completeAddress &&
            item?.postalCode?.toString() === pincode &&
            item?.annotation === addressLabel &&
            item?.landmark === landmark &&
            item?.instructions === instructions &&
            Number(item?.lat) === latitudeValue &&
            Number(item?.lng) === longitudeValue &&
            item?.city === city &&
            item?.state === addressState,
    );

    React.useEffect(() => {
        if (
            removeSpaces(completeAddress).length &&
            removeSpaces(pincode).length === MAX_PINCODE_LENGTH &&
            removeSpaces(addressLabel).length &&
            removeSpaces(city).length &&
            removeSpaces(addressState).length
        ) {
            if (isEqual) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
            setBtnText(
                item
                    ? ADDRESS_FORM_CONSTS.BUTTON.UPDATE_LABEL
                    : ADDRESS_FORM_CONSTS.BUTTON.ACTIVE_LABEL,
            );
        } else {
            setDisabled(true);
            setBtnText(
                item
                    ? ADDRESS_FORM_CONSTS.BUTTON.UPDATE_LABEL
                    : ADDRESS_FORM_CONSTS.BUTTON.ACTIVE_LABEL,
            );
        }
    }, [addressLabel, addressState, city, completeAddress, isEqual, item, pincode]);

    return [disabled, btnText];
};
