import React, { useMemo } from "react";
import { ImageProps, ImageStyle, StyleSheet, TextStyle, View, ViewStyle } from "react-native";
import memoizeOne from "memoize-one";
import { Elevation } from "@swiggy-private/rn-dls-theme";

import { useDLS } from "../../styles/style-service";
import { useEventHandlers } from "../../support/use-event-handlers";
import { FalsyFC, RenderProp } from "../../support/falsy-fc";
import { FalsyText } from "../../support/falsy-text";
import { TouchableComponent, TouchableComponentProps } from "../../support/touchable-component";
import type { TextProps } from "../text";
import type { StyledComponentProps } from "../../styles/styled";
import type { ButtonColor, ButtonStyle } from "../../styles/interfaces/button";

export interface ButtonProps
    extends StyledComponentProps,
        Omit<TouchableComponentProps, "children"> {
    children?: RenderProp<TextProps> | string | number;
    /**
     * @default "primary"
     */
    color?: ButtonColor;
    subText?: RenderProp<TextProps> | string | number;
    accessoryLeft?: RenderProp<Partial<ImageProps>>;
    accessoryRight?: RenderProp<Partial<ImageProps>>;
    accessoryAlignment?: "end" | "center";
    /**
     * @default false
     */
    disableElevation?: boolean;
    elevation?: Elevation;
    textContainerStyle?: ViewStyle;
    textStyle?: TextStyle;
}

export type ButtonElement = React.ReactElement<ButtonProps>;

const getComponentStyle = memoizeOne(
    (
        source: ButtonStyle,
        accessoryAlignment: ButtonProps["accessoryAlignment"],
    ): {
        text: TextStyle;
        subText: TextStyle;
        container: ViewStyle;
        iconLeft: ImageStyle;
        iconRight: ImageStyle;
        textContainer: ViewStyle;
    } => {
        const {
            textColor,
            textFontFamily,
            textFontSize,
            textMarginHorizontal,
            textLetterSpacing,
            textLineHeight,
            iconHeight,
            iconWidth,
            iconMarginHorizontal,
            iconTintColor,
            subTextColor,
            subTextFontFamily,
            subTextFontSize,
            subTextLetterSpacing,
            subTextLineHeight,
            subTextMarginVertical,
            ...containerProps
        } = source;

        return {
            textContainer: {
                marginHorizontal: textMarginHorizontal,
            },
            text: {
                letterSpacing: textLetterSpacing,
                lineHeight: textLineHeight,
                color: textColor,
                fontFamily: textFontFamily,
                fontSize: textFontSize,
            },
            subText: {
                letterSpacing: subTextLetterSpacing,
                lineHeight: subTextLineHeight,
                color: subTextColor,
                fontFamily: subTextFontFamily,
                fontSize: subTextFontSize,
                marginVertical: subTextMarginVertical,
            },
            iconLeft: {
                width: iconWidth,
                height: iconHeight,
                tintColor: iconTintColor,
                marginRight: iconMarginHorizontal,
                marginLeft: accessoryAlignment === "center" ? iconMarginHorizontal : 0,
            },
            iconRight: {
                width: iconWidth,
                height: iconHeight,
                tintColor: iconTintColor,
                marginLeft: iconMarginHorizontal,
                marginRight: accessoryAlignment === "center" ? iconMarginHorizontal : 0,
            },
            container: {
                ...containerProps,
                justifyContent: accessoryAlignment === "end" ? "space-between" : "center",
            },
        };
    },
);

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 * Default button size is `medium` and color is `primary`.
 *
 * ## Usage
 * ```js
 * import * as React from "react";
 * import { Button } from "@swiggy-private/rn-dls";
 *
 * export const MyComponent: React.FC = () => (
 *   <Button onPress={() => console.log('Pressed')}>
 *     Press me
 *   </Button>
 * );
 * ```
 *
 * @param props {@link ButtonProps}
 */
export const Button: React.FC<ButtonProps> = (props) => {
    const {
        children,
        subText,
        style,
        accessoryLeft,
        accessoryRight,
        color,
        size,
        state,
        accessoryAlignment,
        disableElevation,
        elevation = 4,
        textContainerStyle,
        textStyle,
        ...otherProps
    } = props;

    const { theme, ...dls } = useDLS("button", props);
    const componentStyle = useMemo(
        () => getComponentStyle(dls.style, accessoryAlignment),
        [dls.style, accessoryAlignment],
    );
    const eventHandlers = useEventHandlers(otherProps, dls.dispatch, otherProps.disabled);

    const elevationStyle = disableElevation ? null : theme.value.elevations[elevation];
    const containerStyle = StyleSheet.compose(
        [componentStyle.container, styles.container, elevationStyle],
        style,
    );

    const buttonTextStyle = StyleSheet.compose([componentStyle.text], textStyle);

    return (
        <TouchableComponent
            accessibilityRole="button"
            {...otherProps}
            {...eventHandlers}
            style={containerStyle}>
            <FalsyFC component={accessoryLeft} style={componentStyle.iconLeft} />
            <View style={StyleSheet.compose(styles.textContainer, textContainerStyle)}>
                <FalsyText
                    style={buttonTextStyle}
                    component={children}
                    selectable={false}
                    numberOfLines={1}
                />
                <FalsyText
                    style={componentStyle.subText}
                    component={subText}
                    selectable={false}
                    numberOfLines={1}
                />
            </View>
            <FalsyFC component={accessoryRight} style={componentStyle.iconRight} />
        </TouchableComponent>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    textContainer: {
        alignItems: "center",
        justifyContent: "center",
    },
});

Button.defaultProps = {
    accessoryAlignment: "center",
};
