import React, { useState, useEffect } from "react";
import { View, TouchableOpacity, StyleSheet } from "react-native";

import { Text, SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { usePrevious } from "./helpers/StateHelpers";
import { IUserStory, StoryCircleListItemProps } from "./interfaces";

const AVATAR_SIZE = 72;

const StoryCircleListItemComponent: React.FC<StoryCircleListItemProps> = ({
    item,
    unPressedBorderColor,
    pressedBorderColor,
    unPressedAvatarTextColor,
    pressedAvatarTextColor,
    avatarSize = AVATAR_SIZE,
    showText,
    avatarTextStyle,
    handleStoryItemPress,
    avatarImageStyle,
    avatarWrapperStyle,
    index,
}: StoryCircleListItemProps) => {
    const { value: theme } = useTheme();
    const [isPressed, setIsPressed] = useState(item?.seen);

    const prevSeen = usePrevious(item?.seen);

    useEffect(() => {
        if (prevSeen !== item?.seen) {
            setIsPressed(item?.seen);
        }
    }, [item?.seen]);

    const _handleItemPress = (highlight: IUserStory): void => {
        if (handleStoryItemPress) {
            handleStoryItemPress(highlight);
        }

        setIsPressed(true);
    };

    const avatarWrapperSize = avatarSize + 8;
    const containerMargin = index === 0 ? SpacingValue["space-small"] : 0;
    const circleStyle = !isPressed
        ? {
              borderColor: unPressedBorderColor ?? theme["color-basic-30"],
          }
        : {
              borderColor: pressedBorderColor ?? theme["color-basic-30"],
              ...styles.pressed,
          };
    return (
        <View style={[styles.container, { marginLeft: containerMargin }]}>
            <TouchableOpacity
                onPress={() => _handleItemPress(item)}
                style={[
                    styles.avatarWrapper,
                    {
                        height: avatarWrapperSize,
                        width: avatarWrapperSize,
                    },
                    circleStyle,
                    avatarWrapperStyle,
                ]}>
                <CdnImage
                    isImageKitEnabled
                    width={avatarSize}
                    height={avatarSize}
                    style={[
                        {
                            height: avatarSize,
                            width: avatarSize,
                        },
                        styles.image,
                        avatarImageStyle,
                    ]}
                    id={(item.user_image || item.stories[0].story_image) ?? ""}
                    showLoader
                />
            </TouchableOpacity>
            {showText && (
                <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    category="b3"
                    weight="medium"
                    style={[
                        {
                            width: avatarWrapperSize + 8,
                            ...styles.text,
                            ...avatarTextStyle,
                        },
                        isPressed
                            ? { color: pressedAvatarTextColor || undefined }
                            : { color: unPressedAvatarTextColor || undefined },
                    ]}>
                    {item.user_name}
                </Text>
            )}
        </View>
    );
};

export const StoryCircleListItem = React.memo(StoryCircleListItemComponent);

const styles = StyleSheet.create({
    container: {
        alignItems: "center",
    },
    avatarWrapper: {
        borderWidth: 2,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        borderColor: "red",
        borderRadius: 100,
        marginHorizontal: SpacingValue["space-small"],
    },
    image: {
        borderRadius: 100,
    },
    pressed: {
        borderWidth: 1,
    },
    text: {
        marginTop: SpacingValue["space-x-small"],
        textAlign: "center",
        alignItems: "center",
    },
});
