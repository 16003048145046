import React from "react";
import { useWindowDimensions, ImageStyle } from "react-native";

import { CdnImage } from "@swiggy-private/react-native-ui";

type Props = {
    imgStyle?: ImageStyle;
    imgId?: string;
};

export const CalloutMarker: React.FC<Props> = ({ imgStyle, imgId }) => {
    const { width: screenWidth } = useWindowDimensions();

    const imgWidth = React.useMemo(() => Math.min(227, screenWidth * 0.64), [screenWidth]);
    const imageHeight = React.useMemo(() => imgWidth * 0.28, [imgWidth]);

    return (
        <CdnImage
            id={imgId || "minis/map_pickup_marker"}
            width={imgWidth}
            height={imageHeight}
            style={imgStyle}
        />
    );
};
