import React, { FC, memo } from "react";
import { StyleSheet } from "react-native";

import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { PRODUCT_IMG_FALLBACK } from "../item-images/constants";
import { CdnImage } from "../cdn-image";

interface OrderItemSummaryProps {
    productName: string;
    quantity: number;
    price: number;

    imageId?: string;
    variantText?: string;
    discountedPrice?: number;
}

const OrderItemSummaryComponent: FC<OrderItemSummaryProps> = ({
    imageId,
    productName,
    variantText,
    quantity,
    price,
    discountedPrice,
}) => {
    const { value: theme } = useTheme();

    return (
        <Stack direction="row" alignItems="center">
            <Box mr={SpacingValue["space-x-small"]}>
                <CdnImage id={imageId || PRODUCT_IMG_FALLBACK} style={styles.image} />
            </Box>

            <Box flex={1}>
                <Text category="b2" color="high" numberOfLines={2} ellipsizeMode={"tail"}>
                    {productName}
                    {variantText ? `${variantText}` : ""}
                </Text>
            </Box>

            <Box
                justifyContent="center"
                mh={SpacingValue["space-large"]}
                style={[styles.quantity, { backgroundColor: theme["color-basic-5"] }]}>
                <Text category="b2" color="high" weight="medium">
                    x {quantity}
                </Text>
            </Box>

            <Stack alignItems="flex-end" justifyContent="center">
                <Text
                    category="b2"
                    color={discountedPrice ? "color-basic-45" : "color-basic-75"}
                    style={discountedPrice ? styles.strikeThroughPrice : null}>
                    ₹{price}
                </Text>

                {discountedPrice ? (
                    <Text category="b2" color="high">
                        ₹{discountedPrice}
                    </Text>
                ) : null}
            </Stack>
        </Stack>
    );
};

export const OrderItemSummary = memo(OrderItemSummaryComponent);

const styles = StyleSheet.create({
    quantity: {
        height: 24,
        borderRadius: SpacingValue["space-xx-small"],
        paddingHorizontal: SpacingValue["space-small"],
        paddingVertical: SpacingValue["space-xx-small"],
    },
    mt16: {
        marginTop: SpacingValue["space-medium"],
    },
    textUppercase: {
        textTransform: "uppercase",
    },
    strikeThroughPrice: {
        textDecorationLine: "line-through",
    },
    image: {
        width: 32,
        height: 32,
        borderRadius: 4,
    },
});
