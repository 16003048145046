import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { User } from "../interfaces";
import { useChatState } from "./use-chatstate";
import { useChatUserId } from "./use-chatuserid";

const useGetChatUserDetails = (id: string): User | null => {
    const state = useChatState();
    return state.users[id] ?? null;
};

export const useGetChatUserDetailsFromConversation = (
    conversation: SdkConversation | null,
): User | null => {
    const chatUserId = useChatUserId();
    const conversationUser =
        chatUserId == null || conversation == null
            ? null
            : conversation.participants.filter((p) => p.id !== chatUserId)[0] || null;

    return useGetChatUserDetails(conversationUser?.id ?? "0");
};
