/**
 * This is an auto generated file
 * Do not edit manually
 * Use code-gen script to rebuild the file
 */

export { default as AcceptedTag } from "../assets/accepted-tag.svg";
export { default as AccountFilled } from "../assets/account-filled.svg";
export { default as Account } from "../assets/account.svg";
export { default as AddItem } from "../assets/add-item.svg";
export { default as AddToCart } from "../assets/add-to-cart.svg";
export { default as AlertCircle } from "../assets/alert-circle.svg";
export { default as Analytics } from "../assets/analytics.svg";
export { default as Appearance } from "../assets/appearance.svg";
export { default as ArrowDownFilled } from "../assets/arrow-down-filled.svg";
export { default as ArrowDown } from "../assets/arrow-down.svg";
export { default as ArrowLeftFilled } from "../assets/arrow-left-filled.svg";
export { default as ArrowLeft } from "../assets/arrow-left.svg";
export { default as ArrowRightFilled } from "../assets/arrow-right-filled.svg";
export { default as ArrowRight } from "../assets/arrow-right.svg";
export { default as ArrowUpFilled } from "../assets/arrow-up-filled.svg";
export { default as ArrowUp } from "../assets/arrow-up.svg";
export { default as Attach } from "../assets/attach.svg";
export { default as BagEmptyFilled } from "../assets/bag-empty-filled.svg";
export { default as BagFullFilled } from "../assets/bag-full-filled.svg";
export { default as BagEmpty } from "../assets/bag_empty.svg";
export { default as BagFull } from "../assets/bag_full.svg";
export { default as Bin } from "../assets/bin.svg";
export { default as Bio } from "../assets/bio.svg";
export { default as Block } from "../assets/block.svg";
export { default as BoltFilled } from "../assets/bolt-filled.svg";
export { default as Book } from "../assets/book.svg";
export { default as Breakdown } from "../assets/breakdown.svg";
export { default as Calendar } from "../assets/calendar.svg";
export { default as CalendarNew } from "../assets/calendar_new.svg";
export { default as CameraPlus } from "../assets/camera-plus.svg";
export { default as Camera } from "../assets/camera.svg";
export { default as CancelledTag } from "../assets/cancelled-tag.svg";
export { default as CartFilled } from "../assets/cart_filled.svg";
export { default as CartsActive } from "../assets/carts-active.svg";
export { default as CartsInactive } from "../assets/carts-inactive.svg";
export { default as Cash } from "../assets/cash.svg";
export { default as Catalog } from "../assets/catalog.svg";
export { default as CatalogFilled } from "../assets/catalog_filled.svg";
export { default as CatalogOutline } from "../assets/catalog_outline.svg";
export { default as Category } from "../assets/category.svg";
export { default as ChatInactive } from "../assets/chat-inactive.svg";
export { default as ChatActive } from "../assets/chat-active.svg";
export { default as Chat } from "../assets/chat.svg";
export { default as ChatFilled } from "../assets/chat_filled.svg";
export { default as ChatFilledNew } from "../assets/chat_filled_new.svg";
export { default as ChatOutline } from "../assets/chat_outline.svg";
export { default as ChevronDownFilled } from "../assets/chevron-down-filled.svg";
export { default as ChevronDownWithCustomBg } from "../assets/chevron-down-with-custom-bg.svg";
export { default as ChevronDown } from "../assets/chevron-down.svg";
export { default as ChevronLeftFilled } from "../assets/chevron-left-filled.svg";
export { default as ChevronLeft } from "../assets/chevron-left.svg";
export { default as ChevronRightFilled } from "../assets/chevron-right-filled.svg";
export { default as ChevronRight } from "../assets/chevron-right.svg";
export { default as ChevronUpFilled } from "../assets/chevron-up-filled.svg";
export { default as ChevronUp } from "../assets/chevron-up.svg";
export { default as CircleFilled } from "../assets/circle-filled.svg";
export { default as Close } from "../assets/close.svg";
export { default as ColorPicker } from "../assets/color-picker.svg";
export { default as ColumnView } from "../assets/column-view.svg";
export { default as CompletedTag } from "../assets/completed-tag.svg";
export { default as ConfirmedTag } from "../assets/confirmed-tag.svg";
export { default as ConsumerStoreFilled } from "../assets/consumer-store-filled.svg";
export { default as ConsumerStoreOutline } from "../assets/consumer-store-outline.svg";
export { default as CopyOutline } from "../assets/copy-outline.svg";
export { default as CouponFilled } from "../assets/coupon-filled.svg";
export { default as CouponOutline } from "../assets/coupon-outline.svg";
export { default as Crop } from "../assets/crop.svg";
export { default as CrossFilled } from "../assets/cross-filled.svg";
export { default as CustomItem } from "../assets/custom-item.svg";
export { default as DeliveredTag } from "../assets/delivered-tag.svg";
export { default as DeliveryCharges } from "../assets/delivery-charges.svg";
export { default as Diamond } from "../assets/diamond.svg";
export { default as DigitalProduct } from "../assets/digital-product.svg";
export { default as DoubleTick } from "../assets/double_tick.svg";
export { default as DownloadBill } from "../assets/download-bill.svg";
export { default as Download } from "../assets/download.svg";
export { default as Edit } from "../assets/edit.svg";
export { default as Emoji } from "../assets/emoji.svg";
export { default as Expand } from "../assets/expand.svg";
export { default as ExploreFilled } from "../assets/explore-filled.svg";
export { default as ExploreOutline } from "../assets/explore-outline.svg";
export { default as External } from "../assets/external.svg";
export { default as EyeHideOutline } from "../assets/eye-hide-outline.svg";
export { default as Eye } from "../assets/eye.svg";
export { default as Faqs } from "../assets/faqs.svg";
export { default as File } from "../assets/file.svg";
export { default as Filter } from "../assets/filter.svg";
export { default as Follow } from "../assets/follow.svg";
export { default as Followed } from "../assets/followed.svg";
export { default as FoodCard } from "../assets/food_card.svg";
export { default as ForPickupTag } from "../assets/for-pickup-tag.svg";
export { default as FreeOutline } from "../assets/free-outline.svg";
export { default as FriendsAndFamilyFilled } from "../assets/friends-and-family-filled.svg";
export { default as FriendsAndFamily } from "../assets/friends-and-family.svg";
export { default as GalleryAdd } from "../assets/gallery-add.svg";
export { default as Gallery } from "../assets/gallery.svg";
export { default as Gift } from "../assets/gift.svg";
export { default as GlitterStars } from "../assets/glitter-stars.svg";
export { default as Globe } from "../assets/globe.svg";
export { default as GoogleForms } from "../assets/google_forms.svg";
export { default as GridView } from "../assets/grid-view.svg";
export { default as Guide } from "../assets/guide.svg";
export { default as HamMenu } from "../assets/ham_menu.svg";
export { default as Heart } from "../assets/heart.svg";
export { default as HeartCircleFilled } from "../assets/heart_circle_filled.svg";
export { default as HeartFilled } from "../assets/heart_filled.svg";
export { default as Help } from "../assets/help.svg";
export { default as Highlight } from "../assets/highlight.svg";
export { default as History } from "../assets/history.svg";
export { default as Home } from "../assets/home.svg";
export { default as HomeFilled } from "../assets/home_filled.svg";
export { default as HomeOutline } from "../assets/home_outline.svg";
export { default as HowTo } from "../assets/how-to.svg";
export { default as InTransitTag } from "../assets/in-transit-tag.svg";
export { default as Info } from "../assets/info.svg";
export { default as InfoFilled } from "../assets/info_filled.svg";
export { default as Instagram } from "../assets/instagram.svg";
export { default as InstagramOutline } from "../assets/instagram_outline.svg";
export { default as Keyboard } from "../assets/keyboard.svg";
export { default as Legal } from "../assets/legal.svg";
export { default as Link } from "../assets/link.svg";
export { default as LinkNew } from "../assets/link_new.svg";
export { default as LocateMe } from "../assets/locate-me.svg";
export { default as LocationFilled } from "../assets/location-filled.svg";
export { default as LocationPinFilled } from "../assets/location-pin-filled.svg";
export { default as LocationPinOutlineFilled } from "../assets/location-pin-outline-filled.svg";
export { default as LocationStar } from "../assets/location-star.svg";
export { default as Location } from "../assets/location.svg";
export { default as LockFilled } from "../assets/lock-filled.svg";
export { default as Lock } from "../assets/lock.svg";
export { default as Logout } from "../assets/logout.svg";
export { default as Magnifier } from "../assets/magnifier.svg";
export { default as Mail } from "../assets/mail.svg";
export { default as Map } from "../assets/map.svg";
export { default as MeetingProduct } from "../assets/meeting-product.svg";
export { default as MessageFilled } from "../assets/message-filled.svg";
export { default as MessageOutline } from "../assets/message-outline.svg";
export { default as Mic } from "../assets/mic.svg";
export { default as Minus } from "../assets/minus.svg";
export { default as Money } from "../assets/money.svg";
export { default as More } from "../assets/more.svg";
export { default as MyStoreFilled } from "../assets/my store_filled.svg";
export { default as MyStoreOutline } from "../assets/my store_outline.svg";
export { default as NavigationFilled } from "../assets/navigation-filled.svg";
export { default as Navigation } from "../assets/navigation.svg";
export { default as Netbanking } from "../assets/netbanking.svg";
export { default as NewHomeFilled } from "../assets/new-home-filled.svg";
export { default as NewHome } from "../assets/new-home.svg";
export { default as OfferFilled } from "../assets/offer-filled.svg";
export { default as OrdersFilledNew } from "../assets/orders_filled_new.svg";
export { default as OrdersOutline } from "../assets/orders_outline.svg";
export { default as Outline } from "../assets/outline.svg";
export { default as Package } from "../assets/package.svg";
export { default as PackageFilled } from "../assets/package_filled.svg";
export { default as Paint } from "../assets/paint.svg";
export { default as Paused } from "../assets/paused.svg";
export { default as Payment } from "../assets/payment.svg";
export { default as Payouts } from "../assets/payouts.svg";
export { default as Pen } from "../assets/pen.svg";
export { default as PendingTag } from "../assets/pending-tag.svg";
export { default as PercentageOutline } from "../assets/percentage-outline.svg";
export { default as Performance } from "../assets/performance.svg";
export { default as Person } from "../assets/person.svg";
export { default as Phone } from "../assets/phone.svg";
export { default as PhysicalProduct } from "../assets/physical-product.svg";
export { default as PieGraph } from "../assets/pie-graph.svg";
export { default as PlanProduct } from "../assets/plan-product.svg";
export { default as PlaneOutline } from "../assets/plane-outline.svg";
export { default as Play } from "../assets/play.svg";
export { default as Plus } from "../assets/plus.svg";
export { default as PriceTag } from "../assets/price-tag.svg";
export { default as Question } from "../assets/question.svg";
export { default as RearrangeVertical } from "../assets/rearrange-vertical.svg";
export { default as Rearrange } from "../assets/rearrange.svg";
export { default as Rotate } from "../assets/rotate.svg";
export { default as Rto } from "../assets/rto.svg";
export { default as RupeeOutline } from "../assets/rupee_outline.svg";
export { default as SadSmiley } from "../assets/sad_smiley.svg";
export { default as SendFilled } from "../assets/send-filled.svg";
export { default as SentBackFilled } from "../assets/sent-back-filled.svg";
export { default as Settings } from "../assets/settings.svg";
export { default as Share } from "../assets/share.svg";
export { default as Shield } from "../assets/shield.svg";
export { default as ShowcaseSuccess } from "../assets/showcase_success.svg";
export { default as Sort } from "../assets/sort.svg";
export { default as Squiggle } from "../assets/squiggle.svg";
export { default as StarFilled } from "../assets/star-filled.svg";
export { default as Star } from "../assets/star.svg";
export { default as StarBadge } from "../assets/star_badge.svg";
export { default as Store } from "../assets/store.svg";
export { default as StoreFilled } from "../assets/store_filled.svg";
export { default as StoreSettings } from "../assets/store_settings.svg";
export { default as StoreSettingsFilled } from "../assets/store_settings_filled.svg";
export { default as SuitcaseFilled } from "../assets/suitcase-filled.svg";
export { default as Suitcase } from "../assets/suitcase.svg";
export { default as Support } from "../assets/support.svg";
export { default as SwapArrows } from "../assets/swap-arrows.svg";
export { default as SwiggleLine } from "../assets/swiggle-line.svg";
export { default as Swiggy } from "../assets/swiggy.svg";
export { default as SwiggyStore } from "../assets/swiggy_store.svg";
export { default as SwipeConfirm } from "../assets/swipe-confirm.svg";
export { default as SwirlArrow } from "../assets/swirl-arrow.svg";
export { default as Tablet } from "../assets/tablet.svg";
export { default as Tag } from "../assets/tag.svg";
export { default as ThirdParty } from "../assets/third_party.svg";
export { default as TickFilled } from "../assets/tick-filled.svg";
export { default as Tick } from "../assets/tick.svg";
export { default as Timer } from "../assets/timer.svg";
export { default as Trending } from "../assets/trending.svg";
export { default as TruckOutline } from "../assets/truck-outline.svg";
export { default as Truck } from "../assets/truck.svg";
export { default as Upi } from "../assets/upi.svg";
export { default as Upload } from "../assets/upload.svg";
export { default as User } from "../assets/user.svg";
export { default as UserFilled } from "../assets/user_filled.svg";
export { default as Video } from "../assets/video.svg";
export { default as VolumeMute } from "../assets/volume_mute.svg";
export { default as VolumePlus } from "../assets/volume_plus.svg";
export { default as Wallet } from "../assets/wallet.svg";
export { default as Warning } from "../assets/warning.svg";
export { default as WhatsappFilled } from "../assets/whatsapp-filled.svg";
export { default as Whatsapp } from "../assets/whatsapp.svg";
export { default as WhatsappNew } from "../assets/whatsapp_new.svg";
export { default as Youtube } from "../assets/youtube.svg";

/**
 * This is an auto generated file
 * Do not edit manually
 * Use code-gen script to rebuild the file
 */
