import React, { ReactElement } from "react";
import { Pressable, StyleSheet, FlatList, ListRenderItem, Keyboard } from "react-native";
import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";

import { Prediction } from "../../../interfaces";

type Predictions = Prediction[];

export const Result: React.FC<{
    predictions: Predictions;
    handleSearchResultCTA: (arg: string) => void;
}> = ({ predictions, handleSearchResultCTA }) => {
    const { value: theme } = useTheme();

    const renderPredictions: ListRenderItem<Prediction> = ({ item, index }): ReactElement => {
        return (
            <Pressable
                style={({ pressed }: { pressed: boolean }) => [
                    {
                        backgroundColor: pressed ? theme["color-basic-5"] : theme["color-basic-0"],
                        borderColor: theme["color-basic-5"],
                        borderBottomWidth: index === predictions.length - 1 ? 0 : 1,
                    },
                    styles.item,
                ]}
                onPress={() => handleSearchResultCTA(item.place_id)}
                testID={item.place_id}>
                <Text style={styles.mainText} color="color-basic-100" category="b2" weight="bold">
                    {item.structured_formatting.main_text}
                </Text>
                <Text color="color-basic-60" category="b3" weight="medium">
                    {item.structured_formatting.secondary_text}
                </Text>
            </Pressable>
        );
    };

    return (
        <FlatList
            onScrollBeginDrag={() => Keyboard.dismiss()}
            data={predictions}
            renderItem={renderPredictions}
            keyExtractor={(prediction: Prediction) => prediction.place_id}
            contentContainerStyle={styles.flatlist}
            bounces={false}
        />
    );
};

const styles = StyleSheet.create({
    flatlist: {
        paddingBottom: SpacingValue["space-medium"],
    },
    item: {
        justifyContent: "center",
        alignItems: "flex-start",
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: SpacingValue["space-x-large"],
    },
    mainText: {
        marginBottom: 10,
    },
});
