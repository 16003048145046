export const FONT_BLACK = "GilroyExtraBold-Regular";
export const FONT_REGULAR = "GilroyMedium-Regular";
export const FONT_BOLD = "GilroyBold-Regular";
export const FONT_MEDIUM = "GilroySemiBold-Regular";

export const TypographyValue = {
    /** 36 */
    "text-heading-1-font-size": 36,
    "text-heading-1-font-family": FONT_BLACK,
    "text-heading-1-line-height": 40,
    "text-heading-1-letter-spacing": -0.8,

    /** 28 */
    "text-heading-2-font-size": 28,
    "text-heading-2-font-family": FONT_BLACK,
    "text-heading-2-line-height": 32,
    "text-heading-2-letter-spacing": -0.6,

    /** 24 */
    "text-heading-3-font-size": 24,
    "text-heading-3-font-family": FONT_BLACK,
    "text-heading-3-line-height": 28,
    "text-heading-3-letter-spacing": -0.4,

    /** 20 */
    "text-heading-4-font-size": 20,
    "text-heading-4-font-family": FONT_BLACK,
    "text-heading-4-line-height": 24,
    "text-heading-4-letter-spacing": -0.3,

    /** 18 */
    "text-heading-5-font-size": 18,
    "text-heading-5-font-family": FONT_BLACK,
    "text-heading-5-line-height": 20,
    "text-heading-5-letter-spacing": -0.3,

    /** 18 */
    "text-subheader-1-font-size": 18,
    "text-subheader-1-font-family": FONT_REGULAR,
    "text-subheader-1-line-height": 24,
    "text-subheader-1-letter-spacing": -0.3,
    "text-subheader-1-bold-font-family": FONT_BOLD,

    /** 17 */
    "text-subheader-2-font-size": 17,
    "text-subheader-2-font-family": FONT_REGULAR,
    "text-subheader-2-line-height": 20,
    "text-subheader-2-letter-spacing": -0.3,

    /** 16 */
    "text-subheader-3-font-size": 16,
    "text-subheader-3-font-family": FONT_REGULAR,
    "text-subheader-3-line-height": 18,
    "text-subheader-3-letter-spacing": -0.3,

    /** 18 */
    "text-btn-1-font-size": 18,
    "text-btn-1-font-family": FONT_BLACK,
    "text-btn-1-line-height": 24,
    "text-btn-1-letter-spacing": -0.3,

    /** 18 */
    "text-btn-2-font-size": 18,
    "text-btn-2-font-family": FONT_BOLD,
    "text-btn-2-line-height": 24,
    "text-btn-2-letter-spacing": -0.3,

    /** 16 */
    "text-btn-3-font-size": 16,
    "text-btn-3-font-family": FONT_BOLD,
    "text-btn-3-line-height": 20,
    "text-btn-3-letter-spacing": -0.3,

    /** 14 */
    "text-btn-4-font-size": 14,
    "text-btn-4-font-family": FONT_BLACK,
    "text-btn-4-line-height": 20,
    "text-btn-4-letter-spacing": -0.1,

    /** 13 */
    "text-btn-5-font-size": 13,
    "text-btn-5-font-family": FONT_BOLD,
    "text-btn-5-line-height": 16,
    "text-btn-5-letter-spacing": -0.1,

    /** 16 */
    "text-body-1-regular-font-size": 16,
    "text-body-1-regular-font-family": FONT_REGULAR,
    "text-body-1-regular-line-height": 19,
    "text-body-1-regular-letter-spacing": -0.3,

    /** 16 */
    "text-body-1-medium-font-size": 16,
    "text-body-1-medium-font-family": FONT_MEDIUM,
    "text-body-1-medium-line-height": 19,
    "text-body-1-medium-letter-spacing": -0.3,

    /** 16 */
    "text-body-1-bold-font-size": 16,
    "text-body-1-bold-font-family": FONT_BOLD,
    "text-body-1-bold-line-height": 19,
    "text-body-1-bold-letter-spacing": -0.3,

    /** 14 */
    "text-body-2-regular-font-size": 14,
    "text-body-2-regular-font-family": FONT_REGULAR,
    "text-body-2-regular-line-height": 18,
    "text-body-2-regular-letter-spacing": -0.3,

    /** 14 */
    "text-body-2-medium-font-size": 14,
    "text-body-2-medium-font-family": FONT_MEDIUM,
    "text-body-2-medium-line-height": 18,
    "text-body-2-medium-letter-spacing": -0.3,

    /** 14 */
    "text-body-2-bold-font-size": 14,
    "text-body-2-bold-font-family": FONT_BOLD,
    "text-body-2-bold-line-height": 18,
    "text-body-2-bold-letter-spacing": -0.3,

    /** 13 */
    "text-body-3-regular-font-size": 13,
    "text-body-3-regular-font-family": FONT_REGULAR,
    "text-body-3-regular-line-height": 16,
    "text-body-3-regular-letter-spacing": -0.3,

    /** 13 */
    "text-body-3-medium-font-size": 13,
    "text-body-3-medium-font-family": FONT_MEDIUM,
    "text-body-3-medium-line-height": 16,
    "text-body-3-medium-letter-spacing": -0.3,

    /** 13 */
    "text-body-3-bold-font-size": 13,
    "text-body-3-bold-font-family": FONT_BOLD,
    "text-body-3-bold-line-height": 16,
    "text-body-3-bold-letter-spacing": -0.3,

    /** 12 */
    "text-body-4-regular-font-size": 12,
    "text-body-4-regular-font-family": FONT_REGULAR,
    "text-body-4-regular-line-height": 16,
    "text-body-4-regular-letter-spacing": -0.4,

    /** 13 */
    "text-body-4-medium-font-size": 12,
    "text-body-4-medium-font-family": FONT_MEDIUM,
    "text-body-4-medium-line-height": 16,
    "text-body-4-medium-letter-spacing": -0.4,

    /** 13 */
    "text-body-4-bold-font-size": 12,
    "text-body-4-bold-font-family": FONT_BOLD,
    "text-body-4-bold-line-height": 16,
    "text-body-4-bold-letter-spacing": -0.4,
};
