import { useCallback, useEffect, useState } from "react";
import { Keyboard, KeyboardEventListener, Platform } from "react-native";

export const useKeyboardHeight = (): number => {
    const [keyboardHeight, setKeyboardHeight] = useState(0);

    const onShow: KeyboardEventListener = useCallback((e) => {
        setKeyboardHeight(e.endCoordinates.height);
    }, []);

    const onHide: KeyboardEventListener = useCallback((_) => {
        setKeyboardHeight(0);
    }, []);

    useEffect(() => {
        if (Platform.OS === "ios") {
            const s1 = Keyboard.addListener("keyboardWillShow", onShow);
            const s2 = Keyboard.addListener("keyboardWillHide", onHide);

            return () => {
                if (Keyboard.removeSubscription) {
                    Keyboard.removeSubscription(s1);
                    Keyboard.removeSubscription(s2);
                } else {
                    s1.remove();
                    s2.remove();
                }
            };
        }

        const s1 = Keyboard.addListener("keyboardDidShow", onShow);
        const s2 = Keyboard.addListener("keyboardDidHide", onHide);

        return () => {
            if (Keyboard.removeSubscription) {
                Keyboard.removeSubscription(s1);
                Keyboard.removeSubscription(s2);
            } else {
                s1.remove();
                s2.remove();
            }
        };
    }, [onHide, onShow]);

    return keyboardHeight;
};
