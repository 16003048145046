import * as React from "react";
import { Pressable, StyleSheet, Dimensions } from "react-native";

import { CdnImage, Divider } from "@swiggy-private/react-native-ui";
import { Stack, Box } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text } from "@swiggy-private/rn-dls";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";
import { IViewStoryMessage } from "@swiggy-private/connect-chat-commons";

const VIEW_STORY = "View Story";

interface ViewStoryMessageProps {
    message: IViewStoryMessage;
    conversationMeta?: SdkConversation["meta"];
    onPress: VoidFunction;
}

const imageWidth = Math.min(Dimensions.get("window").width / 1.75, 240);
const imageHeight = 120;

export const ViewStoryMessageComponent: React.FC<ViewStoryMessageProps> = ({
    message: { payload: data },
    onPress,
}) => {
    return (
        <>
            <Stack spacing={SpacingValue["space-x-small"]} style={[styles.container]}>
                <Box direction="column" style={styles.content}>
                    <CdnImage
                        id={data.imageUrl}
                        style={[{ width: imageWidth, height: imageHeight }]}
                        resizeMode="cover"
                        height={120}
                        width={imageWidth}
                        showLoader
                    />
                    <Box
                        pv={SpacingValue["space-x-small"]}
                        ph={SpacingValue["space-small"]}
                        style={[styles.title]}>
                        <Text
                            color={"color-basic-0"}
                            category={"b1"}
                            weight="bold"
                            style={styles.trackText}>
                            {data.highlightName}
                        </Text>
                    </Box>
                </Box>

                <Box ph={SpacingValue["space-x-small"]}>
                    <Text category="b1" weight="regular" color={"color-basic-75"}>
                        {data.text}
                    </Text>
                </Box>

                <Pressable onPress={onPress} hitSlop={{ top: 8, bottom: 8 }}>
                    <Divider color={"color-basic-15"} />
                    <Box
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        pv={SpacingValue["space-small"]}>
                        <Text weight="bold" category="btn3" style={[styles.cta]}>
                            {VIEW_STORY}
                        </Text>
                    </Box>
                </Pressable>
            </Stack>
        </>
    );
};

export const ViewStoryMessage = React.memo(ViewStoryMessageComponent);

const styles = StyleSheet.create({
    container: {
        backgroundColor: "#F0F0F5",
        borderRadius: 16,
        minWidth: 200,
        maxWidth: imageWidth,
    },
    content: {
        borderRadius: 16,
        overflow: "hidden",
    },
    title: {
        backgroundColor: "#18274D",
    },
    trackText: {
        textDecorationStyle: "solid",
    },
    cta: {
        textDecorationStyle: "solid",
        color: "#18274D",
    },
});
