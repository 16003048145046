import { Theme } from "@swiggy-private/rn-dls-theme";

import { ModalStyle, ModalStyleMeta } from "../interfaces/modal";

export const getModalStyles = (meta: ModalStyleMeta, theme: Theme): ModalStyle => {
    let backdropStyle = meta.backdropStyle ?? {};
    let modalBodyStyle = meta.modalBodyStyle ?? {};

    backdropStyle = {
        ...backdropStyle,
        backgroundColor: backdropStyle.backgroundColor ?? theme["color-basic-alpha-45"],
    };

    modalBodyStyle = {
        ...modalBodyStyle,
        backgroundColor: modalBodyStyle.backgroundColor ?? theme["color-basic-0"],
    };

    return {
        backdropStyle,
        modalBodyStyle,
    };
};
