import IntersectionObserver from "./IntersectionObserver";
import IOManagerDefaultProps, { type IntersectionObserverOptions } from "./typings/IOManager.d";

class IOManager implements IOManagerDefaultProps {
    io;
    observerId;
    instanceMap = new Map();
    constructor(options: IntersectionObserverOptions) {
        this.io = new IntersectionObserver(this.handleChange, options);
        this.observerId = 0;
    }
    /*
    This method handles changes in intersection status detected by the IntersectionObserver.
    It iterates through the entries array, which contains information about observed elements and their intersection status.
    For each entry, it retrieves the corresponding instance from the instanceMap and calls the callback function stored in the instance, passing the isIntersecting value.
    */
    handleChange: IOManagerDefaultProps["handleChange"] = (entries) => {
        for (let index = 0; index < entries.length; index += 1) {
            const { target, isIntersecting } = entries[index];
            const instance = this.instanceMap.get(target);
            if (instance) {
                instance.callback(isIntersecting);
            }
        }
    };
    /*
    This method is used to start observing an element for intersection changes.
    It takes an element to observe and a callback function to be called when the intersection status changes.
    It checks if an instance for the element already exists in the instanceMap. If it does, it returns the existing instance.
    If not, it generates a new observerId, creates an instance object containing the element, callback, observerId, and the io instance, and stores it in the instanceMap.
    It then calls the observe method of the io instance to start observing the element.
    */
    observe: IOManagerDefaultProps["observe"] = (element, callback) => {
        const existInstance = this.instanceMap.get(element);
        if (existInstance) {
            return existInstance;
        }
        this.observerId += 1;
        const instance = {
            callback,
            element,
            observerId: this.observerId,
            observer: this.io,
        };
        this.instanceMap.set(element, instance);
        this.io.observe(element);
        return instance;
    };
    /*
    This method is used to stop observing an element for intersection changes.
    If the instanceMap contains an entry for the element, it removes the entry from the instanceMap and calls the unobserve method of the io instance to stop observing the element.
    */
    unobserve: IOManagerDefaultProps["unobserve"] = (element) => {
        if (this.instanceMap.has(element)) {
            this.instanceMap.delete(element);
            this.io.unobserve(element);
        }
    };
}
export default IOManager;
