import * as React from "react";
import { StyleSheet, Platform, Pressable, ViewStyle } from "react-native";

import { SpacingValue, useTheme, Text, Surface } from "@swiggy-private/rn-dls";
import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { CdnImage } from "@swiggy-private/react-native-ui";

import { SvgIcon, SvgIconType } from "@swiggy-private/connect-svg-icons";

const ICON_SIZE = 30;

export interface SocialMetricsWidgetProps {
    type: string;
    header: string;
    subtext: string;
    url: string;
    priority: number;

    logo?: string;
    icon?: SvgIconType["icon"];
    testID?: string;
    onPress?: VoidFunction;
    cardStyle?: ViewStyle;
    textContainerStyle?: ViewStyle;
}

const SocialMetricsWidgetComponent: React.FC<SocialMetricsWidgetProps> = ({
    header,
    subtext,
    logo,
    icon,
    testID,
    onPress,
    cardStyle,
    textContainerStyle,
}) => {
    const { value: theme } = useTheme();

    const handlePress = React.useCallback(() => {
        onPress?.();
    }, [onPress]);

    const surfaceStyle: ViewStyle = {
        shadowOpacity: Platform.select({
            android: 0.1,
            web: 0.1,
            ios: 0.2,
        }),
        borderColor: theme.background_Secondary,
        ...styles.card,
        ...cardStyle,
    };

    const logoStyle = {
        ...styles.logo,
        borderColor: theme.background_Secondary,
    };

    return (
        <Pressable onPress={handlePress} testID={testID}>
            <Surface style={surfaceStyle}>
                <Stack direction="row" spacing={SpacingValue["space-x-small"]} alignItems="center">
                    {logo ? (
                        <CdnImage
                            id={logo}
                            width={ICON_SIZE}
                            height={ICON_SIZE}
                            style={logoStyle}
                            resizeMode="contain"
                        />
                    ) : null}

                    <Stack style={textContainerStyle}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            spacing={SpacingValue["space-xx-small"]}>
                            {icon ? (
                                <SvgIcon
                                    icon={icon}
                                    color="color-warning-200"
                                    height={ICON_SIZE / 2}
                                    width={ICON_SIZE / 2}
                                />
                            ) : null}
                            <Text category="b1" weight="bold" color="high">
                                {header}
                            </Text>
                        </Stack>

                        <Text category="b3" weight="medium" color="low">
                            {subtext}
                        </Text>
                    </Stack>
                </Stack>
            </Surface>
        </Pressable>
    );
};

export const SocialMetricsWidget = React.memo(SocialMetricsWidgetComponent);

SocialMetricsWidget.displayName = "SocialMetrics";

const styles = StyleSheet.create({
    card: {
        borderRadius: 12,
        borderWidth: StyleSheet.hairlineWidth,
        paddingHorizontal: SpacingValue["space-x-small"],
        paddingVertical: SpacingValue["space-xx-small"],
        elevation: 2,
        shadowRadius: 6,
        shadowOffset: { width: 2, height: 2 },
    },
    logo: {
        borderRadius: 8,
        borderWidth: 1,
    },
});
