import React, { useState } from "react";
import { Platform, Pressable, StyleProp, TextStyle, ViewStyle } from "react-native";

import { SpacingValue, Text, TextCategory, TextColor, TextProps } from "@swiggy-private/rn-dls";

import { TEST_IDS, TRIPLE_DOT } from "./constants";

interface ReadMoreTextProps {
    text: string;
    maxLengthCount: number;
    textProps: TextProps;
    expandedTextProps: TextProps;
    collapsedTextProps: TextProps;

    expandedText?: string;
    collapsedText?: string;
    textCategory?: TextCategory;
    textColor?: TextColor;
    textStyle?: StyleProp<TextStyle>;
    handleToggle?: (b: boolean) => void;
}

const ReadMoreTextComponent: React.FC<ReadMoreTextProps> = (props) => {
    const {
        text,
        textStyle,
        maxLengthCount,
        expandedTextProps,
        collapsedTextProps,
        textProps,
        expandedText = "show less",
        collapsedText = "read more",
        handleToggle,
    } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const truncatedText = text.slice(0, maxLengthCount);

    const toggleExpanded = React.useCallback(() => {
        const state = !isExpanded;

        handleToggle?.(state);
        setIsExpanded(state);
    }, [isExpanded, handleToggle]);

    const textToRender = isExpanded ? text : truncatedText;

    const textWrapperStyle: ViewStyle = {
        marginBottom: Platform.select({
            web: -SpacingValue["space-xx-small"],
            android: -(SpacingValue["space-xx-small"] + SpacingValue["space-xxx-small"] / 2),
            ios: -SpacingValue["space-xx-small"] / 2,
        }),
    };

    return (
        <Text {...textProps} style={textStyle}>
            <Text {...textProps} style={textStyle} testID={TEST_IDS.CONTENT_ID}>
                {textToRender}
            </Text>
            {text.length > maxLengthCount && !isExpanded ? (
                <Text {...textProps} style={textStyle} testID={TEST_IDS.EXPANDED}>
                    {TRIPLE_DOT}
                </Text>
            ) : (
                ""
            )}
            {text.length > maxLengthCount ? (
                <Pressable
                    onPress={toggleExpanded}
                    hitSlop={{ right: 4, bottom: 8, top: 4 }}
                    testID={TEST_IDS.PRESSABLE}>
                    <Text
                        {...(!isExpanded ? expandedTextProps : collapsedTextProps)}
                        style={textWrapperStyle}
                        testID={TEST_IDS.TOGGLE_TEXT}>
                        {isExpanded ? ` ${expandedText}` : ` ${collapsedText}`}
                    </Text>
                </Pressable>
            ) : (
                ""
            )}
        </Text>
    );
};
export const ReadMoreText = React.memo(ReadMoreTextComponent);
