import { types } from "./document-picker";

import { MediaSourceItem } from "./types";

export const MEDIA_SOURCE_IDS = {
    camera: "upload-media-camera",
    gallery: "upload-media-gallery",
    file: "upload-media-file",
};

export const MEDIA_SOURCE_DATA: MediaSourceItem[] = [
    { id: MEDIA_SOURCE_IDS.camera, name: "Camera", svg: "Camera" },
    { id: MEDIA_SOURCE_IDS.gallery, name: "Gallery", svg: "Gallery" },
    { id: MEDIA_SOURCE_IDS.file, name: "File", svg: "File" },
];

export const UPLOAD_MEDIA_TITLE = "Upload images";

export const FILES_TYPES = types;

export const SUPPORTED_FILE_TYPES: string[] = [
    FILES_TYPES?.doc,
    FILES_TYPES?.docx,
    FILES_TYPES?.pdf,
    FILES_TYPES?.images,
    FILES_TYPES?.video,
];
