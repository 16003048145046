import React from "react";
import { StyleSheet, Pressable, View } from "react-native";

import { SpacingValue, Text, useTheme } from "@swiggy-private/rn-dls";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Stack } from "@swiggy-private/rn-adaptive-layout";

type Props = {
    locateMeText: string;
    handleCTA: () => void;
};

export const AutoLocate: React.FC<Props> = ({ locateMeText, handleCTA }) => {
    const { value: theme } = useTheme();

    return (
        <Pressable
            style={[styles.container, { backgroundColor: theme["primary_Sub"] }]}
            onPress={handleCTA}
            testID="locate-me-test-btn">
            <Stack style={[styles.iconCont, { backgroundColor: theme["primary"] }]}>
                <SvgIcon
                    color="color-background-primary"
                    accessibilityLabel="Current Location Icon"
                    accessible
                    icon="LocateMe"
                    height={22}
                    width={22}
                />
            </Stack>
            <View style={styles.textContainer}>
                <Text color="primary" category="s3" weight="regular">
                    {locateMeText}
                </Text>
            </View>
        </Pressable>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: SpacingValue["space-large"],
        padding: 6,
        borderRadius: 16,
    },
    iconCont: {
        padding: SpacingValue["space-x-small"] + 1,
        borderRadius: 12,
    },
    textContainer: {
        marginLeft: SpacingValue["space-x-small"],
    },
});
