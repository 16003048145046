import React, {
    ForwardRefRenderFunction,
    useCallback,
    useRef,
    useMemo,
    useState,
    useLayoutEffect,
} from "react";
import {
    ColorValue,
    ImageProps,
    ImageStyle,
    LayoutChangeEvent,
    LayoutRectangle,
    NativeSyntheticEvent,
    Platform,
    StyleProp,
    StyleSheet,
    TextInput,
    TextInputContentSizeChangeEventData,
    TextInputFocusEventData,
    TextInputProps,
    TextStyle,
    View,
    ViewStyle,
} from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";
import { useForwardedRef } from "@swiggy-private/react-hooks";

import { FalsyFC, RenderProp } from "../../support/falsy-fc";
import { useDLS, UserInteraction } from "../../styles/style-service";
import { TouchableComponent } from "../../support/touchable-component";
import { FalsyText } from "../../support/falsy-text";
import { InputLabel, InputLabelHandler } from "./input-label";

import type { InputColor, InputSize, InputStyle } from "../../styles/interfaces/input";
import type { TextProps } from "../text";

export interface InputProps extends TextInputProps {
    color?: InputColor;
    size?: InputSize;
    disabled?: boolean;
    label?: React.ReactText;
    hint?: RenderProp<TextProps> | React.ReactText;
    error?: boolean;
    showCounter?: boolean;
    accessoryLeft?: RenderProp<Partial<ImageProps>>;
    accessoryRight?: RenderProp<Partial<ImageProps>>;
    textStyle?: StyleProp<TextStyle>;
    isNumeric?: boolean;
    multilineStyle?: ViewStyle;
    hintContainerStyle?: ViewStyle;
    isCaretHidden?: boolean;
    inputLabelStyle?: StyleProp<TextStyle>;
    hintTextStyle?: TextStyle;
}

export type InputElement = React.ReactElement<InputProps>;

const getComponentStyle = (
    source: InputStyle,
    opts: { error?: boolean; lengthExceeded?: boolean },
): {
    inputContainer: ViewStyle;
    placeholderColor: ColorValue;
    caretColor: ColorValue;
    input: TextStyle;
    label: TextStyle;
    smallLabel: TextStyle;
    hintText: TextStyle;
    hintContainer: ViewStyle;
    icon: ImageStyle;
    iconLeft: ImageStyle;
    iconRight: ImageStyle;
    paddingHorizontal: number;
    paddingVertical: number;
    counter: ViewStyle;
} => {
    const {
        placeholderColor,
        textColor,
        textFontFamily,
        textFontSize,
        textMarginHorizontal,
        textLetterSpacing,
        textLineHeight,
        labelColor,
        labelFontFamily,
        labelFontSize,
        labelLineHeight,
        hintColor,
        hintFontFamily,
        hintFontSize,
        hintContainerBackgroundColor,
        hintContainerBorderColor,
        hintBorderWidth,
        errorColor,
        errorBorderColor,
        errorContainerBackgroundColor,
        errorContainerBorderColor,
        iconHeight,
        iconMarginHorizontal,
        iconWidth,
        iconTintColor,
        smallLabelFontFamily,
        smallLabelFontSize,
        smallLabelBackgroundColor,
        borderColor,
        borderWidth,
        borderRadius,
        paddingHorizontal,
        paddingVertical,
        caretColor,
        opacity,
        backgroundColor,
        counterFontFamily,
        counterFontSize,
        counterColor,
        ...containerProps
    } = source;

    const style = {
        placeholderColor,
        caretColor,
        paddingHorizontal,
        paddingVertical,
        inputContainer: {
            ...containerProps,
            backgroundColor,
            paddingHorizontal,
            paddingVertical,
            opacity,
            borderRadius,
            borderWidth,
            borderColor: opts.error ? errorBorderColor : borderColor,
        },
        input: {
            color: textColor,
            fontFamily: textFontFamily,
            fontSize: textFontSize,
            marginHorizontal: textMarginHorizontal,
            letterSpacing: textLetterSpacing,
            lineHeight: textLineHeight,
        },
        label: {
            color: opts.error ? errorColor : labelColor,
            fontFamily: labelFontFamily,
            fontSize: labelFontSize,
            lineHeight: labelLineHeight,
            letterSpacing: textLetterSpacing,
            left: 0,
        },
        smallLabel: {
            color: opts.error ? errorColor : labelColor,
            backgroundColor: smallLabelBackgroundColor,
            left: paddingHorizontal,
            fontSize: smallLabelFontSize,
            fontFamily: smallLabelFontFamily,
            letterSpacing: textLetterSpacing,
            paddingHorizontal: 4,
        },
        hintContainer: {
            backgroundColor: opts.error
                ? errorContainerBackgroundColor
                : hintContainerBackgroundColor,
            borderColor: opts.error ? errorContainerBorderColor : hintContainerBorderColor,
            borderWidth: hintBorderWidth,
            borderRadius: borderRadius,
        },
        hintText: {
            color: opts.error ? errorColor : hintColor,
            fontFamily: hintFontFamily,
            fontSize: hintFontSize,
            letterSpacing: textLetterSpacing,
        },
        icon: {
            width: iconWidth,
            height: iconHeight,
            tintColor: iconTintColor,
        },
        counter: {
            marginLeft: iconMarginHorizontal,
            fontFamily: counterFontFamily,
            fontSize: counterFontSize,
            letterSpacing: textLetterSpacing,
            color: opts.error || opts.lengthExceeded ? errorColor : counterColor,
        },
        iconLeft: {
            marginRight: iconMarginHorizontal,
        },
        iconRight: {
            marginLeft: iconMarginHorizontal,
        },
    };

    if (Platform.OS === "web") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style.input.caretColor = opts.error ? errorColor : caretColor;
    }

    return style;
};

const InputComponent: ForwardRefRenderFunction<TextInput, InputProps> = (props, ref) => {
    const {
        label,
        hint,
        accessoryLeft,
        accessoryRight,
        testID,
        textStyle,
        hintContainerStyle,
        color,
        size,
        style,
        onChangeText,
        accessible = true,
        accessibilityLabel,
        accessibilityState,
        error,
        onLayout,
        showCounter,
        isNumeric,
        allowFontScaling = false,
        inputLabelStyle,
        isCaretHidden = false,
        hintTextStyle,
        ...textInputProps
    } = props;

    const dls = useDLS("input", props);
    const textInputRef = useForwardedRef<TextInput>(ref);
    const labelRef = useRef<InputLabelHandler>(null);
    const valueRef = useRef(textInputProps.value || textInputProps.defaultValue || "");
    const inputContainerLayoutRef = useRef<LayoutRectangle>();
    const [, setInputLength] = useState(valueRef.current.length);

    // update reference
    if (textInputProps.value) {
        valueRef.current = textInputProps.value;
    }

    const focus = useCallback(() => textInputRef.current?.focus(), [textInputRef]);
    const isFocused = useCallback(() => textInputRef.current?.isFocused() ?? false, [textInputRef]);
    const canShowCounter = !!textInputProps.maxLength && showCounter;

    const onTextFieldFocus = useCallback(
        (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
            dls.dispatch([UserInteraction.FOCUSED]);
            textInputProps.onFocus && textInputProps.onFocus(event);
        },
        [dls, textInputProps],
    );

    const onTextFieldBlur = useCallback(
        (event: NativeSyntheticEvent<TextInputFocusEventData>): void => {
            dls.dispatch([]);
            textInputProps.onBlur && textInputProps.onBlur(event);
        },
        [dls, textInputProps],
    );

    const onChangeTextCallback = useCallback(
        (text: string) => {
            // * mainly utilised for RNWeb
            if (isNumeric) {
                const isMatched = text.match(/^([^0-9.])+$/g)?.length;
                if (isMatched && text) {
                    onChangeText?.("");
                    return;
                }
            }

            valueRef.current = text;
            labelRef.current?.setLabelSize(text.length > 0 || isFocused() ? "small" : "large");

            if (canShowCounter) {
                setInputLength(text.length);
            }

            if (onChangeText) {
                onChangeText(text);
            }
        },
        [isFocused, canShowCounter, onChangeText, isNumeric],
    );

    const onInputContainerLayout = useCallback(({ nativeEvent }: LayoutChangeEvent): void => {
        inputContainerLayoutRef.current = nativeEvent.layout;
        labelRef.current?.setParentHeight(nativeEvent.layout.height);
    }, []);

    const inputLength = String(valueRef.current).length;
    const labelSize =
        !!textInputProps.placeholder?.length || valueRef.current.length || isFocused()
            ? "small"
            : "large";

    const lengthExceeded = !!(textInputProps?.maxLength && inputLength >= textInputProps.maxLength);

    const dlsStyle = getComponentStyle(dls.style, { error, lengthExceeded });
    const labelStyle = labelSize === "small" ? dlsStyle.smallLabel : dlsStyle.label;
    const editable = !textInputProps.disabled;
    const canShowLeftAccessory = !label || labelSize === "small";
    const counter = useMemo(
        () =>
            canShowCounter && textInputProps.maxLength
                ? `${Math.min(
                      textInputProps.value != null ? textInputProps.value.length : inputLength,
                      textInputProps.maxLength,
                  )}/${textInputProps.maxLength}`
                : null,
        [canShowCounter, textInputProps.maxLength, textInputProps.value, inputLength],
    );

    const [inputHeight, setInputHeight] = useState<number | null>(null);
    const onContentSizeChange = useCallback(
        (e: NativeSyntheticEvent<TextInputContentSizeChangeEventData>) => {
            if (Platform.OS === "web") {
                setInputHeight(e.nativeEvent.contentSize.height);
            }
        },
        [],
    );

    useLayoutEffect(() => labelRef.current?.setLabelSize(labelSize), [labelSize]);

    const {
        width,
        marginTop,
        marginLeft,
        marginRight,
        marginHorizontal,
        marginVertical,
        marginBottom,
        maxWidth,
        ...containerStyle
    } = StyleSheet.flatten<ViewStyle>([dlsStyle.inputContainer, styles.inputContainer, style]);

    const groupStyle = StyleSheet.flatten([
        {
            width,
            marginTop,
            marginLeft,
            marginRight,
            marginBottom,
            marginHorizontal,
            marginVertical,
            maxWidth,
        },
        hint ? styles.outerContainerWithHint : null,
    ]);

    const inputStyle = StyleSheet.flatten([
        dlsStyle.input,
        styles.input,
        platformStyles.input,
        textInputProps.multiline
            ? [
                  styles.multilineInput,
                  platformStyles.multilineInput,
                  inputHeight != null ? { height: inputHeight } : null,
                  textInputProps.multilineStyle,
              ]
            : null,
        textStyle,
    ]);

    return (
        <View testID={testID} style={groupStyle} pointerEvents="box-none">
            {hint ? (
                <View style={[styles.hintContainer, dlsStyle.hintContainer, hintContainerStyle]}>
                    <FalsyText style={[dlsStyle.hintText, hintTextStyle]} component={hint} />
                </View>
            ) : null}
            <TouchableComponent
                accessible={accessible}
                accessibilityLabel={accessibilityLabel}
                accessibilityState={{ disabled: !editable }}
                onPress={focus}
                disabled={textInputProps.disabled}
                style={containerStyle}
                onLayout={onInputContainerLayout}>
                <FalsyFC
                    style={[dlsStyle.icon, dlsStyle.iconLeft]}
                    component={canShowLeftAccessory ? accessoryLeft : undefined}
                />
                <TextInput
                    ref={textInputRef}
                    allowFontScaling={allowFontScaling}
                    placeholderTextColor={dlsStyle.placeholderColor}
                    selectionColor={dlsStyle.caretColor}
                    underlineColorAndroid="transparent"
                    onContentSizeChange={onContentSizeChange}
                    {...textInputProps}
                    editable={editable}
                    onChangeText={onChangeTextCallback}
                    onFocus={onTextFieldFocus}
                    onBlur={onTextFieldBlur}
                    style={inputStyle}
                    caretHidden={isCaretHidden}
                />
                <FalsyText
                    style={StyleSheet.compose(
                        dlsStyle.counter,
                        textInputProps.multiline ? styles.multilineInputCounter : null,
                    )}
                    component={counter}
                />
                <FalsyFC style={[dlsStyle.icon, dlsStyle.iconRight]} component={accessoryRight} />
                {label ? (
                    <InputLabel
                        ref={labelRef}
                        label={label}
                        size={labelSize}
                        style={[labelStyle, styles.label, inputLabelStyle]}
                        leftPosition={dlsStyle.paddingHorizontal}
                        parentHeight={inputContainerLayoutRef.current?.height}
                        pointerEvents="none"
                    />
                ) : null}
            </TouchableComponent>
        </View>
    );
};

if (process.env.NODE_ENV !== "production") {
    InputComponent.displayName = "Input";
}

/**
 * Inputs let users enter and edit text.
 *
 * ## Usage
 * ```js
 * import * as React from "react";
 * import { Input } from "@swiggy-private/rn-dls";
 *
 * const MyComponent = () => {
 *   return <Input label="Hello" />;
 * };
 *```
 */
export const Input = React.forwardRef(InputComponent);

const styles = StyleSheet.create({
    inputContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
    },
    input: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: "auto",
        paddingHorizontal: 0,
        paddingVertical: 0,
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
    },
    multilineInput: {
        alignItems: "flex-start",
        justifyContent: "flex-start",
        textAlignVertical: "top",
        minHeight: 104,
        paddingTop: SpacingValue["space-x-small"],
    },
    multilineInputCounter: {
        textAlign: "right",
        alignSelf: "flex-end",
        margin: SpacingValue["space-xxx-small"],
    },
    label: {
        position: "absolute",
        top: 0,
        left: 0,
        elevation: 0,
    },
    hintContainer: {
        position: "absolute",
        bottom: -26,
        left: 0,
        right: 0,
        height: 60,
        paddingHorizontal: SpacingValue["space-medium"],
        paddingVertical: 6,
        justifyContent: "flex-end",
    },
    outerContainerWithHint: {
        marginBottom: 26,
    },
});

const platformStyles = StyleSheet.create({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    input: Platform.select({
        default: {},
        android: {
            marginVertical: -2,
        },
        web: {
            outlineWidth: 0,
        },
    }),
    multilineInput: Platform.select({
        default: {},
        android: {
            marginVertical: 0,
        },
        web: {
            outlineWidth: 0,
            paddingVertical: SpacingValue["space-medium"],
        },
    }),
});
