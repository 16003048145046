import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

export const useControlled = <T>(
    controlled: T | undefined,
    controlledValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
    const isControlled = useRef(controlled !== undefined);
    const [value, setValue] = useState(controlledValue);

    const setValueIfUncontrolled = useCallback((newValue: T) => {
        if (!isControlled.current) {
            setValue(newValue);
        }
    }, []);

    return [
        isControlled.current ? controlledValue : value,
        setValueIfUncontrolled as Dispatch<SetStateAction<T>>,
    ];
};
