import React, { createContext } from "react";
import { ChatSdk } from "@swiggy-private/connect-chat-sdk";

type IChatSdkContext = ChatSdk | null;

export const ChatSdkContext = createContext<IChatSdkContext>(null);
export const ChatSdkProvider: React.FC<React.PropsWithChildren<{ sdk: ChatSdk | null }>> = ({
    sdk,
    children,
}) => {
    return <ChatSdkContext.Provider value={sdk}>{children}</ChatSdkContext.Provider>;
};
