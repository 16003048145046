async function imageUrlToBase64(blob: Blob): Promise<string | ArrayBuffer | null> {
    return new Promise((onSuccess, onError) => {
        try {
            const reader = new FileReader();
            reader.onload = function () {
                onSuccess(this.result);
            };
            reader.readAsDataURL(blob);
        } catch (e) {
            onError(e);
        }
    });
}

export const fetchStoryImage = async (
    storyImage: string | undefined,
): Promise<string | undefined> => {
    if (!storyImage) {
        return Promise.resolve(undefined);
    }
    try {
        return fetch(storyImage).then(async (response) => {
            const imageBlob = await response.blob();
            const imageObjectURL = (await imageUrlToBase64(imageBlob)) as string;
            const webPImage = imageObjectURL?.replace(
                "data:application/octet-stream;base64",
                "data:image/webp;base64",
            );
            return webPImage;
        });
    } catch (e) {
        return Promise.resolve(undefined);
    }
};
