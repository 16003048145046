import { PixelRatio } from "react-native";

import { IK_QUALITY_FORMAT } from "../components/imagekit-image/contants";
import { formatImageId } from "../components/imagekit-image/helpers";
import { IK_PROPS, PRODUCT_IMG_FALLBACK } from "../components/store-media/constants";
import { GetMediaUrlOpts } from "../components/store-media/types";
import {
    getIKImageFormatForPlatform,
    getImageKitImageBaseUrl,
    getImageKitImageUrl,
    getImageKitVideoBaseUrl,
    getImageKitVideoUrl,
    ImageKitTransformation,
    isImageKitUrl,
} from "./imageKit";
import { isUri, isWebUri } from "./uri";

export type Media = {
    mediaId: string;
    url: string;
};

export interface TProductProps {
    mediaIds: string[];
    images?: string[];
    imageIds?: string[];
}

export const isUrlImage = (url: string): boolean => {
    return url?.toLowerCase()?.indexOf("image/") > -1;
};

export const isUrlVideo = (url: string): boolean => {
    return url?.toLowerCase()?.indexOf("video/") > -1;
};

export const isMediaIdAUrl = (mediaId?: string): boolean => isUri(mediaId || "");

export const isMediaIdAWebUrl = (mediaId?: string): boolean => isWebUri(mediaId || "");

export const isMediaIdADataOrTempFile = (mediaId?: string): boolean =>
    mediaId ? mediaId?.indexOf("/tmp/") > -1 || mediaId?.startsWith("data:") : false;

export const mediaAssetsCount = (media: Media[]): { image: number; video: number } => ({
    image: media.filter((val) => isUrlImage(val?.url)).length,
    video: media.filter((val) => isUrlVideo(val?.url)).length,
});

export const checkIfVideo = (file: File): boolean => {
    return file?.type?.startsWith("video");
};

/*
 * Show fallback image if media asset is a video
 */
export const showVideoFallbackImg = (mediaId: string): string =>
    isUrlVideo(mediaId) ? PRODUCT_IMG_FALLBACK : mediaId;

/*
 * helps in retrieving images for products from old and new attribute
 */
export const getMediaAssets = <T extends TProductProps | unknown>(product?: T): string[] => {
    const products = product as TProductProps;
    if (!products) {
        return [];
    }
    const totalImagesList = (products?.mediaIds || []).concat(
        (products?.images || products?.imageIds || []).filter(
            (item) => !(products?.mediaIds || []).includes(item),
        ),
    );
    return totalImagesList;
};

/*
 * Moved from seller-commons, helps to get imagekit URL from mediaId
 */
export const getMediaUrl = (mediaId: string, opts: Partial<GetMediaUrlOpts> = {}): string => {
    const formattedMediaId = formatImageId(mediaId);

    if (isMediaIdAUrl(formattedMediaId)) {
        return formattedMediaId;
    }

    const ikProps = {
        baseUrl: IK_PROPS.IMAGEKIT_MEDIA_BASE_URL,
        cloudName: IK_PROPS.IMAGEKIT_CLOUDNAME,
    };
    const isVideoAsset = isUrlVideo(formattedMediaId);

    opts.height = isVideoAsset ? "100%" : opts.height;

    const baseUrl = isVideoAsset
        ? getImageKitVideoBaseUrl(ikProps)
        : getImageKitImageBaseUrl(ikProps);

    if (isImageKitUrl(baseUrl)) {
        const transformations: Partial<ImageKitTransformation> = {
            width: opts.width
                ? PixelRatio.getPixelSizeForLayoutSize(Number(opts.width))
                : undefined,
            height: opts.height
                ? PixelRatio.getPixelSizeForLayoutSize(Number(opts.height))
                : undefined,
            format: opts.format ?? getIKImageFormatForPlatform(),
            crop: opts.crop ?? "fit",
            qualityAuto: opts.qualityAuto ?? IK_QUALITY_FORMAT.ECO,
        };

        const assetUrlProps = {
            transformations,
            cloudBaseUrl: IK_PROPS.IMAGEKIT_MEDIA_BASE_URL,
            cloudName: IK_PROPS.IMAGEKIT_CLOUDNAME,
            id: formattedMediaId,
        };

        const url = isVideoAsset
            ? getImageKitVideoUrl(assetUrlProps)
            : getImageKitImageUrl(assetUrlProps);

        return url;
    }

    return `${baseUrl}/${formattedMediaId}`;
};
