import React, { useRef } from "react";
import {
    ImageBackground,
    InteractionManager,
    Platform,
    Pressable,
    StyleSheet,
    useWindowDimensions,
} from "react-native";
import Video from "react-native-video";

import {
    addThumbnailSuffix,
    CloudinaryContextProvider,
    getImageKitVideoUrl,
    IK_QUALITY_FORMAT,
    ImageKitTransformation,
    InView,
    isMediaIdADataOrTempFile,
    isMediaIdAUrl,
    isMediaIdAWebUrl,
} from "@swiggy-private/react-native-ui";
import { useTheme } from "@swiggy-private/rn-dls";

import { ConditionalJSX } from "../../conditional-components";
import {
    IK_PROPS,
    IMAGE_PLACEHOLDER,
    MAX_VIDEO_LAYOUT_SIZE,
    PREVIEW_CONTROL_SIZE,
    TEST_ID,
} from "../constants";
import { StoreMediaProps } from "../types";
import { VideoControl, VideoFullScreen } from "./video-utility";
import { VideoPreviewMain } from "./render-video";

const isIOS = Platform.OS === "ios";
const isAndroid = Platform.OS === "android";

const VideoPreviewComponent: React.FC<StoreMediaProps> = (props) => {
    const {
        mediaId,
        style,
        autoPlayVideo = false,
        playControlSize,
        checkViewportVisibility = false,
        isInViewport,
        disableFullScreenPlay = false,
        width,
        onPress,
        disableVideoPlay = false,
        onLoadEnd,
        onLoadStart,
        startPerfMarker,
        stopPerfMarker,
        hidePlay,
    } = props;
    const videoRef = React.useRef<Video>(null);
    const widthViewPort = useWindowDimensions().width;
    const _style = StyleSheet.flatten(style);
    const videoWidth = width || _style?.width || 0;
    const videoSize = Math.min(widthViewPort * 0.65, MAX_VIDEO_LAYOUT_SIZE);
    const { value: theme } = useTheme();
    const [isVideoInView, setVideoInView] = React.useState(false);
    const manualControl = useRef(false);

    const [muted, setMuted] = React.useState(true);
    const [paused, setPaused] = React.useState(true);
    const [fullscreen, setFullscreen] = React.useState(false);

    const videoIKProps = React.useMemo(
        () => ({
            // eslint-disable-next-line no-extra-boolean-cast
            id: !!mediaId ? mediaId : IMAGE_PLACEHOLDER,
            cloudBaseUrl: IK_PROPS.IMAGEKIT_MEDIA_BASE_URL,
            cloudName: IK_PROPS.IMAGEKIT_CLOUDNAME,
        }),
        [mediaId],
    );
    const videoTransformationProps: Partial<ImageKitTransformation> = React.useMemo(
        () => ({
            qualityAuto: IK_QUALITY_FORMAT.GOOD,
            quality: 80,
            format: "auto",
            width: Number(videoWidth),
        }),
        [videoWidth],
    );

    const isMediaIdDataOrTempFile = isMediaIdADataOrTempFile(mediaId);
    const isMediaIdUrl = isMediaIdAUrl(mediaId);
    const isMediaIdWebOnlyUrl = isMediaIdAWebUrl(mediaId);

    const videoUrl = React.useMemo(
        () =>
            isMediaIdUrl || isMediaIdDataOrTempFile
                ? mediaId
                : getImageKitVideoUrl({
                      transformations: [
                          {
                              ...videoTransformationProps,
                          },
                      ],
                      ...videoIKProps,
                  }),
        [isMediaIdDataOrTempFile, isMediaIdUrl, mediaId, videoIKProps, videoTransformationProps],
    );

    const thumbUrl = React.useMemo(
        () =>
            isMediaIdDataOrTempFile
                ? mediaId
                : isMediaIdWebOnlyUrl
                ? addThumbnailSuffix(mediaId)
                : getImageKitVideoUrl({
                      transformations: [
                          {
                              ...videoTransformationProps,
                              crop: "fit",
                          },
                      ],
                      ...videoIKProps,
                      shouldFetchThumbnail: true,
                  }),
        [
            isMediaIdDataOrTempFile,
            isMediaIdWebOnlyUrl,
            mediaId,
            videoIKProps,
            videoTransformationProps,
        ],
    );

    const videoStyle = StyleSheet.flatten([
        styles.video,
        {
            width: videoSize,
            height: videoSize,
        },
        style,
        { backgroundColor: theme["color-basic-100"] },
    ]);

    const doPlay = React.useCallback(() => {
        setPaused(false);
        setMuted(false);
    }, []);

    const doPause = React.useCallback(() => {
        setPaused(true);
        setMuted(true);
    }, []);

    const onFullScreenVideoLoad = React.useCallback(() => {
        if (!videoRef.current) {
            return;
        }
        videoRef.current?.seek(0);
        videoRef.current?.presentFullscreenPlayer();
        doPlay();
    }, [doPlay]);

    const onPlay = React.useCallback(() => {
        if (hidePlay) {
            return;
        }
        onPress?.();
        if (!videoUrl || disableVideoPlay) {
            return;
        }
        if (!disableFullScreenPlay) {
            if (isAndroid) {
                setFullscreen(true);
            }
            if (isIOS) {
                onFullScreenVideoLoad();
            }
        }
        manualControl.current = true;
        setMuted((prevState) => !prevState);
        setPaused((prevState) => !prevState);
    }, [
        hidePlay,
        onPress,
        videoUrl,
        disableVideoPlay,
        disableFullScreenPlay,
        onFullScreenVideoLoad,
    ]);

    const onExit = React.useCallback(() => {
        setFullscreen(false);
        doPause();
    }, [doPause]);

    const onVideoEnd = React.useCallback(() => {
        doPause();
    }, [doPause]);

    const onMediaLoadStart = React.useCallback(() => {
        onLoadStart?.();
        startPerfMarker?.();
    }, [onLoadStart, startPerfMarker]);

    const onMediaLoadEnd = React.useCallback(() => {
        onLoadEnd?.();
        stopPerfMarker?.();
    }, [onLoadEnd, stopPerfMarker]);

    const canShowVideo = typeof mediaId === "string" && mediaId.length > 0;

    const onPreviewViewed = React.useCallback(
        (isVisible: boolean) => {
            if (!videoUrl || disableVideoPlay) {
                return;
            }

            if (checkViewportVisibility && !isVisible && !paused) {
                doPause();
            }
            setVideoInView(isVisible);
        },
        [disableVideoPlay, doPause, checkViewportVisibility, paused, videoUrl],
    );

    React.useEffect(() => {
        /** We can't use both these together, either InView component
         * in this file can tell if the component is in viewport
         * or the parent component of this file, not both
         */

        if (!autoPlayVideo) {
            return;
        }

        if (!checkViewportVisibility && isInViewport) {
            InteractionManager.runAfterInteractions(() => {
                doPlay();
            });
        }
    }, [isInViewport, checkViewportVisibility, autoPlayVideo, doPlay]);

    const isImageInView = checkViewportVisibility ? isVideoInView : isInViewport ?? true;

    const videoResolution = playControlSize ?? videoWidth > 100 ? "LARGE" : "SMALL";
    const controlContainerSize = PREVIEW_CONTROL_SIZE.CONTAINER[videoResolution];
    const controlIconSize = PREVIEW_CONTROL_SIZE.ICON[videoResolution];

    const playControlDimension = {
        container: {
            width: controlContainerSize,
            height: controlContainerSize,
        },
        icon: {
            width: controlIconSize,
            height: controlIconSize,
        },
    };

    const inViewStyle = StyleSheet.flatten([
        style,
        styles.videoInView,
        {
            backgroundColor: theme["color-basic-100"],
        },
    ]);

    if (!canShowVideo) {
        return null;
    }

    return (
        <CloudinaryContextProvider>
            <InView style={inViewStyle} onChange={onPreviewViewed}>
                <Pressable onPress={onPlay}>
                    <ConditionalJSX
                        condition={!!(videoUrl && isImageInView && !disableVideoPlay)}
                        fallback={
                            <ImageBackground
                                testID={TEST_ID.VIDEO_PREVIEW_THUMBNAIL}
                                source={{ uri: thumbUrl }}
                                style={videoStyle}
                                resizeMode="contain"
                                onLoadStart={onMediaLoadStart}
                                onLoadEnd={onMediaLoadEnd}
                            />
                        }>
                        <VideoPreviewMain
                            videoUrl={videoUrl}
                            thumbUrl={thumbUrl}
                            videoStyle={videoStyle}
                            muted={muted}
                            paused={paused}
                            onVideoEnd={onVideoEnd}
                            onMediaLoadStart={onMediaLoadStart}
                            onMediaLoadEnd={onMediaLoadEnd}
                            videoRef={videoRef}
                            doPause={doPause}
                        />
                    </ConditionalJSX>
                </Pressable>

                {!hidePlay ? (
                    <VideoControl
                        onPress={onPlay}
                        paused={paused}
                        playControlDimension={playControlDimension}
                    />
                ) : null}
                <ConditionalJSX condition={fullscreen && !disableVideoPlay}>
                    <VideoFullScreen
                        source={{ uri: videoUrl }}
                        controls={true}
                        onClose={onExit}
                        ignoreSilentSwitch="ignore"
                        allowsExternalPlayback={false}
                        onEnd={onVideoEnd}
                        onError={onExit}
                        muted={muted}
                        paused={paused}
                        vRef={videoRef}
                        resizeMode="contain"
                        onLoad={onFullScreenVideoLoad}
                        repeat
                    />
                </ConditionalJSX>
            </InView>
        </CloudinaryContextProvider>
    );
};

export const VideoPreview = React.memo(VideoPreviewComponent);

const styles = StyleSheet.create({
    video: {
        height: "100%",
        borderRadius: 16,
    },
    videoInView: {
        justifyContent: "center",
    },
    loader: {
        ...StyleSheet.absoluteFillObject,
        alignItems: "center",
        justifyContent: "center",
    },
});
