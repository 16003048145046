import React, { useCallback, useEffect, useRef } from "react";
import ChatSdk, { IChatService } from "@swiggy-private/connect-chat-sdk";
import { AppState, AppStateStatus } from "react-native";
import bigInt from "big-integer";
import { DeactivateContextOnBlur } from "@swiggy-private/react-native-ui";

import { ChatStateContext, ChatStateProvider } from "./contexts/chat-state";
import { ChatSdkProvider } from "./contexts/chat-sdk";
import { useChatDispatch } from "./hooks/use-chatdispatch";
import { useChatStateHandler, useOnMessageNotification } from "./hooks/use-chat-state-handler";
import { ChatServiceContextProvider } from "./contexts/chat-service-context";
import { logDebug } from "./helpers/log";

interface ChatProviderProps {
    sdk: ChatSdk | null;
    service?: IChatService | null;
}

const ChatProviderInner: React.FC<React.PropsWithChildren<ChatProviderProps>> = (props) => {
    const { children, sdk } = props;
    const chatDispatch = useChatDispatch();

    const isSdkDisconnected = useRef(false);
    const subscribedConversations = useRef<string[]>([]);
    const disconnectTimetoken = useRef<string | number>(0);
    const sdkRef = useRef(sdk);

    useChatStateHandler();
    useOnMessageNotification();

    const onAppForeground = useCallback(() => {
        if (sdk && isSdkDisconnected.current) {
            logDebug("Reconnecting chat");

            sdk.reconnect();
            isSdkDisconnected.current = false;
            sdk.subscribe({
                conversationIds: subscribedConversations.current,
                timetoken: String(disconnectTimetoken.current),
            });
        }
    }, [sdk]);

    const onAppBackground = useCallback(() => {
        if (sdk && !isSdkDisconnected.current) {
            logDebug("Disconnecting chat");

            subscribedConversations.current = sdk.getSubscribedConversations();
            sdk.disconnect();
            isSdkDisconnected.current = true;
            disconnectTimetoken.current = bigInt(Date.now()).multiply(10_000).toString();
            sdk.unsubscribeAll();
        }
    }, [sdk]);

    useEffect(() => {
        const fn = (state: AppStateStatus): void => {
            if (!sdk) {
                return;
            }

            if (state === "active" || state === "inactive") {
                onAppForeground();
                return;
            }

            onAppBackground();
        };

        const appStateSubscription = AppState.addEventListener("change", fn);

        return () => {
            if (appStateSubscription == null) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-expect-error
                AppState.removeEventListener("change", fn);
            } else {
                appStateSubscription.remove();
            }

            isSdkDisconnected.current = false;
            subscribedConversations.current = [];
            disconnectTimetoken.current = 0;
        };
    }, [onAppBackground, onAppForeground, sdk]);

    useEffect(() => {
        if (sdkRef.current && !sdk) {
            chatDispatch({ type: "RESET" });
        }

        sdkRef.current = sdk;
    }, [chatDispatch, sdk]);

    return <>{children}</>;
};

export const ChatProvider: React.FC<React.PropsWithChildren<ChatProviderProps>> = (props) => {
    return (
        <ChatSdkProvider sdk={props.sdk}>
            <ChatServiceContextProvider value={props.service || null}>
                <ChatStateProvider>
                    <ChatProviderInner {...props} />
                </ChatStateProvider>
            </ChatServiceContextProvider>
        </ChatSdkProvider>
    );
};

export const ChatContextContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
    return <DeactivateContextOnBlur Context={ChatStateContext}>{children}</DeactivateContextOnBlur>;
};
