import { SdkMessage, SdkFetchMessagesResponse } from "../interfaces";
import { ProviderFetchMessagesResponse } from "../interfaces/provider";

export const isValidMessage = (message: Partial<SdkMessage>): message is SdkMessage => {
    return !!(message.id && message.payload && message.type && message.timestamp);
};

export const getMessagesFromProviderResponse = (
    response: ProviderFetchMessagesResponse,
): SdkFetchMessagesResponse => {
    return Object.keys(response.conversations).reduce(
        (result, conversationId) => {
            const messages = response.conversations[conversationId];
            result.conversations[conversationId] = messages
                .filter((m) => isValidMessage(m.message as SdkMessage))
                .map((m) => {
                    return {
                        ...m,
                        message: m.message as SdkMessage,
                    };
                });

            return result;
        },
        { conversations: {} } as SdkFetchMessagesResponse,
    );
};
