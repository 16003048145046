import memoizeOne from "memoize-one";
import { Theme } from "@swiggy-private/rn-dls-theme";

import {
    ButtonColor,
    ButtonColorStyle,
    ButtonSizeStyleMap,
    ButtonStyle,
    ButtonStyleMeta,
} from "../interfaces/button";
import { ElementState } from "../style-service";

interface ButtonColorWithStateStyle extends ButtonColorStyle {
    state: Partial<Record<ElementState, Partial<ButtonStyle>>>;
}

const primaryButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme["color-primary"],
        backgroundColor: theme["color-primary"],
        textColor: theme["color-basic-0"],
        iconTintColor: theme["color-basic-0"],
        subTextColor: theme["color-basic-0"],
        state: {
            disabled: {
                backgroundColor: theme["color-basic-30"],
                borderColor: theme["color-basic-30"],
                textColor: theme["color-basic-75"],
                iconTintColor: theme["color-basic-75"],
                subTextColor: theme["color-basic-75"],
            },
        },
    }),
);

const secondaryButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme["color-primary-light"],
        backgroundColor: theme["color-primary-light"],
        textColor: theme["color-primary"],
        iconTintColor: theme["color-primary"],
        subTextColor: theme["color-primary"],
        state: {
            disabled: {
                backgroundColor: theme["color-basic-30"],
                borderColor: theme["color-basic-30"],
                textColor: theme["color-basic-75"],
                iconTintColor: theme["color-basic-75"],
                subTextColor: theme["color-basic-75"],
            },
        },
    }),
);

const tertiaryButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-background-primary"],
        textColor: theme["color-primary"],
        iconTintColor: theme["color-primary"],
        subTextColor: theme["color-primary"],
        state: {
            disabled: {
                iconTintColor: theme["color-basic-60"],
                textColor: theme["color-basic-60"],
                subTextColor: theme["color-basic-60"],
            },
        },
    }),
);

const warningButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme["color-warning-400"],
        backgroundColor: theme["color-warning-400"],
        textColor: theme["color-basic-75"],
        iconTintColor: theme["color-basic-75"],
        subTextColor: theme["color-basic-75"],
        state: {
            disabled: {
                backgroundColor: theme["color-warning-500"],
                borderColor: theme["color-warning-500"],
            },
        },
    }),
);

const criticalButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme.negative,
        backgroundColor: theme.negative,
        textColor: theme["color-basic-0"],
        iconTintColor: theme["color-basic-0"],
        subTextColor: theme["color-basic-0"],
        state: {
            disabled: {
                backgroundColor: theme["color-critical-500"],
                borderColor: theme["color-critical-500"],
            },
        },
    }),
);

const positiveButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-background-primary"],
        textColor: theme["color-positive"],
        iconTintColor: theme["color-positive"],
        subTextColor: theme["color-positive"],
        state: {
            disabled: {
                iconTintColor: theme["color-basic-60"],
                textColor: theme["color-basic-60"],
                subTextColor: theme["color-basic-60"],
            },
        },
    }),
);

const rnDlsPrimaryButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme.primary,
        backgroundColor: theme.primary,
        textColor: theme["color-basic-0"],
        iconTintColor: theme["color-basic-0"],
        subTextColor: theme["color-basic-0"],
        state: {
            disabled: {
                backgroundColor: theme.background_Button_Disabled,
                borderColor: theme.background_Button_Disabled,
                textColor: theme.text_Button_Disabled,
                iconTintColor: theme.text_Button_Disabled,
                subTextColor: theme.text_Button_Disabled,
            },
        },
    }),
);

const rnDlsSecondaryButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme.primary_Sub,
        backgroundColor: theme.primary_Sub,
        textColor: theme.primary,
        iconTintColor: theme.primary_Sub,
        subTextColor: theme.primary,
        state: {
            disabled: {
                backgroundColor: theme.background_Button_Disabled,
                borderColor: theme.background_Button_Disabled,
                textColor: theme.text_Button_Disabled,
                iconTintColor: theme.text_Button_Disabled,
                subTextColor: theme.text_Button_Disabled,
            },
        },
    }),
);

const rnDlsTertiaryButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-background-primary"],
        textColor: theme["color-primary"],
        iconTintColor: theme["color-primary"],
        subTextColor: theme["color-primary"],
        state: {
            disabled: {
                backgroundColor: theme.background_Button_Disabled,
                borderColor: theme.background_Button_Disabled,
                textColor: theme.text_Button_Disabled,
                iconTintColor: theme.text_Button_Disabled,
                subTextColor: theme.text_Button_Disabled,
            },
        },
    }),
);

const rnDlsWarningButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme["color-basic-15"],
        backgroundColor: theme["color-background-primary"],
        textColor: theme.negative,
        iconTintColor: theme.negative,
        subTextColor: theme.negative,
        state: {
            disabled: {
                backgroundColor: theme.background_Button_Disabled,
                borderColor: theme.background_Button_Disabled,
                textColor: theme.text_Button_Disabled,
                iconTintColor: theme.text_Button_Disabled,
                subTextColor: theme.text_Button_Disabled,
            },
        },
    }),
);

const rnDlsCriticalButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme.negative,
        backgroundColor: theme.negative,
        textColor: theme["color-basic-0"],
        iconTintColor: theme["color-basic-0"],
        subTextColor: theme["color-basic-0"],
        state: {
            disabled: {
                backgroundColor: theme.background_Button_Disabled,
                borderColor: theme.background_Button_Disabled,
                textColor: theme.text_Button_Disabled,
                iconTintColor: theme.text_Button_Disabled,
                subTextColor: theme.text_Button_Disabled,
            },
        },
    }),
);

const rnDlsPositiveButtonStyle = memoizeOne(
    (theme: Theme): ButtonColorWithStateStyle => ({
        borderColor: theme.positive,
        backgroundColor: theme.positive,
        textColor: theme["color-basic-0"],
        iconTintColor: theme["color-basic-0"],
        subTextColor: theme["color-basic-0"],
        state: {
            disabled: {
                backgroundColor: theme.background_Button_Disabled,
                borderColor: theme.background_Button_Disabled,
                textColor: theme.text_Button_Disabled,
                iconTintColor: theme.text_Button_Disabled,
                subTextColor: theme.text_Button_Disabled,
            },
        },
    }),
);

const buttonColorStyleMap = (color: ButtonColor, theme: Theme): ButtonColorWithStateStyle => {
    switch (color) {
        case "secondary":
            return secondaryButtonStyle(theme);
        case "tertiary":
            return tertiaryButtonStyle(theme);
        case "warning":
            return warningButtonStyle(theme);
        case "critical":
            return criticalButtonStyle(theme);
        case "primary":
            return primaryButtonStyle(theme);
        case "positive":
            return positiveButtonStyle(theme);
        case "rn_dls_secondary":
            return rnDlsSecondaryButtonStyle(theme);
        case "rn_dls_tertiary":
            return rnDlsTertiaryButtonStyle(theme);
        case "rn_dls_warning":
            return rnDlsWarningButtonStyle(theme);
        case "rn_dls_critical":
            return rnDlsCriticalButtonStyle(theme);
        case "rn_dls_primary":
            return rnDlsPrimaryButtonStyle(theme);
        case "rn_dls_positive":
            return rnDlsPositiveButtonStyle(theme);
    }
};

const buttonSizeStyleMap = memoizeOne(
    (theme: Theme): ButtonSizeStyleMap => ({
        tiny: {
            minWidth: theme["size-tiny"],
            minHeight: theme["size-tiny"],
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            paddingHorizontal: theme["space-medium"],
            paddingVertical: theme["space-xx-small"],
            textFontSize: theme["text-btn-5-font-size"],
            textFontFamily: theme["text-btn-5-font-family"],
            textLineHeight: theme["text-btn-5-line-height"],
            textLetterSpacing: theme["text-btn-5-letter-spacing"],
            textMarginHorizontal: 0,
            subTextMarginVertical: 1,
            subTextFontFamily: theme["text-body-3-regular-font-family"],
            subTextFontSize: theme["text-body-3-regular-font-size"],
            subTextLineHeight: theme["text-body-3-regular-line-height"],
            subTextLetterSpacing: theme["text-body-3-regular-letter-spacing"],
            iconWidth: 16,
            iconHeight: 16,
            iconMarginHorizontal: theme["space-x-small"],
        },
        small: {
            minWidth: theme["size-small"],
            minHeight: theme["size-small"],
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            paddingHorizontal: theme["space-medium"],
            paddingVertical: 6,
            textFontSize: theme["text-btn-4-font-size"],
            textFontFamily: theme["text-btn-4-font-family"],
            textLineHeight: theme["text-btn-4-line-height"],
            textLetterSpacing: theme["text-btn-4-letter-spacing"],
            textMarginHorizontal: 0,
            subTextMarginVertical: 1,
            subTextFontFamily: theme["text-body-3-regular-font-family"],
            subTextFontSize: theme["text-body-3-regular-font-size"],
            subTextLineHeight: theme["text-body-3-regular-line-height"],
            subTextLetterSpacing: theme["text-body-3-regular-letter-spacing"],
            iconWidth: 16,
            iconHeight: 16,
            iconMarginHorizontal: 8,
        },
        medium: {
            minWidth: theme["size-medium"],
            minHeight: theme["size-medium"],
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            paddingHorizontal: theme["space-medium"],
            paddingVertical: theme["space-x-small"],
            textFontSize: theme["text-btn-3-font-size"],
            textFontFamily: theme["text-btn-3-font-family"],
            textLineHeight: theme["text-btn-3-line-height"],
            textLetterSpacing: theme["text-btn-3-letter-spacing"],
            textMarginHorizontal: 0,
            subTextMarginVertical: 1,
            subTextFontFamily: theme["text-body-3-regular-font-family"],
            subTextFontSize: theme["text-body-3-regular-font-size"],
            subTextLineHeight: theme["text-body-3-regular-line-height"],
            subTextLetterSpacing: theme["text-body-3-regular-letter-spacing"],
            iconWidth: 16,
            iconHeight: 16,
            iconMarginHorizontal: theme["space-x-small"],
        },
        large: {
            minWidth: theme["size-large"],
            minHeight: theme["size-large"],
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            paddingHorizontal: theme["space-medium"],
            paddingVertical: theme["space-x-small"],
            textFontSize: theme["text-btn-2-font-size"],
            textFontFamily: theme["text-btn-2-font-family"],
            textLineHeight: theme["text-btn-2-line-height"],
            textLetterSpacing: theme["text-btn-2-letter-spacing"],
            textMarginHorizontal: 0,
            subTextMarginVertical: 1,
            subTextFontFamily: theme["text-body-3-regular-font-family"],
            subTextFontSize: theme["text-body-3-regular-font-size"],
            subTextLineHeight: theme["text-body-3-regular-line-height"],
            subTextLetterSpacing: theme["text-body-3-regular-letter-spacing"],
            iconWidth: 16,
            iconHeight: 16,
            iconMarginHorizontal: theme["space-x-small"],
        },
        giant: {
            minWidth: theme["size-giant"],
            minHeight: theme["size-giant"],
            borderWidth: theme["border-width"],
            borderRadius: theme["border-radius"],
            paddingHorizontal: theme["space-medium"],
            paddingVertical: theme["space-small"],
            textFontSize: theme["text-btn-2-font-size"],
            textFontFamily: theme["text-btn-2-font-family"],
            textLineHeight: theme["text-btn-2-line-height"],
            textLetterSpacing: theme["text-btn-2-letter-spacing"],
            textMarginHorizontal: 0,
            subTextMarginVertical: 1,
            subTextFontFamily: theme["text-body-3-regular-font-family"],
            subTextFontSize: theme["text-body-3-regular-font-size"],
            subTextLineHeight: theme["text-body-3-regular-line-height"],
            subTextLetterSpacing: theme["text-body-3-regular-letter-spacing"],
            iconWidth: 16,
            iconHeight: 16,
            iconMarginHorizontal: theme["space-x-small"],
        },
    }),
);

export const getButtonStyles = (meta: ButtonStyleMeta, theme: Theme): ButtonStyle => {
    const size = meta.size ?? "medium";
    const color = meta.color ?? "primary";
    const state = Array.isArray(meta.state) ? meta.state[0] : meta.state;

    const sizeStyle = buttonSizeStyleMap(theme)[size];
    const { state: stateStyle, ...colorStyle } = buttonColorStyleMap(color, theme);

    return {
        ...sizeStyle,
        ...colorStyle,
        ...(state ? stateStyle[state] ?? {} : {}),
    };
};
