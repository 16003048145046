import React, { createContext } from "react";
import { useChatReducer } from "../reducers/chat";

type ChatReducerType = ReturnType<typeof useChatReducer>;
type State = ChatReducerType[0];
type Dispatch = ChatReducerType[1];

export const ChatStateContext = createContext<State>({
    conversations: {},
    conversationsMeta: {},
    users: {},
    messages: {},
    messageBlocks: {},
});

// eslint-disable-next-line no-void
export const ChatDispatchContext = createContext<Dispatch>(() => void 0);

export const ChatStateProvider: React.FC<
    React.PropsWithChildren<{ initialState?: Partial<State> }>
> = ({ initialState, children }) => {
    const [state, dispatch] = useChatReducer(initialState);

    return (
        <ChatStateContext.Provider value={state}>
            <ChatDispatchContext.Provider value={dispatch}>{children}</ChatDispatchContext.Provider>
        </ChatStateContext.Provider>
    );
};
