/**
 * Converts a union like - "a" | "b" | "c"
 * to an intersection like - "a" & "b" & "c"
 */
type UnionToIntersection<U> = (U extends unknown ? (k: U) => void : never) extends (
    k: infer I,
) => void
    ? I
    : never;

type URIParams = Record<string, string> | undefined;

export class Endpoint {
    private uri: string;
    private uriParams: URIParams;

    private constructor(uri: string, uriParams: URIParams) {
        this.uri = uri;
        this.uriParams = uriParams;
    }

    public static from<U, Key extends string & keyof U>(
        uri: U extends Record<string, string>
            ? UnionToIntersection<`${string}{%%${Key}%%}${string}`>
            : string,
        uriParams?: U,
    ): Endpoint {
        return new Endpoint(uri as unknown as string, uriParams as unknown as URIParams);
    }

    toString(): string {
        if (!this.uriParams) {
            return this.uri;
        }

        const keys = Object.keys(this.uriParams);

        let endpoint = this.uri;
        keys.forEach((key) => {
            if (!this.uriParams) {
                return;
            }

            /**
             * Android doesn't support replaceAll
             * https://stackoverflow.com/questions/69297024/why-is-string-replaceall-not-a-function-on-android-react-native
             * */
            endpoint = endpoint.split(`{%%${key}%%}`).join(this.uriParams[key]);
        });

        return endpoint;
    }

    public getUri(): string {
        return this.uri;
    }

    public getUriParams(): URIParams {
        return this.uriParams;
    }
}
