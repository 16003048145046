import React from "react";
import { SafeAreaView, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { ElevationValue, SpacingValue, useTheme } from "@swiggy-private/rn-dls";

import { ImageGalleryHeaderProps } from "./types";

export const ImageGalleryHeader: React.FC<ImageGalleryHeaderProps> = ({
    disableClose = false,
    onClose,
    headerHeight = 64,
    ...props
}) => {
    const { value: theme } = useTheme();

    if (disableClose) {
        return null;
    }

    const style: StyleProp<ViewStyle> = [
        styles.container,
        {
            paddingHorizontal: SpacingValue["space-medium"],
            borderBottomWidth: 1,
            borderBottomColor: theme["color-basic-15"],
            height: headerHeight,
            alignItems: "center",
            flexDirection: "row",
            ...ElevationValue[1],
        },
    ];

    return (
        <SafeAreaView>
            <View style={style} {...props}>
                <SvgIcon
                    icon="Close"
                    onPress={onClose}
                    hitSlop={{ top: 16, right: 16, left: 16, bottom: 16 }}
                    width={16}
                    height={16}
                    color="color-basic-100"
                />
            </View>
        </SafeAreaView>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
});
