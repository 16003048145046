import { ShareAPI } from "../interfaces/share";

type SharePackage = Omit<ShareAPI, "canShare">;

let SharePackage: SharePackage | null = null;

if (SharePackage == null) {
    try {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        SharePackage = require("react-native-web").Share;
    } catch (error) {}
}

export default SharePackage;
