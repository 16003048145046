import React from "react";
import { StyleSheet, ViewProps, View, ViewStyle } from "react-native";

import { useDLS } from "../../styles/style-service";

import type { ButtonProps, ButtonElement } from "../button";
import type { StyledComponentProps } from "../../styles/styled";
import type { ButtonColor } from "../../styles/interfaces/button";
import type { ButtonGroupStyle } from "../../styles/interfaces/button-group";
import type { ChildrenWithProps } from "../../../typings";

export interface ButtonGroupProps extends StyledComponentProps, ViewProps {
    children?: ChildrenWithProps<ButtonProps>;
    color?: ButtonColor;
}

const getComponentStyle = (
    source: ButtonGroupStyle,
): { container: ViewStyle; button: ViewStyle } => {
    const { dividerBackgroundColor, dividerWidth, ...containerParameters } = source;
    return {
        container: {
            ...containerParameters,
            borderWidth: containerParameters.borderWidth,
        },
        button: {
            borderWidth: dividerWidth,
            borderColor: dividerBackgroundColor,
        },
    };
};

const isFirstElement = (index: number): boolean => {
    return index === 0;
};

const isLastElement = (index: number, props: ButtonGroupProps): boolean => {
    return index === React.Children.count(props.children) - 1;
};

const renderButtonElement = (
    element: ButtonElement,
    index: number,
    props: ButtonGroupProps,
    style: ViewStyle,
    // eslint-disable-next-line max-params
): ButtonElement => {
    const { size, color, state } = props;

    const shapeStyle: ViewStyle | null = !isLastElement(index, props)
        ? {
              borderEndWidth: style.borderWidth,
              borderEndColor: style.borderColor,
          }
        : null;

    const startShapeStyle: ViewStyle | null = isFirstElement(index)
        ? {
              borderTopStartRadius: style.borderRadius,
              borderBottomStartRadius: style.borderRadius,
          }
        : null;

    const endShapeStyle: ViewStyle | null = isLastElement(index, props)
        ? {
              borderTopEndRadius: style.borderRadius,
              borderBottomEndRadius: style.borderRadius,
          }
        : null;

    return React.cloneElement(element, {
        size,
        color,
        state,
        disableElevation: true,
        key: index,
        style: [style, styles.button, shapeStyle, startShapeStyle, endShapeStyle],
    });
};

const renderButtonElements = (
    source: ChildrenWithProps<ButtonProps>,
    props: ButtonGroupProps,
    style: ViewStyle,
): ButtonElement[] => {
    return React.Children.map(source, (element: ButtonElement, index: number): ButtonElement => {
        return renderButtonElement(element, index, props, style);
    });
};

/**
 * A group of buttons with additional styles provided by DLS.
 * ButtonGroup should contain Button components to provide a usable component.
 *
 * @param props {@link ButtonGroupProps}
 */
export const ButtonGroup: React.FC<ButtonGroupProps> = (props) => {
    const dls = useDLS("buttonGroup", props);
    const dlsStyle = getComponentStyle(dls.style);

    const { style, children, color, size, state, ...viewProps } = props;
    const containerStyle = StyleSheet.compose([dlsStyle.container, styles.container], style);

    return (
        <View {...viewProps} style={containerStyle}>
            {children && renderButtonElements(children, props, dlsStyle.button)}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        overflow: "hidden",
    },
    button: {
        borderRadius: 0,
        borderWidth: 0,
    },
});
