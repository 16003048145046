import { ColorFormat, ColorRGB, Props } from "./types";

export const linearEase = ({
    time,
    start,
    goal,
    duration,
}: {
    time: number;
    start: number;
    goal: number;
    duration: number;
}): number => {
    if (duration === 0) {
        return start;
    }

    const currentTime = time / duration;
    return start + goal * currentTime;
};

export const getRGB = (color: string): number[] =>
    color
        // eslint-disable-next-line max-params
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
        .substring(1)
        .match(/.{2}/g)
        ?.map((x) => parseInt(x, 16)) ?? [];

export const getStroke = (props: Props, remainingTime: number): ColorFormat => {
    const { colors, colorsTime, isSmoothColorTransition = true } = props;
    if (typeof colors === "string") {
        return colors;
    }

    const index =
        colorsTime?.findIndex(
            (time, i) => time >= remainingTime && remainingTime >= colorsTime[i + 1],
        ) ?? -1;

    if (!colorsTime || index === -1) {
        return colors[0];
    }

    if (!isSmoothColorTransition) {
        return colors[index];
    }

    const currentTime = colorsTime[index] - remainingTime;
    const currentDuration = colorsTime[index] - colorsTime[index + 1];
    const startColorRGB = getRGB(colors[index]);
    const endColorRGB = getRGB(colors[index + 1]);

    return `rgb(${startColorRGB
        .map((color) =>
            Math.floor(
                linearEase({
                    time: currentTime,
                    start: color,
                    goal: endColorRGB[index] - color,
                    duration: currentDuration,
                }),
            ),
        )
        .join(",")})`;
};

export const getPathProps = (
    size: number,
    strokeWidth: number,
    rotation: "clockwise" | "counterclockwise",
): { path: string; pathLength: number } => {
    const halfSize = size / 2;
    const halfStrokeWith = strokeWidth / 2;
    const arcRadius = halfSize - halfStrokeWith;
    const arcDiameter = 2 * arcRadius;
    const rotationIndicator = rotation === "clockwise" ? "1,0" : "0,1";

    const pathLength = 2 * Math.PI * arcRadius;
    const path = `m ${halfSize},${halfStrokeWith} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,${arcDiameter} a ${arcRadius},${arcRadius} 0 ${rotationIndicator} 0,-${arcDiameter}`;

    return { path, pathLength };
};

export const getStartAt = (duration: number, initialRemainingTime?: number): number => {
    if (duration === 0 || duration === initialRemainingTime) {
        return 0;
    }

    return typeof initialRemainingTime === "number" ? duration - initialRemainingTime : 0;
};

export const getWrapperStyle = (size: number): React.CSSProperties => ({
    position: "relative",
    width: size,
    height: size,
});

export const timeStyle: React.CSSProperties = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
};

export const getIsColorBetweenColors = (
    color: ColorRGB,
    start: ColorRGB,
    end: ColorRGB,
): boolean => {
    const getIsInRange = (x: number, min: number, max: number): boolean =>
        (x - min) * (x - max) <= 0;

    const getRGBArray = (clr: ColorRGB): number[] | undefined =>
        clr
            .match(/(\d+),(\d+),(\d+)/)
            ?.splice(1, 4)
            .map((c: string) => parseInt(c, 10));

    const colorRGB = getRGBArray(color);
    const startRGB = getRGBArray(start);
    const endRGB = getRGBArray(end);

    if (
        typeof colorRGB === "undefined" ||
        typeof startRGB === "undefined" ||
        typeof endRGB === "undefined"
    ) {
        return false;
    }

    return colorRGB.every((c, index) => getIsInRange(c, startRGB[index], endRGB[index]));
};
