import * as React from "react";

import { WidgetAnimatorProps } from "../index";

type ChildrenCloneProps = Pick<WidgetAnimatorProps, "children" | "widgetId" | "cloneOffset">;

const ChildrenCloneComponent: React.FC<ChildrenCloneProps> = ({
    children,
    widgetId,
    cloneOffset = 0,
}) => {
    return React.cloneElement(children, {
        ...children.props,
        style: { ...children.props.style, marginRight: -cloneOffset },
        listKey: () => {
            `key_clone_${widgetId}`;
        },
    });
};

export const ChildrenClone = React.memo(ChildrenCloneComponent);
