import React, { useEffect } from "react";
import { View, StyleSheet, Keyboard, TouchableWithoutFeedback, Platform } from "react-native";
import { SpacingValue, useTheme } from "@swiggy-private/rn-dls";
import { useDebounceFn, useMountedRef } from "@swiggy-private/react-hooks";
import { useMount } from "@swiggy-private/react-hooks";
import { DashBorderLine } from "@swiggy-private/react-native-ui";

import { LocationPermissionModal } from "../location-permission-modal";
import { fetchLocationsApi, fetchGeocodeApi } from "../../network";
import { Prediction } from "../../interfaces";
import { useAddressContext } from "../../contexts";
import { removeSpaces } from "../../helpers";
import { useMapEndpointContext } from "../../contexts/map-endpoint-context";

import { SEARCH_LOCATION_CONSTS } from "./constants/index";
import { Search } from "./components/search";
import { AutoLocate } from "./components/auto-locate";
import { Result } from "./components/result";
import { autoLocate } from "./helpers";
import { EmptySearchResultScreen } from "./components/empty-search-result-screen";
import { GeoPosition } from "./helpers/types";

type Props = {
    onPressCTA: () => void;
    onMount?: () => void;
    onError?: (err: string) => void;
    onLocateMePress?: () => void;
    placeholder?: string;
};

export const SearchLocation: React.FC<Props> = ({
    onPressCTA,
    onMount,
    onError,
    onLocateMePress,
    placeholder,
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [locations, updateLocations] = React.useState<null | Prediction[]>(null);
    const [coordinates, setCoordinates] = React.useState<null | {
        latitude: number;
        longitude: number;
    }>(null);

    const { updateState } = useAddressContext();
    const { mapEndpoint } = useMapEndpointContext();

    const { value: theme } = useTheme();
    const mountedRef = useMountedRef();

    const searchLocation = useDebounceFn(
        async (query: string): Promise<void> => {
            if (removeSpaces(query).length) {
                try {
                    const { data: predictions } = await fetchLocationsApi(query, {
                        baseUrl: mapEndpoint,
                    });

                    mountedRef.current && updateLocations(predictions);
                } catch (err) {
                    mountedRef && onError?.(err as string);
                }
            } else {
                mountedRef.current && updateLocations(null);
            }
        },
        { wait: SEARCH_LOCATION_CONSTS.DEBOUNCE_TIME },
    );

    const updateCurrentCoordinates = React.useCallback(
        (position: GeoPosition) => {
            if (!mountedRef.current) {
                return;
            }

            const { latitude, longitude } = position.coords;
            updateState({ lat: latitude.toString(), lng: longitude.toString() });
            setCoordinates({ latitude, longitude });
        },
        [mountedRef, updateState],
    );

    const handleAutoLocate = React.useCallback(() => {
        try {
            onLocateMePress?.();
            autoLocate()
                .then((position) => {
                    if (mountedRef.current && position) {
                        updateCurrentCoordinates(position);
                    } else {
                        mountedRef.current && setShowModal(true);
                    }
                })
                .catch((err) => {
                    if (mountedRef.current) {
                        Platform.OS === "web" && setShowModal(true);
                        onError?.(err.message);
                    }
                });
        } catch (err) {
            mountedRef && onError?.(err as string);
        }
    }, [mountedRef, onError, onLocateMePress, updateCurrentCoordinates]);

    const resultCTA = React.useCallback(
        async (placeId: string) => {
            try {
                const response = await fetchGeocodeApi(placeId, { baseUrl: mapEndpoint });
                if (mountedRef.current) {
                    const { lat, lng } = response.data[0].geometry.location;

                    updateState({
                        lat: lat.toString(),
                        lng: lng.toString(),
                    });

                    setCoordinates({
                        latitude: lat,
                        longitude: lng,
                    });
                }
            } catch (err) {
                mountedRef && onError?.(err as string);
            }
        },
        [mapEndpoint, mountedRef, onError, updateState],
    );

    useMount(() => {
        onMount?.();
    });

    useEffect(() => {
        if (coordinates) {
            onPressCTA();
        }
    }, [coordinates, onPressCTA]);

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View
                style={[
                    styles.mainContainer,
                    { backgroundColor: theme["color-background-primary"] },
                ]}>
                <View style={styles.topContainer}>
                    <Search
                        placeholderText={placeholder || SEARCH_LOCATION_CONSTS.INPUT_PLACEHOLDER}
                        onChangeText={searchLocation}
                    />
                    <DashBorderLine
                        borderColor={theme["color-basic-15"]}
                        type="solid"
                        style={styles.divider}
                    />
                    <AutoLocate
                        locateMeText={SEARCH_LOCATION_CONSTS.LOCATE_ME_TEXT}
                        handleCTA={handleAutoLocate}
                    />
                </View>

                {locations?.length === 0 ? <EmptySearchResultScreen /> : null}

                {Array.isArray(locations) && locations.length > 0 ? (
                    <View style={styles.predictions}>
                        <View
                            style={{
                                ...styles.shadedBox,
                                backgroundColor: theme["color-basic-5"],
                            }}
                        />
                        <Result predictions={locations} handleSearchResultCTA={resultCTA} />
                    </View>
                ) : null}

                <LocationPermissionModal showModal={showModal} setShowModal={setShowModal} />
            </View>
        </TouchableWithoutFeedback>
    );
};

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        paddingTop: SpacingValue["space-x-small"],
    },
    topContainer: {
        paddingHorizontal: SpacingValue["space-medium"],
    },
    shadedBox: {
        height: SpacingValue["space-small"],
        marginTop: SpacingValue["space-x-large"],
    },
    predictions: {
        flex: 1,
    },
    divider: {
        transform: [{ scaleX: 1.5 }],
    },
});
