import { Platform } from "react-native";
import { nanoid } from "nanoid/non-secure";
import mime from "mime";
import { xhrFetch } from "@swiggy-private/http-client";
import { DOC_MIME_TYPES } from "@swiggy-private/connect-business-commons";

import {
    Asset,
    CameraOptions,
    ErrorCode,
    ImageLibraryOptions,
    ImagePickerResponse,
    launchCamera,
    launchImageLibrary,
} from "react-native-image-picker";

import { DocumentPickerResponse, pickSingle, DocumentPickerOptions } from "./document-picker";
import { MediaFile, UploadedMediaFile, MediaSource } from "./types";
import { SUPPORTED_FILE_TYPES } from "./constants";

const DEFAULT_IMAGE_MAX_HEIGHT = 1024;
const DEFAULT_IMAGE_MAX_WIDTH = 1024;
const DEFAULT_DURATION_LIMIT_SECS = 30;
const isWeb = Platform.OS === "web";

const DEFAULT_IMAGE_LIBRARY_OPTIONS: ImageLibraryOptions = {
    mediaType: "photo",
    maxWidth: DEFAULT_IMAGE_MAX_HEIGHT,
    maxHeight: DEFAULT_IMAGE_MAX_WIDTH,
    videoQuality: "low",
    quality: 1.0,
};

export function getValidMediaFiles(assets: Asset[]): MediaFile[] {
    return assets
        .filter((a) => !!a.uri)
        .map((a) => {
            if (isWeb && a.uri?.startsWith("data:")) {
                const matches = /data:(.+);/.exec(a.uri);
                if (Array.isArray(matches) && matches[1]) {
                    a.type = matches[1];
                    a.fileSize = a.uri.length;
                }
            }

            return {
                path: a.uri ?? "",
                fileSize: a.fileSize ?? 0,
                type: (a.type || "").startsWith("video/") ? "video" : "image",
                duration: a.duration,
                contentType: a.type,
                fileName: a.fileName,
            };
        });
}

const imagePickerResponseHandler =
    (resolve: (value: MediaFile[]) => void, reject: (reason?: unknown) => void) =>
    (response: ImagePickerResponse) => {
        if (response.errorMessage && __DEV__) {
            console.debug("image picker response: " + response.errorMessage);
        }

        if (response.errorCode) {
            return reject(new Error(getImagePickerErrorMessage(response.errorCode)));
        }

        if (response.didCancel) {
            return resolve([]);
        }

        resolve(getValidMediaFiles(response.assets ?? []));
    };

const getImagePickerErrorMessage = (errorCode: ErrorCode): string => {
    switch (errorCode) {
        case "camera_unavailable":
            return "Camera not available on this device";
        case "permission":
            return "Permission not satisfied";
        case "others":
            return "Oops!! something went wrong";
    }
};

export const openGallery = (
    opts: ImageLibraryOptions = { mediaType: "photo", selectionLimit: 1 },
): Promise<MediaFile[]> => {
    return new Promise((resolve, reject) => {
        launchImageLibrary(opts, imagePickerResponseHandler(resolve, reject));
    });
};

export const openCamera = (options: CameraOptions): Promise<MediaFile[]> => {
    return new Promise((resolve, reject) => {
        launchCamera(options, imagePickerResponseHandler(resolve, reject));
    });
};

export const openDocumentPicker = (options: DocumentPickerOptions<any>): Promise<MediaFile[]> => {
    return new Promise((resolve, reject) => {
        pickSingle(options)
            .then((response: DocumentPickerResponse) => {
                if (!response.uri) {
                    return resolve([]);
                }

                resolve(getValidDocumentFiles([response] ?? []));
            })
            .catch((err) => {
                if (err && __DEV__) {
                    console.debug("document picker response: " + err?.errorMessage);
                    return reject(new Error("Failed to select selected file", err?.errorMessage));
                }
            });
    });
};

export function getValidDocumentFiles(assets: DocumentPickerResponse[]): MediaFile[] {
    return assets
        .filter((asset) => !!asset.uri)
        .map((asset) => {
            const { uri, size, type, name = "" } = asset;
            return {
                path: uri ?? "",
                fileSize: size ?? 0,
                type: "file",
                contentType: type || "",
                fileName: name || "",
            };
        });
}

export function pickMediaFromGallery(options?: Partial<ImageLibraryOptions>): Promise<MediaFile[]> {
    const galleryOptions: ImageLibraryOptions = {
        ...DEFAULT_IMAGE_LIBRARY_OPTIONS,
        selectionLimit: 1,
        ...options,
    };

    return openGallery(galleryOptions);
}

export function pickMediaUsingCamera({
    durationLimit,
    ...options
}: Partial<CameraOptions> = {}): Promise<MediaFile[]> {
    const cameraOptions: CameraOptions = {
        ...DEFAULT_IMAGE_LIBRARY_OPTIONS,
        durationLimit: durationLimit || DEFAULT_DURATION_LIMIT_SECS,
        cameraType: "back",
        saveToPhotos: false,
        ...options,
    };

    return openCamera(cameraOptions);
}

export function pickMediaUsingFiles({
    ...options
}: Partial<DocumentPickerOptions<any>> = {}): Promise<MediaFile[]> {
    const documentPickerOptions: DocumentPickerOptions<any> = {
        type: SUPPORTED_FILE_TYPES,
        ...options,
    };
    return openDocumentPicker(documentPickerOptions);
}

export interface GenerateMediaUrlParams {
    fileName: string;
    contentLength: number;
    source?: MediaSource;

    contentType?: string;
}

interface GenerateMediaUploadUrlResponse {
    url: string;
    headers: Record<string, string | string[]>;
    mediaId: string;
}

export function uploadMedia({
    files,
    generateMediaUrl,
    onError,
    source = "PUBLIC_ASSET",
}: {
    files: MediaFile[];
    generateMediaUrl: (params: GenerateMediaUrlParams) => Promise<GenerateMediaUploadUrlResponse>;
    onError?: (err?: Error) => void;
    source?: MediaSource;
}): {
    cancel: () => void;
    result: Promise<UploadedMediaFile[]>;
} {
    const controller = new AbortController();
    const signal = controller.signal;

    const upload = async (): Promise<UploadedMediaFile[]> => {
        const uploadedMedia: UploadedMediaFile[] = [];
        for await (const file of files) {
            if (signal.aborted) {
                return uploadedMedia;
            }

            try {
                const mediaId = await UploadSingleMedia({ file, generateMediaUrl, signal, source });
                if (mediaId) {
                    uploadedMedia.push({
                        type: file.type,
                        mediaId,
                    });
                }
            } catch (err) {
                __DEV__ && console.debug("error while uploading file ", err);
                onError?.(err as Error);
            }
        }

        return uploadedMedia;
    };

    return { cancel: controller.abort, result: upload() };
}

const UploadSingleMedia = async ({
    file,
    generateMediaUrl,
    signal,
    source,
}: {
    file: MediaFile;
    generateMediaUrl: (params: GenerateMediaUrlParams) => Promise<GenerateMediaUploadUrlResponse>;
    signal?: AbortSignal;
    source?: MediaSource;
}): Promise<string | void> => {
    const { path, fileSize: size, contentType: fileType, fileName: name } = file;

    let actualFilePath = path,
        fileSize = size;

    if (Platform.OS === "android") {
        const RNFS = await import("react-native-fs");

        if (Platform.OS === "android" && file.path.startsWith("content://")) {
            const filePath = `${RNFS.TemporaryDirectoryPath}/${nanoid()}`;
            await RNFS.copyFile(file.path, filePath);
            file.path = filePath;
        }
        const fileStat = await RNFS.stat(decodeURIComponent(file.path));
        actualFilePath = fileStat.originalFilepath || fileStat.path;
        fileSize = fileStat.size;
    }

    const fileUri = isWeb ? actualFilePath : "file:///" + actualFilePath.split("file:/").join("");
    let contentType = isWeb && fileType ? fileType : mime.getType(fileUri) || file.contentType;

    // For doc files change content type to text/*
    contentType = DOC_MIME_TYPES.includes(contentType || "")
        ? "text/*"
        : contentType || file.contentType;

    // this is needed to retrieve name for digital assets.
    const uniqueId = nanoid();
    const fileName = name ? `${uniqueId}/${name}` : uniqueId;

    if (contentType == null) {
        throw new Error("invalid content type");
    }

    if (fileName == null) {
        throw new Error("invalid file name");
    }

    return new Promise(async (resolve, reject) => {
        let contentLength = fileSize;
        let fileBlob: null | Blob = null;

        if (isWeb) {
            fileBlob = await fetch(fileUri).then((it) => it.blob());
            contentLength = fileBlob?.size || contentLength;
        }

        const apiParams = {
            contentType: contentType ?? "",
            fileName,
            contentLength,
            source,
        };

        const { url, headers, mediaId } = await generateMediaUrl(apiParams);

        if (signal?.aborted) {
            return;
        }

        __DEV__ && console.debug("media upload url", url);

        const response = xhrFetch(url, {
            method: "PUT",
            headers,
            body: fileBlob || {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                uri: fileUri,
                type: contentType,
                name: fileName,
            },
            signal,
            timeout: 120_000,
        });

        return response
            .then(() => {
                __DEV__ && console.debug("media successfully uploaded");
                return resolve(mediaId);
            })
            .catch((err) => {
                const error = new Error("Error while uploading the media " + err);
                return reject(error);
            });
    });
};
