import React from "react";
import { Pressable, StyleProp, StyleSheet, TextStyle } from "react-native";
import LinearGradient from "react-native-linear-gradient";

import { Box, Stack, useSelectScreen } from "@swiggy-private/rn-adaptive-layout";
import { SvgIcon } from "@swiggy-private/connect-svg-icons";
import { Text, SpacingValue, useTheme, FontFamily } from "@swiggy-private/rn-dls";
import { useMount } from "@swiggy-private/react-hooks";

import { COPIES } from "./constants";
import { StarSellerBadgeProps } from "../../interfaces/seller-badge";

const StarSellerBadgeComponent: React.FC<StarSellerBadgeProps> = (props) => {
    const { value: theme } = useTheme();

    const starOnlyPaddingHorizontalValue = 3 * SpacingValue["space-xxx-small"];
    const iconSize = props.iconSize || 12;

    /** Rest of the colors are not part of DLS */
    const GRADIENT_COLORS = {
        FILL: [theme["color-basic-0"].toString(), "#FCFCFC", "#EDF6F0"],
        STROKE: [theme["color-basic-0"].toString(), "#E3F1E8"],
    };

    const paddingHorizontal = useSelectScreen({
        lg: props.showPrimaryText ? SpacingValue["space-medium"] : starOnlyPaddingHorizontalValue,
        default: props.showPrimaryText
            ? SpacingValue["space-small"]
            : starOnlyPaddingHorizontalValue,
    });

    const primaryText = React.useMemo(
        () =>
            props.showPrimaryText && props.showSecondaryText
                ? `${COPIES.PRIMARY_TEXT} on `
                : COPIES.PRIMARY_TEXT,
        [props.showPrimaryText, props.showSecondaryText],
    );

    const borderRadius = React.useMemo(
        () => (props.showPrimaryText ? 24 : 12),
        [props.showPrimaryText],
    );

    const rootStyle = React.useMemo(
        () => StyleSheet.flatten([{ paddingHorizontal }, styles.stack, props.rootContainerStyle]),
        [paddingHorizontal, props.rootContainerStyle],
    );

    const primaryTextStyle = {
        fontFamily: FontFamily.PROXIMA_NOVA_CONDENSED_SEMIBOLD,
    } as StyleProp<TextStyle>;

    const secondaryTextStyle = {
        fontFamily: FontFamily.PROXIMA_NOVA_BLACK,
    } as StyleProp<TextStyle>;

    const outerGradientStyle = React.useMemo(
        () =>
            StyleSheet.flatten([
                styles.gradient,
                styles.outerGradient,
                { borderRadius },
                props.outerGradientStyle,
            ]),
        [borderRadius, props.outerGradientStyle],
    );

    useMount(() => props.onMount?.());

    return (
        <LinearGradient
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            locations={[0.3, 1]}
            colors={props.gradientStrokeColors ?? GRADIENT_COLORS.STROKE}
            style={outerGradientStyle}>
            <Pressable disabled={!props.onPress} onPress={props.onPress}>
                <LinearGradient
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 0 }}
                    locations={[0.2, 0.3, 1]}
                    colors={props.gradientFillColors ?? GRADIENT_COLORS.FILL}
                    style={[styles.gradient, { borderRadius }]}>
                    <Stack
                        direction="row"
                        style={rootStyle}
                        alignItems="center"
                        spacing={SpacingValue["space-xx-small"]}>
                        <Box>
                            <SvgIcon
                                icon="StarBadge"
                                height={iconSize}
                                width={iconSize}
                                style={props.iconStyle}
                                color="color-positive-500"
                            />
                        </Box>

                        {props.showPrimaryText || props.showSecondaryText ? (
                            <Text>
                                <Text
                                    category="b3"
                                    color="color-basic-60"
                                    style={[primaryTextStyle, props.primaryTextStyle]}
                                    {...props.primaryTextProps}>
                                    {props.showPrimaryText ? primaryText : ""}
                                </Text>
                                <Text
                                    category="b3"
                                    color="color-positive-500"
                                    style={[secondaryTextStyle, props.secondaryTextStyle]}
                                    {...props.secondaryTextProps}>
                                    {props.showSecondaryText ? COPIES.SECONDARY_TEXT : ""}
                                </Text>
                            </Text>
                        ) : null}
                    </Stack>
                </LinearGradient>
            </Pressable>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    outerGradient: {
        padding: 1, // It has to be odd number here to make the stroke gradient look good
    },
    gradient: {
        alignSelf: "flex-end",
    },
    stack: {
        paddingVertical: 3 * SpacingValue["space-xxx-small"],
    },
});

export const StarSellerBadge = React.memo(StarSellerBadgeComponent);
