import { ApiClient } from "@swiggy-private/connect-api-core";
import { Endpoint } from "@swiggy-private/http-client";

import { APIService } from "./base";
import type { User } from "../interfaces/user";

const API_V1_PATH = "/api/v1/user";

export class UserAPIService extends APIService {
    private static create = (endpoint: string, client: ApiClient): UserAPIService => {
        return new UserAPIService(endpoint, client);
    };

    static createV1 = (host: string, client: ApiClient): UserAPIService => {
        return UserAPIService.create(host + API_V1_PATH, client);
    };

    fetchUser = async (): Promise<User> => {
        const endpoint = Endpoint.from(`${this.endpoint}/profile`);

        const response = await this.client.get<User>({ endpoint });

        return this.handleResponse(response, "failed to fetch user info");
    };

    updateUser = async (payload: { name: string }): Promise<UpdateUserResponse> => {
        const endpoint = Endpoint.from(`${this.endpoint}/profile`);

        const response = await this.client.put<UpdateUserResponse>({
            endpoint,
            body: {
                ...payload,
            },
        });

        return this.handleResponse(response, "failed to update user");
    };
}

interface UpdateUserResponse {
    name: {
        statusCode: number;
        statusMessage: string;
    };
}
