import React, { ForwardRefRenderFunction, useCallback, useContext } from "react";
import { InputProps, Input } from "@swiggy-private/rn-dls";
import { FormContext } from "./form-context";
import { TextInput } from "react-native";

export type FormInputProps = InputProps & {
    name: string;
    removeError?: (name: string) => void;
};

const FormInputComponent: ForwardRefRenderFunction<TextInput, FormInputProps> = (
    { onChangeText, removeError, ...props },
    ref,
) => {
    const { updateField } = useContext(FormContext);
    const onChangeTextCallback = useCallback(
        (text: string) => {
            updateField(props.name, text.trim());
            if (props.error && removeError) {
                removeError(props.name);
            }

            if (onChangeText) {
                onChangeText(text);
            }
        },
        [onChangeText, updateField, props.name, props.error, removeError],
    );

    return <Input {...props} ref={ref} onChangeText={onChangeTextCallback} />;
};

export const FormInput = React.forwardRef(FormInputComponent);
