import { CommonActions, NavigationProp, ParamListBase } from "@react-navigation/native";

interface PopAndNavigateParams {
    navigation: NavigationProp<ParamListBase>;
    numberOfScreensToPop: number;
    newRouteName: string;
    params: object;
}

/**
 * Pop the last 'n' screens from the stack and navigate to a new route
 * @param navigation - The navigation object
 * @param numberOfScreensToPop - The number of screens to pop from the stack
 * @param newRouteName - The name of the new route to navigate to
 * @param params - The params to pass to the new route
 */
export const popAndNavigate = ({
    navigation,
    numberOfScreensToPop = 1,
    newRouteName,
    params,
}: PopAndNavigateParams): void => {
    if (!navigation?.dispatch) {
        return;
    }

    navigation.dispatch((state) => {
        // Ensure we don't remove more screens than exist in the stack
        const safeNumberOfScreensToPop = Math.min(numberOfScreensToPop, state.routes.length - 1);

        // Remove the last 'n' screens
        const routes = state.routes.slice(0, -safeNumberOfScreensToPop);

        // Push the new route onto the stack
        routes.push({
            name: newRouteName,
            params,
            key: `${newRouteName}-${Date.now()}`, // Ensure a unique key
        });

        return CommonActions.reset({
            ...state,
            routes,
            index: routes.length - 1,
        });
    });
};
