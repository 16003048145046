import React from "react";

type TConditionalJSX = {
    condition: boolean;
    fallback?: React.ReactNode;
    children?: React.ReactNode;
};

/*
 * Renders JSX based on a passed condition
 */
const ConditionalComponent: React.FC<TConditionalJSX> = (props) => {
    const { condition, children, fallback } = props;

    if (condition) {
        return <>{children}</>;
    } else {
        return fallback ? <>{fallback}</> : null;
    }
};

export const ConditionalJSX = React.memo(ConditionalComponent);
