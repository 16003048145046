import React, { useCallback, useEffect, useMemo, useRef } from "react";
import {
    StyleSheet,
    Modal as RNModal,
    ViewStyle,
    Pressable,
    GestureResponderEvent,
    Animated,
    Platform,
} from "react-native";

import { useDLS } from "../../styles/style-service";
import { ModalWithTitle, ModalWithTitleProps } from "./modal-with-title";

import type { ModalStyle } from "../../styles/interfaces/modal";

export type { ModalWithTitleProps };

export interface ModalStyleInterface {
    backdropStyle?: ViewStyle;
    modalBodyStyle?: ViewStyle;
}

interface animationFade {
    type?: "fade";
    duration?: never;
    initialPosition?: never;
}

interface animationSlideFromBottom {
    type?: "slideFromBottom";
    duration?: number;
    initialPosition?: number;
}

export type ModalAnimation = animationFade | animationSlideFromBottom;

interface ModalRequiredProps {
    showModal: boolean;
    setShowModal: (show: boolean) => void;
    modalStyles?: ModalStyleInterface;
    dismissOnPressingBackdrop?: boolean;
    animation?: ModalAnimation;
    /**
     * Only use with modal that are in center without input fields
     * This affects the keboard positioning in android
     * @default false to avoid breaking existing modals
     */
    isStatusBarTranslucent?: boolean;
}

export interface ModalWithComponent extends ModalRequiredProps {
    component: React.ReactNode;
    title?: never;
    description?: never;
    buttons?: never;
}

type getComponentStyleInterface = (s: ModalStyle) => {
    backdropStyle: ViewStyle | undefined;
    modalBodyStyle: ViewStyle | undefined;
};

const getComponentStyle: getComponentStyleInterface = (source) => {
    const { backdropStyle, modalBodyStyle } = source;
    return {
        backdropStyle,
        modalBodyStyle,
    };
};

export type ModalProps = ModalWithComponent | (ModalRequiredProps & ModalWithTitleProps);

/**
 * Modal (like a pop up), to display confirmation messages, or other utilities
 */
export const Modal: React.FC<ModalProps> = ({
    showModal = false,
    setShowModal,
    title,
    description,
    buttons,
    component,
    modalStyles,
    dismissOnPressingBackdrop = false,
    animation,
    isStatusBarTranslucent = false,
}) => {
    const [modalShown, setModalShown] = React.useState(false);

    const animationType = useMemo(() => animation?.type || "fade", [animation]);
    const animationDuration = useMemo(() => animation?.duration || 250, [animation]);
    const animationInitialPosition = useMemo(
        () => animation?.initialPosition || (animationType === "slideFromBottom" ? 250 : 0),
        [animation, animationType],
    );

    const position = useRef(new Animated.Value(animationInitialPosition));
    const animatingRef = useRef<Animated.CompositeAnimation>();

    useEffect(() => {
        if (animationType !== "slideFromBottom") {
            setModalShown(showModal);
            return;
        }

        const toValue = showModal ? 0 : animationInitialPosition;
        animatingRef.current?.stop();
        animatingRef.current = Animated.timing(position.current, {
            toValue: toValue,
            duration: animationDuration,
            useNativeDriver: Platform.OS !== "web",
        });

        if (showModal) {
            setModalShown(true);
        }

        animatingRef.current.start(() => {
            animatingRef.current = undefined;
            if (!showModal) {
                setModalShown(false);
            }
        });
    }, [animationDuration, animationInitialPosition, animationType, showModal]);

    const dls = useDLS("modal", {
        backdropStyle: modalStyles?.backdropStyle,
        modalBodyStyle: modalStyles?.modalBodyStyle,
    });

    const dlsStyle = getComponentStyle(dls.style);
    const hideModal = useCallback(() => setShowModal(false), [setShowModal]);

    const backDropPressed = useCallback(
        (e: GestureResponderEvent) => {
            if (e.target === e.currentTarget && dismissOnPressingBackdrop) {
                hideModal();
            }
        },
        [hideModal, dismissOnPressingBackdrop],
    );

    return (
        <RNModal
            statusBarTranslucent={isStatusBarTranslucent}
            animationType="none"
            onRequestClose={hideModal}
            visible={modalShown}
            presentationStyle="overFullScreen"
            transparent>
            <Pressable
                style={[styles.backdrop, dlsStyle.backdropStyle]}
                onPress={backDropPressed}
                accessible={false}
                testID="modal-backdrop">
                <Animated.View
                    style={[
                        styles.modalBody,
                        dlsStyle.modalBodyStyle,
                        { transform: [{ translateY: position.current }] },
                    ]}>
                    {title ? (
                        <ModalWithTitle
                            title={title}
                            description={description}
                            buttons={buttons}
                            hideModal={hideModal}
                        />
                    ) : null}
                    {component ?? null}
                </Animated.View>
            </Pressable>
        </RNModal>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalBody: {
        padding: 20,
        borderRadius: 8,
        maxWidth: 284,
    },
});
