import React, { useCallback } from "react";
import { Pressable, StyleSheet, ViewProps } from "react-native";

import {
    IReturnOrderMessage,
    ReturnOrderMessagePayload,
} from "@swiggy-private/connect-chat-commons";
import { CdnImage } from "@swiggy-private/react-native-ui";
import { Box, Stack } from "@swiggy-private/rn-adaptive-layout";
import { SpacingValue, Text, TextColor, useTheme } from "@swiggy-private/rn-dls";

interface Props {
    message: IReturnOrderMessage;
    isPublisher: boolean;

    style?: ViewProps["style"];
    textColor?: TextColor;
    onPress?: VoidFunction;
}

const IMAGE_WIDTH = 230;
const IMAGE_HEIGHT = 130;
const IMAGE_ID = "minis/return_chat";

const LABELS = {
    ISSUE: "Issue:",
    RESOLUTION: "Resolution:",
    COMMENTS: "Comments:",
    NUMBER: "UPI refund to phone number:",
    RETURN_EXCHANGE_TITLE: "Return or exchange",
};

const ConsumerReturnOrderMessageComponent: React.FC<Props> = ({
    message,
    isPublisher,
    onPress,
}) => {
    const { value: theme } = useTheme();

    const payload = message.payload as ReturnOrderMessagePayload;

    const imageContainerStyle = isPublisher
        ? {
              backgroundColor: theme["color-basic-5"],
              borderColor: theme["color-basic-5"],
          }
        : {
              backgroundColor: theme["color-primary-50"],
              borderColor: theme["color-primary-dark"],
          };

    const renderTextComponent = useCallback(
        (text: string) => (
            <Text category="b1" color="color-basic-75">
                {text}
            </Text>
        ),
        [],
    );

    if (!payload.orderId || !payload.text) {
        return null;
    }

    const { itemsTitle, text, comments, phoneNumber } = payload;

    return (
        <Pressable onPress={onPress}>
            <Stack style={[styles.container]}>
                <Stack
                    spacing={SpacingValue["space-x-small"]}
                    style={[styles.imageContainer, imageContainerStyle]}>
                    <Box style={styles.image}>
                        <CdnImage
                            isImageKitEnabled
                            id={IMAGE_ID}
                            width={IMAGE_WIDTH}
                            height={IMAGE_HEIGHT}
                        />
                    </Box>
                    <Stack style={styles.imageContent}>
                        <Text category="b1" weight="bold" color="color-basic-75">
                            {LABELS.RETURN_EXCHANGE_TITLE}
                        </Text>
                        <Text category="b3" color="color-basic-75" numberOfLines={1}>
                            {itemsTitle}
                        </Text>
                    </Stack>
                </Stack>
                <Stack spacing={SpacingValue["space-xx-large"]}>
                    {text
                        ? renderTextComponent(
                              `${isPublisher ? LABELS.ISSUE : LABELS.RESOLUTION} ${text}`,
                          )
                        : null}
                    {comments ? renderTextComponent(`${LABELS.COMMENTS} ${comments}`) : null}
                    {phoneNumber ? renderTextComponent(`${LABELS.NUMBER} ${phoneNumber}`) : null}
                </Stack>
            </Stack>
        </Pressable>
    );
};

export const ConsumerReturnOrderMessage = React.memo(ConsumerReturnOrderMessageComponent);

const styles = StyleSheet.create({
    container: {
        width: IMAGE_WIDTH,
        borderRadius: 12,
    },
    imageContent: {
        paddingBottom: SpacingValue["space-small"],
        paddingHorizontal: SpacingValue["space-x-small"],
    },
    imageContainer: {
        borderRadius: 16,
        overflow: "hidden",
        marginBottom: SpacingValue["space-small"],
    },
    image: {
        width: IMAGE_WIDTH,
        resizeMode: "cover",
        height: IMAGE_HEIGHT,
    },
});
