const avatarColors = [
    "#1abc9c",
    "#3498db",
    "#f1c40f",
    "#8e44ad",
    "#1D8796",
    "#d35400",
    "#2c3e50",
    "#A66F59",
];

const calculateAsciiCode = (value: string): number => {
    return value
        .split("")
        .map((letter) => letter.charCodeAt(0))
        .reduce((previous, current) => previous + current);
};

export function getRandomAvatarColor(avatarText?: string): string {
    const asciiCodeSum = calculateAsciiCode(avatarText || "U");
    return avatarColors[asciiCodeSum % avatarColors.length];
}
