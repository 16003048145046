import memoizeOne from "memoize-one";
import * as React from "react";
import { Icon, SvgIcon } from "@swiggy-private/connect-svg-icons";
import { ColorValue, StyleSheet, TextStyle, View } from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls-theme";

import { TouchableComponent, TouchableComponentProps } from "../../support/touchable-component";
import { AppBarStyle } from "../../styles/interfaces/appbar";
import { useDLS } from "../../styles/style-service";
import { FalsyText } from "../../support/falsy-text";
import { FalsyFC, RenderProp } from "../../support/falsy-fc";
import { TextProps } from "../text";

interface AppBarActionProps extends TouchableComponentProps {
    icon?: Icon;
    title?: string | RenderProp<TextProps>;
    active?: boolean;
}

const getComponentStyle = memoizeOne(
    (
        style: AppBarStyle,
    ): {
        iconColor: ColorValue;
        activeIconColor: ColorValue;
        text: TextStyle;
        activeText: TextStyle;
        trayColor: ColorValue;
    } => {
        return {
            iconColor: style.iconColor,
            activeIconColor: style.activeIconColor,
            text: {
                color: style.textColor,
                fontFamily: style.textFontFamily,
                fontSize: style.textFontSize,
            },
            activeText: {
                color: style.activeTextColor,
                fontFamily: style.activeTextFontFamily,
                fontSize: style.activeTextFontSize,
            },
            trayColor: style.trayColor,
        };
    },
);

type AppBarAction = React.ForwardRefExoticComponent<AppBarActionProps & React.RefAttributes<View>>;

export const AppBarAction: AppBarAction = React.forwardRef(
    ({ icon, title, active, style, ...props }, ref) => {
        const dls = useDLS("appbar");
        const dlsStyle = getComponentStyle(dls.style);

        const iconColor = active ? dlsStyle.activeIconColor : dlsStyle.iconColor;
        const titleStyle = active ? dlsStyle.activeText : dlsStyle.text;

        return (
            <TouchableComponent
                useDefaultHitSlop={false}
                style={[styles.container, style]}
                {...props}
                ref={ref}>
                {icon ? (
                    <FalsyFC component={SvgIcon} icon={icon} color={iconColor.toString()} />
                ) : null}
                {icon || title ? <View style={styles.separator} /> : null}
                <FalsyText component={title} style={titleStyle} />
            </TouchableComponent>
        );
    },
);

if (process.env.NODE_ENV !== "production") {
    AppBarAction.displayName = "AppBarAction";
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
        height: "100%",
    },
    separator: {
        width: SpacingValue["space-small"],
    },
});
