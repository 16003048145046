import {
    ImageKitTransformation,
    getImageKitImageUrl,
    getImageKitTransformationsFromChildren,
    getIKImageFormatForPlatform,
    getImagePixelRatio,
} from "../../helpers/imageKit";
import { isUri } from "../../helpers/uri";
import { ImagekitTransformation, ImagekitTransformationProps } from "../imagekit-transformation";

type Children = React.ReactElement<ImagekitTransformationProps, typeof ImagekitTransformation>;

type ImageUrlParams = {
    id: string;
    cloudName: string;
    cloudBaseUrl: string;
    folderName?: string;
    cloudResourceType?: "upload" | "youtube";
    width?: number;
    height?: number;
    format?: ImageKitTransformation["format"];
    children?: Children | Children[];
    optimizedDpr?: boolean;
};

export const getIKImageUrl = ({
    id,
    cloudBaseUrl,
    cloudName,
    cloudResourceType,
    folderName,
    width,
    height,
    format,
    children,
    optimizedDpr,
}: ImageUrlParams): string => {
    const dpr = getImagePixelRatio(optimizedDpr);
    return isUri(id)
        ? id
        : getImageKitImageUrl({
              id: id,
              cloudName,
              cloudBaseUrl,
              folderName,
              cloudResourceType,
              transformations: [
                  ...getImageKitTransformationsFromChildren(ImagekitTransformation, children),
                  {
                      width: width ? Math.floor(width * dpr) : undefined,
                      height: height ? Math.floor(height * dpr) : undefined,
                      crop: "fit",
                  },
                  {
                      format: format ?? getIKImageFormatForPlatform(),
                  },
              ],
          });
};

export const formatImageId = (imageId: string): string => {
    if (!imageId) {
        return imageId;
    }

    // This is for old assets
    let formattedId = imageId.replace("minis/minis25/", "minis25/");
    formattedId = formattedId.replace("minis/", "static-assets/");
    formattedId = formattedId.replace("app-assets/", "static-assets/");

    return formattedId;
};
