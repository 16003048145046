import React from "react";
import { ViewStyle, StyleSheet } from "react-native";

import { Stack } from "@swiggy-private/rn-adaptive-layout";
import { DashBorderLine } from "@swiggy-private/react-native-ui";
import { ColorPalette, SpacingValue, Text, TextProps, useTheme } from "@swiggy-private/rn-dls";

export interface DottedUnderlineTextComponentProps extends TextProps {
    children: string;
    underlineColor: keyof ColorPalette;

    underlineStyle?: ViewStyle;
    underlineDistance?: keyof typeof SpacingValue | 0;
}

const DottedUnderlineTextComponent: React.FC<DottedUnderlineTextComponentProps> = ({
    children,
    underlineColor,
    underlineStyle,
    underlineDistance = 0,
    ...props
}) => {
    const { value: theme } = useTheme();

    const dashBorderStyles: ViewStyle = React.useMemo(
        () => ({
            ...underlineStyle,
            bottom: underlineDistance ? -SpacingValue[underlineDistance] : 0,
        }),
        [underlineDistance, underlineStyle],
    );

    return (
        <Stack>
            <Text {...props}>{children}</Text>
            <DashBorderLine
                borderColor={theme[underlineColor]}
                style={[styles.dashLine, dashBorderStyles]}
            />
        </Stack>
    );
};

export const DottedUnderlineText = React.memo(DottedUnderlineTextComponent);

const styles = StyleSheet.create({
    dashLine: {
        position: "absolute",
        left: 0,
        right: 0,
    },
});
