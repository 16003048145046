import React, { ComponentPropsWithoutRef } from "react";
import { StyleSheet, ViewStyle } from "react-native";
import { Dialog, DialogProps } from "@swiggy-private/rn-dls";
import { useSelectScreen } from "@swiggy-private/rn-adaptive-layout";

const BottomSheetComponent: React.FC<ComponentPropsWithoutRef<typeof Dialog>> = ({
    style: propStyle,
    ...props
}) => {
    const verticalAlignDialog = useSelectScreen<DialogProps["verticalAlignDialog"]>({
        default: "bottom",
        lg: "center",
    });

    const widthStyles = useSelectScreen<ViewStyle>({
        default: styles.maxWidthContainer,
        lg: styles.emptyStyles,
    });

    // * fix : causing Keyboard whitespaces issues on Mweb
    if (!props?.open) {
        return null;
    }
    return (
        <Dialog
            verticalAlignDialog={verticalAlignDialog}
            style={[widthStyles, propStyle]}
            {...props}
        />
    );
};

export const BottomSheet = React.memo(BottomSheetComponent);

const styles = StyleSheet.create({
    maxWidthContainer: {
        width: "100%",
        maxWidth: "100%",
    },
    emptyStyles: {},
});
