import { AsyncStorageAPI } from "../interfaces/async-storage";
import AsyncStoragePackage from "../optional-dependencies/async-storage-package";

const FallbackAsyncStorageService: AsyncStorageAPI = {
    getItem: function (): Promise<string | null> {
        return Promise.resolve(null);
    },

    setItem: function (): Promise<void> {
        return Promise.resolve();
    },

    removeItem: function (): Promise<void> {
        return Promise.resolve();
    },

    mergeItem: function (): Promise<void> {
        return Promise.resolve();
    },

    clear: function (): Promise<void> {
        return Promise.resolve();
    },

    getAllKeys: function (): Promise<string[]> {
        return Promise.resolve([]);
    },

    multiGet: function (): Promise<[string, string | null][]> {
        return Promise.resolve([]);
    },

    multiSet: function (): Promise<void> {
        return Promise.resolve();
    },

    multiRemove: function (): Promise<void> {
        return Promise.resolve();
    },

    multiMerge: function (): Promise<void> {
        return Promise.resolve();
    },
};

export const AsyncStorageService: AsyncStorageAPI =
    AsyncStoragePackage ?? FallbackAsyncStorageService;
