import { Provider, ProviderConfig } from "../../interfaces/provider";
import { PubnubProvider } from "./pubnub";

type ProviderType = "pubnub";

export const getProvider = (config: ProviderConfig, providerType: ProviderType): Provider => {
    switch (providerType) {
        case "pubnub":
            return new PubnubProvider(config);
        default:
            throw new Error("invalid provider type specified");
    }
};
