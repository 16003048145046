import React, { useCallback, useMemo, useState } from "react";
import {
    FlatListProps,
    StyleProp,
    StyleSheet,
    View,
    ViewStyle,
    NativeSyntheticEvent,
    NativeScrollEvent,
} from "react-native";
import { SpacingValue } from "@swiggy-private/rn-dls";
import { IOFlatList } from "@swiggy-private/react-native-ui";
import { SdkConversation } from "@swiggy-private/connect-chat-sdk";

import { ChatConversationListItemView } from "./item-view";
import { ChatSearchInput } from "../search-input";
import { Conversation, IChatSearchInputStyles } from "../../interfaces";
import { useChatRenderer } from "../../hooks/use-chatrender";

export type ChatConversationListViewProps = {
    testID?: string;
    conversations: Conversation[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
    style?: StyleProp<ViewStyle>;
    refreshing?: boolean;
    onEndReached?: () => void;
    onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
    onConversationPress: (conversation: Conversation) => void;
    avatarPlaceholderImageId?: string;
    viewabilityConfig?: FlatListProps<SdkConversation>["viewabilityConfig"];
    viewabilityConfigCallbackPairs?: FlatListProps<SdkConversation>["viewabilityConfigCallbackPairs"];
    onViewableItemsChanged?: FlatListProps<SdkConversation>["onViewableItemsChanged"];
    canShowGuestUserConversation?: boolean;
    searchWidgetStyles?: IChatSearchInputStyles;
    iconSize?: number;
};

export const ChatConversationListView: React.FC<ChatConversationListViewProps> = (props) => {
    const {
        conversations,
        ListEmptyComponent,
        style,
        refreshing,
        onEndReached,
        onScroll,
        onConversationPress,
        avatarPlaceholderImageId,
        ListFooterComponent,
        viewabilityConfig,
        viewabilityConfigCallbackPairs,
        onViewableItemsChanged,
        canShowGuestUserConversation,
        searchWidgetStyles,
        iconSize,
    } = props;

    const [searchText, setSearchText] = useState("");
    const filteredConversations = useMemo(
        () =>
            searchText.length > 0
                ? conversations.filter((conversation: Conversation) => {
                      return conversation.title
                          ? conversation.title.toLowerCase().includes(searchText.toLowerCase())
                          : true;
                  })
                : conversations,
        [conversations, searchText],
    );

    const ChatRenderer = useChatRenderer();

    const onChatSearch = useCallback(
        (searchTextVal = "") => setSearchText(searchTextVal.toLowerCase()),
        [],
    );

    const ItemSeparatorComponent: React.FC = useCallback(
        () => <View style={styles.separator} />,
        [],
    );

    const renderItem: React.FC<{ item: Conversation; index: number }> = useCallback(
        ({ item }) => {
            return (
                <ChatConversationListItemView
                    conversation={item}
                    onConversationPress={onConversationPress}
                    avatarPlaceholderImageId={avatarPlaceholderImageId}
                    isGuestUser={canShowGuestUserConversation && item.meta?.isGuestUser}
                />
            );
        },
        [avatarPlaceholderImageId, canShowGuestUserConversation, onConversationPress],
    );

    const onEndReachedCallback = useCallback(
        (info: { distanceFromEnd: number }) => {
            if (
                info.distanceFromEnd < 0 ||
                searchText.length > 0 ||
                typeof onEndReached !== "function"
            ) {
                return;
            }

            onEndReached();
        },
        [onEndReached, searchText.length],
    );

    const SearchInput = ChatRenderer?.renderConversationListSearchInput ?? ChatSearchInput;
    const List = ChatRenderer?.renderConversationList ?? IOFlatList;

    return (
        <View style={styles.container}>
            {conversations.length > 0 ? (
                <SearchInput
                    onChangeText={onChatSearch}
                    searchWidgetStyles={searchWidgetStyles}
                    iconSize={iconSize}
                />
            ) : null}
            <List
                ListEmptyComponent={conversations.length > 0 ? null : ListEmptyComponent}
                ListFooterComponent={ListFooterComponent}
                bounces={false}
                showsVerticalScrollIndicator={false}
                ItemSeparatorComponent={ItemSeparatorComponent}
                contentContainerStyle={[styles.contentContainer, style]}
                data={filteredConversations}
                renderItem={ChatRenderer?.renderConversationListItem ?? renderItem}
                keyExtractor={(item) => item.id}
                onEndReached={onEndReachedCallback}
                onEndReachedThreshold={2}
                viewabilityConfig={viewabilityConfig}
                viewabilityConfigCallbackPairs={viewabilityConfigCallbackPairs}
                onViewableItemsChanged={onViewableItemsChanged}
                refreshing={refreshing}
                onScroll={onScroll}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: { flex: 1 },
    separator: { marginTop: SpacingValue["space-x-small"] },
    contentContainer: {
        flexGrow: 1,
        paddingHorizontal: SpacingValue["space-medium"],
        backgroundColor: "transparent",
    },
});
